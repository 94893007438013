import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Stack, IconButton } from '@mui/material';
import CustomTable from 'components/customTable';
import DefaultCell from 'components/DefaultCell';
import Profile from 'assets/actionIcons/profile.png';
import { DeleteIcon } from 'icons';
// *** Third Party Imports
import { useNavigate } from 'react-router-dom';

// *** Custom Components
import { useAppDispatch, useAppSelector } from 'store/store';
import { getLocalDateTime } from 'helper/service';
import { getUserSessionList } from 'store/Thunk/userSessionThunk';
import DeleteUserSession from 'components/dialog/userSession/deleteSession';
import Avatar from '@core/components/mui/avatar';

// const breadcrumbs = [
//   <Link
//     underline="hover"
//     key="1"
//     color="inherit"
//     href="/"
//     onClick={() => handleBreadCrumbClick}
//   >
//     Content Management
//   </Link>,
//   <Typography key="2" color="#C73E9D">
//     Quiz
//   </Typography>,
// ];

// function handleBreadCrumbClick(
//   event: React.MouseEvent<HTMLDivElement, MouseEvent>,
// ) {
//   event.preventDefault();
//   console.info('You clicked a breadcrumb.');
// }

function Index() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userSessionList } = useAppSelector(
    (state: any) => state.userSessionReducer,
  );

  const [userSession, setUserSession] = useState<any[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPageSize] = useState(10);
  const [search, setSearch] = useState<any>(null);
  const [totalCount, setTotalCount] = useState(0);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [deleteId, setDeleteId] = useState('');

  const fetchIdRef = useRef(0);

  useEffect(() => {
    if (userSessionList && userSessionList?.items) {
      setUserSession(userSessionList?.items);
      setPageCount(userSessionList?.meta?.lastPage);
      setTotalCount(userSessionList?.meta?.total);
    }
  }, [userSessionList]);

  const handleDeleteDialog = (id: string) => {
    setDeleteId(id);
    setOpenDelete(!openDelete);
  };

  const columns = [
    {
      Header: 'User Created',
      accessor: 'user_created',
      Cell: ({ value }: any) => {
        if (Object.keys(value).length) {
          return (
            <Stack direction="row" spacing={2} alignItems="center">
              <Avatar
                alt={value?.name}
                src={value?.profileImage || Profile}
                sx={{ width: 28, height: 28 }}
              />
              {/* <Box component="img" src={value?.profileImage || Profile} /> */}
              <Box>{value?.email}</Box>
            </Stack>
          );
        }
        return '-';
      },
    },
    {
      Header: 'School Leader Name',
      accessor: 'school_leader_name',
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: 'School Leader Email',
      accessor: 'school_leader_email',
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: 'Parent Name',
      accessor: 'parent_name',
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: 'Parent Email',
      accessor: 'parent_email',
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: 'District Name',
      accessor: 'district_name',
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: 'School Name',
      accessor: 'school_name',
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: ({ value }: any) => (
        <Box>
          <IconButton onClick={() => handleDeleteDialog(value.id)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const getRows = () => {
    const data: any = userSession.map((obj: any) => ({
      user_created: obj?.user,
      school_leader_name: obj?.school_leader_name || '-',
      school_leader_email: obj?.school_leader_email || '-',
      parent_name: obj?.parent_name || '-',
      parent_email: obj?.parent_email || '-',
      district_name: obj?.district_name || '-',
      school_name: obj?.school_name || '-',
      date: obj.createdAt ? getLocalDateTime(obj.createdAt) : '-',
      action: obj,
    }));
    return data;
  };

  const tableData = {
    columns: columns,
    rows: getRows(),
  };

  const fetchData = useCallback(({ pageSize, pageIndex, search }: any) => {
    const fetchId = ++fetchIdRef.current;

    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        setPageNo(pageIndex);
        setPageSize(pageSize);
        setSearch(search);
        dispatch(getUserSessionList({ pageSize, pageIndex, search }));
      }
    }, 1000);
  }, []);

  return (
    <Box>
      {/* <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        style={{ marginBottom: 20 }}
      >
        {breadcrumbs}
      </Breadcrumbs> */}
      <CustomTable
        table={tableData}
        fetchData={fetchData}
        canSearch
        title="User Session"
        searchText="Search..."
        pageCount={pageCount}
        pageNo={pageNo}
        totalCount={totalCount}
      />

      {openDelete && (
        <DeleteUserSession
          open={openDelete}
          deleteId={deleteId}
          onClose={handleDeleteDialog}
        />
      )}
    </Box>
  );
}

export default Index;
