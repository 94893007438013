import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  Breadcrumbs,
  InputAdornment,
  TextField,
  Button,
  Grid,
  Link,
  Typography,
  Autocomplete,
  Pagination,
  Tooltip,
} from '@mui/material';
import TeacherCard from 'pages/school_leader/dashboard/tabs/teachers/teacher_card';

import SearchIcon from 'icons/search';
import GraduationHat from 'icons/graduationHat';
import CustomTable from 'components/customTable';
import DefaultCell from 'components/DefaultCell';
import { EditIcon, DeleteIcon, ViewIcon } from 'icons';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AddFirstParent from './AddFirstParent';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/store';
import {
  getParentsListOfClass,
  removeUserFromClassRoom,
} from 'store/Thunk/classroomThunk';
import useSchoolClassroomInfo from 'hooks/useSchoolClassroomInfo';
import { useSelector } from 'react-redux';
import { getLocalDateTime } from 'helper/service';
import { startLoading } from 'store/Slice/loadingSlice';
import AddParentToClassRoom from '../modals/AddParentToClassRoom';
import RemoveUserFromClassDialog from 'components/dialog/users/Delete/removeUser';

function handleBreadCrumbClick(
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
) {
  event.preventDefault();
}

function Index() {
  const [search, setSearch] = useState<string>('');
  const [pageSize, setPageSize] = useState(12);
  const [gotoPage, setGotoPage] = useState<number>(0);
  const [isAddTeacherDialogOpen, setIsTeacherDialogOpen] =
    useState<boolean>(false);

  const closeAddStudentDialog = () => setIsTeacherDialogOpen(false);
  const dispatch = useAppDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const classroomId = urlParams.get('classroomId');

  const { classRoomParents } = useSelector(
    (state: any) => state.classroomReducer,
  );
  console.log('classRoomParents', classRoomParents);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [addParentDialog, setAddParentDialog] = useState(false);
  const [removeUserModal, setRemoveUserModal] = useState<boolean>(false);
  const [userIdToRemove, setUserIdToRemove] = useState('');

  useEffect(() => {
    dispatch(getParentsListOfClass({ id: classroomId }));
  }, []);
  const onSearchChange = (value: any) => {
    setSearch(value);
  };
  const setEntriesPerPage = (value: any) => {
    setPageSize(value);
  };
  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) =>
    setPageNo(value - 1);

  const renderPagination = () => (
    <Pagination
      count={pageCount}
      page={pageNo + 1}
      shape="rounded"
      onChange={handleChangePage}
    />
  );
  useEffect(() => {
    if (classRoomParents && classRoomParents?.items) {
      setPageCount(classRoomParents?.meta?.totalPages);
      setTotalCount(classRoomParents?.meta?.totalCount);
    }
  }, [classRoomParents]);

  const columns = [
    {
      Header: 'Parent',
      accessor: 'parent',
      Cell: ({ value }: any) => (
        <Tooltip title={value?.id}>
          <Typography
            variant="inherit"
            fontWeight={400}
            sx={{
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-words',
              width: '100%',
              minWidth: '150px',
            }}
          >
            {value?.name || '-'}
          </Typography>
        </Tooltip>
      ),
    },
    {
      Header: 'Date Joined',
      accessor: 'date',
      Cell: ({ value }: any) => (
        <DefaultCell value={getLocalDateTime(value) || ''} />
      ),
    },
    {
      Header: 'Kids',
      accessor: 'kids',
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: 'School',
      accessor: 'school',
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: 'Email',
      accessor: 'email',
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: ({ value }: any) => (
        <Tooltip title="Coming Soon">
          <Box>
            <IconButton>
              <EditIcon />
            </IconButton>
            {/* <IconButton>
              <DeleteIcon />
            </IconButton> */}
            <IconButton>
              <ViewIcon />
            </IconButton>
          </Box>
        </Tooltip>
      ),
    },
  ];

  const deleteUserFromClassRoom = () => {
    dispatch(
      removeUserFromClassRoom({
        typeToRemove: 'parent',
        classRoomId: classroomId || '',
        payload: { parentIds: [userIdToRemove] },
      }),
    )
      .unwrap()
      .then((res) => {
        if (res?.status === 200 || res?.status === 202) {
          setRemoveUserModal(false);
          setUserIdToRemove('');
        }
      });
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/"
      onClick={() => handleBreadCrumbClick}
    >
      School Management
    </Link>,
    <Typography key="2" color="#C73E9D">
      Parent
    </Typography>,
  ];

  const getRows = () => {
    if (classRoomParents?.items?.length > 0) {
      const data: any = classRoomParents?.items.map((obj: any) => ({
        parentId: obj?.id || '',
        parent: obj || '',
        kids: obj.dependentOfUser.map((item: any) => item)?.length,
        email: obj?.email,
        school: '',
        date: obj?.createdAt,
      }));
      return data;
    }
  };

  const fetchData = useCallback(({ pageSize, pageIndex, search }: any) => {
    setPageNo(pageIndex);
    dispatch(startLoading());
    dispatch(
      getParentsListOfClass({ id: classroomId, pageSize, pageIndex, search }),
    );
  }, []);

  return (
    <>
      <Box sx={{ m: 4 }}>
        {classRoomParents?.items?.length > 0 ? (
          <Box>
            <Box sx={{ p: '24px', pt: '0' }}>
              <Box ml={2} mt={2}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  mb={2}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <GraduationHat />
                    <Typography className="table_title" sx={{ ml: 2 }}>
                      Parents
                    </Typography>
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {/* <Button
                    variant="contained"
                    className="gray_bg_btn"
                    sx={{ ml: 3 }}
                      onClick={handleOnChange}
                  >
                    Import Teachers
                  </Button> */}
                    <Button
                      variant="contained"
                      className="primary_bg_btn"
                      sx={{ ml: 3 }}
                      onClick={() => {
                        setAddParentDialog(true);
                      }}
                      //   onClick={handleOnChange}
                    >
                      Add Parents
                    </Button>
                  </Box>
                </Box>
                <TextField
                  placeholder={'Search'}
                  sx={{
                    mb: 10,
                    width: { xs: '100%', sm: '40%', md: '30%' },
                    background: '#F9F9F9',
                  }}
                  size="small"
                  value={search}
                  onChange={(e) => onSearchChange(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <Grid container spacing={8}>
                  {classRoomParents?.items?.map((item: any) => {
                    return (
                      <Grid item md={3} sm={4} xs={12}>
                        <TeacherCard
                          id={item?.user?.id}
                          deleteUserFromClassRoom={deleteUserFromClassRoom}
                          setUserIdToRemove={setUserIdToRemove}
                          setRemoveUserModal={setRemoveUserModal}
                          user={item?.user}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Box>
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              my={4}
              sx={{
                border: '1px solid #E9EBED',
                borderRadius: '6px',
                p: 2,
                m: '24px',
                mt: 'unset',
              }}
            >
              {true && (
                <Box flex="1" display="flex" alignItems="center">
                  <Typography variant="body2" className="entries-page-txt">
                    Show&nbsp;&nbsp;
                  </Typography>
                  <Autocomplete
                    disableClearable
                    value={pageSize.toString()}
                    options={['12', '24', '48', '96']}
                    onChange={(event, newValue) => {
                      setEntriesPerPage(parseInt(newValue, 10));
                    }}
                    size="small"
                    sx={{ width: '5rem' }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <Typography variant="body2" className="entries-page-txt">
                    &nbsp;&nbsp;entries of {totalCount}
                  </Typography>
                </Box>
              )}
              {renderPagination()}
            </Box>
          </Box>
        ) : (
          <AddFirstParent />
        )}
      </Box>
      <AddParentToClassRoom
        open={addParentDialog}
        setOpen={setAddParentDialog}
        classRoomId={classroomId}
        fetchData={() => {
          dispatch(getParentsListOfClass({ id: classroomId }));
        }}
        onClose={() => setAddParentDialog(false)}
      />
      <RemoveUserFromClassDialog
        onOk={() => deleteUserFromClassRoom()}
        onClose={() => setRemoveUserModal(false)}
        open={removeUserModal}
      />
    </>
  );
}

export default Index;
