import { createAsyncThunk } from '@reduxjs/toolkit';
import API from 'store/api';
import { startLoading, stopLoading } from 'store/Slice/loadingSlice';
import { notificationFail } from 'store/Slice/notificationSlice';
import { setQuestionList } from 'store/Slice/QuestionSlice';
import { setStudentReplyList } from 'store/Slice/studentReplySlice';
import { setScreenLoading } from 'store/Slice/userSlice';

export const createQuestion = createAsyncThunk(
  'createQuestion',
  async (formData: any, { dispatch }) => {
    let url = formData._request?.id
      ? `/api/v1/questions/${formData._request?.id}`
      : `/api/v1/questions/`;
    try {
      if (formData?._request?.id) {
        const response = await API.patch(url, formData._request);
      } else {
        await API.post(url, formData._request);
      }
      formData?.callback();
      dispatch(stopLoading());
    } catch (err: any) {
      dispatch(stopLoading());
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const getStudentReplyList = createAsyncThunk(
  'getQuestionList',
  async (_request: any, { dispatch }) => {
    dispatch(startLoading());
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 10;
    const search = _request?.search || '';
    let url = `api/v1/student-reply/${_request?.id}?page=${
      page + 1
    }&limit=${size}`;
    try {
      if (search) {
        url += `&query=${search}`;
      }
      const response: any = await API.get(url);
      dispatch(setStudentReplyList(response.data.data));
      dispatch(stopLoading());
      return response;
    } catch (err: any) {
      dispatch(stopLoading());
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const DeleteQuestion = createAsyncThunk(
  'DeleteQuestion',
  async (formData: any, { dispatch }) => {
    let url = `/api/v1/questions/${formData._request}`;
    try {
      await API.delete(url);
      formData?.callback();
      dispatch(stopLoading());
    } catch (err: any) {
      dispatch(stopLoading());
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);
