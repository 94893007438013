import React, { useEffect, useState } from 'react';
import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import {
  bellwork,
  classroom,
  masteredSkill,
  parents,
  practice,
  quiz,
  quizTotal,
  school,
  student,
  teacher2,
  ticket,
} from 'assets';

import { TableList } from '../../../components/Dashboard/table';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import {
  getDashboardInfo,
  getTeacherDashboardInfo,
  getTeacherClassroomProgress,
  getTeacherSkillMastered,
  getTeacherDashboardSkillsMastered,
} from '../../../store/Thunk/dashboardThunk';
import { CardInfo } from '../../../components/Dashboard/cardInfo';
import Icon1 from '../../super_admin/dashboard/assets/icon-1.svg';
import Icon2 from '../../super_admin/dashboard/assets/icon-2.svg';
import Icon3 from '../../super_admin/dashboard/assets/icon-3.svg';
import Icon4 from '../../super_admin/dashboard/assets/icon-4.svg';
import Icon5 from '../../super_admin/dashboard/assets/icon-5.svg';
import Icon6 from '../../super_admin/dashboard/assets/icon-6.svg';
import Icon7 from '../../super_admin/dashboard/assets/icon-7.svg';
import Icon8 from '../../super_admin/dashboard/assets/icon-8.svg';
import Icon9 from '../../super_admin/dashboard/assets/icon-9.svg';
import Icon10 from '../../super_admin/dashboard/assets/icon-10.svg';
import Icon11 from '../../super_admin/dashboard/assets/icon-11.svg';
import Icon12 from '../../super_admin/dashboard/assets/icon-12.svg';

import { student9 } from 'assets/students';
import { student8 } from 'assets/students';
import { student7 } from 'assets/students';
import { student6 } from 'assets/students';
import { student5 } from 'assets/students';
import { student4 } from 'assets/students';
import { student3 } from 'assets/students';
import { student2 } from 'assets/students';
import { student1 } from 'assets/students';
import Students from '../../../components/Dashboard/students';
import { classStudents } from '../../../components/Dashboard/students.mock';
import ProgressByClassRoom from 'components/Dashboard/teacherDashboard/progressByClassrooms';
import TableTeacherProgress from 'components/student/progress/teacherTable';
import TableHeader from 'components/tableHeader';
import Nodata from 'components/v2_components/Admin_Panel_Dashboards/skills_chart/components/noData';
import SkillsMasteredChart from 'components/v2_components/Admin_Panel_Dashboards/skills_chart';
import {
  scrambledData,
  tabs,
} from 'components/v2_components/Admin_Panel_Dashboards/skills_chart/helpers/helpers';
function Index() {
  const classData = [
    'class:120507/14 M/J  ODADE B PRE',
    'class:120507/14 M/J  ODADE B PRE',
    'class:120507/14 M/J  ODADE B PRE',
  ];

  const dispatch: any = useAppDispatch();
  const { teachers, totalCounts } = useAppSelector(
    (state: any) => state.teacherDashboardReducer,
  );
  const teacherSchool: any = localStorage.getItem('teacherSchool');
  const { teacherProgress, skillsMastered } = useAppSelector(
    (state: any) => state.dashboardReducer,
  );
  const userData: any = localStorage.getItem('userData');
  const userDataParsed = JSON.parse(userData);

  // useEffect(() => {
  //   dispatch(getDashboardInfo({ id: teacherSchool }));
  // }, []);
  useEffect(() => {
    dispatch(getTeacherDashboardInfo({ id: userDataParsed?.id }));
    dispatch(getTeacherSkillMastered({ id: userDataParsed?.id }));
    dispatch(getTeacherClassroomProgress({ id: userDataParsed?.id }));
    dispatch(getTeacherDashboardSkillsMastered({ id: userDataParsed?.id }));
  }, []);
  const { teacherStudents, teacherClassrooms, totalParentsCount } =
    useAppSelector((state: any) => state.teacherDashboardReducer);
  console.log('totalParentsCount', totalParentsCount);
  console.log('totalParentsCount', totalCounts);

  const color = '#E9F6F3';

  const topCounters: any[] = [
    {
      title: 'Total Students',
      icon: Icon1,
      count: totalCounts?.totalStudentsCount || 0,
      md: 3,
    },
    {
      title: 'Total Teachers',
      icon: Icon2,
      count: totalCounts?.totalTeachersCount || 0,
      md: 3,
    },
    {
      title: 'Total Parents',
      count: totalCounts?.totalParentsCount || 0,
      icon: Icon3,
      md: 3,
    },
    {
      title: 'Total Classrooms',
      icon: Icon4,
      count: totalCounts?.totalClassroomsCount || 0,
      md: 3,
    },
    {
      title: 'Bellwork %',
      count: totalCounts?.schoolAssignmentBellwork,
      icon: Icon5,

      md: 3,
    },
    {
      title: 'Exit Ticket %',
      count: totalCounts?.schoolAssignmentExitTicket,
      icon: Icon6,
      md: 3,
    },
    {
      title: 'Unit Quiz',
      count: totalCounts?.schoolAssignmentUnitQuiz,
      icon: Icon8,
      md: 3,
    },
    {
      title: 'Unit Test %',
      count: totalCounts?.schoolAssignmentUnitTest,
      icon: Icon7,
      md: 3,
    },
    {
      title: 'Daily Practice %',
      count: totalCounts?.dailyPractices,
      icon: Icon9,
      md: 3,
    },
    {
      title: 'Diagnostics%',
      count: totalCounts?.schoolAssignmentDiagnostic,
      icon: Icon10,
      md: 3,
    },
    {
      title: 'Total Videos Watched',
      count: totalCounts?.watchedVideos,
      icon: Icon11,
      md: 3,
    },
    {
      title: 'Total G3MS Earned',
      count: totalCounts?.totalGemsEarned,
      icon: Icon12,
      md: 3,
    },
  ];
  return (
    <>
      <Grid container spacing={4} sx={{ mt: 3, mb: 4 }}>
        {topCounters?.map((info: any) => (
          <CardInfo
            item={{
              title: ` ${info?.title}`,
              content: `${info?.count || 0}`,
              icon: `${info?.icon}`,
              color: `${info?.color}`,
              sm: info?.sm || 4,
              md: info?.md || 4,
            }}
          />
        ))}
      </Grid>
      <SkillsMasteredChart
        data={skillsMastered.length ? skillsMastered : scrambledData}
        tabs={tabs}
      />
      <Students
        students={teacherStudents}
        title={'Students Overall Score %'}
        select={['Bell work']}
        select2={['1205070/14 M/J GRADE 8 PRE']}
      />
      {/* <TableList
        teachers={teacherDataEdited}
        title={'Progress by Classrooms'}
      /> */}
      {/* <TeacherComponent teacher={teacherDataEdited} color='white'/> */}

      <Card sx={{ my: 2, p: 4, minHeight: '320px', width: '100%' }}>
        <TableHeader
          item={{
            canNotSearch: true,
            title: 'Progress by Classrooms and Students',
            action: false,
          }}
        />
        {!(teacherClassrooms.length > 0) ? (
          <Nodata type={2} />
        ) : (
          <>
            <div
              style={{
                display: 'flex',
                marginBottom: '1rem',
                overflowX: 'auto',
              }}
            >
              <div
                className="left-vertical-bar"
                style={{ borderRadius: '8px 0px 0px 8px' }}
              >
                <img
                  className="left-vertical-bar-image"
                  src={userDataParsed?.profileImage || ''}
                  alt=""
                />
                <div className="left-vertical-bar-name text-sm font-medium">
                  {userDataParsed?.name || ''}
                </div>
              </div>
              <div className="right-main-bar">
                {teacherClassrooms?.map((classes: any, i: number) => (
                  <TableTeacherProgress
                    key={i}
                    color={color}
                    classes={classes}
                  />
                ))}
              </div>
            </div>
          </>
        )}
      </Card>
    </>
  );
}

export default Index;
