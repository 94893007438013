import React from 'react';
import { Box, Typography, Grid } from '@mui/material';

interface MyComponentProps {
  details: {}[];
  gridSize: number;
}

const MyComponent: React.FC<MyComponentProps> = ({ details, gridSize }) => {
  return (
    <>
      <Grid container spacing={8} sx={{ p: '20px 5px' }}>
        {details?.map((item: any, key: any) => {
          return (
            <Grid item md={item.avatar ? 6 : gridSize} sm={4} xs={6}>
              <Typography
                variant="body1"
                sx={{
                  color: '#52525B',
                  fontSize: '14px',
                  fontFamily: 'Inter',
                  fontWeight: '500',
                  lineHeight: '13px',
                  wordWrap: 'break-word',
                  mb: 3,
                }}
              >
                {item?.title}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {/* {item?.avatar ? item?.avatar : null} */}
                <Typography
                  variant="body1"
                  sx={{
                    color: '#84818a',
                    fontSize: '14px',
                    fontFamily: 'Inter',
                    fontWeight: '500',
                    wordWrap: 'break-word',
                  }}
                >
                  {item?.content}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default MyComponent;
