import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Stack,
  Breadcrumbs,
  Link,
  AvatarGroup,
  Avatar,
  Button,
} from '@mui/material';
import CustomTable from 'components/customTable';
import DefaultCell from 'components/DefaultCell';
import { EditIcon, DeleteIcon, ViewIcon } from 'icons';
import Profile from 'assets/actionIcons/profile.png';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AddUpdate from 'components/dialog/dailyChallenges/addUpdate';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useNavigate, useParams } from 'react-router-dom';
import { displayMultipleValue, getLocalDateTime } from 'helper/service';
import { route_info } from 'navigation/route';
import { Base } from 'assets';
import AddVideo from 'components/dialog/studentReplies/view';

import DeleteDailyChallenge from 'components/dialog/dailyChallenges/deleteDailyChallenges';
import {
  getDailyChallengesById,
  getDailyChallengesList,
} from 'store/Thunk/dailyChallengesThunk';
import { getStudentReplyList } from 'store/Thunk/studentReplyThunk';

function handleBreadCrumbClick(
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
) {
  event.preventDefault();
}

function Index() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  console.log('id', id);

  const navigate = useNavigate();
  const { studentReplyList } = useAppSelector(
    (state: any) => state.StudentReplyReducer,
  );
  // const { dailyChallengesObj } = useAppSelector(
  //   (state: any) => state.dailyChallengesReducer,
  // );

  const [isOpen, setIsOpen] = useState(false);
  const [studentRepliesList, setStudentRepliesList] = useState<any[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPageSize] = useState(10);
  const [search, setSearch] = useState<any>(null);
  const [totalCount, setTotalCount] = useState(0);
  const [editId, setEditId] = useState('');
  const [openDelete, setOpenDelete] = React.useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [isUploadVideo, setIsUploadVideo] = useState(false);
  const [replyDetails, setReplyDetails] = useState({});

  const handleCancel = (e: any) => {
    setIsUploadVideo(!isUploadVideo);
  };

  const fetchIdRef = useRef(0);

  useEffect(() => {
    if (studentReplyList && studentReplyList?.items) {
      setStudentRepliesList(studentReplyList?.items || []);
      setPageCount(studentReplyList?.meta?.lastPage || 0);
      setTotalCount(studentReplyList?.meta?.total || 0);
    }
  }, [studentReplyList]);

  // useEffect(() => {
  //   if (id) {
  //     dispatch(getStudentReplyList({ id }));
  //   }
  //   // dispatch(getDailyChallengesById({ id }));
  // }, [id]);

  const handleAddUpdateChallenges = () => {
    setEditId('');
    setIsOpen(!isOpen);
  };

  const handleEditClick = (id: string) => {
    setEditId(id);
    setIsOpen(!isOpen);
  };

  const handleDeleteDialog = (id: string) => {
    setDeleteId(id);
    setOpenDelete(!openDelete);
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/"
      onClick={() => handleBreadCrumbClick}
    >
      Resources
    </Link>,
    <Typography key="2" color="#C73E9D">
      Daily Challenge
    </Typography>,
    <Typography key="3" color="#C73E9D">
      {/* {dailyChallengesObj?.title} */} Title
    </Typography>,
  ];

  const columns = [
    {
      Header: 'Students',
      accessor: 'name',
      Cell: ({ value }: any) => {
        console.log('value', value);
        if (Object.keys(value).length) {
          return (
            <Stack direction="row" spacing={2} alignItems="center">
              <Box component="img" src={value?.profileImage || Profile} />
              <DefaultCell value={value?.name} />
            </Stack>
          );
        }
      },
    },
    {
      Header: 'Grade',
      accessor: 'grade',
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: 'Skill',
      accessor: 'skill',
      Cell: ({ value }: any) => {
        if (value.length) {
          return <DefaultCell value={value} />;
        } else {
          return '-';
        }
      },
    },
    {
      Header: 'Date',
      accessor: 'date',
      Cell: ({ value }: any) => (
        <DefaultCell value={getLocalDateTime(value) || '-'} />
      ),
    },
    {
      Header: 'Response',
      accessor: 'response',
      Cell: ({ value }: any) => (
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          sx={{ display: 'grid', placeContent: 'center' }}
        >
          <Box
            onClick={() => {
              setReplyDetails(value);
              setIsUploadVideo(!isUploadVideo);
            }}
            sx={{
              textTransform: 'capitalize',
              textDecoration: 'underLine',
              color: '#0040BC',
              fontSize: '13.4px',
              fontWeight: '400',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          >
            View
          </Box>
        </Stack>
      ),
    },
  ];

  const getRows = () => {
    const data: any = studentRepliesList?.map((obj: any) => ({
      name: obj?.user || {},
      profileImage: obj?.user?.profileImage || '',
      date: obj?.createdAt ? obj?.createdAt : '',
      user_created: obj?.createdBy || '',
      sub_topic: obj?.subTopic || {},
      grade: obj?.grade?.name || '',
      skill: obj?.skill?.name || '',
      student_replies: obj || {},
      response: obj,
    }));
    return data;
  };

  const tableData = {
    columns: columns,
    rows: getRows(),
  };

  const fetchData = useCallback(({ pageSize, pageIndex, search }: any) => {
    const fetchId = ++fetchIdRef.current;

    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        setPageNo(pageIndex);
        setPageSize(pageSize);
        setSearch(search);
        dispatch(getStudentReplyList({ pageSize, pageIndex, search, id }));
      }
    }, 1000);
  }, []);

  return (
    <Box>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        style={{ marginBottom: 20 }}
      >
        {breadcrumbs}
      </Breadcrumbs>
      <CustomTable
        table={tableData}
        fetchData={fetchData}
        canSearch
        title={'Title'}
        // buttonTitle="Create Daily Challange"
        searchText="Search..."
        pageCount={pageCount}
        pageNo={pageNo}
        totalCount={totalCount}
        handleOnChange={handleAddUpdateChallenges}
      />
      <AddVideo
        open={isUploadVideo}
        onClose={handleCancel}
        data={replyDetails}
        title={'title'}
      />
    </Box>
  );
}

export default Index;
