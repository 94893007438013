import React from "react";
import { Box, Button, Typography } from "@mui/material";

const MyScheduledCard = ({ index }: any) => {
  return (
    <Box sx={{ border: "dotted 2px #20A58A", borderRadius: "8px" }}>
      <Box m={4}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Typography
            component={"p"}
            className="title_text"
            sx={{ color: "#110C1D" }}
          >
            Mon July 22 2:45-4:40
          </Typography>
          <Button
            variant="contained"
            className={index === 0 ? "primary_bg_btn" : "dark_gray_bg_btn"}
            sx={{ minWidth: "100px" }}
          >
            {index === 0 ? "Join live" : "Register"}
          </Button>
        </Box>
        <Typography
          component={"p"}
          className="subtitle_text"
          sx={{ color: "#979C9E" }}
        >
          Fractions, Mixed Numbers
        </Typography>
        <Typography
          component={"p"}
          className="subtitle_text"
          sx={{ color: "#979C9E" }}
        >
          Lorem ipsum is a placeholder text commonly used to
        </Typography>
      </Box>
    </Box>
  );
};

export default MyScheduledCard;
