// *** React Imports
import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  TextField,
  Autocomplete,
  CircularProgress,
  InputLabel,
  createFilterOptions,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  Stack,
} from '@mui/material';

// *** Redux Imports
import { useAppDispatch, useAppSelector } from 'store/store';
import {
  getTopicList,
  getSubTopicList,
  getSkillList,
  getGradeList,
  getMuxUploadUrl,
  saveUploadVideo,
  getAssetList,
} from 'store/Thunk/videoThunk';

// *** Custom Components
import FileUpload from 'components/fileUpload';

// *** Icons
import { EditWithColorIcon } from 'icons';

import MDDialog from '@core/components/MDDialog';

// *** Assets

import Validations from 'helper/validations';

// *** Third Party Imports
import { useLocation, useNavigate } from 'react-router-dom';
import { setLoading } from 'store/Slice/videoSlice';
import { getAuthUser, getOptionLabel } from 'helper/service';
import { getDomainList } from 'store/Thunk/userThunk';

import AddSkill from 'components/dialog/skill/addSkill';
import AddSubTopic from 'components/dialog/subtopic/addSubTopic';
import { notificationFail } from 'store/Slice/notificationSlice';

import SelectDistrictAndSchool from 'components/selectDistrictAndSchool';

interface Props {
  handleCancel?: any;
}

const filter = createFilterOptions();

function Index({ open, onClose }: any) {
  const dispatch: any = useAppDispatch();
  const {
    topicList,
    subtopicList,
    skillList,
    gradeList,
    uploadUrl,

    assetList,

    loading,
  } = useAppSelector((state: any) => state.videoReducer);
  console.log('topicList', topicList);
  const { userList } = useAppSelector((state: any) => state.userReducer);
  const { domainList } = useAppSelector((state: any) => state.userReducer);

  const [uploadedFile, setUploadedFile]: any = useState<any[]>([]);
  const { schoolList } = useAppSelector((state: any) => state.schoolReducer);

  const [formData, setFormData] = useState<any>({
    grade: [],
    skill: [],
    school: [],
  });
  useEffect(() => {
    setFormData({ ...formData, school: schoolList });
  }, [schoolList]);

  const [formError, setFormError] = useState<any>({});
  const [loadingTopic, setLoadingTopic] = React.useState(false);
  const [loadingDomain, setLoadingDomain] = React.useState(false);
  const [loadingSubTopic, setLoadingSubTopic] = React.useState(false);
  const [loadingGrade, setLoadingGrade] = React.useState(false);
  const [loadingSkill, setLoadingSkill] = React.useState(false);
  const [loadingUser, setLoadingUser] = React.useState(false);
  const [openTopic, setOpenTopic] = React.useState(false);
  const [openSubTopic, setOpenSubTopic] = React.useState(false);
  const [openGrade, setOpenGrade] = React.useState(false);
  const [openSkill, setOpenSkill] = React.useState(false);
  const [openUser, setOpenUser] = React.useState(false);
  const [topic, setTopic] = React.useState<any[]>([]);
  const [subTopic, setSubTopic] = React.useState<any[]>([]);
  const [grade, setGrade] = React.useState<any[]>([]);
  const [skill, setSkill] = React.useState<any[]>([]);
  const [user, setUser] = React.useState<any[]>([]);
  const [openVideo, setOpenVideo] = React.useState(false);
  const [openSubTopicDialog, setOpenSubTopicDialog] = React.useState(false);
  const [newSubTopicValue, setNewSubTopicValue] = React.useState({});
  const [openSkillDialog, setOpenSkillDialog] = React.useState(false);
  const [newSkillValue, setNewSkillValue] = React.useState({});
  const [loadingVideo, setLoadingVideo] = React.useState(false);
  const [videoProgress, setVideoProgress] = React.useState(0);
  const [value, setValue] = React.useState('upload');
  const [video, setVideo] = React.useState<any[]>([]);
  let location = useLocation();
  const isAcademic = !location?.pathname.includes('fun');
  const handleVideo = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };
  const funTopic = topic?.filter((item) =>
    item?.name?.toLowerCase()?.includes('fun'),
);
const academicTopic = topic?.filter(
  (item) => !item?.name?.toLowerCase()?.includes('fun'),
);
console.log('topic', topic);
console.log('topic', funTopic);
console.log('topic', academicTopic);

  const authUser = getAuthUser();

  useEffect(() => {
    if (authUser) {
      setUser([{ ...authUser }]);
    }
  }, []);
  // useEffect(() => {
  //   dispatch(getUserList())
  // },[])
  const [openDomain, setOpenDomain] = React.useState(false);

  useEffect(() => {
    dispatch(getAssetList({ pageIndex: 0, pageSize: 2000, search: '' }));
    setLoadingVideo(openVideo);
  }, []);

  useEffect(() => {
    if (openDomain) {
      dispatch(getDomainList(''));
      setLoadingDomain(openDomain);
    } else {
      setLoadingDomain(openDomain);
    }
  }, [openDomain]);
  useEffect(() => {
    setLoadingVideo(false);
    setVideo(assetList || []);
  }, [assetList]);

  useEffect(() => {
    if (openTopic) {
      dispatch(getTopicList());
      setLoadingTopic(openTopic);
    } else {
      setTopic([]);
    }
  }, [openTopic]);

  useEffect(() => {
    if (formData?.topic?.id && openSubTopic) {
      dispatch(getSubTopicList({ topic: formData?.topic }));
      setLoadingSubTopic(openSubTopic);
    } else {
      setSubTopic([]);
    }
  }, [openSubTopic, formData?.topic]);

  useEffect(() => {
    if (openGrade) {
      dispatch(getGradeList());
      setLoadingGrade(openGrade);
    } else {
      setGrade([]);
    }
  }, [openGrade]);

  useEffect(() => {
    if (formData?.sub_topic?.id && openSkill) {
      dispatch(getSkillList({ subTopic: formData?.sub_topic }));
      setLoadingSkill(openSkill);
    } else {
      setSkill([]);
    }
  }, [openSkill, formData?.sub_topic]);

  useEffect(() => {
    setLoadingTopic(false);
    setTopic(topicList);
  }, [topicList]);

  useEffect(() => {
    setLoadingSubTopic(false);
    setSubTopic(subtopicList);
  }, [subtopicList]);

  useEffect(() => {
    setLoadingGrade(false);
    setGrade(gradeList || []);
  }, [gradeList]);

  useEffect(() => {
    setLoadingSkill(false);
    setSkill(skillList);
  }, [skillList]);

  useEffect(() => {
    setLoadingUser(false);
    setUser(userList);
  }, [userList]);

  useEffect(() => {
    setFormError({ ...formError, videoFile: '' });
  }, [uploadedFile]);

  let UpChunk: any = null;
  if (typeof window !== 'undefined') {
    UpChunk = require('@mux/upchunk');
  }

  useEffect(() => {
    // dispatch(getUserList())
    dispatch(getMuxUploadUrl());
  }, []);

  /* useEffect(() => {
    if (formData?.topic?.id) {
      dispatch(getSubTopicList({ topic: formData.topic }))
    } else {
      dispatch(setSubTopicList([]))
    }
  }, [formData.topic]) */

  /* useEffect(() => {
    if (formData?.topic?.id && formData?.sub_topic?.id) {
      dispatch(getGradeList())
      dispatch(getSkillList({ subTopic: formData?.sub_topic }))
    } else {
      dispatch(setSkillList([]))
      dispatch(setGradeList([]))
    }
  },[formData.sub_topic]) */
  const handleChangeAutocomplete =
    (name: string) =>
    (
      event: React.SyntheticEvent<Element, Event>,
      newValue: string | null | any,
    ) => {
      let param = {
        [name]: newValue,
        ...(name === 'topic' && {
          sub_topic: '',
          skill: '',
        }),
        ...(name === 'sub_topic' && {
          skill: '',
        }),
        ...(name === 'district' && {
          school: '',
        }),
      };
      setFormData({ ...formData, ...param });
    };

  // Autocomplete onChange method for select and create a skill options
  const handleChangeCreateSkill = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string | null | any,
  ) => {
    if (typeof newValue === 'string') {
      // timeout to avoid instant validation of the dialog's form.
      setTimeout(() => {
        handleSkillDialog(newValue);
      });
    } else if (newValue && newValue.inputValue) {
      handleSkillDialog(newValue.inputValue);
    } else {
      setFormData({
        ...formData,
        skill: newValue,
      });
    }
  };

  const handleFilterSkill = (options: any, params: any) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options?.some(
      (option: any) => inputValue === option.name,
    );
    if (inputValue !== '' && !isExisting && formData?.sub_topic) {
      filtered.push({
        inputValue,
        name: `Add "${inputValue}"`,
      });
    }

    return filtered;
  };

  // Autocomplete onChange method for select and create a options
  const handleChangeCreateSubTopic = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string | null | any,
  ) => {
    if (typeof newValue === 'string') {
      // timeout to avoid instant validation of the dialog's form.
      setTimeout(() => {
        handleSubTopicDialog(newValue);
      });
    } else if (newValue && newValue.inputValue) {
      handleSubTopicDialog(newValue.inputValue);
    } else {
      setFormData({
        ...formData,
        sub_topic: newValue,
      });
    }
  };

  console.log('formData', formData);
  const handleFilterSubTopic = (options: any, params: any) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options?.some(
      (option: any) => inputValue === option.name,
    );
    if (inputValue !== '' && !isExisting && formData?.topic) {
      filtered.push({
        inputValue,
        name: `Add "${inputValue}"`,
      });
    }

    return filtered;
  };

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSkillDialog = (name: string = '') => {
    if (!formData.topic && !formData.sub_topic) {
      dispatch(notificationFail('Please Select Topic and Sub Topic First'));
      return;
    }
    if (!formData.topic || !formData.sub_topic) {
      const message = !formData.topic
        ? 'Please Select Topic First'
        : 'Please Select SubTopic First';

      dispatch(notificationFail(message));
      return;
    }

    setNewSkillValue({
      ...(!openSkillDialog
        ? {
            topic: formData.topic,
            name: name || '',
            subTopic: formData.sub_topic,
          }
        : {}),
    });
    setOpenSkillDialog(!openSkillDialog);
  };

  const handleSubTopicDialog = (name: string = '') => {
    if (!formData.topic) {
      dispatch(notificationFail('Please Select Topic First'));
      return;
    }
    setNewSubTopicValue({
      ...(!openSubTopicDialog
        ? {
            topic: formData.topic,
            name: name,
          }
        : {}),
    });

    setOpenSubTopicDialog(!openSubTopicDialog);
  };
  console.log('formData', formData);
  console.log('formData', formError);
  const onSave = (event: any) => {
    event.preventDefault();
    let _request: any = {
      id: formData?.id || '',
      title: formData.title?.trim(),
      districtIds:
        (Array.isArray(formData?.district) &&
          formData?.district?.map((item: any) => item.id)) ||
        [],
      schoolIds:
        Array.isArray(formData?.school) && formData?.school?.length > 0
          ? formData?.school.map((item: any) => item.id)
          : [],
      topicId: formData.topic?.id || '',
      subTopicId: formData.sub_topic?.id || '',
      skillId: formData?.skill?.id || '',
      userId: authUser?.id || '',
      gradeIds: formData?.grade.map((grade: any) => grade?.id),
      videoFile: uploadedFile,
      uploadId: uploadUrl?.id || '',
      assetId: formData?.assetId,
      domainId: formData?.domain?.id,
      isAcademic: isAcademic,
    };

    if (value === 'select') {
      delete _request?.videoFile;
      setUploadedFile(null);
    } else {
      delete _request?.assetId;
    }

    let allError = Validations.validateVideoForm(_request, isAcademic);
    console.log('formData', Object.entries(allError).length);

    if (
      Object.entries(allError).length === 0 &&
      allError.constructor === Object
    ) {
      dispatch(setLoading(true));
      if (uploadUrl?.url && uploadedFile && !(value === 'select')) {
        const muxUpload = UpChunk.createUpload({
          endpoint: uploadUrl?.url,
          file: uploadedFile[0],
          chunkSize: 5120, // Uploads the file in ~5mb chunks
        });

        // subscribe to events
        muxUpload.on('error', (error: any) => {
          console.error('💥 🙀', error.detail);
          setVideoProgress(0);
          dispatch(setLoading(false));
        });

        muxUpload.on('progress', (progress: any) => {
          setVideoProgress(progress?.detail || 0);
        });

        muxUpload.on('success', () => {
          dispatch(saveUploadVideo(_request));
          onClose();
        });
      } else {
        dispatch(saveUploadVideo(_request));
        onClose();
      }
    } else {
      setFormError(allError);
    }
  };

  const dialogProps = {
    open,
    onClose,
    onSave,
    title: `Upload Video`,
    size: 'md',
    saveTbtText: 'Upload Video',
    loading: loading,
  };

  return (
    <>
      <MDDialog {...dialogProps}>
        <Box p={4}>
          <Box>
            <Grid container spacing={7} mb={5}>
              <Grid item xs={4}>
                <InputLabel sx={{ color: '#484848', pb: 2 }}>Title</InputLabel>
                <TextField
                  sx={{ width: '100%' }}
                  placeholder="Title"
                  name="title"
                  onChange={handleChange}
                  value={formData?.title || ''}
                  error={formError && formError?.title}
                  helperText={
                    formError && formError?.title ? formError.title : ''
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel disabled sx={{ color: '#484848', pb: 2 }}>
                  User Created
                </InputLabel>
                <Autocomplete
                  id="asynchronous-demo"
                  open={openUser}
                  onOpen={() => setOpenUser(true)}
                  onClose={() => setOpenUser(false)}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                  getOptionLabel={(option) => option?.email || ''}
                  options={user}
                  loading={loadingUser}
                  disabled
                  value={authUser}
                  onChange={handleChangeAutocomplete('user')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select User"
                      error={formError && formError?.user}
                      helperText={
                        formError && formError?.user ? formError.user : ''
                      }
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingUser ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel sx={{ color: '#484848', pb: 2 }}>Grade</InputLabel>
                <Autocomplete
                  multiple
                  id="asynchronous-demo"
                  open={openGrade}
                  onOpen={() => setOpenGrade(true)}
                  onClose={() => setOpenGrade(false)}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                  getOptionLabel={(option) => option?.name || ''}
                  options={grade}
                  loading={loadingGrade}
                  value={formData?.grade || []}
                  disableCloseOnSelect
                  onChange={handleChangeAutocomplete('grade')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Grade"
                      error={formError && formError?.grade}
                      helperText={
                        formError && formError?.grade ? formError.grade : ''
                      }
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingGrade ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <SelectDistrictAndSchool
                state={formData}
                setState={setFormData}
                formError={formError}
              />
              <Grid item xs={4}>
                <InputLabel sx={{ color: '#484848', pb: 2 }}>Topic</InputLabel>
                <Autocomplete
                  id="asynchronous-demo"
                  open={openTopic}
                  onOpen={() => setOpenTopic(true)}
                  onClose={() => setOpenTopic(false)}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                  getOptionLabel={(option) => option?.name || ''}
                  options={isAcademic ? academicTopic : funTopic}
                  loading={loadingTopic}
                  filterSelectedOptions
                  value={formData?.topic || {}}
                  onChange={handleChangeAutocomplete('topic')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Topic"
                      error={formError && formError?.topic}
                      helperText={
                        formError && formError?.topic ? formError.topic : ''
                      }
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingTopic ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              {isAcademic && (
                <Grid item xs={4}>
                  <InputLabel sx={{ color: '#484848', pb: 2 }}>
                    Domain
                  </InputLabel>
                  <Autocomplete
                    id="asynchronous-demo"
                    open={openDomain}
                    onOpen={() => setOpenDomain(true)}
                    onClose={() => setOpenDomain(false)}
                    isOptionEqualToValue={(option, value) =>
                      option?.name === value?.name
                    }
                    getOptionLabel={(option) => option?.name || ''}
                    options={domainList?.length > 0 ? domainList : []}
                    loading={loadingDomain}
                    filterSelectedOptions
                    value={formData?.domain || {}}
                    onChange={handleChangeAutocomplete('domain')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Domain"
                        error={formError && formError?.domain}
                        helperText={
                          formError && formError?.domain ? formError.domain : ''
                        }
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingDomain ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
              {isAcademic && (
                <Grid item xs={12}>
                  <Stack direction="row" gap="10px" textAlign="center">
                    <InputLabel sx={{ color: '#484848', pb: 2 }}>
                      Sub-Topic -
                    </InputLabel>
                    <Typography
                      fontSize="14px"
                      color="secondary"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => handleSubTopicDialog('')}
                    >
                      Add new
                    </Typography>
                  </Stack>
                  <Autocomplete
                    id="asynchronous-demo"
                    open={openSubTopic}
                    onOpen={() => setOpenSubTopic(true)}
                    onClose={() => setOpenSubTopic(false)}
                    isOptionEqualToValue={(option, value) =>
                      option.name === value.name
                    }
                    getOptionLabel={getOptionLabel}
                    options={subTopic}
                    loading={loadingSubTopic}
                    value={formData?.sub_topic || {}}
                    onChange={handleChangeCreateSubTopic}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Sub topic"
                        error={formError && formError?.sub_topic}
                        helperText={
                          formError && formError?.sub_topic
                            ? formError.sub_topic
                            : ''
                        }
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingSubTopic ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                    filterOptions={handleFilterSubTopic}
                  />
                </Grid>
              )}
              {isAcademic && (
                <Grid item xs={12}>
                  <Stack direction="row" gap="10px" textAlign="center">
                    <InputLabel sx={{ color: '#484848', pb: 2 }}>
                      Skill -
                    </InputLabel>
                    <Typography
                      fontSize="14px"
                      color="secondary"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => handleSkillDialog('')}
                    >
                      Add new
                    </Typography>
                  </Stack>
                  <Autocomplete
                    id="asynchronous-demo"
                    open={openSkill}
                    onOpen={() => setOpenSkill(true)}
                    onClose={() => setOpenSkill(false)}
                    isOptionEqualToValue={(option, value) =>
                      option.name === value.name
                    }
                    getOptionLabel={getOptionLabel}
                    options={skill}
                    loading={loadingSkill}
                    value={formData?.skill || {}}
                    onChange={handleChangeCreateSkill}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Skill"
                        error={formError && formError?.skill}
                        helperText={
                          formError && formError?.skill ? formError.skill : ''
                        }
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingSkill ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                    filterOptions={handleFilterSkill}
                  />
                </Grid>
              )}
              {/* <Grid item xs={12}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Upload video
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={value}
                    onChange={handleVideo}
                  >
                    <FormControlLabel
                      value="select"
                      control={<Radio />}
                      label="Select existing videos"
                    />
                    <FormControlLabel
                      value="upload"
                      control={<Radio />}
                      label="Upload"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid> */}
              {/* {value == 'select' && (
                <Grid item xs={12}>
                  <InputLabel
                    // error={formError && formError?.video}
                    sx={{ color: '#484848', pb: 2 }}
                  >
                    Asset ID
                  </InputLabel>
                  <Autocomplete
                    id="asynchronous-demo"
                    open={openVideo}
                    onOpen={() => setOpenVideo(true)}
                    onClose={() => setOpenVideo(false)}
                    isOptionEqualToValue={(option, value) => option === value}
                    getOptionLabel={(option) => option || ''}
                    options={video}
                    loading={loadingVideo}
                    value={formData?.assetId || []}
                    onChange={handleChangeAutocomplete('assetId')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={formError && formError?.assetId}
                        helperText={
                          formError && formError?.assetId
                            ? formError.assetId
                            : ''
                        }
                        placeholder="Select Video"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingVideo ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              )} */}
              {value === 'upload' && (
                <Grid item xs={12}>
                  <FileUpload
                    videoProgress={videoProgress}
                    uploadedFile={uploadedFile}
                    setUploadedFile={setUploadedFile}
                    errorText={formError?.videoFile || ''}
                  />
                </Grid>
              )}
            </Grid>

            {openSkillDialog && (
              <AddSkill
                open={openSkillDialog}
                onClose={() => setOpenSkillDialog(false)}
                skill={newSkillValue}
                setParentFormData={setFormData}
              />
            )}
            {openSubTopicDialog && (
              <AddSubTopic
                open={openSubTopicDialog}
                onClose={handleSubTopicDialog}
                subTopic={newSubTopicValue}
                setParentFormData={setFormData}
              />
            )}
          </Box>
          {/* <Box justifyContent={"flex-end"} display={"flex"}>
        <Button
          variant="contained"
          sx={{ ml: 3, bgcolor: "#D9D9D9", color: "#000000", width: "163px" }}
          onClick={handleCancelBtn}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          className="primary_bg_btn"
          sx={{ ml: 3, width: "163px" }}
          onClick={handleSaveBtn}
        >
          {screenLoading ? (<CircularProgress size={"20px"} sx={{ color: 'white' }} />) : saveTitle}
        </Button>
      </Box> */}
        </Box>
      </MDDialog>
    </>
  );
}

export default Index;
