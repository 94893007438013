import React from "react";

export default function BackButtonWithGreyBgIcon() {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="44" rx="22" fill="#D9D9D9" />
      <path
        d="M22 30L14 22L22 14L23.05 15.05L16.85 21.25H30V22.75H16.85L23.05 28.95L22 30Z"
        fill="black"
      />
    </svg>
  );
}
