import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Button,
  Grid,
  Paper,
  TextField,
  InputLabel,
  SelectChangeEvent,
  Autocomplete,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import MediaQuestion from 'components/Question/MediaQuestion';
import QuestionDialog from 'components/dialog/question/AddQuestion';

// *** Routes
import { route_info } from 'navigation/route';

// *** Third Party Imports
import { useNavigate, useParams } from 'react-router-dom';

// *** Custom Components

// *** Icons
import EditWithBlackIcon from 'icons/editWithBlack';
import EditWithColor from 'icons/editWithColor';
import DeleteIcon from 'icons/delete';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

//*** React-dnd
import DragableOption from './DragableOptions';
import { RootState, useAppDispatch, useAppSelector } from 'store/store';
import {
  createQuestion,
  DeleteQuestion,
  getQuestionList,
} from 'store/Thunk/questionThunk';
import Validations from 'helper/validations';
import { setLoginUser } from 'store/Slice/authSlice';
import { border } from '@mui/system';
import { setGeneratedQuestions } from 'store/Slice/QuestionSlice';
import { EditBlackNoBg, SaveIcon } from 'icons';

const blankFormData = {
  type: 'text',
  level: 'EASY',
  message: '',
  explanation: '',
  question: '',
  answer: '',
  optionA: '',
  optionB: '',
  optionC: '',
  optionD: '',
  optionAMedia: null,
  optionBMedia: null,
  optionCMedia: null,
  optionDMedia: null,
  questionMedia: null,
  createdById: '',
  templateIds: [''],
};

const CurrentQuestionEdit = (props: any) => {
  const dispatch = useAppDispatch();
  const [formError, setFormError] = useState<any>([]);

  const { user } = useAppSelector((state: RootState) => state.authReducer);
  const { navItems } = useAppSelector((state: any) => state.navItemReducer);
  const questionLevel = ['EASY', 'MEDIUM', 'HARD'];
  const questionType = [
    'text',
    'image',
    'audio',
    'video',
    'math',
    'passage',
    'boolean',
  ];
  const [openAssignTo, setOpenAssignTo] = useState(false);
  const [openLevel, setOpenLevel] = useState(false);
  const [loadingGrade, setLoadingGrade] = useState(false);

  useEffect(() => {
    const userData = localStorage.getItem('userData');
    if (userData) {
      dispatch(setLoginUser(JSON.parse(userData)));
    }
  }, []);
  const handleChangeAutocomplete =
    (name: string) =>
    (
      event: React.SyntheticEvent<Element, Event>,
      newValue: string | null | any,
    ) => {
      let param = {
        [name]: newValue,
      };
      setFormData({ ...formData, ...param });
    };
  const { id, formData, setFormData } = props;
  console.log('formData', formData);
  const addQuestion = (event: any) => {
    // const [open,setOpen] = useState(false)
    event.preventDefault();
    let allError = Validations?.ValidateQuestionForm(formData);
    if (
      Object.entries(allError).length === 0 &&
      allError.constructor === Object
    ) {
      let _request: any = {
        type: props?.formData?.type || '',
        level: props?.formData?.level || '',
        question: props.formData?.question || '',
        message: props.formData?.message || '',
        explanation: props.formData?.explanation || '',
        answer: props.formData?.answer || '',
        optionA: props.formData?.optionA || '',
        optionB: props.formData?.optionB || '',
        optionC: props.formData?.optionC || '',
        optionD: props.formData?.optionD || '',
        optionAMedia: null,
        optionBMedia: null,
        optionCMedia: null,
        optionDMedia: null,
        questionMedia: null,
        createdById: user?.id,
        templateId: id,
      };

      let _update: any = {
        ..._request,
        id: props.formData?.id || '',
        templateIds: props?.formData?.templates?.map((item: any) => {
          return item?.id;
        }),
      };
      dispatch(
        createQuestion({
          _request: props.formData?.id ? _update : [_request],
          callback: () => {
            dispatch(
              getQuestionList({
                templateId: id,
              }),
            );
            setFormError([]);
            props.setFormData({
              ...blankFormData,
            });
            const filteredGenerated = props.generatedQuestionsList.filter(
              (item: any) => item.questionId !== formData.questionId,
            );
            props.setgeneratedQuestionsList(filteredGenerated);
            props.generatedQuestionsList?.length === 1 &&
              dispatch(setGeneratedQuestions([]));
          },
        }),
      );
    } else {
      setFormError(allError);
    }
  };

  const handleChangeInput = (event: React.ChangeEvent<any>) => {
    setFormData({
      ...props?.formData,
      [event.target.name]: event.target.value,
    });
  };
  // const handleFileChange = (event:React.ChangeEvent<any>) => {
  //   const file = event.target.files[0];
  //   setFormData({
  //     ...props?.formData,
  //     [event.target.name]: file,
  //   });
  // };

  const handleChangeAnswer = (answer: any) => {
    setFormData({ ...props.formData, answer: answer.toLowerCase() });
  };

  const handleDragChanges = (data: any) => {
    setFormData({
      ...props.formData,
      optionA: data[0]?.content,
      optionB: data[1]?.content,
      optionC: data[2]?.content,
      optionD: data[3]?.content,
    });
  };

  return (
    <>
      <Box className="flex gap-4">
        <Box className="flex flex-1 flex-col">
          <InputLabel
            error={formError && formError?.message}
            sx={{ color: '#484848', pb: 2 }}
          >
            <Typography>Message</Typography>
          </InputLabel>
          <TextField
            required
            fullWidth
            id="message"
            value={formData?.message || ''}
            onChange={handleChangeInput}
            placeholder="Write your question message"
            name="message"
            error={formError && formError?.message}
            helperText={
              formError && formError?.message ? formError.message : ''
            }
            // sx={{ input: { textAlign: 'center' } }}
          />
        </Box>
        <Box className="flex flex-1 flex-col">
          <InputLabel
            error={formError && formError?.type}
            sx={{ color: '#484848', pb: 2 }}
          >
            <Typography>Question Type</Typography>
          </InputLabel>
          <Box className="flex-1">
            <Autocomplete
              id="grade"
              open={openAssignTo}
              onOpen={() => setOpenAssignTo(true)}
              onClose={() => setOpenAssignTo(false)}
              isOptionEqualToValue={(option, value) => option === value}
              getOptionLabel={(option) =>
                option ? option.charAt(0).toUpperCase() + option.slice(1) : ''
              }
              options={questionType}
              filterSelectedOptions
              // disabled={editContentInfo && true}
              value={formData?.type || {}}
              onChange={handleChangeAutocomplete('type')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select"
                  error={formError && formError?.type}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingGrade ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Box>
        </Box>
        <Box className="flex flex-1 flex-col">
          <InputLabel
            error={formError && formError?.level}
            sx={{ color: '#484848', pb: 2 }}
          >
            <Typography>Question Level</Typography>
          </InputLabel>
          <Box className="flex-1">
            <Autocomplete
              id="grade"
              open={openLevel}
              onOpen={() => setOpenLevel(true)}
              onClose={() => setOpenLevel(false)}
              isOptionEqualToValue={(option, value) => option === value}
              getOptionLabel={(option) => option || ''}
              options={questionLevel}
              filterSelectedOptions
              // disabled={editContentInfo && true}
              value={formData?.level || ''}
              onChange={handleChangeAutocomplete('level')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select"
                  error={formError && formError?.userIds}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingGrade ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ m: '4px 0', mt: 4 }}>
        <Typography>{'Question'}</Typography>
      </Box>
      <Box sx={{ mb: 1 }}>
        <TextField
          variant="outlined"
          value={formData?.question}
          fullWidth
          name="question"
          onChange={handleChangeInput}
          size="medium"
          placeholder="Write your question"
          error={formError && formError?.question}
          id="outlined-error-helper-text"
          helperText={
            formError && formError?.question ? formError?.question : ''
          }
        />
        {/* <TextField
        type="file"
        label="Upload Image"
        onChange={handleFileChange}
        InputLabelProps={{
          shrink: true,
        }}
      /> */}
      </Box>
      {formData?.type === 'passage' && (
        <Box sx={{ mt: 4 }}>
          <Typography>{'Passage'}</Typography>
        </Box>
      )}
      {formData?.type === 'passage' && (
        <Box sx={{ mb: 1 }}>
          <textarea
            placeholder="Passage"
            className="input-questions"
            name="passage"
            value={formData?.passage}
            onChange={handleChangeInput}
            style={{
              border: '1px solid #e5e7eb',
              borderRadius: '10px',
              padding: '14px',
              width: '100%',
            }}
          ></textarea>

          {/* <TextField
        type="file"
        label="Upload Image"
        onChange={handleFileChange}
        InputLabelProps={{
          shrink: true,
        }}
      /> */}
        </Box>
      )}
      <Box>
        <DragableOption
          options={props?.formData}
          handleChange={handleChangeInput}
          handleChangeAnswer={handleChangeAnswer}
          handleDragChanges={handleDragChanges}
        />
        <Typography
          sx={{ color: '#FF3333', fontSize: '0.75rem', fontWeight: '400' }}
        >
          {formError?.answer && 'Select the correct answer'}
          {(formError?.optionA ||
            formError?.optionB ||
            formError?.optionC ||
            formError?.optionD) &&
            'All four options are required'}
        </Typography>
      </Box>
      <Box sx={{ m: '4px 0', mt: 4 }}>
        <Typography>{'Explanation:'}</Typography>
      </Box>
      <Box sx={{ mb: 1 }}>
        <textarea
          placeholder="Explanation"
          className="input-questions"
          name="explanation"
          value={formData?.explanation}
          onChange={handleChangeInput}
          style={{
            border:
              formError && formError.explanation
                ? '1px solid red'
                : '1px solid #e5e7eb',
            borderRadius: '10px',
            padding: '14px',
            width: '100%',
          }}
        ></textarea>
        {/* <TextField
          variant="outlined"
          value={formData?.explanation}
          fullWidth
          name="passage"
          onChange={handleChangeInput}
          size="medium"
          placeholder="Explanation"
          error={formError && formError?.explanation}
          id="outlined-error-helper-text"
          helperText={
            formError && formError?.explanation ? formError?.explanation : ''
          }
        /> */}
        {/* <TextField
        type="file"
        label="Upload Image"
        onChange={handleFileChange}
        InputLabelProps={{
          shrink: true,
        }}
      /> */}
      </Box>
      <Box sx={{ p: '12px 0' }} className="flex gap-8">
        {props?.formData?.id ? (
          <Button
            onClick={(e: any) => {
              addQuestion(e);
            }}
            sx={{
              backgroundColor: '#C73E9D',
              color: '#fff',
              '&:hover': {
                backgroundColor: '#db4fb0 !important',
              },
            }}
          >
            Update
          </Button>
        ) : (
          <Button
            onClick={(e: any) => {
              addQuestion(e);
            }}
            sx={{
              backgroundColor: '#C73E9D',
              color: '#fff',
              '&:hover': {
                backgroundColor: '#db4fb0 !important',
              },
            }}
          >
            Save
          </Button>
        )}
        {
          <Button
            disabled={!props.formData?.index}
            variant="contained"
            className="green_bg_btn_bold"
            onClick={() => {
              props.handleDeleteDialog('12');
            }}
          >
            Delete
          </Button>
        }
      </Box>
    </>
  );
};
interface AddQuestionProps {
  dialogOpen: boolean;
  handleOpenDialog: any;
  handleDeleteDialog: any;
  handleCloseDialog: any;
  isAddQuest?: boolean;
  generatedQuestionsList?: any;
  setgeneratedQuestionsList?: any;
  quizTemplateData: any;
  formData: any;
  setCurrentQuestionIndex: any;
  setFormData: any;
  updatedQuestionArray: any;
  questionType: string;
  setDeleteId: any;
  setIsOpen: any;

  handleQuestionTypeChange: (event: SelectChangeEvent<string>) => void;
  questionMessage: string;
  handleQuestionMessageChange: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
}
function AddQuestion({
  setIsOpen,
  handleDeleteDialog,
  dialogOpen,
  generatedQuestionsList,
  updatedQuestionArray,
  setgeneratedQuestionsList,
  handleOpenDialog,
  handleCloseDialog,
  quizTemplateData,
  questionType,
  setDeleteId,
  handleQuestionMessageChange,
  questionMessage,
  handleQuestionTypeChange,
  formData,
  setFormData,
  setCurrentQuestionIndex,
}: AddQuestionProps) {
  // console.log(questionType,'the questiontype here')
  const dispatch = useAppDispatch();
  const navigate: any = useNavigate();
  const [confirmDelete, setConfirmDelete] = useState<Boolean>(false);
  const { user } = useAppSelector((state: RootState) => state.authReducer);

  useEffect(() => {
    formData.id && setDeleteId(formData.id);
  }, [formData.id]);
  const { id } = useParams();
  const handleEdit = (item: any, index: number) => {
    setFormData(item);
    setCurrentQuestionIndex(index + 1);
  };
  const handleBackButton = () => {
    navigate(route_info.content_management.quiz_template);
  };
  // const [dialogOpen, setDialogOpen] = useState(false);

  const handleNewQuestion = () => {
    // handleOpenDialog();
    setFormData({
      ...blankFormData,
      message: questionMessage ? questionMessage : '',
      type: questionType ? questionType : '',
    });
  };
  const handleCloseQuestionDialog = () => {
    handleCloseDialog();
    setFormData({ ...formData, message: questionMessage, type: questionType });
  };
  const addQuestion = (item: any) => {
    // const [open,setOpen] = useState(false)
    // setFormData(item);
    try {
      let _request: any = {
        type: item?.type || '',
        level: item?.level || '',
        question: item?.question || '',
        message: item?.message || '',
        explanation: item?.explanation || '',
        answer: item?.answer || '',
        optionA: item?.optionA || '',
        optionB: item?.optionB || '',
        optionC: item?.optionC || '',
        optionD: item?.optionD || '',
        optionAMedia: null,
        optionBMedia: null,
        optionCMedia: null,
        optionDMedia: null,
        questionMedia: null,
        createdById: user?.id,
        templateId: id,
      };

      let _update: any = {
        ..._request,
        id: formData?.id || '',
        templateIds: formData?.templates?.map((item: any) => {
          return item?.id;
        }),
      };
      dispatch(
        createQuestion({
          _request: formData?.id ? _update : [_request],
          callback: () => {
            dispatch(
              getQuestionList({
                templateId: id,
              }),
            );
            setFormData({
              ...blankFormData,
            });
            const filteredGenerated = generatedQuestionsList.filter(
              (gen: any) => gen.questionId !== item.questionId,
            );
            setgeneratedQuestionsList(filteredGenerated);
            generatedQuestionsList?.length === 1 &&
              dispatch(setGeneratedQuestions([]));
          },
        }),
      );
    } catch (error) {
      console.log(error);
    }
  };
  console.log('quizTemplateData', quizTemplateData);
  return (
    <Box height={'100%'} pt={22} px={16}>
      <Grid container spacing={3}>
        <Grid
          item
          md={4}
          xs={12}
          sx={{
            backgroundColor: '#f9f9f9',
            height: '540px',
            width: '382px',
          }}
        >
          <Paper elevation={3} sx={{ height: '100%', borderRadius: '6px' }}>
            <Box sx={{ p: '24px 16px' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: '18px',
                      fontWeight: '600',
                      textTransform: 'capitalize',
                    }}
                  >
                    Assessment: {quizTemplateData}
                  </Typography>
                </Box>
                <Box>
                  <IconButton onClick={() => setIsOpen(true)}>
                    <EditWithBlackIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                border: '#DCDCDC 1px solid',
                borderRadius: '8px',
                m: '24px 16px',
              }}
            >
              <Box
                sx={{
                  backgroundColor: '#DCDCDC',
                  textAlign: 'left',
                  p: '8px 16px',
                  borderTopLeftRadius: '8px',
                  borderTopRightRadiusRadius: '8px',
                }}
              >
                <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
                  Questions
                </Typography>
              </Box>
              <Box
                sx={{
                  overflowY: 'scroll',
                  maxHeight: '235px',
                  WebkitScrollbar: {
                    width: '2px',
                  },
                }}
              >
                {updatedQuestionArray?.map((item: any, index: any) => {
                  console.log(item?.questionMedia, 'questionMedia');
                  return (
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          p: '8px 16px',
                          '&:hover': {
                            backgroundColor: '#F5F5F5',
                          },
                        }}
                      >
                        <Box>
                          <Tooltip title={item.id}>
                            <Typography
                              sx={{ fontSize: '12px', fontWeight: '400' }}
                            >
                              {`Question ${index + 1}: ${item.question}`}
                            </Typography>
                          </Tooltip>
                        </Box>
                        {item.saved ? (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box>
                              <IconButton
                                onClick={() => {
                                  handleEdit(item, item.index);
                                }}
                                sx={{ height: '15px' }}
                              >
                                <EditBlackNoBg />
                              </IconButton>
                            </Box>
                          </Box>
                        ) : (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box>
                              <IconButton
                                onClick={() => {
                                  addQuestion(item);
                                }}
                                sx={{ height: '15px' }}
                              >
                                <SaveIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </>
                  );
                })}
              </Box>
              <Box sx={{ m: '24px' }}>
                <Button
                  onClick={handleNewQuestion}
                  sx={{
                    backgroundColor: '#fff',
                    color: '#C73E9D',
                    width: '100%',
                    border: '#C73E9D 2px solid',
                  }}
                >
                  <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                    Add Question
                  </Typography>
                </Button>
              </Box>

              <QuestionDialog
                open={dialogOpen}
                onClose={handleCloseQuestionDialog}
                questionMessage={questionMessage}
                questionType={questionType}
                handleQuestionTypeChange={handleQuestionTypeChange}
                handleQuestionMessageChange={handleQuestionMessageChange}
              />
            </Box>
          </Paper>
        </Grid>
        <Grid
          item
          md={8}
          xs={12}
          sx={{
            backgroundColor: '#f9f9f9',
            height: '540px',
            width: '720px',
          }}
        >
          <Paper elevation={3} sx={{ borderRadius: '6px' }}>
            <Box sx={{ p: '24px 16px' }}>
              {!(
                formData?.type === 'image' ||
                formData?.type === 'video' ||
                formData?.type === 'audio'
              ) ? (
                <CurrentQuestionEdit
                  id={id}
                  handleDeleteDialog={handleDeleteDialog}
                  formData={formData}
                  setFormData={setFormData}
                  generatedQuestionsList={generatedQuestionsList}
                  setgeneratedQuestionsList={setgeneratedQuestionsList}
                />
              ) : (
                <MediaQuestion
                  handleDeleteDialog={handleDeleteDialog}
                  id={id}
                  formData={formData}
                  setFormData={setFormData}
                />
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AddQuestion;
