import React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

interface DynamicAutocompleteProps {
  id: string;
  label: string;
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  multiple?: boolean;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  getOptionLabel?: (option: any) => string;
  options: any[];
  loading?: boolean;
  filterSelectedOptions?: boolean;
  value?: any;
  onChange?: (event: React.ChangeEvent<{}>, value: any) => void;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  disable?: boolean;
}

const DynamicAutocomplete: React.FC<DynamicAutocompleteProps> = ({
  id,
  label,
  open,
  onOpen,
  onClose,
  isOptionEqualToValue,
  getOptionLabel,
  options,
  loading,
  filterSelectedOptions,
  value,
  onChange,
  placeholder,
  error,
  helperText,
  multiple,
  disable = false,
}) => {
  return (
    <Box sx={{ width: '100%', textAlign: 'left' }}>
      <InputLabel error={error} sx={{ color: '#484848', pb: 2 }}>
        {label}
      </InputLabel>
      <Autocomplete
        id={id}
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        isOptionEqualToValue={isOptionEqualToValue}
        getOptionLabel={getOptionLabel}
        options={options}
        loading={loading}
        disabled={disable}
        filterSelectedOptions={filterSelectedOptions}
        value={value}
        onChange={onChange}
        multiple={multiple}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            error={error}
            helperText={helperText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};

export default DynamicAutocomplete;
