import React, { useState } from 'react';
import Card from '@mui/material/Card';

import Typography from '@mui/material/Typography';
import { EditIcon, DeleteIcon, ViewIcon } from 'icons';
import EyeBorderIcon from 'icons/eyeBorderIcon';
import DeleteBorderIcon from 'icons/deleteBorderIcon';
import EditBorderIcon from 'icons/editBorderIcon';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';

// import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';

import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Box,
  Checkbox,
  Pagination,
  TextField,
} from '@mui/material';
import { Bell } from 'mdi-material-ui';
import { VisibilityOutlined } from '@mui/icons-material';

export interface Header {
  label: string;
  width: number;
  value: string;
}
export interface IStudent {
  id?: string;
  name?: string;
  skill?: string;
  topic?: string;
  subTopic?: string;
  assignmentType?: string;
  totalGems?: string;
  pending?: string;
  status?: string;
  startDate?: string;
  endDate?: string;
}

export interface IClasses {
  id?: string;
  name?: string;
  totalAssignments?: string;
  completedAssignments?: string;
  pendingAssignments?: string;
  totalGems?: string;
  interVentionRecommended?: string;
  averageScore?: string;
  studentCount?: string;
  target?: string;
  Assignments?: IStudent[];
}

const StudentsList = ({ students }: { students: IStudent[] | undefined }) => {
  let stud: IStudent[] = [];
  if (students && students.length > 0) {
    stud = students;
  }
  const urlParams = new URLSearchParams(window.location.search);

  const assignmentType = urlParams.get('assignmentType');
  const assignmentFrom = urlParams.get('type');
  const inst = ['icon'];
  return (
    <List component="div" disablePadding>
      {stud.map((student) => (
        <Box sx={{ mb: 4 }} className="flex flex-col gap-6 w-full p-2">
          <Box className="flex bg-gray-100 px-2  gap-6 items-center py-2">
            <Box className="flex" sx={{ flex: 3 }}>
              Sub Topic
            </Box>
            <Box className="flex" sx={{ flex: 3 }}>
              Skill
            </Box>
            {assignmentType === 'diagnostic' && (
              <Box className="flex" sx={{ flex: 1.5 }}>
                <Typography>Number Of Questions</Typography>
              </Box>
            )}
            <Box className="flex"></Box>
          </Box>
          <Box className="flex gap-6 p-2 items-center">
            <Box className="flex" sx={{ flex: 3 }}>
              <Typography className="!text-black">
                MA.6.NSO.2.2 - Extend previous understanding of multiplication
                and division to compute products and quotients of positive
                fractions by positive fractions, including mixed numbers, with
                procedural fluency. || '-
              </Typography>
            </Box>
            <Box className="flex" sx={{ flex: 3 }}>
              <Typography className="!text-black">
                6-L.14 - Multiply mixed numbers: word problems
              </Typography>
            </Box>
            {assignmentType === 'diagnostic' && (
              <Box className="flex" sx={{ flex: 1 }}>
                <Box className="rounded-2xl px-8 py-1 border border-gray-300">
                  -{'    '} 1 {'    '}+
                </Box>
              </Box>
            )}
            <Box className="flex" sx={{ flex: 0.5 }}>
              <Checkbox size="medium" />
            </Box>
          </Box>

          <Box className="flex gap-6 p-2 items-center">
            <Box className="flex" sx={{ flex: 3 }}>
              <Typography className="!text-black">
                MA.6.NSO.2.2 - Extend previous understanding of multiplication
                and division to compute products and quotients of positive
                fractions by positive fractions, including mixed numbers, with
                procedural fluency. || '-
              </Typography>
            </Box>
            <Box className="flex" sx={{ flex: 3 }}>
              <Typography className="!text-black">
                6-L.14 - Multiply mixed numbers: word problems
              </Typography>
            </Box>
            {assignmentType === 'diagnostic' && (
              <Box className="flex" sx={{ flex: 1 }}>
                <Box className="rounded-2xl px-8 py-1 border border-gray-300">
                  -{'    '} 1 {'    '}+
                </Box>
              </Box>
            )}
            <Box className="flex" sx={{ flex: 0.5 }}>
              <Checkbox size="medium" />
            </Box>
          </Box>
        </Box>
      ))}
    </List>
  );
};

export default function Instructional({
  openDefault,
  classes,
  color,
  pageIndex,
  pageSize,
  header,
  pageCount,
  showPagination,
  totalCount,
  entriesPerPage,
}: {
  openDefault?: any;
  classes?: any;
  pageCount?: any;
  pageIndex?: any;
  pageSize?: any;
  showPagination?: any;
  totalCount?: any;
  color?: string;
  entriesPerPage?: any;
  header?: Header[];
}) {
  const [open, setOpen] = React.useState(openDefault || false);
  const [search, setSearch] = useState('');

  const renderPagination = () => (
    <Pagination
      count={pageCount || 10}
      page={pageIndex || 4 + 1}
      shape="rounded"
      // onChange={handleChangePage}
    />
  );
  const setEntriesPerPage = (value: any) => {
    // fetchData({
    //   pageIndex: pageIndex,
    //   pageSize: value,
    //   search: search,
    // });
    // setPageSize(value);
  };

  const onSearchChange = (value: any) => {
    setSearch(value); // Update the search state with the current input value.
  };
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      {classes?.map((classes: any, i: number) => (
        <div
          style={{
            border: open ? '1px solid #DCDCDC' : 'none',
            borderRadius: '10px 10px 0px 0px',
            marginBottom: '16px',
          }}
        >
          <Card
            style={{
              backgroundColor: color,
              borderRadius: '10px 10px 0px 0px',
              boxShadow:
                '0px 2px 1px -1px rgba(76, 78, 100, 0.2),0px 1px 1px 0px rgba(76, 78, 100, 0.14),0px 1px 3px 0px rgba(76, 78, 100, 0.12)',
            }}
          >
            <ListItemButton
              style={{ padding: '3px 12px' }}
              onClick={handleClick}
            >
              {/* <ListItemIcon>
                        class
                    </ListItemIcon> */}

              <Grid
                container
                spacing={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  color: '#131515',
                  '& .css-6uzn28-MuiTypography-root': {
                    color: '#131515',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '22px',
                  },
                }}
              >
                {header?.map((head) => (
                  <Grid item className="min-h-14 flex items-center">
                    {head && classes && (
                      <>
                        <Typography fontWeight={600} color={'#131515'}>
                          {classes[head?.value] || 'N/A'}
                        </Typography>
                      </>
                    )}
                  </Grid>
                ))}
                <Grid
                  item
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {open ? <ExpandLess /> : <ExpandMore />}
                </Grid>
              </Grid>
            </ListItemButton>
          </Card>

          <Collapse in={open} timeout="auto" unmountOnExit>
            <StudentsList students={classes?.Assignments} />
          </Collapse>
        </div>
      ))}
      {/* {showPagination && ( */}
      {false && (
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          my={4}
          sx={{ border: '1px solid #E9EBED', borderRadius: '6px', p: 2 }}
        >
          {/* {entriesPerPage && ( */}
          {true && (
            <Box flex="1" display="flex" alignItems="center">
              <Typography variant="body2" className="entries-page-txt">
                Show&nbsp;&nbsp;
              </Typography>
              <Autocomplete
                disableClearable
                value={pageSize?.toString()}
                options={['10', '25', '50', '100']}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10));
                }}
                size="small"
                sx={{ width: '5rem' }}
                renderInput={(params) => <TextField {...params} />}
              />
              <Typography variant="body2" className="entries-page-txt">
                &nbsp;&nbsp;entries of {totalCount}
              </Typography>
            </Box>
          )}
          {renderPagination()}
        </Box>
      )}
    </>
  );
}
