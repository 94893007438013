import React from "react";
import {
  Box,
  Typography,
  IconButton,
  Stack,
  Link,
  Breadcrumbs,
} from "@mui/material";
import CustomTable from "components/customTable";
import DefaultCell from "components/DefaultCell";
import { EditIcon, DeleteIcon, ViewIcon, LinkIcon } from "icons";
import Profile from "assets/actionIcons/profile.png";
import Chip from "@mui/material/Chip";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

function handleBreadCrumbClick(
  event: React.MouseEvent<HTMLDivElement, MouseEvent>
) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

function Index() {
  const columns = [
    {
      Header: "Teacher",
      accessor: "teacher",
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: "Date Joined",
      accessor: "date",
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: "",
      accessor: "time",
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: "School",
      accessor: "school",
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ value }: any) => (
        <Box>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
          <IconButton>
            <ViewIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const rows = [
    {
      teacher: "Mrs. Terry Kenter",
      date: "12 Dec 2023",
      time: (
        <Chip
          label="full-time"
          variant="filled"
          color="success"
          style={{ borderRadius: 6 }}
        />
      ),
      school: "School A",
      email: "abc@example.com",
    },
    {
      teacher: "Mrs. Terry Kenter",
      date: "12 Dec 2023",
      time: (
        <Chip
          label="part-time"
          variant="filled"
          color="warning"
          style={{ borderRadius: 6 }}
        />
      ),
      school: "School A",
      email: "abc@example.com",
    },
    {
      teacher: "Mrs. Terry Kenter",
      date: "12 Dec 2023",
      time: (
        <Chip
          label="full-time"
          variant="filled"
          color="success"
          style={{ borderRadius: 6 }}
        />
      ),
      school: "School A",
      email: "abc@example.com",
    },
    {
      teacher: "Mrs. Terry Kenter",
      date: "12 Dec 2023",
      time: (
        <Chip
          label="part-time"
          variant="filled"
          color="warning"
          style={{ borderRadius: 6 }}
        />
      ),
      school: "School A",
      email: "abc@example.com",
    },
    {
      teacher: "Mrs. Terry Kenter",
      date: "12 Dec 2023",
      time: (
        <Chip
          label="part-time"
          variant="filled"
          color="warning"
          style={{ borderRadius: 6 }}
        />
      ),
      school: "School A",
      email: "abc@example.com",
    },
    {
      teacher: "Mrs. Terry Kenter",
      date: "12 Dec 2023",
      time: (
        <Chip
          label="full-time"
          variant="filled"
          color="success"
          style={{ borderRadius: 6 }}
        />
      ),
      school: "School A",
      email: "abc@example.com",
    },
    {
      teacher: "Mrs. Terry Kenter",
      date: "12 Dec 2023",
      time: (
        <Chip
          label="part-time"
          variant="filled"
          color="warning"
          style={{ borderRadius: 6 }}
        />
      ),
      school: "School A",
      email: "abc@example.com",
    },
    {
      teacher: "Mrs. Terry Kenter",
      date: "12 Dec 2023",
      time: (
        <Chip
          label="full-time"
          variant="filled"
          color="success"
          style={{ borderRadius: 6 }}
        />
      ),
      school: "School A",
      email: "abc@example.com",
    },
  ];

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/"
      onClick={() => handleBreadCrumbClick}
    >
      School Management
    </Link>,
    <Typography key="2" color="#C73E9D">
      Teacher
    </Typography>,
  ];

  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        style={{ marginBottom: 20 }}
      >
        {breadcrumbs}
      </Breadcrumbs>
      <CustomTable
        table={{
          columns: columns,
          rows: [],
        }}
        title="Teachers"
        canSearch
        buttonTitle="Add Teacher"
      />
    </>
  );
}

export default Index;
