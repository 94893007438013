import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Link,
  Breadcrumbs,
  Grid,
  Button,
  Card,
  InputAdornment,
  TextField,
  Autocomplete,
  Pagination,
  CardContent,
  Tooltip,
} from '@mui/material';
import CustomTable from 'components/customTable';
import DefaultCell from 'components/DefaultCell';
import { EditIcon, DeleteIcon, ViewIcon } from 'icons';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useAppDispatch, useAppSelector } from 'store/store';
import { startLoading, stopLoading } from 'store/Slice/loadingSlice';
import { useNavigate, useParams } from 'react-router-dom';
import AddUpdate from 'components/dialog/school/AddUpdate';
import AddFirstSchool from './AddFirstSchool';
import {
  getAllSchoolOfDistrict,
  getDistrictByID,
} from 'store/Thunk/districtThunk';
import SchoolImage from 'assets/school/schoolImage.png';
import SearchIcon from 'icons/search';
import { getAllSchoolList } from 'store/Thunk/schoolThunk';
// import CheckCircle from 'icons/checkCircle';
// import PlusCircle from 'icons/PlusCircle';
// import File from 'icons/File';
import {
  lineIndicationFour,
  lineIndicationOne,
  lineIndicationThree,
  lineIndicationTwo,
} from 'assets';
import { route_info } from 'navigation/route';
import { useSelector } from 'react-redux';

//C:\client-project\G3MS-AdminPanel\src\pages\super_admin\school_management\school\index.tsx

// const borderStyleObj = {
//   width: '100%',
//   height: '1px',
//   backgroundImage: 'linear-gradient(to right, #c73e9d4f 50%, #fff 50%)',
//   backgroundPosition: 'top',
//   backgroundSize: '11px 2px',
//   backgroundRepeat: 'repeat-x',
//   marginLeft: '12px',
// };

const steps = [
  {
    title: 'Manage Districs ',
    content: 'Create an District to get started.',
    icon: lineIndicationOne,
  },
  {
    title: 'Manage Schools',
    content: 'Create an school to get started.',
    icon: lineIndicationTwo,
  },
  {
    title: 'Manage Classroom',
    content: 'Create classroom to get started.',
    icon: lineIndicationThree,
  },
  {
    title: 'Manage all content',
    content: 'Create an District to get started.',
    icon: lineIndicationFour,
  },
];

function handleBreadCrumbClick(
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

function Index() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { schoolList } = useAppSelector((state) => state.schoolReducer);
  const { districtID } = useAppSelector((state: any) => state.districtReducer);
  let data = useSelector((state: any) => state.loadingReducer);
  console.log('data', data);
  const [entriesPerPage, setEntriesPerPage] = useState<number>(12);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [gotoPage, setGotoPage] = useState<number>(pageIndex);
  const [isOpen, setIsOpen] = useState(false);
  const [template, setTemplate] = useState<any[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(12);
  const [search, setSearch] = useState<any>(null);
  const [totalCount, setTotalCount] = useState(0);
  const fetchIdRef = useRef(0);

  useEffect(() => {
    dispatch(getDistrictByID({ id }));
    if (!id) {
      dispatch(getAllSchoolList({ pageSize, pageIndex, search }));
    }
  }, [id]);

  useEffect(() => {
    if (districtID[0]?.ncesDistrictId) {
      dispatch(startLoading());
      dispatch(
        getAllSchoolOfDistrict({
          pageSize: entriesPerPage,
          pageIndex: gotoPage,
          search,
          id: districtID[0]?.ncesDistrictId,
        }),
      ).then(() => dispatch(stopLoading()));
    }
  }, [districtID, entriesPerPage, gotoPage, search]);

  useEffect(() => {
    setPageIndex(gotoPage);
  }, [gotoPage]);

  useEffect(() => {
    if (schoolList && schoolList?.items) {
      setTemplate(schoolList?.items);
      setPageCount(schoolList?.meta?.lastPage);
      setTotalCount(schoolList?.meta?.total);
      setEntriesPerPage(schoolList?.meta?.perPage);
    }
  }, [schoolList]);

  const fetchData = useCallback(({ pageSize, pageIndex, search }: any) => {
    const fetchId = ++fetchIdRef.current;

    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        setPageNo(pageIndex);
        setPageSize(pageSize);
        setSearch(search);
        dispatch(startLoading());
        dispatch(
          getAllSchoolOfDistrict({ pageSize, pageIndex, search, id }),
        ).then(() => dispatch(stopLoading()));
      }
    }, 1000);
  }, []);

  const columns = [
    {
      Header: 'School',
      accessor: 'name',
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },

    {
      Header: 'School Type',
      accessor: 'schoolType',
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: 'School Grades',
      accessor: 'schoolGrades',
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: 'District',
      accessor: 'district',
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: ({ value }: any) => (
        <Box>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              navigateViewPage(value.id);
            }}
          >
            <ViewIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const getRows = () => {
    const data: any = template?.map((obj: any) => ({
      id: obj?.id,
      schoolType: obj?.schoolType,
      specificSchoolType: obj?.specificSchoolType,
      schoolGrades: obj?.schoolGrades,
      name: obj?.name,
      ncesSchoolId: obj?.ncesSchoolId,
      streetAddress: obj?.streetAddress,
      phone: obj?.phone,
      totalNoOfStudent: obj?.totalNoOfStudent,
      district: obj?.district?.name,
      city: obj?.city || null,
      state: obj?.state || null,
      county: obj?.county || null,
      zipCode: obj?.zipCode || null,
      action: obj,
    }));
    return data;
  };

  const tableData = {
    columns: columns,
    rows: getRows(),
  };

  const onSearchChange = (value: any) => {
    setSearch(value);
  };

  const navigateViewPage = (id: string) => {
    navigate(`${route_info?.school_management?.schoolView}/${id}`);
  };

  const handleAddUpdateSchool = () => {
    setIsOpen(!isOpen);
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) =>
    setGotoPage(value - 1);

  const renderPagination = () => (
    <Pagination
      count={pageCount}
      page={pageIndex + 1}
      shape="rounded"
      onChange={handleChangePage}
    />
  );

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href={route_info?.school_management?.districtCrud}
      onClick={() => handleBreadCrumbClick}
    >
      District Management
    </Link>,
    <Typography key="2" color="#C73E9D">
      {districtID.length > 0
        ? districtID[0]?.name || districtID[0]?.ncesDistrictId
        : 'District'}
    </Typography>,
    <Typography key="3" color="#C73E9D">
      School
    </Typography>,
  ];

  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        style={{ marginBottom: 20 }}
      >
        {breadcrumbs}
      </Breadcrumbs>

      {schoolList?.items?.length <= 0 && !search && !data.screenLoading && (
        <AddFirstSchool />
      )}
      <Grid container spacing={4} sx={{ mt: 3, mb: 4 }}>
        {steps?.map((item) => {
          return (
            <Grid item md={3} sm={12} xs={12}>
              <Card sx={{ minHeight: '130px' }}>
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={item?.icon}
                      alt="line indication"
                      width={'100%'}
                    />
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{
                      color: '#18191F',
                      fontSize: '16px',
                      lineHeight: '30px',
                      fontWeight: '600',
                      mt: 3,
                    }}
                  >
                    {item?.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: '13px',
                      fontWeight: '400',
                      lineHeight: '26px',
                      color: '#18191F',
                    }}
                  >
                    {item?.content}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      {
        <Card sx={{ p: '24px' }}>
          <Box sx={{ p: '24px', pt: '0' }}>
            <Box ml={2} mt={2}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="flex-start"
                mb={2}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography className="table_title" sx={{ ml: 2 }}>
                    Schools
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    variant="contained"
                    className="primary_bg_btn"
                    sx={{ ml: 3 }}
                    onClick={handleAddUpdateSchool}
                  >
                    Add School
                  </Button>
                </Box>
              </Box>
              <TextField
                placeholder={'searchText'}
                sx={{
                  mb: 10,
                  width: { xs: '100%', sm: '40%', md: '30%' },
                  background: '#F9F9F9',
                }}
                size="small"
                value={search}
                onChange={(e) => onSearchChange(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {schoolList?.items?.length > 0 && (
                <Grid container spacing={8}>
                  {schoolList?.items?.map((item: any) => {
                    // eslint-disable-next-line no-lone-blocks
                    {
                      console.log('ItemId:-', item.id);
                    }
                    return (
                      <Grid item md={3} sm={6} xs={12}>
                        <Box
                          sx={{
                            boxShadow: '0px 1px 12px rgba(0, 0, 0, 0.03)',
                            border: '0.50px #E9EBED solid',
                            borderRadius: '6px',
                            p: '10px',
                            minHeight: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Box>
                            <Box>
                              <img
                                src={SchoolImage}
                                alt="School"
                                style={{ height: '100%', width: '100%' }}
                              />
                            </Box>
                            <Tooltip title={item?.id}>
                              <Box>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    p: '4px 2px',
                                  }}
                                >
                                  {item?.name || 'School name'}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontSize: '12px',
                                    fontWeight: '400',
                                    p: '0px 2px',
                                  }}
                                >
                                  Conduct quizzes online and grade them
                                  automatically
                                </Typography>
                              </Box>
                            </Tooltip>
                          </Box>
                          <Button
                            variant="contained"
                            className="primary_bg_btn"
                            sx={{
                              mt: 1.5,
                              width: '100%',
                              mr: '4px',
                              ml: '4px',
                            }}
                            // route_info?.district_management_schoolList.replace(':id', value.id)
                            onClick={() => {
                              navigate(
                                route_info?.school_management.school_details.replace(
                                  ':id',
                                  item.id,
                                ),
                                // `${route_info?.school_management?.school_details}/${item?.id}`,
                              );
                            }}
                            // endIcon={<AddIcon />}
                          >
                            Manage
                          </Button>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </Box>
          </Box>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            my={4}
            sx={{
              border: '1px solid #E9EBED',
              borderRadius: '6px',
              p: 2,
              m: '24px',
              mt: 'unset',
            }}
          >
            {entriesPerPage && (
              <Box flex="1" display="flex" alignItems="center">
                <Typography variant="body2" className="entries-page-txt">
                  Show&nbsp;&nbsp;
                </Typography>
                <Autocomplete
                  disableClearable
                  value={pageSize.toString()}
                  options={['12', '25', '50', '100']}
                  onChange={(event, newValue) => {
                    setEntriesPerPage(parseInt(newValue, 10));
                  }}
                  size="small"
                  sx={{ width: '5rem' }}
                  renderInput={(params) => <TextField {...params} />}
                />
                <Typography variant="body2" className="entries-page-txt">
                  &nbsp;&nbsp;entries of {totalCount}
                </Typography>
              </Box>
            )}
            {renderPagination()}
          </Box>
        </Card>
      }
      {isOpen && (
        <AddUpdate open={isOpen} onClose={handleAddUpdateSchool} id={id} />
      )}
    </>
  );
}

export default Index;
