import { createAsyncThunk } from '@reduxjs/toolkit';
import API from 'store/api';
import {
  setBtnLoading,
  startLoading,
  stopLoading,
} from 'store/Slice/loadingSlice';
import {
  notificationFail,
  notificationSuccess,
} from 'store/Slice/notificationSlice';
import {
  setStudent,
  setStudentDetail,
  setStudentList,
  setStudentListByClassroom,
  setStudentListByGradeIdTopicId,
  setStudentListLoading,
} from 'store/Slice/studentSlice';
import { setScreenLoading } from 'store/Slice/userSlice';
import { addParentToClassRoom } from './classroomThunk';

export const addStudent = createAsyncThunk(
  'addStudent',
  async (formData: any, { dispatch }) => {
    let url = '/api/v1/questionss';
    try {
      await API.post(url, formData);
      dispatch(stopLoading());
    } catch (err: any) {
      dispatch(stopLoading());
      return err;
    }
  },
);

export const getStudentListByClassroom = createAsyncThunk(
  'getQuestionList',
  async (_request: any, { dispatch }) => {
    dispatch(startLoading());
    // const page = _request.pageIndex || 0;
    // const size = _request.pageSize || 10;
    const search = _request?.search || '';
    let url = `api/v1/classroom/${_request?.id}/student`;
    try {
      if (search) {
        url += `?query=${search}`;
      }
      const response: any = await API.get(url);
      dispatch(setStudentListByClassroom(response.data.data));
      dispatch(stopLoading());
      return response;
    } catch (err: any) {
      dispatch(stopLoading());
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);
export const getStudentListByGradeIdTopicId = createAsyncThunk(
  'getStudentListByGradeIdTopicId',
  async (_request: any, { dispatch }) => {
    const teacherSchool: any = localStorage.getItem('teacherSchool');

    dispatch(startLoading());
    // const page = _request.pageIndex || 0;
    // const size = _request.pageSize || 10;
    const search = _request?.search || '';
    let url = `api/v1/school/${teacherSchool}/grades/${_request?.grade?.grade?.id}/topic/${_request?.topic?.id}/students`;
    try {
      if (search) {
        url += `?query=${search}`;
      }
      const response: any = await API.get(url);
      dispatch(setStudentListByGradeIdTopicId(response.data.data));
      dispatch(stopLoading());
      return response;
    } catch (err: any) {
      dispatch(stopLoading());
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);
export const getStudentDetail = createAsyncThunk(
  'getStudentDetail',
  async (_request: any, { dispatch, rejectWithValue }) => {
    dispatch(startLoading());
    // const page = _request.pageIndex || 0;
    // const size = _request.pageSize || 10;
    const search = _request?.search || '';
    let url = `api/v1/dashboard/general/student/${_request?.id}`;
    try {
      if (search) {
        url += `?query=${search}`;
      }
      const response: any = await API.get(url);
      dispatch(setStudentDetail(response.data.data));
      dispatch(stopLoading());
      return response;
    } catch (err: any) {
      dispatch(stopLoading());
      return rejectWithValue(err);
    }
  },
);

export const getAllStudentList = createAsyncThunk(
  'getAllStudentList',
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setStudentListLoading(true));
      const response: any = await API.get(`api/v1/students`);
      dispatch(setStudentList(response.data.data));
      dispatch(setStudentListLoading(false));

      return response?.data?.data;
    } catch (err: any) {
      dispatch(setStudentListLoading(false));
      dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const createStudent = createAsyncThunk(
  'createStudent',
  async (_request: any, { dispatch }) => {
    // TODO: Upload the avatar file and use the url as profileImage
    try {
      const finalInfo = {
        ..._request,
        name: _request?.firstName + _request?.lastName,
        dateOfBirth: '2022-11-07T10:17:07.688Z',
      };

      const response: any = await API.post(`api/v1/students`, { ...finalInfo });
      dispatch(notificationSuccess('Student Created Successfully'));
      return response;
    } catch (err: any) {
      dispatch(setStudentListLoading(false));
      dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const updateStudent = createAsyncThunk(
  'updateStudent',
  async (_request: any, { dispatch }) => {
    const {
      name,
      image,
      email,
      classrooms,
      school,
      uuid,
      gradeId,
      parentId,
      removeParent,
      addNewParent,
      paidStatus,
      username,
      isUnder13,
    } = _request;

    const url = `api/v1/students/${uuid}`;

    try {
      dispatch(setBtnLoading(true));

      const formData = new FormData();
      if (image) {
        formData.append('file', image);
      }
      formData.append('name', name);
      formData.append('email', email);
      formData.append('schoolId', school);
      formData.append('gradeId', gradeId);
      formData.append('classrooms', classrooms);
      formData.append('parentId', parentId);
      formData.append('paidStatus', paidStatus);
      formData.append('username', username);
      formData.append('isUnder13', isUnder13);

      await API.patch(url, formData);

      if (removeParent?.length > 0) {
        await API.post(`/api/v1/parents/${removeParent}/remove/children`, {
          userIds: [uuid],
        });

        await dispatch(
          addParentToClassRoom({
            userIds: [uuid],
            parentId,
          }),
        );
      }

      if (addNewParent) {
        await dispatch(
          addParentToClassRoom({
            userIds: [uuid],
            parentId,
          }),
        );
      }

      dispatch(setBtnLoading(false));
      return true;
    } catch (error: any) {
      console.error('Error in updateStudent:', error);

      if (error.response) {
        dispatch(notificationFail(error.response.data.message));
      } else {
        dispatch(
          notificationFail('An error occurred while updating the student.'),
        );
      }

      dispatch(setBtnLoading(false));
      return false;
    }
  },
);

export const getStudentsOfSchool = createAsyncThunk(
  'studentsOfSchool',
  async (schoolId: string, { dispatch }) => {
    try {
      const response: any = await API.get(`api/v1/students/${schoolId}/school`);
      return response?.data?.data || [];
    } catch (err: any) {
      dispatch(setStudentListLoading(false));
      dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const deleteStudent = createAsyncThunk(
  'deleteStudent',
  async (studentId: string, { dispatch }) => {
    try {
      const response: any = await API.delete(`api/v1/students/${studentId}`);
      dispatch(notificationSuccess('Student Deleted Successfully'));
      return response?.data?.data || [];
    } catch (err: any) {
      dispatch(setStudentListLoading(false));
      dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);
