import { createAsyncThunk } from '@reduxjs/toolkit';
import API from 'store/api';
import {
  setDailyChallengesById,
  setDailyChallengesList,
} from 'store/Slice/dailyChallengesSlice';
import {
  setBtnLoading,
  startLoading,
  stopLoading,
} from 'store/Slice/loadingSlice';
import {
  notificationFail,
  notificationSuccess,
} from 'store/Slice/notificationSlice';
import { setScreenLoading } from 'store/Slice/userSlice';
import {
  setVideoList,
  setMuxUploadUrl,
  setVideoById,
} from 'store/Slice/videoSlice';

export const addUpdateChallenge = createAsyncThunk(
  'addUpdateChallenge',
  async (formData: any, { dispatch }) => {
    let url = formData?.id
      ? `/api/v1/daily-challenge/${formData?.id}`
      : `/api/v1/daily-challenge`;
    console.log('formData', formData);

    try {
      if (formData?.id) {
        let _request = {
          title: formData?.title,
          question: formData?.question,
          datePosted: formData?.datePosted,
          districtIds: formData?.districtIds,
          schoolIds: formData?.schoolIds,
          topicId: formData?.topicId,
          subTopicId: formData?.subTopicId,
          skillId: formData?.skillId,
          userId: formData?.userId,
          videoId: formData?.videoId,
          gradeIds: formData?.gradeIds,
          assetId: formData?.assetId,
          playbackId: formData?.playbackId,
          uploadId: formData?.uploadId,
        };

        const response = await API.patch(url, _request);
        dispatch(notificationSuccess('Daily Challenges updated successfully'));
      } else {
        let _request = {
          title: formData?.title,
          question: formData?.question,
          datePosted: formData?.datePosted,
          districtIds: formData?.districtIds,
          schoolIds: formData?.schoolIds,
          topicId: formData?.topicId,
          subTopicId: formData?.subTopicId,
          skillId: formData?.skillId,
          userId: formData?.userId,
          videoId: formData?.videoId,
          gradeIds: formData?.gradeIds,
          uploadId: formData?.uploadId,
        };
        await API.post(url, _request);
        dispatch(notificationSuccess('Daily Challenges created successfully'));
      }
      dispatch(setBtnLoading(false));
      dispatch(getDailyChallengesList({ pageSize: 10, pageIndex: 0 }));
    } catch (err: any) {
      dispatch(setBtnLoading(false));
      dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const getDailyChallengesList = createAsyncThunk(
  'getDailyChallengesList',
  async (_request: any, { dispatch }) => {
    // dispatch(startLoading());
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 10;
    const search = _request?.search || '';
    let url = `/api/v1/daily-challenge?page=${page + 1}&limit=${size}`;
    try {
      if (search) {
        url += `&query=${search}`;
      }
      const response: any = await API.get(url);
      dispatch(setDailyChallengesList(response.data.data));
      dispatch(stopLoading());
      return response;
    } catch (err: any) {
      dispatch(stopLoading());
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const getDailyChallengesById = createAsyncThunk(
  'getDailyChallengesById',
  async ({ id }: any, { dispatch }) => {
    dispatch(startLoading());
    let url = `/api/v1/daily-challenge/${id}`;
    try {
      const response: any = await API.get(url);
      dispatch(setDailyChallengesById(response.data.data));
      dispatch(stopLoading());
      return response?.data?.data;
    } catch (err: any) {
      dispatch(setDailyChallengesById({}));
      dispatch(stopLoading());
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const deleteDailyChallenge = createAsyncThunk(
  'deleteDailyChallenge',
  async ({ id }: any, { dispatch }) => {
    let url = `/api/v1/daily-challenge/${id}`;
    try {
      await API.delete(url);
      dispatch(getDailyChallengesList({ pageSize: 10, pageIndex: 0 }));
      dispatch(notificationSuccess('Daily challange deleted successfully'));
      return true;
    } catch (err: any) {
      dispatch(notificationFail(err.response.data.message));
      return false;
    }
  },
);
