import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';

import EditBorderIcon from 'icons/editBorderIcon';
import DeleteBorderIcon from 'icons/deleteBorderIcon';
import DefaultCell from 'components/DefaultCell';
import CustomTable from 'components/customTable';

import AddUpdateContent from 'components/dialog/assigment/AddUpdate';
import TableTeacherProgress from 'components/student/progress/teacherTable';
import { DartIcon } from 'icons';
import StudentProgressTable from 'components/student/progress/table/temp';
import TableHeader from 'components/tableHeader';
import AssignContent from 'components/student/assign';
import { useAppDispatch } from 'store/store';
import useSchoolClassroomInfo from 'hooks/useSchoolClassroomInfo';
import { getImportedAssignments } from 'store/Thunk/assignContentThunk';
import { startLoading } from 'store/Slice/loadingSlice';
interface Assignment {
  assignmentId: string;
  checked: boolean;
  type: string;
  subTopics: string;
  skills: string;
  date: string;
  score: number;
}

interface AssignmentWrapper {
  id: string;
  picture: string;
  name: string;
  skillsMastered: number;
  completedAssignments: number;
  skillsNotMastered: number;
  assignemetsTypes: {
    type: string;
    totalVideo: string;
    totalQuiz: string;
    totalChallenge: string;
    assignments: Assignment[];
  }[];
}

interface SelectedAssignment {
  id: string;
  name: string;
  picture: string;
  assignmentId: string;
}
interface SelectedAssignment {
  id: string;
  name: string;
  picture: string;
  email: string;
  assignmentId: string;
}
function Index() {
  const [search, setSearch] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenFiler, setIsOpenFilter] = React.useState<boolean>(false);
  const [isOpenAssign, setIsOpenAssign] = React.useState<boolean>(false);
  const [selectedAssignments, setSelectedAssignments] = useState<
    SelectedAssignment[]
  >([]);
  const [selectedAssessments, setSelectedAssessments] = useState<
    { userIds: string[]; assignmentIds: string[] }[]
  >([]);

  const [importedAssssmentsMeta, setImportedAssessmentsMeta] = useState([]);
  const [importedAssssmentsList, setImportedAssessments] = useState([]);
  const dispatch = useAppDispatch();

  const fetchIdRef = useRef(0);
  const urlParams = new URLSearchParams(window.location.search);
  const classroomId = urlParams.get('classroomId');

  const fetchData = useCallback(
    ({ pageSize, pageIndex }: any) => {
      const fetchId = ++fetchIdRef.current;
      // We'll even set a delay to simulate a server here
      if (fetchId === fetchIdRef.current) {
        dispatch(startLoading());
        setPageNo(0);
        dispatch(
          getImportedAssignments({
            id: classroomId,
            pageSize: 10,
            pageIndex: 0,
            search,
          }),
        )
          .unwrap()
          .then((res) => {
            setImportedAssessments(res.items);
            setImportedAssessmentsMeta(res);
            console.log('assignmentsList', res);
          });
      }
      // setTimeout(() => {}, 1000);
    },
    [search],
  );
  useEffect(() => {
    // dispatch(getImportedAssignments('784664'))
    dispatch(getImportedAssignments({ id: classroomId }))
      .unwrap()
      .then((res) => {
        setImportedAssessments(res.items);
        setImportedAssessmentsMeta(res);
        console.log('assignmentsList', res);
      });
  }, []);

  const handleClose = () => {
    setIsOpenAssign(false);
  };
  const handleAddUpdateContent = () => {
    setIsOpen(!isOpen);
  };

  const header = [
    { label: 'Student Name', width: 1, value: 'name', color: '#84818A' },
    {
      label: 'Total Standards(skills) Mastered',
      width: 1,
      value: 'totalSkillsMasteredCount',
      color: '#20A58A',
    },
    {
      label: 'Total Standards(skills) Not Mastered',
      width: 1,
      value: 'totalSkillsNotMasteredCount',
      color: '#C73E9D',
    },
  ];
  const action = true;
  const subHeader = [
    { label: 'Standard', value: 'subTopics', width: 4 },
    { label: 'Skills', value: 'skills', width: 5 },
    { label: 'Score', value: 'score', width: 2 },
    { label: 'Action', value: 'action', width: 1 },
  ];
  const assignmentTypeHeader = [
    {
      label: 'Standards not mastered',
      icon: <DartIcon />,
      value: 'type',
      width: 12,
      bg: '#F9F9F9',
      color: '',
    },
  ];
  const assignmentMasteredHeader = [
    {
      label: 'Standards mastered',
      icon: <DartIcon />,
      value: 'type',
      width: 12,
      bg: '#F9F9F9',
      color: '',
    },
  ];
  const [selected, setSelected] = useState<
    { userIds: string[]; assignmentIds: string[] }[]
  >([]);

  console.log('selectedAssignments', selectedAssessments);
  console.log('selectedAssignments', importedAssssmentsList);

  const color = '#E9F6F3';
  const [pageNo, setPageNo] = useState(0);

  return (
    <Box>
      <Box className="px-10">
        <TableHeader
          item={{
            search,
            setSearch,
            fetchData: fetchData,
            openFilter: isOpenFiler,
            openAssign: isOpenAssign,
            onCloseFilter: setIsOpenFilter,
            onCloseAssign: setIsOpenAssign,
            action: true,
            button: true,
            canSearch: true,
            disabledButton:
              !(importedAssssmentsList?.length > 0) ||
              (selectedAssessments.length === 1
                ? !(selectedAssessments[0].assignmentIds.length > 0)
                : !(selectedAssessments.length > 0)),
          }}
        />
        {importedAssssmentsList?.length > 0 ? (
          <Typography
            style={{ color: '#1A846E' }}
            my={4}
            className="!text-sm !font-bold"
          >
            Click “Assign Content” to have G3MS automatically provide classwork
            to address skills selected below.
          </Typography>
        ) : (
          <></>
        )}
      </Box>
      {importedAssssmentsList?.length ? (
        <Box m={4}>
          <StudentProgressTable
            color={color}
            setImportedAssessmentsMeta={setImportedAssessmentsMeta}
            importedAssssmentsMeta={importedAssssmentsMeta}
            classes={importedAssssmentsList}
            setImportedAssessments={setImportedAssessments}
            header={header}
            search={search}
            pageNo={pageNo}
            setPageNo={setPageNo}
            subHeader={subHeader}
            assignmentTypeHeader={assignmentTypeHeader}
            assignmentMasteredHeader={assignmentMasteredHeader}
            selectedAssignments={selectedAssignments}
            setSelectedAssignments={setSelectedAssignments}
            selectedAssessments={selectedAssessments}
            setSelectedAssessments={setSelectedAssessments}
            setSelected={setSelected}
            selected={selected}
          />
        </Box>
      ) : (
        <Box className="px-10">
          <Typography
            style={{ color: '#1A846E' }}
            my={4}
            className="!text-sm !font-bold"
          >
            No external assessments have been imported into G3MS. To import
            assessments, please reach out to help@getg3ms.com.
          </Typography>
        </Box>
      )}
      {isOpen && (
        <AddUpdateContent open={isOpen} onClose={handleAddUpdateContent} />
      )}
      <AssignContent
        imported={true}
        open={isOpenAssign}
        onClose={handleClose}
        selectedAssessments={selectedAssessments}
      />
    </Box>
  );
}

export default Index;
