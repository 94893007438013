import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

export interface ResponseData {
  status: String;
  messagecode: String;
  message: String;
  data: any;
}

interface InitialState {
  studentInfo: any;
  studentDetail: any;
  studentList: any;
  studentListByClassroom: any;
  studentListByGradeIdTopicId: any;
  studentListLoading: boolean;
}

const initialState: InitialState = {
  studentInfo: null,
  studentDetail: null,
  studentListByClassroom: [],
  studentListByGradeIdTopicId: [],
  studentList: [],
  studentListLoading: false,
};

const StudentSlice = createSlice({
  name: 'School_slice',
  initialState,
  reducers: {
    setStudent: (state: Draft<InitialState>, action: PayloadAction<[]>) => {
      state.studentInfo = action.payload;
    },
    setStudentDetail: (
      state: Draft<InitialState>,
      action: PayloadAction<[]>,
    ) => {
      state.studentDetail = action.payload;
    },
    setStudentListByClassroom: (
      state: Draft<InitialState>,
      action: PayloadAction<[]>,
    ) => {
      state.studentListByClassroom = action.payload;
    },
    setStudentListByGradeIdTopicId: (
      state: Draft<InitialState>,
      action: PayloadAction<[]>,
    ) => {
      state.studentListByGradeIdTopicId = action.payload;
    },
    setStudentList: (state: Draft<InitialState>, action: PayloadAction<[]>) => {
      state.studentList = action.payload;
    },
    setStudentListLoading: (
      state: Draft<InitialState>,
      action: PayloadAction<boolean>,
    ) => {
      state.studentListLoading = action.payload;
    },
  },
});

export const {
  setStudent,
  setStudentDetail,
  setStudentListByClassroom,
  setStudentListByGradeIdTopicId,
  setStudentList,
  setStudentListLoading,
} = StudentSlice.actions;

export default StudentSlice.reducer;
