import React, { useEffect, useState } from 'react';
import { Roles, USA_STATES } from '@core/utils/global-mock';
import {
  Grid,
  TextField,
  InputLabel,
  Box,
  Typography,
  Button,
  Stack,
  Autocomplete,
  Checkbox,
  CircularProgress,
  Avatar,
  Select,
  MenuItem,
} from '@mui/material';
import DynamicAutocomplete from 'components/AutoComplete/DynamicAutocomplete';
import { useAppDispatch, useAppSelector } from 'store/store';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
  getAllStudentList,
  getStudentsOfSchool,
} from 'store/Thunk/studentThunk';
import ClearIcon from '@mui/icons-material/Clear';
import Validations from 'helper/validations';

import { createUserAtFirebaseAndSetAtBackend } from 'store/Thunk/authThunk';

import { getSchoolsInState, getStates } from 'store/Thunk/districtThunk';
import { getUserData } from 'store/Thunk/user';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateParent } from 'store/Thunk/parentThunk';
import { notificationSuccess } from 'store/Slice/notificationSlice';
import { LoadingButton } from '@mui/lab';
import { setUser } from 'store/Slice/userSlice';
import ViewLoaderBox from 'components/viewUser/loader';
import { setBtnLoading } from 'store/Slice/loadingSlice';
import { getLinkPartFromRole, getLoggedInUser } from 'helper/functions';
import { getAllSchoolListIds } from 'store/Thunk/schoolThunk';
import ImageViewPopup from 'components/popUp/imageViewPopup';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function Parent() {
  const teacherSchool: any = localStorage.getItem('teacherSchool');

  const [formError, setFormError] = useState<any>({});
  const [schoolOpen, setSchoolOpen] = useState<boolean>(false);
  const [openChildren, setOpenChildren] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<any>(null);
  const [imagePreview, setImagePreview] = useState<any>(null);
  const [statesList, setStatesList] = useState<any>([]);
  const [schoolList, setSchoolList] = useState<any>([]);
  const [loadingSchool, setLoadingSchool] = useState<boolean>(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [parentId] = useState(queryParams.get('id'));
  const { user, loading } = useAppSelector((state) => state.userReducer);
  const [childrenList, setChildrenList] = useState<any>([]);
  const { btnLoading } = useAppSelector((state: any) => state.loadingReducer);
  const dispatch = useAppDispatch();
  const initial = getLinkPartFromRole();
  const [imageView, setImageView] = useState(false);
  const navigate = useNavigate();
  const currentLoggedInUserSchool =
    localStorage.getItem('current_schoolId') || '';
  const loggedInUser = getLoggedInUser();
  const { schoolList: allSchoolList, loading: schoolLoading } = useAppSelector(
    (state) => state.schoolReducer,
  );
  const [formData, setFormData] = useState<any>({
    school: { id: teacherSchool || 'ds' },
    state: '',
    salutation: 'Mr',
  });

  useEffect(() => {
    if (parentId) {
      dispatch(getUserData(parentId));
    }
    return () => {
      dispatch(setUser([]));
      dispatch(setBtnLoading(false));
    };
  }, []);

  useEffect(() => {
    if (user) {
      const school = user?.school?.length > 0 ? user?.school[0]?.school : {};
      setFormData({
        salutation: user?.salutation || '',
        name: user?.name,
        role: { label: Roles.parent },
        email: user?.email || '',
        school: user?.school,
        children:
          user?.dependentOfUser?.map((item: any) => {
            return {
              id: item?.user?.id,
              name: item?.user?.name,
              email: item?.user?.email,
            };
          }) || [],
        state: { id: user?.school?.state, name: user?.school?.state },
      });
      if (user?.profileImage) {
        setImagePreview(user?.profileImage);
      }
    }
  }, [user, statesList]);

  useEffect(() => {
    if (schoolOpen && formData?.state && loggedInUser?.type === 'super_admin') {
      setLoadingSchool(true);
      dispatch(getSchoolsInState(formData?.state?.name))
        .unwrap()
        .then((res) => {
          if (res?.length > 0) {
            setSchoolList(
              res?.map((item: any) => {
                return {
                  id: item.id,
                  name: item?.name,
                };
              }),
            );
          }
        })
        .finally(() => {
          setLoadingSchool(false);
        });
    }
    if (schoolOpen && loggedInUser?.type === 'school_leader') {
      dispatch(getAllSchoolListIds({}));
    }
  }, [schoolOpen]);
  const { stateList } = useAppSelector((state: any) => state.districtReducer);

  useEffect(() => {
    dispatch(getStates({}))
      .unwrap()
      .then((res) => {
        if (res.length > 0) {
          setStatesList(
            res?.map((item: string) => {
              return {
                label: item,
                value: item,
              };
            }),
          );
        }
      });
  }, []);

  useEffect(() => {
    if (openChildren && formData?.school?.id) {
      dispatch(getStudentsOfSchool(formData.school.id))
        .unwrap()
        .then((res: any) => {
          setChildrenList(res);
        });
      setOpenChildren(false);
    }
  }, [openChildren]);

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      setImageFile(file);
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChangeAutocomplete =
    (name: string) => (value: string | null | any) => {
      let param = {
        [name]: value,
        ...(name === 'school' && {
          children: [],
        }),
      };
      setFormData({ ...formData, ...param });
    };

  const onSubmit = (event: any) => {
    event.preventDefault();
    let parentInfo = {
      salutation: formData?.salutation || '',
      name: formData?.name || '',
      role: 'Parent',
      email: formData?.email || '',
      school: formData?.school?.id || '',
      children: formData?.children?.map((item: any) => item?.id) || [],
      state: formData?.state.name || '',
      image: imageFile,
    };

    let allError = Validations.validateAddParentForm(
      parentInfo,
      loggedInUser?.type,
    );

    if (
      Object.entries(allError).length === 0 &&
      allError.constructor === Object
    ) {
      if (parentId) {
        dispatch(setBtnLoading(true));
        dispatch(updateParent({ ...parentInfo, uuid: parentId }))
          .unwrap()
          .then(() => {
            dispatch(notificationSuccess('Parent Updated Successfully'));
            setTimeout(() => {
              dispatch(setBtnLoading(false));
              navigate(`/${initial}/user-management?tab=1`);
            }, 400);
          })
          .catch((err) => {
            dispatch(setBtnLoading(false));
          });
      } else {
        dispatch(
          createUserAtFirebaseAndSetAtBackend({
            userInfo: { ...parentInfo },
          }),
        );
      }
    } else {
      setFormError(allError);
    }
  };

  const handleCancel = () => {
    navigate(`/${initial}/user-management?tab=1`);
  };

  if (loading) {
    return <ViewLoaderBox allowShadow={false} />;
  }
  return (
    <>
      <Grid sx={{ p: 4 }} container spacing={4}>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between">
            <Typography color="#000" fontWeight={700} variant="body1">
              User Information
            </Typography>
            <input
              accept="image/*"
              id="contained-button-file"
              type="file"
              hidden
              onChange={handleImageChange}
            />
            {imagePreview ? (
              <span
                style={{
                  background: '#a1f2cb',
                  padding: '10px 15px',
                  borderRadius: '6px',
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'center',
                  width: '168px',
                  justifyContent: 'space-between',
                  color: '#000',
                  cursor: 'pointer',
                }}
              >
                <span onClick={() => setImageView(true)}>View Image</span>
                <span
                  onClick={() => {
                    setImagePreview('');
                    setImageFile(null);
                  }}
                  style={{ marginBottom: '-5px', cursor: 'pointer' }}
                >
                  <ClearIcon />
                </span>
              </span>
            ) : (
              <label htmlFor="contained-button-file">
                <Button
                  sx={{ borderStyle: 'dashed' }}
                  variant="outlined"
                  component="span"
                >
                  Upload Image
                </Button>
              </label>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InputLabel
            error={Boolean(formError?.salutation)}
            sx={{ color: '#484848', pb: 2 }}
          >
            Salutation
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={formData?.salutation}
            label="Salutation"
            error={Boolean(formError?.salutation)}
            onChange={(e) =>
              setFormData({
                ...formData,
                salutation: e.target.value,
              })
            }
            sx={{ width: '100%' }}
          >
            <MenuItem value={'Mr'}>Mr</MenuItem>
            <MenuItem value={"Mr's"}>Mr's</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputLabel
            error={Boolean(formError?.name?.message)}
            sx={{ color: '#484848', pb: 2 }}
          >
            Full Name
          </InputLabel>
          <TextField
            required
            fullWidth
            id="Full name"
            value={formData?.name || ''}
            onChange={handleChangeInput}
            name="name"
            error={formError && formError?.name}
            helperText={formError && formError?.name ? formError.name : ''}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DynamicAutocomplete
            id="role"
            label="Role"
            getOptionLabel={(option) => option?.label || ''}
            disable
            options={[{ label: Roles.parent }]}
            filterSelectedOptions
            multiple={false}
            value={
              formData?.role?.length > 0
                ? formData?.role[0]
                : formData?.role || { label: Roles.parent }
            }
            onChange={(event, value) => handleChangeAutocomplete('role')(value)}
            placeholder="Select Role"
            error={formError && formError?.role}
            helperText={formError && formError?.role ? formError.role : ''}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InputLabel
            error={Boolean(formError?.email?.message)}
            sx={{ color: '#484848', pb: 2 }}
          >
            Email
          </InputLabel>
          <TextField
            required
            fullWidth
            id="email"
            type="email"
            disabled={Boolean(parentId)}
            value={formData?.email || ''}
            onChange={handleChangeInput}
            name="email"
            error={formError && formError?.email}
            helperText={formError && formError?.email ? formError.email : ''}
          />
        </Grid>

        {loggedInUser?.type === 'super_admin' && (
          <>
            <Grid item xs={12} sm={6} md={4}>
              <DynamicAutocomplete
                id="state"
                label="State"
                options={stateList || []}
                getOptionLabel={(option) => option.name || ''}
                multiple={false}
                value={formData?.state || ''}
                onChange={(event, value) =>
                  handleChangeAutocomplete('state')(value)
                }
                placeholder="Select State"
                error={formError && formError?.state}
                helperText={
                  formError && formError?.state ? formError.state : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <DynamicAutocomplete
                id="parent"
                label="School"
                multiple={false}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option?.name || option?.id || ''}
                options={schoolList?.length > 0 ? schoolList : []}
                onOpen={() => setSchoolOpen(true)}
                onClose={() => setSchoolOpen(false)}
                loading={loadingSchool}
                open={schoolOpen}
                filterSelectedOptions
                value={formData?.school ? formData?.school : { name: '' }}
                onChange={(event, value) =>
                  handleChangeAutocomplete('school')(value)
                }
                placeholder="Select School"
                error={formError && formError?.school}
                helperText={
                  formError && formError?.school ? formError.school : ''
                }
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <InputLabel error={false} sx={{ color: '#484848', pb: 2 }}>
            Children
          </InputLabel>
          <Autocomplete
            multiple={true}
            id="tags-standard"
            loading={false}
            onOpen={() => setOpenChildren(true)}
            onClose={() => setOpenChildren(false)}
            options={childrenList}
            disableCloseOnSelect
            isOptionEqualToValue={(option, value) => option.name === value.name}
            value={formData?.children || []}
            onChange={(event, value) =>
              handleChangeAutocomplete('children')(value)
            }
            getOptionLabel={(option: any) => option.name || ''}
            renderOption={(props, option, { selected }) => (
              <li
                {...props}
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <span>{option?.id}</span>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Avatar sx={{ width: '22px', height: '22px' }} />
                  </Grid>
                  <Grid item>
                    <span>{option?.name}</span>
                  </Grid>
                </Grid>
                <span>{option?.email}</span>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginLeft: 8 }}
                  checked={selected}
                  sx={{
                    ' &.Mui-checked': {
                      color: '#C73E9D',
                    },
                  }}
                />
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Children"
                error={formError && formError?.children}
                helperText={
                  formError && formError?.children ? formError.children : ''
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {false ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={12}
          justifyContent="flex-end"
          sx={{ mt: 5, display: 'flex', gap: '20px' }}
        >
          <Button
            className="gray_bg_btn"
            sx={{ width: '163px', height: '44px' }}
            variant="contained"
            disabled={btnLoading}
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={btnLoading}
            onClick={onSubmit}
            sx={{
              width: '163px',
              height: '44px',
              '& .MuiLoadingButton-loadingIndicator': {
                color: 'white',
              },
            }}
            className="primary_bg_btn"
          >
            {parentId ? 'Update' : 'Apply'}
          </LoadingButton>
        </Grid>
      </Grid>
      <ImageViewPopup
        imageUrl={imagePreview}
        open={imageView}
        onClose={() => setImageView(false)}
      />
    </>
  );
}

export default Parent;
