import { createAsyncThunk } from '@reduxjs/toolkit';
import API from 'store/api';
import { setBtnLoading } from 'store/Slice/loadingSlice';
import {
  notificationFail,
  notificationSuccess,
} from 'store/Slice/notificationSlice';

export const addUpdateDomain = createAsyncThunk(
  'addUpdateDomain',
  async (formData: any, { dispatch }) => {
    let url = `/api/v1/domains`;
    try {
      const response = await API.post(url, formData);
      dispatch(notificationSuccess('Domain created successfully'));
      return response?.data?.data;
    } catch (err: any) {
      dispatch(notificationFail(err.response.data.message));
      return false;
    }
  },
);
