export default function checkIfExists(
  userId: string,
  skillId: string,
  selected: any[],
) {
  let exists = false;

  selected?.forEach((assessment) => {
    if (
      assessment.userIds?.includes(userId) &&
      assessment.assignmentIds?.includes(skillId)
    ) {
      exists = true;
    }
  });

  return exists;
}
