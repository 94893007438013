import React, { useCallback, useEffect, useRef, useState } from 'react';
import './styles/style.css';
import { ReactComponent as EyeIcon } from './assets/eye-icon.svg';
import { sampleColumns, sampleRows } from './helpers/helpers';

///
import { ReactComponent as DropDownIcon } from '../skills_chart/assets/drop-down-svg.svg';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { tabsAssignment } from '../skills_chart/helpers/helpers';
import QuizView from './components/viewQuiz';
import { getSuperAdminAssignments } from 'store/Thunk/dashboardThunk';
import { startLoading } from 'store/Slice/loadingSlice';
import { useAppDispatch } from 'store/store';
import { getLocalDateTime } from 'helper/service';

const ProgressDetailSection = ({
  sectionTitle,
  data,
}: {
  sectionTitle: string;
  data: any;
}) => {
  const [selectTab, setSelectTab] = React.useState({
    name: 'Bellwork',
    value: 'Bellwork',
  });
  const [isOpen, setIsOpen] = React.useState(false);

  const onChangeHandler = (item: any) => {
    console.log(item);
  };
  return (
    <div style={StylesTab.mainContainer}>
      <div className="section-header" style={StylesTab.sectionHeader}>
        <div className="right-aligned" style={StylesTab.rightAligned}>
          {tabsAssignment.map((value, index) => {
            const selectedStyles = {
              lineHeight: '20px',
              fontWeight: 600,
              borderBottom: '4px solid #C73E9D',
            };
            return (
              <div
                className="tab-item"
                onClick={() => setSelectTab(value)}
                style={{
                  ...StylesTab.tabItem,
                  lineHeight: '20px',
                  color:
                    selectTab.value === value.value ? '#484848' : '#84818A',
                  ...(selectTab.value === value.value ? selectedStyles : {}),
                }}
              >
                {value.name}
              </div>
            );
          })}
        </div>
      </div>

      <div
        className="detailedProgressTable-section-body"
        style={Styles.sectionBody}
      >
        <StickyHeadTable selectTab={selectTab} />
      </div>
    </div>
  );
};

export default ProgressDetailSection;

const Styles = {
  mainContainer: {
    marginTop: '2rem', //TODO: remove this
    width: '100%',
    height: 'auto',
    backgroundColor: 'white',
    color: '#484848',
  },
  sectionHeader: {
    height: '60px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 2rem',
  },
  rightAligned: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',

    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '28px',
    marginTop: '1rem',
  },
  sectionBody: {
    padding: '1.5rem 2rem 1rem 2rem',
    width: '100%',
  },
};

function StickyHeadTable({ selectTab }: { selectTab: any }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const fetchIdRef = useRef(0);
  const [videoList, setVideoList] = useState([]);
  const [assignmentMeta, setAssignmentMeta] = useState<any>({});
  const dispatch: any = useAppDispatch();
  console.log('selectTab', selectTab);
  console.log('selectTab', videoList);
  const fetchData = useCallback(
    ({ pageSize, pageIndex, search }: any) => {
      const fetchId = ++fetchIdRef.current;
      // We'll even set a delay to simulate a server here
      if (fetchId === fetchIdRef.current) {
        dispatch(startLoading());
        dispatch(
          getSuperAdminAssignments({
            type: selectTab.value,
            limit: pageSize,
            page: pageIndex + 1,
          }),
        )
          .unwrap()
          .then((res: any) => {
            setVideoList(res.items);
            setAssignmentMeta(res.meta);
          });
      }
      // setTimeout(() => {}, 1000);
    },
    [page, selectTab.value],
  );
  useEffect(() => {
    dispatch(getSuperAdminAssignments({ type: selectTab.value }))
      .unwrap()
      .then((res: any) => {
        setVideoList(res.items);
        setAssignmentMeta(res.meta);
      });
  }, []);
  useEffect(() => {
    dispatch(getSuperAdminAssignments({ type: selectTab.value }))
      .unwrap()
      .then((res: any) => {
        setVideoList(res.items);
        setAssignmentMeta(res.meta);
      });
  }, [selectTab]);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    fetchData({
      pageIndex: newPage,
      pageSize: rowsPerPage,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    fetchData({
      pageIndex: 1,
      pageSize: +event.target.value,
    });
  };

  const [isOpenDelete, setIsOpenDelete] = React.useState<boolean>(false);

  const [isOpenAssignmentView, setIsOpenAssignmentView] =
    React.useState<boolean>(false);
  const [selectedAssignement, setSelectedAssignment] = React.useState<any>({});
  const handleAssignmentView = (assignement: any) => {
    setIsOpenAssignmentView(true);
    setSelectedAssignment(assignement);
  };
  const handleCloseView = (open: boolean) => {
    setIsOpenAssignmentView(false);
    setIsOpenDelete(false);
  };
  console.log('selectTab', selectedAssignement);

  return (
    <Paper
      sx={{ width: '100%', overflow: 'hidden', borderRadius: '6px !important' }}
    >
      <TableContainer
        sx={{ maxHeight: 440, minWidth: 1000, overflowX: 'scroll' }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow
              sx={{
                overflow: 'visible !important',
              }}
            >
              {sampleColumns.map((column) => (
                <TableCell
                  sx={{
                    backgroundColor: '#E9F6F3 !important',
                    borderRadius: '0px !important',
                    fontWeight: '500 !important',
                    lineHeight: '21px !important',
                    fontSize: '14px !important',
                    color: '#52525B !important',
                    textTransform: 'none !important',
                  }}
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.styles.minWidth,
                    maxWidth: column.styles.maxWidth,
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {videoList?.map((row) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  sx={{
                    height: '80px !important',
                  }}
                >
                  {sampleColumns.map((column) => {
                    const value = row[column.id];

                    return (
                      <TableCell
                        sx={{
                          width:
                            column.id === 'date'
                              ? '15%'
                              : column.id === 'topic'
                              ? '10%'
                              : 'unset',
                        }}
                        key={column.id}
                        align={column.align}
                      >
                        <DisplayCorrectColumn
                          column={column}
                          value={value}
                          row={row}
                          handleAssignmentView={handleAssignmentView}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={assignmentMeta?.total || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <QuizView
        open={isOpenAssignmentView}
        onClose={handleCloseView}
        selectedAssignement={selectedAssignement}
        assignmentType={selectTab?.value}
      />
    </Paper>
  );
}

const DisplayCorrectColumn = ({
  column,
  value,
  row,
  handleAssignmentView,
}: {
  column: any;
  value: any;
  row: any;
  handleAssignmentView: any;
}) => {
  if (column.id === 'action') {
    return (
      <div
        className="action-buttons"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {value.map((action: string, index: number) => {
          return (
            <div
              className="action-button"
              key={`${action}-${index}`}
              onClick={() => handleAssignmentView(row)}
              style={{
                backgroundColor:
                  action === 'Approve'
                    ? '#66CB63'
                    : action === 'Reject'
                    ? '#DF3023'
                    : '',
                borderRadius: '8px',
                display: 'flex',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                color: '#FFFFFF',
                fontSize: '14px',
              }}
            >
              {<EyeIcon />}
            </div>
          );
        })}
      </div>
    );
  }

  if (column.id === 'video') {
    return (
      <div
        style={{
          display: 'flex',
        }}
      >
        <img
          src={value.thumbNail}
          alt=""
          style={{
            width: 60,
            height: 47,
            borderRadius: 4,
            marginRight: 5,
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            className="video-title"
            style={{
              color: '#131515',
              fontWeight: 600,
              fontSize: '12px',
            }}
          >
            {value.title}
          </div>
          <div
            className="video-length"
            style={{
              color: '#979C9E',
            }}
          >
            {value.length}
          </div>
        </div>
      </div>
    );
  }
  if (column.id === 'date') {
    return (
      <div
        style={{
          fontSize: '13px',
          fontWeight: 400,
        }}
      >
        {getLocalDateTime(value, 'MMMM D, YYYY')}
      </div>
    );
  }
  if (column.id === 'skill') {
    return (
      <div
        style={{
          color: '#3454CF',
        }}
      >
        {value?.skill[0]?.skill?.name}
      </div>
    );
  }
  if (column.id === 'topic') {
    return (
      <div
        style={{
          color: '#3454CF',
        }}
      >
        {value?.topic[0]?.topic?.name}
      </div>
    );
  }
  if (column.id === 'score') {
    return (
      <div
        style={{
          color: parseInt(value) > 50 ? '#131515' : '#F41F52',
          fontWeight: 700,
        }}
      >
        {value}%
      </div>
    );
  }

  if (column.id === 'student') {
    return (
      <div
        className="student-container"
        style={{
          display: 'flex',
          minWidth: column.minWidth,
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <img
          src={value.imageUrl}
          alt=""
          style={{
            width: '32px',
            height: '32px',
            borderRadius: '50%',
            marginRight: '5px',
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            className="student-name"
            style={{
              color: '#484848',
              fontWeight: 600,
              fontSize: '12px',
            }}
          >
            {value.name} (ID: {value.id})
          </div>
        </div>
      </div>
    );
  }

  if (!column.id) {
    return <div></div>;
  }

  return (
    <div
      style={{
        maxWidth: column.id === 'skill' ? 150 : 200,
        fontSize: '13px',
        fontWeight: 400,
      }}
    >
      {value}
    </div>
  );
};
const StylesTab = {
  mainContainer: {
    width: '100%',
    height: 'auto',
    backgroundColor: 'white',
    color: '#484848',
    marginTop: '1.5rem',
  },
  sectionHeader: {
    height: '65px',
    borderBottom: '1px solid #E5E5E5',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 2rem',
  },
  rightAligned: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },
  tabItem: {
    cursor: 'pointer',
    height: '100%',
    marginRight: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 1rem',
    fontFamily: 'Inter',
    fontSize: '14px',
  },
  rangePickerContainer: {
    cursor: 'pointer',
    fontFamily: 'Inter',
    fontSize: '14px',
    color: '#484848',
    fontWeight: 500,
    border: '1px solid #E0E2E7',
    padding: '10px 14px',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
  },
  sectionBody: {
    padding: '1.5rem 2rem 1rem 2rem',
  },
  groupList: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '0.5rem',
  },
};
