import React, { useState } from "react";
import MySchedule from "./tabs/my-schedule";
import Videos from "./tabs/videos";
import { Avatar, Box, Button, Card, IconButton, Stack, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import { profile } from "assets";
import { Theme } from '@mui/material/styles'

import { IoMdContacts } from "react-icons/io";
import { FaRegNewspaper } from "react-icons/fa";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { ShareIcon } from "icons";
import UsersIcon from "icons/users";
import PaperIcon from "icons/paper";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function Dashboard() {
  const [value, setValue] = useState(0);

  const mobileSize = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const headerResponsiveStyles = () => {
    return {
      ...(!mobileSize ? {borderRadius: "32px 32px 0px 0px", mt: "12px"} : {borderRadius: "0px"})
    };
  };
  return (
    <Card sx={{...headerResponsiveStyles()}}>
      <Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="relative"
          minHeight="200px"
          borderRadius="xl"
          sx={{
            backgroundColor: "#E9F6F3",
            overflow: "hidden",
            ...(mobileSize && {borderRadius: "0px 0px 40px 40px"})
          }}
        >
          {mobileSize && (<Box my={4}>
            <IconButton
              aria-label="settings"
              sx={{ position: "absolute", left: "0px", top: "0px", m: 1 }}
            >
              <ArrowBackRoundedIcon />
            </IconButton>
            <IconButton
              aria-label="settings"
              sx={{ position: "absolute", right: "0px", top: "0px", m: 1 }}
            >
              <ShareIcon />
            </IconButton>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                my: 6,
              }}
            >
              <Avatar
                alt={"avatar_profile"}
                className="avatar_profile"
                src={profile}
              />
              <Box display="flex" flexDirection="row" sx={{ml: 3, mb: 2, mt: 1}}>
                <Typography variant="h6" className="abbreviation_txt">Mrs.</Typography>
                <Typography variant="h6" className="profile_name_txt">Terry Kenter</Typography>
              </Box>
              <Stack direction="row" spacing={2} mt={1}>
                <Button 
                  size="small"
                  className="follower_btn"
                  startIcon={<UsersIcon />}>
                  3k
                </Button>
                <Button
                  size="small"
                  className="follower_btn"
                  startIcon={<PaperIcon/>}
                >
                  3k
                </Button>
            </Stack>
            </Box>
          </Box>)}
        </Box>
        {
          mobileSize && (
            <Box display="flex" position={"relative"} mt={"-18px"} justifyContent={"center"}>
              <Box>
                  <Button variant='contained' className="gray_bg_btn">
                    Go Live
                  </Button>
                  <Button variant='contained' className="primary_bg_btn" sx={{ ml: 3}}>
                    Create Session
                  </Button>
              </Box>
            </Box>
          )
        }
        { !mobileSize && (<Box display="flex" position={"relative"} mt={"-4rem"}>
          <Box flexGrow={1} ml={14} display={"flex"} alignItems={"flex-end"}>
            <Avatar
              className="avatar_profile"
              src={profile}
            />
            <Box display="flex" flexDirection="row" sx={{ml: 3, mb: 2}}>
            <Typography variant="h6" className="abbreviation_txt">Mrs.</Typography>
            <Typography variant="h6" className="profile_name_txt">Terry Kenter</Typography>
            </Box>
          </Box>
          <Box display="flex" justifyContent={"space-around"} alignItems={"flex-end"} flexDirection={"column"} flexGrow={1} mr={"16px"}>
            <Stack direction="row" spacing={2}>
              <Button 
                className="white_bg_btn"
                startIcon={<IoMdContacts style={{ color: "#20A58A" }} />}>
                300k Students
              </Button>
              <Button
                className="white_bg_btn"
                startIcon={<FaRegNewspaper style={{ color: "#20A58A" }} />}
              >
                300k Students
              </Button>
            </Stack>
            <Box display="flex">
              <Button variant='contained' className="gray_bg_btn">
                Videos
              </Button>
              <Button variant='contained' className="primary_bg_btn" sx={{ ml: 3}}>
                Create Session
              </Button>
            </Box>
          </Box>
        </Box>)}

        <Box mt="14px">
          <Box className="teacher_tabs_container">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="MySchedule" {...a11yProps(0)} />
              <Tab label="Videos" {...a11yProps(1)} />
            </Tabs>
          </Box>

          <Box>
            <TabPanel value={value} index={0}>
                <MySchedule />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Videos />
            </TabPanel>
          </Box>
        </Box>
      </Box>
    </Card>
    // <div className="main_div_teacher_profile">
    //   <div className="innerdiv_teacher_profile">
    //     <div className="profile_teacher_">
    //       <div className="profile-section">
    //         <Avatar
    //           className="avatar_profile"
    //           // size={126.82}
    //           src={profile}
    //         />
    //       </div>
    //       <div className="profile-section-rights_side">
    //         <div className="rights_side">
    //           <Button
    //             className="Studentsbtn"
    //             sx={{
    //               color: "#000",
    //               background: "#fff"
    //             }}
    //             startIcon={<IoMdContacts style={{ color: "#20A58A" }} />}
    //             // icon={<IoMdContacts style={{ color: "#20A58A" }} />}
    //           >
    //             300k Students
    //           </Button>
    //           <Button
    //             className="Studentsbtn"
    //             sx={{
    //               marginLeft: "9px",
    //               color: "#000",
    //               background: "#fff"
    //             }}
    //             startIcon={<FaRegNewspaper style={{ color: "#20A58A" }} />}
    //           >
    //             300k Students
    //           </Button>
    //         </div>
    //         <div className="rights_side2">
    //           <Button
    //             className="Studentsbtns"
    //             style={{
    //               marginLeft: "9px",
    //               display: "flex",
    //               justifyContent: "center",
    //               alignItems: "center",
    //               backgroundColor: "#D9D9D9",
    //               color: "black",
    //             }}
    //           >
    //             Go Live
    //           </Button>
    //           <Button
    //             className="Studentsbtns"
    //             style={{
    //               marginLeft: "9px",
    //               display: "flex",
    //               justifyContent: "center",
    //               alignItems: "center",
    //               backgroundColor: "#C73E9D",
    //               color: "white",
    //             }}
    //           >
    //             Create Session
    //           </Button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>

    //   <div className="teacher_profie_taps">
    //     <Box sx={{ padding: 0 }}>
    //       <Tabs
    //         value={value}
    //         onChange={handleChange}
    //         aria-label="basic tabs example"
    //       >
    //         <Tab label="MySchedule" {...a11yProps(0)} />
    //         <Tab label="Videos" {...a11yProps(1)} />
    //       </Tabs>
    //     </Box>

    //     <Box>
    //       <TabPanel value={value} index={0}>
    //         <MySchedule />
    //       </TabPanel>
    //       <TabPanel value={value} index={1}>
    //         <Videos />
    //       </TabPanel>
    //     </Box>
    //   </div>
    // </div>
  );
}
