import { AutoAwesome } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

export default function LoadingAI() {
  return (
    <Stack className="w-full h-full justify-center items-center">
      <ThreeDots
        visible={true}
        height="100"
        width="100"
        color="#1A846E"
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />

      <Typography
        className="!font-semibold !text-2xl text#131515 !mt-20 mb-4"
        sx={{ color: '#131515' }}
      >
        Estimated time to generate questions: Less than a minute left
      </Typography>
      <Typography className="!font-normal !text-base">
        Please do not go back while generating the questions.
      </Typography>
      <Box
        className="mt-8 flex gap-3"
        sx={{
          backgroundColor: '#E9F6F3',
          padding: '12px 16px',
          borderRadius: '8px',
        }}
      >
        <AutoAwesome color="#20A58A" sx={{ color: '#20A58A' }} />
        <Typography className="!font-medium !text-base !text-black">
          <b>AI tip:</b> You can edit questions with AI enhance after they’re
          generated.
        </Typography>
      </Box>
    </Stack>
  );
}
