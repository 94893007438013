import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { ITeacher } from '../../components/Dashboard/table';

interface IntialState {
  teacherProgress: any[];
  superAdminCounts: any;
  superAdminVideos: any;
  superAdminAssignments: any;
  skillsMastered: any[];
  totalCounts: any;
  totalStudentsCount: number;
  totalTeachersCount: number;
  totalSchoolLeadersCount: number;
  totalParentsCount: number;
  totalClassroomsCount: number;
  schoolTeacherClassroomStudentInfo?: SchoolInfo;
  teachers: ITeacher[];
}
interface SchoolInfo {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  website: string;
  jobTitle: string;
  contactType: string;
  schoolType: string;
  specificSchoolType: string;
  schoolGrades: string;
  name: string;
  ncesDistrictId: string;
  ncesSchoolId: string;
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  phone: string;
  totalNoOfStudent: string;
  createdAt: string;
  updatedAt: string;
}

const intialState: IntialState = {
  teachers: [],
  skillsMastered: [
    {
      tableTitle: 'Skills mastered',
      tableData: [],
    },
    {
      tableTitle: 'Watched hours',
      tableData: [],
    },
  ],
  teacherProgress: [],
  totalClassroomsCount: 0,
  totalParentsCount: 0,
  totalSchoolLeadersCount: 0,
  totalStudentsCount: 0,
  totalTeachersCount: 0,
  superAdminCounts: 0,
  superAdminVideos: [],
  superAdminAssignments: [],
  totalCounts: {},
};

const DashboardsSlice = createSlice({
  name: 'dashboard_slice',
  initialState: intialState,
  reducers: {
    setDashboardInfo: (
      state: Draft<IntialState>,
      action: PayloadAction<any>,
    ) => {
      state.totalCounts = action.payload;
    },
    setDashboardTeachers: (
      state: Draft<IntialState>,
      action: PayloadAction<[]>,
    ) => {
      state.teachers = action.payload;
    },
    setDashboardLeaderTeacherProgress: (
      state: Draft<IntialState>,
      action: PayloadAction<[]>,
    ) => {
      state.teacherProgress = action.payload;
    },
    setDashboardLeaderSkillsMastered: (
      state: Draft<IntialState>,
      action: PayloadAction<[]>,
    ) => {
      state.skillsMastered = action.payload;
    },
    setSuperAdminCounts: (
      state: Draft<IntialState>,
      action: PayloadAction<{}>,
    ) => {
      state.superAdminCounts = action.payload;
    },
    setSuperAdminVideos: (
      state: Draft<IntialState>,
      action: PayloadAction<{}>,
    ) => {
      state.superAdminVideos = action.payload;
    },
    setSuperAdminAssignemtns: (
      state: Draft<IntialState>,
      action: PayloadAction<{}>,
    ) => {
      state.superAdminAssignments = action.payload;
    },
  },
});

export const {
  setDashboardTeachers,
  setDashboardInfo,
  setSuperAdminAssignemtns,
  setSuperAdminCounts,
  setSuperAdminVideos,
  setDashboardLeaderTeacherProgress,
  setDashboardLeaderSkillsMastered,
} = DashboardsSlice.actions;

export default DashboardsSlice.reducer;
