import React, { useState } from 'react';
import Card from '@mui/material/Card';

import Typography from '@mui/material/Typography';
import { EditIcon, DeleteIcon, ViewIcon } from 'icons';
import EyeBorderIcon from 'icons/eyeBorderIcon';
import DeleteBorderIcon from 'icons/deleteBorderIcon';
import EditBorderIcon from 'icons/editBorderIcon';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';

// import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';

import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Box,
  Checkbox,
  Pagination,
  TextField,
} from '@mui/material';
import { Bell } from 'mdi-material-ui';
import { VisibilityOutlined } from '@mui/icons-material';

export interface Header {
  label: string;
  width: number;
  value: string;
}
export interface IStudent {
  id?: string;
  assignmentId?: string;
  setAssignments?: any;
  count?: number;
  name?: string;
  skill?: string;
  topic?: string;
  subTopic?: string;
  assignmentType?: string;
  totalGems?: string;
  pending?: string;
  status?: string;
  startDate?: string;
  endDate?: string;
}

export interface IClasses {
  id?: string;
  name?: string;
  totalAssignments?: string;
  completedAssignments?: string;
  pendingAssignments?: string;
  totalGems?: string;
  interVentionRecommended?: string;
  averageScore?: string;
  studentCount?: string;
  target?: string;
  Assignments?: IStudent[];
  setAssignments?: any;
}

const StudentsList = ({
  students,
  setAssignments,
}: {
  students: IStudent[] | undefined;
  setAssignments: any;
}) => {
  let allAssignments: IStudent[] = [];
  if (students && students.length > 0) {
    allAssignments = students;
  }
  const handleIncreaseCount = (assignmentId: any) => {
    console.log('assignmentId', assignmentId);
    console.log('assignmentId', allAssignments);

    setAssignments((prevAssignments: any) =>
      prevAssignments.map((assignment: any) => {
        if (
          assignment.assignments.some(
            (a: any) => a.assignmentId === assignmentId,
          )
        ) {
          return {
            ...assignment,
            assignments: assignment.assignments.map((a: any) =>
              a.assignmentId === assignmentId
                ? { ...a, count: a.count + 1 }
                : a,
            ),
          };
        }
        return assignment;
      }),
    );
  };

  const handleDecreaseCount = (assignmentId: any) => {
    console.log('assignmentId', assignmentId);
    setAssignments((prevAssignments: any) =>
      prevAssignments.map((assignment: any) => {
        if (
          assignment.assignments.some(
            (a: any) => a.assignmentId === assignmentId,
          )
        ) {
          return {
            ...assignment,
            assignments: assignment.assignments.map((a: any) =>
              a.assignmentId === assignmentId && a.count > 0
                ? { ...a, count: a.count - 1 }
                : a,
            ),
          };
        }
        return assignment;
      }),
    );
  };
  const urlParams = new URLSearchParams(window.location.search);
  const assignmentType = urlParams.get('assignmentType');

  return (
    <List component="div" className="!p-4">
      <Box className={'my-4'} px={2} bgcolor={'#F9F9F9'}>
        <Grid container spacing={4} mb={4}>
          <Grid item md={5.5} pb={4}>
            Subtopic
          </Grid>
          <Grid item md={4} pb={4}>
            Skill
          </Grid>
          <Grid item md={2.5} pb={4}>
            Number of question
          </Grid>
        </Grid>
      </Box>
      {allAssignments.map((assignment) => (
        <Grid container spacing={4} mb={4}>
          <Grid item md={5.5}>
            {assignment.subTopic}
          </Grid>
          <Grid item md={4}>
            {assignment?.skill}
          </Grid>
          <Grid item md={2.5}>
            <div className="flex justify-around !text-black">
              {assignmentType === 'diagnostic' && (
                <div className="px-4 gap-6 py-0 border-black border rounded-xl flex items-center justify-between">
                  <div
                    className="cursor-pointer"
                    onClick={() => handleDecreaseCount(assignment.assignmentId)}
                  >
                    -
                  </div>
                  <div className="font-bold">{assignment?.count}</div>
                  <div
                    className="cursor-pointer"
                    onClick={() => handleIncreaseCount(assignment.assignmentId)}
                  >
                    +
                  </div>
                </div>
              )}
              <Checkbox size="medium" checked />
            </div>
          </Grid>
        </Grid>
      ))}
    </List>
  );
};

export default function AssignConfirmationTable({
  openDefault,
  classes,
  setAssignments,
  assignments,
  color,
  pageIndex,
  pageSize,
  header,
  pageCount,
  showPagination,
  totalCount,
  entriesPerPage,
}: {
  openDefault?: any;
  classes?: any;
  pageCount?: any;
  assignments?: any;
  setAssignments?: any;
  pageIndex?: any;
  pageSize?: any;
  showPagination?: any;
  totalCount?: any;
  color?: string;
  entriesPerPage?: any;
  header?: Header[];
}) {
  const [open, setOpen] = React.useState(openDefault || false);
  console.log('classes', assignments);
  console.log('classes', classes);
  console.log('classes', header);
  const [search, setSearch] = useState('');

  const renderPagination = () => (
    <Pagination
      count={pageCount || 10}
      page={pageIndex || 4 + 1}
      shape="rounded"
      // onChange={handleChangePage}
    />
  );
  const setEntriesPerPage = (value: any) => {
    // fetchData({
    //   pageIndex: pageIndex,
    //   pageSize: value,
    //   search: search,
    // });
    // setPageSize(value);
  };

  const onSearchChange = (value: any) => {
    setSearch(value); // Update the search state with the current input value.
  };
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      {assignments?.map((assignments: any, i: number) => (
        <div
          style={{
            border: open ? '1px solid #DCDCDC' : 'none',
            borderRadius: '10px 10px 0px 0px',
            marginBottom: '16px',
          }}
        >
          <Card
            style={{
              backgroundColor: color,
              borderRadius: '10px 10px 0px 0px',
              boxShadow:
                '0px 2px 1px -1px rgba(76, 78, 100, 0.2),0px 1px 1px 0px rgba(76, 78, 100, 0.14),0px 1px 3px 0px rgba(76, 78, 100, 0.12)',
            }}
          >
            <ListItemButton
              style={{ padding: '3px 12px' }}
              onClick={handleClick}
            >
              {/* <ListItemIcon>
                        class
                    </ListItemIcon> */}

              <Grid
                container
                spacing={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  color: '#131515',
                  '& .css-6uzn28-MuiTypography-root': {
                    color: '#131515',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '22px',
                  },
                }}
              >
                {header?.map((head) => (
                  <Grid item className="min-h-14 flex items-center">
                    {head && assignments && (
                      <>
                        <Typography fontWeight={600} color={'#131515'}>
                          {assignments[head?.value] || 'N/A'}
                        </Typography>
                      </>
                    )}
                  </Grid>
                ))}
                <Grid
                  item
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {open ? <ExpandLess /> : <ExpandMore />}
                </Grid>
              </Grid>
            </ListItemButton>
          </Card>

          <Collapse in={open} timeout="auto" unmountOnExit>
            <StudentsList
              students={assignments?.assignments}
              setAssignments={setAssignments}
            />
          </Collapse>
        </div>
      ))}
      {/* {showPagination && ( */}
      {false && (
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          my={4}
          sx={{ border: '1px solid #E9EBED', borderRadius: '6px', p: 2 }}
        >
          {/* {entriesPerPage && ( */}
          {true && (
            <Box flex="1" display="flex" alignItems="center">
              <Typography variant="body2" className="entries-page-txt">
                Show&nbsp;&nbsp;
              </Typography>
              <Autocomplete
                disableClearable
                value={pageSize?.toString()}
                options={['10', '25', '50', '100']}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10));
                }}
                size="small"
                sx={{ width: '5rem' }}
                renderInput={(params) => <TextField {...params} />}
              />
              <Typography variant="body2" className="entries-page-txt">
                &nbsp;&nbsp;entries of {totalCount}
              </Typography>
            </Box>
          )}
          {renderPagination()}
        </Box>
      )}
    </>
  );
}
