import {
  Box,
  Typography,
  Card,
  Avatar,
  IconButton,
  Divider,
  Tooltip,
} from '@mui/material';
import style from './view.module.scss';
import React, { useEffect, useState } from 'react';
import { TypoItem } from './macros';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUserData } from 'store/Thunk/user';
import { DeleteBorderIcon, EditBorderIcon } from 'icons';
import SimpleTable from 'components/simpleTable/simpleTable';
import ViewLoaderBox from './loader';
import { getLinkPartFromRole } from 'helper/functions';
import { setUser } from 'store/Slice/userSlice';
import { getUserListAccordingToType } from 'store/Thunk/userThunk';
import { deleteParent } from 'store/Thunk/parentThunk';
import DeleteUserDialog from 'components/dialog/users/Delete/deleteUser';

function Parent() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [parentId] = useState(queryParams.get('id'));
  const { user, loading } = useAppSelector((state) => state.userReducer);
  const navigate = useNavigate();
  const initial = getLinkPartFromRole();

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedUserToDelete, setSelectedUserToDelete] = useState({
    id: user.id,
    name: user.name,
  });

  useEffect(() => {
    if (parentId) {
      dispatch(getUserData(parentId));
    }
    return () => {
      dispatch(setUser([]));
    };
  }, []);

  const handleEditClick = () => {
    const url = `/${initial}/user-management/edit?mode=parent&id=${parentId}`;
    navigate(url);
  };

  const getRows = () => {
    const data = user.dependentOfUser?.map((item: any) => {
      const { user } = item;
      const studendId = (
        <Tooltip title={user.id}>
          <Typography sx={{ color: 'black' }}>
            {user.id.substring(0, 20) + '..'}
          </Typography>
        </Tooltip>
      );
      const userName = user.name;
      const grade =
        item?.user.grade?.length > 0 ? item.grade?.grade[0]?.name : '-';
      const classroom =
        item?.user?.classroom?.length > 0
          ? item.user.classroom[0]?.clasroom?.name.includes(':')
            ? item.user.classroom[0]?.clasroom?.name.split(':')[1]
            : item.user.classroom[0]?.clasroom?.name
          : '-';

      return { studendId, userName, grade, classroom };
    });

    return data || [];
  };

  if (loading) {
    return <ViewLoaderBox allowShadow />;
  }
  const fetchTableData = () => {
    dispatch(
      getUserListAccordingToType({
        pageSize: 10,
        pageIndex: 0,
        search: '',
        userType: 'parent',
      }),
    );
  };
  const handleDeleteUser = () => {
    setDeleteLoading(true);
    dispatch(deleteParent(selectedUserToDelete.id))
      .then(() => {
        fetchTableData();
        navigate(`/${initial}/user-management?tab=1`);
      })
      .finally(() => {
        setOpenDeleteModal(false);
        setDeleteLoading(false);
        setSelectedUserToDelete({
          id: '',
          name: '',
        });
      });
  };
  return (
    <Card sx={{ p: 6, position: 'relative' }}>
      <Box className={style.content_wrapper}>
        {user?.profileImage ? (
          <img
            className={style.profile_img}
            alt={user?.name}
            src={user?.profileImage}
          />
        ) : (
          <Avatar sx={{ width: 100, height: 100, alignitems: 'center' }} />
        )}
        <div className={style.profile_detail_wrapper}>
          <Box className={style.profile_detail}>
            <Typography
              sx={{ color: '#20A58A', ntWeight: 700, fontSize: '23px' }}
            >
              {user?.name}
            </Typography>
            <Typography sx={{ display: '13.48px' }}>
              (ID: {user?.id})
            </Typography>
          </Box>
          <Box className={style.info}>
            <TypoItem title="Role" detail="Parent" />
            <TypoItem title="Email" detail={user?.email} />
            <TypoItem title="State" detail={user?.school?.state || '-'} />
            <TypoItem title="School Name" detail={user?.school?.name || ''} />
          </Box>
        </div>

        <Box sx={{ display: 'flex', position: 'absolute', top: 30, right: 30 }}>
          <IconButton onClick={() => handleEditClick()}>
            <EditBorderIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setOpenDeleteModal(true);
              setSelectedUserToDelete({
                id: user.id,
                name: user.name,
              });
            }}
          >
            <DeleteBorderIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider variant="inset" component="li" />
      <SimpleTable
        rows={getRows()}
        columns={['Student ID', 'Name', 'Grade', 'Class']}
        title={"Children's"}
      />
      <DeleteUserDialog
        loading={deleteLoading}
        open={openDeleteModal}
        title={`Are you sure you want to delete the user "${selectedUserToDelete?.name}" from G3MS?`}
        onOk={handleDeleteUser}
        onClose={() => {
          setOpenDeleteModal(false);
          setSelectedUserToDelete({
            id: '',
            name: '',
          });
        }}
      />
    </Card>
  );
}

export default Parent;
