import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useAppSelector } from 'store/store';
export default function StudentDetails() {
  const { studentDetail } = useAppSelector((state) => state.studentReducer);
  return (
    <Box className="items-center flex justify-between gap-5 max-md:flex-wrap mb-10">
      <div className="aspect-square object-contain object-center w-[120px] overflow-hidden shrink-0 max-w-full my-auto">
        <img loading="lazy" src={studentDetail?.profileImage} alt="My Image" />
      </div>
      <Box className="content-end flex-1 self-stretch flex-wrap max-md:max-w-full">
        <Box className="gap-5 flex flex-1 max-md:flex-col justify-between  max-md:items-stretch max-md:gap-0">
          <Box className="flex flex-col items-stretch  max-md:w-full max-md:ml-0 flex-1">
            <Box className="grow mt-2.5 px-5 max-md:max-w-full max-md:mt-10">
              <Box className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                <Box className="flex flex-col items-stretch w-[40%] max-md:w-full max-md:ml-0">
                  <Box className="flex grow flex-col items-stretch max-md:mt-5 absolute">
                    <Box className="items-stretch flex justify-between gap-4">
                      <p className="text-teal-600 text-2xl font-bold leading-6 grow whitespace-nowrap">
                        {studentDetail?.name}
                      </p>
                      <p className="text-neutral-400 text-sm self-center grow whitespace-nowrap my-auto">
                        (ID: {studentDetail?.id})
                      </p>
                    </Box>
                    <p className="text-neutral-400 text-base font-medium leading-6 mt-9">
                      Email
                    </p>
                    <p className="text-neutral-900 text-base whitespace-nowrap mt-3.5 self-start">
                      {studentDetail?.email}
                    </p>
                  </Box>
                </Box>
                <Box className="flex flex-col items-stretch w-[19%] ml-5 max-md:w-full max-md:ml-0">
                  <Box className="items-stretch flex grow flex-col mt-14 max-md:mt-10">
                    <p className="text-neutral-400 text-base font-medium leading-6">
                      Grade
                    </p>
                    <p className="text-neutral-900 text-base whitespace-nowrap mt-3.5">
                      {studentDetail?.grade}
                    </p>
                  </Box>
                </Box>
                <Box className="flex flex-col items-stretch w-[20%] ml-5 max-md:w-full max-md:ml-0">
                  <Box className="items-stretch flex grow flex-col mt-14 max-md:mt-10">
                    <p className="text-neutral-400 text-base font-medium leading-6">
                      Parent
                    </p>
                    <p className="text-neutral-900 text-base whitespace-nowrap mt-3.5">
                      {studentDetail?.parent?.name}
                    </p>
                  </Box>
                </Box>
                <Box className="flex flex-col items-stretch w-[21%] ml-5 max-md:w-full max-md:ml-0">
                  <Box className="items-stretch flex grow flex-col mt-14 max-md:mt-10">
                    <p className="text-neutral-400 text-base font-medium leading-6 whitespace-nowrap">
                      School Name
                    </p>
                    <p className="text-neutral-900 text-base whitespace-nowrap mt-3.5">
                      {studentDetail?.userSchool}
                    </p>
                  </Box>
                </Box>
                <Box className="flex flex-col items-stretch w-[10%] ml-5 max-md:w-full max-md:ml-0">
                  <Box className="items-stretch flex grow flex-col mt-14 max-md:mt-10">
                    {/* <p className="text-neutral-400 text-base font-medium leading-6">
                      Password
                    </p>
                    <p className="text-neutral-900 text-base whitespace-nowrap mt-3.5">
                      {studentDetail?.password || 'N/A'}
                    </p> */}
                  </Box>
                </Box>
                <Box className="flex flex-col items-start w-[10%] ml-5 max-md:w-full max-md:ml-0">
                  <Box className="items-start flex grow flex-col mt-4 max-md:mt-10">
                    <div className="aspect-[0.99] object-contain object-center w-[119px] overflow-hidden shrink-0 max-w-full self-start">
                      {/* <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/9c064c3f-3b2f-47b7-9bf0-0ed6a37d9ae7?apiKey=0379640166cc489a8cdc2270ac65e890"
                        alt="My Other Image"
                      /> */}
                    </div>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
