import {
  Box,
  Typography,
  Card,
  Avatar,
  IconButton,
  Divider,
  Tooltip,
} from '@mui/material';
import style from './view.module.scss';
import React, { useEffect, useState } from 'react';
import { ClassItem, TypoItem } from './macros';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUserData } from 'store/Thunk/user';
import { DeleteBorderIcon, EditBorderIcon } from 'icons';
import SimpleTable from 'components/simpleTable/simpleTable';
import ViewLoaderBox from './loader';
import { getLinkPartFromRole } from 'helper/functions';
import { setUser } from 'store/Slice/userSlice';
import DeleteUserDialog from 'components/dialog/users/Delete/deleteUser';
import { deleteTeacher } from 'store/Thunk/teacherThunk';
import { getUserListAccordingToType } from 'store/Thunk/userThunk';

function Parent() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [teacherId] = useState(queryParams.get('id'));
  const { user, loading } = useAppSelector((state) => state.userReducer);

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedUserToDelete, setSelectedUserToDelete] = useState({
    id: user.id,
    name: user.name,
  });

  const navigate = useNavigate();
  const initial = getLinkPartFromRole();

  useEffect(() => {
    if (teacherId) {
      dispatch(getUserData(teacherId));
    }
    return () => {
      dispatch(setUser([]));
    };
  }, []);

  const handleEditClick = () => {
    const url = `/${initial}/user-management/edit?mode=teacher&id=${teacherId}`;
    navigate(url);
  };

  if (loading) {
    return <ViewLoaderBox allowShadow />;
  }
  const fetchTableData = () => {
    dispatch(
      getUserListAccordingToType({
        pageSize: 10,
        pageIndex: 0,
        search: '',
        userType: 'teacher',
      }),
    );
  };
  const handleDeleteUser = () => {
    setDeleteLoading(true);
    dispatch(deleteTeacher(selectedUserToDelete.id))
      .then(() => {
        fetchTableData();
        navigate(`/${initial}/user-management?tab=0`);
      })
      .finally(() => {
        setOpenDeleteModal(false);
        setDeleteLoading(false);
        setSelectedUserToDelete({
          id: '',
          name: '',
        });
      });
  };
  return (
    <Card sx={{ p: 6, position: 'relative' }}>
      <Box className={style.content_wrapper}>
        {user?.profileImage ? (
          <img
            className={style.profile_img}
            alt={user?.name}
            src={user?.profileImage}
          />
        ) : (
          <Avatar sx={{ width: 100, height: 100, alignitems: 'center' }} />
        )}
        <div className={style.profile_detail_wrapper}>
          <Box className={style.profile_detail}>
            <Typography
              sx={{ color: '#20A58A', ntWeight: 700, fontSize: '23px' }}
            >
              {user?.name}
            </Typography>
            <Typography sx={{ display: '13.48px' }}>
              (ID: {user?.id})
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '50px',
              flexDirection: 'column',
              mt: '50px',
            }}
          >
            <Box className={style.info}>
              <TypoItem title="Email" detail={user?.email} />
              <TypoItem title="Phone" detail={user?.phone || '-'} />
              <TypoItem title="School" detail={user?.school?.name || '-'} />
            </Box>
            <Box className={style.info}>
              <TypoItem
                title="State"
                detail={user?.school?.state || '-' || '-'}
              />
              <TypoItem title="Zip Code" detail={user?.zipCode || '-'} />
              <TypoItem
                title="Teaching Preference"
                detail={user?.type || '-'}
              />
              <TypoItem
                title="Grade"
                detail={
                  user?.grade?.length > 0 ? user?.grade[0]?.grade?.name : '-'
                }
              />
            </Box>
          </Box>
        </div>

        <Box sx={{ display: 'flex', position: 'absolute', top: 30, right: 30 }}>
          <IconButton onClick={() => handleEditClick()}>
            <EditBorderIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setOpenDeleteModal(true);
              setSelectedUserToDelete({
                id: user.id,
                name: user.name,
              });
            }}
          >
            <DeleteBorderIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider variant="inset" />
      <Box>
        <Typography sx={{ color: '#929292' }}>Classrooms</Typography>
        <Box className={style.classroom_list}>
          {user.classrooms?.length > 0 &&
            user.classrooms?.map((item: any, index: any) => {
              return (
                <ClassItem
                  sx={{ p: 1, color: '#000' }}
                  key={index}
                  title={item?.classroom?.name}
                />
              );
            })}
        </Box>
      </Box>
      <DeleteUserDialog
        loading={deleteLoading}
        open={openDeleteModal}
        title={`Are you sure you want to delete the user "${selectedUserToDelete?.name}" from G3MS?`}
        onOk={handleDeleteUser}
        onClose={() => {
          setOpenDeleteModal(false);
          setSelectedUserToDelete({
            id: '',
            name: '',
          });
        }}
      />
    </Card>
  );
}

export default Parent;
