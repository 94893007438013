// ** MUI Imports
import { Theme } from '@mui/material/styles'

const Form = (theme: Theme) => {
  return {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#484848",
          fontWeight: 400,
          fontSize: "small",
          '&.Mui-focused': {
            color: "#484848",
          },
        }
      }
    },
  }
}

export default Form
