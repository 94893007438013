// *** React Imports
import React, { useEffect, useState } from 'react';

// *** Material UI Imports
import {
  Box,
  Grid,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';

import MDDialog from '@core/components/MDDialog';

/* mui-color-input' */
import { useAppDispatch } from 'store/store';
import Validations from 'helper/validations';
import { setBtnLoading } from 'store/Slice/loadingSlice';
import { addUpdateSchool } from 'store/Thunk/schoolThunk';


function Index({ open, onClose }: any) {
  const dispatch: any = useAppDispatch();

  const [formData, setFormData] = useState<any>({
    grade: [],
  });
  const [formError, setFormError] = useState<any>({});
  const [screenLoader, setScreenLoader] = React.useState(false);

  useEffect(() => {
    setFormData({});
  }, []);

  const onSave = (event: any) => {
    event.preventDefault();
    let _request: any = {
      name: formData?.name || '',
      classroomType: formData?.schoolType?.name || '',
      classroomPeriod: formData?.classroomPeriod || '',
      grade: formData?.grade?.id,
      classroomTeacher1Ids:
        formData?.classroomTeacher1?.map((item: any) => {
          return item?.id;
        }) || '',
    };

    let allError = Validations.ValidateClassroomDataFrom(_request);

    if (
      Object.entries(allError).length === 0 &&
      allError.constructor === Object
    ) {
      dispatch(setBtnLoading(true));
      dispatch(addUpdateSchool(_request));
      onClose();
    } else {
      setFormError(allError);
    }
  };

  const dialogProps = {
    open,
    onClose,
    onSave,
    title: 'Change status',
    size: 'xs',
    saveTbtText: 'Save',
    closeBtnText: 'Cancel',
    screenLoader,
  };

  return (
    <MDDialog {...dialogProps}>
      <Box sx={{ p: 3 }}>
        <Grid container>
          <Grid container spacing={5}>
            <Grid item md={12} sm={12} xs={12}>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Group status
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                // onChange={(e) => {
                //   handleAssigmentType(e);
                // }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Active"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Deactivate"
                />
              </RadioGroup>
            </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </MDDialog>
  );
}

export default Index;
