import React from "react";

export default function UsersIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.194 11.4051C9.96075 11.4051 12.3255 11.8243 12.3255 13.4991C12.3255 15.1738 9.9765 15.6051 7.194 15.6051C4.4265 15.6051 2.0625 15.1896 2.0625 13.5141C2.0625 11.8386 4.41075 11.4051 7.194 11.4051Z" stroke="#20A58A" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.1938 9.01485C5.3773 9.01485 3.9043 7.5426 3.9043 5.7261C3.9043 3.9096 5.3773 2.43735 7.1938 2.43735C9.00955 2.43735 10.4825 3.9096 10.4825 5.7261C10.4893 7.53585 9.0268 9.0081 7.21705 9.01485H7.1938Z" stroke="#20A58A" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.3623 8.1612C13.5631 7.99245 14.4878 6.96195 14.4901 5.7147C14.4901 4.48545 13.5938 3.46545 12.4186 3.2727" stroke="#20A58A" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.9473 11.0491C15.1105 11.2224 15.9228 11.6304 15.9228 12.4704C15.9228 13.0486 15.5403 13.4236 14.9223 13.6584" stroke="#20A58A" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}
