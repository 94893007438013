import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/style.css';
import { ReactComponent as DropDownIcon } from '../../skills_chart/assets/drop-down-svg.svg';
import { ReactComponent as EyeIcon } from '../assets/eye-icon.svg';
import {
  TeacherProgress,
  ClassroomProgress,
  StudentProgress,
} from '../helpers/helpers';
import { useAppSelector } from 'store/store';
import useSchoolClassroomInfo from 'hooks/useSchoolClassroomInfo';

const DropDownPanel = ({
  students,
  classRoom,
}: {
  students: StudentProgress[];
  classRoom: any;
}) => {
  const history = useNavigate();
  const teacherSchool: any = localStorage.getItem('teacherSchool');

  const onStudentDetailHandler = (studentId: string) => {
    history(
      `/school/${teacherSchool}/classroom/${
        classRoom?.id
      }?studentId=${studentId}&topic=${classRoom?.topic?.name}&topicId=${
        classRoom?.topic?.id
      }&grade=${classRoom?.grade?.name}&gradeId=${
        classRoom?.grade?.id
      }&studentDetail=${true}&classroomId=${
        classRoom?.id
      }&schoolId=${teacherSchool}`,
    );
  };
  return (
    <div className="inner-single-bar-container">
      {students.map((student: StudentProgress, index: number) => {
        return (
          <div className="inner-single-bar" key={`${index}-${student.name}`}>
            <div className="inner-single-bar-element">
              <div className="inner-single-bar-row-title">Student Name</div>
              <div className="inner-single-bar-row-value w-32">
                {student.name}
              </div>
            </div>
            <div className="inner-single-bar-element">
              <div className="inner-single-bar-row-title">
                Total Assignments
              </div>
              <div className="inner-single-bar-row-value">
                {student.totalAssignments}
              </div>
            </div>
            <div className="inner-single-bar-element w-20">
              <div className="inner-single-bar-row-title">Completed</div>
              <div className="inner-single-bar-row-value">
                {student.totalDone}
              </div>
            </div>
            <div className="inner-single-bar-element w-24">
              <div className="inner-single-bar-row-title">Total $G3MS</div>
              <div className="inner-single-bar-row-value">
                {student.totalGems}
              </div>
            </div>
            <div className="inner-single-bar-element">
              <div className="inner-single-bar-row-title">
                Intervention Recommended
              </div>
              <div className="inner-single-bar-row-value">
                {student.interventionRecommended}
              </div>
            </div>
            <div className="inner-single-bar-element">
              <div className="inner-single-bar-row-title">Overall Grade</div>
              <div className="inner-single-bar-row-value">
                {Math.round(parseInt(student?.overAllGrade))}
              </div>
            </div>
            <div
              className="inner-single-bar-row-drop-icon"
              onClick={() => onStudentDetailHandler(student.id)}
            >
              <EyeIcon />
            </div>
          </div>
        );
      })}
    </div>
  );
};

const ProgressDetailTableComponent = ({
  teacherProgress,
  leftVerticalBar,
}: {
  teacherProgress: TeacherProgress;
  leftVerticalBar: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(null);
  const {
    totalStudentsCount,
    totalTeachersCount,
    totalParentsCount,
    totalClassroomsCount,
  } = useAppSelector((state: any) => state.dashboardReducer);
  const onClickHandler = (index: any) => {
    if (isOpen === index) {
      setIsOpen(null);
      return;
    }
    setIsOpen(index);
  };
  console.log('schoolId', teacherProgress);

  return (
    <div
      style={{
        display: 'flex',
        marginBottom: '1rem',
        overflowX: 'auto',
      }}
    >
      {leftVerticalBar && (
        <div className="left-vertical-bar">
          <img
            className="left-vertical-bar-image"
            src={teacherProgress.profileImage}
            alt=""
          />
          <div className="left-vertical-bar-name">{teacherProgress.name}</div>
        </div>
      )}

      <div className="right-main-bar">
        {teacherProgress.teacherClassrooms.map(
          (classRoom: ClassroomProgress, index: number) => {
            return (
              <div
                style={{
                  width: '100%',
                  minWidth: '873px',
                  alignSelf: 'center',
                }}
                key={`${index}-${classRoom.name}`}
              >
                <div
                  className="right-single-bar"
                  onClick={() => onClickHandler(index)}
                >
                  <div className="right-single-bar-element">
                    <div className="right-single-bar-row-value">
                      {classRoom.name}
                    </div>
                  </div>
                  {/* <div className="right-single-bar-element">
                    <div className="right-single-bar-row-title">
                      Total Skills
                    </div>
                    <div className="right-single-bar-row-value">
                      {classRoom.totalSkills}
                    </div>
                  </div>
                  <div className="right-single-bar-element">
                    <div className="right-single-bar-row-title">On Target</div>
                    <div className="right-single-bar-row-value">
                      {classRoom.onTarget}
                    </div>
                  </div>
                  <div className="right-single-bar-element">
                    <div className="right-single-bar-row-title">
                      Total Video Lessons
                    </div>
                    <div className="right-single-bar-row-value">
                      {classRoom.totalVideoWatchHours}
                    </div>
                  </div>
                  <div className="right-single-bar-element">
                    <div className="right-single-bar-row-title">
                      Total Score
                    </div>
                    <div className="right-single-bar-row-value">
                      {classRoom.totalScore}
                    </div>
                  </div> */}
                  <div
                    className="right-single-bar-row-drop-icon"
                    style={{
                      transform:
                        isOpen === index ? 'rotate(180deg)' : 'rotate(0deg)',
                    }}
                  >
                    <DropDownIcon />
                  </div>
                </div>
                {isOpen === index && (
                  <DropDownPanel
                    classRoom={classRoom}
                    students={classRoom.students}
                  />
                )}
              </div>
            );
          },
        )}
      </div>
    </div>
  );
};

export default ProgressDetailTableComponent;
