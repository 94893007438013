import React, { useState } from 'react';
import Card from '@mui/material/Card';

import Typography from '@mui/material/Typography';
import { EditIcon, DeleteIcon, ViewIcon } from 'icons';
import EyeBorderIcon from 'icons/eyeBorderIcon';
import DeleteBorderIcon from 'icons/deleteBorderIcon';
import EditBorderIcon from 'icons/editBorderIcon';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';

// import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';

import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Box,
  Checkbox,
  Pagination,
  TextField,
} from '@mui/material';
import { Bell } from 'mdi-material-ui';
import { VisibilityOutlined } from '@mui/icons-material';
import QuestionWarning from './QuestionWarning';

const data = {
  somedfield: {
    skills: {
      ab: { count: 1, selected: true },
      ac: { count: 2, selected: false },
      ad: { count: 0, selected: true },
      ae: { count: 0, selected: true },
      af: { count: 0, selected: true },
    },
  },
};

export interface Header {
  label: string;
  width: number;
  value: string;
}
export interface IStudent {
  id?: string;
  name?: string;
  skill?: any;
  title?: string;
  topic?: string;
  subTopic?: any;
  subTopicId?: string;
  assignmentType?: string;
  totalGems?: string;
  pending?: string;
  status?: string;
  startDate?: string;
  endDate?: string;
}

export interface IClasses {
  id?: string;
  name?: string;
  totalAssignments?: string;
  completedAssignments?: string;
  pendingAssignments?: string;
  totalGems?: string;
  interVentionRecommended?: string;
  averageScore?: string;
  studentCount?: string;
  target?: string;
  Assignments?: IStudent[];
}

const StudentsList = ({
  students,
  dataArray,
  confirmation,
  data,
  setData,
  quarterId,
  setIsOpenQuestionLimit,
  handleCloseLimit,
}: {
  dataArray: any;
  quarterId: any;
  setIsOpenQuestionLimit?: any;
  confirmation?: any;
  handleCloseLimit?: any;
  data: any;
  setData: any;
  students: any | undefined;
}) => {
  let stud: IStudent[] = [];
  if (students && students.length > 0) {
    stud = students;
  }
  function checkSkillWithCountAndSelectedTrue(skills: any) {
    return Object.values(skills).some((skill: any) => skill.selected);
  }
  const urlParams = new URLSearchParams(window.location.search);
  const assignmentType = urlParams.get('assignmentType');

  const inst = ['icon'];
  const Skills = ({
    template,
    dataArray,
    data,
    setData,
    subTopic,
    setIsOpenQuestionLimit,
  }: {
    template: any;
    data: any;
    setData: any;
    setIsOpenQuestionLimit: any;
    dataArray: any;
    subTopic: any;
  }) => {
    const urlParams = new URLSearchParams(window.location.search);
    const assignmentType = urlParams.get('assignmentType');

    function handleIncrease(skillId: any, questionCount: any) {
      const updatedData = data.map((item: any) => {
        const subTopics = { ...item.subTopics };
        for (const subTopicKey in subTopics) {
          const skills = { ...subTopics[subTopicKey].skills };
          for (const skillKey in skills) {
            if (
              skills[skillKey].skillId === skillId &&
              skills[skillKey].count < questionCount
            ) {
              skills[skillKey].count++;
              // Add any other property changes here
            } else if (skills[skillKey].skillId === skillId) {
              setIsOpenQuestionLimit(true);
              // window.alert('maximum questions reached');
            }
          }
          subTopics[subTopicKey].skills = skills;
        }
        item.subTopics = subTopics;
        return item;
      });

      setData(updatedData);
    }
    function handleInputChange(skillId: any, value: any, questionCount: any) {
      const updatedData = data.map((item: any) => {
        const subTopics = { ...item.subTopics };
        for (const subTopicKey in subTopics) {
          const skills = { ...subTopics[subTopicKey].skills };
          for (const skillKey in skills) {
            if (
              skills[skillKey].skillId === skillId &&
              parseInt(value) <= questionCount
            ) {
              skills[skillKey].count = parseInt(value);
              // Add any other property changes here
            } else if (!(parseInt(value) > 0)) {
              skills[skillKey].count = 0;
            } else if (skills[skillKey].skillId === skillId) {
              setIsOpenQuestionLimit(true);
              // window.alert('maximum questions reached');
            }
          }
          subTopics[subTopicKey].skills = skills;
        }
        item.subTopics = subTopics;
        return item;
      });

      setData(updatedData);
    }
    function handleDecrease(skillId: any) {
      const updatedData = data?.map((item: any) => {
        const subTopics = { ...item.subTopics };
        for (const subTopicKey in subTopics) {
          const skills = { ...subTopics[subTopicKey].skills };
          for (const skillKey in skills) {
            if (
              skills[skillKey].skillId === skillId &&
              skills[skillKey].count > 0
            ) {
              skills[skillKey].count--;
              // Add any other property changes here
            }
          }
          subTopics[subTopicKey].skills = skills;
        }
        item.subTopics = subTopics;
        return item;
      });

      setData(updatedData);
    }

    function handleCheckboxChange(checked: any, skillId: any, quarterId: any) {
      const updatedData = data.map((item: any) => {
        const subTopics = { ...item.subTopics };
        for (const subTopicKey in subTopics) {
          const skills = { ...subTopics[subTopicKey].skills };
          for (const skillKey in skills) {
            if (
              skills[skillKey].templates[0].id === skillId &&
              item?.quarterId === quarterId
            ) {
              skills[skillKey].selected = checked;
              // Add any other property changes here
            }
          }
          subTopics[subTopicKey].skills = skills;
        }
        item.subTopics = subTopics;
        return item;
      });

      setData(updatedData);
    }

    return (
      <Box className="flex flex-col !mx-6 px-2">
        <Box className="flex items-center">
          <Box className="flex" sx={{ flex: 6 }}>
            <Typography className="!text-black"> {template?.name}</Typography>
          </Box>
          <Box className="flex justify-center" sx={{ flex: 2 }}>
            {assignmentType !== 'Daily_Practice' && (
              <Box className="flex gap-2 rounded-lg px-5 py-1 border border-gray-400">
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleDecrease(template?.skillId)}
                >
                  -
                </div>
                <input
                  type="text"
                  style={{
                    width: '30px',
                    border: 'none',
                    outline: 'none',
                    textAlign: 'center',
                  }}
                  value={template?.count}
                  onChange={(e) =>
                    handleInputChange(
                      template.skillId,
                      e.target.value,
                      template?.templates[0]?.questions.length,
                    )
                  }
                />
                <div>/ {template?.templates[0]?.questions?.length || 0}</div>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    handleIncrease(
                      template?.skillId,
                      template?.templates[0]?.questions.length,
                    )
                  }
                >
                  +
                </div>
              </Box>
            )}
          </Box>
          <Box className="flex">
            <Checkbox
              size="medium"
              disabled={!template?.templates[0]?.questions?.length}
              checked={template?.selected}
              onChange={(e) =>
                handleCheckboxChange(
                  e.target.checked,
                  template?.templates[0].id,
                  quarterId,
                )
              }
            />
          </Box>
        </Box>
      </Box>
    );
  };
  return (
    <List component="div" disablePadding className="mb-2">
      <>
        <Box sx={{ mb: 0 }} className="flex flex-col !mx-0 p-2">
          <Box className="flex bg-gray-100 px-2 items-center w-full py-2 my-2">
            <Box className="flex" sx={{ flex: 4 }}>
              Standards
            </Box>
            {assignmentType !== 'Daily_Practice' && (
              <Box className="flex justify-center" sx={{ flex: 2 }}>
                <Typography>Number Of Questions</Typography>
              </Box>
            )}
            <Box className="flex"></Box>
          </Box>
        </Box>
        {students &&
          Object.entries(students)?.map((key: any, value: any) => (
            <>
              {checkSkillWithCountAndSelectedTrue(
                Object.values(key[1]?.skills),
              ) && confirmation ? (
                <Box
                  sx={{ m: 2, mb: 4, mt: 4 }}
                  className="flex bg-gray-200 px-5 py-2 rounded-tl-lg rounded-tr-lg mt-4 items-center"
                >
                  <Box className="flex-1 flex-col">
                    <Box className="flex gap-2">
                      <Typography
                        sx={{ color: '#C73E9D' }}
                        className="!font-bold"
                      >
                        {key[1]?.name}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>{/* <Checkbox size="medium" /> */}</Box>
                </Box>
              ) : !confirmation ? (
                <Box
                  sx={{ m: 2, mb: 4, mt: 4 }}
                  className="flex bg-gray-200 px-5 py-2 rounded-tl-lg rounded-tr-lg mt-4 items-center"
                >
                  <Box className="flex-1 flex-col">
                    <Box className="flex gap-2">
                      <Typography
                        sx={{ color: '#C73E9D' }}
                        className="!font-bold"
                      >
                        {key[1]?.name}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>{/* <Checkbox size="medium" /> */}</Box>
                </Box>
              ) : (
                <></>
              )}

              {Object.values(key[1]?.skills)?.map((template: any) => (
                <>
                  {template.selected && confirmation ? (
                    <Skills
                      data={data}
                      setIsOpenQuestionLimit={setIsOpenQuestionLimit}
                      setData={setData}
                      subTopic={key[1]}
                      template={template}
                      dataArray={dataArray}
                    />
                  ) : !confirmation ? (
                    <Skills
                      data={data}
                      setIsOpenQuestionLimit={setIsOpenQuestionLimit}
                      setData={setData}
                      subTopic={key[1]}
                      template={template}
                      dataArray={dataArray}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ))}
            </>
          ))}
      </>
    </List>
  );
};

export default function Instructional({
  openDefault,
  classes,
  data,
  setData,
  color,
  pageIndex,
  pageSize,
  header,
  pageCount,
  showPagination,
  totalCount,
  entriesPerPage,
  confirmation,
  setIsOpenQuestionLimit,
  handleCloseLimit,
}: {
  openDefault?: any;
  classes?: any;
  data?: any;
  setData?: any;
  confirmation?: any;
  pageCount?: any;
  pageIndex?: any;
  pageSize?: any;
  showPagination?: any;
  totalCount?: any;
  color?: string;
  setIsOpenQuestionLimit?: any;
  handleCloseLimit?: any;
  entriesPerPage?: any;
  header?: Header[];
}) {
  const [open, setOpen] = React.useState(openDefault || false);

  const [search, setSearch] = useState('');
  let dataArray: { templateId: string; count: number }[] = [];

  const renderPagination = () => (
    <Pagination
      count={pageCount || 10}
      page={pageIndex || 4 + 1}
      shape="rounded"
      // onChange={handleChangePage}
    />
  );
  const setEntriesPerPage = (value: any) => {
    // fetchData({
    //   pageIndex: pageIndex,
    //   pageSize: value,
    //   search: search,
    // });
    // setPageSize(value);
  };
  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  const onSearchChange = (value: any) => {
    setSearch(value); // Update the search state with the current input value.
  };
  const handleClick = (index: number) => {
    if (selectedIndex === index) {
      setSelectedIndex(-1); // Collapse the selected div if it's already expanded
    } else {
      setSelectedIndex(index); // Expand the selected div
    }
  };
  function checkSkillWithCountAndSelectedTrue(data: any) {
    for (const subTopicKey in data.subTopics) {
      const skills = Object.values(data.subTopics[subTopicKey].skills);
      if (skills.some((skill: any) => skill.selected)) {
        return true;
      }
    }
    return false;
  }
  const confirmationAssignments = '';

  return (
    <>
      {classes?.map((classes: any, i: number) => (
        <>
          {confirmation && checkSkillWithCountAndSelectedTrue(classes) ? (
            <div
              style={{
                border: open ? '1px solid #DCDCDC' : 'none',
                borderRadius: '10px 10px 0px 0px',
                marginBottom: '16px',
              }}
            >
              <Card
                style={{
                  backgroundColor: color,
                  borderRadius: '10px 10px 0px 0px',
                  boxShadow:
                    '0px 2px 1px -1px rgba(76, 78, 100, 0.2),0px 1px 1px 0px rgba(76, 78, 100, 0.14),0px 1px 3px 0px rgba(76, 78, 100, 0.12)',
                }}
              >
                <ListItemButton
                  style={{ padding: '12px 12px' }}
                  onClick={() => handleClick(i)}
                >
                  {/* <ListItemIcon>
                        class
                    </ListItemIcon> */}

                  <Grid
                    container
                    spacing={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      color: '#131515',
                      '& .css-6uzn28-MuiTypography-root': {
                        color: '#131515',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '22px',
                      },
                    }}
                  >
                    {header?.map((head) => (
                      <Grid item className="min-h-14 flex items-center">
                        {head && classes && (
                          <>
                            <Typography fontWeight={600} color={'#131515'}>
                              {classes[head?.value] || 'N/A'}
                            </Typography>
                          </>
                        )}
                      </Grid>
                    ))}
                    <Grid
                      item
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {selectedIndex === i ? <ExpandLess /> : <ExpandMore />}
                    </Grid>
                  </Grid>
                </ListItemButton>
              </Card>

              <Collapse
                in={openDefault || selectedIndex === i}
                timeout="auto"
                unmountOnExit
              >
                <StudentsList
                  students={classes?.subTopics}
                  quarterId={classes?.quarterId}
                  setIsOpenQuestionLimit={setIsOpenQuestionLimit}
                  handleCloseLimit={handleCloseLimit}
                  data={data}
                  setData={setData}
                  dataArray={dataArray}
                  confirmation={confirmation}
                />
              </Collapse>
            </div>
          ) : !confirmation ? (
            <div
              style={{
                border: open ? '1px solid #DCDCDC' : 'none',
                borderRadius: '10px 10px 0px 0px',
                marginBottom: '16px',
              }}
            >
              <Card
                style={{
                  backgroundColor: color,
                  borderRadius: '10px 10px 0px 0px',
                  boxShadow:
                    '0px 2px 1px -1px rgba(76, 78, 100, 0.2),0px 1px 1px 0px rgba(76, 78, 100, 0.14),0px 1px 3px 0px rgba(76, 78, 100, 0.12)',
                }}
              >
                <ListItemButton
                  style={{ padding: '12px 12px' }}
                  onClick={() => handleClick(i)}
                >
                  {/* <ListItemIcon>
                      class
                  </ListItemIcon> */}

                  <Grid
                    container
                    spacing={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      color: '#131515',
                      '& .css-6uzn28-MuiTypography-root': {
                        color: '#131515',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '22px',
                      },
                    }}
                  >
                    {header?.map((head) => (
                      <Grid item className="min-h-14 flex items-center">
                        {head && classes && (
                          <>
                            <Typography fontWeight={600} color={'#131515'}>
                              {classes[head?.value] || 'N/A'}
                            </Typography>
                          </>
                        )}
                      </Grid>
                    ))}
                    <Grid
                      item
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {selectedIndex === i ? <ExpandLess /> : <ExpandMore />}
                    </Grid>
                  </Grid>
                </ListItemButton>
              </Card>

              <Collapse
                in={openDefault || selectedIndex === i}
                timeout="auto"
                unmountOnExit
              >
                <StudentsList
                  students={classes?.subTopics}
                  quarterId={classes?.quarterId}
                  setIsOpenQuestionLimit={setIsOpenQuestionLimit}
                  handleCloseLimit={handleCloseLimit}
                  data={data}
                  setData={setData}
                  dataArray={dataArray}
                  confirmation={confirmation}
                />
              </Collapse>
            </div>
          ) : (
            <></>
          )}
        </>
      ))}
      {/* {showPagination && ( */}
      {false && (
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          my={4}
          sx={{ border: '1px solid #E9EBED', borderRadius: '6px', p: 2 }}
        >
          {/* {entriesPerPage && ( */}
          {true && (
            <Box flex="1" display="flex" alignItems="center">
              <Typography variant="body2" className="entries-page-txt">
                Show&nbsp;&nbsp;
              </Typography>
              <Autocomplete
                disableClearable
                value={pageSize?.toString()}
                options={['10', '25', '50', '100']}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10));
                }}
                size="small"
                sx={{ width: '5rem' }}
                renderInput={(params) => <TextField {...params} />}
              />
              <Typography variant="body2" className="entries-page-txt">
                &nbsp;&nbsp;entries of {totalCount}
              </Typography>
            </Box>
          )}
          {renderPagination()}
        </Box>
      )}
    </>
  );
}
