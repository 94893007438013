import { Card, Skeleton } from '@mui/material';
import React from 'react';

function ViewLoaderBox({ allowShadow }: { allowShadow?: boolean }) {
  return (
    <React.Fragment>
      {allowShadow ? (
        <Card
          sx={{
            width: '100%',
            height: '300px',
            p: 2,
          }}
        >
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12].map((index) => (
            <div key={index}>
              <Skeleton animation="wave" />
            </div>
          ))}
        </Card>
      ) : (
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12].map((index) => (
          <div key={index}>
            <Skeleton animation="wave" />
          </div>
        ))
      )}
    </React.Fragment>
  );
}

export default ViewLoaderBox;
