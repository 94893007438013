import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  InputAdornment,
  TextField,
  Button,
  Grid,
  Card,
  Autocomplete,
  Pagination,
} from '@mui/material';

//Icons
import Stand from 'icons/stand';
import SearchIcon from 'icons/search';
import GraduationHat from 'icons/graduationHat';
import TeacherCard from 'pages/school_leader/dashboard/tabs/teachers/teacher_card';

import AddFirstTeacher from './AddFirstTeacher';
import { getTeacherListByClassroom } from 'store/Thunk/teacherThunk';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useParams } from 'react-router-dom';
import AddTeacherToClassRoom from '../modals/AddTeacherToClassRoom';
import useSchoolClassroomInfo from 'hooks/useSchoolClassroomInfo';
import RemoveUserFromClassDialog from 'components/dialog/users/Delete/removeUser';
import { removeUserFromClassRoom } from 'store/Thunk/classroomThunk';

function Index({ schoolId }: any) {
  const [search, setSearch] = useState<string>('');
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(12);
  const [gotoPage, setGotoPage] = useState<number>(0);
  const [isAddTeacherDialogOpen, setIsTeacherDialogOpen] =
    useState<boolean>(false);

  const closeAddStudentDialog = () => setIsTeacherDialogOpen(false);
  const [removeUserModal, setRemoveUserModal] = useState<boolean>(false);
  const [userIdToRemove, setUserIdToRemove] = useState('');
  const urlParams = new URLSearchParams(window.location.search);
  const classroomId = urlParams.get('classroomId');

  const { teacherListByClassRoom } = useAppSelector(
    (state: any) => state.teacherReducer,
  );
  const dispatch = useAppDispatch();
  const { id } = useParams();
  console.log('id', id);
  useEffect(() => {
    dispatch(getTeacherListByClassroom({ id }));
  }, []);

  useEffect(() => {
    if (teacherListByClassRoom && teacherListByClassRoom?.items) {
      setPageCount(teacherListByClassRoom?.meta?.lastPage);
      setTotalCount(teacherListByClassRoom?.meta?.total);
    }
  }, [teacherListByClassRoom]);

  useEffect(() => {
    if (id) {
      dispatch(
        getTeacherListByClassroom({
          pageIndex: pageNo,
          pageSize,
          search,
          id,
        }),
      );
    }
  }, [id, , gotoPage, pageNo, search, pageSize]);

  const onSearchChange = (value: any) => {
    setSearch(value);
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) =>
    setPageNo(value - 1);

  const renderPagination = () => (
    <Pagination
      count={pageCount}
      page={pageNo + 1}
      shape="rounded"
      onChange={handleChangePage}
    />
  );

  const fetchData = () => {
    dispatch(getTeacherListByClassroom({ id }));
  };

  const setEntriesPerPage = (value: any) => {
    setPageSize(value);
  };

  const deleteUserFromClassRoom = () => {
    dispatch(
      removeUserFromClassRoom({
        typeToRemove: 'teacher',
        classRoomId: classroomId || '',
        payload: { teacherIds: [userIdToRemove] },
      }),
    )
      .unwrap()
      .then((res) => {
        if (res?.status === 200 || res?.status === 202) {
          setRemoveUserModal(false);
          setUserIdToRemove('');
          dispatch(
            getTeacherListByClassroom({
              pageIndex: pageNo,
              pageSize,
              search,
              id,
            }),
          );
        }
      });
  };

  console.log('teacherListByClassRoom', teacherListByClassRoom);

  return (
    <>
      {teacherListByClassRoom?.items?.length > 0 ? (
        <Box>
          <Box sx={{ p: '24px', pt: '0' }}>
            <Box ml={2} mt={2}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="flex-start"
                mb={2}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <GraduationHat />
                  <Typography className="table_title" sx={{ ml: 2 }}>
                    Teachers
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {/* <Button
                    variant="contained"
                    className="gray_bg_btn"
                    sx={{ ml: 3 }}
                      onClick={handleOnChange}
                  >
                    Import Teachers
                  </Button> */}
                  <Button
                    variant="contained"
                    className="primary_bg_btn"
                    sx={{ ml: 3 }}
                    onClick={() => {
                      setIsTeacherDialogOpen(true);
                    }}
                    //   onClick={handleOnChange}
                  >
                    Add Teachers
                  </Button>
                </Box>
              </Box>
              <TextField
                placeholder={'Search'}
                sx={{
                  mb: 10,
                  width: { xs: '100%', sm: '40%', md: '30%' },
                  background: '#F9F9F9',
                }}
                size="small"
                value={search}
                onChange={(e) => onSearchChange(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Grid container spacing={8}>
                {teacherListByClassRoom?.items?.map((item: any) => {
                  return (
                    <Grid item md={3} sm={4} xs={12}>
                      <TeacherCard
                        id={item.user.id}
                        deleteUserFromClassRoom={deleteUserFromClassRoom}
                        setUserIdToRemove={setUserIdToRemove}
                        setRemoveUserModal={setRemoveUserModal}
                        user={item.user}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Box>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            my={4}
            sx={{
              border: '1px solid #E9EBED',
              borderRadius: '6px',
              p: 2,
              m: '24px',
              mt: 'unset',
            }}
          >
            {true && (
              <Box flex="1" display="flex" alignItems="center">
                <Typography variant="body2" className="entries-page-txt">
                  Show&nbsp;&nbsp;
                </Typography>
                <Autocomplete
                  disableClearable
                  value={pageSize.toString()}
                  options={['12', '24', '48', '96']}
                  onChange={(event, newValue) => {
                    setEntriesPerPage(parseInt(newValue, 10));
                  }}
                  size="small"
                  sx={{ width: '5rem' }}
                  renderInput={(params) => <TextField {...params} />}
                />
                <Typography variant="body2" className="entries-page-txt">
                  &nbsp;&nbsp;entries of {totalCount}
                </Typography>
              </Box>
            )}
            {renderPagination()}
          </Box>
        </Box>
      ) : (
        <AddFirstTeacher onClick={() => setIsTeacherDialogOpen(true)} />
      )}
      <AddTeacherToClassRoom
        isAddTeacherDialogOpen={isAddTeacherDialogOpen}
        setIsTeacherDialogOpen={setIsTeacherDialogOpen}
        onClose={closeAddStudentDialog}
        classRoomId={id}
        fetchClassRooms={fetchData}
      />
      <RemoveUserFromClassDialog
        onOk={() => deleteUserFromClassRoom()}
        onClose={() => setRemoveUserModal(false)}
        open={removeUserModal}
      />
    </>
  );
}

export default Index;
