import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, Typography, Card, Button, Chip } from '@mui/material';
import { ContentWrapper, Container, AssignmentInfoContainer } from '../styles';
import { CustomTabPanel, MuiTabs } from 'components/Tabs';
import AssignChallengeTable from '../AssignChallengeTable';
import { useAppDispatch, useAppSelector } from 'store/store';
import {
  assignContent,
  updateAssignment,
} from 'store/Thunk/assignContentThunk';
import { areAllIdsEmpty } from 'helper/functions';
import { displayMultipleValue, getLocalDateTime } from 'helper/service';
import {
  _assignmentTypes,
  _assignmentTypesLable,
  assignmentTypes,
} from 'helper/types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  getQuarterList,
  getVideoById,
  getVideoList,
} from 'store/Thunk/videoThunk';
import { getDailyChallengesById } from 'store/Thunk/dailyChallengesThunk';
import { getQuizTemplateById } from 'store/Thunk/templateThunk';
import { typesColors } from 'helper/constants';
import DefaultCell from 'components/DefaultCell';
import ReadMore from 'components/ReadMore/ReadMore';
import CustomTable from 'components/customTable';
import AlreadyAssignContentInfo from '../AlreadyAssignInfo';
import AssignQuizTable from './AssignQuizTable';
import InstructionalCalander from './InstractionalCalandar';
import AssignVideosTable from './AssignVideosTable';
import AssignContentDialog from './AssignContentDialog';
import AssignContentDialogSuccess from './AssignContentDialogSuccess';
import AssignConfirmation from './AssignConfirmation';
import AssignDeselect from './AssignDeselect';
import InstructionalCalanderImported from './imported/diagnostic/InstractionalCalandar';
import AssignConfirmationImported from './imported/AssignConfirmation';
import QuestionWarning from './QuestionWarning';
import { startLoading } from 'store/Slice/loadingSlice';

function Index() {
  const [assignModal, setAssignModal] = useState(false);
  const [assignModalSuccess, setAssignModalSuccess] = useState(false);
  const [value, setValue] = React.useState(0);
  const [selectedContent, setSelectedContent] = useState<any>([]);
  const dispatch = useAppDispatch();
  const [selectiveInfo, setSelectiveInfo] = useState<Record<string, any>>({});
  const [infoModal, setInfoModal] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const selectedInfo = localStorage.getItem('selectiveInfo');
  const { id: assignmentId } = useParams();
  const [editModeSelectItem, setEditModeSelectedItem] = useState<
    Record<string, any>
  >([]);

  // Get query parameters
  const searchParams = new URLSearchParams(useLocation().search);
  const contentId = searchParams.get('content');
  const contentType = searchParams.get('contentType');
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedInfo) {
      setSelectiveInfo(JSON.parse(selectedInfo));
    }
  }, [selectedInfo]);

  const handleCloseSuccess = () => {
    setAssignModalSuccess(false);
  };

  const topicId = 21;
  const gradeId = 41;
  const [classroom, setClassroom] = useState([
    {
      classroom: {
        grade: { id: 12, name: 'abc' },
        topic: { id: 21, name: 'def' },
      },
    },
    {
      classroom: {
        grade: { id: 32, name: 'efc' },
        topic: { id: 31, name: 'asa' },
      },
    },
    {
      classroom: {
        grade: { id: 42, name: 'fs' },
        topic: { id: 41, name: 'as' },
      },
    },
  ]);

  const [quizzes, setQuizzes] = useState<any[]>([]);
  const urlParams = new URLSearchParams(window.location.search);
  const assignmentType = urlParams.get('assignmentType');
  const assignmentFrom = urlParams.get('type');
  console.log('assignmentType', assignmentType);
  console.log('assignmentType', assignmentFrom);
  console.log('assignmentType', selectedContent);
  const location = useLocation();
  const { selectedAssignments, formData } = location.state || {};
  console.log('selectedAssignments', formData);
  const FilteredselectedAssignments = selectedAssignments?.reduce(
    (result: any, assignment: any) => {
      const { id, name, picture, email, assignmentId, subTopic, skill, count } =
        assignment;

      // Check if there is already an object in the result array with the same id
      const existingAssignment = result.find((a: any) => a.id === id);

      if (existingAssignment) {
        // If an existing object is found, push the assignment details to its "assignments" array
        existingAssignment.assignments.push({
          assignmentId,
          subTopic,
          skill,
          count,
        });
      } else {
        // If no existing object is found, create a new object with the assignment details
        result.push({
          id,
          name,
          picture,
          email,
          assignments: [{ assignmentId, subTopic, skill, count }],
        });
      }

      return result;
    },
    [],
  );
  const [assignments, setAssignments] = useState(FilteredselectedAssignments);
  const [isOpenQuestionLimit, setIsOpenQuestionLimit] =
    React.useState<boolean>(false);
  const handleCloseLimit = () => {
    setIsOpenQuestionLimit(false);
  };
  const handleConfirm = () => {
    handleClose();
    // handleAssignContent();
  };
  const handleClose = () => {
    setAssignModal(true);
  };
  const handleOpenModal = () => {
    setConfirm(true);
    // setAssignModal(true);
  };
  const handleOk = () => {
    // setConfirm(true);
    setAssignModal(false);
    setAssignModalSuccess(true);
    // handleClose();
    // handleAssignContent();
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem('alreadyAssigned');
    };
  }, []);

  const Tabs =
    assignmentType === 'Daily_Practice'
      ? [
          {
            label: 'Instructional Calendar',
            value: 0,
          },
          {
            label: 'All Grades Video Lessons',
            value: 1,
          },
          {
            label: 'All Grades Quiz Practice',
            value: 2,
          },
        ]
      : [
          {
            label: 'Instructional Calendar',
            value: 0,
          },

          {
            label: 'All Grades Quiz Practice',
            value: 2,
          },
        ];

  const navigateUser = () => {
    setTimeout(() => {
      const classroomId = localStorage.getItem('current_classroomId');
      const current_schoolId = localStorage.getItem('current_schoolId');
      navigate(
        `/school/${current_schoolId}/classroom/${classroomId}?section=content`,
      );
    }, 2000);
  };
  const handleAssignContent = () => {
    if (editModeSelectItem?.length > 0) {
      dispatch(
        updateAssignment({
          assignmentId: assignmentId,
          data: {
            removeContentId: contentId,
            currentType: contentType,
            contentSelection: {
              id: selectedContent[0]?.ids[0],
              type: selectedContent[0]?.type,
            },
          },
        }),
      )
        .unwrap()
        .then(() => {
          handleClose();
          navigateUser();
        })
        .catch((err) => {
          handleClose();
        });
    } else {
      dispatch(assignContent(selectedContent))
        .unwrap()
        .then(() => {
          handleClose();
          navigateUser();
        })
        .catch((err) => {
          handleClose();
        });
    }
  };

  useEffect(() => {
    if (contentId && contentType) {
      if (contentType === 'video') {
        dispatch(getVideoById({ id: contentId }))
          .unwrap()
          .then((res: any) => {
            setEditModeSelectedItem([{ ...res }]);
          });
      } else if (contentType === 'dailyChallenges') {
        dispatch(getDailyChallengesById({ id: contentId }))
          .unwrap()
          .then((res: any) => {
            setEditModeSelectedItem([res]);
          });
      } else if (contentType === 'template') {
        dispatch(getQuizTemplateById({ id: contentId }))
          .unwrap()
          .then((res) => {
            setEditModeSelectedItem([res?.data?.data]);
          });
      }
    }
  }, [contentId, contentType]);

  const columns = useMemo(
    () => [
      {
        Header: 'Grade',
        accessor: 'grade',
        Cell: ({ value }: any) => {
          if (value.length) {
            return <p>{displayMultipleValue(value, 'grade')}</p>;
          }
        },
      },
      {
        Header: 'Title',
        accessor: 'title', // accessor is the "key" in your data
      },
      {
        Header: 'Topic',
        accessor: 'topic',
        Cell: ({ value }: any) => {
          if (value.length > 0) {
            return <ReadMore text={value[0]?.topic?.name || '-'} />;
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Sub Topic',
        accessor: 'sub_topic',
        Cell: ({ value }: any) => {
          if (value.length) {
            return <ReadMore text={value[0]?.subTopic?.name || '-'} />;
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Skill',
        accessor: 'skill',
        Cell: ({ value }: any) => {
          if (value.length) {
            return (
              <DefaultCell
                value={displayMultipleValue(value, 'skill') || '-'}
              />
            );
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Assigned',
        accessor: 'assigned',
        Cell: ({ row }) => (
          <Chip
            label={'Assigned'}
            style={{
              background: '#F6E3F0',
              color: '#C73E9D',
            }}
          />
        ),
      },
    ],
    [selectedContent],
  );

  const rows = useMemo(() => {
    if (editModeSelectItem.length > 0) {
      const data = editModeSelectItem?.map((item: any) => ({
        id: item?.id || '',
        title: item?.title || '-',
        topic: item?.topic || '-',
        date: item?.createdAt ? getLocalDateTime(item?.createdAt) : '-',
        user_created: item?.createdBy,
        sub_topic: item?.subTopic || {},
        grade: item?.grade || {},
        question: item?.question || '',
        skill: item?.skill || {},
        student_replies: item,
      }));
      return data;
    } else return [];
  }, [editModeSelectItem]);
  const ClassroomData = useAppSelector(
    (state) => state.classroomReducer.classroomID,
  );
  const current_schoolId = localStorage.getItem('current_schoolId');
  const [search, setSearch] = useState('');
  const fetchIdRef = useRef(0);

  const fetchData = useCallback(
    ({ pageSize, pageIndex, search }: any) => {
      const fetchId = ++fetchIdRef.current;
      // We'll even set a delay to simulate a server here
      if (fetchId === fetchIdRef.current) {
        dispatch(startLoading());
        dispatch(
          getQuarterList({
            pageCount: 1,
            perPage: 10,
            id: current_schoolId,
            grade: ClassroomData?.classroom?.grade.id,
            topic: ClassroomData?.classroom?.topic.id,
            search,
          }),
        );
      }
      // setTimeout(() => {}, 1000);
    },
    [search],
  );
  useEffect(() => {
    dispatch(startLoading());

    dispatch(
      getQuarterList({
        pageCount: 1,
        perPage: 10,
        id: current_schoolId,
        grade: ClassroomData?.classroom?.grade.id,
        topic: ClassroomData?.classroom?.topic.id,
      }),
    );
  }, []);
  const { quarterList } = useAppSelector((state: any) => state.videoReducer);
  const deepCopy = (obj: any): any => {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }

    const copy: any = Array.isArray(obj) ? [] : {};
    Object.keys(obj).forEach((key) => {
      copy[key] = deepCopy(obj[key]);
    });

    return copy;
  };

  const quarterBased: any[] = quarterList?.map((item: any) => {
    const copiedItem: any = JSON.parse(JSON.stringify(item));
    const updatedSubTopics: any = copiedItem.subTopics;
    Object.keys(updatedSubTopics).forEach((key) => {
      const updatedSkills: any = updatedSubTopics[key].skills;
      Object.keys(updatedSkills).forEach((skillKey) => {
        updatedSkills[skillKey].count = 0;
        updatedSkills[skillKey].selected = false;
      });
    });
    return copiedItem;
  });
  const [data, setData] = useState(quarterBased);
  const [selectedQuizzes, setSelectedQuizzes] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);

  useEffect(() => {
    setData(quarterBased);
  }, [quarterList]);

  const handleInfoOk = () => setInfoModal(false);
  useEffect(() => {
    dispatch(
      getVideoList({ pageCount: 1, perPage: 10, topicId, isAcademic: true }),
    );
  }, []);
  console.log('selectedContent', data);
  console.log('selectedContent', FilteredselectedAssignments);
  console.log('selectedContent', assignments);
  return (
    <>
      <AssignContentDialog
        onOk={handleOk}
        open={assignModal}
        onClose={handleClose}
      />
      <QuestionWarning open={isOpenQuestionLimit} onClose={handleCloseLimit} />

      <AssignContentDialogSuccess
        onOk={handleCloseSuccess}
        open={assignModalSuccess}
        onClose={handleCloseSuccess}
      />
      <AlreadyAssignContentInfo
        onOk={handleInfoOk}
        open={infoModal}
        onClose={handleInfoOk}
      />
      {confirm || assignmentFrom === 'imported' ? (
        <>
          {assignmentFrom === 'imported' ? (
            <AssignConfirmationImported
              onClose={handleConfirm}
              selectedAssignments={FilteredselectedAssignments}
              assignments={assignments}
              setAssignments={setAssignments}
            />
          ) : (
            <AssignConfirmation
              setIsOpenQuestionLimit={setIsOpenQuestionLimit}
              setData={setData}
              data={data}
              selectedVideos={selectedVideos}
              setSelectedVideos={setSelectedVideos}
              selectedQuizzes={selectedQuizzes}
              setSelectedQuizzes={setSelectedQuizzes}
              onClose={handleConfirm}
              formData={formData}
              setConfirm={setConfirm}
            />
          )}
        </>
      ) : (
        <Container>
          <Card>
            <ContentWrapper>
              <AssignmentInfoContainer>
                <Typography>Assignment Type:</Typography>
                <Typography sx={{ fontWeight: '500', color: '#000' }}>
                  {formData?.type?.replace(/_/g, ' ')}
                </Typography>
              </AssignmentInfoContainer>
              <AssignmentInfoContainer>
                <Typography>Assign To:</Typography>
                <Typography
                  sx={{
                    fontWeight: '500',
                    color: '#000',
                    textTransform: 'capitalize',
                  }}
                >
                  {/* {selectiveInfo?.toName} */}
                  {formData?.assignTo}
                </Typography>
              </AssignmentInfoContainer>
              <AssignmentInfoContainer>
                <Typography>Deadline:</Typography>
                <Typography
                  sx={{
                    display: 'flex',
                    gap: '20px',
                    fontWeight: '500',
                    color: '#000',
                  }}
                >
                  <span>
                    {getLocalDateTime(formData?.startDate, 'MMMM D, YYYY')}
                  </span>
                  <span style={{ fontWeight: '500', color: '#84818A' }}>
                    to
                  </span>{' '}
                  <span>
                    {getLocalDateTime(formData?.dueDate, 'MMMM D, YYYY')}
                  </span>
                </Typography>
              </AssignmentInfoContainer>
            </ContentWrapper>
          </Card>
          {assignmentId && editModeSelectItem.length > 0 && (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography
                  sx={{ fontSize: '18px', fontWeight: '500', color: '#131515' }}
                >
                  Assigned Content
                </Typography>
              </Box>
              <Card>
                <span className="assign-content">
                  <CustomTable
                    table={{
                      columns,
                      rows,
                    }}
                    showPagination={false}
                    variant="borderLess"
                  />
                </span>
              </Card>
            </>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              sx={{ fontSize: '18px', fontWeight: '500', color: '#131515' }}
            >
              Select Content
            </Typography>
            <Button
              onClick={handleOpenModal}
              variant="contained"
              color="secondary"
              // disabled={contentId ? false : areAllIdsEmpty(selectedContent)}
            >
              Next
            </Button>
          </Box>
          <Card sx={{ p: 5 }}>
            <MuiTabs value={value} setValue={setValue} tabsList={Tabs} />
            <CustomTabPanel index={0} value={value}>
              <span className="assign-content">
                {assignmentFrom === 'imported' ? (
                  <InstructionalCalanderImported
                    selectedContent={selectedContent}
                    setSelectedContent={setSelectedContent}
                    selectiveInfo={selectiveInfo}
                    setInfoModal={setInfoModal}
                    data={data}
                    setData={setData}
                  />
                ) : (
                  <InstructionalCalander
                    search={search}
                    setSearch={setSearch}
                    fetchData={fetchData}
                    selectedContent={selectedContent}
                    setSelectedContent={setSelectedContent}
                    selectiveInfo={selectiveInfo}
                    setInfoModal={setInfoModal}
                    data={data}
                    setData={setData}
                    setIsOpenQuestionLimit={setIsOpenQuestionLimit}
                    handleCloseLimit={handleCloseLimit}
                  />
                )}
              </span>
            </CustomTabPanel>

            {assignmentType === 'Daily_Practice' && (
              <CustomTabPanel index={1} value={value}>
                <span className="assign-content">
                  <AssignVideosTable
                    setSelectedQuizzes={setSelectedQuizzes}
                    selectedContent={selectedContent}
                    setSelectedContent={setSelectedContent}
                    selectiveInfo={selectiveInfo}
                    setInfoModal={setInfoModal}
                    selectedVideos={selectedVideos}
                    setSelectedVideos={setSelectedVideos}
                  />
                </span>
              </CustomTabPanel>
            )}
            <CustomTabPanel
              index={assignmentType === 'Daily_Practice' ? 2 : 1}
              value={value}
            >
              <span className="assign-content">
                <AssignQuizTable
                  selectedQuizzes={selectedQuizzes}
                  setSelectedQuizzes={setSelectedQuizzes}
                  selectedContent={selectedContent}
                  setSelectedVideos={setSelectedVideos}
                  setSelectedContent={setSelectedContent}
                  selectiveInfo={selectiveInfo}
                  setInfoModal={setInfoModal}
                  setIsOpenQuestionLimit={setIsOpenQuestionLimit}
                />
              </span>
            </CustomTabPanel>
          </Card>
        </Container>
      )}
    </>
  );
}

export default Index;
