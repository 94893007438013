import React from 'react';

export default function arrowCircleRight() {
  return (
    <svg
      width="40"
      height="37"
      viewBox="0 0 40 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.56185 10.7923C8.44359 6.18427 13.8298 3.08398 19.9988 3.08398C29.2036 3.08398 36.6655 9.98626 36.6655 18.5007C36.6655 27.015 29.2036 33.9173 19.9988 33.9173C13.8298 33.9173 8.4436 30.817 5.56185 26.209M19.9987 24.6673L26.6654 18.5007M26.6654 18.5007L19.9987 12.334M26.6654 18.5007H3.33203"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
