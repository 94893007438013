interface Column {
  id: 'student' | 'date' | 'topic' | 'action' | 'skill' | 'score';
  label: string;
  styles: {
    maxWidth: number;
    minWidth: number;
  };
  align?: 'right';
  format?: (value: number) => string;
}

export const sampleColumns: readonly Column[] = [
  {
    id: 'date',
    label: 'Last Activity Date',
    styles: {
      minWidth: 0,
      maxWidth: 0,
    },
  },
  {
    id: 'student',
    label: 'Student',
    styles: {
      minWidth: 0,
      maxWidth: 0,
    },
  },
  {
    id: 'topic',
    label: 'Topic',
    styles: {
      minWidth: 0,
      maxWidth: 0,
    },
  },
  {
    id: 'skill',
    label: 'Skill',
    styles: {
      minWidth: 0,
      maxWidth: 0,
    },
  },
  {
    id: 'score',
    label: 'Score',
    styles: {
      minWidth: 0,
      maxWidth: 50,
    },
  },
  {
    id: 'action',
    label: 'Action',
    styles: {
      minWidth: 0,
      maxWidth: 0,
    },
  },
];
const arrayfromApi = [
  {
    answerCorrect: [
      {
        questionId: '34b1f9d5-fd0a-4001-b345-c95af10020b0',
        correct_answer: 'c',
        student_answer: 'a',
      },
      {
        questionId: '51d8f662-b412-4387-9f9c-35dd463b88ee',
        correct_answer: 'a',
      },
    ],
    student: {
      name: 'nametest',
      profileImage: 'imgto=compress&cs=tinysrgb&w=800&lazy=load',
      id: 'dsfdfs',
    },
    template: [],
    score: 85,
    createdAt: '2024-04-16T14:40:57.333Z',
  },
];
const expectedArray = [
  {
    date: '2024-04-16T14:40:57.333Z',
    student: {
      name: 'nametest',
      id: 'dsfdfs',
      imageUrl: 'imgto=compress&cs=tinysrgb&w=800&lazy=load',
    },
    topic: 'Master Math',
    action: ['View'],
    score: 85,

    skill: '1.0 - Fraction',
  },
];
export const sampleRows = [
  {
    date: '12-June-2023',
    student: {
      name: 'Ashley24',
      id: '2256abc',
      imageUrl:
        'https://images.pexels.com/photos/17652903/pexels-photo-17652903/free-photo-of-naomi.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
    },
    topic: 'Master Math',
    action: ['View'],
    score: 85,

    skill: '1.0 - Fraction',
  },
  {
    id: 'video',
    topic: 'Master Math',
    action: ['View'],
    date: '12-June-2023',
    score: '40',
    skill: '1.0 - Fraction',
    video: {
      title: 'Video 1',
      length: '10:00',
      thumbNail: 'https://i.ytimg.com/vi/1Ne1hqOXKKI/maxresdefault.jpg',
    },
    student: {
      name: 'Ashley',
      id: '2256abc',
      imageUrl:
        'https://images.pexels.com/photos/17652903/pexels-photo-17652903/free-photo-of-naomi.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
    },
  },
  {
    id: 'video',
    topic: 'Master Math',
    action: ['View'],
    date: '12-June-2023',
    score: '90',
    skill: '1.0 - Fraction',
    video: {
      title: 'Video 1',
      length: '10:00',
      thumbNail: 'https://i.ytimg.com/vi/1Ne1hqOXKKI/maxresdefault.jpg',
    },
    student: {
      name: 'Ashley',
      id: '2256abc',
      imageUrl:
        'https://images.pexels.com/photos/17652903/pexels-photo-17652903/free-photo-of-naomi.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
    },
  },
  {
    id: 'video',
    topic: 'Master Math',
    action: ['View'],
    date: '12-June-2023',
    score: '20',
    skill: '1.0 - Fraction',
    video: {
      title: 'Video 1',
      length: '10:00',
      thumbNail: 'https://i.ytimg.com/vi/1Ne1hqOXKKI/maxresdefault.jpg',
    },
    student: {
      name: 'Ashley',
      id: '2256abc',
      imageUrl:
        'https://images.pexels.com/photos/17652903/pexels-photo-17652903/free-photo-of-naomi.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
    },
  },
  {
    id: 'video',
    topic: 'Master Math',
    action: ['View'],
    date: '12-June-2023',
    score: '90',
    skill: '1.0 - Fraction',
    video: {
      title: 'Video 1',
      length: '10:00',
      thumbNail: 'https://i.ytimg.com/vi/1Ne1hqOXKKI/maxresdefault.jpg',
    },
    student: {
      name: 'Ashley',
      id: '2256abc',
      imageUrl:
        'https://images.pexels.com/photos/17652903/pexels-photo-17652903/free-photo-of-naomi.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
    },
  },
  {
    id: 'video',
    topic: 'Master Math',
    action: ['View'],
    date: '12-June-2023',
    score: '90',
    skill: '1.0 - Fraction',
    video: {
      title: 'Video 1',
      length: '10:00',
      thumbNail: 'https://i.ytimg.com/vi/1Ne1hqOXKKI/maxresdefault.jpg',
    },
    student: {
      name: 'Ashley',
      id: '2256abc',
      imageUrl:
        'https://images.pexels.com/photos/17652903/pexels-photo-17652903/free-photo-of-naomi.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
    },
  },
  {
    id: 'video',
    topic: 'Master Math',
    action: ['View'],
    date: '12-June-2023',
    score: '90',
    skill: '1.0 - Fraction',
    video: {
      title: 'Video 1',
      length: '10:00',
      thumbNail: 'https://i.ytimg.com/vi/1Ne1hqOXKKI/maxresdefault.jpg',
    },
    student: {
      name: 'Ashley',
      id: '2256abc',
      imageUrl:
        'https://images.pexels.com/photos/17652903/pexels-photo-17652903/free-photo-of-naomi.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
    },
  },
  {
    id: 'video',
    topic: 'Master Math',
    action: ['View'],
    date: '12-June-2023',
    score: '47',
    skill: '1.0 - Fraction',
    video: {
      title: 'Video 1',
      length: '10:00',
      thumbNail: 'https://i.ytimg.com/vi/1Ne1hqOXKKI/maxresdefault.jpg',
    },
    student: {
      name: 'Ashley',
      id: '2256abc',
      imageUrl:
        'https://images.pexels.com/photos/17652903/pexels-photo-17652903/free-photo-of-naomi.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
    },
  },
  {
    id: 'video',
    topic: 'Master Math',
    action: ['View'],
    date: '12-June-2023',
    score: '78',
    skill: '1.0 - Fraction',
    video: {
      title: 'Video 1',
      length: '10:00',
      thumbNail: 'https://i.ytimg.com/vi/1Ne1hqOXKKI/maxresdefault.jpg',
    },
    student: {
      name: 'Ashley',
      id: '2256abc',
      imageUrl:
        'https://images.pexels.com/photos/17652903/pexels-photo-17652903/free-photo-of-naomi.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
    },
  },
  {
    id: 'video',
    topic: 'Master Math',
    action: ['View'],
    date: '12-June-2023',
    score: '60',
    skill: '1.0 - Fraction',
    video: {
      title: 'Video 1',
      length: '10:00',
      thumbNail: 'https://i.ytimg.com/vi/1Ne1hqOXKKI/maxresdefault.jpg',
    },
    student: {
      name: 'Ashley',
      id: '2256abc',
      imageUrl:
        'https://images.pexels.com/photos/17652903/pexels-photo-17652903/free-photo-of-naomi.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
    },
  },
  {
    id: 'video',
    topic: 'Master Math',
    action: ['View'],
    date: '12-June-2023',
    score: '90',
    skill: '1.0 - Fraction',
    video: {
      title: 'Video 1',
      length: '10:00',
      thumbNail: 'https://i.ytimg.com/vi/1Ne1hqOXKKI/maxresdefault.jpg',
    },
    student: {
      name: 'Ashley',
      id: '2256abc',
      imageUrl:
        'https://images.pexels.com/photos/17652903/pexels-photo-17652903/free-photo-of-naomi.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
    },
  },
  {
    id: 'video',
    topic: 'Master Math',
    action: ['View'],
    date: '12-June-2023',
    score: '90',
    skill: '1.0 - Fraction',
    video: {
      title: 'Video 1',
      length: '10:00',
      thumbNail: 'https://i.ytimg.com/vi/1Ne1hqOXKKI/maxresdefault.jpg',
    },
    student: {
      name: 'Ashley',
      id: '2256abc',
      imageUrl:
        'https://images.pexels.com/photos/17652903/pexels-photo-17652903/free-photo-of-naomi.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
    },
  },
  {
    id: 'video',
    topic: 'Master Math',
    action: ['View'],
    date: '12-June-2023',
    score: '90',
    skill: '1.0 - Fraction',
    video: {
      title: 'Video 1',
      length: '10:00',
      thumbNail: 'https://i.ytimg.com/vi/1Ne1hqOXKKI/maxresdefault.jpg',
    },
    student: {
      name: 'Ashley',
      id: '2256abc',
      imageUrl:
        'https://images.pexels.com/photos/17652903/pexels-photo-17652903/free-photo-of-naomi.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
    },
  },
  {
    id: 'video',
    topic: 'Master Math',
    action: ['View'],
    date: '12-June-2023',
    score: '90',
    skill: '1.0 - Fraction',
    video: {
      title: 'Video 1',
      length: '10:00',
      thumbNail: 'https://i.ytimg.com/vi/1Ne1hqOXKKI/maxresdefault.jpg',
    },
    student: {
      name: 'Ashley',
      id: '2256abc',
      imageUrl:
        'https://images.pexels.com/photos/17652903/pexels-photo-17652903/free-photo-of-naomi.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
    },
  },
];
