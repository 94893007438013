import React from "react";

export default function WarningIcon() {
  return (
    <svg width="71" height="70" viewBox="0 0 71 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1081_24606)">
        <path d="M69.5981 55.5617L40.8852 7.94365C39.754 6.06773 37.6905 4.90234 35.4999 4.90234C33.3092 4.90234 31.2457 6.06773 30.1144 7.94379L1.40166 55.5617C0.232577 57.5006 0.197988 59.9299 1.31129 61.9013C2.42486 63.8728 4.52295 65.0976 6.78701 65.0976H64.2127C66.4768 65.0976 68.575 63.8728 69.6886 61.9012C70.8019 59.9296 70.7673 57.5004 69.5981 55.5617ZM65.7202 59.6603C65.5691 59.9271 65.35 60.1491 65.0852 60.3037C64.8204 60.4583 64.5193 60.5399 64.2127 60.5402H6.78701C6.48041 60.5399 6.17936 60.4584 5.91456 60.3038C5.64976 60.1493 5.43069 59.9272 5.27969 59.6604C5.12915 59.3933 5.05212 59.091 5.05647 58.7844C5.06082 58.4779 5.14638 58.1779 5.30443 57.9152L34.0174 10.2971C34.1722 10.0423 34.3898 9.83146 34.6494 9.68485C34.909 9.53825 35.2019 9.46078 35.5 9.45986C36.1029 9.45986 36.671 9.78074 36.9824 10.2971L65.6952 57.9152C65.8533 58.1778 65.9389 58.4778 65.9433 58.7843C65.9477 59.0909 65.8707 59.3932 65.7202 59.6603Z" fill="#F41F52"/>
        <path d="M35.521 23.6531C33.7874 23.6531 32.4345 24.5833 32.4345 26.2324C32.4345 31.2639 33.0263 38.4942 33.0263 43.5258C33.0265 44.8365 34.1682 45.3862 35.5212 45.3862C36.5359 45.3862 37.9735 44.8365 37.9735 43.5258C37.9735 38.4943 38.5654 31.2641 38.5654 26.2324C38.5654 24.5834 37.1701 23.6531 35.521 23.6531ZM35.5636 48.3036C33.7032 48.3036 32.3077 49.7834 32.3077 51.5594C32.3077 53.293 33.7031 54.8152 35.5636 54.8152C37.2972 54.8152 38.7771 53.293 38.7771 51.5594C38.7771 49.7834 37.297 48.3036 35.5636 48.3036Z" fill="#F41F52"/>
        </g>
        <defs>
        <clipPath id="clip0_1081_24606">
        <rect width="70" height="70" fill="white" transform="translate(0.5)"/>
        </clipPath>
        </defs>
    </svg>
  );
}
