import React from 'react';

export default function EditIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2144 1.56443C13.4238 1.35499 13.6725 1.18885 13.9461 1.0755C14.2198 0.962149 14.5131 0.903809 14.8093 0.903809C15.1055 0.903809 15.3987 0.962149 15.6724 1.0755C15.9461 1.18885 16.1947 1.35499 16.4041 1.56443C16.6136 1.77388 16.7797 2.02252 16.8931 2.29618C17.0064 2.56983 17.0648 2.86313 17.0648 3.15932C17.0648 3.45552 17.0064 3.74882 16.8931 4.02247C16.7797 4.29612 16.6136 4.54477 16.4041 4.75421L5.63863 15.5197L1.25269 16.7159L2.44885 12.3299L13.2144 1.56443Z"
        stroke="#000"
        stroke-width="1.59489"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
