import React, { useEffect } from 'react';
import { Breadcrumbs, Card, Grid, Link, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import StudentDetails from 'components/student/studentDetail';
import { parents, school, student, teacher2 } from 'assets';
import { CardInfo } from 'components/Dashboard/cardInfo';
import StudentProgressHeader from 'components/student/progress/header';
import StudentProgressTable from 'components/student/progress/table';
import TableHeader from 'components/tableHeader';
import { useParams } from 'react-router-dom';
import { getStudentDetail } from 'store/Thunk/studentThunk';
import { useAppDispatch, useAppSelector } from 'store/store';
export default function StudentDetail() {
  function handleBreadCrumbClick(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }
  const { studentId } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      getStudentDetail({
        id: studentId,
        // id: '32c33cb6-b042-45f1-8677-c372dd40508b' || studentId,
      }),
    );
  }, []);
  const { studentDetail } = useAppSelector((state) => state.studentReducer);
  console.log('studentDetail', studentDetail);

  const header = [
    { label: 'Student name', width: 1, value: 'name' },
    { label: 'Total Assignments', width: 1, value: 'totalAssignments' },
    { label: 'Completed', width: 1, value: 'completedAssignments' },
    { label: 'Pending', width: 1, value: 'pendingAssignments' },
    { label: 'Total $G3MS', width: 1, value: 'totalGems' },
    { label: 'Watch Time', width: 1, value: 'totalWatchHours' },
    {
      label: 'Intervention Recommended',
      width: 1,
      value: 'interVentionRecommended',
    },
    { label: 'Overall Grade', width: 1, value: 'averageScore' },
  ];
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/"
      onClick={() => handleBreadCrumbClick}
    >
      Dashboard
    </Link>,
    <Typography key="2" color="#C73E9D">
      Student Profile
    </Typography>,
  ];
  const topCounters: any[] = [
    {
      title: 'Total Classes',
      count: studentDetail?.totalClasses || 0,
      icon: student,
      color: '',
      sm: 6,
      md: 3,
    },
    {
      title: 'Total Assigned Video Lessons',
      count: studentDetail?.totalAssignedVideos || 0,
      icon: teacher2,
      color: '',
      sm: 6,
      md: 3,
    },
    {
      title: 'Total Assigned Assessments',
      count: studentDetail?.totalAssignments || 0,
      icon: parents,
      color: '',
      sm: 6,
      md: 3,
    },
    {
      title: 'Total Skills Mastered',
      count: studentDetail?.totalSkills || 0,
      icon: school,
      color: '',
      sm: 6,
      md: 3,
    },
  ];
  const color = '#E9F6F3';
  const classes = {
    id: '1',
    name: 'test',
    studentCount: '2',
    target: 'on',
    students: [
      {
        id: 'string',
        name: 'string',
        assesment: 'string',
        completed: 'string',
        pending: 'string',
        progress: 'string',
        dueDate: 'string',
      },
      {
        id: 'string',
        name: 'string',
        assesment: 'string',
        completed: 'string',
        pending: 'string',
        progress: 'string',
        dueDate: 'string',
      },
      {
        id: 'string',
        name: 'string',
        assesment: 'string',
        completed: 'string',
        pending: 'string',
        progress: 'string',
        dueDate: 'string',
      },
      {
        id: 'string',
        name: 'string',
        assesment: 'string',
        completed: 'string',
        pending: 'string',
        progress: 'string',
        dueDate: 'string',
      },
      {
        id: 'string',
        name: 'string',
        assesment: 'string',
        completed: 'string',
        pending: 'string',
        progress: 'string',
        dueDate: 'string',
      },
    ],
  };
  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        style={{ marginBottom: 20 }}
      >
        {breadcrumbs}
      </Breadcrumbs>
      <Card className="datatable_card" sx={{ p: '24px' }}>
        <StudentDetails />
        <Grid container spacing={4} sx={{ mt: 3, mb: 8 }}>
          {topCounters?.map((info: any) => (
            <CardInfo
              item={{
                title: ` ${info?.title}`,
                content: `${info?.count || 0}`,
                icon: `${info?.icon}`,
                color: `${info?.color}`,
                sm: info?.sm || 4,
                md: info?.md || 4,
              }}
            />
          ))}
        </Grid>
        <TableHeader
          item={{
            title: 'Progress Summary',
            action: true,
          }}
        />
        <StudentProgressHeader />
        <StudentProgressTable
          color={color}
          header={header}
          classes={studentDetail}
        />
      </Card>
    </>
  );
}
