import React from 'react';
import {
  Box,
  IconButton,
  Breadcrumbs,
  Link,
  Typography,
  Paper,
  Card,
  Button,
  CardActions,
  CardContent,
} from '@mui/material';
import CustomTable from 'components/customTable';
import DefaultCell from 'components/DefaultCell';
import { EditIcon, DeleteIcon, ViewIcon } from 'icons';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Classroom from 'icons/ManageClassroom';
import ManageStudents from 'icons/manageStudent';
import ManageTeachers from 'icons/manageTeacher';
import AddIcon from '@mui/icons-material/Add';
import ClassroomTable from './classroom';
import AddUpdate from 'components/dialog/school/AddUpdate';
import AddUpdateClass from 'components/dialog/classroom/AddUpdate';

function handleBreadCrumbClick(
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const cardData = [
  { title: 'Manage Class', icons: <Classroom /> },
  { title: 'Manage Students', icons: <ManageStudents /> },
  { title: 'Manage Teachers', icons: <ManageTeachers /> },
];

function Index() {
  const [isOpenAdd, setIsOpenAdd] = React.useState<boolean>(false);
  const [isOpenAddClass, setIsOpenAddClass] = React.useState<boolean>(false);

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/"
      onClick={() => handleBreadCrumbClick}
    >
      School Management
    </Link>,
    <Typography key="2" color="#C73E9D">
      Schools 
    </Typography>,
  ];

  const columns = [
    {
      Header: 'Classroom',
      accessor: 'classroom',
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: 'School',
      accessor: 'school',
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: 'Teacher',
      accessor: 'teacher',
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: 'Date',
      accessor: 'date',
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: 'Assignment',
      accessor: 'assignment',
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: ({ value }: any) => (
        <Box>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
          <IconButton>
            <ViewIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const handleClose = () => {
    setIsOpenAdd(false);
    setIsOpenAddClass(false);
  };

  const handleOnChangeSchool = () => {
    setIsOpenAdd(!isOpenAdd);
  };

  const handleOnChangeClassRoom = () => {
    setIsOpenAddClass(!isOpenAddClass);
  };

  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        style={{ marginBottom: 20 }}
      >
        {breadcrumbs}
      </Breadcrumbs>
      <Card className="datatable_card" sx={{ p: '24px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',

            alignItems: 'center',
          }}
        >
          <Box>
            <Typography
              variant="body1"
              sx={{ color: '#131515', fontSize: '18px', fontWeight: '500' }}
            >
              School ABC
            </Typography>
          </Box>
          <Box>
            {' '}
            <Button
              variant="contained"
              className="primary_bg_btn"
              sx={{ ml: 3 }}
              onClick={() => {
                setIsOpenAdd(!isOpenAdd);
              }}
              endIcon={<AddIcon />}
            >
              {'Add School'}
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {cardData.map((item: any) => {
            return (
              <Box
                sx={{
                  margin: '12px 24px',
                  padding: '14px 20px',
                  width: '32vw',
                }}
              >
                <Paper sx={{}}>
                  <Card>
                    <CardContent
                      sx={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <Box>{item?.icons}</Box>
                      <Typography
                        variant="body1"
                        sx={{
                          mb: 1,
                          color: '#484848',
                          fontSize: '16px',
                          fontWeight: '500',
                        }}
                      >
                        {item?.title}
                      </Typography>
                    </CardContent>
                  </Card>
                </Paper>
              </Box>
            );
          })}
        </Box>
        <CustomTable
          table={{
            columns: columns,
            rows: [],
          }}
          title="Classroom"
          canSearch
          buttonTitle="Add Classroom"
          handleOnChange={handleOnChangeClassRoom}
        />
      </Card>
      {isOpenAdd && <AddUpdate title={'Update School'} open={isOpenAdd} onClose={handleClose} />}
      {isOpenAddClass && (
        <AddUpdateClass open={isOpenAddClass} onClose={handleClose} />
      )}
    </>
  );
}

export default Index;
