import { useCallback, useState } from 'react';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import NodataToShow from 'icons/NoDataToShow';

//*** Icons
import AddIcon from '@mui/icons-material/Add';
import { useParams } from 'react-router-dom';
import AddStudentToClassRoom from '../modals/AddStudentToClassRoom';
import useSchoolClassroomInfo from 'hooks/useSchoolClassroomInfo';
import { getStudentListByClassroom } from 'store/Thunk/studentThunk';
import { useAppDispatch } from 'store/store';

function Index() {
  const { id } = useParams();
  if (!id) {
    throw new Error('School id is required');
  }

  const [isAddStudentDialogOpen, setIsAddStudentDialogOpen] = useState(false);
  const { classroomId } = useSchoolClassroomInfo();
  const dispatch = useAppDispatch();

  const toggleIsAddDialogOpen = useCallback(() => {
    setIsAddStudentDialogOpen((value) => !value);
  }, []);

  return (
    <>
      <Box sx={{ p: '24px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              m: '15px',
              mt: '5px',
            }}
          >
            <NodataToShow />
            <Typography
              variant="body1"
              sx={{ fontSize: '18px', fontWeight: '600' }}
            >
              No Student Found
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="body2"
              onClick={() => setIsAddStudentDialogOpen(true)}
              sx={{
                textAlign: 'justify',
                fontSize: '13px',
                fontWeight: '400',
                p: '0 30px',
              }}
            >
              Please Add Students to the classroom
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              m: '15px 0 0 0',
            }}
          >
            <Button
              variant="contained"
              className="primary_bg_btn"
              onClick={toggleIsAddDialogOpen}
              endIcon={<AddIcon />}
            >
              Add Student
            </Button>
          </Box>
        </Box>
      </Box>

      {isAddStudentDialogOpen && (
        <AddStudentToClassRoom
          isAddStudentDialogOpen={isAddStudentDialogOpen}
          setIsAddStudentDialogOpen={setIsAddStudentDialogOpen}
          onClose={toggleIsAddDialogOpen}
          classRoomId={classroomId}
          schoolId={id}
          fetchData={() => {
            dispatch(getStudentListByClassroom({ id }));
          }}
        />
      )}
    </>
  );
}

export default Index;
