import React from 'react';

export default function GraduationHat() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="graduation-hat-01">
        <path
          id="Icon"
          d="M5 10.3831V16.3943C5 16.7532 5 16.9327 5.05465 17.0912C5.10299 17.2313 5.18187 17.3589 5.28558 17.4648C5.40287 17.5846 5.5634 17.6648 5.88446 17.8254L11.2845 20.5254C11.5468 20.6565 11.678 20.7221 11.8156 20.7479C11.9375 20.7708 12.0625 20.7708 12.1844 20.7479C12.322 20.7221 12.4532 20.6565 12.7155 20.5254L18.1155 17.8254C18.4366 17.6648 18.5971 17.5846 18.7144 17.4648C18.8181 17.3589 18.897 17.2313 18.9453 17.0912C19 16.9327 19 16.7532 19 16.3943V10.3831M2 8.88312L11.6422 4.06201C11.7734 3.99642 11.839 3.96362 11.9078 3.95071C11.9687 3.93928 12.0313 3.93928 12.0922 3.95071C12.161 3.96362 12.2266 3.99642 12.3578 4.06201L22 8.88312L12.3578 13.7042C12.2266 13.7698 12.161 13.8026 12.0922 13.8155C12.0313 13.827 11.9687 13.827 11.9078 13.8155C11.839 13.8026 11.7734 13.7698 11.6422 13.7042L2 8.88312Z"
          stroke="#484848"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
}
