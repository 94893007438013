import React, { FC } from 'react';
import { Button, ButtonProps } from '@mui/material';

interface SharedButtonProps extends ButtonProps {
  variant: 'text' | 'outlined' | 'contained';
  name: string;
  disabled?: boolean;
  className?: string;
}

const SharedButton: FC<SharedButtonProps> = ({
  variant,
  name,
  disabled,
  className,
  ...props
}) => {
  return (
    <Button
      variant={variant}
      disabled={disabled}
      className={className}
      {...props}
    >
      {name}
    </Button>
  );
};

export default SharedButton;
