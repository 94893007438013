// *** React Imports
import React, { useEffect, useState } from 'react';

// *** Material UI Imports
import {
  Box,
  Grid,
  TextField,
  Autocomplete,
  InputLabel,
  CircularProgress,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  createFilterOptions,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
  Stack,
  List,
  Chip,
  Checkbox,
  Paper,
  Divider,
} from '@mui/material';

import MDDialog from '@core/components/MDDialog';

/* mui-color-input' */
import { useAppDispatch, useAppSelector } from 'store/store';
import { getAuthUser, getOptionLabel } from 'helper/service';

import AddSkill from 'components/dialog/skill/addSkill';
import AddSubTopic from 'components/dialog/subtopic/addSubTopic';
import { notificationFail } from 'store/Slice/notificationSlice';
import { generateQuestions } from 'store/Thunk/questionThunk';
import Validations from 'helper/validations';
const questionType = [
  'text',
  'image',
  'audio',
  'video',
  'math',
  'passage',
  'sound',
  'boolean',
];
const Grades = ['6th', '7th', '8th'];
const questionLevel = ['EASY', 'MEDIUM', 'HARD'];

function GenerateQuiz({
  editId,
  open,
  onClose,
  setQuestionList,
  setOpenAiFail,
  formData,
  setFormData,
}: any) {
  const dispatch: any = useAppDispatch();

  const { schoolList } = useAppSelector((state: any) => state.schoolReducer);
  const { btnLoading, screenLoading } = useAppSelector(
    (state: any) => state.loadingReducer,
  );
  const { quizTemplate } = useAppSelector(
    (state: any) => state.templateReducer,
  );

  useEffect(() => {
    setFormData({ ...formData, school: schoolList });
  }, [schoolList]);
  console.log('domainList', formData);

  const [formError, setFormError] = useState<any>({});
  const [screenLoader, setScreenLoader] = React.useState(false);
  const [loadingGrade, setLoadingGrade] = React.useState(false);

  const [openAssignTo, setOpenAssignTo] = useState(false);
  const [openGrade, setOpenGrade] = useState(false);
  const [openLevel, setOpenLevel] = useState(false);

  useEffect(() => {
    setScreenLoader(screenLoading);
  }, [screenLoading]);

  const handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  console.log('quizTemplate', quizTemplate);

  const handleChangeAutocomplete =
    (name: string) =>
    (
      event: React.SyntheticEvent<Element, Event>,
      newValue: string | null | any,
    ) => {
      let param = {
        [name]: newValue,
        ...(name === 'topic' && {
          sub_topic: '',
          skill: '',
        }),
        ...(name === 'sub_topic' && {
          skill: '',
        }),
        ...(name === 'district' && {
          school: [],
        }),
      };
      setFormData({ ...formData, ...param });
    };

  const onSave = (event: any) => {
    let _request: any = {
      questionsCount: parseInt(formData.questionCount) || '',
      questionType: formData.type || '',
      questionLevel: formData.level || '',
    };
    let allError = Validations.validateGenerateQuestionForm(_request);
    console.log(allError);
    if (
      Object.entries(allError).length === 0 &&
      allError.constructor === Object
    ) {
      dispatch(
        generateQuestions({
          _request,
          id: quizTemplate?.id,
        }),
      ).then((res: any) => {
        console.log('res', res);
        Array.isArray(res.payload)
          ? setQuestionList(res.payload)
          : setOpenAiFail(true);
      });
      onClose();
    } else {
      setFormError(allError);
    }
  };

  const dialogProps = {
    open,
    onClose,
    generate: true,
    titleColor: '#20A58A',
    onSave,
    title: editId ? 'Edit a Quiz' : 'Generate Quiz Questions with AI',
    size: 'md',
    saveTbtText: editId ? 'Edit a Quiz' : 'Generate Question',
    loading: btnLoading,
    screenLoader,
  };

  return (
    <MDDialog {...dialogProps}>
      <Box sx={{ p: 3 }}>
        <Grid container spacing={6} sx={{ mb: 7 }}>
          <Grid item xs={6}>
            <Box className="flex flex-1 flex-col gap-2">
              <Typography color="#20A58A" fontWeight={700} fontSize={'16px'}>
                Assessment Title: {quizTemplate?.title}
              </Typography>

              <Typography color="#20A58A" fontWeight={700} fontSize={'16px'}>
                Standard: {quizTemplate?.subTopic[0]?.subTopic?.name}
              </Typography>

              <Typography color="#20A58A" fontWeight={700} fontSize={'16px'}>
                Skill: {quizTemplate?.skill[0]?.skill?.name}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="flex flex-1 flex-col gap-2">
              <Typography color="#20A58A" fontWeight={700} fontSize={'16px'}>
                Topic: {quizTemplate?.topic[0]?.topic?.name}
              </Typography>

              <Typography color="#20A58A" fontWeight={700} fontSize={'16px'}>
                Domain: {quizTemplate?.domain?.id} {quizTemplate?.domain?.name}
              </Typography>

              <Typography color="#20A58A" fontWeight={700} fontSize={'16px'}>
                Grade: {quizTemplate?.grade[0]?.grade?.name}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box className="flex flex-1 flex-col">
              <InputLabel
                error={formError && formError?.message}
                sx={{ color: '#484848', pb: 2 }}
              >
                <Typography color="#20A58A" fontWeight={700} fontSize={'16px'}>
                  Number of Questions
                </Typography>
              </InputLabel>
              <TextField
                required
                fullWidth
                id="message"
                value={formData?.questionCount || ''}
                onChange={handleChangeInput}
                placeholder="Enter number of questions"
                name="questionCount"
                error={formError && formError?.questionsCount}
                helperText={
                  formError && formError?.questionsCount
                    ? formError.questionsCount
                    : ''
                }
                // sx={{ input: { textAlign: 'center' } }}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="flex flex-1 flex-col">
              <InputLabel
                error={formError && formError?.type}
                sx={{ color: '#484848', pb: 2 }}
              >
                <Typography color="#20A58A" fontWeight={700} fontSize={'16px'}>
                  Question Type
                </Typography>
              </InputLabel>
              <Box className="flex-1">
                <Autocomplete
                  id="grade"
                  open={openAssignTo}
                  onOpen={() => setOpenAssignTo(true)}
                  onClose={() => setOpenAssignTo(false)}
                  isOptionEqualToValue={(option, value) => option === value}
                  getOptionLabel={(option) =>
                    option
                      ? option.charAt(0).toUpperCase() + option.slice(1)
                      : ''
                  }
                  options={questionType}
                  filterSelectedOptions
                  // disabled={editContentInfo && true}
                  value={formData?.type || ''}
                  onChange={handleChangeAutocomplete('type')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ textTransform: 'capitalize' }}
                      placeholder="Select"
                      helperText={formError?.questiontype || ''}
                      error={formError && formError?.questiontype}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingGrade ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box className="flex flex-1 flex-col">
              <InputLabel
                error={formError && formError?.questionLevel}
                sx={{ color: '#484848', pb: 2 }}
              >
                <Typography color="#20A58A" fontWeight={700} fontSize={'16px'}>
                  Question Level
                </Typography>
              </InputLabel>
              <Box className="flex-1">
                <Autocomplete
                  id="grade"
                  open={openLevel}
                  onOpen={() => setOpenLevel(true)}
                  onClose={() => setOpenLevel(false)}
                  isOptionEqualToValue={(option, value) => option === value}
                  getOptionLabel={(option) => option || ''}
                  options={questionLevel}
                  filterSelectedOptions
                  // disabled={editContentInfo && true}
                  value={formData?.level || ''}
                  onChange={handleChangeAutocomplete('level')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Choose question level"
                      error={formError && formError?.questionLevel}
                      helperText={formError?.questionLevel || ''}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingGrade ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </MDDialog>
  );
}

export default GenerateQuiz;
