// ** React Imports
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
// ** MUI Imports
import { IconButton, Box, Grid, Card, FormHelperText } from '@mui/material';
import Typography, { TypographyProps } from '@mui/material/Typography';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CloseIcon from '@mui/icons-material/Close';

// ** Third Party Components
import { useDropzone } from 'react-dropzone';
// *** Icons
import { FileUploadIcon } from 'icons';
// *** Custom Styles
import { styled, useTheme } from '@mui/material/styles';
import LinearProgressWithLabel from '@core/components/lLinearProgressWithLabel';

const HeadingTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(4),
  },
}));

const FileUpload = ({
  uploadedFile,
  setUploadedFile,
  errorText,
  videoProgress = 0,
}: any) => {
  // const [videoFile, setVideoFile] = useState<any>(null);
  const [previewImage, setPreviewImage] = useState<any>('');
  const theme = useTheme();

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      console.log('files');
      setUploadedFile(acceptedFiles);
    },
    [uploadedFile],
  );

  useEffect(() => {
    if (uploadedFile?.length) {
      getVideoThumbnail(uploadedFile[0], 4).then((thumbnail: any) => {
        setPreviewImage(thumbnail);
      });
    } else {
      setPreviewImage('');
    }
  }, [uploadedFile]);

  const importFileandPreview = (file: any, revoke: any = false) => {
    return new Promise((resolve, reject) => {
      window.URL = window.URL || window.webkitURL;
      let preview = window.URL.createObjectURL(file);
      // remove reference
      if (revoke) {
        window.URL.revokeObjectURL(preview);
      }
      setTimeout(() => {
        resolve(preview);
      }, 100);
    });
  };

  const getVideoThumbnail = (file: any, videoTimeInSeconds: any = 1) => {
    return new Promise((resolve, reject) => {
      if (file.type.match('video')) {
        importFileandPreview(file).then((urlOfFIle: any) => {
          var video: any = document.createElement('video');
          var timeupdate = function () {
            if (snapImage()) {
              video.removeEventListener('timeupdate', timeupdate);
              video.pause();
            }
          };
          video.addEventListener('loadeddata', function () {
            if (snapImage()) {
              video.removeEventListener('timeupdate', timeupdate);
            }
          });
          var snapImage = function () {
            var canvas: any = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas
              .getContext('2d')
              .drawImage(video, 0, 0, canvas.width, canvas.height);
            var image = canvas.toDataURL();
            var success = image?.length > 100000;
            if (success) {
              URL.revokeObjectURL(urlOfFIle);
              resolve(image);
            }
            return success;
          };
          video.addEventListener('timeupdate', timeupdate);
          video.preload = 'metadata';
          video.src = urlOfFIle;
          // Load video in Safari / IE11
          video.muted = true;
          video.playsInline = true;
          video.currentTime = videoTimeInSeconds;
          video.play();
        });
      } else {
        reject('file not valid');
      }
    });
  };

  const { getRootProps, acceptedFiles } = useDropzone({
    maxSize: 60000000,
    maxFiles: 1,
    accept: {
      'video/mp4': ['.mp4', '.MP4'],
    },
    onDrop: onDrop,
  });

  const onDeleteUploadFile = (file: any) => {
    setUploadedFile(acceptedFiles.filter((obj: any) => obj.name !== file.name));
    // importFileandPreview(file, true)
  };
  return (
    <Box>
      <section className="container">
        <div {...getRootProps({ className: 'dropzone' })}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: ['column', 'column', 'row'],
              alignItems: 'center',
              justifyContent: 'center',
              border: 1,
              borderRadius: '8px',
              borderColor: errorText ? theme.palette.error.main : '#20A58A',
              padding: '25px',
              borderStyle: 'dashed',
              cursor: 'pointer',
            }}
          >
            <Box
              display={'flex'}
              alignItems={'center'}
              flexDirection={'column'}
            >
              <FileUploadIcon />
              <HeadingTypography
                variant="subtitle2"
                color="textSecondary"
                sx={{ my: 2 }}
              >
                Drop your video here, or <b>browse</b>
              </HeadingTypography>
              <Typography variant="caption" color="textSecondary">
                MP4, MOV, GIF, Max Size: 60MB
              </Typography>
            </Box>
          </Box>
        </div>
        {errorText && (
          <FormHelperText error id="password-error">
            {errorText || ''}
          </FormHelperText>
        )}
        {uploadedFile?.length > 0 && (
          <Box>
            <h4>Files</h4>
            {uploadedFile?.map((file: any) => (
              <Box
                sx={{
                  display: 'flex',
                  border: (theme) => `solid 1px ${theme.palette.divider}`,
                  borderRadius: '12px',
                  p: 2,
                }}
              >
                <img
                  src={previewImage}
                  width={'120px'}
                  height={'90px'}
                  style={{ borderRadius: '12px' }}
                />
                <Box sx={{ flex: 1, ml: 4 }}>
                  <Typography variant="subtitle1">{file.name}</Typography>
                  <Typography variant="subtitle2" color="text.secondary">
                    {`${Math.abs(file.size / 1048576).toFixed(2)}`} mb
                  </Typography>
                  {videoProgress > 0 && (
                    <Box mt={1}>
                      <LinearProgressWithLabel value={videoProgress} />
                    </Box>
                  )}
                </Box>
                <Box>
                  <IconButton
                    sx={{
                      // padding: 1.5,
                      fontSize: 16,
                    }}
                    onClick={() => onDeleteUploadFile(file)}
                  >
                    <CloseIcon sx={{ fontSize: 18 }} />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </section>
    </Box>
  );
};

export default FileUpload;
