// *** React Imports
import React, { useEffect, useState } from 'react';

// *** Material UI Imports
import {
  Box,
  Grid,
  TextField,
  Autocomplete,
  InputLabel,
  CircularProgress,
  IconButton,
  Button,
  Popover,
  InputAdornment,
  Checkbox,
  ListItemText,
  Avatar,
  Stack,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import CloseIcon from 'icons/closeIcon';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import MDDialog from '@core/components/MDDialog';

/* mui-color-input' */
import { useAppDispatch, useAppSelector } from 'store/store';
import Validations from 'helper/validations';
import { setBtnLoading } from 'store/Slice/loadingSlice';
import { addUpdateSchool } from 'store/Thunk/schoolThunk';
import SearchIcon from 'icons/search';
import spacing from '../../../../@core/theme/spacing/index';
import Profile from 'assets/actionIcons/profile.png';
import { getStudentListByClassroom } from 'store/Thunk/studentThunk';
import { useParams } from 'react-router-dom';
import {
  GetGroupDetailsByID,
  addUpdateGroup,
  getGroupListByClassId,
} from 'store/Thunk/groupThunk';
import { notificationFail } from 'store/Slice/notificationSlice';

// let studnets = [
//   { name: '1111 XYZ1111 XYZ1111 XYZ' },
//   { name: '2222 XYZ' },
//   { name: '3333 XYZ' },
//   { name: '4444 XYZ' },
//   { name: '5555 XYZ' },
//   { name: '6666 XYZ' },
//   { name: '7777 XYZ' },
// ];

function Index({ open, onClose, editId, classId }: any) {
  const dispatch: any = useAppDispatch();
  const { id } = useParams();

  const [formData, setFormData] = useState<any>({
    grade: [],
  });
  const [search, setSearch] = useState<string>('');
  const [formError, setFormError] = useState<any>({});
  const [screenLoader, setScreenLoader] = React.useState(false);
  const [groupName, setGroupName] = useState<string>('');
  const [selectedMembers, setSelectedMembers] = React.useState<any[]>([]);

  const { groupDetailsByID } = useAppSelector((state) => state.groupReducer);

  const { studentListByClassroom } = useAppSelector(
    (state) => state.studentReducer,
  );

  useEffect(() => {
    dispatch(getStudentListByClassroom({ id, search }));
  }, [search, id]);

  useEffect(() => {
    setGroupName(groupDetailsByID?.name);
    setSelectedMembers(groupDetailsByID?.GroupStudent);
  }, [groupDetailsByID]);

  useEffect(() => {
    if (editId !== null || '') {
      dispatch(GetGroupDetailsByID({ classId, groupId: editId }));
    } else {
      setGroupName('');
      setSelectedMembers([]);
    }
  }, [editId]);

  console.log('ERRR', formError);

  const onSave = (event: any) => {
    event.preventDefault();
    let _request: any = {
      classId: id,
      groupId: editId || null,
      formData: {
        groupName: groupName || '',
        studentIds:
          selectedMembers?.map((user) => {
            return user?.userId;
          }) || [],
      },
    };
    let allError = Validations.ValidateGroupDataFrom(_request?.formData);
    if (
      Object.entries(allError).length === 0 &&
      allError.constructor === Object
    ) {
      dispatch(setBtnLoading(true));
      dispatch(addUpdateGroup(_request))
        .unwrap()
        .then(() => {
          dispatch(getGroupListByClassId({ id }));
          onClose();
        })
        .catch((err: any) => {
          console.log(err);
        });
    } else {
      setFormError(allError);
    }
  };

  const dialogProps = {
    open,
    onClose,
    onSave,
    title: editId ? 'Update group' : 'Create a new group',
    size: 'xs',
    saveTbtText: editId ? 'Update' : 'Save',
    closeBtnText: 'Cancel',
    screenLoader,
  };

  function selectMembersContains(item: any) {
    const found = selectedMembers?.find((selected) => {
      return selected.user.id === item.user.id;
    });
    return Boolean(found);
  }

  // Load selected members by default
  return (
    <MDDialog {...dialogProps}>
      <Box sx={{ p: 3 }}>
        <Grid container>
          <Grid container spacing={5}>
            <Grid item md={12} sm={12} xs={12}>
              <InputLabel
                error={formError && formError?.groupName}
                sx={{ color: '#484848', pb: 2 }}
              >
                Group Name
              </InputLabel>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Group name"
                value={groupName}
                onChange={(e) => {
                  setGroupName(e.target.value);
                }}
                error={formError && formError?.groupName}
                helperText={
                  formError && formError?.groupName ? formError.groupName : ''
                }
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap">
                {selectedMembers?.map((item) => {
                  return (
                    <Chip
                      sx={{
                        backgroundColor: '#F9F9F9',
                        '&:hover': {
                          backgroundColor: '#F9F9F9',
                        },
                      }}
                      avatar={<Avatar alt="Natacha" src={Profile} />}
                      label={item?.user?.name}
                      variant="outlined"
                      onDelete={() => {
                        let newArr = selectedMembers.filter((items) => {
                          return items?.userId !== item?.userId;
                        });
                        setSelectedMembers(newArr);
                      }}
                    />
                  );
                })}
              </Stack>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <InputLabel
                error={formError && formError?.studentIds}
                sx={{ color: '#484848', pb: 2 }}
              >
                Select Members
              </InputLabel>
              <Accordion
                sx={{
                  border: '#dcdcdc 1px solid',
                  borderRadius: '10px',
                  boxShadow: 'unset',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    {selectedMembers?.length > 0
                      ? `Selected ${selectedMembers?.length}`
                      : 'Select Member'}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    placeholder={'Search'}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    sx={{
                      mb: 10,
                      background: '#F9F9F9',
                      m: '6px 10px',
                      width: '88%',
                    }}
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box sx={{ maxHeight: '150px', overflowY: 'scroll' }}>
                    <Grid container spacing={3}>
                      {studentListByClassroom?.items?.map((item: any) => {
                        return (
                          <Grid item xs={6} sm={6} md={6}>
                            <MenuItem
                              sx={{ p: 'unset' }}
                              key={12}
                              value={'name'}
                              onClick={() => {
                                if (selectMembersContains(item)) {
                                  let newArr = selectedMembers.filter(
                                    (items) => {
                                      return items?.userId !== item?.userId;
                                    },
                                  );
                                  setSelectedMembers(newArr);
                                } else {
                                  setSelectedMembers(
                                    selectedMembers?.length > 0
                                      ? [...selectedMembers, item]
                                      : [item],
                                  );
                                }
                              }}
                            >
                              <img
                                src={Profile}
                                alt="Student"
                                style={{ marginRight: '4px' }}
                              />
                              <Box
                                sx={{
                                  fontSize: '16px',
                                  fontWeight: '400',
                                  overflowX: 'hidden',
                                  width: '100px',
                                }}
                              >
                                {item?.user?.name}{' '}
                              </Box>
                              <Checkbox checked={selectMembersContains(item)} />
                            </MenuItem>
                          </Grid>
                        );
                      })}
                    </Grid>{' '}
                  </Box>
                </AccordionDetails>
              </Accordion>
              {formError?.studentIds && (
                <Typography
                  variant="body2"
                  sx={{ color: '#ff0000', fontSize: '12px', ml: 4 }}
                >
                  {formError?.studentIds}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </MDDialog>
  );
}

export default Index;
