import { Box, Typography, Button, Stack, Dialog } from '@mui/material';
import { InfoIcon } from 'icons';
import { useSelector } from 'react-redux';
import SimpleLoader from '@core/components/MDLoader/simpleLoader';
import { useEffect, useState } from 'react';

interface DialogProps {
  open: boolean;
  onClose: () => void;
  onOk: () => void;
}

function AlreadyAssignContentInfo({ onClose, open, onOk }: DialogProps) {
  const selectedInfo = localStorage.getItem('selectiveInfo');
  const [selectiveInfo, setSelectiveInfo] = useState<Record<string, any>>({});
  useEffect(() => {
    if (selectedInfo) {
      setSelectiveInfo(JSON.parse(selectedInfo));
    }
  }, [selectedInfo]);
  return (
    <Dialog
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '435px',
          },
        },
      }}
      fullWidth={true}
      maxWidth="sm"
      onClose={onClose}
      open={open}
    >
      <Stack sx={{ p: 5, gap: 5, textAlign: 'center', alignItems: 'center' }}>
        <InfoIcon />
        <Typography>
          {selectiveInfo?.assignTo === 'Individual'
            ? 'This task has already been assigned to an individual student. If reassigned, it will create a duplicate assignment, which will be ignored. New assignments will only be sent to students who have not yet received this task.'
            : `This task has already been assigned to one or more students in this ${
                selectiveInfo?.assignTo === 'Group' ? 'group' : 'class'
              }. If reassigned, it will create a duplicate assignment, which will be ignored. New assignments will only be sent to students who have not yet received this task.`}
        </Typography>
        <div
          style={{
            width: '100%',
            display: 'flex',
            gap: '20px',
          }}
        >
          <Button
            onClick={onOk}
            sx={{ width: '100%' }}
            variant="contained"
            color="secondary"
          >
            Ok
          </Button>
        </div>
      </Stack>
    </Dialog>
  );
}

export default AlreadyAssignContentInfo;
