import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

export interface ResponseData {
  status: String;
  messagecode: String;
  message: String;
  data: any;
  loading: boolean;
}

interface IntialState {
  schoolList: any;
  schoolByID: any;
  schoolByCity: any;
  schoolLeader: any;
  loading: boolean;
}

const intialState: IntialState = {
  schoolList: [],
  schoolByID: [],
  schoolByCity: [],
  schoolLeader: [],
  loading: false,
};

const SchoolSlice = createSlice({
  name: 'School_slice',
  initialState: intialState,
  reducers: {
    setSchoolList: (state: Draft<IntialState>, action: PayloadAction<[]>) => {
      state.schoolList = action.payload;
    },
    setSchoolByID: (state: Draft<IntialState>, action: PayloadAction<[]>) => {
      state.schoolByID = action.payload;
    },
    setSchoolByCity: (state: Draft<IntialState>, action: PayloadAction<[]>) => {
      state.schoolByCity = action.payload;
    },
    setSchoolLeader: (state: Draft<IntialState>, action: PayloadAction<[]>) => {
      state.schoolLeader = action.payload;
    },
    setLoading: (state: Draft<IntialState>, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const {
  setSchoolList,
  setSchoolByCity,
  setSchoolByID,
  setSchoolLeader,
  setLoading,
} = SchoolSlice.actions;

export default SchoolSlice.reducer;
