import { Box, Typography, Button, Stack, Dialog, Grid } from '@mui/material';
import { HazardIcon, SuccessIcon } from 'icons';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getLocalDateTime } from 'helper/service';

interface DialogProps {
  open?: any;
  itemID: string;
  duplicates: any;
  onCancel: () => void;
  onClose: (itemID: string) => void;
  onOk?: () => void;
}

function Duplicate({
  onClose,
  onCancel,
  itemID,
  open,
  onOk,
  duplicates,
}: DialogProps) {
  const { btnLoading } = useSelector((state: any) => state.assignContentSlice);
  const selectedInfo = localStorage.getItem('selectiveInfo');
  const [selectiveInfo, setSelectiveInfo] = useState<Record<string, any>>({});
  useEffect(() => {
    if (selectedInfo) {
      setSelectiveInfo(JSON.parse(selectedInfo));
    }
  }, [selectedInfo]);
  console.log('itemID', itemID);
  return (
    <Dialog
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '60vw',
            zIndex: '999999',
          },
        },
      }}
      fullWidth={true}
      maxWidth="sm"
      onClose={onClose}
      open={open}
      className="p-8"
    >
      <Stack
        p={8}
        sx={{ p: 5, gap: 5, textAlign: 'center', alignItems: 'center' }}
      >
        <HazardIcon />
        <Box mt={4} className="flex justify-center flex-col">
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 600,
              color: '#131515',
              lineHeight: '34px',
            }}
            mb={2}
          >
            Duplicate Standard Detected
          </Typography>
          <Typography
            className="flex self-center"
            color="#979C9E"
            width={'70%'}
            sx={{
              fontSize: '16px',
              color: '#484848',
              fontWeight: 700,
              lineHeight: '24px',
            }}
          >
            One or many standards has already been assigned to one or more
            users. Please review and remove the duplicates entries and try
            again.
          </Typography>
          {/* <Typography color="#484848">
            a these assignments to {selectiveInfo?.toName} ?
          </Typography> */}
          <Stack mt={8} gap={2}>
            {duplicates?.map((duplicate: any) => (
              <Grid container>
                <Grid item md={3}>
                  <Typography
                    color="#979C9E"
                    sx={{
                      fontSize: '16px',
                      color: '#484848',
                      fontWeight: 700,
                      lineHeight: '24px',
                    }}
                  >
                    {duplicate?.user?.name || ''}
                  </Typography>
                </Grid>
                <Grid item md={6} textAlign={'left'}>
                  <Typography
                    color="#979C9E"
                    sx={{
                      fontSize: '16px',
                      color: '#484848',
                      fontWeight: 700,
                      lineHeight: '24px',
                    }}
                  >
                    {duplicate?.skills[0]?.name || ''}
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography>
                    <Typography
                      color="#979C9E"
                      sx={{
                        fontSize: '16px',
                        color: '#484848',
                        fontWeight: 700,
                        lineHeight: '24px',
                      }}
                    >
                      {getLocalDateTime(duplicate?.assignedAt, 'MMMM D, YYYY')}
                    </Typography>{' '}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Stack>
        </Box>

        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}
          className="mt-4"
        >
          <Button
            onClick={() => onClose(itemID)}
            variant="contained"
            className="primary_bg_btn w-max"
          >
            Ok
          </Button>
        </div>
      </Stack>
    </Dialog>
  );
}

export default Duplicate;
