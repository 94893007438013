interface StatusBgColorIndexSignature {
  [status: string]: string;
}
interface StatusColorIndexSignature {
  [status: string]: string;
}
interface StatusIndexSignature {
  [status: string]: string;
}
export enum Roles {
  ADMIN = 'super_admin',
  TEACHER = 'teacher',
  PARENT = 'parent',
  SCHOOL_LEADER = 'school_leader',
  TUTOR = 'tutor',
}
enum StatusColor {
  Live = '#C73E9D',
  Started = '#B54708',
  Pause = '#B54708',
  Resume = '#B54708',
  Completed = '#027A48',
  Cancelled = '#B54708',
  Not_Started = '#84818a',
}

enum StatusBgColor {
  Live = '#ffecf9',
  Started = '#FFFAEB',
  Pause = '#FFFAEB',
  Resume = '#FFFAEB',
  Completed = '#ECFDF3',
  Cancelled = '#FFFAEB',
  Not_Started = '#F2F4F7',
}
enum Status {
  Live = 'Live',
  Started = 'Started',
  Pause = 'Pause',
  Resume = 'Resume',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  Not_Started = 'Not Started',
}
export const statusBgColors: StatusBgColorIndexSignature = StatusBgColor;
export const statusColors: StatusColorIndexSignature = StatusColor;
export const status: StatusIndexSignature = Status;
