import React from 'react';
import './styles/style.css';
import { SampleTeachersData, TeacherProgress } from './helpers/helpers';
import ProgressDetailTableComponent from './components/progress_detail_table';
import Nodata from '../skills_chart/components/noData';

const ProgressDetailSection = ({
  sectionTitle,
  progressData,
  leftVerticalBar,
}: {
  sectionTitle: string;
  leftVerticalBar: boolean;
  progressData?: TeacherProgress[];
}) => {
  return (
    <div style={Styles.mainContainer}>
      <div
        className="detailedProgressTable-section-header"
        style={Styles.sectionHeader}
      >
        <div
          className="detailedProgressTable-right-aligned"
          style={Styles.rightAligned}
        >
          {sectionTitle}
        </div>
        <div className="detailedProgressTable-left-aligned">
          <div className="searc-icon"></div>
          <input
            className="right-aligned-search-input"
            placeholder="Search"
            type="text"
          />
        </div>
      </div>
      <div
        className="detailedProgressTable-section-body"
        style={Styles.sectionBody}
      >
        {progressData?.length ? (
          progressData?.map((teacherProgress: TeacherProgress) => {
            return (
              <ProgressDetailTableComponent
                teacherProgress={teacherProgress}
                leftVerticalBar={leftVerticalBar}
              />
            );
          })
        ) : (
          <Nodata type={2} />
        )}
      </div>
    </div>
  );
};

export default ProgressDetailSection;

const Styles = {
  mainContainer: {
    width: 'auto',
    maxWidth: '100%',
    overFlowX: 'scroll',
    height: 'auto',
    backgroundColor: 'white',
    color: '#484848',
    marginTop: '1.5rem',
  },
  sectionHeader: {
    height: '60px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 2rem',
  },
  rightAligned: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',

    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '28px',
    marginTop: '1rem',
  },
  sectionBody: {
    padding: '1.5rem 2rem 1rem 2rem',
    width: '100%',
  },
};
