import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Typography,
  InputAdornment,
  TextField,
  Button,
  Grid,
  Pagination,
  Tooltip,
} from '@mui/material';

import SearchIcon from 'icons/search';
import GraduationHat from 'icons/graduationHat';
import StudentCard from 'pages/school_leader/dashboard/tabs/student/student_card';

import AddFirstStudent from './AddFirstStudent';
import { useAppDispatch, useAppSelector } from 'store/store';
import { getStudentListByClassroom } from 'store/Thunk/studentThunk';
import { useLocation, useParams } from 'react-router-dom';
import AddStudentToClassRoom from '../modals/AddStudentToClassRoom';
import RemoveUserFromClassDialog from 'components/dialog/users/Delete/removeUser';
import useSchoolClassroomInfo from 'hooks/useSchoolClassroomInfo';
import { removeUserFromClassRoom } from 'store/Thunk/classroomThunk';

const entriesPerPage = 8;
const pageSize = entriesPerPage;

function Index() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const location = useLocation();

  const { studentListByClassroom } = useAppSelector(
    (state) => state.studentReducer,
  );

  const [search, setSearch] = useState<string>('');
  const [entriesPerPage, setEntriesPerPage] = useState<number>(8);
  const [pageSize, setPageSize] = useState<number>(8);
  const [pageCount, setPageCount] = useState<number>(8);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [gotoPage, setGotoPage] = useState<number>(1);
  const [removeUserModal, setRemoveUserModal] = useState<boolean>(false);
  const urlParams = new URLSearchParams(window.location.search);
  const classroomId = urlParams.get('classroomId');
  const schoolId = urlParams.get('schoolId');

  const [userIdToRemove, setUserIdToRemove] = useState('');

  const [isAddStudentDialogOpen, setIsAddStudentDialogOpen] =
    useState<boolean>(false);

  // TODO: Improve this school id extraction logic... may be get them from useParams, it is not currently available tho

  const toggleIsAddStudentDialogOpen = useCallback(() => {
    setIsAddStudentDialogOpen((value) => !value);
  }, []);

  useEffect(() => {
    dispatch(getStudentListByClassroom({ id }));
  }, []);

  // Search input state handle
  const onSearchChange = (value: any) => {
    setSearch(value);
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) =>
    setGotoPage(value - 1);

  const renderPagination = () => (
    <Pagination
      count={pageCount}
      page={pageIndex + 1}
      shape="rounded"
      onChange={handleChangePage}
    />
  );

  const deleteUserFromClassRoom = () => {
    dispatch(
      removeUserFromClassRoom({
        typeToRemove: 'student',
        classRoomId: classroomId || '',
        payload: { studentIds: [userIdToRemove] },
      }),
    )
      .unwrap()
      .then((res) => {
        if (res?.status === 200 || res?.status === 202) {
          setRemoveUserModal(false);
          setUserIdToRemove('');
          dispatch(getStudentListByClassroom({ id }));
        }
      });
  };

  return (
    <>
      {studentListByClassroom?.items?.length > 0 ? (
        <Box>
          <Box sx={{ p: '24px', pt: '0' }}>
            <Box ml={2} mt={2}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="flex-start"
                mb={2}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <GraduationHat />
                  <Typography className="table_title" sx={{ ml: 2 }}>
                    Students
                  </Typography>
                </Box>

                <Button
                  variant="contained"
                  className="primary_bg_btn"
                  sx={{ ml: 3 }}
                  onClick={toggleIsAddStudentDialogOpen}
                >
                  Add Students
                </Button>
              </Box>
              <TextField
                placeholder={'searchText'}
                sx={{
                  mb: 10,
                  width: { xs: '100%', sm: '40%', md: '30%' },
                  background: '#F9F9F9',
                }}
                size="small"
                value={search}
                onChange={(e) => onSearchChange(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Grid container spacing={8}>
                {studentListByClassroom?.items?.map((item: any) => {
                  return (
                    <Grid item md={3} sm={4} xs={12}>
                      <StudentCard
                        id={item?.user?.id}
                        name={item?.user?.name}
                        grade={'-'}
                        setUserIdToRemove={setUserIdToRemove}
                        deleteUserFromClassRoom={deleteUserFromClassRoom}
                        setRemoveUserModal={setRemoveUserModal}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Box>
          {/* <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            my={4}
            sx={{
              border: '1px solid #E9EBED',
              borderRadius: '6px',
              p: 2,
              m: '24px',
              mt: 'unset',
            }}
          >
            {entriesPerPage && (
              <Box flex="1" display="flex" alignItems="center">
                <Typography variant="body2" className="entries-page-txt">
                  Show&nbsp;&nbsp;
                </Typography>
                <Autocomplete
                  disableClearable
                  value={pageSize.toString()}
                  options={['10', '25', '50', '100']}
                  onChange={(event, newValue) => {
                    setEntriesPerPage(parseInt(newValue, 10));
                  }}
                  size="small"
                  sx={{ width: '5rem' }}
                  renderInput={(params) => <TextField {...params} />}
                />
                <Typography variant="body2" className="entries-page-txt">
                  &nbsp;&nbsp;entries of {100}
                </Typography>
              </Box>
            )}
            {renderPagination()}
          </Box> */}
        </Box>
      ) : (
        <AddFirstStudent />
      )}
      <RemoveUserFromClassDialog
        onOk={() => deleteUserFromClassRoom()}
        onClose={() => setRemoveUserModal(false)}
        open={removeUserModal}
      />
      ,
      {isAddStudentDialogOpen && (
        <AddStudentToClassRoom
          isAddStudentDialogOpen={isAddStudentDialogOpen}
          setIsAddStudentDialogOpen={setIsAddStudentDialogOpen}
          onClose={toggleIsAddStudentDialogOpen}
          classRoomId={id}
          schoolId={schoolId}
          fetchData={() => {
            dispatch(getStudentListByClassroom({ id }));
          }}
        />
      )}
    </>
  );
}

export default Index;
