interface Column {
  id: 'video' | 'user' | 'date' | 'videoLength' | 'action';
  label: string;
  width: number;
  styles: {
    maxWidth: number;
    minWidth: number;
  };
  align?: 'right' | 'center';
  format?: (value: number) => string;
}

export const sampleColumns: readonly Column[] = [
  {
    id: 'video',
    label: 'Title',
    width: 1,
    styles: {
      minWidth: 0,
      maxWidth: 0,
    },
  },
  {
    id: 'user',
    label: 'User',
    width: 1,
    styles: {
      minWidth: 0,
      maxWidth: 0,
    },
  },
  {
    id: 'videoLength',
    label: 'Video Length',
    width: 1,
    styles: {
      minWidth: 0,
      maxWidth: 0,
    },
  },
  {
    id: 'date',
    label: 'Date Posted',
    width: 1,
    styles: {
      minWidth: 0,
      maxWidth: 0,
    },
  },

  {
    id: 'action',
    label: 'Action',
    width: 1.5,
    styles: {
      minWidth: 0,
      maxWidth: 0,
    },
    align: 'center',
  },
];

export const sampleRows = [
  {
    video: {
      title: 'Video 1',
      length: '10:00',
      thumbNail:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7nK2ntw9PgaYiVbN6jlOG-AmnODqoYJtw4A&s',
    },
    student: {
      name: 'John Doe',
      id: '1234',
      imageUrl:
        'https://images.pexels.com/photos/17652903/pexels-photo-17652903/free-photo-of-naomi.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
    },
    videoLength: '01:24',
    action: ['View', 'Approve', 'Reject'],
    date: '12/12/2021',
  },
  {
    videoLength: '01:24',
    action: ['View', 'Approve', 'Reject'],
    date: '12/12/2021',
    video: {
      title: 'Video 2',
      length: '10:00',
      thumbNail:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7nK2ntw9PgaYiVbN6jlOG-AmnODqoYJtw4A&s',
    },
    student: {
      name: 'John Doe',
      id: '1234',
      imageUrl:
        'https://images.pexels.com/photos/17652903/pexels-photo-17652903/free-photo-of-naomi.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
    },
  },
  {
    videoLength: '01:24',
    action: ['View', 'Approve', 'Reject'],
    date: '12/12/2021',
    video: {
      title: 'Video 1',
      length: '10:00',
      thumbNail:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7nK2ntw9PgaYiVbN6jlOG-AmnODqoYJtw4A&s',
    },
    student: {
      name: 'John Doe',
      id: '1234',
      imageUrl:
        'https://images.pexels.com/photos/17652903/pexels-photo-17652903/free-photo-of-naomi.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
    },
  },
  {
    videoLength: '01:24',
    action: ['View', 'Approve', 'Reject'],
    date: '12/12/2021',
    video: {
      title: 'Video 1',
      length: '10:00',
      thumbNail:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7nK2ntw9PgaYiVbN6jlOG-AmnODqoYJtw4A&s',
    },
    student: {
      name: 'John Doe',
      id: '1234',
      imageUrl:
        'https://images.pexels.com/photos/17652903/pexels-photo-17652903/free-photo-of-naomi.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
    },
  },
  {
    videoLength: '01:24',
    action: ['View', 'Approve', 'Reject'],
    date: '12/12/2021',
    video: {
      title: 'Video 1',
      length: '10:00',
      thumbNail: 'https://i.ytimg.com/vi/1Ne1hqOXKKI/maxresdefault.jpg',
    },
    student: {
      name: 'John Doe',
      id: '1234',
      imageUrl:
        'https://images.pexels.com/photos/17652903/pexels-photo-17652903/free-photo-of-naomi.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
    },
  },
  {
    videoLength: '01:24',
    action: ['View', 'Approve', 'Reject'],
    date: '12/12/2021',
    video: {
      title: 'Video 1',
      length: '10:00',
      thumbNail: 'https://i.ytimg.com/vi/1Ne1hqOXKKI/maxresdefault.jpg',
    },
    student: {
      name: 'John Doe',
      id: '1234',
      imageUrl:
        'https://images.pexels.com/photos/17652903/pexels-photo-17652903/free-photo-of-naomi.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
    },
  },
  {
    videoLength: '01:24',
    action: ['View', 'Approve', 'Reject'],
    date: '12/12/2021',
    video: {
      title: 'Video 1',
      length: '10:00',
      thumbNail: 'https://i.ytimg.com/vi/1Ne1hqOXKKI/maxresdefault.jpg',
    },
    student: {
      name: 'John Doe',
      id: '1234',
      imageUrl:
        'https://images.pexels.com/photos/17652903/pexels-photo-17652903/free-photo-of-naomi.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
    },
  },
  {
    videoLength: '01:24',
    action: ['View', 'Approve', 'Reject'],
    date: '12/12/2021',
    video: {
      title: 'Video 1',
      length: '10:00',
      thumbNail: 'https://i.ytimg.com/vi/1Ne1hqOXKKI/maxresdefault.jpg',
    },
    student: {
      name: 'John Doe',
      id: '1234',
      imageUrl:
        'https://images.pexels.com/photos/17652903/pexels-photo-17652903/free-photo-of-naomi.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
    },
  },
  {
    videoLength: '01:24',
    action: ['View', 'Approve', 'Reject'],
    date: '12/12/2021',
    video: {
      title: 'Video 1',
      length: '10:00',
      thumbNail: 'https://i.ytimg.com/vi/1Ne1hqOXKKI/maxresdefault.jpg',
    },
    student: {
      name: 'John Doe',
      id: '1234',
      imageUrl:
        'https://images.pexels.com/photos/17652903/pexels-photo-17652903/free-photo-of-naomi.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
    },
  },
  {
    videoLength: '01:24',
    action: ['View', 'Approve', 'Reject'],
    date: '12/12/2021',
    video: {
      title: 'Video 1',
      length: '10:00',
      thumbNail: 'https://i.ytimg.com/vi/1Ne1hqOXKKI/maxresdefault.jpg',
    },
    student: {
      name: 'John Doe',
      id: '1234',
      imageUrl:
        'https://images.pexels.com/photos/17652903/pexels-photo-17652903/free-photo-of-naomi.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
    },
  },
  {
    videoLength: '01:24',
    action: ['View', 'Approve', 'Reject'],
    date: '12/12/2021',
    video: {
      title: 'Video 1',
      length: '10:00',
      thumbNail: 'https://i.ytimg.com/vi/1Ne1hqOXKKI/maxresdefault.jpg',
    },
    student: {
      name: 'John Doe',
      id: '1234',
      imageUrl:
        'https://images.pexels.com/photos/17652903/pexels-photo-17652903/free-photo-of-naomi.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
    },
  },
];
