import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

export interface ResponseData {
  status: String;
  messagecode: String;
  message: String;
  data: any;
}

interface IntialState {
  navItems: any;
  loading: boolean;
}

const intialState: IntialState = {
  navItems: [],
  loading: false,
};

const VideoSlice = createSlice({
  name: 'video_slice',
  initialState: intialState,
  reducers: {
    setNavItems: (state: Draft<IntialState>, action: PayloadAction<[]>) => {
      state.navItems = action.payload;
    },

    setLoading: (state: Draft<IntialState>, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const { setNavItems, setLoading } = VideoSlice.actions;

export default VideoSlice.reducer;
