import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import {
  ViewParent,
  ViewStudent,
  ViewTeacher,
  ViewSchoolLeader,
} from 'components/viewUser';

function ViewUser() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [createType, setCreateType] = useState(queryParams.get('mode'));

  useEffect(() => {
    setCreateType(queryParams.get('mode'));
  }, [location.search]);

  let componentToRender;

  switch (createType) {
    case 'teacher':
      componentToRender = <ViewTeacher />;
      break;
    case 'student':
      componentToRender = <ViewStudent />;
      break;
    case 'parent':
      componentToRender = <ViewParent />;
      break;
    case 'leader':
      componentToRender = <ViewSchoolLeader />;
      break;
    default:
      componentToRender = <div>Invalid user type</div>;
  }

  return <Box sx={{ height: '100%' }}>{componentToRender}</Box>;
}

export default ViewUser;
