import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import NodataToShow from 'icons/NoDataToShow';

//*** Icons
import AddIcon from '@mui/icons-material/Add';

// *** Custom Components
import { useAppDispatch, useAppSelector } from 'store/store';
import AddUpdate from 'components/dialog/school/AddUpdate';
import {
  getAllSchoolOfDistrict,
  getDistrictList,
} from 'store/Thunk/districtThunk';
import { getAllSchoolList } from 'store/Thunk/schoolThunk';
import { useParams } from 'react-router-dom';

function Index() {
  const { id } = useParams();

  const dispatch = useAppDispatch();

  const { schoolList } = useAppSelector((state) => state.schoolReducer);

  const userData = JSON.parse(localStorage.getItem('userData') || '');

  const [isOpen, setIsOpen] = useState(false)

  const handleAddUpdateDistrict = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {schoolList ? (
        <>
          <Card sx={{ p: '24px' }}>
            <Box>
              <Typography className="profile_name_txt">Schools</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Typography
                variant="body1"
                className="profile_name_txt"
                sx={{ fontSize: '24px', fontWeight: '600' }}
              >
                Welcome to the G3MS,
              </Typography>
              <Typography
                variant="body1"
                className="profile_name_txt"
                sx={{ fontSize: '24px', fontWeight: '600' }}
              >
                {`(${userData?.email})`}
              </Typography>
              <Box
                sx={{
                  m: '15px',
                  mt: '5px',
                }}
              >
                <NodataToShow />
                <Typography
                  variant="body1"
                  sx={{ fontSize: '18px', fontWeight: '600' }}
                >
                  No School Found
                </Typography>
              </Box>
              <Box>
                {/* <Typography
                  variant="body1"
                  sx={{ fontSize: '15px', fontWeight: '600', pb: '15px' }}
                >
                  Welcome to the G3MS
                </Typography> */}
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: 'justify',
                    fontSize: '13px',
                    fontWeight: '400',
                    p: '0 30px',
                  }}
                >
                  Please create new School to proceed
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  m: '15px 0 0 0',
                }}
              >
                <Button
                  variant="contained"
                  className="primary_bg_btn"
                  sx={{ ml: 3 }}
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                  endIcon={<AddIcon />}
                >
                  Add School
                </Button>
              </Box>
            </Box>
          </Card>
          
          {isOpen && (
            <AddUpdate
              open={isOpen}
              onClose={handleAddUpdateDistrict}
              id={id}
            />
          )}
        </>
      ) : null}
    </>
  );
}

export default Index;
