import axios from 'axios';
import config from '../config/config';
const API = axios.create({ baseURL: config.BASE_URL });

API.interceptors.request.use((req: any) => {
  if (localStorage.getItem('userData')) {
    const userData: any = localStorage.getItem('userData');
    const token: any = JSON.parse(userData).token;

    if (token) {
      req.headers.Token = token;
    }
    if (token) {
      req.headers.Authorization = `Bearer ${token}`;
    }
  }
  return req;
});

export default API;
