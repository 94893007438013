import SimpleLoader from '@core/components/MDLoader/simpleLoader';
import { Cancel, Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import useSchoolClassroomInfo from 'hooks/useSchoolClassroomInfo';
import WarningIcon from 'icons/warning';
import { useState } from 'react';
import {
  deleteAssignment,
  deleteSchoolAssignment,
  getAllAssignments,
} from 'store/Thunk/assignContentThunk';
import { deleteDistrict } from 'store/Thunk/districtThunk';
import { useAppDispatch } from 'store/store';
interface DialogProps {
  open: boolean;
  onClose: (id: string) => void;
}

export default function QuestionWarning(props: DialogProps) {
  const { onClose, open } = props;
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    onClose('');
  };

  const onDelete = () => {
    onClose('');
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      open={open}
      sx={{ padding: '0px 24px' }}
    >
      <IconButton
        onClick={handleClose}
        color="primary"
        size="large"
        sx={{ position: 'absolute', right: 8, top: 8 }}
      >
        <Close fontSize="inherit" style={{ color: '#000', fontSize: 25 }} />
      </IconButton>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        gap={4}
        py={6}
      >
        <WarningIcon />
        <Typography
          mt={3}
          color={'#000'}
          px={10}
          fontWeight={600}
          fontSize={'24px'}
          textAlign={'center'}
        >
          Oh no..looks like there are no more questions in our library for this
          standard. You can either request questions to be added from our
          Customer Success team or add them yourself via Content Management.{' '}
        </Typography>
        <Stack direction={'row'} spacing={4} mt={4} width={'60%'}>
          <Button
            fullWidth
            variant="contained"
            className="primary_bg_btn"
            onClick={() => onDelete()}
            disabled={loading}
          >
            {loading ? (
              <SimpleLoader size={24} color={'secondary'} />
            ) : (
              'Request to ADD'
            )}
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
}
