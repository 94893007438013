import React from 'react';

export default function DeleteBorderIcon() {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3332 13.4634V21.7967H11.6665V13.4634H18.3332ZM17.0832 8.46338H12.9165L12.0832 9.29671H9.1665V10.9634H20.8332V9.29671H17.9165L17.0832 8.46338ZM19.9998 11.7967H9.99984V21.7967C9.99984 22.7134 10.7498 23.4634 11.6665 23.4634H18.3332C19.2498 23.4634 19.9998 22.7134 19.9998 21.7967V11.7967Z"
        fill="#F41F52"
      />
      <rect
        x="0.5"
        y="1.46338"
        width="29"
        height="29"
        rx="6.5"
        stroke="#F41F52"
      />
    </svg>
  );
}
