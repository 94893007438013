// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-dates__header {
  display: none !important;
}

/* Or hide specific parts of the header */
.react-dates__navigation--previous {
  display: none !important;
}

.react-dates__navigation--next {
  display: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/v2_components/Admin_Panel_Dashboards/skills_chart/styles/style.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA,yCAAyC;AACzC;EACE,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[".react-dates__header {\n  display: none !important;\n}\n\n/* Or hide specific parts of the header */\n.react-dates__navigation--previous {\n  display: none !important;\n}\n\n.react-dates__navigation--next {\n  display: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
