import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Breadcrumbs,
  Link,
  Typography,
  Card,
  Button,
  Avatar,
  Divider,
  Modal,
} from '@mui/material';
import closeButton from './closeButton.svg';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AddUpdate from 'components/dialog/classroom/AddUpdate';

import WarningIcon from './warning.svg';
import './style.css';
import ThreeDotVertical from 'icons/threeDotVerticalIcon';
import ViewDetails from 'components/ViewPages/viewDetails';
import Assigment from './assignment';
import ImportedAssigment from './imported_assessments';
import StudentGroups from './assignment/studentGroup';
import Students from './student';
import Parents from './parent';
import TeacherList from './teacherList';
import { getClassroomByID } from 'store/Thunk/classroomThunk';
import { RootState, useAppDispatch, useAppSelector } from 'store/store';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import useSchoolClassroomInfo from 'hooks/useSchoolClassroomInfo';
import RemoveUserFromClassDialog from 'components/dialog/users/Delete/removeUser';
import SharedButton from 'components/button';
import Dropdown from './Dropdown';
import TableHeader from 'components/tableHeader';
import SimpleDialog from 'components/filter/assignments';
import AssignContent from 'components/student/assign';
import { route_info } from 'navigation/route';
import { getQuarterList } from 'store/Thunk/videoThunk';
import { TimerStarOutline } from 'mdi-material-ui';

import {
  cancelSchoolAssignment,
  getSchoolAssignments,
  initSchoolAssignment,
  pauseSchoolAssignment,
  startSchoolAssignment,
} from 'store/Thunk/schoolAssignmentThunk';
import {
  SchoolAssignment,
  setCurrentSchoolAssignment,
} from 'store/Slice/schoolAssignmentSlice';
import { getStudentListByClassroom } from 'store/Thunk/studentThunk';
import { Roles } from 'constants/consts';
import { getUser } from 'helper/functions';

function handleBreadCrumbClick(
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const tabValues = [
  { title: 'Imported Assessments', value: 'imported_assessments' },
  { title: 'Assignments', value: 'content' },
  { title: 'Students', value: 'student' },
  { title: 'Groups', value: 'group' },
  { title: 'Parents', value: 'parent' },
  { title: 'Teachers', value: 'teacher' },
];

function Index() {
  const navigate: any = useNavigate();
  const dispatch: any = useAppDispatch();
  const teacherSchool: any = localStorage.getItem('teacherSchool');

  const [open, setOpen] = React.useState(false);
  const [cancelModal, setCancelModal] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const section = searchParams.get('section');
  const urlParams = new URLSearchParams(window.location.search);
  const topicId = urlParams.get('topicId');
  const grade = urlParams.get('grade');
  const gradeId = urlParams.get('gradeId');
  const topic = urlParams.get('topic');
  const schoolId = urlParams.get('schoolId');
  const classroomId = urlParams.get('classroomId');
  const current_school_id = urlParams.get('schoolId') || '';
  const ClassroomData = useAppSelector(
    (state) => state.classroomReducer.classroomID,
  );
  console.log('ClassroomData', ClassroomData);
  console.log('ClassroomData', classroomId);
  console.log('ClassroomData', schoolId);
  useEffect(() => {
    if (classroomId && schoolId) {
      localStorage.setItem('current_classroomId', classroomId);
      localStorage.setItem('current_schoolId', schoolId);
    }
  }, [classroomId, schoolId]);
  const [isOpenAdd, setIsOpenAdd] = React.useState<boolean>(false);
  const [isOpenFiler, setIsOpenFilter] = React.useState<boolean>(false);
  const [isOpenAssign, setIsOpenAssign] = React.useState<boolean>(false);
  const [isOpenAddClass, setIsOpenAddClass] = React.useState<boolean>(false);
  const [tabValue, setTabValue] = React.useState<string>(
    (section as string) ?? 'content',
  );
  const params = useParams();
  const schoolDetails = [
    { title: 'School ID', content: ClassroomData?.school?.ncesSchoolId },
    {
      title: 'Class Type',
      content: ClassroomData?.classroom?.topic?.name || '-',
    },
    {
      title: 'Class Grade',
      content: ClassroomData?.classroom?.grade?.name || '-',
    },
    { title: 'Class Period', content: ClassroomData?.classroom?.period || '-' },
  ];

  useEffect(() => {
    if (schoolId && classroomId) {
      dispatch(
        getClassroomByID({
          schoolId: schoolId,
          id: classroomId,
        }),
      );
    }
    window.scrollTo(0, 0);
  }, [schoolId, classroomId]);
  const { type } = getUser();
  const breadcrumbs =
    type === Roles.ADMIN
      ? [
          <Link
            underline="hover"
            key="1"
            color="inherit"
            href={route_info?.school_management?.districtCrud}
            onClick={() => handleBreadCrumbClick}
          >
            School Management
          </Link>,
          <Link
            underline="hover"
            key="1"
            color="inherit"
            href={route_info?.school_management.school_manage.replace(
              ':id',
              current_school_id,
            )}
            onClick={() => handleBreadCrumbClick}
          >
            All Grades
          </Link>,
          <Link
            underline="hover"
            key="1"
            color="inherit"
            href={route_info?.school_management.school_manage.replace(
              ':id',
              current_school_id,
            )}
            onClick={() => handleBreadCrumbClick}
          >
            {grade}
          </Link>,
          <Link
            underline="hover"
            key="1"
            color="inherit"
            href={route_info?.school_management.school_manage.replace(
              ':id',
              current_school_id,
            )}
            onClick={() => handleBreadCrumbClick}
          >
            {ClassroomData?.classroom?.topic?.name}
          </Link>,
          <Link
            underline="hover"
            key="1"
            color="inherit"
            href={`${route_info?.school_management.school_manage.replace(
              ':id',
              current_school_id,
            )}/${gradeId}/${topicId}`}
            onClick={() => handleBreadCrumbClick}
          >
            All classrooms
          </Link>,
          <Typography key="2" color="#C73E9D" onClick={() => navigate(-1)}>
            {ClassroomData?.classroom?.name}
          </Typography>,
        ]
      : [
          <Link
            underline="hover"
            key="1"
            color="inherit"
            href={route_info?.school_management.school_manage.replace(
              ':id',
              current_school_id,
            )}
            onClick={() => handleBreadCrumbClick}
          >
            All Grades
          </Link>,
          <Link
            underline="hover"
            key="1"
            color="inherit"
            sx={{ cursor: 'default' }}
            onClick={() => handleBreadCrumbClick}
          >
            {grade}
          </Link>,
          <Link
            underline="hover"
            key="1"
            color="inherit"
            sx={{ cursor: 'default' }}
            onClick={() => handleBreadCrumbClick}
          >
            {ClassroomData?.classroom?.topic?.name}
          </Link>,
          <Link
            underline="hover"
            key="1"
            color="inherit"
            href={`${route_info?.school_management.school_manage.replace(
              ':id',
              current_school_id,
            )}/${gradeId}/${topicId}`}
            onClick={() => handleBreadCrumbClick}
          >
            All classrooms
          </Link>,
          <Typography key="2" color="#C73E9D" onClick={() => navigate(-1)}>
            {ClassroomData?.classroom?.name}
          </Typography>,
        ];

  const handleClose = () => {
    setIsOpenAdd(false);
    setIsOpenAddClass(false);
    setIsOpenFilter(false);
    setIsOpenAssign(false);
  };
  const handleCloseModal = (e: any, r: any) => {
    if (r && r === "backdropClick")
      return;
    setOpen(false);
  };
  const handleCancelModal = (value: boolean) => {
    if (!value) setCancelModal(false);
    setCancelModal(value);
  };
  useEffect(() => {
    dispatch(getStudentListByClassroom({ id: classroomId }));
  }, []);
  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        style={{ marginBottom: 20 }}
      >
        {breadcrumbs}
      </Breadcrumbs>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEscapeKeyDown={true}
      >
        <CorrectModal
          modalType="pausedTimerModal"
          handleCancelModal={handleCancelModal}
          handleCloseModal={handleCloseModal}
        />
      </Modal>
      <Modal
        open={cancelModal}
        onClose={handleCancelModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CancelWarningModal
          handleTimerModal={setOpen}
          handleCancelModal={handleCancelModal}
        />
      </Modal>
      <AssignContent open={isOpenAssign} onClose={handleClose} />
      <SimpleDialog open={isOpenFiler} onClose={handleClose} />
      <Card className="datatable_card" sx={{ p: '24px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="body1"
              sx={{
                color: '#20A58A',
                fontSize: '22.98px',
                fontWeight: '700',
                mr: 2,
                textTransform: 'capitalize',
              }}
            >
              {ClassroomData?.classroom?.name || ''}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: '#929292',
                fontSize: '12px',
                fontFamily: 'Inter',
                fontWeight: '400',
                wordWrap: 'break-word',
                mt: 2,
              }}
            >
              {`(ID: ${classroomId})`}
            </Typography>
          </Box>
          <Box>
            <ThreeDotVertical />
          </Box>
        </Box>

        <Box>
          <ViewDetails details={schoolDetails} gridSize={3} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            m: '15px 0 0 0',
          }}
        >
          <SharedButton
            onClick={() => setOpen(true)}
            variant="outlined"
            name="Start Pin Code Assessment"
            className="green_bg_btn_bold"
          />
        </Box>
        {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            className="primary_bg_btn"
            sx={{ ml: 3 }}
            onClick={() => {
              setIsOpenAdd(!isOpenAdd);
            }}
            endIcon={<AddIcon />}
          >
            {'Add classroom'}
          </Button>
        </Box> */}
      </Card>
      {/* list of assegments */}
      <Card sx={{ mt: '24px' }}>
        <Box sx={{ m: '24px', mt: 0 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            {tabValues?.map((item: any) => {
              return (
                <>
                  <Box
                    sx={
                      tabValue === item?.value
                        ? {
                            borderBottom: '4px #C73E9D solid',
                            color: '#000',
                          }
                        : null
                    }
                  >
                    <Button
                      onClick={() => {
                        setTabValue(item?.value);
                      }}
                      sx={{
                        p: '20px 30px',
                        borderRadius: '0px',
                        color: tabValue === item?.value ? '#484848' : '#84818A',
                        fontSize: '14px',
                        fontWeight: tabValue === item?.value ? '600' : '500',
                        lineHeight: '22px',
                        '&:hover': {
                          backgroundColor: 'none',
                        },
                      }}
                    >
                      {item?.title}
                    </Button>
                  </Box>
                </>
              );
            })}
          </Box>
          <Divider sx={{ mt: 0 }} />
        </Box>

        {tabValue === 'imported_assessments' && <ImportedAssigment />}
        {tabValue === 'content' && <Assigment />}
        {tabValue === 'student' && <Students />}
        {tabValue === 'teacher' && <TeacherList />}
        {tabValue === 'group' && <StudentGroups />}
        {tabValue === 'parent' && <Parents />}
      </Card>
      {isOpenAdd && <AddUpdate open={isOpenAdd} onClose={handleClose} />}
    </>
  );
}

export default Index;

const CorrectModal = ({
  modalType,
  handleCancelModal,
  handleCloseModal,
}: {
  modalType: string;
  handleCancelModal: any;
  handleCloseModal: any;
}) => {
  const [currentModal, setCurrentModal] = React.useState('selectAssesment');

  if (currentModal === 'selectAssesment')
    return (
      <SelectAssesment
        setCurrentModal={setCurrentModal}
        handleCloseModal={handleCloseModal}
      />
    );

  // if (currentModal === 'timerModal') return <>{TimerModal()}</>;
  if (currentModal === 'timerModal')
    return (
      <TimerModal
        handleCancelModal={handleCancelModal}
        handleCloseModal={handleCloseModal}
      />
    );
  if (currentModal === 'resumeTimerModal') return <>{ResumeTimerModal()}</>;
  if (currentModal === 'oneMinuteWarningTimerModal')
    return <>{OneMinuteWarningTimerModal()}</>;
  if (currentModal === 'timeOutModal') return <>{TimeOutModal()}</>;
  return null;
};

const SelectAssesment = ({
  setCurrentModal,
  handleCloseModal,
}: {
  setCurrentModal: any;
  handleCloseModal: any;
}) => {
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    height: '227px',
    borderRadius: '8px',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const dispatch = useAppDispatch();
  const urlParams = new URLSearchParams(window.location.search);

  const classroomId = urlParams.get('classroomId');

  const { schoolAssignments } = useAppSelector(
    (state: RootState) => state.schoolAssignmentSlice,
  );

  useEffect(() => {
    if (classroomId) {
      dispatch(getSchoolAssignments(classroomId));
    }
  }, [classroomId, dispatch]);

  function formattedDate(date: Date) {
    // Format date as 'MM/DD/YYYY'
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }

  console.log('=========GET SCHOOL ASSIGNMENTS===========');
  console.log(schoolAssignments);

  const options = schoolAssignments.map((assignment: SchoolAssignment) => {
    return `${assignment.type} - ${formattedDate(
      new Date(assignment.startDate ?? ''),
    )} - ${formattedDate(new Date(assignment.dueDate ?? ''))}`;
  });

  const [selectedOption, setSelectedOption] = useState<SchoolAssignment | null>(
    null,
  );

  const handleSelect = (index: number) => {
    setSelectedOption(schoolAssignments[index]);
    dispatch(setCurrentSchoolAssignment(schoolAssignments[index]));
  };

  return (
    <Box sx={style}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div
          id="modal-modal-title"
          style={{
            fontWeight: '500',
            fontSize: '16px',
            color: '#131515',
            lineHeight: '19.15px',
          }}
        >
          Start Pin Code Assessment
        </div>
        <div>
          <img
            src={closeButton}
            alt=""
            style={{ height: '20px', width: '20px' }}
            onClick={handleCloseModal}
          />
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          marginTop: '24px',
        }}
      >
        <div
          style={{
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '14.52px',
            color: '#484848',
          }}
        >
          Select Assessment To Begin
        </div>
        <Dropdown options={options} onSelect={handleSelect} />
      </div>
      <div style={{ display: 'flex', gap: '30px', marginTop: '20px' }}>
        <div>
          <button
            style={{
              width: '219px',
              height: '44px',
              borderRadius: '12px',
              border: '2px solid #C73E9D',
              color: '#C73E9D',
              fontWeight: '700',
              fontSize: '14px',
              lineHeight: '22px',
            }}
            onClick={handleCloseModal}
          >
            Cancel
          </button>
        </div>
        <div>
          <button
            onClick={() => {
              if (selectedOption) {
                dispatch(initSchoolAssignment(selectedOption.id))
                  .unwrap()
                  .then((response) => {
                    setCurrentModal('timerModal');
                  })
                  .catch((err) => {
                    console.log("Can't initiate school assignment");
                  });
              }
            }}
            style={{
              width: '219px',
              height: '44px',
              borderRadius: '12px',
              backgroundColor: ' #C73E9D',
              border: '2px solid #C73E9D',
              color: '#FFFFFF',
              fontWeight: '700',
              fontSize: '14px',
              lineHeight: '22px',
            }}
          >
            Next
          </button>
        </div>
      </div>
    </Box>
  );
};

const TimerModal = ({
  handleCancelModal,
  handleCloseModal,
}: {
  handleCancelModal: any;
  handleCloseModal: any;
}) => {
  const dispatch = useAppDispatch();
  const { currentSchoolAssignmentInitiated } = useAppSelector(
    (state: RootState) => state.schoolAssignmentSlice,
  );

  const timeDuration = currentSchoolAssignmentInitiated?.timeDuration || 0;
  const initialMins = Math.floor(timeDuration);
  const initialSecs = 0;

  const [nextButtonText, setNextButtonText] =
    useState<string>('Start Assignment');
  const [paused, setPaused] = useState<any>(false); // Store the interval in state
  const [started, setStarted] = useState<any>(false); // Store the interval in state
  const [intervals, setIntervals] = useState<any>([]); // Store the interval in state
  const [timeTracker, setTotalSeconds] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0); // Initial minutes
  const [seconds, setSeconds] = useState<number>(0);
  const [timerColor, setTimerColor] = useState<string>('#20A58A'); // Initial seconds

  useEffect(() => {
    setTotalSeconds(timeDuration * 60);
    setMinutes(initialMins);
    setSeconds(initialSecs);
  }, [timeDuration, initialMins, initialSecs]);

  const onNextHandler = async () => {
    if (currentSchoolAssignmentInitiated) {
      if (nextButtonText === 'Start Assignment') {
        setStarted(true);
        setNextButtonText('Pause Assignment');
        dispatch(startSchoolAssignment(currentSchoolAssignmentInitiated.id));
      } else if (nextButtonText === 'Pause Assignment') {
        setPaused(true);
        setNextButtonText('Resume Assignment');
        dispatch(pauseSchoolAssignment(currentSchoolAssignmentInitiated.id));
      } else if (nextButtonText === 'Resume Assignment') {
        setPaused(false);
        setNextButtonText('Pause Assignment');
        dispatch(startSchoolAssignment(currentSchoolAssignmentInitiated.id));
      }
    }
  };

  const onCancelHandler = () => {
    handleCancelModal(true);
  };

  useEffect(() => {
    if (paused) {
      intervals.forEach((interval: any) => {
        clearInterval(interval);
      });
      return;
    }
    if (started) {
      const timerInt = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
          setTotalSeconds(timeTracker - 1);
        } else {
          if (paused) {
            intervals.forEach((interval: any) => {
              clearInterval(interval);
            });
            return;
          }
          setTotalSeconds(timeTracker - 1);
          if (timeTracker <= 0) {
            clearInterval(timerInt);
            // Timer reached 0:00, you can handle this event here
          } else if (minutes === 1) {
            setTimerColor('#DF3023');
            setMinutes(0);
            setSeconds(59);
          } else {
            if (minutes >= 1) {
              setMinutes(minutes - 1);
            }
            setSeconds(59);
          }
        }
      }, 1000);
      setIntervals([...intervals, timerInt]);
    }

    return () => {
      intervals.forEach((interval: any) => {
        clearInterval(interval);
      });
    };
    // ... existing code ...
  }, [minutes, seconds, paused, started, timeTracker]);

  // Calculate total seconds
  const totalSeconds = minutes * 60 + seconds;

  // Calculate the angle of the circle's border based on time remaining
  // const borderAngle = ((totalSeconds / 300) * 360).toFixed(2); // Total time is 300 seconds, so the circle is 360 degrees
  // Calculate the angle of the circle's border based on time remaining
  const borderAngle = parseFloat(((totalSeconds / 300) * 360).toFixed(2)); // Parse the result as a float
  const currentTotalSeconds = minutes * 60 + seconds;
  const timePercentage = (currentTotalSeconds / (timeDuration * 60)) * 100;
  // Format minutes and seconds
  const formattedMinutes: string = String(minutes).padStart(2, '0');
  const formattedSeconds: string = String(seconds).padStart(2, '0');

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: '#FFFFFF',
    border: '2px solid #FFFFFF',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    // justifyContent: 'space-between',
  };
  return (
    <Box sx={style}>
      <div
        style={{
          width: '100%',
          height: '70px',
        }}
      >
        <div
          style={{
            fontSize: '28px',
            fontWeight: '700',
            lineHeight: '38px',
            letterSpacing: '0em',
            textAlign: 'left',
            color: '#131515',
            borderBottom: '1.3px solid #C4C4C4',
            padding: '15px 15px',
          }}
        >
          Timer
        </div>
      </div>
      <div
        className="question-section"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '26px',
          marginBottom: '36px',
        }}
      >
        <div
          className="question-title"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '26px',
          }}
        >
          <div
            className="label"
            style={{
              fontSize: '28px',
              fontWeight: '500',
              lineHeight: '40px',
              letterSpacing: '0em',
              textAlign: 'left',
              color: '#84818A',
              marginBottom: '6px',
            }}
          >
            Pin Code
          </div>
          <div
            className="question-title-content"
            style={{
              fontSize: '63px',
              fontWeight: '700',
              lineHeight: '80px',
              letterSpacing: '0em',
              textAlign: 'left',
              marginBottom: '15px',
            }}
          >
            <div
              style={{
                color: '#131515',
              }}
            >
              {currentSchoolAssignmentInitiated?.pinCode}
            </div>
          </div>
        </div>

        <div
          style={{
            position: 'relative',
            width: '280px',
            height: '280px',
            borderRadius: '50%',
            overflow: 'hidden',
            backgroundColor: '#F9F9F9',
            // Ensure the gradient doesn't overflow the container
          }}
        >
          {minutes === 0 && seconds === 0 ? (
            <div
              className="question-timer"
              style={{
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '46px',
              }}
            >
              <div
                style={{
                  fontWeight: '700',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  lineHeight: '80px',
                  fontSize: '60px',
                  color: '#131515',
                }}
              >
                <div>Time’s</div> <div> Up!</div>
              </div>
            </div>
          ) : (
            <div
              style={{
                position: 'absolute',
                width: '280px',
                height: '280px',
                borderRadius: '50%',
                backgroundImage: `conic-gradient(${
                  timePercentage >= 100
                    ? `${timerColor} 100%, transparent 100%`
                    : `${timerColor} ${timePercentage}%, transparent ${timePercentage}%`
                })`,
                transition: 'background-image  1s linear',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                className="question-timer"
                style={{
                  position: 'absolute',

                  width: '88%',
                  height: '88%',
                  borderRadius: '50%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'white', // Set background color to transparent to allow gradient to show
                }}
              >
                <div
                  style={{
                    fontWeight: '700',
                    lineHeight: '80px',
                    fontSize: '60px',
                    color: '#131515',
                  }}
                >
                  {formattedMinutes}:{formattedSeconds}
                </div>
                <div
                  style={{
                    color: '#84818A',
                    fontSize: '28px',
                    fontWeight: '500',
                    lineHeight: '40px',
                  }}
                >
                  Minutes
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {minutes === 1 && started && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '12px',
            marginBottom: '18px',
          }}
        >
          <div
            onClick={onCancelHandler}
            style={{
              border: '3px solid #C73E9D',
              borderRadius: '20px',
              height: '70px',
              width: '90%',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                fontWeight: '700',
                fontSize: '24px',
                lineHeight: '32px',
                color: '#C73E9D',
              }}
            >
              Cancel
            </div>
          </div>
        </div>
      )}
      {((started && minutes !== 1 && minutes !== 0) || !started) && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '12px',
            marginBottom: '18px',
          }}
        >
          <div
            className="pointer"
            onClick={onCancelHandler}
            style={{
              border: '3px solid #C73E9D',

              borderRadius: '20px',
              height: '70px',
              width: '48%',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                fontWeight: '700',
                fontSize: '24px',
                lineHeight: '32px',
                color: '#C73E9D',
              }}
            >
              Cancel
            </div>
          </div>
          <div
            className="pointer"
            onClick={onNextHandler}
            style={{
              background: '#C73E9D',
              borderRadius: '20px',
              height: '70px',
              width: '48%',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                fontWeight: '700',
                fontSize: '24px',
                lineHeight: '32px',
                color: '#FFFFFF',
              }}
            >
              {nextButtonText}
            </div>
          </div>
        </div>
      )}
      {minutes === 0 && seconds === 0 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '12px',
            marginBottom: '18px',
          }}
        >
          <div
            onClick={() => handleCloseModal(false)}
            style={{
              border: '3px solid #C73E9D',
              backgroundColor: '#C73E9D',
              borderRadius: '20px',
              height: '70px',
              width: '90%',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                fontWeight: '700',
                fontSize: '24px',
                lineHeight: '32px',
                color: '#FFFFFF',
              }}
            >
              OK
            </div>
          </div>
        </div>
      )}
    </Box>
  );
};

const ResumeTimerModal = () => {
  const [minutes, setMinutes] = useState<number>(5); // Initial minutes
  const [seconds, setSeconds] = useState<number>(0); // Initial seconds

  useEffect(() => {
    const timerInterval = setInterval(() => {
      // Decrease seconds by 1 every second
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        if (minutes === 0) {
          clearInterval(timerInterval);
          // Timer reached 0:00, you can handle this event here
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(timerInterval);
  }, [minutes, seconds]);

  // Calculate total seconds
  const totalSeconds = minutes * 60 + seconds;

  // Calculate the angle of the circle's border based on time remaining
  // const borderAngle = ((totalSeconds / 300) * 360).toFixed(2); // Total time is 300 seconds, so the circle is 360 degrees
  // Calculate the angle of the circle's border based on time remaining
  const borderAngle = parseFloat(((totalSeconds / 300) * 360).toFixed(2)); // Parse the result as a float

  // Format minutes and seconds
  const formattedMinutes: string = String(minutes).padStart(2, '0');
  const formattedSeconds: string = String(seconds).padStart(2, '0');

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: '90%',
    bgcolor: '#FFFFFF',
    border: '2px solid #FFFFFF',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-between',
  };
  return (
    <Box sx={style}>
      <div
        style={{
          width: '100%',
          height: '70px',
        }}
      >
        <div
          style={{
            fontSize: '28px',
            fontWeight: '700',
            lineHeight: '38px',
            letterSpacing: '0em',
            textAlign: 'left',
            color: '#131515',
            borderBottom: '1.3px solid #C4C4C4',
            padding: '15px 15px',
          }}
        >
          Timer
        </div>
      </div>
      <div
        className="question-section"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '26px',
          marginBottom: '36px',
        }}
      >
        <div
          className="question-title"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '26px',
          }}
        >
          <div
            className="label"
            style={{
              fontSize: '28px',
              fontWeight: '500',
              lineHeight: '40px',
              letterSpacing: '0em',
              textAlign: 'left',
              color: '#84818A',
              marginBottom: '6px',
            }}
          >
            Pin Code
          </div>
          <div
            className="question-title-content"
            style={{
              fontSize: '63px',
              fontWeight: '700',
              lineHeight: '80px',
              letterSpacing: '0em',
              textAlign: 'left',
              marginBottom: '15px',
            }}
          >
            <div
              style={{
                color: '#131515',
              }}
            >
              ELA2084
            </div>
          </div>
        </div>

        <div
          style={{
            position: 'relative',
            width: '250px',
            height: '250px',
            borderRadius: '50%',
            overflow: 'hidden', // Ensure the gradient doesn't overflow the container
          }}
        >
          <div
            className="question-timer"
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'transparent', // Set background color to transparent to allow gradient to show
            }}
          >
            <div
              style={{
                fontWeight: '700',
                lineHeight: '80px',
                fontSize: '60px',
                color: '#131515',
              }}
            >
              {formattedMinutes}:{formattedSeconds}
            </div>
            <div
              style={{
                color: '#84818A',
                fontSize: '28px',
                fontWeight: '500',
                lineHeight: '40px',
              }}
            >
              Minutes
            </div>
          </div>
          <div
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              boxShadow: `inset 0 0 0 17px #DF3023`, // Border effect
              // borderImage: `conic-gradient(#20A58A ${90 + borderAngle}deg, transparent ${90 + borderAngle}deg, transparent 450deg) 1`,

              clipPath: `polygon(50% 0%, 50% 100%, ${
                100 - borderAngle
              }% 100%, ${100 - borderAngle}% 0%)`, // Clip path based on border angle
            }}
          ></div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '12px',
          marginBottom: '18px',
        }}
      >
        <div
          style={{
            border: '3px solid #C73E9D',
            borderRadius: '20px',
            height: '70px',
            width: '48%',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              fontWeight: '700',
              fontSize: '24px',
              lineHeight: '32px',
              color: '#C73E9D',
            }}
          >
            Cancel
          </div>
        </div>
        <div
          style={{
            background: '#C73E9D',
            borderRadius: '20px',
            height: '70px',
            width: '48%',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              fontWeight: '700',
              fontSize: '24px',
              lineHeight: '32px',
              color: '#FFFFFF',
            }}
          >
            Resume Assignment
          </div>
        </div>
      </div>
    </Box>
  );
};

const OneMinuteWarningTimerModal = () => {
  const [minutes, setMinutes] = useState<number>(5); // Initial minutes
  const [seconds, setSeconds] = useState<number>(0); // Initial seconds

  useEffect(() => {
    const timerInterval = setInterval(() => {
      // Decrease seconds by 1 every second
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        if (minutes === 0 && seconds === 0) {
          clearInterval(timerInterval);
          // Timer reached 0:00, you can handle this event here
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(timerInterval);
  }, [minutes, seconds]);

  // Calculate total seconds
  const totalSeconds = minutes * 60 + seconds;

  // Calculate the angle of the circle's border based on time remaining
  // const borderAngle = ((totalSeconds / 300) * 360).toFixed(2); // Total time is 300 seconds, so the circle is 360 degrees
  // Calculate the angle of the circle's border based on time remaining
  const borderAngle = parseFloat(((totalSeconds / 300) * 360).toFixed(2)); // Parse the result as a float

  // Format minutes and seconds
  const formattedMinutes: string = String(minutes).padStart(2, '0');
  const formattedSeconds: string = String(seconds).padStart(2, '0');

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: '90%',
    bgcolor: '#FFFFFF',
    border: '2px solid #FFFFFF',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-between',
  };
  return (
    <Box sx={style}>
      <div
        style={{
          width: '100%',
          height: '70px',
        }}
      >
        <div
          style={{
            fontSize: '28px',
            fontWeight: '700',
            lineHeight: '38px',
            letterSpacing: '0em',
            textAlign: 'left',
            color: '#131515',
            borderBottom: '1.3px solid #C4C4C4',
            padding: '15px 15px',
          }}
        >
          Timer
        </div>
      </div>
      <div
        className="question-section"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '26px',
          marginBottom: '36px',
        }}
      >
        <div
          className="question-title"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '26px',
          }}
        >
          <div
            className="label"
            style={{
              fontSize: '28px',
              fontWeight: '500',
              lineHeight: '40px',
              letterSpacing: '0em',
              textAlign: 'left',
              color: '#84818A',
              marginBottom: '6px',
            }}
          >
            Pin Code
          </div>
          <div
            className="question-title-content"
            style={{
              fontSize: '63px',
              fontWeight: '700',
              lineHeight: '80px',
              letterSpacing: '0em',
              textAlign: 'left',
              marginBottom: '15px',
            }}
          >
            <div
              style={{
                color: '#131515',
              }}
            >
              ELA2084
            </div>
          </div>
        </div>

        <div
          style={{
            position: 'relative',
            width: '250px',
            height: '20px',
            borderRadius: '50%',
            overflow: 'hidden', // Ensure the gradient doesn't overflow the container
          }}
        >
          <div
            className="question-timer"
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'transparent', // Set background color to transparent to allow gradient to show
            }}
          >
            <div
              style={{
                fontWeight: '700',
                lineHeight: '80px',
                fontSize: '60px',
                color: '#131515',
              }}
            >
              {formattedMinutes}:{formattedSeconds}
            </div>
            <div
              style={{
                color: '#84818A',
                fontSize: '28px',
                fontWeight: '500',
                lineHeight: '40px',
              }}
            >
              Minutes
            </div>
          </div>
          <div
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              boxShadow: `inset 0 0 0 17px #DF3023`, // Border effect
              // borderImage: `conic-gradient(#20A58A ${90 + borderAngle}deg, transparent ${90 + borderAngle}deg, transparent 450deg) 1`,

              clipPath: `polygon(50% 0%, 50% 100%, ${
                100 - borderAngle
              }% 100%, ${100 - borderAngle}% 0%)`, // Clip path based on border angle
            }}
          ></div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '12px',
          marginBottom: '18px',
        }}
      >
        <div
          style={{
            border: '3px solid #C73E9D',
            borderRadius: '20px',
            height: '70px',
            width: '90%',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              fontWeight: '700',
              fontSize: '24px',
              lineHeight: '32px',
              color: '#C73E9D',
            }}
          >
            Cancel
          </div>
        </div>
      </div>
    </Box>
  );
};

const TimeOutModal = () => {
  const [minutes, setMinutes] = useState<number>(5); // Initial minutes
  const [seconds, setSeconds] = useState<number>(0); // Initial seconds

  useEffect(() => {
    const timerInterval = setInterval(() => {
      // Decrease seconds by 1 every second
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        if (minutes === 0) {
          clearInterval(timerInterval);
          // Timer reached 0:00, you can handle this event here
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(timerInterval);
  }, [minutes, seconds]);

  // Calculate total seconds
  const totalSeconds = minutes * 60 + seconds;

  // Calculate the angle of the circle's border based on time remaining
  // const borderAngle = ((totalSeconds / 300) * 360).toFixed(2); // Total time is 300 seconds, so the circle is 360 degrees
  // Calculate the angle of the circle's border based on time remaining
  const borderAngle = parseFloat(((totalSeconds / 300) * 360).toFixed(2)); // Parse the result as a float

  // Format minutes and seconds
  const formattedMinutes: string = String(minutes).padStart(2, '0');
  const formattedSeconds: string = String(seconds).padStart(2, '0');

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: '90%',
    bgcolor: '#FFFFFF',
    border: '2px solid #FFFFFF',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-between',
  };
  return (
    <Box sx={style}>
      <div
        style={{
          width: '100%',
          height: '70px',
        }}
      >
        <div
          style={{
            fontSize: '28px',
            fontWeight: '700',
            lineHeight: '38px',
            letterSpacing: '0em',
            textAlign: 'left',
            color: '#131515',
            borderBottom: '1.3px solid #C4C4C4',
            padding: '15px 15px',
          }}
        >
          Timer
        </div>
      </div>
      <div
        className="question-section"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '26px',
          marginBottom: '36px',
        }}
      >
        <div
          className="question-title"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '26px',
          }}
        >
          <div
            className="label"
            style={{
              fontSize: '28px',
              fontWeight: '500',
              lineHeight: '40px',
              letterSpacing: '0em',
              textAlign: 'left',
              color: '#84818A',
              marginBottom: '6px',
            }}
          >
            Pin Code
          </div>
          <div
            className="question-title-content"
            style={{
              fontSize: '63px',
              fontWeight: '700',
              lineHeight: '80px',
              letterSpacing: '0em',
              textAlign: 'left',
              marginBottom: '46px',
            }}
          >
            <div
              style={{
                color: '#131515',
              }}
            >
              ELA2084
            </div>
          </div>
        </div>

        <div
          className="question-timer"
          style={{
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '46px',
          }}
        >
          <div
            style={{
              fontWeight: '700',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              lineHeight: '80px',
              fontSize: '60px',
              color: '#131515',
            }}
          >
            <div>Time’s</div> <div> Up!</div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '22px',
          marginBottom: '4px',
        }}
      >
        <div
          style={{
            border: '3px solid #C73E9D',
            backgroundColor: '#C73E9D',
            borderRadius: '20px',
            height: '70px',
            width: '90%',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              fontWeight: '700',
              fontSize: '24px',
              lineHeight: '32px',
              color: '#FFFFFF',
            }}
          >
            OK
          </div>
        </div>
      </div>
    </Box>
  );
};

const CancelWarningModal = ({
  handleCancelModal,
  handleTimerModal,
}: {
  handleCancelModal: any;
  handleTimerModal: any;
}) => {
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '480px',
    height: '406px',
    borderRadius: '8px',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const dispatch = useAppDispatch();
  const { currentSchoolAssignmentInitiated } = useAppSelector(
    (state: RootState) => state.schoolAssignmentSlice,
  );

  return (
    <Box sx={style}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          height: '80%',
          marginTop: '40px',
          marginBottom: '40px',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <img
            src={WarningIcon}
            style={{
              height: '70px',
              width: '60.2px',
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <div
            style={{
              fontWeight: '400',
              fontSize: '24px',
              lineHeight: '34px',
              color: '#131515',
            }}
          >
            Are you sure you want to cancel
            <br /> this assessment?
          </div>
          <div
            style={{
              fontWeight: '600',
              fontSize: '16px',
              lineHeight: '24px',
              color: '#979C9E',
            }}
          >
            All progress will be erased. This action <br />
            cannot be undone.
          </div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '10px',
          }}
        >
          <div>
            <div
              className="pointer"
              onClick={() => handleCancelModal(false)}
              style={{
                border: '2px solid #C73E9D',
                color: '#C73E9D',
                width: '180px',
                height: '44px',
                borderRadius: '12px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              No
            </div>
          </div>
          <div>
            <div
              className="pointer"
              onClick={() => {
                if (currentSchoolAssignmentInitiated) {
                  dispatch(
                    cancelSchoolAssignment(currentSchoolAssignmentInitiated.id),
                  ).unwrap()
                  .then((response) => {
                    handleCancelModal(false);
                    handleTimerModal(false);
                  })
                  .catch((err) => {
                    console.log("Can't cancel school assignment");
                  });
                }
              }}
              style={{
                border: '2px solid #C73E9D',
                backgroundColor: '#C73E9D',
                color: '#FFFFFF',
                width: '180px',
                height: '44px',
                borderRadius: '12px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              Yes
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};
