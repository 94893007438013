import { createAsyncThunk } from '@reduxjs/toolkit';
import API from 'store/api';
import { startLoading, stopLoading } from 'store/Slice/loadingSlice';
import { notificationFail } from 'store/Slice/notificationSlice';
import { setQuestionList } from 'store/Slice/QuestionSlice';
import { setUserSessionList } from 'store/Slice/userSessionSlice';
import { setScreenLoading } from 'store/Slice/userSlice';

export const getUserSessionList = createAsyncThunk(
  'getUserSessionList',
  async (_request: any, { dispatch }) => {
    dispatch(startLoading());
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 10;
    const search = _request?.search || '';
    let url = `api/v1/user-session/?page=${page + 1}&limit=${size}`;
    try {
      if (search) {
        url += `&query=${search}`;
      }
      const response: any = await API.get(url);
      dispatch(setUserSessionList(response.data.data));
      dispatch(stopLoading());
      return response;
    } catch (err: any) {
      dispatch(setUserSessionList([]));
      dispatch(stopLoading());
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const deleteUserSession = createAsyncThunk(
  'deleteUserSession',
  async (_request: any, { dispatch }) => {
    const id = _request?.id || '';
    let url = `/api/v1/user-session/${id}`;
    try {
      await API.delete(url);
      dispatch(getUserSessionList({ pageSize: 10, pageIndex: 1, search: '' }));
      // dispatch(stopLoading());
      return true;
    } catch (err: any) {
      // dispatch(stopLoading());
      // dispatch(notificationFail(err.response.data.message));
      return false;
    }
  },
);
