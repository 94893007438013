import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Button, Paper, Typography, useMediaQuery } from '@mui/material';

import { Theme } from '@mui/material/styles';
import CrudPopUp from 'components/popUp/crudPopup';
import { useAppDispatch } from 'store/store';
import { removeUserFromClassRoom } from 'store/Thunk/classroomThunk';
import useSchoolClassroomInfo from 'hooks/useSchoolClassroomInfo';
import RemoveUserFromClassDialog from 'components/dialog/users/Delete/removeUser';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';

interface MyComponentProps {
  name: string;
  grade: string;
  id?: string;
  deleteUserFromClassRoom?: any;
  setUserIdToRemove?: any;
  setRemoveUserModal?: any;
}

const StudentCard: React.FC<MyComponentProps> = ({
  name,
  grade,
  id,
  setRemoveUserModal,
  setUserIdToRemove,
}) => {
  const [showPopUp, setShowPopUp] = useState(false);
  const navigate = useNavigate();

  const mobileSize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  return (
    <>
      <Box sx={{ ...(!mobileSize && { maxWidth: 260 }) }}>
        <Paper
          elevation={0}
          sx={{
            position: 'relative',
            border: '1px solid #DCDCDC',
            borderRadius: '8px',
          }}
        >
          <Box sx={{ my: { sm: 4, xs: 2 } }}>
            <Box>
              <IconButton
                aria-label="settings"
                onClick={() => setShowPopUp(!showPopUp)}
                sx={{
                  ...(!mobileSize
                    ? { position: 'absolute', right: '0px', top: '0px', m: 1 }
                    : { display: 'none' }),
                }}
              >
                <MoreVertIcon />
              </IconButton>
              {showPopUp && (
                <CrudPopUp
                  sx={{ right: '30px !important' }}
                  showDelete={true}
                  setClose={() => setShowPopUp(false)}
                  deleteText="Remove from Classroom"
                  onDeleteClick={() => {
                    setRemoveUserModal(true);
                    setShowPopUp(false);
                    if (id) {
                      setUserIdToRemove(id);
                    }
                  }}
                />
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { sm: 'column', xs: 'row' },
                alignItems: 'center',
                my: { sm: 6, xs: 0 },
                mx: { sm: 0, xs: 2 },
              }}
            >
              <Avatar
                alt={'Student'}
                src={''}
                sx={{
                  width: { sm: 86, xs: 54 },
                  height: { sm: 86, xs: 54 },
                  p: 1,
                  border: { sm: '1px solid #E9EBEC', xs: 'none' },
                }}
              />
              <Typography variant="subtitle1" className="title_text" mt={2}>
                {name ? name : '-'}
              </Typography>
              <Typography
                variant="body1"
                className="subtitle_text"
                mt={2}
                sx={{ ...(mobileSize && { display: 'none' }) }}
              >
                {grade ? grade : 'Grade 3'}
              </Typography>
              <Button
                variant="contained"
                className="gray_bg_btn"
                sx={{ ...(mobileSize ? { display: 'none' } : { mt: 3 }) }}
                onClick={() => {
                  navigate(`/school-leader/user-management/view?mode=student&id=${id}`)
                }}
              >
                View Profile
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default StudentCard;
