import { Card, CircularProgress } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { capture } from 'assets'
import G3MS from 'icons/g3ms'
import React, { useEffect, useState } from 'react'
import { loginwithEmailPassword } from 'store/Thunk/authThunk'

import { useAppDispatch, useAppSelector } from 'store/store'

export default function SignIn() {
  const dispatch = useAppDispatch()
  const [email, setEmail]: any = useState<any>('')
  const [password, setPassword]: any = useState<any>('')
  const { screenLoading } = useAppSelector((state: any) => state.loadingReducer)

  console.log(screenLoading)

  const handleSubmit = () => {
    const req = {
      email: email,
      password: password
    }
    dispatch(loginwithEmailPassword(req))
  }

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}
    >
      <Card
        sx={{
          maxWidth: 900,
          minHeight: { xs: 320, sm: 450 },
          width: '100%',
          overflow: 'hidden',
          position: 'relative',
          display: 'flex',
          mb: 8
        }}
      >
        <Grid container>
          <Grid item xs={12} lg={6} md={6}>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                textAlign: 'center',
                padding: { xs: 5, lg: 10 }
              }}
            >
              <Box
                sx={{
                  padding: { xs: 5, lg: 10 },
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}
              >
                <Box sx={{ mr: 2, '.svg': { height: 24 } }}>
                  <G3MS />
                </Box>
                <Box sx={{ mb: 1.5, fontWeight: 'bold', fontSize: 20 }}>Login</Box>
              </Box>
              <Box sx={{ mb: { xs: 3, xl: 4 } }}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
              </Box>
              <Box sx={{ mb: { xs: 3, xl: 4 } }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
              </Box>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                disabled={screenLoading}
                onClick={() => handleSubmit()}
              >
                {screenLoading ? <CircularProgress color="primary" size={24} /> : "Login"}
                {/* Login */}
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            md={6}
            sx={{
              position: 'relative',
              padding: { xs: 5, lg: 10 },
              display: { xs: 'none', lg: 'flex', sm: 'flex', md: 'flex' },
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              backgroundImage: `url(${capture})`,
              color: (theme) => theme.palette.common.white,
              fontSize: 14
            }}
          >
            <Box sx={{ height: 200 }}></Box>
          </Grid>
        </Grid>
      </Card>
    </Box>
  )
}
