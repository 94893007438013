// *** React Imports
import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Typography,
  Button,
  Grid,
  TextField,
  Autocomplete,
  CircularProgress,
  InputLabel,
  createFilterOptions,
} from '@mui/material';

// *** Redux Imports
import { useAppDispatch, useAppSelector } from 'store/store';
import {
  getTopicList,
  getSubTopicList,
  getSkillList,
  getGradeList,
  getMuxUploadUrl,
  saveUploadVideo,
  getVideoById,
} from 'store/Thunk/videoThunk';

// *** Custom Components
import FileUpload from 'components/fileUpload';

// *** Icons
import { EditWithColorIcon } from 'icons';

// *** Assets
import DetailImage from 'assets/detail_dummy_img.png';
import EditImage from 'assets/edit_dummy_video.png';
import MuxVideoPlayer from 'components/fileUpload/MuxVideoPlayer';
import { getUserList } from 'store/Thunk/userThunk';
import { DatePicker } from '@mui/lab';
import Validations from 'helper/validations';
import { useLocation, useParams } from 'react-router-dom';
// *** Third Party Imports
import { useNavigate } from 'react-router-dom';
import { route_info } from 'navigation/route';
import {
  setGradeList,
  setSkillList,
  setSubTopicList,
  setVideoById,
} from 'store/Slice/videoSlice';
import { startLoading, stopLoading } from 'store/Slice/loadingSlice';
import { getAuthUser, getOptionLabel } from 'helper/service';

import AddSkill from 'components/dialog/skill/addSkill';
import AddSubTopic from 'components/dialog/subtopic/addSubTopic';
import { getDistrictList } from 'store/Thunk/districtThunk';
import { getSchoolList } from 'store/Thunk/schoolThunk';
import SelectDistrictAndSchool from 'components/selectDistrictAndSchool';

interface Props {
  handleCancel?: any;
}

const filter = createFilterOptions();

function Index({ detail, edit, add, saveTitle, handleCancel }: any) {
  const navigate: any = useNavigate();
  const dispatch: any = useAppDispatch();
  const { topicList, subtopicList, skillList, gradeList, uploadUrl, videoObj } =
    useAppSelector((state: any) => state.videoReducer);
  const { userList } = useAppSelector((state: any) => state.userReducer);
  const { screenLoading } = useAppSelector(
    (state: any) => state.loadingReducer,
  );
  const { districtList } = useAppSelector(
    (state: any) => state.districtReducer,
  );
  const { schoolList } = useAppSelector((state: any) => state.schoolReducer);

  const [uploadedFile, setUploadedFile]: any = useState<any[]>([]);

  const [formData, setFormData] = useState<any>({
    grade: [],
    skill: [],
  });

  const [formError, setFormError] = useState<any>({});
  const [loadingDistrict, setLoadingDistrict] = React.useState(false);
  const [loadingSchool, setLoadingSchool] = React.useState(false);
  const [loadingTopic, setLoadingTopic] = React.useState(false);
  const [loadingSubTopic, setLoadingSubTopic] = React.useState(false);
  const [loadingGrade, setLoadingGrade] = React.useState(false);
  const [loadingSkill, setLoadingSkill] = React.useState(false);
  const [loadingUser, setLoadingUser] = React.useState(false);
  const [openDistrict, setOpenDistrict] = React.useState(false);
  const [openSchool, setOpenSchool] = React.useState(false);
  const [openTopic, setOpenTopic] = React.useState(false);
  const [openSubTopic, setOpenSubTopic] = React.useState(false);
  const [openGrade, setOpenGrade] = React.useState(false);
  const [openSkill, setOpenSkill] = React.useState(false);
  const [openUser, setOpenUser] = React.useState(false);
  const [district, setDistrict] = React.useState<any[]>([]);
  const [school, setSchool] = React.useState<any[]>([]);
  const [topic, setTopic] = React.useState<any[]>([]);
  const [subTopic, setSubTopic] = React.useState<any[]>([]);
  const [grade, setGrade] = React.useState<any[]>([]);
  const [skill, setSkill] = React.useState<any[]>([]);
  const [user, setUser] = React.useState<any[]>([]);

  const [openSubTopicDialog, setOpenSubTopicDialog] = React.useState(false);
  const [newSubTopicValue, setNewSubTopicValue] = React.useState({});
  const [openSkillDialog, setOpenSkillDialog] = React.useState(false);
  const [newSkillValue, setNewSkillValue] = React.useState({});

  const [videoProgress, setVideoProgress] = React.useState(0);

  const authUser = getAuthUser();

  let { id } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const isAcademic = urlParams.get('academic') === 'false' ? false : true;
  console.log('isAcademic', isAcademic);
  console.log('isAcademic', urlParams.get('academic'));

  useEffect(() => {
    dispatch(setVideoById({}));
    if (edit || detail) {
      dispatch(getVideoById({ id }));
    }
  }, [id]);

  useEffect(() => {
    if (
      Object.keys(videoObj).length > 0 &&
      videoObj.constructor === Object &&
      (edit || detail)
    ) {
      console.log('HERE THE FK IAM', videoObj);
      setFormData({
        ...formData,
        id: videoObj?.id || '',
        title: videoObj?.title || '',
        user: videoObj?.user || '',
        date: videoObj?.createdAt || '',
        district: videoObj?.district?.map((item: any) => item.district),
        school: videoObj?.school?.map((item: any) => item.school),
        topic: videoObj?.topic[0]?.topic || '',
        sub_topic: videoObj?.subTopic[0]?.subTopic || '',
        grade: videoObj?.grade?.map((obj: any) => obj.grade),
        skill: videoObj?.skill[0]?.skill || '',
        assetId: videoObj?.assetId || '',
        playbackId: videoObj?.playbackId || '',
      });
    } else {
      setFormData({
        grade: [],
        skill: [],
      });
    }
  }, [videoObj]);

  useEffect(() => {
    if (authUser) {
      setUser([{ ...authUser }]);
    }
  }, []);

  console.log('formData', formData);

  // useEffect(() => {
  //   dispatch(getUserList());
  // }, []);

  useEffect(() => {
    if (openDistrict) {
      dispatch(getDistrictList({ pageSize: 50 }));
      setLoadingDistrict(openDistrict);
    } else {
      setDistrict([]);
    }
  }, [openDistrict]);

  useEffect(() => {
    if (formData?.district?.id && openSchool) {
      dispatch(getSchoolList({ district: formData?.district }));
      setLoadingSchool(openSchool);
    } else {
      setSchool([]);
    }
  }, [openSchool, formData?.district]);

  useEffect(() => {
    if (openTopic) {
      dispatch(getTopicList());
    } else {
      setTopic([]);
    }
    setLoadingTopic(openTopic);
  }, [openTopic]);

  useEffect(() => {
    if (formData?.topic?.id && openSubTopic) {
      dispatch(getSubTopicList({ topic: formData?.topic }));
    } else {
      setSubTopic([]);
    }
    setLoadingSubTopic(openSubTopic);
  }, [openSubTopic, formData?.topic]);

  useEffect(() => {
    if (openGrade) {
      dispatch(getGradeList());
    } else {
      setGrade([]);
    }
    setLoadingGrade(openGrade);
  }, [openGrade]);

  useEffect(() => {
    if (formData?.sub_topic?.id && openSkill) {
      dispatch(getSkillList({ subTopic: formData?.sub_topic }));
    } else {
      setSkill([]);
    }
    setLoadingSkill(openSkill);
  }, [openSkill, formData?.sub_topic]);

  useEffect(() => {
    setLoadingDistrict(false);
    setDistrict(districtList?.items || []);
  }, [districtList]);

  useEffect(() => {
    setLoadingSchool(false);
    if (schoolList && schoolList?.items) {
      setSchool(schoolList.items);
    } else {
      setSchool(schoolList);
    }
  }, [schoolList]);

  useEffect(() => {
    setLoadingTopic(false);
    setTopic(topicList);
  }, [topicList]);

  useEffect(() => {
    setLoadingSubTopic(false);
    setSubTopic(subtopicList);
  }, [subtopicList]);

  useEffect(() => {
    setLoadingGrade(false);
    setGrade(gradeList || []);
  }, [gradeList]);

  useEffect(() => {
    setLoadingSkill(false);
    setSkill(skillList);
  }, [skillList]);

  useEffect(() => {
    setLoadingUser(false);
    setUser(userList);
  }, [userList]);

  useEffect(() => {
    setFormError({ ...formError, videoFile: '' });
  }, [uploadedFile]);

  let UpChunk: any = null;
  if (typeof window !== 'undefined') {
    UpChunk = require('@mux/upchunk');
  }

  useEffect(() => {
    // dispatch(getUserList())
    dispatch(getMuxUploadUrl());
  }, []);

  /*  useEffect(() => {
    if (formData?.topic?.id) {
      dispatch(getSubTopicList({ topic: formData.topic }))
    } else {
      dispatch(setSubTopicList([]))
    }
  }, [formData.topic])
  
  useEffect(() => {
    if (formData?.topic?.id && formData?.sub_topic?.id) {
      dispatch(getGradeList())
      dispatch(getSkillList({ subTopic: formData?.sub_topic }))
    } else {
      dispatch(setSkillList([]))
      dispatch(setGradeList([]))
    }
  },[formData.sub_topic]) */

  const handleChangeAutocomplete =
    (name: string) =>
    (
      event: React.SyntheticEvent<Element, Event>,
      newValue: string | null | any,
    ) => {
      let param = {
        [name]: newValue,
        ...(name === 'topic' && {
          sub_topic: '',
          skill: '',
        }),
        ...(name === 'sub_topic' && {
          skill: '',
        }),
        ...(name === 'district' && {
          school: '',
        }),
      };
      setFormData({ ...formData, ...param });
    };

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Autocomplete onChange method for select and create a options
  const handleChangeCreateOptions =
    (name: string) =>
    (
      event: React.SyntheticEvent<Element, Event>,
      newValue: string | null | any,
    ) => {
      if (typeof newValue === 'string') {
        // timeout to avoid instant validation of the dialog's form.
        setTimeout(() => {
          handleSkillDialog(newValue);
        });
      } else if (newValue && newValue.inputValue) {
        handleSkillDialog(newValue.inputValue);
      } else {
        setFormData({
          ...formData,
          skill: newValue,
        });
      }
    };

  const handleFilterOptions = (options: any, params: any) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some(
      (option: any) => inputValue === option.name,
    );
    if (inputValue !== '' && !isExisting && formData?.sub_topic) {
      filtered.push({
        inputValue,
        name: `Add "${inputValue}"`,
      });
    }

    return filtered;
  };

  // Autocomplete onChange method for select and create a options
  const handleChangeCreateSubTopic = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string | null | any,
  ) => {
    if (typeof newValue === 'string') {
      // timeout to avoid instant validation of the dialog's form.
      setTimeout(() => {
        handleSubTopicDialog(newValue);
      });
    } else if (newValue && newValue.inputValue) {
      handleSubTopicDialog(newValue.inputValue);
    } else {
      setFormData({
        ...formData,
        sub_topic: newValue,
      });
    }
  };

  console.log('FormdAra', formData);

  const handleFilterSubTopic = (options: any, params: any) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some(
      (option: any) => inputValue === option.name,
    );
    if (inputValue !== '' && !isExisting && formData?.topic) {
      filtered.push({
        inputValue,
        name: `Add "${inputValue}"`,
      });
    }

    return filtered;
  };

  const handleSkillDialog = (name: string = '') => {
    setNewSkillValue({
      ...(!openSkillDialog
        ? {
            topic: formData.topic,
            name: name,
            subTopic: formData.sub_topic,
          }
        : {}),
    });
    setOpenSkillDialog(!openSkillDialog);
  };

  const handleSubTopicDialog = (name: string = '') => {
    setNewSubTopicValue({
      ...(!openSubTopicDialog
        ? {
            topic: formData.topic,
            name: name,
          }
        : {}),
    });
    setOpenSubTopicDialog(!openSubTopicDialog);
  };

  const handleUpload = (event: any) => {
    event.preventDefault();
    let _request: any = {
      id: formData?.id || '',
      title: formData.title?.trim(),
      topicId: formData.topic?.id,
      subTopicId: formData.sub_topic?.id,
      skillIds: formData?.skill?.map((skill: any) => skill.id),
      gradeIds: formData?.grade?.map((grade: any) => grade.id),
      userId: formData.user?.id || '',
      videoFile: uploadedFile,
      uploadId: uploadUrl?.id || '',
      isAcademic: isAcademic,
    };

    let allError = Validations.validateVideoForm(_request, isAcademic);
    console.log('allError', allError);
    console.log('allError', formError);
    if (
      Object.entries(allError).length === 0 &&
      allError.constructor === Object
    ) {
      dispatch(startLoading());
      if (uploadUrl?.url && uploadedFile) {
        const muxUpload = UpChunk.createUpload({
          endpoint: uploadUrl?.url,
          file: uploadedFile[0],
          chunkSize: 5120, // Uploads the file in ~5mb chunks
        });

        // subscribe to events
        muxUpload.on('error', (error: any) => {
          console.error('💥 🙀', error.detail);
          dispatch(stopLoading());
        });

        muxUpload.on('progress', (progress: any) => {
          console.log(
            `So far we've uploaded ${progress.detail}% of this file.`,
          );
        });

        muxUpload.on('success', () => {
          console.log("Wrap it up, we're done here. 👋");
          dispatch(saveUploadVideo(_request)).them(() => {
            handleCancel({ add, edit, detail });
          })
        });
      }
    } else {
      setFormError(allError);
    }
  };

  const handleUpdate = (event: any) => {
    event.preventDefault();
    let _request: any = {
      id: formData?.id || '',
      title: formData.title?.trim(),
      districtIds:
        (Array.isArray(formData?.district) &&
          formData?.district?.map((item: any) => item.id)) ||
        [],
      schoolIds:
        Array.isArray(formData?.school) && formData?.school?.length > 0
          ? formData?.school.map((item: any) => item.id)
          : [],
      topicId: formData.topic?.id || '',
      subTopicId: formData.sub_topic?.id || '',
      skillId: formData?.skill?.id || '',
      gradeIds: formData?.grade?.map((grade: any) => grade.id),
      userId: formData.user?.id || '',
      videoFile: uploadedFile,
      assetId: formData?.assetId || '',
      playbackId: formData?.playbackId || '',
      isAcademic: isAcademic,
    };

    let allError = Validations.validateUpdateVideoForm(_request, isAcademic);
    console.log('allError', allError);
    console.log('allError', formError);
    if (
      Object.entries(allError).length === 0 &&
      allError.constructor === Object
    ) {
      dispatch(startLoading());
      if (uploadUrl?.url && uploadedFile.length) {
        const muxUpload = UpChunk.createUpload({
          endpoint: uploadUrl?.url,
          file: uploadedFile[0],
          chunkSize: 5120, // Uploads the file in ~5mb chunks
        });

        // subscribe to events
        muxUpload.on('error', (error: any) => {
          console.error('💥 🙀', error.detail);
          setVideoProgress(0);
          dispatch(stopLoading());
        });

        muxUpload.on('progress', (progress: any) => {
          setVideoProgress(progress?.detail || 0);
          console.log(
            `So far we've uploaded ${progress.detail}% of this file.`,
          );
        });

        muxUpload.on('success', () => {
          console.log("Wrap it up, we're done here. 👋");
          _request['uploadId'] = uploadUrl.id;
          dispatch(saveUploadVideo(_request)).then(() => {
            handleCancel({ add, edit, detail })
          });
        });
      } else {
        dispatch(saveUploadVideo(_request)).then(() => {
          handleCancel({ add, edit, detail })
        });
      }
    } else {
      setFormError(allError);
    }
  };

  const handleRemoveVideo = (event: any) => {
    setFormData({ ...formData, assetId: '', playbackId: '' });
  };

  const handleCancelBtn = (event: any) => {
    handleCancel({ add, edit, detail });
  };

  const handleSaveBtn = (event: any) => {
    if (add) {
      handleUpload(event);
    } else if (edit) {
      handleUpdate(event);
    } else {
      handleCancel({ add, edit, detail });
    }
  };

  const handleEditBtn = (event: any) => {
    navigate(
      `${route_info.content_management.video_edit}/${id}?academic=${isAcademic}`,
    );
  };

  return (
    <Box p={4}>
      <Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          mb="24px"
        >
          <Typography className="table_title">Upload Video</Typography>

          {detail && (
            <Button
              onClick={handleEditBtn}
              variant="text"
              sx={{ ml: 3, color: '#C73E9D', fontSize: '16px' }}
              endIcon={<EditWithColorIcon />}
            >
              Edit
            </Button>
          )}
        </Box>
        <Grid container spacing={7} mb={5}>
          <Grid item xs={4}>
            <Typography mb={2}>Title</Typography>
            <TextField
              sx={{ width: '100%' }}
              placeholder="Title"
              name="title"
              onChange={handleChange}
              disabled={detail}
              value={formData?.title || ''}
              error={formError && formError?.title}
              helperText={formError && formError?.title ? formError.title : ''}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel disabled sx={{ color: '#484848', pb: 2 }}>
              User Created
            </InputLabel>
            <Autocomplete
              id="asynchronous-demo"
              open={openUser}
              onOpen={() => setOpenUser(true)}
              onClose={() => setOpenUser(false)}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              getOptionLabel={(option) => option?.email || ''}
              options={user}
              loading={loadingUser}
              disabled
              value={authUser}
              onChange={handleChangeAutocomplete('user')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select User"
                  error={formError && formError?.user}
                  helperText={
                    formError && formError?.user ? formError.user : ''
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingUser ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel sx={{ color: '#484848', pb: 2 }}>Grade</InputLabel>
            <Autocomplete
              multiple
              id="asynchronous-demo"
              disabled={detail}
              open={openGrade}
              onOpen={() => setOpenGrade(true)}
              onClose={() => setOpenGrade(false)}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              getOptionLabel={(option) => option?.name || ''}
              options={grade}
              loading={loadingGrade}
              value={formData?.grade || []}
              onChange={handleChangeAutocomplete('grade')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Grade"
                  error={formError && formError?.grade}
                  helperText={
                    formError && formError?.grade ? formError.grade : ''
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingGrade ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <SelectDistrictAndSchool
            state={formData}
            formError={formError}
            setState={setFormData}
          />
          {/* <Grid item xs={4}>
            <InputLabel sx={{ color: '#484848', pb: 2 }}>
              District ID
            </InputLabel>
            <Autocomplete
              id="asynchronous-demo"
              open={openDistrict}
              onOpen={() => setOpenDistrict(true)}
              onClose={() => setOpenDistrict(false)}
              isOptionEqualToValue={(option, value) =>
                option.ncesDistrictId === value?.ncesDistrictId
              }
              getOptionLabel={(option) => option?.ncesDistrictId || ''}
              options={district}
              loading={loadingDistrict}
              filterSelectedOptions
              value={formData?.district || []}
              onChange={handleChangeAutocomplete('district')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select District"
                  error={formError && formError?.district}
                  helperText={
                    formError && formError?.district ? formError.district : ''
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingDistrict ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel sx={{ color: '#484848', pb: 2 }}>School ID</InputLabel>
            <Autocomplete
              id="asynchronous-demo"
              open={openSchool}
              onOpen={() => setOpenSchool(true)}
              onClose={() => setOpenSchool(false)}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              getOptionLabel={(option) => option?.name || ''}
              options={school}
              loading={loadingSchool}
              filterSelectedOptions
              value={formData?.school || {}}
              onChange={handleChangeAutocomplete('school')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select school"
                  error={formError && formError?.school}
                  helperText={
                    formError && formError?.school ? formError.school : ''
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingSchool ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid> */}
          <Grid item xs={4}>
            <InputLabel sx={{ color: '#484848', pb: 2 }}>Topic</InputLabel>
            <Autocomplete
              id="asynchronous-demo"
              disabled={detail}
              open={openTopic}
              onOpen={() => setOpenTopic(true)}
              onClose={() => setOpenTopic(false)}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              getOptionLabel={(option) => option?.name || ''}
              options={topic}
              loading={loadingTopic}
              filterSelectedOptions
              value={formData?.topic || {}}
              onChange={handleChangeAutocomplete('topic')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Topic"
                  error={formError && formError?.topic}
                  helperText={
                    formError && formError?.topic ? formError.topic : ''
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingTopic ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          {isAcademic && (
            <Grid item xs={4}>
              <InputLabel sx={{ color: '#484848', pb: 2 }}>
                Sub-Topic
              </InputLabel>
              <Autocomplete
                id="asynchronous-demo"
                open={openSubTopic}
                onOpen={() => setOpenSubTopic(true)}
                onClose={() => setOpenSubTopic(false)}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={getOptionLabel}
                options={subTopic}
                loading={loadingSubTopic}
                value={formData?.sub_topic || {}}
                onChange={handleChangeCreateSubTopic}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Sub topic"
                    error={formError && formError?.sub_topic}
                    helperText={
                      formError && formError?.sub_topic
                        ? formError.sub_topic
                        : ''
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingSubTopic ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
                filterOptions={handleFilterSubTopic}
              />
            </Grid>
          )}

          {isAcademic && (
            <Grid item xs={4}>
              <InputLabel sx={{ color: '#484848', pb: 2 }}>Skill</InputLabel>
              <Autocomplete
                id="asynchronous-demo"
                disabled={detail}
                open={openSkill}
                onOpen={() => setOpenSkill(true)}
                onClose={() => setOpenSkill(false)}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={getOptionLabel}
                options={skill}
                loading={loadingSkill}
                value={formData?.skill || {}}
                onChange={handleChangeCreateOptions('skill')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Skill"
                    error={formError && formError?.skill}
                    helperText={
                      formError && formError?.skill ? formError.skill : ''
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingSkill ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
                filterOptions={handleFilterOptions}
              />
            </Grid>
          )}
          {/* <Grid item xs={4}>
            <InputLabel sx={{ color: '#484848', pb: 2 }}>User</InputLabel>
            <Autocomplete
              id="asynchronous-demo"
              open={openUser}
              onOpen={() => setOpenUser(true)}
              onClose={() => setOpenUser(false)}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              getOptionLabel={(option) => option?.name || ''}
              options={user}
              loading={loadingUser}
              disabled
              value={user.filter((val) => val.email === authUser.email)[0]}
              onChange={handleChangeAutocomplete('user')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select User"
                  error={formError && formError?.user}
                  helperText={
                    formError && formError?.user ? formError.user : ''
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingUser ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid> */}
          {/* <Grid item xs={4}>
            <Typography mb={2}>User</Typography>
            <Autocomplete
              value={formData?.user || null}
              disablePortal
              getOptionLabel={(option: any) => option.email}
              disableCloseOnSelect
              filterSelectedOptions
              id="user"
              onChange={(e, value) => handleAutoComplete("user", value)}
              options={userList}
              disabled={detail}
              renderInput={(params) => (
                <TextField {...params} placeholder="Choose User"
                disabled={detail}
                error={formError && formError?.user}
                helperText={formError && formError?.user ? formError.user : ""}/>
              )}
            />
          </Grid> */}

          <Grid item xs={4}>
            <DatePicker
              label="Date"
              value={formData?.date || null}
              onChange={(newValue: any) =>
                setFormData({ ...formData, date: newValue })
              }
            />
          </Grid>
        </Grid>

        {edit && formData.assetId && formData.playbackId && (
          <Grid container>
            <Grid item sm={4}>
              <MuxVideoPlayer
                {...formData}
                edit
                handleRemoveVideo={handleRemoveVideo}
              />
            </Grid>
          </Grid>
        )}
        {edit && !formData.assetId && !formData.playbackId && (
          <Box mb={10}>
            <FileUpload
              videoProgress={videoProgress}
              uploadedFile={uploadedFile}
              setUploadedFile={setUploadedFile}
              errorText={formError?.videoFile || ''}
            />
          </Box>
        )}
        {detail && formData.assetId && formData.playbackId && (
          <Grid container mb={4}>
            <Grid item sm={12}>
              <MuxVideoPlayer
                {...formData}
                detail
                handleRemoveVideo={handleRemoveVideo}
              />
            </Grid>
          </Grid>
        )}
        {add && (
          <Box mb={10}>
            <FileUpload
              uploadedFile={uploadedFile}
              setUploadedFile={setUploadedFile}
              errorText={formError?.videoFile || ''}
            />
          </Box>
        )}
        {openSkillDialog && (
          <AddSkill
            open={openSkillDialog}
            onClose={handleSkillDialog}
            skill={newSkillValue}
          />
        )}
        {openSubTopicDialog && (
          <AddSubTopic
            open={openSubTopicDialog}
            onClose={handleSubTopicDialog}
            subTopic={newSubTopicValue}
          />
        )}
      </Box>
      <Box justifyContent={'flex-end'} display={'flex'}>
        <Button
          variant="contained"
          sx={{ ml: 3, bgcolor: '#D9D9D9', color: '#000000', width: '163px' }}
          onClick={handleCancelBtn}
        >
          Cancel
        </Button>
        {edit && (
          <Button
            variant="contained"
            className="primary_bg_btn"
            sx={{ ml: 3, width: '163px' }}
            onClick={handleSaveBtn}
          >
            {screenLoading ? (
              <CircularProgress size={'20px'} sx={{ color: 'white' }} />
            ) : (
              saveTitle
            )}
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default Index;
