import React, { useState } from 'react';
import { AvatarsList } from 'components/Dashboard/students.mock';
import { Modal, Box, Grid } from '@mui/material';
import MDDialog from '@core/components/MDDialog';
import './index.scss';
import { useAppDispatch } from 'store/store';
import { notificationFail } from 'store/Slice/notificationSlice';
import { ContactsOutlined } from '@mui/icons-material';

const ArrowSvg = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 16.5C11.071 16.5 12.946 15.6605 14.3033 14.3033C15.6605 12.946 16.5 11.071 16.5 9C16.5 6.92895 15.6605 5.05395 14.3033 3.6967C12.946 2.33947 11.071 1.5 9 1.5C6.92895 1.5 5.05395 2.33947 3.6967 3.6967C2.33947 5.05395 1.5 6.92895 1.5 9C1.5 11.071 2.33947 12.946 3.6967 14.3033C5.05395 15.6605 6.92895 16.5 9 16.5Z"
      fill="white"
      stroke="#C73E9D"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M6 9L8.25 11.25L12.75 6.75"
      stroke="#C73E9D"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface componentProps {
  open: boolean;
  setOpen: any;
  setFormData: any;
  formData: any;
  setImagePreview: any;
}

function SelectAvatar({
  open,
  setOpen,
  setFormData,
  formData,
  setImagePreview,
}: componentProps) {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const dispatch = useAppDispatch();

  const onModalClose = () => {
    if (!formData.profileImage) {
      dispatch(notificationFail('Please Select Avatar.'));
    } else {
      setOpen(false);
    }
  };

  const dialogProps = {
    open,
    title: 'Select Avatar',
    size: 'xs',
    saveTbtText: 'Select',
    closeBtnText: 'Cancel',
    onClose: onModalClose,
    onSave: onModalClose,
  };

  const handleImageClick = async (index: number) => {
    const response = await fetch(AvatarsList[index].img);
    const blob = await response.blob();
    const file = new File([blob], 'userprofile');
    setSelectedIndex(index);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };

      reader.readAsDataURL(file);
    }

    setFormData((prev: any) => ({
      ...prev,
      profileImage: file,
    }));
  };

  return (
    <MDDialog {...dialogProps}>
      <Grid container spacing={2}>
        {AvatarsList.map((item, index) => (
          <Grid onClick={() => handleImageClick(index)} item xs={3} key={index}>
            <Box sx={{ position: 'relative' }}>
              <img
                width={80}
                height={80}
                src={item.img}
                alt={`avatar-${index}`}
                style={{ cursor: 'pointer' }}
                className={selectedIndex === index ? 'selected' : ''}
              />
              {selectedIndex === index && (
                <span className="arrow">
                  <ArrowSvg />
                </span>
              )}
            </Box>
          </Grid>
        ))}
      </Grid>
    </MDDialog>
  );
}

export default SelectAvatar;
