import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import NodataToShow from 'icons/NoDataToShow';

//*** Icons
import AddIcon from '@mui/icons-material/Add';

// *** Custom Components
import { useAppDispatch, useAppSelector } from 'store/store';
import AddUpdate from 'components/dialog/district/AddUpdate';
import { getDistrictList } from 'store/Thunk/districtThunk';
import { Height } from '@mui/icons-material';

function Index() {
  const dispatch = useAppDispatch();
  const { districtList } = useAppSelector(
    (state: any) => state.districtReducer,
  );

  const [isOpen, setIsOpen] = useState(false);

  const handleAddUpdateDistrict = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {districtList?.items ? (
        <>
          <Card sx={{ p: '24px' }}>
            <Box>
              <Box>
                <Typography className="profile_name_txt">Districts</Typography>
              </Box>
              <Box mx={'10%'} my={2}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <NodataToShow />
                  <Typography variant="body1" className="header_txt" my={4}>
                    No District Found
                  </Typography>
                  <Typography variant="body1" className="header_title" my={2}>
                    Welcome to the G3MS
                  </Typography>
                  <Typography variant="body2" className="header_content" my={3}>
                    Here you will be able to manage your school. In order to
                    accomplish this, you must first create a school district and
                    then create schools within it. Once you have selected or
                    created your school, you will be able to select or create
                    your classroom. <br /> After updating or adding students and
                    their parents, you will be able to assign them G3MS lessons,
                    quizzes, and daily challenges. If you need support or help
                    please check out our resource hub or email
                    hello@getg3ms.com. Live chat support coming soon!
                  </Typography>
                  <Button
                    variant="contained"
                    className="primary_bg_btn"
                    sx={{ my: 3 }}
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                    endIcon={<AddIcon />}
                  >
                    Add District
                  </Button>
                </Box>
              </Box>
            </Box>
          </Card>

          {isOpen && (
            <AddUpdate open={isOpen} onClose={handleAddUpdateDistrict} />
          )}
        </>
      ) : null}
    </>
  );
}

export default Index;
