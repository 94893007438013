import { createAsyncThunk } from '@reduxjs/toolkit';
import { setBtnLoading } from 'store/Slice/loadingSlice';
import {
  notificationFail,
  notificationSuccess,
} from 'store/Slice/notificationSlice';
import {
  setLoading,
  setSchoolByCity,
  setSchoolByID,
  setSchoolLeader,
  setSchoolList,
} from 'store/Slice/schoolSlice';
import API from 'store/api';
import { getDistrictList } from './districtThunk';

export const getSchoolList = createAsyncThunk(
  'getSchoolList',
  async (_request: any, { dispatch }) => {
    let url = `/api/v1/district/${_request?.district?.ncesDistrictId}/school?limit=10&page=1`;
    try {
      const response: any = await API.get(url);
      dispatch(setSchoolList(response.data.data));
      // dispatch(setSchoolList([]));
      return true;
    } catch (err: any) {
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const getAllSchoolList = createAsyncThunk(
  'getSchoolList',
  async (_request: any, { dispatch }) => {
    const page = _request?.pageIndex || 0;
    const size = _request?.pageSize || 10;
    const search = _request?.search || '';
    let url = `/api/v1/school?page=${page + 1}&limit=${size}`;
    try {
      if (search) {
        url += `&query=${search}`;
      }
      const response: any = await API.get(url);

      dispatch(setSchoolList(response.data.data));
      // dispatch(setSchoolList([]));
      return true;
    } catch (err: any) {
      console.log('Error:-', err);
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const getAllSchoolListIds = createAsyncThunk(
  'getSchoolList',
  async (_: any, { dispatch }) => {
    let url = `/api/v1/school/all`;
    try {
      dispatch(setLoading(true));
      const response: any = await API.get(url);
      dispatch(setSchoolList(response.data.data));
      dispatch(setLoading(false));
      return true;
    } catch (err: any) {
      console.log('Error:-', err);
      return err;
    }
  },
);

export const getSchoolLeader = createAsyncThunk(
  'getSchoolList',
  async (_request: any, { dispatch }) => {
    const page = _request?.pageIndex || 0;
    const size = _request?.pageSize || 10;
    const search = _request?.search || '';
    let url = `/api/v1/schools/${_request?.id}/school-leaders?page=${
      page + 1
    }&limit=${size}`;
    try {
      if (search) {
        url += `&query=${search}`;
      }
      const response: any = await API.get(url);

      dispatch(setSchoolLeader(response.data.data));
      // dispatch(setSchoolList([]));
      return true;
    } catch (err: any) {
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);
export const getSchoolByCity = createAsyncThunk(
  'getSchoolByCity',
  async (_request: any, { dispatch }) => {
    let url = `/api/v1/schools?cityId=${_request?.id}`;
    try {
      const response: any = await API.get(url);

      dispatch(
        setSchoolByCity(
          response.data.data.items.map((item: any) => ({
            id: item.id,
            name: item.name,
            district: item.district.id
          })),
        ),
      );
      // dispatch(setSchoolList([]));
      return true;
    } catch (err: any) {
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);
export const getSchoolByID = createAsyncThunk(
  'getSchoolList',
  async (_request: any, { dispatch }) => {
    let url = `/api/v1/school/${_request?.id}`;
    try {
      const response: any = await API.get(url);

      dispatch(setSchoolByID(response.data.data));
      // dispatch(setSchoolList([]));
      return true;
    } catch (err: any) {
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const addUpdateSchool = createAsyncThunk(
  'addUpdateDistrict',
  async (formData: any, { dispatch }) => {
    let url = formData?.id
      ? `/api/v1/school/${formData?.id}`
      : `/api/v1/school`;
    console.log('formData', formData);

    try {
      if (formData?.id) {
        let _request = {
          name: formData?.name,
          ncesDistrictId: formData?.ncesDistrictId,
          streetAddress: formData?.streetAddress,
          schoolType: formData?.schoolType,
          specificSchoolType: formData?.specificSchoolType,
          schoolGrades: formData?.schoolGrades,
          schoolLeaderIds: formData?.schoolLeaderIds,
          cityId: formData?.city,
          stateId: formData?.state,
          zipCode: formData?.zipCode,
          county: formData?.county,
          districtType: formData?.districtType,
          startGradeId: formData?.startGradeId,
          endGradeId: formData?.endGradeId,
          federalFunding: formData?.federalFunding,
          stateFunding: formData?.stateFunding,
          localFunding: formData?.localFunding,
          totalNoOfSchool: formData?.totalNoOfSchool,
          totalNoOfElementarySchool: formData?.totalNoOfElementarySchool,
          totalNoOfMiddleSchool: formData?.totalNoOfMiddleSchool,
          totalNoOfHighSchool: formData?.totalNoOfHighSchool,
        };
        console.log('url', url);

        const response = await API.patch(url, _request);
        dispatch(notificationSuccess('District updated successfully'));
      } else {
        let _request = {
          name: formData?.name,
          schoolType: formData?.schoolType,
          specificSchoolType: formData?.specificSchoolType,
          schoolGrades: formData?.schoolGrades,
          streetAddress: formData?.streetAddress,
          cityId: formData?.city,
          stateId: formData?.state,
          zipCode: formData?.zipCode,
          county: formData?.county,
          phone: formData?.phone,
          districtId: formData?.districtId,
          ncesSchoolId: formData?.ncesSchoolId,
          schoolLeaderIds: formData?.schoolLeaderIds,
        };
        await API.post(url, _request);
        dispatch(notificationSuccess('School created successfully'));
      }
      dispatch(setBtnLoading(false));
      // dispatch(getDistrictList({ pageSize: 10, pageIndex: 0 }));
    } catch (err: any) {
      dispatch(setBtnLoading(false));
      dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const getAllParentInSchool = createAsyncThunk(
  'getAllParentInSchool',
  async (schoolId: any, { dispatch }) => {
    let url = `/api/v1/parents/school/${schoolId}`;
    try {
      const response = await API.get(url);
      return response.data;
    } catch (err: any) {
      return err;
    }
  },
);
