import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Stack,
  Link,
  Breadcrumbs,
  Dialog,
  Card,
} from '@mui/material';
import CustomTable from 'components/customTable';
import DefaultCell from 'components/DefaultCell';
import { EditBorderIcon, DeleteBorderIcon, ViewBorderIcon } from 'icons';
import { EditIcon, DeleteIcon, ViewIcon } from 'icons';

import Profile from 'assets/actionIcons/profile.png';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// *** Third Party Imports
import { useNavigate } from 'react-router-dom';

// *** Custom Components
import { route_info } from 'navigation/route';
import AddUpdate from 'components/dialog/quiz/addUpdate';
import { useAppDispatch, useAppSelector } from 'store/store';
import { getQuizTemplateList } from 'store/Thunk/templateThunk';
import { displayMultipleValue, getLocalDateTime } from 'helper/service';
import DeleteQuiz from 'components/dialog/quiz/deleteQuiz';
import Avatar from '@core/components/mui/avatar';
import { setQuizTemplateById } from 'store/Slice/templateSlice';
import { sortArray } from '@core/utils/sort';
import AppLoader from 'components/appLoader';
import { useSelector } from 'react-redux';
import GenerateQuiz from 'components/dialog/quiz/generate';
import { getUser } from 'helper/functions';
import { Roles } from 'constants/consts';

const breadcrumbs = [
  <Link
    underline="hover"
    key="1"
    color="inherit"
    href="/"
    onClick={() => handleBreadCrumbClick}
  >
    Resources
  </Link>,
  <Typography key="2" color="#C73E9D">
    Assessment
  </Typography>,
];

function handleBreadCrumbClick(
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

function Index() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { quizTemplateList } = useAppSelector(
    (state: any) => state.templateReducer,
  );
  const { btnLoading } = useSelector((state: any) => state.loadingReducer);
  const user = getUser();
  const role = user?.type || '';

  const [isOpen, setIsOpen] = useState(false);
  const [isGenerateOpen, setIsGenerateOpen] = useState(false);
  const [template, setTemplate] = useState<any[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPageSize] = useState(10);
  const [search, setSearch] = useState<any>(null);
  const [totalCount, setTotalCount] = useState(0);
  const [editId, setEditId] = useState('');
  const [openDelete, setOpenDelete] = React.useState(false);
  const [deleteId, setDeleteId] = useState('');

  const fetchIdRef = useRef(0);
  console.log('pagecount', pageCount);
  console.log('pagecount', totalCount);
  useEffect(() => {
    if (quizTemplateList && quizTemplateList?.items) {
      setTemplate(quizTemplateList?.items);
      setPageCount(quizTemplateList?.meta?.lastPage);
      setTotalCount(quizTemplateList?.meta?.total);
    }
  }, [quizTemplateList]);

  const sortedTemplate = [...template]?.sort(
    (a, b) => b?.questions?.length - a?.questions?.length,
  );

  const handleGenerateQuiz = () => {
    setIsGenerateOpen(!isGenerateOpen);
  };
  const handleAddUpdateQuiz = () => {
    setEditId('');
    setIsOpen(!isOpen);
  };

  const handleEditClick = (id: string) => {
    dispatch(setQuizTemplateById({}));
    setEditId(id);
    setIsOpen(!isOpen);
  };

  const handleDeleteDialog = (id: string) => {
    setDeleteId(id);
    setOpenDelete(!openDelete);
  };

  const columns = [
    {
      Header: 'Grade',
      accessor: 'grade',
      Cell: ({ value }: any) => {
        if (value.length) {
          return <DefaultCell value={value} />;
        } else {
          return '-';
        }
      },
    },
    {
      Header: 'Title',
      accessor: 'title',
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: 'No of Questions',
      accessor: 'questions',
      flex: 0.4,
      Cell: ({ value }: any) => {
        if (value.length) {
          return <DefaultCell value={` ${value?.length}` || '0'} />;
        } else {
          return '0';
        }
      },
    },
    // {
    //   Header: 'Date',
    //   accessor: 'date',
    //   Cell: ({ value }: any) => <DefaultCell value={value} />,
    // },
    // {
    //   Header: 'User Created',
    //   accessor: 'user_created',
    //   Cell: ({ value }: any) => (
    //     <Stack direction="row" spacing={2} alignItems="center">
    //       <Avatar
    //         alt={value?.name}
    //         src={value?.profileImage || Profile}
    //         sx={{ width: 28, height: 28 }}
    //       />
    //       {/* <Box component="img" src={value?.profileImage || Profile} /> */}
    //       <Box>{value?.email}</Box>
    //     </Stack>
    //   ),
    // },
    // {
    //   Header: 'Color Code',
    //   accessor: 'color_code',
    //   Cell: ({ value }: any) => (
    //     <Stack direction="row" spacing={2} alignItems="center">
    //       <Box
    //         sx={{
    //           height: '12px',
    //           width: '12px',
    //           borderRadius: '2px',
    //           backgroundColor: { value },
    //         }}
    //       />
    //       <Box>{value}</Box>
    //     </Stack>
    //   ),
    // },
    {
      Header: 'Topic',
      accessor: 'topic',
      Cell: ({ value }: any) => {
        if (value.length > 0) {
          return <DefaultCell value={value} />;
        } else {
          return '-';
        }
      },
    },
    {
      Header: 'Standard',
      accessor: 'sub_topic',
      Cell: ({ value }: any) => {
        if (value.length) {
          return <DefaultCell value={value} />;
        } else {
          return '-';
        }
      },
    },
    {
      Header: 'Skill',
      accessor: 'skill',
      Cell: ({ value }: any) => {
        if (value.length) {
          return <DefaultCell value={value} />;
        } else {
          return '-';
        }
      },
    },

    {
      Header: 'Action',
      accessor: 'action',
      Cell: ({ value }: any) => (
        <Box>
          <IconButton onClick={() => handleEditClick(value.id)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDeleteDialog(value.id)}>
            <DeleteIcon />
          </IconButton>
          <IconButton
            onClick={() =>
              navigate(
                route_info?.content_management?.question.replace(
                  ':id',
                  value?.id,
                ),
              )
            }
          >
            <ViewIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  console.log('sortedTemplate', sortedTemplate);
  const getRows = () => {
    const data: any = sortedTemplate?.map((obj: any) => ({
      title: obj?.title || '-',
      date: obj.createdAt ? getLocalDateTime(obj.createdAt) : '-',
      user_created: obj?.createdBy,
      color_code: obj?.colorCode || '',
      topic: obj?.topic[0]?.topic?.name || '-',
      sub_topic: obj?.subTopic[0]?.subTopic?.name || '-',
      grade: obj?.grade[0]?.grade?.name || {},
      skill: obj?.skill[0]?.skill?.name || '-',
      questions: obj?.questions || [],

      action: obj,
    }));
    return data;
  };

  const tableData = {
    columns: columns,
    rows: getRows(),
  };

  useEffect(() => {
    dispatch(getQuizTemplateList({ pageCount: 1, perPage: 10 }));
  }, []);

  const fetchData = useCallback(({ pageSize, pageIndex, search }: any) => {
    const fetchId = ++fetchIdRef.current;

    // We'll even set a delay to simulate a server here
    if (fetchId === fetchIdRef.current) {
      setPageNo(pageIndex);
      setPageSize(pageSize);
      setSearch(search);
      dispatch(getQuizTemplateList({ pageSize, pageIndex, search }));
    }
    // setTimeout(() => {}, 1000);
  }, []);

  return (
    <Box>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        style={{ marginBottom: 20 }}
      >
        {breadcrumbs}
      </Breadcrumbs>
      <Card sx={{ p: '24px' }}>
        <CustomTable
          table={tableData}
          fetchData={fetchData}
          canSearch
          title="Assessments"
          buttonTitle={role === Roles.ADMIN ? 'Create Assessment' : ''}
          buttonTitleSecond="Generate Quiz"
          searchText="Search..."
          pageCount={pageCount}
          pageNo={pageNo}
          totalCount={totalCount}
          handleOnChange={handleAddUpdateQuiz}
          handleSecondAction={handleGenerateQuiz}
        />
      </Card>
      {isOpen && (
        <AddUpdate
          editId={editId}
          open={isOpen}
          onClose={handleAddUpdateQuiz}
        />
      )}
      {isGenerateOpen && (
        <GenerateQuiz
          editId={editId}
          open={isGenerateOpen}
          onClose={handleGenerateQuiz}
        />
      )}

      {openDelete && (
        <DeleteQuiz
          search={search}
          open={openDelete}
          deleteId={deleteId}
          onClose={handleDeleteDialog}
        />
      )}
      {btnLoading && <AppLoader />}
    </Box>
  );
}

export default Index;
