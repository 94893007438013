import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

export interface ResponseData {
	status: String
	messagecode: String
	message: String
	data: any
}

interface IntialState {
	studentReplyList: any,
}

const intialState: IntialState = {
	studentReplyList: [],
};

const StudentReplySlice = createSlice({
	name: 'video_slice',
	initialState: intialState,
	reducers: {
		setStudentReplyList: (
			state: Draft<IntialState>,
			action: PayloadAction<[]>
		) => {
			state.studentReplyList = action.payload
		},
	}
});


export const {
	setStudentReplyList
} = StudentReplySlice.actions;

export default StudentReplySlice.reducer;
