import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from 'icons/closeIcon';
import Profile from 'assets/actionIcons/profile.png';

export default function PositionedMenu(props: any) {
  const { title } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const listofLeaders = [
    { name: 'Ashley24', email: 'ashley24@gmail.com' },
    { name: 'Ashley24', email: 'ashley24@gmail.com' },
    { name: 'Ashley24', email: 'ashley24@gmail.com' },
    { name: 'Ashley24', email: 'ashley24@gmail.com' },
  ];

  return (
    <div>
      <Button
        variant="contained"
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        size="small"
        sx={{
          backgroundColor: '#20A58A',
          color: '#fff',
          height: '26px',
          width: '26px',
          borderRadius: '24px',
        }}
      >
        {title}
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box
          sx={{
            width: '100%',
            p: '10px',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              variant="body1"
              sx={{ fontSize: '16px', fontWeight: '500' }}
            >
              School Leader
            </Typography>
            <IconButton
              onClick={handleClose}
              sx={{ padding: 'unset', margin: 'unset' }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider />
        </Box>
        {/* {listofLeaders.map((items) => (
          <MenuItem onClick={handleClose}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <>
                  <Box>
                    <Avatar
                      key={'index'}
                      alt={'profileName'}
                      src={Profile}
                      sx={{ marginBottom: 2 }}
                    />
                  </Box>
                </>
              </Grid>
              <Grid item xs={2}>
                <Box mx={3}>
                  <Typography sx={{fontSize:"16px", fontWeight:"400"}}>{items?.name}</Typography>
                </Box>
                <Box mx={3}>
                  <Typography sx={{fontSize:"13px", fontWeight:"400"}}>{items?.email}</Typography>
                </Box>
              </Grid>
            </Grid>
          </MenuItem>
        ))} */}
      </Menu>
    </div>
  );
}
