import { useEffect } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { startLoading } from 'store/Slice/loadingSlice';
import { loginwithToken } from 'store/Thunk/authThunk';
import { useAppDispatch, useAppSelector } from 'store/store';
import { Roles } from 'constants/consts';
interface Props {
  children: JSX.Element;
  path?: string;
}

const AuthRoute = ({ children, path }: Props) => {
  const userData: any = localStorage.getItem('userData');
  const isAuthenticated = JSON.parse(userData);

  const { user } = useAppSelector((state: any) => state.authReducer);

  const dispatch: any = useAppDispatch();

  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get('token')) {
      dispatch(startLoading());
      dispatch(loginwithToken({ token: searchParams.get('token') }), navigate);
      searchParams.delete('token');
      navigate(pathname);
    }
  }, [searchParams]);

  // if (!Object.keys(isAuthenticated ? isAuthenticated : {}).length) {
  //   return <Navigate to={'/login'} replace />
  // }

  // const location = useLocation();
  // const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  // const token: any = new URLSearchParams(location.search).get("token");
  // const userId: any = new URLSearchParams(location.search).get("useridb64");

  // useEffect(() => {
  //   if (location.pathname == "/reset-password") {
  //     let _request: any = {
  //       token: token,
  //       userId: userId,
  //     };
  //     dispatch(verifyResetPasswordToken({ _request, navigate }));
  //   }
  // },[token, userId])
  if (isAuthenticated && isAuthenticated?.token) {
    const userRole = isAuthenticated['type'];
    if (userRole === Roles.ADMIN) {
      return <Navigate to={'/super-admin/dashboard'} replace />;
    } else if (userRole === Roles.TEACHER) {
      return <Navigate to={'/teacher/dashboard'} replace />;
    } else if (userRole === Roles.SCHOOL_LEADER) {
      return <Navigate to={'/school-leader/dashboard'} replace />;
    } else if (userRole === Roles.PARENT) {
      return <Navigate to={'/parent/dashboard'} replace />;
    } else {
      return <Navigate to={'/student/dashboard'} replace />;
    }
  }
  return children;
};

export default AuthRoute;
