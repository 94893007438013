// *** React Imports
import React, { useEffect, useState } from 'react';

// *** Material UI Imports
import {
  Box,
  Grid,
  TextField,
  Autocomplete,
  InputLabel,
  CircularProgress,
  Button,
} from '@mui/material';

/* mui-color-input' */
import { getGradeList } from 'store/Thunk/videoThunk';
import { useAppDispatch, useAppSelector } from 'store/store';
import Validations from 'helper/validations';
import { setBtnLoading, startLoading, stopLoading } from 'store/Slice/loadingSlice';
import { getDistrictByID, addUpdateDistrict } from 'store/Thunk/districtThunk';
import { useNavigate, useParams } from 'react-router-dom';

function Index({ open }: any) {
  const dispatch: any = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { gradeList } = useAppSelector((state: any) => state.videoReducer);

  const { districtID } = useAppSelector((state: any) => state.districtReducer);

  const { screenLoading } = useAppSelector(
    (state: any) => state.loadingReducer,
  );

  const [formData, setFormData] = useState<any>({
    grade: [],
  });
  const [formError, setFormError] = useState<any>({});
  const [loadingGrade, setLoadingGrade] = React.useState(false);
  const [openDistrictType, setOpenDistrictType] = React.useState(false);
  const [openGrade, setOpenGrade] = React.useState(false);
  const [openEndGrade, setOpenEndGrade] = React.useState(false);
  const [grade, setGrade] = React.useState<any[]>([]);
  const [dataFlag, setDataFlag] = React.useState<boolean>(false);

  const DistrictTypesList = [
    { name: 'Regular' },
    { name: 'Component' },
    { name: 'Supervisory Union ID' },
    { name: 'Regional' },
    { name: 'State' },
    { name: 'Federal' },
    { name: 'Charter' },
    { name: 'Other' },
  ];

  useEffect(() => {
    setFormData({});
    dispatch(startLoading());
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getDistrictByID({ id })).then(() => {
        setDataFlag(true);
        dispatch(stopLoading());
      });
    } else {
      dispatch(getDistrictByID({}));
    }
  }, [id]);

  useEffect(() => {
    dispatch(getGradeList());
    if (dataFlag) {
      setFormData({
        ...formData,
        id: id,
        name: districtID[0]?.name || '',
        ncesDistrictId: districtID[0]?.ncesDistrictId || '',
        streetAddress: districtID[0]?.streetAddress || '',
        city: districtID[0]?.city || '',
        state: districtID[0]?.state || '',
        zipCode: districtID[0]?.zipCode || '',
        county: districtID[0]?.county || '',
        districtType: { name: districtID[0]?.districtType } || {},
        startGradeId: districtID[0]?.startGradeId || '',
        startGrade:
          gradeList.filter(
            (item: any) => item.id === districtID[0]?.startGradeId,
          ) || '',
        endGradeId: districtID[0]?.endGradeId || '',
        endGrade:
          gradeList.filter(
            (item: any) => item.id === districtID[0]?.endGradeId,
          ) || '',
        federalFunding: districtID[0]?.federalFunding || '',
        stateFunding: districtID[0]?.stateFunding || '',
        localFunding: districtID[0]?.localFunding || '',
        totalNoOfSchool: districtID[0]?.totalNoOfSchool || '',
        totalNoOfElementarySchool:
          districtID[0]?.totalNoOfElementarySchool || '',
        totalNoOfMiddleSchool: districtID[0]?.totalNoOfMiddleSchool || '',
        totalNoOfHighSchool: districtID[0]?.totalNoOfHighSchool || '',
        superintendent1: districtID?.superintendent1 || '',
        superintendent2: districtID?.superintendent2 || '',
        superintendent3: districtID?.superintendent3 || '',
        superintendent4: districtID?.superintendent4 || '',
      });
    }
  }, [districtID, dataFlag]);

  useEffect(() => {
    if (openGrade || openEndGrade) {
      dispatch(getGradeList());
      setLoadingGrade(true);
      setLoadingGrade(true);
    } else {
      setGrade([]);
    }
  }, [openGrade, openEndGrade]);

  useEffect(() => {
    setLoadingGrade(false);
    setGrade(gradeList);
  }, [gradeList]);

  const handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleChangeAutocomplete =
    (name: string) =>
    (
      event: React.SyntheticEvent<Element, Event>,
      newValue: string | null | any,
    ) => {
      let param = {
        [name]: newValue,
      };
      setFormData({ ...formData, ...param });
    };

  const onClose = () => {
    navigate('/super-admin/district-management');
  };

  const onSave = (event: any) => {
    event.preventDefault();
    let _request: any = {
      id: id || '',
      name: formData?.name || '',
      ncesDistrictId: formData?.ncesDistrictId || '',
      streetAddress: formData?.streetAddress || '',
      city: formData?.city || '',
      state: formData?.state || '',
      zipCode: formData?.zipCode || '',
      county: formData?.county || '',
      districtType: formData?.districtType?.name || '',
      startGradeId:
        formData?.startGrade?.length > 0
          ? formData?.startGrade[0]?.id
          : formData?.startGrade?.id || '',
      startGrade: formData?.startGrade?.name || '',
      endGradeId:
        formData?.startGrade?.length > 0
          ? formData?.endGrade[0]?.id
          : formData?.endGrade?.id || '',
      endGrade: formData?.endGrade?.name || '',
      federalFunding: formData?.federalFunding || '',
      stateFunding: formData?.stateFunding || '',
      localFunding: formData?.localFunding || '',
      totalNoOfSchool: formData?.totalNoOfSchool || '',
      totalNoOfElementarySchool: formData?.totalNoOfElementarySchool || '',
      totalNoOfMiddleSchool: formData?.totalNoOfMiddleSchool || '',
      totalNoOfHighSchool: formData?.totalNoOfHighSchool || '',
    };

    let allError = Validations.ValidateDistrictDataFrom(_request);
    console.log('_request', _request);
    if (
      Object.entries(allError).length === 0 &&
      allError.constructor === Object
    ) {
      dispatch(setBtnLoading(true));
      dispatch(addUpdateDistrict(_request));
      onClose();
    } else {
      setFormError(allError);
    }
  };

  return (
    <Box>
      <Box sx={{ p: 3 }}>
        <Grid container>
          <Grid container spacing={6}>
            <Grid item md={4} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.name}
                sx={{ color: '#484848', pb: 2 }}
              >
                District Name
              </InputLabel>
              <TextField
                required
                fullWidth
                id="name"
                value={formData?.name || ''}
                onChange={handleChangeInput}
                placeholder="Enter district name"
                name="name"
                error={formError && formError?.name}
                helperText={formError && formError?.name ? formError.name : ''}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.ncesDistrictId}
                sx={{ color: '#484848', pb: 2 }}
              >
                NCES District ID
              </InputLabel>
              <TextField
                required
                fullWidth
                id="ncesDistrictId"
                value={formData?.ncesDistrictId || ''}
                onChange={handleChangeInput}
                placeholder="Enter NECS District ID"
                name="ncesDistrictId"
                error={formError && formError?.ncesDistrictId}
                helperText={
                  formError && formError?.ncesDistrictId
                    ? formError.ncesDistrictId
                    : ''
                }
              />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <InputLabel
                error={formError && formError?.districtType}
                sx={{ color: '#484848', pb: 2 }}
              >
                District type
              </InputLabel>
              <Autocomplete
                id="districtType"
                open={openDistrictType}
                onOpen={() => setOpenDistrictType(true)}
                onClose={() => setOpenDistrictType(false)}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option?.name || ''}
                options={DistrictTypesList}
                filterSelectedOptions
                value={formData?.districtType || {}}
                onChange={handleChangeAutocomplete('districtType')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select District type"
                    error={formError && formError?.districtType}
                    helperText={
                      formError && formError?.districtType
                        ? formError.districtType
                        : ''
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6} sx={{ mt: 'unset' }}>
            <Grid item md={3} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.county}
                sx={{ color: '#484848', pb: 2 }}
              >
                Country
              </InputLabel>
              <TextField
                required
                fullWidth
                id="county"
                value={formData?.county || ''}
                onChange={handleChangeInput}
                placeholder="United States"
                name="county"
                error={formError && formError?.county}
                helperText={
                  formError && formError?.county ? formError.county : ''
                }
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.city}
                sx={{ color: '#484848', pb: 2 }}
              >
                City
              </InputLabel>
              <TextField
                required
                fullWidth
                id="city"
                value={formData?.city || ''}
                onChange={handleChangeInput}
                placeholder="Enter City"
                name="city"
                error={formError && formError?.city}
                helperText={formError && formError?.city ? formError.city : ''}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.state}
                sx={{ color: '#484848', pb: 2 }}
              >
                State
              </InputLabel>
              <TextField
                required
                fullWidth
                id="state"
                value={formData?.state || ''}
                onChange={handleChangeInput}
                placeholder="Enter State"
                name="state"
                error={formError && formError?.state}
                helperText={
                  formError && formError?.state ? formError.state : ''
                }
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.zipCode}
                sx={{ color: '#484848', pb: 2 }}
              >
                Zip Code
              </InputLabel>
              <TextField
                required
                fullWidth
                id="zipCode"
                value={formData?.zipCode || ''}
                onChange={handleChangeInput}
                placeholder="Enter Zip Code"
                name="zipCode"
                error={formError && formError?.zipCode}
                helperText={
                  formError && formError?.zipCode ? formError.zipCode : ''
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={6} sx={{ mt: 'unset' }}>
            <Grid item md={4} sm={12} xs={12}>
              <InputLabel
                error={formError && formError?.streetAddress}
                sx={{ color: '#484848', pb: 2 }}
              >
                Street Address
              </InputLabel>
              <TextField
                required
                fullWidth
                id="streetAddress"
                value={formData?.streetAddress || ''}
                onChange={handleChangeInput}
                placeholder="Enter Address"
                name="streetAddress"
                error={formError && formError?.streetAddress}
                helperText={
                  formError && formError?.streetAddress
                    ? formError.streetAddress
                    : ''
                }
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.startGradeId}
                sx={{ color: '#484848', pb: 2 }}
              >
                Start Grade
              </InputLabel>
              <Autocomplete
                id="startGradeId"
                open={openGrade}
                onOpen={() => setOpenGrade(true)}
                onClose={() => setOpenGrade(false)}
                isOptionEqualToValue={(option, value) =>
                  option?.name === value?.name
                }
                getOptionLabel={(option) => option?.name || ''}
                options={grade}
                loading={loadingGrade}
                filterSelectedOptions
                value={
                  formData?.startGrade?.length > 0
                    ? formData?.startGrade[0]
                    : formData?.startGrade || {}
                }
                onChange={handleChangeAutocomplete('startGrade')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Start Grade"
                    error={formError && formError?.startGradeId}
                    helperText={
                      formError && formError?.startGradeId
                        ? formError.startGradeId
                        : ''
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingGrade ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.endGradeId}
                sx={{ color: '#484848', pb: 2 }}
              >
                End Grade
              </InputLabel>
              <Autocomplete
                id="endGrade"
                open={openEndGrade}
                onOpen={() => setOpenEndGrade(true)}
                onClose={() => setOpenEndGrade(false)}
                isOptionEqualToValue={(option, value) =>
                  option?.name === value?.name
                }
                getOptionLabel={(option) => option?.name || ''}
                options={grade}
                loading={loadingGrade}
                filterSelectedOptions
                value={
                  formData?.endGrade?.length > 0
                    ? formData?.endGrade[0]
                    : formData?.endGrade || {}
                }
                onChange={handleChangeAutocomplete('endGrade')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select End Grade"
                    error={formError && formError?.startGradeId}
                    helperText={
                      formError && formError?.endGradeId
                        ? formError.endGradeId
                        : ''
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingGrade ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6} sx={{ mt: 'unset' }}>
            <Grid item md={4} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.federalFunding}
                sx={{ color: '#484848', pb: 2 }}
              >
                Federal Funding
              </InputLabel>
              <TextField
                required
                fullWidth
                id="federalFunding"
                value={formData?.federalFunding || ''}
                onChange={handleChangeInput}
                placeholder="Enter Federal Funding"
                name="federalFunding"
                error={formError && formError?.federalFunding}
                helperText={
                  formError && formError?.federalFunding
                    ? formError.federalFunding
                    : ''
                }
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.stateFunding}
                sx={{ color: '#484848', pb: 2 }}
              >
                State Funding
              </InputLabel>
              <TextField
                required
                fullWidth
                id="stateFunding"
                value={formData?.stateFunding || ''}
                onChange={handleChangeInput}
                placeholder="Enter State Funding"
                name="stateFunding"
                error={formError && formError?.stateFunding}
                helperText={
                  formError && formError?.stateFunding
                    ? formError.stateFunding
                    : ''
                }
              />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <InputLabel
                error={formError && formError?.localFunding}
                sx={{ color: '#484848', pb: 2 }}
              >
                Local Funding
              </InputLabel>
              <TextField
                required
                fullWidth
                id="localFunding"
                value={formData?.localFunding || ''}
                onChange={handleChangeInput}
                placeholder="Enter Local Funding"
                name="localFunding"
                error={formError && formError?.localFunding}
                helperText={
                  formError && formError?.localFunding
                    ? formError.localFunding
                    : ''
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={6} sx={{ mt: 'unset' }}>
            <Grid item md={3} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.totalNoOfSchool}
                sx={{ color: '#484848', pb: 2 }}
              >
                Total Number of Schools
              </InputLabel>
              <TextField
                required
                fullWidth
                type='number'
                id="totalNoOfSchool"
                value={formData?.totalNoOfSchool || ''}
                onChange={handleChangeInput}
                placeholder="Enter Number of Schools"
                name="totalNoOfSchool"
                error={formError && formError?.totalNoOfSchool}
                helperText={
                  formError && formError?.totalNoOfSchool
                    ? formError.totalNoOfSchool
                    : ''
                }
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.totalNoOfElementarySchool}
                sx={{ color: '#484848', pb: 2 }}
              >
                Total Number of Elementary Schools
              </InputLabel>
              <TextField
                required
                fullWidth
                type='number'
                id="totalNoOfElementarySchool"
                value={formData?.totalNoOfElementarySchool || ''}
                onChange={handleChangeInput}
                placeholder="Enter Number of Elementary Schools"
                name="totalNoOfElementarySchool"
                error={formError && formError?.totalNoOfElementarySchool}
                helperText={
                  formError && formError?.totalNoOfElementarySchool
                    ? formError.totalNoOfElementarySchool
                    : ''
                }
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.totalNoOfMiddleSchool}
                sx={{ color: '#484848', pb: 2 }}
              >
                Total Number of Middle Schools
              </InputLabel>
              <TextField
                required
                fullWidth
                type='number'
                id="totalNoOfMiddleSchool"
                value={formData?.totalNoOfMiddleSchool || ''}
                onChange={handleChangeInput}
                placeholder="Enter Number of Middle Schools"
                name="totalNoOfMiddleSchool"
                error={formError && formError?.totalNoOfMiddleSchool}
                helperText={
                  formError && formError?.totalNoOfMiddleSchool
                    ? formError.totalNoOfMiddleSchool
                    : ''
                }
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.totalNoOfHighSchool}
                sx={{ color: '#484848', pb: 2 }}
              >
                Total Number of High Schools
              </InputLabel>
              <TextField
                required
                fullWidth
                type='number'
                id="totalNoOfHighSchool"
                value={formData?.totalNoOfHighSchool || ''}
                onChange={handleChangeInput}
                placeholder="Enter Number of High Schools"
                name="totalNoOfHighSchool"
                error={formError && formError?.totalNoOfHighSchool}
                helperText={
                  formError && formError?.totalNoOfHighSchool
                    ? formError.totalNoOfHighSchool
                    : ''
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={6} sx={{ mt: 'unset' }}>
            <Grid item md={3} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.superintendent1}
                sx={{ color: '#484848', pb: 2 }}
              >
                Superintendent 1
              </InputLabel>
              <TextField
                required
                fullWidth
                id="superintendent1"
                value={formData?.superintendent1 || ''}
                onChange={handleChangeInput}
                placeholder="Enter Superintendent"
                name="superintendent1"
                error={formError && formError?.superintendent1}
                helperText={
                  formError && formError?.superintendent1
                    ? formError.superintendent1
                    : ''
                }
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.superintendent2}
                sx={{ color: '#484848', pb: 2 }}
              >
                Superintendent 2
              </InputLabel>
              <TextField
                required
                fullWidth
                id="superintendent2"
                value={formData?.superintendent2 || ''}
                onChange={handleChangeInput}
                placeholder="Enter Superintendent"
                name="superintendent2"
                error={formError && formError?.superintendent2}
                helperText={
                  formError && formError?.superintendent2
                    ? formError.superintendent2
                    : ''
                }
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.superintendent3}
                sx={{ color: '#484848', pb: 2 }}
              >
                Superintendent 3
              </InputLabel>
              <TextField
                required
                fullWidth
                id="superintendent3"
                value={formData?.superintendent3 || ''}
                onChange={handleChangeInput}
                placeholder="Enter Superintendent"
                name="superintendent3"
                error={formError && formError?.superintendent3}
                helperText={
                  formError && formError?.superintendent3
                    ? formError.superintendent3
                    : ''
                }
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.superintendent4}
                sx={{ color: '#484848', pb: 2 }}
              >
                Superintendent 4
              </InputLabel>
              <TextField
                required
                fullWidth
                id="superintendent4"
                value={formData?.superintendent4 || ''}
                onChange={handleChangeInput}
                placeholder="Enter Superintendent"
                name="superintendent4"
                error={formError && formError?.superintendent4}
                helperText={
                  formError && formError?.superintendent4
                    ? formError.superintendent4
                    : ''
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Box sx={{ m: 2 }}>
                <Button
                  onClick={onClose}
                  variant="contained"
                  color="primary"
                  className="gray_bg_btn"
                  sx={{ mr: 4 }}
                >
                  Discard
                </Button>
                <Button
                  onClick={onSave}
                  variant="contained"
                  className="primary_bg_btn"
                  sx={{ minWidth: '140px' }}
                >
                  Update
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {screenLoading ? (
              <CircularProgress size={'20px'} sx={{ color: 'white' }} />
            ) : (
              null
            )}
    </Box>
  );
}

export default Index;
