const breadcrumbs = {
  MuiBreadcrumbs: {
    styleOverrides: {
      li: {
        '& > .MuiLink-root': {
          textDecoration: 'none'
        }
      }
    }
  }
}
export default breadcrumbs;
