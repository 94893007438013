import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import NodataToShow from 'icons/NoDataToShow';

//*** Icons
import AddIcon from '@mui/icons-material/Add';
import CheckCircle from 'icons/checkCircle';
import PlusCircle from 'icons/PlusCircle';
import File from 'icons/File';

// *** Custom Components
import { useAppDispatch, useAppSelector } from 'store/store';
import AddUpdate from 'components/dialog/classroom/AddUpdate';
import {
  getAllSchoolOfDistrict,
  getDistrictList,
} from 'store/Thunk/districtThunk';
import { getAllSchoolList } from 'store/Thunk/schoolThunk';
import { useParams } from 'react-router-dom';

const borderStyleObj = {
  width: '100%',
  height: '1px',
  backgroundImage: 'linear-gradient(to right, #c73e9d4f 50%, #fff 50%)',
  backgroundPosition: 'top',
  backgroundSize: '11px 2px',
  backgroundRepeat: 'repeat-x',
  marginLeft: '12px',
};
function Index({ onClick }: any) {
  const { id } = useParams();

  const dispatch = useAppDispatch();

  const { schoolList } = useAppSelector((state) => state.schoolReducer);

  const userData = JSON.parse(localStorage.getItem('userData') || '');

  const [isOpen, setIsOpen] = useState(false);

  // useEffect(() => {
  //   if (id) {
  //     dispatch(getAllSchoolOfDistrict({ pageCount: 1, perPage: 10, id }));
  //   } else {
  //     dispatch(getAllSchoolList({ pageCount: 1, perPage: 10 }));
  //   }
  // }, []);

  const handleAddUpdateDistrict = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {true ? (
        <>
          <Box sx={{ p: '24px' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  m: '15px',
                  mt: '5px',
                }}
              >
                <NodataToShow />
                <Typography
                  variant="body1"
                  sx={{ fontSize: '18px', fontWeight: '600' }}
                >
                  No Teacher Found
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: 'justify',
                    fontSize: '13px',
                    fontWeight: '400',
                    p: '0 30px',
                  }}
                >
                  Please Add Teachers to the system to proceed
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  m: '15px 0 0 0',
                }}
              >
                <Button
                  variant="contained"
                  className="primary_bg_btn"
                  onClick={() => {
                    onClick && onClick();
                  }}
                  endIcon={<AddIcon />}
                >
                  Add Teacher
                </Button>
              </Box>
            </Box>
          </Box>
          {isOpen && (
            <AddUpdate
              open={isOpen}
              onClose={handleAddUpdateDistrict}
              id={id}
            />
          )}
        </>
      ) : null}
    </>
  );
}

export default Index;
