// ImagePopup.js
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const ImageViewPopup = ({ imageUrl, onClose, open }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <IconButton
        edge="end"
        color="inherit"
        onClick={handleClose}
        aria-label="close"
        sx={{ position: 'absolute', top: 8, right: 8 }}
      >
        <CloseIcon />
      </IconButton>
      <img
        src={imageUrl}
        alt="Popup Image"
        style={{ maxWidth: '100%', height: 'auto' }}
      />
    </Dialog>
  );
};

export default ImageViewPopup;
