import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Avatar, AvatarGroup, Box, IconButton } from '@mui/material';

import AddFirstContent from './AddFirstGroup';
import { DeleteIcon, ViewIcon, EditIcon } from 'icons';
import DefaultCell from 'components/DefaultCell';
import CustomTable from 'components/customTable';
import AddUpdate from 'components/dialog/group/AddUpdate';
import DeleteDialog from 'components/dialog/group/Delete';
import ChangeStatus from 'components/dialog/group/groupStatus';
import { Base } from 'assets';
import { useAppDispatch, useAppSelector } from 'store/store';
import { getGroupListByClassId } from 'store/Thunk/groupThunk';
import { useParams } from 'react-router-dom';
import { startLoading, stopLoading } from 'store/Slice/loadingSlice';
import { getLocalDateTime } from 'helper/service';

function StudentGroup() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [editGroupId, setEditGroupId] = useState<string | null>(null);
  const [deleteId, setDeleteId] = useState<string>('');
  const [template, setTemplate] = useState<any[]>([]);
  const [openStatus, setOpenStatus] = useState<boolean>(false);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const fetchIdRef = useRef(0);

  // useEffect(()=>{
  //   dispatch(getGroupListByClassId({id}))
  // },[])

  const { groupListByClassId } = useAppSelector((state) => state.groupReducer);

  useEffect(() => {
    if (groupListByClassId && groupListByClassId?.items) {
      setTemplate(groupListByClassId?.items);
      setPageCount(groupListByClassId?.meta?.lastPage);
      setTotalCount(groupListByClassId?.meta?.total);
    }
  }, [groupListByClassId]);

  const handleAddUpdateGroup = () => {
    setEditGroupId('');
    setOpen(!open);
  };
  const handleOpenStatusGroup = () => {
    setOpenStatus(!openStatus);
  };
  const handleOpenDeleteDialog = () => {
    setOpenDelete(!openDelete);
  };

  const handleEditClick = (id: string) => {
    setEditGroupId(id);
    setOpen(!open);
  };

  const fetchData = useCallback(({ pageSize, pageIndex, search }: any) => {
    const fetchId = ++fetchIdRef.current;

    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        setPageNo(pageIndex);
        setPageSize(pageSize);
        // setSearch(search);
        dispatch(startLoading());
        dispatch(getGroupListByClassId({ id })).then(() =>
          dispatch(stopLoading()),
        );
      }
    }, 1000);
  }, []);

  const columns = [
    {
      Header: 'Group Name',
      accessor: 'name',
      width: '18rem',
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: 'Group Member',
      accessor: 'GroupStudent',
      Cell: ({ value }: any) => {
        return (
          <AvatarGroup total={value?.length} spacing={'small'}>
            {value?.map((item: any) => {
              return (
                <Avatar alt={item?.user?.name} src={item?.user?.profileImage} />
              );
            })}
            <Avatar alt="Eureka" src={''} />
            <Avatar alt="Eureka" src={''} />
            <Avatar alt="Eureka" src={''} />
            <Avatar alt="Eureka" src={''} />
            <Avatar alt="Eureka" src={''} />
          </AvatarGroup>
        );
      },
    },
    {
      Header: 'Creation date',
      accessor: 'createdAt',
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }: any) => (
        <Box
          sx={
            value === 'Active'
              ? { color: '#20A58A', backgroundColor: '#DDFAEC' }
              : value === 'Pause'
              ? { color: '#AE7E00CC', backgroundColor: '#FFF2D0' }
              : { color: '#000' }
          }
          minWidth={'6rem'}
          minHeight={'2rem'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          borderRadius={'5px'}
          onClick={handleOpenStatusGroup}
        >
          <DefaultCell value={value} />
        </Box>
      ),
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: ({ value }: any) => (
        <Box>
          <IconButton
            onClick={() => {
              handleEditClick(value?.id);
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setDeleteId(value?.id);
              handleOpenDeleteDialog();
            }}
          >
            <DeleteIcon />
          </IconButton>
          {/* <IconButton
          // onClick={() => {
          //   navigate(route_info?.school_management?.classroomView);
          // }}
          >
            <ViewIcon />
          </IconButton> */}
        </Box>
      ),
    },
  ];

  // Search input state handle
  const onSearchChange = (value: any) => {
    setSearch(value);
  };

  const getRows = () => {
    const data: any = template?.map((obj: any) => ({
      id: obj?.id,
      GroupStudent: obj?.GroupStudent,
      createdAt: obj.createdAt ? getLocalDateTime(obj.createdAt) : '-',
      name: obj.name,
      action: obj,
    }));
    return data;
  };

  const tableData = {
    columns: columns,
    rows: getRows(),
  };

  return (
    <>
      {groupListByClassId?.items?.length > 0 ? (
        <Box>
          <Box sx={{ p: '24px', pt: 0 }}>
            <Box ml={2} mt={2}>
              <CustomTable
                table={tableData}
                fetchData={fetchData}
                canSearch
                searchText="Search..."
                pageCount={pageCount}
                pageNo={pageNo}
                totalCount={totalCount}
                // handleOnChange={handleAddUpdateDistrict}
                buttonTitle="Create New Group"
                title="Group"
                // table={{
                //   columns: columns,
                //   rows: rows,
                // }}
                // canSearch
                handleOnChange={handleAddUpdateGroup}
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <AddFirstContent handleAddUpdateGroup={handleAddUpdateGroup} />
      )}
      {open && (
        <AddUpdate
          open={open}
          onClose={handleAddUpdateGroup}
          classId={id}
          editId={editGroupId}
        />
      )}
      {openStatus && (
        <ChangeStatus open={openStatus} onClose={handleOpenStatusGroup} />
      )}
      {openDelete && (
        <DeleteDialog
          open={openDelete}
          onClose={handleOpenDeleteDialog}
          deleteId={deleteId}
          classId={id}
        />
      )}
    </>
  );
}

export default StudentGroup;
