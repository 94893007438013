export function secondsToMinutes(seconds) {
  var minutes = Math.floor(seconds / 60);
  var remainingSeconds = seconds % 60;

  if (minutes === 0) {
    return parseFloat(remainingSeconds.toFixed(0)) + ' sec';
  } else {
    return minutes + ' min ' + parseFloat(remainingSeconds.toFixed(0)) + ' sec';
  }
}
