import React from "react";

function index() {

  return (
    <p>Coming Soon</p>
  );
}

export default index;
