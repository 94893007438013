import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/store';
import { getStudentDetail } from 'store/Thunk/studentThunk';
import { useLocation } from 'react-router-dom';

export default function StudentProgressHeader() {
  const dispatch = useAppDispatch();
  const { studentDetail } = useAppSelector((state) => state.studentReducer);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [studentId] = useState(queryParams.get('id'));

  return (
    <Box
      mb={4}
      mt={4}
      className="flex gap-0 max-md:flex-wrap"
      style={{ justifyContent: 'space-around', gap: '10px' }}
    >
      <Box
        className="flex-grow flex-basis-0 flex-col px-2 py-2"
        bgcolor={'#20A58A'}
        textAlign={'center'}
      >
        <Typography
          className="text-white font-medium leading-6"
          variant="body1"
          color={'#fff'}
        >
          Total Assignments
        </Typography>
        <Typography
          className="text-white font-semibold leading-6 whitespace-nowrap mt-2"
          variant="body1"
          color={'#fff'}
        >
          <strong>{studentDetail?.totalAssignments || 0}</strong>
        </Typography>
      </Box>
      <Box
        className="flex-grow flex-basis-0 flex-col px-2 py-2"
        bgcolor={'#20A58A'}
        textAlign={'center'}
      >
        <Typography
          className="text-white font-medium leading-6"
          variant="body1"
          color={'#fff'}
        >
          Completed
        </Typography>
        <Typography
          className="text-white font-semibold leading-6 whitespace-nowrap mt-2"
          variant="body1"
          color={'#fff'}
        >
          <strong>{studentDetail?.completedAssignments || 0}</strong>
        </Typography>
      </Box>
      <Box
        className="flex-grow flex-basis-0 flex-col px-2 py-2"
        bgcolor={'#20A58A'}
        textAlign={'center'}
      >
        <Typography
          className="text-white font-medium leading-6"
          variant="body1"
          color={'#fff'}
        >
          Not Started
        </Typography>
        <Typography
          className="text-white font-semibold leading-6 whitespace-nowrap mt-2"
          variant="body1"
          color={'#fff'}
        >
          <strong>{studentDetail?.pendingAssignments || 0}</strong>
        </Typography>
      </Box>
      <Box
        className="flex-grow flex-basis-0 flex-col px-2 py-2"
        bgcolor={'#20A58A'}
        textAlign={'center'}
      >
        <Typography
          className="text-white font-medium leading-6"
          variant="body1"
          color={'#fff'}
        >
          Total Average Score
        </Typography>
        <Typography
          className="text-white font-semibold leading-6 whitespace-nowrap mt-2"
          variant="body1"
          color={'#F41F52'}
        >
          <strong>{studentDetail?.averageScore || 0}%</strong>
        </Typography>
      </Box>
      <Box
        className="flex-grow flex-basis-0 flex-col px-2 py-2"
        bgcolor={'#20A58A'}
        textAlign={'center'}
      >
        <Typography
          className="text-white font-medium leading-6"
          variant="body1"
          color={'#fff'}
        >
          Watch Time
        </Typography>
        <Typography
          className="text-white font-semibold leading-6 whitespace-nowrap mt-2"
          variant="body1"
          color={'#fff'}
        >
          <strong>{studentDetail?.totalWatchHours || 0}</strong>{' '}
        </Typography>
      </Box>
      <Box
        className="flex-grow flex-basis-0 flex-col px-2 py-2"
        bgcolor={'#20A58A'}
        textAlign={'center'}
      >
        <Typography
          className="text-white font-medium leading-6"
          variant="body1"
          color={'#fff'}
        >
          Total G3MS
        </Typography>
        <Typography
          className="text-white font-semibold leading-6 whitespace-nowrap mt-2"
          variant="body1"
          color={'#fff'}
        >
          <strong>{studentDetail?.totalGems || 0}</strong>
        </Typography>
      </Box>
    </Box>
  );
}
