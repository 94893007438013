import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  setGroupDetailsByID,
  setGroupListByClassId,
} from 'store/Slice/groupSlice';
import {
  setBtnLoading,
  startLoading,
  stopLoading,
} from 'store/Slice/loadingSlice';
import {
  notificationFail,
  notificationSuccess,
} from 'store/Slice/notificationSlice';
import API from 'store/api';

export const getGroupListByClassId = createAsyncThunk(
  'getGroupListByClassId',
  async (_request: any, { dispatch }) => {
    const page = _request?.pageIndex || 0;
    const size = _request?.pageSize || 10;
    const search = _request?.search || '';
    let url = `/api/v1/classroom/${_request?.id}/group`;
    try {
      if (search) {
        url += `&query=${search}`;
      }

      const response: any = await API.get(url);

      dispatch(setGroupListByClassId(response.data.data));
      // dispatch(setDistrictList([]));
      return true;
    } catch (err: any) {
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const GetGroupDetailsByID = createAsyncThunk(
  'getGroupListByClassId',
  async (_request: any, { dispatch }) => {
    let url = `/api/v1/classroom/${_request?.classId}/group/${_request?.groupId}`;
    try {
      const response: any = await API.get(url);

      dispatch(setGroupDetailsByID(response.data.data));
      // dispatch(setDistrictList([]));
      return true;
    } catch (err: any) {
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const addUpdateGroup = createAsyncThunk(
  'addUpdateSubTopic',
  async (_request: any, { dispatch }) => {
    let url = _request?.groupId
      ? `/api/v1/classroom/${_request?.classId}/group/${_request?.groupId}`
      : `/api/v1/classroom/${_request?.classId}/group`;
    try {
      if (_request?.groupId) {
        await API.patch(url, _request?.formData);
        dispatch(notificationSuccess('Group updated successfully'));
      } else {
        await API.post(url, _request?.formData);
        dispatch(notificationSuccess('Group created successfully'));
      }
      return true;
    } catch (err: any) {
      dispatch(notificationFail(err.response.data.message));
      return false;
    }
  },
);

export const deleteGroup = createAsyncThunk(
  'deleteGroup',
  async (_request: any, { dispatch }) => {
    let url = `/api/v1/classroom/${_request?.classId}/group/${_request?.id}`;
    try {
      await API.delete(url);
      dispatch(notificationSuccess('Group deleted successfully'));
      return true;
    } catch (err: any) {
      dispatch(notificationFail(err.response.data.message));
      return false;
    }
  },
);
