import { createAsyncThunk } from '@reduxjs/toolkit';
import API from 'store/api';
import { startLoading, stopLoading } from 'store/Slice/loadingSlice';
import { notificationFail } from 'store/Slice/notificationSlice';
import {
  setLoading,
  setUser,
  setUserList,
  setUserQR,
} from 'store/Slice/userSlice';

export const getUserList = createAsyncThunk(
  'getUserList',
  async (_, { dispatch }) => {
    let url = `/api/v1/users`;
    dispatch(startLoading());
    try {
      const response: any = await API.get(url);
      dispatch(setUserList(response.data.data));
      dispatch(stopLoading());
      return response;
    } catch (err: any) {
      dispatch(stopLoading());
      dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const getUserData = createAsyncThunk(
  'getStudentData',
  async (id: string, { dispatch }) => {
    dispatch(setLoading(true));
    let url = `/api/v1/user/${id}`;
    try {
      dispatch(setLoading(true));
      const response = await API.get(url);
      dispatch(setUser(response?.data?.data));
      dispatch(setLoading(false));
      return response;
    } catch (err) {
      return err;
    }
  },
);
export const getUserQR = createAsyncThunk(
  'getStudentQR',
  async (id: string, { dispatch }) => {
    dispatch(setLoading(true));

    try {
      dispatch(setLoading(true));
      const response = await API.get(`/api/v1/students/get-qr/${id}`);
      dispatch(setUserQR(response?.data?.data));
      dispatch(setLoading(false));

      return response?.data;
    } catch (err) {
      return err;
    }
  },
);
