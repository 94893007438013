import React, { useState, useEffect } from 'react';
import AddModal from '.';
import {
  Autocomplete,
  CircularProgress,
  InputLabel,
  TextField,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/store';
import { addTeacherToClassRoom } from 'store/Thunk/classroomThunk';
import { getUnAssignedUsersList } from 'store/Thunk/userThunk';
import { useParams } from 'react-router-dom';
import useSchoolClassroomInfo from 'hooks/useSchoolClassroomInfo';

function AddTeacherToClassRoom({
  isAddTeacherDialogOpen,
  setIsTeacherDialogOpen,
  classRoomId,
  fetchClassRooms,
  onClose
}) {
  const [selectedTeachers, setSelectedTeacher] = useState([]);
  const [teacherList, setTeacherList] = useState([]);
  const urlParams = new URLSearchParams(window.location.search);
  const schoolId = urlParams.get('schoolId');

  const dispatch = useAppDispatch();
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    dispatch(
      getUnAssignedUsersList({
        schoolId: schoolId,
        classId: classRoomId,
        type: 'teacher',
      }),
    )
      .unwrap()
      .then((res) => {
        setTeacherList(res?.data?.data);
      });
  }, [isAddTeacherDialogOpen]);

  const handleChangeAutocomplete = (_, values) => {
    const selectedValues = values?.map((student) => student.id);
    setSelectedTeacher(selectedValues);
  };

  const handleSubmit = () => {
    if (selectedTeachers?.length > 0) {
      setIsError(false);
      dispatch(
        addTeacherToClassRoom({
          classId: classRoomId,
          teacherIds: selectedTeachers,
        }),
      )
        .unwrap()
        .then((res) => {
          fetchClassRooms();
          onClose();
        })
        .catch((err) => {});
    } else {
      setIsError(true);
    }
  };

  return (
    <AddModal
      onClose={onClose}
      open={isAddTeacherDialogOpen}
      setOpen={setIsTeacherDialogOpen}
      onSave={handleSubmit}
      title="Add a Teacher to Classroom"
      info="*Note if a teacher is not listed then please contact your
            administrator to add the teacher"
    >
      <InputLabel error={isError} sx={{ color: '#484848', pb: 2 }}>
        Search And Select Teacher
      </InputLabel>
      <Autocomplete
        multiple
        options={teacherList}
        id="grade"
        getOptionLabel={(option) => option?.name || ''}
        loading={false}
        clearOnBlur
        clearOnEscape
        selectOnFocus={false}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        filterSelectedOptions
        onChange={handleChangeAutocomplete}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Select Teacher"
            error={Boolean('')}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </AddModal>
  );
}

export default AddTeacherToClassRoom;
