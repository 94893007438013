import { createAsyncThunk } from '@reduxjs/toolkit';
import { stopLoading } from 'store/Slice/loadingSlice';
import { setNavItems } from 'store/Slice/navSlice';
import API from 'store/api';
export const getNavItems = createAsyncThunk(
  'getNavItems',
  async (_request: any, { dispatch }) => {
    // dispatch(startLoading());
    let url = `/api/v1/school/120087004352/grades-topics`;
    try {
      const response: any = await API.get(_request);
      console.log(response);
      dispatch(setNavItems(response.data.data));
      dispatch(stopLoading());
      return response;
    } catch (err: any) {
      dispatch(setNavItems([]));
      dispatch(stopLoading());
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);
