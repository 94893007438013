import {
  Box,
  Breadcrumbs,
  Card,
  Checkbox,
  Stack,
  Typography,
} from '@mui/material';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Container, ContentWrapper } from '../styles';
import Instructional from './indtractional';
import ReadMore from 'components/ReadMore/ReadMore';
import { getVideoList } from 'store/Thunk/videoThunk';
import DefaultCell from 'components/DefaultCell';
import { startLoading } from 'store/Slice/loadingSlice';
import { useAppDispatch, useAppSelector } from 'store/store';
import TableWrapper from 'components/tableWrapper';
import CustomTable from 'components/customTable';
import { displayMultipleValue, getLocalDateTime } from 'helper/service';
import SharedButton from 'components/button';
import AssignDeselect from './AssignDeselect';
import { assignContent } from 'store/Thunk/assignContentThunk';
import useSchoolClassroomInfo from 'hooks/useSchoolClassroomInfo';
import Duplicate from './Duplicate';
interface DialogProps {
  onClose?: () => void;
  data?: any;
  setData?: any;
  setSelectedQuizzes?: any;
  selectedQuizzes?: any;
  selectedVideos?: any;
  setSelectedVideos?: any;
  formData?: any;
  setIsOpenQuestionLimit?: any;
  setConfirm?: any;

  onOk?: () => void;
}
export default function AssignConfirmation({
  onClose,
  setData,
  setConfirm,
  setSelectedQuizzes,
  selectedQuizzes,
  selectedVideos,
  setSelectedVideos,
  setIsOpenQuestionLimit,
  formData,
  data,
}: DialogProps) {
  const [selectedId, setSelectedId] = useState('');
  const handleDeselect = () => setDeSelectedContent(true);
  const handleDeselectCancel = () => setDeSelectedContent(false);
  const handleOpenDuplicate = () => setOpenDuplicate(false);
  const [deSelectedContent, setDeSelectedContent] = useState<any>(false);
  const [openDuplicate, setOpenDuplicate] = useState<any>(false);
  const [duplicates, setDuplicates] = useState<any>();
  const [confirmDeselect, setConfirmDeselect] = useState(false);
  const [allGradeContents, setAllgradeContents] = useState(
    selectedQuizzes.concat(selectedVideos),
  );
  const filteredQuizzes = allGradeContents?.filter((obj: any) => obj.selected);
  const filtered = [...data];
  const filteredFinal = data
    ?.map((obj: any) => {
      const subTopics = { ...obj.subTopics };
      Object.keys(subTopics).forEach((key: any) => {
        const skills = { ...subTopics[key].skills };
        Object.keys(skills).forEach((skillKey) => {
          if (!skills[skillKey].selected) {
            delete skills[skillKey];
          }
        });
        subTopics[key].skills = skills;
      });
      obj.subTopics = subTopics;
      return obj;
    })
    ?.filter((obj: any) => {
      const subTopics = { ...obj.subTopics };
      Object.keys(subTopics).forEach((key) => {
        if (Object.keys(subTopics[key].skills).length === 0) {
          delete subTopics[key];
        }
      });
      obj.subTopics = subTopics;
      return Object.keys(subTopics).length > 0;
    });

  const filteredArray = filteredFinal.flatMap((template: any) =>
    Object.values(template.subTopics).flatMap((subTopic: any) =>
      Object.values(subTopic.skills).map((skill: any) => ({
        templateId: skill.templates[0].id,
        questionsCount: skill.count,
      })),
    ),
  );
  console.log('selectedContent', filtered);
  console.log('selectedContent', filteredQuizzes);
  console.log('selectedContent', filteredFinal);
  console.log('selectedContent', filteredArray);
  console.log('selectedContent', filtered.length);
  console.log('selectedContent', checkSkillWithCountAndSelectedTrue(filtered));

  const quizzesDaily = filteredQuizzes?.filter(
    (item: any) => item.type === 'quiz',
  );
  const videosDaily = filteredQuizzes?.filter(
    (item: any) => item.type === 'video',
  );

  let allDailyVideoContents = videosDaily?.map((item: any) => {
    return {
      templateId: item.id,
      questionsCount: item.count,
    };
  });
  let allDailyVideoIds = [
    {
      type: 'Videos',
      ids: allDailyVideoContents.map((item: any) => item.templateId),
    },
  ];
  let allDailyTemplates = quizzesDaily?.map((item: any) => {
    return {
      templateId: item.id,
      questionsCount: item.count,
    };
  });
  let allDailyTemplateIds = [
    {
      type: 'Templates',
      ids: allDailyTemplates.map((item: any) => item.templateId),
    },
  ];

  let allQuizTemplates = filteredQuizzes.map((item: any) => {
    return {
      templateId: item.id,
      questionsCount: item.count,
    };
  });
  console.log('allQuizTemplates', formData);
  let dailyQuizTemplatesInstructional = [
    {
      type: 'Templates',
      ids: filteredArray.map((item: any) => item.templateId),
    },
  ];
  let allDailyContentIdsArray =
    dailyQuizTemplatesInstructional.concat(allDailyTemplateIds);
  let allDailyTemplatesIds = {
    type: 'Templates',
    ids: allDailyContentIdsArray.flatMap((item: any) => item.ids),
  };
  const urlParams = new URLSearchParams(window.location.search);
  const assignmentType = urlParams.get('assignmentType');

  const schoolId = urlParams.get('schoolId');
  const classroomId = urlParams.get('classroomId');
  localStorage.setItem('current_classroomId', classroomId || '');
  localStorage.setItem('teacherSchool', schoolId || '');

  const { studentListByGradeIdTopicId } = useAppSelector(
    (state: any) => state.studentReducer,
  );
  const ClassroomData = useAppSelector(
    (state: any) => state.classroomReducer.classroomID,
  );
  const assignContents = async () => {
    const data =
      assignmentType === 'Daily_Practice'
        ? {
            startDate: formData?.startDate || '',
            endDate: formData?.dueDate || '',
            assignedById: formData?.assignedById || '',
            userIds:
              formData?.grade?.grade?.id && formData?.type === 'Daily_Practice'
                ? studentListByGradeIdTopicId
                : formData?.grade?.grade?.id
                ? []
                : formData?.userIds?.GroupStudent?.length > 0
                ? formData?.userIds?.GroupStudent?.map(
                    (user: any) => user.userId,
                  )
                : !(formData?.userIds?.length > 0)
                ? [formData?.userIds.userId]
                : formData?.userIds?.map((user: any) => user.userId) || [],
            contentSelection: allDailyVideoIds.concat(allDailyTemplatesIds),
          }
        : {
            type: formData?.type || '',
            startDate: formData?.startDate || '',
            dueDate: formData?.dueDate || '',
            userIds:
              formData?.grade?.grade?.id && formData?.type === 'Daily_Practice'
                ? studentListByGradeIdTopicId
                : formData?.grade?.grade?.id
                ? []
                : formData?.userIds?.GroupStudent?.length > 0
                ? formData?.userIds?.GroupStudent?.map(
                    (user: any) => user.userId,
                  )
                : !(formData?.userIds?.length > 0)
                ? [formData?.userIds.userId]
                : formData?.userIds?.map((user: any) => user.userId) || [],
            templates: allQuizTemplates.concat(filteredArray),
            gradeId: formData?.grade?.grade?.id || '',
            topicId: formData?.topic?.id || '',
            classroomId: formData?.classroomId || '',
            assignedById: formData?.assignedById || '',
            isPinCode: formData?.isPinCode,
            timeDuration: formData?.timeDuration || '',
            isDoubleTime: formData?.isDoubleTime,
          };
    console.log(data);

    // console.log(allDailyTemplatesIds);
    await dispatch(assignContent(data))
      .then((action: any) => {
        const res = action.payload;
        console.log('resres', res);
        if (!res?.data?.data?.error) {
          window.history.go(-1);
        } else {
          setDuplicates(res?.data?.data?.duplicatedUserDetails);
          setOpenDuplicate(true);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };
  console.log('formData', allDailyVideoIds.concat(allDailyTemplatesIds));
  console.log('formData', allQuizTemplates);
  console.log('formData', filteredArray);
  console.log('resres', duplicates);
  let videoLength =
    assignmentType === 'Daily_Practice'
      ? allDailyVideoIds.concat(allDailyTemplatesIds)[0]?.ids?.length
      : 0;
  let QuizLength =
    assignmentType === 'Daily_Practice'
      ? allDailyVideoIds.concat(allDailyTemplatesIds)[1]?.ids?.length
      : allQuizTemplates.concat(filteredArray)?.length;
  // let schoolVideoLength = assignmentType === 'Daily_Practice'?
  // let dailyVideoLength = assignmentType === 'Daily_Practice'?
  const handleCloseDeselect = (itemID: string) => {
    setDeSelectedContent(false);
    setOpenDuplicate(false);
    setConfirmDeselect(true);
    setSelectedItems((value) => value.filter((item) => item !== itemID));

    setAllgradeContents((prevQuizzes: any) =>
      prevQuizzes.filter((item: any) => item.id !== itemID),
    );
    // handleAssignContent();
  };
  const dispatch = useAppDispatch();

  const navigate: any = useNavigate();
  const [pageCount, setPageCount] = useState(0);
  const [quizzes, setQuizzes] = useState<any[]>([]);

  const [videos, setVideos] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([
    '05d27c4e-2b07-4170-89bd-1cd247718e4a',
    '047ca921-0009-4ff9-a686-504b97e7e355',
    '0402cece-cdc7-4c17-b089-f0185fa519a5',
  ]);
  const [foundedItems, setFoundedItems] = useState<any>({});
  const [pageNo, setPageNo] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const { videoList } = useAppSelector((state: any) => state.videoReducer);

  useEffect(() => {
    setPageCount(videoList?.meta?.lastPage);
    setTotalCount(videoList?.meta?.total);
  }, []);
  function handleInputChange(id: number, value: string) {
    if (parseInt(value) > 0)
      setAllgradeContents((prevState: any) => {
        return prevState.map((obj: any) => {
          if (obj.id === id && value <= obj.questionCount) {
            return { ...obj, count: parseInt(value) };
          } else if (obj.id === id) {
            setIsOpenQuestionLimit(true);
          }
          return obj;
        });
      });
    else {
      setAllgradeContents((prevState: any) => {
        return prevState.map((obj: any) => {
          if (obj.id === id) {
            return { ...obj, count: 0 };
          }
          return obj;
        });
      });
    }
  }
  function handleCountChange(id: number, text: string) {
    const increase = text === 'increment';
    // Find the object in the array with the matching id
    if (increase) {
      setAllgradeContents((prevState: any) => {
        return prevState.map((obj: any) => {
          if (obj.id === id && obj.count < obj.questionCount) {
            return { ...obj, count: obj.count + 1 };
          } else if (obj.id === id && increase) {
            setIsOpenQuestionLimit(true);
          }
          return obj;
        });
      });
    } else {
      setAllgradeContents((prevState: any) => {
        return prevState.map((obj: any) => {
          if (obj.id === id && obj.count > 0) {
            return { ...obj, count: obj.count - 1 };
          }
          return obj;
        });
      });
    }

    // Output the updated array for demonstration
  }
  const fetchData = useCallback(({ pageSize, pageIndex, search }: any) => {
    setPageNo(pageIndex);
    dispatch(startLoading());
    dispatch(getVideoList({ pageSize, pageIndex, search, isAcademic: true }));
  }, []);
  function onConfirmDeselect(
    itemID: string,
    e: React.ChangeEvent<HTMLInputElement>,
  ) {
    handleDeselect();
  }
  const onItemSelect = useCallback(
    (itemID: string, e: ChangeEvent<HTMLInputElement>) => {
      setAllgradeContents((prevState: any) => {
        return prevState.map((obj: any) => {
          if (obj.id === itemID) {
            return { ...obj, selected: e.target.checked };
          }
          return obj;
        });
      });
    },
    [],
  );
  const columns = useMemo(
    () => [
      {
        Header: 'Grade',
        accessor: 'grade',
        Cell: ({ value }: any) => {
          if (value.length) {
            return <p>{displayMultipleValue(value, 'grade')}</p>;
          }
        },
      },
      {
        Header: 'Title',
        accessor: 'title', // accessor is the "key" in your data
        Cell: ({ value }: any) => {
          if (value?.length) {
            return <ReadMore text={value || '-'} />;
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Content Area',
        accessor: 'topic',
        Cell: ({ value }: any) => {
          if (value.length) {
            return (
              <DefaultCell
                value={displayMultipleValue(value, 'topic') || '-'}
              />
            );
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Domain',
        accessor: 'subTopic',
        Cell: ({ value }: any) => {
          if (value?.length) {
            return (
              <ReadMore
                text={
                  `${value[0]?.subTopic?.sequenceNumber} - ${value[0]?.subTopic?.name}` ||
                  '-'
                }
              />
            );
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Skill',
        accessor: 'skill',
        Cell: ({ row, value }: any) => {
          if (value && value?.length > 0 && value[0]?.skill) {
            const skillInfo = value[0].skill;
            const displayText = `${skillInfo.sequenceNumber} - ${
              skillInfo.name || '-'
            }`;

            return <DefaultCell value={displayText || ''} />;
          } else {
            return '-';
          }
        },
      },
      assignmentType !== 'Daily_Practice'
        ? {
            Header: 'No of Questions',
            accessor: 'assigned',
            Cell: ({ row }) => {
              return (
                <div className="flex items-center justify-center px-4 py-1 border-gray-400 border rounded-lg">
                  <button
                    className="text-2xl text-gray-500"
                    onClick={() =>
                      handleCountChange(row.original.id, 'decrement')
                    }
                  >
                    -
                  </button>
                  <input
                    type="text"
                    style={{
                      width: '30px',
                      border: 'none',
                      outline: 'none',
                      textAlign: 'center',
                    }}
                    value={row.original.count}
                    onChange={(e) =>
                      handleInputChange(row.original.id, e.target.value)
                    }
                  />
                  <div className="mr-1">
                    {' '}
                    / {row?.original?.questionCount || 0}
                  </div>
                  <button
                    className="text-2xl text-gray-500"
                    onClick={() =>
                      handleCountChange(row.original.id, 'increment')
                    }
                  >
                    +
                  </button>
                </div>
              );
            },
          }
        : { Header: '', accessor: '0' },
      {
        Header: '',
        accessor: 'selected',
        Cell: ({ row }) => {
          return (
            <div className="flex flex-1 justify-center">
              <Checkbox
                onChange={(e) => onItemSelect(row.original?.id, e)}
                sx={{
                  '&.Mui-checked': {
                    color: '#C73E9D',
                  },
                  margin: '10px',
                }}
                checked={row.original.selected}
              />
            </div>
          );
        },
      },
    ],
    [selectedItems],
  );
  console.log('ClassroomData', ClassroomData);
  let rows = useMemo(() => {
    const data = quizzes?.map((item: any) => ({
      id: item.id,
      title: item.title,
      topic: item.topic,
      subTopic: item?.subTopic,
      grade: item.grade,
      skill: item.skill,
      date: item.createdAt ? getLocalDateTime(item.createdAt) : '-',
      selected: selectedItems.includes(item.id),
      alreadyAssigned:
        foundedItems?.existingTemplateIds?.includes(item.id) || false,
      count: item.count,
    }));

    return data;
  }, [quizzes, selectedItems, quizzes, foundedItems, selectedId]);

  const allAssignments = [
    {
      dailyPracticeAssignments: [
        {
          id: 1,
          templates: [{ name: 'a' }, { name: 'b' }],
        },
        {
          id: 2,
          templates: [{ name: 'c' }, { name: 'd' }],
        },
      ],
      schoolAssignments: [
        {
          id: 1,
          templates: [{ name: 'a' }, { name: 'b' }],
        },
        {
          id: 2,
          templates: [{ name: 'c' }, { name: 'd' }],
        },
      ],
    },
  ];
  function checkSkillWithCountAndSelectedTrue(data: any) {
    for (const subTopicKey in data.subTopics) {
      const skills = Object.values(data.subTopics[subTopicKey].skills);
      if (skills.some((skill: any) => skill.count > 0 && skill.selected)) {
        return true;
      }
    }
    return false;
  }
  const breadcrumbs = [
    <Link to={''} key="1" color="inherit">
      School Management
    </Link>,
    <Link to={''} key="1" color="inherit">
      Classroom
    </Link>,
    <Link to={''} key="1" color="inherit">
      {ClassroomData?.classroom?.grade?.name || ''}
    </Link>,
    <Typography
      key="2"
      sx={{ cursor: 'pointer', color: 'inherit' }}
      onClick={() => navigate(-1)}
    >
      {ClassroomData?.classroom?.name || ''} - Assignments
    </Typography>,
    <Typography key="2" color="#C73E9D">
      Confirmation of Assignments
    </Typography>,
  ];
  const header = [{ label: '01 Quarter', width: 1, value: 'name' }];
  const color = '#E9F6F3';

  return (
    <div>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        style={{ marginBottom: 20 }}
      >
        {breadcrumbs}
      </Breadcrumbs>
      <Container>
        <Card>
          <ContentWrapper className="!w-full !flex-col !gap-0">
            <Stack gap={8} mb={8} className=" w-full flex-1">
              <Typography
                sx={{
                  color: '#20A58A',
                  fontSize: '23px',
                  fontWeight: '700',
                  lineHeight: '23px',
                }}
              >
                Summary
              </Typography>
              <Stack className="!flex-row justify-between w-full flex-1">
                <Stack className="gap-4">
                  <Typography className="!font-normal !text-sm">
                    Assign To
                  </Typography>
                  <Typography className="!font-medium capitalize !text-lg !text-black">
                    {formData?.assignTo}
                  </Typography>
                </Stack>
                <Stack className="gap-4">
                  <Typography className="!font-normal !text-sm">
                    Assignment Type
                  </Typography>
                  <Typography className="!font-medium !text-lg !text-black">
                    {formData?.type?.replace(/_/g, ' ')}
                  </Typography>
                </Stack>
                <Stack className="gap-4">
                  <Typography className="!font-normal !text-sm">
                    Start Date
                  </Typography>
                  <Typography className="!font-medium !text-lg !text-black">
                    {getLocalDateTime(formData?.startDate, 'MMMM D, YYYY')}
                  </Typography>
                </Stack>
                <Stack className="gap-4">
                  <Typography className="!font-normal !text-sm">
                    End Date
                  </Typography>
                  <Typography className="!font-medium !text-lg !text-black">
                    {getLocalDateTime(formData?.dueDate, 'MMMM D, YYYY')}
                  </Typography>
                </Stack>
                <Stack className="gap-4">
                  <Typography className="!font-normal !text-sm">
                    Time Duration
                  </Typography>
                  <Typography className="!font-medium !text-lg !text-black">
                    {formData?.isDoubleTime
                      ? formData?.timeDuration * 2
                      : formData?.timeDuration}
                  </Typography>
                </Stack>
                <Stack className="gap-4">
                  <Typography className="!font-normal !text-sm">
                    Videos
                  </Typography>
                  <Typography className="!font-medium !text-lg !text-black">
                    {videoLength}
                  </Typography>
                </Stack>
                <Stack className="gap-4">
                  <Typography className="!font-normal !text-sm">
                    Quiz
                  </Typography>
                  <Typography className="!font-medium !text-lg !text-black">
                    {QuizLength}
                  </Typography>
                </Stack>
                <Stack className="gap-4">
                  <Typography className="!font-normal !text-sm">
                    Pin Activated
                  </Typography>
                  <Typography className="!font-medium !text-lg !text-black">
                    {formData?.isPinCode ? 'YES' : 'No'}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            {filtered.length ? (
              <TableWrapper title="Instractional Calendar">
                <Box className="m-2">
                  <Instructional
                    color={color}
                    openDefault={true}
                    confirmation={true}
                    classes={filtered}
                    data={filtered}
                    setData={setData}
                    header={header}
                  />
                </Box>
              </TableWrapper>
            ) : (
              <></>
            )}
            {filteredQuizzes.length ? (
              <TableWrapper title="All Grades Content ">
                <CustomTable
                  table={{
                    columns,
                    rows: filteredQuizzes,
                  }}
                  handleDeselect={handleDeselect}
                  fetchData={fetchData}
                  variant="borderLess"
                  canSearch={false}
                  searchText="Search..."
                  pageCount={pageCount}
                  pageNo={pageNo}
                  totalCount={totalCount}
                  color="#929292"
                  hidePagination={true}
                />
              </TableWrapper>
            ) : (
              <></>
            )}
            <Box className="flex justify-end gap-4 w-full mt-8">
              <SharedButton
                variant="outlined"
                name="Back"
                className="primary_bg_btn_outlined"
                onClick={() => setConfirm(false)}
              />
              <SharedButton
                onClick={assignContents}
                variant="contained"
                name="Assign"
                className="primary_bg_btn"
              />
            </Box>
          </ContentWrapper>
        </Card>
      </Container>
      <Duplicate
        open={openDuplicate}
        duplicates={duplicates}
        itemID={selectedId}
        onClose={() => handleCloseDeselect(selectedId)}
        onCancel={handleOpenDuplicate}
      />
      <AssignDeselect
        open={deSelectedContent}
        itemID={selectedId}
        onClose={() => handleCloseDeselect(selectedId)}
        onCancel={handleDeselectCancel}
      />
    </div>
  );
}
