import * as React from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import StudentCard from './student';
import Nodata from 'components/v2_components/Admin_Panel_Dashboards/skills_chart/components/noData';
// import {StudInfo} from './student'
const selectStyles = {
  color: '#C73E9D',
  fontWeight: '600',
  '& .MuiSvgIcon-root': {
    color: '#C73E9D',
  },
  '& fieldset': {
    borderColor: '#C73E9D !important',
  },
  '&:hover fieldset': {
    borderColor: '#C73E9D',
  },
};
const Students = ({
  title,
  select,
  select2,
  students,
}: {
  title: string;
  select: string[];
  select2?: string[];
  students: any[];
}) => {
  const elements = new Array(12).fill(null);
  console.log('students', students);
  return (
    <Card sx={{ my: 6, p: 4 }}>
      <Stack spacing={2}>
        {/*horizontal stack*/}
        {/* stack for title items */}
        <Stack
          p={2}
          sx={{ mx: 2, pt: 1 }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography
            style={{ fontWeight: '600', fontSize: '20px', color: '#131515' }}
            display="block"
            gutterBottom
          >
            {title}
          </Typography>
          {/* {students.length > 0 && (
            <Stack
              p={2}
              sx={{ mx: 2, pt: 1 }}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Select
                size="small"
                sx={selectStyles}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={1}
                label="Age"
                // onChange={handleChange}
              >
                {select?.map((item) => (
                  <MenuItem value={1}>{item}</MenuItem>
                ))}
              </Select>
              <Select
                size="small"
                sx={selectStyles}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={1}
                label="Age"
                // onChange={handleChange}
              >
                {select2?.map((item) => (
                  <MenuItem value={1}>{item}</MenuItem>
                ))}
              </Select>
            </Stack>
          )} */}
        </Stack>
        {!(students.length > 0) ? (
          <Nodata type={1} />
        ) : (
          <Grid
            container
            my={4}
            spacing={4}
            style={{ overflow: 'auto', maxHeight: '420px', minHeight: '300px' }}
          >
            {students?.map((element, index) => (
              <Grid key={index} item lg={2} md={3} sm={4} xs={12}>
                <StudentCard stud={element} />
              </Grid>
            ))}
          </Grid>
        )}
      </Stack>
    </Card>
  );
};
export default Students;
