// *** React Imports
import React from "react";
import { Typography, Breadcrumbs, Link, Card } from "@mui/material";

// *** Custom Components
import EditDetailVideo from "components/video/editDetail";

// *** Icons
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { route_info } from "navigation/route";
// *** Third Party Imports
import { useNavigate } from "react-router-dom";

function Index() {
  const handleBreadCrumbClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href={route_info.content_management.video}
      onClick={() => handleBreadCrumbClick}
    >
      Resources
    </Link>,
    <Typography key="2" color="inherit">
      Video
    </Typography>,
    <Typography key="2" color="#C73E9D">
      Detail
    </Typography>,
  ];

  const navigate: any = useNavigate();
  const handleCancel = ({ add, edit, detail }: any) => {
    if (edit || detail) {
      navigate(route_info.content_management.video);
    }
  }

  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        style={{ marginBottom: 20 }}
      >
        {breadcrumbs}
      </Breadcrumbs>
      <Card
        sx={{
          p: "25px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <EditDetailVideo saveTitle="Save" detail handleCancel={handleCancel} />
      </Card>
    </>
  );
}

export default Index;
