export default function Success() {
  return (
    <svg
      width="59"
      height="58"
      viewBox="0 0 59 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_7842_29714"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="59"
        height="58"
      >
        <path
          d="M29.5 56.4999C33.112 56.5046 36.6893 55.7954 40.0264 54.4131C43.3635 53.0308 46.3944 51.0026 48.9452 48.4452C51.5026 45.8944 53.5308 42.8635 54.9131 39.5264C56.2954 36.1893 57.0046 32.612 56.9999 29C57.0045 25.3879 56.2953 21.8106 54.913 18.4736C53.5307 15.1365 51.5026 12.1055 48.9452 9.55475C46.3944 6.9973 43.3635 4.96914 40.0264 3.58684C36.6893 2.20453 33.112 1.49534 29.5 1.50002C25.8879 1.49542 22.3106 2.20464 18.9736 3.58694C15.6365 4.96924 12.6055 6.99736 10.0548 9.55475C7.49736 12.1055 5.46924 15.1365 4.08694 18.4736C2.70464 21.8106 1.99542 25.3879 2.00002 29C1.99534 32.612 2.70453 36.1893 4.08684 39.5264C5.46914 42.8635 7.4973 45.8944 10.0548 48.4452C12.6055 51.0026 15.6365 53.0307 18.9736 54.413C22.3106 55.7953 25.8879 56.5045 29.5 56.4999Z"
          fill="white"
          stroke="white"
          stroke-width="2.72164"
          stroke-linejoin="round"
        />
        <path
          d="M18.5 29.001L26.75 37.2509L43.2499 20.751"
          stroke="black"
          stroke-width="4.76288"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </mask>
      <g mask="url(#mask0_7842_29714)">
        <path d="M-3.5 -4H62.4998V61.9998H-3.5V-4Z" fill="#20A58A" />
      </g>
    </svg>
  );
}
