// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.class_list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.count_badge {
  background-color: #20a58a;
  color: white;
  border-radius: "3px";
  padding: 2px 4px;
  text-align: center;
  border-radius: 2px;
  right: 0px;
  bottom: 8px;
}

.badge_head {
  position: relative;
  margin-left: 10px;
  margin-top: 60%;
  height: 40px;
}

.status_button {
  background: #ddfaec;
}`, "",{"version":3,"sources":["webpack://./src/pages/super_admin/user_management/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;AACF;;AAEA;EACE,yBAAA;EACA,YAAA;EACA,oBAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;EAEA,UAAA;EACA,WAAA;AAAF;;AAGA;EACE,kBAAA;EACA,iBAAA;EAEA,eAAA;EACA,YAAA;AADF;;AAIA;EACE,mBAAA;AADF","sourcesContent":[".class_list {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n}\n\n.count_badge {\n  background-color: #20a58a;\n  color: #ffff;\n  border-radius: '3px';\n  padding: 2px 4px;\n  text-align: center;\n  border-radius: 2px;\n\n  right: 0px;\n  bottom: 8px;\n}\n\n.badge_head {\n  position: relative;\n  margin-left: 10px;\n\n  margin-top: 60%;\n  height: 40px;\n}\n\n.status_button {\n  background: #ddfaec;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
