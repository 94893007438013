import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Typography,
  Divider,
  Card,
  Avatar,
  IconButton,
  Stack,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { getClassroomsOfStudent } from 'store/Thunk/classroomThunk';
import { useAppDispatch, useAppSelector } from 'store/store';

import style from './view.module.scss';
import SimpleTable from 'components/simpleTable/simpleTable';
import { DeleteBorderIcon, EditBorderIcon, EditIcon } from 'icons';
import { ClassItem, TypoItem } from './macros';
import { getUserData } from 'store/Thunk/user';
import ViewLoaderBox from './loader';
import { getLinkPartFromRole } from 'helper/functions';
import { setUser } from 'store/Slice/userSlice';
import DeleteUserDialog from 'components/dialog/users/Delete/deleteUser';

import { getUserListAccordingToType } from 'store/Thunk/userThunk';
import { deleteStudent } from 'store/Thunk/studentThunk';

function Student() {
  const dispatch = useAppDispatch();
  const { user, loading } = useAppSelector((state) => state.userReducer);
  console.log('user', user);
  const [classList, setClassList] = useState([]);
  const navigate = useNavigate();
  const initial = getLinkPartFromRole();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [studentId] = useState(queryParams.get('id'));
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedUserToDelete, setSelectedUserToDelete] = useState({
    id: user.id,
    name: user.name,
  });
  useEffect(() => {
    if (studentId) {
      dispatch(getUserData(studentId));
    }
    return () => {
      dispatch(setUser([]));
    };
  }, []);

  const getRows = () => {
    const data = user?.classroom?.map((classroom: any) => {
      const classroomName = classroom?.classroom?.name || '-';

      const teachers =
        classroom?.classroom?.ClassroomTeacher?.map((teacher: any) => ({
          Name: teacher.user.name || '-',
          Phone: teacher.user.phone || '-',
          Subject: teacher.user.subject || '-',
          Class: classroomName,
        })) || [];

      return teachers;
    });

    const flattenData = data ? data.flat() : [];
    return flattenData;
  };

  const handleEditClick = () => {
    const url = `/${initial}/user-management/edit?mode=student&id=${studentId}`;
    navigate(url);
  };

  if (loading) {
    return <ViewLoaderBox allowShadow />;
  }
  const fetchTableData = () => {
    dispatch(
      getUserListAccordingToType({
        pageSize: 10,
        pageIndex: 0,
        search: '',
        userType: 'student',
      }),
    );
  };
  const handleDeleteUser = () => {
    setDeleteLoading(true);
    dispatch(deleteStudent(selectedUserToDelete.id))
      .then(() => {
        fetchTableData();
        navigate(`/${initial}/user-management?tab=2`);
      })
      .finally(() => {
        setOpenDeleteModal(false);
        setDeleteLoading(false);
        setSelectedUserToDelete({
          id: '',
          name: '',
        });
      });
  };
  return (
    <Stack sx={{ p: 4, position: 'relative' }} spacing={2}>
      <Grid sx={{ position: 'relative' }} container spacing={2}>
        <Card
          sx={{
            p: 7,
            gap: '10px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box className={style.content_wrapper}>
            {user?.profileImage ? (
              <img
                className={style.profile_img}
                alt={user?.name}
                src={user?.profileImage}
              />
            ) : (
              <Avatar sx={{ width: 120, height: 120, alignitems: 'center' }} />
            )}
            <div className={style.profile_detail_wrapper}>
              <Box className={style.profile_detail}>
                <Typography
                  sx={{ color: '#20A58A', fontWeight: 700, fontSize: '23px' }}
                >
                  {user?.name}
                </Typography>
                <Typography sx={{ display: '13.48px' }}>
                  (ID: {user?.id})
                </Typography>
              </Box>
              <Box className={style.info}>
                <TypoItem title="Email" detail={user?.email} />
                <TypoItem title="Grade" detail={user?.grade_?.name} />
                <TypoItem title="Parent" detail={user?.parent?.name} />
                <TypoItem
                  title="School Name"
                  detail={user?.school?.name || '-'}
                />
              </Box>
            </div>
            <Box
              sx={{ display: 'flex', position: 'absolute', top: 30, right: 30 }}
            >
              <IconButton onClick={() => handleEditClick()}>
                <EditBorderIcon />
              </IconButton>

              <IconButton
                onClick={() => {
                  setOpenDeleteModal(true);
                  setSelectedUserToDelete({
                    id: user.id,
                    name: user.name,
                  });
                }}
              >
                <DeleteBorderIcon />
              </IconButton>
            </Box>
          </Box>
          <Divider />
          <Box>
            <Typography sx={{ color: '#929292' }}>Classrooms</Typography>
            <Box className={style.classroom_list}>
              {user.classroom?.length > 0 &&
                user.classroom?.map((item: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <ClassItem
                        title={
                          item?.classroom?.name?.includes(':')
                            ? item?.classroom?.name?.split(':')[1]
                            : item?.classroom?.name
                        }
                      />
                    </React.Fragment>
                  );
                })}
            </Box>
          </Box>
        </Card>
      </Grid>
      <Card sx={{ p: 7 }}>
        <SimpleTable
          title="Teachers"
          rows={getRows()}
          columns={['Name', 'Phone', 'Subject', 'Class']}
        />
      </Card>
      <DeleteUserDialog
        loading={deleteLoading}
        open={openDeleteModal}
        title={`Are you sure you want to delete the user "${selectedUserToDelete?.name}" from G3MS?`}
        onOk={handleDeleteUser}
        onClose={() => {
          setOpenDeleteModal(false);
          setSelectedUserToDelete({
            id: '',
            name: '',
          });
        }}
      />
    </Stack>
  );
}

export default Student;
