import { createAsyncThunk } from '@reduxjs/toolkit';
import API from 'store/api';
import { startLoading, stopLoading } from 'store/Slice/loadingSlice';
import { notificationFail } from 'store/Slice/notificationSlice';
import { setSchoolLeaderList } from 'store/Slice/schoolLeaderSlice';

export const getSchoolLeaderList = createAsyncThunk(
  'getUserList',
  async (_, { dispatch }) => {
    let url = `/api/v1/school-leader`;
    dispatch(startLoading());
    try {
      const response: any = await API.get(url);
      dispatch(setSchoolLeaderList(response.data.data));
      dispatch(stopLoading());
      return response;
    } catch (err: any) {
      dispatch(stopLoading());
      dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);
export const updateSchoolLeader = createAsyncThunk(
  'updateSchoolLeader',
  async (_request: any, { dispatch }) => {
    const {
      name,
      username,
      email,
      classrooms,
      school,
      city,
      totalNoOfTeacher,
      districtId,
      uuid,
      gradeIds,
      phone,
      teachMethod,
      salutation,
      topicIds,
      zipCode,
    } = _request;
    let url = `api/v1/school-leader/${uuid}`;
    try {
      const formData = new FormData();

      if (_request?.file) {
        formData.append('file', _request?.file);
      }

      formData.append('name', name);
      formData.append('username', username);
      formData.append('city', city);
      formData.append('districtId', districtId);
      formData.append('totalNoOfTeacher', totalNoOfTeacher);
      formData.append('schoolId', school);
      API.patch(url, formData);
    } catch (err: any) {
      dispatch(notificationFail(err.response.data.message));
    }
  },
);
