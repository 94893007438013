// ** MUI Imports
import { Theme } from '@mui/material/styles';
import { Skin } from '../../layouts/types';

const Autocomplete = (theme: Theme, skin: Skin) => {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          backgroundColor: '#FFF',
          boxShadow: theme.shadows[6],
          ...(skin === 'bordered' && {
            boxShadow: 'none',
            border: `1px solid ${theme.palette.divider}`,
          }),
        },
      },
    },
  };
};

export default Autocomplete;
