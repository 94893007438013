import SimpleLoader from '@core/components/MDLoader/simpleLoader';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import useSchoolClassroomInfo from 'hooks/useSchoolClassroomInfo';
import WarningIcon from 'icons/warning';
import { useState } from 'react';
import {
  deleteAssignment,
  deleteSchoolAssignment,
  getAllAssignments,
} from 'store/Thunk/assignContentThunk';
import { deleteDistrict } from 'store/Thunk/districtThunk';
import { useAppDispatch } from 'store/store';
interface DialogProps {
  open: boolean;
  deleteId: any;
  setAssignmentsList: any;
  onClose: (id: string) => void;
}

export default function DeleteConfirmation(props: DialogProps) {
  const { onClose, deleteId, open, setAssignmentsList } = props;
  const dispatch = useAppDispatch();
  const urlParams = new URLSearchParams(window.location.search);

  const classroomId = urlParams.get('classroomId');

  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    onClose('');
  };

  const onDelete = (id: string) => {
    let contentType = deleteId?.video?.id
      ? 'video'
      : deleteId?.template?.id
      ? 'template'
      : 'dailyChallenges';
    let schoolAssignmentId = deleteId?.sAssignmentId;
    let dailyAssignmentId = deleteId?.assignmentId;
    let contentId = deleteId?.video?.id
      ? deleteId?.video.id
      : deleteId?.template?.id
      ? deleteId?.template?.id
      : deleteId?.dailyChallenges?.id;
    let templateId = deleteId?.template?.id;

    setLoading(true);
    console.log(id);
    if (!schoolAssignmentId) {
      dispatch(
        deleteAssignment({ dailyAssignmentId, contentId, contentType }),
      ).then((res) => {
        setLoading(false);
        dispatch(getAllAssignments({ id: classroomId }))
          .unwrap()
          .then((res) => {
            setAssignmentsList(res.items);
            console.log('assignmentsList', res);
          });

        handleClose();
      });
    } else {
      dispatch(deleteSchoolAssignment({ schoolAssignmentId, templateId })).then(
        (res) => {
          setLoading(false);
          dispatch(getAllAssignments({ id: classroomId }))
            .unwrap()
            .then((res) => {
              setAssignmentsList(res.items);
              console.log('assignmentsList', res);
            });

          handleClose();
        },
      );
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" onClose={handleClose} open={open}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        py={6}
      >
        <WarningIcon />
        <Typography variant="h6" mt={3}>
          Are you sure you want to delete this assignment?
        </Typography>
        <Stack direction={'row'} spacing={4} mt={4}>
          <Button
            fullWidth
            size="small"
            variant="contained"
            className="dialog_grey_button"
            onClick={() => onClose('')}
          >
            No
          </Button>
          <Button
            fullWidth
            size="small"
            variant="contained"
            className="dialog_red_button"
            onClick={() => onDelete(deleteId)}
            disabled={loading}
          >
            {loading ? <SimpleLoader size={24} color={'secondary'} /> : 'Yes'}
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
}
