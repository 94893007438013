const buttonGroup = {
  MuiButtonGroup: {
    styleOverrides: {
      root: {
        borderRadius: 8
      }
    }
  }
}
export default buttonGroup;
