import { createAsyncThunk } from '@reduxjs/toolkit';
import API from 'store/api';
import { startLoading, stopLoading } from 'store/Slice/loadingSlice';
import { notificationFail } from 'store/Slice/notificationSlice';
import {
  setSchoolAssignmentList
} from 'store/Slice/schoolAssignmentSlice';

export const getSchoolAssignments = createAsyncThunk(
  'getSchoolAssignments',
  async (classroomId: string, { dispatch, rejectWithValue }) => {
    let url = `api/v1/assignment/school-assignment/${classroomId}`;
    dispatch(startLoading());
    try {
      const response: any = await API.get(url);
      dispatch(setSchoolAssignmentList(response.data.data));
      dispatch(stopLoading());
      return response;
    } catch (err: any) {
      dispatch(stopLoading());
      dispatch(notificationFail(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  },
);

export const initSchoolAssignment = createAsyncThunk(
  'initSchoolAssignment',
  async (assignmentId: string, { dispatch, rejectWithValue }) => {
    let url = `api/v1/assignment/school-assignment/initiate/${assignmentId}`;
    dispatch(startLoading());
    try {
      await API.post(url);
      dispatch(stopLoading());
    } catch (err: any) {
      dispatch(stopLoading());
      dispatch(notificationFail(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  },
);

export const startSchoolAssignment = createAsyncThunk(
  'startSchoolAssignment',
  async (assignmentId: string, { dispatch, rejectWithValue }) => {
    let url = `api/v1/assignment/school-assignment/start/${assignmentId}`;
    dispatch(startLoading());
    try {
      await API.post(url);
      dispatch(stopLoading());
    } catch (err: any) {
      dispatch(stopLoading());
      dispatch(notificationFail(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  },
);

export const pauseSchoolAssignment = createAsyncThunk(
  'pauseSchoolAssignment',
  async (assignmentId: string, { dispatch, rejectWithValue }) => {
    let url = `api/v1/assignment/school-assignment/pause/${assignmentId}`;
    dispatch(startLoading());
    try {
      await API.post(url);
      dispatch(stopLoading());
    } catch (err: any) {
      dispatch(stopLoading());
      dispatch(notificationFail(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  },
);

export const cancelSchoolAssignment = createAsyncThunk(
  'cancelSchoolAssignment',
  async (assignmentId: string, { dispatch, rejectWithValue }) => {
    let url = `api/v1/assignment/school-assignment/cancel/${assignmentId}`;
    dispatch(startLoading());
    try {
      await API.post(url);
      dispatch(stopLoading());
    } catch (err: any) {
      dispatch(stopLoading());
      dispatch(notificationFail(err.response.data.message));
      return rejectWithValue(err.response.data);
    }
  },
);
