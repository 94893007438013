import { createAsyncThunk } from '@reduxjs/toolkit';
import API from 'store/api';
import { startLoading, stopLoading } from 'store/Slice/loadingSlice';
import {
  notificationFail,
  notificationSuccess,
} from 'store/Slice/notificationSlice';
import {
  setGeneratedQuestions,
  setQuestionList,
  startLoadingAI,
  stopLoadingAI,
} from 'store/Slice/QuestionSlice';
import { setScreenLoading } from 'store/Slice/userSlice';

export const createQuestion = createAsyncThunk(
  'createQuestion',
  async (formData: any, { dispatch }) => {
    let url = formData._request?.id
      ? `/api/v1/questions/${formData._request?.id}`
      : `/api/v1/questions/`;
    try {
      if (formData?._request?.id) {
        dispatch(startLoading());
        console.log(formData?._request, 'th request here');
        const response = await API.patch(url, formData._request);
        dispatch(notificationSuccess('Question updated successfully'));
      } else {
        dispatch(startLoading());
        const theResponse = await API.post(url, {
          questions: formData._request,
        });
        console.log(theResponse, 'the response for test');
        dispatch(notificationSuccess('Question added successfully'));
      }
      formData?.callback();
      dispatch(stopLoading());
    } catch (err: any) {
      dispatch(stopLoading());
      dispatch(notificationFail(err.response.data.message));
      return err;
    } finally {
      dispatch(stopLoading());
    }
  },
);
export const generateQuestions = createAsyncThunk(
  'generateQuestions',
  async (formData: any, { dispatch }) => {
    let url = `/api/v1/templates/${formData?.id}/generate-questions`;

    try {
      dispatch(startLoadingAI());
      const theResponse: any = await API.post(url, formData._request);
      console.log(theResponse, 'the response for test');
      dispatch(notificationSuccess('Question added successfully'));
      dispatch(setGeneratedQuestions(theResponse.data.data));
      dispatch(stopLoadingAI());
      return theResponse.data.data;
    } catch (err: any) {
      dispatch(stopLoadingAI());
      // dispatch(notificationFail(err.response.data.message));
      return err;
    } finally {
      dispatch(stopLoadingAI());
    }
  },
);

export const getQuestionList = createAsyncThunk(
  'getQuestionList',
  async (_request: any, { dispatch }) => {
    dispatch(startLoading());
    const page = _request.pageIndex || 0;
    const size = _request.pageSize || 10;
    const search = _request?.search || '';
    let url = `api/v1/templates/${_request?.templateId}/questions?page=${
      page + 1
    }&limit=${1000}`;
    try {
      if (search) {
        url += `&query=${search}`;
      }
      const response: any = await API.get(url);
      dispatch(setQuestionList(response.data.data));
      dispatch(stopLoading());
      return response;
    } catch (err: any) {
      dispatch(stopLoading());
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const DeleteQuestion = createAsyncThunk(
  'DeleteQuestion',
  async (id: any, { dispatch }) => {
    let url = `/api/v1/questions/${id}`;
    try {
      await API.delete(url);
      dispatch(stopLoading());
    } catch (err: any) {
      dispatch(stopLoading());
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);
