// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.view_profile_detail_wrapper__Qt1on {
  display: flex;
  flex-direction: column;
  flex: 2 1;
  justify-content: space-between;
}

.view_profile_detail__gAiA9 {
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  gap: 10px;
}

@media (max-width: 768px) {
  .view_profile_detail__gAiA9 {
    flex-direction: column;
    align-items: start;
  }
  .view_info__SORUG {
    flex-direction: column;
  }
  .view_content_wrapper__htvEu {
    flex-direction: column;
    align-items: center;
  }
}
.view_profile_img__q9WdW {
  min-width: 120px;
  min-height: 120px;
  max-width: 120px;
  max-height: 120px;
  align-items: center;
  border-radius: 8px;
}

.view_info__SORUG {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.view_content_wrapper__htvEu {
  display: flex;
  gap: 20px;
}

.view_classroom_list__dv\\+hC {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.view_ellipse_text__2LymY {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.view_hello__zFB6F > input[readonly] {
  cursor: text;
  background-color: red;
}`, "",{"version":3,"sources":["webpack://./src/components/viewUser/view.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,8BAAA;AACF;;AAEA;EACE,aAAA;EACA,WAAA;EACA,mBAAA;EACA,kBAAA;EACA,SAAA;AACF;;AAEA;EACE;IACE,sBAAA;IACA,kBAAA;EACF;EACA;IACE,sBAAA;EACF;EACA;IACE,sBAAA;IACA,mBAAA;EACF;AACF;AAEA;EACE,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;AAAF;;AAGA;EACE,aAAA;EACA,8BAAA;EACA,qCAAA;EACA,SAAA;AAAF;;AAGA;EACE,aAAA;EACA,SAAA;AAAF;;AAGA;EACE,aAAA;EACA,eAAA;EACA,SAAA;AAAF;;AAGA;EACE,oBAAA;EACA,qBAAA;EACA,4BAAA;EACA,gBAAA;EACA,uBAAA;AAAF;;AAGA;EACE,YAAA;EACA,qBAAA;AAAF","sourcesContent":[".profile_detail_wrapper {\n  display: flex;\n  flex-direction: column;\n  flex: 2;\n  justify-content: space-between;\n}\n\n.profile_detail {\n  display: flex;\n  width: 100%;\n  align-items: center;\n  text-align: center;\n  gap: 10px;\n}\n\n@media (max-width: 768px) {\n  .profile_detail {\n    flex-direction: column;\n    align-items: start;\n  }\n  .info {\n    flex-direction: column;\n  }\n  .content_wrapper {\n    flex-direction: column;\n    align-items: center;\n  }\n}\n\n.profile_img {\n  min-width: 120px;\n  min-height: 120px;\n  max-width: 120px;\n  max-height: 120px;\n  align-items: center;\n  border-radius: 8px;\n}\n\n.info {\n  display: grid;\n  justify-content: space-between;\n  grid-template-columns: repeat(4, 1fr);\n  gap: 10px;\n}\n\n.content_wrapper {\n  display: flex;\n  gap: 20px;\n}\n\n.classroom_list {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 10px;\n}\n\n.ellipse_text {\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.hello > input[readonly] {\n  cursor: text;\n  background-color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profile_detail_wrapper": `view_profile_detail_wrapper__Qt1on`,
	"profile_detail": `view_profile_detail__gAiA9`,
	"info": `view_info__SORUG`,
	"content_wrapper": `view_content_wrapper__htvEu`,
	"profile_img": `view_profile_img__q9WdW`,
	"classroom_list": `view_classroom_list__dv+hC`,
	"ellipse_text": `view_ellipse_text__2LymY`,
	"hello": `view_hello__zFB6F`
};
export default ___CSS_LOADER_EXPORT___;
