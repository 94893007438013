import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

export interface ResponseData {
	status: String
	messagecode: String
	message: String
	data: any
}

interface IntialState {
	quizTemplateList: any,
	quizTemplate: any,
}

const intialState: IntialState = {
	quizTemplateList: [],
	quizTemplate: {}
};

const TemplateSlice = createSlice({
	name: 'video_slice',
	initialState: intialState,
	reducers: {
		setQuizTemplateList: (
			state: Draft<IntialState>,
			action: PayloadAction<[]>
		) => {
			state.quizTemplateList = action.payload
		},
		setQuizTemplateById: (
			state: Draft<IntialState>,
			action: PayloadAction<{}>
		) => {
			state.quizTemplate = action.payload
		},
	}
});


export const {
	setQuizTemplateList,
	setQuizTemplateById
} = TemplateSlice.actions;

export default TemplateSlice.reducer;
