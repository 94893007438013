import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  loading: boolean;
  parentsList: any;
}

const initialState: InitialState = {
  loading: false,
  parentsList: [],
};

const parentSlice = createSlice({
  name: 'notification',
  initialState: initialState,
  reducers: {
    setParentsList: (state: Draft<InitialState>, action: PayloadAction<[]>) => {
      state.parentsList = action.payload;
    },
    setLoading: (
      state: Draft<InitialState>,
      action: PayloadAction<boolean>,
    ) => {
      state.loading = action.payload;
    },
  },
});

export const { setParentsList, setLoading } = parentSlice.actions;

export default parentSlice.reducer;
