import React from "react";

export default function SearchIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.86198 1.95005C3.74827 1.95005 2.03477 3.66355 2.03477 5.77727C2.03477 7.89098 3.74827 9.60448 5.86198 9.60448C7.97569 9.60448 9.68919 7.89098 9.68919 5.77727C9.68919 3.66355 7.97569 1.95005 5.86198 1.95005ZM0.759033 5.77727C0.759033 2.95898 3.0437 0.674316 5.86198 0.674316C8.68026 0.674316 10.9649 2.95898 10.9649 5.77727C10.9649 6.9565 10.5649 8.04231 9.89322 8.90642L13.3296 12.3428C13.5787 12.5919 13.5787 12.9958 13.3296 13.2449C13.0805 13.494 12.6766 13.494 12.4275 13.2449L8.99114 9.8085C8.12703 10.4802 7.04122 10.8802 5.86198 10.8802C3.0437 10.8802 0.759033 8.59555 0.759033 5.77727Z"
        fill="#ABB5C5"
      />
    </svg>
  );
}
