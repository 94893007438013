import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Pagination,
  InputAdornment,
  TextField,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import usePagination from 'hooks/usePagination';
import { useAppDispatch } from 'store/store';
import {
  deleteAssignment,
  getAssignments,
} from 'store/Thunk/assignContentThunk';
import AddIcon from '@mui/icons-material/Add';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import AddUpdate from 'components/dialog/classroom/AddUpdate';
import NodataToShow from 'icons/NoDataToShow';
import CustomAccordion from 'components/customAccordian/customAccordian';
import SearchIcon from 'icons/search';
import { AccordionUp } from 'assets';
import CustomDropDown from 'components/customDropDown';
import { searchBySkillOrStudent } from 'helper/functions';

function Index({ handleAddUpdateContent }: any) {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [assignmentsList, setAssignmentsList] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [originalList, setOriginalList] = useState({});
  const [sortOption, setSortOption] = useState('');

  const handleDeleteAssignment = ({
    assignmentId,
    contentId,
    contentType,
  }: any) => {
    if (assignmentId && contentId && contentType) {
      dispatch(
        deleteAssignment({
          assignmentId,
          contentId,
          contentType,
        }),
      )
        .unwrap()
        .then(() => {
          function excludeContentByAssignmentAndVideoId(
            data: any,
            assignmentId: string,
            videoId: string,
          ) {
            for (let studentId in data) {
              if (data.hasOwnProperty(studentId)) {
                let assignments = data[studentId].assignments || [];
                assignments.forEach((assignment: any) => {
                  let content = assignment.content || [];
                  assignment.content = content.filter((item: any) => {
                    return !(
                      item[contentType] &&
                      item[contentType]?.id === videoId &&
                      assignment.assignmentId === assignmentId
                    );
                  });
                });
              }
            }

            return data;
          }
          excludeContentByAssignmentAndVideoId(
            assignmentsList,
            assignmentId,
            contentId,
          );
        });
    }
  };

  const { currentPage, totalPages, goToPage, currentData } = usePagination(
    Object.values(assignmentsList),
    7,
  );

  useEffect(() => {
    dispatch(getAssignments(id))
      .unwrap()
      .then((res) => {
        setOriginalList(res);
        setAssignmentsList(res);
      });
  }, []);

  useEffect(() => {
    const searchResults = searchBySkillOrStudent({
      query: searchValue.trim(),
      data: originalList,
      sortOrder: sortOption,
    });

    setAssignmentsList(searchResults);

    if (Object.values(searchResults).length === 0 && searchValue.length === 0) {
      setAssignmentsList(originalList);
    }
  }, [searchValue, originalList, sortOption]);

  const handleAddUpdateDistrict = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    goToPage(newPage);
  };

  const onSearchChange = (value: string) => {
    setSearchValue(value);
  };

  const handleSortChange = (value: string) => {
    setSortOption(value);
  };

  return (
    <>
      <Box sx={{ p: '24px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              m: '15px',
              mt: '5px',
            }}
          >
            <NodataToShow />
            <Typography
              variant="body1"
              sx={{ fontSize: '18px', fontWeight: '600' }}
            >
              No Content Assigned
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{
                textAlign: 'justify',
                fontSize: '13px',
                fontWeight: '400',
                p: '0 30px',
              }}
            >
              Please assign some content to the students
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              m: '15px 0 0 0',
            }}
          >
            {/* <Button
                  variant="contained"
                  className="primary_bg_btn"
                  onClick={handleAddUpdateContent}
                  endIcon={<AddIcon />}
                >
                  Add Content
                </Button> */}
          </Box>
          {isOpen && (
            <AddUpdate
              open={isOpen}
              onClose={handleAddUpdateDistrict}
              id={id}
            />
          )}
        </Box>
      </Box>
    </>
  );
}

export default Index;
