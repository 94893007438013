import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  setCityList,
  setDistrictByID,
  setDistrictByState,
  setDistrictList,
  setStateList,
} from 'store/Slice/districtSlice';
import {
  setBtnLoading,
  startLoading,
  stopLoading,
} from 'store/Slice/loadingSlice';
import {
  notificationFail,
  notificationSuccess,
} from 'store/Slice/notificationSlice';
import { setSchoolList } from 'store/Slice/schoolSlice';
import API from 'store/api';

export const getDistrictList = createAsyncThunk(
  'getDistrictList',
  async (_request: any, { dispatch }) => {
    const page = _request?.pageIndex || 0;
    const size = _request?.pageSize || 10;
    const search = _request?.search || '';
    let url = `/api/v1/districts/search?page=${page + 1}&limit=${size}`;
    try {
      if (search) {
        url += `&query=${search}`;
      }

      const response: any = await API.get(url);
      dispatch(setDistrictList(response.data.data));
      // dispatch(setDistrictList([]));
      return true;
    } catch (err: any) {
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const getDistrictByState = createAsyncThunk(
  'getDistrictByID',
  async (_request: any, { dispatch }: any) => {
    let url = `/api/v1/districts/states`;
    try {
      const response: any = await API.post(url, { states: _request.states });

      dispatch(setDistrictByState(response.data.data));
      console.log('districtState', response.data.data);
      // dispatch(setDistrictList([]));
      return true;
    } catch (err: any) {
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);
export const getDistrictByID = createAsyncThunk(
  'getDistrictByID',
  async (_request: any, { dispatch }: any) => {
    let url = `/api/v1/district/${_request?.id}`;
    try {
      const response: any = await API.get(url);

      dispatch(setDistrictByID(response.data.data));
      // dispatch(setDistrictList([]));
      return true;
    } catch (err: any) {
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const addUpdateDistrict = createAsyncThunk(
  'addUpdateDistrict',
  async (formData: any, { dispatch }) => {
    let url = formData?.id
      ? `/api/v1/district/${formData?.id}`
      : `/api/v1/district`;
    console.log('formData', formData);

    try {
      if (formData?.id) {
        let _request = {
          name: formData?.name,
          ncesDistrictId: formData?.ncesDistrictId,
          streetAddress: formData?.streetAddress,
          city: formData?.city,
          state: formData?.state,
          zipCode: formData?.zipCode,
          county: formData?.county,
          districtType: formData?.districtType,
          startGradeId: formData?.startGradeId,
          endGradeId: formData?.endGradeId,
          federalFunding: formData?.federalFunding,
          stateFunding: formData?.stateFunding,
          localFunding: formData?.localFunding,
          totalNoOfSchool: formData?.totalNoOfSchool,
          totalNoOfElementarySchool: formData?.totalNoOfElementarySchool,
          totalNoOfMiddleSchool: formData?.totalNoOfMiddleSchool,
          totalNoOfHighSchool: formData?.totalNoOfHighSchool,
        };
        console.log('url', url);

        const response = await API.patch(url, _request);
        dispatch(notificationSuccess('District updated successfully'));
      } else {
        let _request = {
          name: formData?.name,
          ncesDistrictId: formData?.ncesDistrictId,
          streetAddress: formData?.streetAddress,
          city: formData?.city,
          state: formData?.state,
          zipCode: formData?.zipCode,
          county: formData?.county,
          districtType: formData?.districtType,
          startGradeId: formData?.startGradeId,
          endGradeId: formData?.endGradeId,
          federalFunding: formData?.federalFunding,
          stateFunding: formData?.stateFunding,
          localFunding: formData?.localFunding,
          totalNoOfSchool: formData?.totalNoOfSchool,
          totalNoOfElementarySchool: formData?.totalNoOfElementarySchool,
          totalNoOfMiddleSchool: formData?.totalNoOfMiddleSchool,
          totalNoOfHighSchool: formData?.totalNoOfHighSchool,
        };
        await API.post(url, _request);
        dispatch(notificationSuccess('District created successfully'));
      }
      dispatch(setBtnLoading(false));
      dispatch(getDistrictList({ pageSize: 10, pageIndex: 0 }));
    } catch (err: any) {
      dispatch(setBtnLoading(false));
      dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const deleteDistrict = createAsyncThunk(
  'deleteDistrict',
  async ({ id }: any, { dispatch }) => {
    let url = `/api/v1/district/${id}`;
    try {
      await API.delete(url);
      dispatch(getDistrictList({ pageSize: 10, pageIndex: 0 }));
      dispatch(notificationSuccess('District deleted successfully'));
      return true;
    } catch (err: any) {
      dispatch(notificationFail(err.response.data.message));
      return false;
    }
  },
);

export const getAllSchoolOfDistrict = createAsyncThunk(
  'getSchoolList',
  async (_request: any, { dispatch }) => {
    const page = _request?.pageIndex || 0;
    const size = _request?.pageSize || 12;
    const search = _request?.search || '';
    let url = `/api/v1/district/${_request?.id}/school?page=${
      page + 1
    }&limit=${size}`;
    try {
      if (search) {
        url += `&query=${search}`;
      }
      const response: any = await API.get(url);
      dispatch(setSchoolList(response.data.data));
      // dispatch(setSchoolList([]));
      return true;
    } catch (err: any) {
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

// get schools of one or more districts
export const getSchoolsInDistricts = createAsyncThunk(
  'getSchoolsInDistricts',
  async (districtIds: any, { dispatch }) => {
    let url = `/api/v1/districts/schools`;
    try {
      const response = await API.post(url, { districtIds: districtIds });
      dispatch(setSchoolList(response.data.data));
    } catch (err: any) {
      return err;
    }
  },
);

export const getStates = createAsyncThunk(
  'getStates',
  async (_: any, { dispatch }) => {
    let url = '/api/v1/csc/states';
    try {
      const response = await API.get(url);
      dispatch(setStateList(response.data.data));

      return true;
    } catch (err: any) {
      return err;
    }
  },
);
export const getCities = createAsyncThunk(
  'getCities',
  async (stateId: any, { dispatch }) => {
    let url = `/api/v1/csc/cities?stateId=${stateId}`;
    try {
      const response = await API.get(url);
      dispatch(setCityList(response.data.data));

      return true;
    } catch (err: any) {
      return err;
    }
  },
);

export const getSchoolsInState = createAsyncThunk(
  'getStates',
  async (state: any, { dispatch }) => {
    let url = `api/v1/state/${state}/school`;
    try {
      const response = await API.get(url);
      return response?.data?.data;
    } catch (err: any) {
      return err;
    }
  },
);
