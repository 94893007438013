import React from 'react';

export default function arrowCircleLeft() {
  return (
    <svg
      width="41"
      height="37"
      viewBox="0 0 41 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.2992 26.209C32.3454 30.817 26.8245 33.9173 20.5013 33.9173C11.0664 33.9173 3.41797 27.015 3.41797 18.5007C3.41797 9.98626 11.0664 3.08398 20.5013 3.08398C26.8245 3.08398 32.3454 6.18427 35.2992 10.7923M20.5014 12.334L13.6681 18.5007M13.6681 18.5007L20.5014 24.6673M13.6681 18.5007H37.5848"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
