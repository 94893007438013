import {
  TextField,
  Typography,
  Button,
  Box,
  InputLabel,
  Stack,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import Validations from 'helper/validations';
import React, { useState, ChangeEvent, DragEvent, useEffect } from 'react';
import { createQuestion, getQuestionList } from 'store/Thunk/questionThunk';
import { useAppDispatch } from 'store/store';
import VideocamIcon from '@mui/icons-material/Videocam';
// import DragableOption from 'pages/super_admin/content_management/quiz_template/question/addQuestion/DragableOptions';
import MediaUpload from './MediaUpload';
import MediaOptions from './MediaOptions';
const MediaQuestion = (props: any) => {
  const blankFormData = {
    message: '',
    question: '',
    answer: '',
    optionA: '',
    optionB: '',
    optionC: '',
    optionD: '',
    optionAMedia: null,
    optionBMedia: null,
    optionCMedia: null,
    optionDMedia: null,
    questionMedia: null,
    createdById: '',
    templateIds: [''],
  };
  const questionLevel = ['EASY', 'MEDIUM', 'HARD'];
  const questionType = [
    'text',
    'image',
    'audio',
    'video',
    'math',
    'passage',
    'sound',
    'boolean',
  ];
  const [openAssignTo, setOpenAssignTo] = useState(false);
  const [openLevel, setOpenLevel] = useState(false);
  const [loadingGrade, setLoadingGrade] = useState(false);
  const dispatch = useAppDispatch();
  const [formError, setFormError] = useState<any>([]);
  const { id, formData, setFormData } = props;
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const handleChangeAutocomplete =
    (name: string) =>
    (
      event: React.SyntheticEvent<Element, Event>,
      newValue: string | null | any,
    ) => {
      let param = {
        [name]: newValue,
      };
      setFormData({ ...formData, ...param });
    };
  // const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0];
  //   setSelectedFile(file || null);
  // };

  // const handleDrop = (event: DragEvent<HTMLDivElement>) => {
  //   event.preventDefault();
  //   const file = event.dataTransfer.files?.[0];
  //   setSelectedFile(file || null);
  // };

  // const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
  //   event.preventDefault();
  // };

  const addQuestion = (event: any) => {
    event.preventDefault();
    let allError = Validations?.ValidateMediaQuestionForm(formData);
    if (
      Object.entries(allError).length === 0 &&
      allError.constructor === Object
    ) {
      let _request: any = {
        type: props?.formData?.type || '',
        question: props.formData?.question || '',
        message: props.formData?.message || '',
        level: props.formData?.level || '',
        explanation: props.formData?.explanation || '',
        answer: props.formData?.answer || '',
        optionA: props.formData?.optionA || '',
        optionB: props.formData?.optionB || '',
        optionC: props.formData?.optionC || '',
        optionD: props.formData?.optionD || '',
        optionAMedia: props.formData?.optionAMedia || null,
        optionBMedia: props.formData?.optionBMedia || null,
        optionCMedia: props.formData?.optionCMedia || null,
        optionDMedia: props.formData?.optionDMedia || null,
        questionMedia: props.formData?.questionMedia || null,
        createdById:
          props?.formData?.createdById ||
          '6708decc-573e-478c-a5cf-35bfc82ace32',
        templateIds: [id],
        // templateIds: ['707e2d19-49b4-47f5-9ac1-a3829abcbb36'],
      };

      let _update: any = {
        type: props?.formData?.type || '',
        question: props.formData?.question || '',
        message: props.formData?.message || '',
        answer: props.formData?.answer || '',
        optionA: props.formData?.optionA || '',
        optionB: props.formData?.optionB || '',
        optionC: props.formData?.optionC || '',
        optionD: props.formData?.optionD || '',
        createdById:
          props?.formData?.createdById ||
          '6708decc-573e-478c-a5cf-35bfc82ace32',
        id: props.formData?.id || '',
        templateIds: props?.formData?.templates?.map((item: any) => {
          return item?.id;
        }),
      };
      const mediaFields = [
        'optionAMedia',
        'optionBMedia',
        'optionCMedia',
        'optionDMedia',
        'questionMedia',
      ];

      mediaFields.forEach((field) => {
        if (props.formData[field]) {
          const media = props.formData[field];
          if (media.name && media.type && media.contents) {
            _update[field] = media;
          }
        }
      });
      try {
        setLoading(true);
        dispatch(
          createQuestion({
            _request: props.formData?.id ? _update : _request,
            callback: () => {
              dispatch(
                getQuestionList({
                  templateId: id,
                }),
              );
              setFormError([]);
              props.setFormData({
                ...blankFormData,
              });
            },
          }),
        );
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    } else {
      setFormError(allError);
    }
  };

  const handleChangeInput = (event: React.ChangeEvent<any>) => {
    setFormData({
      ...props?.formData,
      [event.target.name]: event.target.value,
    });
  };
  const handleFileChange = (
    event: React.ChangeEvent<any> | DragEvent<any>,
    mediaName: string,
    drop?: boolean,
  ) => {
    let file: any;

    if (drop && (event as DragEvent).dataTransfer) {
      file = (event as DragEvent).dataTransfer.files?.[0] ?? null;
    } else {
      file = event?.target?.files[0];
    }

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const media = {
          name: file?.name.replace(/[^\w\s.-]/gi, ''),
          type: file.type,
          contents: reader.result as string,
        };

        setFormData((prevFormData: any) => ({
          ...prevFormData,
          [mediaName]: media,
        }));
      };

      reader.readAsDataURL(file);
    }
  };

  const handleChangeAnswer = (answer: any) => {
    setFormData({ ...props.formData, answer: answer.toLowerCase() });
  };

  // const handleDragChanges = (data: any) => {
  //   setFormData({
  //     ...props.formData,
  //     optionA: data[0]?.content,
  //     optionB: data[1]?.content,
  //     optionC: data[2]?.content,
  //     optionD: data[3]?.content,
  //   });
  // };

  return (
    <>
      <Box className="flex gap-4">
        <Stack className="flex-1">
          <Box sx={{ m: '4px 0' }}>
            <Typography>{'Message'}</Typography>
          </Box>
          <TextField
            name="message"
            type="text"
            fullWidth
            value={formData?.message}
            onChange={handleChangeInput}
            size="medium"
            placeholder="Write your question message"
            error={formError && formError?.message}
            id="outlined-error-helper-text"
            helperText={
              formError && formError?.message ? formError?.message : ''
            }
          />
        </Stack>
        <Box className="flex flex-1 flex-col">
          <InputLabel
            error={formError && formError?.type}
            sx={{ color: '#484848', pb: 2 }}
          >
            <Typography>Question Type</Typography>
          </InputLabel>
          <Box className="flex-1">
            <Autocomplete
              id="grade"
              open={openAssignTo}
              onOpen={() => setOpenAssignTo(true)}
              onClose={() => setOpenAssignTo(false)}
              isOptionEqualToValue={(option, value) => option === value}
              getOptionLabel={(option) =>
                option ? option.charAt(0).toUpperCase() + option.slice(1) : ''
              }
              options={questionType}
              filterSelectedOptions
              // disabled={editContentInfo && true}
              value={formData?.type || {}}
              onChange={handleChangeAutocomplete('type')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select"
                  error={formError && formError?.type}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingGrade ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Box>
        </Box>
        <Box className="flex flex-1 flex-col">
          <InputLabel
            error={formError && formError?.level}
            sx={{ color: '#484848', pb: 2 }}
          >
            <Typography>Question Level</Typography>
          </InputLabel>
          <Box className="flex-1">
            <Autocomplete
              id="grade"
              open={openLevel}
              onOpen={() => setOpenLevel(true)}
              onClose={() => setOpenLevel(false)}
              isOptionEqualToValue={(option, value) => option === value}
              getOptionLabel={(option) => option || ''}
              options={questionLevel}
              filterSelectedOptions
              // disabled={editContentInfo && true}
              value={formData?.level || ''}
              onChange={handleChangeAutocomplete('level')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select"
                  error={formError && formError?.userIds}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingGrade ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ m: '4px 0' }}>
        <Typography>{'Question'}</Typography>
      </Box>
      <TextField
        variant="outlined"
        value={formData?.question}
        fullWidth
        multiline
        rows={2}
        name="question"
        onChange={handleChangeInput}
        size="medium"
        placeholder="Write your question"
        error={formError && formError?.question}
        id="outlined-error-helper-text"
        helperText={formError && formError?.question ? formError?.question : ''}
      />
      <MediaUpload
        key="questionMedia"
        name="questionMedia"
        formData={props?.formData}
        handleFileChange={handleFileChange}
      />

      <Box>
        {/* <DragableOption
          options={props?.formData}
          handleChange={handleChangeInput}
          handleChangeAnswer={handleChangeAnswer}
          handleDragChanges={handleDragChanges}
        /> */}
        <MediaOptions
          // key="questionMedia"
          options={props?.formData}
          // formSetter={props?.setFormData}
          handleChange={handleChangeInput}
          handleFileChange={handleFileChange}
          handleChangeAnswer={handleChangeAnswer}
        />
        <Typography
          sx={{ color: '#FF3333', fontSize: '0.75rem', fontWeight: '400' }}
        >
          {formError?.answer && 'Select the correct answer'}
          {(formError?.optionA ||
            formError?.optionB ||
            formError?.optionC ||
            formError?.optionD) &&
            'All four options are required'}
        </Typography>
      </Box>
      <Box sx={{ m: '4px 0', mt: 4 }}>
        <Typography>{'Explanation:'}</Typography>
      </Box>
      <Box sx={{ mb: 1 }}>
        <textarea
          placeholder="Explanation"
          className="input-questions"
          name="explanation"
          value={formData?.explanation}
          onChange={handleChangeInput}
          style={{
            border:
              formError && formError.explanation
                ? '1px solid red'
                : '1px solid #e5e7eb',
            borderRadius: '10px',
            padding: '14px',
            width: '100%',
          }}
        ></textarea>
        {/* <TextField
          variant="outlined"
          value={formData?.explanation}
          fullWidth
          name="passage"
          onChange={handleChangeInput}
          size="medium"
          placeholder="Explanation"
          error={formError && formError?.explanation}
          id="outlined-error-helper-text"
          helperText={
            formError && formError?.explanation ? formError?.explanation : ''
          }
        /> */}
        {/* <TextField
        type="file"
        label="Upload Image"
        onChange={handleFileChange}
        InputLabelProps={{
          shrink: true,
        }}
      /> */}
      </Box>

      <Box sx={{ p: '12px 0' }}>
        {props?.formData?.id ? (
          <Button
            onClick={(e) => {
              addQuestion(e);
            }}
            sx={{
              backgroundColor: '#C73E9D',
              color: '#fff',
              border: '#C73E9D 2px solid',
              '&:hover': {
                backgroundColor: '#db4fb0 !important',
              },
            }}
            disabled={loading ? true : false}
          >
            Update
          </Button>
        ) : (
          <Button
            onClick={(e) => {
              addQuestion(e);
            }}
            sx={{
              backgroundColor: '#C73E9D',
              color: '#fff',
              border: '#C73E9D 2px solid',
              '&:hover': {
                backgroundColor: '#db4fb0 !important',
              },
            }}
            disabled={loading ? true : false}
          >
            Save
          </Button>
        )}
      </Box>
    </>
  );
};
export default MediaQuestion;
