import {
  Card,
  Checkbox,
  Chip,
  Collapse,
  Grid,
  ListItemButton,
  Stack,
  Typography,
} from '@mui/material';
import DefaultCell from 'components/DefaultCell';
import ReadMore from 'components/ReadMore/ReadMore';
import CustomTable from 'components/customTable';
import { typesColors } from 'helper/constants';
import {
  itemExistsWithType,
  removeItemByID,
  updateOrAddType,
} from 'helper/functions';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { displayMultipleValue, getLocalDateTime } from 'helper/service';
import { _assignmentTypes } from 'helper/types';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { startLoading } from 'store/Slice/loadingSlice';
import { checkExistence } from 'store/Thunk/assignContentThunk';
import { getQuizTemplateList } from 'store/Thunk/templateThunk';
import { useAppDispatch, useAppSelector } from 'store/store';
import TableWrapper from 'components/tableWrapper';
import TableHeader from 'components/tableHeader';

interface propsType {
  setSelectedContent: (value: any) => void;
  selectedContent: any;
  selectedQuizzes: any;
  setSelectedQuizzes: any;
  selectiveInfo: any;
  setSelectedVideos: any;
  setInfoModal: any;
  setIsOpenQuestionLimit: any;
}

const AssignQuizTable = ({
  setSelectedContent,
  selectedQuizzes,
  setSelectedQuizzes,
  setSelectedVideos,
  selectedContent,
  selectiveInfo,
  setIsOpenQuestionLimit,

  setInfoModal,
}: any) => {
  const dispatch = useAppDispatch();
  const { quizTemplateList: quizList } = useAppSelector(
    (state) => state.templateReducer,
  );
  const ClassroomData = useAppSelector(
    (state) => state.classroomReducer.classroomID,
  );
  const urlParams = new URLSearchParams(window.location.search);
  let topicId = ClassroomData?.classroom?.topic?.id;
  const [quizzes, setQuizzes] = useState<any[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const { id: assigmentId } = useParams();
  const [search, setSearch] = useState('');

  const [searchParams] = useSearchParams();
  const contentId = searchParams.get('content');
  const contentType = searchParams.get('contentType');
  const [foundedItems, setFoundedItems] = useState<any>({});

  useEffect(() => {
    if (quizList && quizList?.items) {
      setQuizzes(quizList?.items);
      setPageCount(quizList?.meta?.lastPage);
      setTotalCount(quizList?.meta?.total);
    }
  }, [quizList]);

  useEffect(() => {
    if (contentId && contentType === 'template') {
      setSelectedContent([
        {
          type: 'Templates',
          ids: [contentId],
        },
      ]);
    }
  }, [contentId]);

  let rows = useMemo(() => {
    const data = quizzes?.map((item) => ({
      id: item.id,
      title: item.title,
      topic: item.topic,
      subTopic: item?.subTopic,
      grade: item.grade,
      skill: item.skill,
      questionCount: item?.questions?.length || 0,
      date: item.createdAt ? getLocalDateTime(item.createdAt) : '-',
      selected: selectedItems.includes(item.id),
      alreadyAssigned:
        foundedItems?.existingTemplateIds?.includes(item.id) || false,
      count: item?.count || 0,
    }));

    return data;
  }, [quizzes, selectedItems, foundedItems]);
  useEffect(() => {
    setSelectedQuizzes(rows);
  }, [rows]);
  useEffect(() => {
    setSelectedVideos([]);
  }, []);
  const onItemSelect = useCallback(
    (itemID: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setSelectedQuizzes((prevState: any) => {
        return prevState.map((obj: any) => {
          if (obj.id === itemID) {
            return { ...obj, selected: e.target.checked, type: 'quiz' };
          }
          return obj;
        });
      });
    },
    [],
  );
  // const handleCountChange = useCallback(
  //   (id: string, text: 'increment' | 'decrement'): void => {
  console.log('selectedQuizzes', selectedQuizzes);
  function handleInputChange(id: number, value: string) {
    if (parseInt(value) > 0)
      setSelectedQuizzes((prevState: any) => {
        return prevState.map((obj: any) => {
          if (obj.id === id && value <= obj.questionCount) {
            return { ...obj, count: parseInt(value) };
          } else if (obj.id === id) {
            setIsOpenQuestionLimit(true);
          }
          return obj;
        });
      });
    else {
      setSelectedQuizzes((prevState: any) => {
        return prevState.map((obj: any) => {
          if (obj.id === id) {
            return { ...obj, count: 0 };
          }
          return obj;
        });
      });
    }
  }
  function handleCountChange(id: number, text: string) {
    const increase = text === 'increment';

    // Find the object in the array with the matching id
    if (increase) {
      setSelectedQuizzes((prevState: any) => {
        return prevState.map((obj: any) => {
          if (obj.id === id && obj.count < obj.questionCount) {
            return { ...obj, count: obj.count + 1, selected: true };
          } else if (obj.id === id && increase) {
            setIsOpenQuestionLimit(true);
          }
          return obj;
        });
      });
    } else {
      setSelectedQuizzes((prevState: any) => {
        return prevState.map((obj: any) => {
          if (obj.id === id && obj.count > 0) {
            return {
              ...obj,
              count: obj.count - 1,
              selected: obj.count === 1 ? false : true,
            };
          }
          return obj;
        });
      });
    }

    // Output the updated array for demonstration
    console.log(rows);
    console.log(text);
  }

  console.log('quizzes', selectedQuizzes);
  console.log('quizzes', rows);
  useEffect(() => {
    dispatch(getQuizTemplateList({ pageCount: 1, perPage: 10, topicId }));
  }, []);
  const assignmentType = urlParams.get('assignmentType');

  const columns = useMemo(
    () => [
      {
        Header: 'Grade',
        accessor: 'grade',
        Cell: ({ value }: any) => {
          if (value.length) {
            return <p>{displayMultipleValue(value, 'grade')}</p>;
          }
        },
      },
      {
        Header: 'Title',
        accessor: 'title', // accessor is the "key" in your data
        Cell: ({ value }: any) => {
          if (value?.length) {
            return <ReadMore text={value || '-'} />;
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Content Area',
        accessor: 'topic',
        Cell: ({ value }: any) => {
          if (value.length) {
            return (
              <DefaultCell
                value={displayMultipleValue(value, 'topic') || '-'}
              />
            );
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Domain',
        accessor: 'subTopic',
        Cell: ({ value }: any) => {
          if (value?.length) {
            return (
              <ReadMore
                text={
                  `${value[0]?.subTopic?.sequenceNumber} - ${value[0]?.subTopic?.name}` ||
                  '-'
                }
              />
            );
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Skill',
        accessor: 'skill',
        Cell: ({ row, value }: any) => {
          if (value && value?.length > 0 && value[0]?.skill) {
            const skillInfo = value[0].skill;
            const displayText = `${skillInfo.sequenceNumber} - ${
              skillInfo.name || '-'
            }`;

            return <DefaultCell value={displayText || ''} />;
          } else {
            return '-';
          }
        },
      },
      assignmentType !== 'Daily_Practice'
        ? {
            Header: 'No of Questions',
            accessor: 'assigned',
            Cell: ({ row }) => {
              return (
                <div className="flex items-center justify-center px-4 py-1 border-gray-400 border rounded-lg">
                  <button
                    className="text-2xl text-gray-500"
                    onClick={() =>
                      handleCountChange(row.original.id, 'decrement')
                    }
                  >
                    -
                  </button>
                  {/* <span className="mx-3 text-xl">{row.original.count}</span> */}
                  <input
                    type="text"
                    style={{
                      width: '30px',
                      border: 'none',
                      outline: 'none',
                      textAlign: 'center',
                    }}
                    value={row.original.count}
                    onChange={(e) =>
                      handleInputChange(row.original?.id, e.target.value)
                    }
                  />
                  <div>/ {row.original?.questionCount}</div>
                  <button
                    className="text-2xl text-gray-500"
                    onClick={() =>
                      handleCountChange(row.original?.id, 'increment')
                    }
                  >
                    +
                  </button>
                </div>
              );
            },
          }
        : { Header: '', accessor: '0' },
      {
        Header: '',
        accessor: 'selected',
        Cell: ({ row }) => {
          if (
            selectiveInfo?.toName !== 'Invididual' &&
            !row.original.alreadyAssigned
          ) {
            return (
              <div className="flex flex-1 justify-center">
                <Checkbox
                  sx={{
                    '&.Mui-checked': {
                      color: '#C73E9D',
                    },
                    margin: '10px',
                  }}
                  disabled={!row.original?.questionCount}
                  onChange={onItemSelect(row.original.id)}
                  checked={
                    itemExistsWithType(
                      selectedContent,
                      'Videos',
                      row.original.id,
                    ) || row.original.selected
                  }
                />
              </div>
            );
          }
        },
      },
    ],
    [selectedContent],
  );

  useEffect(() => {
    const checkIds = quizzes?.map((item) => item.id);
    if (checkIds.length > 0) {
      dispatch(
        checkExistence({
          type: 'Templates',
          ids: [...checkIds],
        }),
      )
        .unwrap()
        .then((response) => {
          setFoundedItems(response);
        })
        .catch(() => {});
    }
  }, [quizzes, dispatch]);

  const fetchData = useCallback(({ pageSize, pageIndex, search }: any) => {
    console.log('pageSize', search);
    console.log('pageSize', topicId);
    setPageNo(pageIndex);
    dispatch(startLoading());
    dispatch(getQuizTemplateList({ pageSize, pageIndex, search, topicId }));
  }, []);

  return (
    <Stack>
      <TableHeader
        item={{
          action: false,
          fetchData,
          search,
          setSearch,
        }}
      />
      <TableWrapper title="Quiz">
        <CustomTable
          table={{
            columns,
            rows: selectedQuizzes,
          }}
          fetchData={fetchData}
          variant="borderLess"
          canSearch={false}
          searchText="Search..."
          pageCount={pageCount}
          pageNo={pageNo}
          totalCount={totalCount}
          color="#929292"
          searchAssignment={search}
        />
      </TableWrapper>
    </Stack>
  );
};

export default AssignQuizTable;
