import React from "react";
import { Box, Grid } from "@mui/material";
import MyScheduledCard from "./scheduled_card";

const index = () => {
  return (
    <Box m={4}>
      <Grid container spacing={4}>
        {
          [...new Array(4)].map((_,index) => (   
            <Grid item md={6}>
              <MyScheduledCard index={index}/>
            </Grid>     
          ))
        }
        
      </Grid>
    </Box>
  );
};

export default index;