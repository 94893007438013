import React from "react";

export default function DeleteIcon() {
  return (
    <svg
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.32422 4.95692H2.91889M2.91889 4.95692H15.6763M2.91889 4.95692V16.1196C2.91889 16.5426 3.0869 16.9482 3.38596 17.2472C3.68502 17.5463 4.09063 17.7143 4.51356 17.7143H12.4869C12.9099 17.7143 13.3155 17.5463 13.6145 17.2472C13.9136 16.9482 14.0816 16.5426 14.0816 16.1196V4.95692H2.91889ZM5.3109 4.95692V3.36225C5.3109 2.93932 5.47891 2.53371 5.77797 2.23465C6.07703 1.93559 6.48264 1.76758 6.90557 1.76758H10.0949C10.5178 1.76758 10.9235 1.93559 11.2225 2.23465C11.5216 2.53371 11.6896 2.93932 11.6896 3.36225V4.95692M6.90557 8.9436V13.7276M10.0949 8.9436V13.7276"
        stroke="#F41F52"
        stroke-width="1.59467"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
