import React from 'react';

export default function ViewBorderIcon() {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.7381 11.2472L23.1082 10.1796C22.5753 9.2762 21.3944 8.96456 20.4682 9.4829C20.0274 9.73657 19.5013 9.80853 19.0061 9.68294C18.5109 9.55734 18.0871 9.24449 17.8283 8.81338C17.6618 8.53936 17.5723 8.22725 17.5689 7.90862C17.584 7.39777 17.3866 6.90273 17.0219 6.53628C16.6572 6.16983 16.1562 5.96317 15.633 5.96338H14.3639C13.8513 5.96337 13.3599 6.16289 12.9983 6.51777C12.6367 6.87266 12.4348 7.35365 12.4372 7.85433C12.4221 8.88806 11.5597 9.71825 10.5013 9.71814C10.1751 9.71483 9.85553 9.62744 9.57499 9.46481C8.64885 8.94647 7.46792 9.25811 6.93504 10.1615L6.25885 11.2472C5.72661 12.1494 6.04132 13.3022 6.96283 13.8258C7.56183 14.1635 7.93082 14.7878 7.93082 15.4634C7.93082 16.139 7.56183 16.7632 6.96283 17.101C6.0425 17.6211 5.72744 18.771 6.25885 19.6705L6.89799 20.7472C7.14767 21.1872 7.56658 21.512 8.06204 21.6495C8.55749 21.787 9.08864 21.7261 9.53794 21.48C9.97962 21.2283 10.506 21.1593 11 21.2885C11.494 21.4176 11.9147 21.7341 12.1686 22.1677C12.3351 22.4417 12.4246 22.7538 12.428 23.0724C12.428 24.1168 13.2947 24.9634 14.3639 24.9634H15.633C16.6986 24.9634 17.5638 24.1223 17.5689 23.0815C17.5665 22.5792 17.7696 22.0968 18.1332 21.7417C18.4968 21.3865 18.9907 21.1881 19.5049 21.1905C19.8303 21.199 20.1485 21.2861 20.4312 21.4439C21.3549 21.9637 22.5351 21.6563 23.0711 20.7562L23.7381 19.6705C23.9962 19.2377 24.0671 18.7223 23.9349 18.2383C23.8028 17.7543 23.4786 17.3417 23.0341 17.092C22.5896 16.8422 22.2654 16.4296 22.1332 15.9456C22.0011 15.4616 22.0719 14.9462 22.3301 14.5134C22.498 14.2271 22.741 13.9897 23.0341 13.8258C23.9501 13.3025 24.264 12.1564 23.7381 11.2562V11.2472Z"
        stroke="#20A58A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.6708 15.4634C17.6708 16.9025 16.4764 18.0691 15.0031 18.0691C13.5297 18.0691 12.3354 16.9025 12.3354 15.4634C12.3354 14.0243 13.5297 12.8577 15.0031 12.8577C16.4764 12.8577 17.6708 14.0243 17.6708 15.4634Z"
        stroke="#20A58A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="0.5"
        y="1.46338"
        width="29"
        height="29"
        rx="6.5"
        stroke="#20A58A"
      />
    </svg>
  );
}
