// *** React Imports
import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';

// *** Redux Imports
import { useAppDispatch, useAppSelector } from 'store/store';

// *** Icons
import Profile from 'assets/actionIcons/profile.png';

import MDDialog from '@core/components/MDDialog';

// *** Assets
import MuxVideoPlayer from 'components/fileUpload/MuxVideoPlayer';

// *** Third Party Imports
import { useNavigate } from 'react-router-dom';

function Index({ open, onClose, data, title }: any) {
  const navigate: any = useNavigate();
  const dispatch: any = useAppDispatch();
  // useEffect(() => {
  //   dispatch(getUserList())
  // },[])

  //   useEffect(() => {
  //     setFormError({ ...formError, videoFile: '' });
  //   }, [uploadedFile]);

  let UpChunk: any = null;
  if (typeof window !== 'undefined') {
    UpChunk = require('@mux/upchunk');
  }

  const dialogProps = {
    open,
    onClose,
    title: title,
    saveTbtText: 'Approve',
    size: 'sm',
    closeBtnText: 'Reject',
  };

  return (
    <MDDialog {...dialogProps}>
      <Box pl={3}>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Box>
                <Typography variant="subtitle2" className="title_text_model">
                  School ID
                </Typography>
                <Typography variant="caption" className="subtitle_text_model">
                  School A234
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box>
                <Typography variant="subtitle2" className="title_text_model">
                  District ID
                </Typography>
                <Typography variant="caption" className="subtitle_text_model">
                  District A234
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box>
                <Typography variant="subtitle2" className="title_text_model">
                  User created
                </Typography>
                <Box
                  component="img"
                  src={data?.user?.profileImage || Profile}
                />
                <Typography variant="caption" className="subtitle_text_model">
                  {data?.user?.name || ''}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box>
                <Typography variant="subtitle2" className="title_text_model">
                  Grade
                </Typography>
                <Typography variant="caption" className="subtitle_text_model">
                  {data?.grade?.name || ''}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box>
                <Typography variant="subtitle2" className="title_text_model">
                  Topic
                </Typography>
                <Typography variant="caption" className="subtitle_text_model">
                  {data?.topic?.name || ''}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box>
                <Typography variant="subtitle2" className="title_text_model">
                  Sub-Topic
                </Typography>
                <Typography variant="caption" className="subtitle_text_model">
                  {data?.subTopic?.name || ''}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box>
                <Typography variant="subtitle2" className="title_text_model">
                  Skill
                </Typography>
                <Typography variant="caption" className="subtitle_text_model">
                  {data?.skill?.name || ''}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} mt={2}>
              <MuxVideoPlayer
                {...data}
                detail
                // handleRemoveVideo={handleRemoveVideo}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </MDDialog>
  );
}

export default Index;
