import { createAsyncThunk } from '@reduxjs/toolkit';
import API from 'store/api';
import {
  setBtnLoading,
  startLoading,
  stopLoading,
} from 'store/Slice/loadingSlice';
import {
  notificationFail,
  notificationSuccess,
} from 'store/Slice/notificationSlice';
import {
  setQuizTemplateById,
  setQuizTemplateList,
} from 'store/Slice/templateSlice';
import { setScreenLoading } from 'store/Slice/userSlice';
import {
  setTopicList,
  setSubTopicList,
  setGradeList,
  setSkillList,
  setVideoList,
  setMuxUploadUrl,
  setVideoById,
} from 'store/Slice/videoSlice';

export const addUpdateTemplate = createAsyncThunk(
  'addUpdateTemplate',
  async (formData: any, { dispatch }) => {
    let { id, ...rest } = formData;
    let url = id ? `/api/v1/templates/${id}` : `/api/v1/templates`;
    try {
      if (id) {
        await API.patch(url, rest);
        dispatch(notificationSuccess('Assessment updated successfully'));
      } else {
        await API.post(url, rest);
        dispatch(notificationSuccess('Assessment created successfully'));
      }
      dispatch(getQuizTemplateList({ pageIndex: 0, pageSize: 10 }));
      dispatch(setBtnLoading(false));
      return true;
    } catch (err: any) {
      dispatch(setBtnLoading(false));
      dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const getQuizTemplateList = createAsyncThunk(
  'getQuizTemplateList',
  async (_request: any, { dispatch }) => {
    dispatch(startLoading());
    const page = _request?.pageIndex || 0;
    const size = _request?.pageSize || 10;
    const topicId = _request?.topicId || '';
    const search = _request?.search || '';
    let url = `/api/v1/templates?page=${
      page + 1
    }&limit=${size}&topicId=${topicId}`;
    try {
      if (search) {
        url += `&query=${search}`;
      }
      const response: any = await API.get(url);
      dispatch(setQuizTemplateList(response.data.data));
      dispatch(stopLoading());
      return response;
    } catch (err: any) {
      dispatch(stopLoading());
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const getQuizTemplateById = createAsyncThunk(
  'getQuizTemplateById',
  async (_request: any, { dispatch }) => {
    dispatch(startLoading());
    let url = `/api/v1/templates/${_request.id}`;
    try {
      const response: any = await API.get(url);
      dispatch(setQuizTemplateById(response.data.data));
      dispatch(stopLoading());
      return response;
    } catch (err: any) {
      dispatch(setQuizTemplateById({}));
      dispatch(stopLoading());
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const deleteQuiz = createAsyncThunk(
  'deleteQuiz',
  async (_request: any, { dispatch }) => {
    // dispatch(startLoading());
    let url = `/api/v1/templates/${_request.id}`;
    try {
      const response: any = await API.delete(url);
      dispatch(
        getQuizTemplateList({
          pageSize: 10,
          pageIndex: 0,
          search: _request.search,
        }),
      );
      // dispatch(stopLoading());
      dispatch(notificationSuccess('Templated deleted successfully'));
      return true;
    } catch (err: any) {
      // dispatch(stopLoading());
      dispatch(notificationFail(err.response.data.message));
      return false;
    }
  },
);
