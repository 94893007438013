import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

export interface ResponseData {
  status: String;
  messagecode: String;
  message: String;
  data: any;
}

interface IntialState {
  groupDetailsByID: any;
  groupListByClassId: any;
}

const intialState: IntialState = {
    groupDetailsByID: {},
    groupListByClassId: [],
};

const GroupSlice = createSlice({
  name: 'group_slice',
  initialState: intialState,
  reducers: {
    setGroupDetailsByID: (state: Draft<IntialState>, action: PayloadAction<[]>) => {
      state.groupDetailsByID = action.payload;
    },
    setGroupListByClassId: (state: Draft<IntialState>, action: PayloadAction<[]>) => {
      state.groupListByClassId = action.payload;
    },
  },
});

export const { setGroupDetailsByID, setGroupListByClassId } = GroupSlice.actions;

export default GroupSlice.reducer;
