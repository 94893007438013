import React from 'react';

export default function EditBorderIcon() {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.49902 23.4645H10.624L19.8407 14.2478L16.7157 11.1228L7.49902 20.3395V23.4645ZM9.16569 21.0312L16.7157 13.4812L17.4824 14.2478L9.93236 21.7978H9.16569V21.0312Z"
        fill="#000"
      />
      <path
        d="M20.3074 8.70615C19.9824 8.38115 19.4574 8.38115 19.1324 8.70615L17.6074 10.2312L20.7324 13.3562L22.2574 11.8312C22.5824 11.5062 22.5824 10.9812 22.2574 10.6562L20.3074 8.70615Z"
        fill="#000"
      />
      <rect
        x="0.5"
        y="1.46338"
        width="29"
        height="29"
        rx="6.5"
        stroke="#000"
      />
    </svg>
  );
}
