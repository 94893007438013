import React from "react";

export default function EditWithBlackIcon() {
  return (
    <svg
      width="312"
      height="281"
      viewBox="0 0 312 281"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1208_29125)">
        <path
          d="M159.785 0.61248L149.633 3.23884L24.5594 35.5963L14.4075 38.2226C9.47985 39.5034 5.25984 42.6927 2.67329 47.091C0.0867336 51.4892 -0.655137 56.7372 0.610433 61.6834L44.6315 232.934C45.9081 237.878 49.0873 242.111 53.4714 244.706C57.8556 247.301 63.0868 248.045 68.0173 246.775L68.0435 246.769L213.369 209.172L213.395 209.165C218.323 207.885 222.543 204.695 225.129 200.297C227.716 195.899 228.458 190.651 227.192 185.704L183.171 14.4538C181.894 9.5104 178.715 5.27685 174.331 2.68199C169.947 0.0871265 164.716 -0.657139 159.785 0.61248Z"
          fill="#F2F2F2"
        />
        <path
          d="M161.165 5.98159L149.141 9.09234L27.8116 40.4811L15.7876 43.5918C12.2785 44.5038 9.27329 46.775 7.43133 49.9071C5.58937 53.0392 5.06106 56.7764 5.9623 60.2987L49.9833 231.549C50.8924 235.07 53.1564 238.085 56.2785 239.932C59.4006 241.78 63.1258 242.31 66.6369 241.406L66.6631 241.399L211.988 203.803L212.015 203.796C215.524 202.884 218.529 200.613 220.371 197.481C222.213 194.348 222.741 190.611 221.84 187.089L177.819 15.8383C176.91 12.318 174.646 9.30326 171.524 7.4554C168.402 5.60754 164.676 5.0775 161.165 5.98159Z"
          fill="white"
        />
        <path
          d="M152.086 61.4371L78.5699 80.4563C77.7488 80.6687 76.8772 80.5452 76.1468 80.113C75.4165 79.6807 74.8873 78.9751 74.6755 78.1514C74.4638 77.3276 74.5869 76.4532 75.0178 75.7206C75.4486 74.9879 76.152 74.4569 76.9731 74.2445L150.49 55.2253C151.31 55.0137 152.181 55.1377 152.911 55.5701C153.641 56.0024 154.17 56.7077 154.381 57.531C154.593 58.3542 154.47 59.2281 154.04 59.9605C153.609 60.693 152.907 61.2241 152.086 61.4371Z"
          fill="#F2F2F2"
        />
        <path
          d="M167.552 68.6157L81.2642 90.9388C80.4431 91.1512 79.5715 91.0277 78.8412 90.5954C78.1108 90.1632 77.5816 89.4576 77.3699 88.6338C77.1581 87.8101 77.2812 86.9357 77.7121 86.203C78.143 85.4704 78.8463 84.9394 79.6674 84.727L165.955 62.4039C166.776 62.1914 167.648 62.3149 168.378 62.7472C169.108 63.1795 169.638 63.8851 169.849 64.7088C170.061 65.5325 169.938 66.4069 169.507 67.1396C169.076 67.8723 168.373 68.4032 167.552 68.6157Z"
          fill="#F2F2F2"
        />
        <path
          d="M164.162 108.414L90.6453 127.433C90.2387 127.538 89.8155 127.562 89.3998 127.503C88.984 127.444 88.5839 127.304 88.2223 127.09C87.8607 126.876 87.5446 126.592 87.2922 126.256C87.0397 125.919 86.8558 125.536 86.7509 125.128C86.6461 124.72 86.6224 124.296 86.6811 123.879C86.7398 123.462 86.8798 123.06 87.0932 122.697C87.3065 122.335 87.589 122.017 87.9245 121.764C88.26 121.511 88.642 121.326 89.0485 121.221L162.565 102.202C162.972 102.097 163.395 102.073 163.811 102.132C164.226 102.191 164.626 102.331 164.988 102.545C165.35 102.759 165.666 103.043 165.918 103.379C166.171 103.716 166.355 104.099 166.459 104.507C166.564 104.915 166.588 105.339 166.529 105.757C166.471 106.174 166.331 106.575 166.117 106.938C165.904 107.301 165.621 107.618 165.286 107.871C164.95 108.124 164.568 108.309 164.162 108.414Z"
          fill="#F2F2F2"
        />
        <path
          d="M179.627 115.592L93.3399 137.915C92.9333 138.021 92.5101 138.044 92.0943 137.986C91.6786 137.927 91.2785 137.786 90.9169 137.572C90.5552 137.358 90.2392 137.075 89.9867 136.738C89.7343 136.402 89.5504 136.018 89.4455 135.611C89.3407 135.203 89.3169 134.778 89.3757 134.361C89.4344 133.944 89.5744 133.543 89.7878 133.18C90.0011 132.817 90.2836 132.5 90.6191 132.247C90.9546 131.993 91.3365 131.809 91.7431 131.704L178.031 109.381C178.852 109.168 179.723 109.292 180.454 109.724C181.184 110.156 181.713 110.862 181.925 111.686C182.137 112.509 182.014 113.384 181.583 114.116C181.152 114.849 180.448 115.38 179.627 115.592Z"
          fill="#F2F2F2"
        />
        <path
          d="M176.238 155.391L102.721 174.41C101.9 174.622 101.029 174.498 100.299 174.065C99.5694 173.633 99.0405 172.928 98.8288 172.104C98.6172 171.281 98.7401 170.407 99.1705 169.674C99.601 168.942 100.304 168.411 101.124 168.198L174.641 149.179C175.462 148.966 176.333 149.09 177.064 149.522C177.794 149.954 178.323 150.66 178.535 151.484C178.747 152.307 178.624 153.182 178.193 153.915C177.762 154.647 177.059 155.178 176.238 155.391Z"
          fill="#F2F2F2"
        />
        <path
          d="M191.703 162.569L105.416 184.892C105.009 184.998 104.585 185.022 104.169 184.963C103.753 184.905 103.353 184.764 102.991 184.55C102.629 184.336 102.313 184.053 102.06 183.716C101.807 183.38 101.623 182.996 101.519 182.588C101.414 182.18 101.39 181.755 101.449 181.338C101.508 180.92 101.648 180.519 101.861 180.156C102.075 179.793 102.358 179.476 102.694 179.223C103.03 178.97 103.412 178.785 103.819 178.68L190.106 156.357C190.927 156.145 191.799 156.268 192.529 156.701C193.26 157.133 193.789 157.839 194.001 158.662C194.212 159.486 194.089 160.36 193.658 161.093C193.228 161.826 192.524 162.357 191.703 162.569Z"
          fill="#F2F2F2"
        />
        <path
          d="M66.0195 99.9577L40.0027 106.688C39.609 106.79 39.1913 106.73 38.8412 106.523C38.4911 106.316 38.2373 105.978 38.1353 105.583L32.1632 82.3504C32.0621 81.9555 32.1214 81.5364 32.3279 81.1852C32.5344 80.834 32.8714 80.5793 33.2649 80.4771L59.2817 73.7464C59.6754 73.645 60.0931 73.7044 60.4432 73.9116C60.7932 74.1188 61.0471 74.4568 61.149 74.8516L67.1212 98.0844C67.2222 98.4793 67.163 98.8984 66.9565 99.2496C66.7499 99.6008 66.4129 99.8555 66.0195 99.9577Z"
          fill="#E6E6E6"
        />
        <path
          d="M78.0949 146.935L52.0781 153.665C51.6844 153.767 51.2667 153.707 50.9166 153.5C50.5665 153.293 50.3127 152.955 50.2108 152.56L44.2386 129.327C44.1376 128.932 44.1968 128.513 44.4033 128.162C44.6099 127.811 44.9468 127.556 45.3403 127.454L71.3571 120.723C71.7508 120.622 72.1685 120.681 72.5186 120.888C72.8687 121.096 73.1225 121.434 73.2245 121.828L79.1966 145.061C79.2976 145.456 79.2384 145.875 79.0319 146.226C78.8253 146.578 78.4884 146.832 78.0949 146.935Z"
          fill="#E6E6E6"
        />
        <path
          d="M90.1706 193.911L64.1538 200.642C63.7601 200.743 63.3424 200.684 62.9923 200.477C62.6422 200.269 62.3884 199.931 62.2864 199.537L56.3143 176.304C56.2133 175.909 56.2725 175.49 56.479 175.139C56.6856 174.787 57.0225 174.533 57.416 174.431L83.4328 167.7C83.8265 167.598 84.2442 167.658 84.5943 167.865C84.9444 168.072 85.1982 168.41 85.3002 168.805L91.2723 192.038C91.3734 192.433 91.3141 192.852 91.1076 193.203C90.901 193.554 90.5641 193.809 90.1706 193.911Z"
          fill="#E6E6E6"
        />
        <path
          d="M261.179 44.0223H111.045C105.955 44.0281 101.075 46.0592 97.4752 49.6702C93.8758 53.2811 91.8512 58.177 91.8455 63.2836V240.137C91.8512 245.244 93.8758 250.139 97.4752 253.75C101.075 257.361 105.955 259.392 111.045 259.398H261.179C266.269 259.392 271.149 257.361 274.749 253.75C278.348 250.139 280.373 245.244 280.379 240.137V63.2836C280.373 58.177 278.348 53.2811 274.749 49.6702C271.149 46.0592 266.269 44.0281 261.179 44.0223Z"
          fill="#E6E6E6"
        />
        <path
          d="M261.179 49.5673H111.045C107.42 49.5713 103.945 51.0178 101.382 53.5892C98.8184 56.1606 97.3766 59.6471 97.3726 63.2836V240.137C97.3766 243.773 98.8184 247.26 101.382 249.831C103.945 252.403 107.42 253.849 111.045 253.853H261.179C264.804 253.849 268.279 252.403 270.843 249.831C273.406 247.26 274.847 243.773 274.852 240.137V63.2836C274.847 59.6471 273.406 56.1607 270.843 53.5892C268.279 51.0178 264.804 49.5714 261.179 49.5673Z"
          fill="white"
        />
        <path
          d="M276.952 280.916C296.308 280.916 312 265.174 312 245.755C312 226.336 296.308 210.594 276.952 210.594C257.595 210.594 241.904 226.336 241.904 245.755C241.904 265.174 257.595 280.916 276.952 280.916Z"
          fill="#F6E3F0"
        />
        <path
          d="M293.714 241.169H281.523V228.939C281.523 227.723 281.042 226.556 280.184 225.696C279.327 224.836 278.164 224.353 276.952 224.353C275.739 224.353 274.576 224.836 273.719 225.696C272.862 226.556 272.38 227.723 272.38 228.939V241.169H260.189C258.977 241.169 257.814 241.652 256.957 242.512C256.1 243.372 255.618 244.539 255.618 245.755C255.618 246.971 256.1 248.138 256.957 248.998C257.814 249.858 258.977 250.341 260.189 250.341H272.38V262.571C272.38 263.787 272.862 264.954 273.719 265.814C274.576 266.674 275.739 267.157 276.952 267.157C278.164 267.157 279.327 266.674 280.184 265.814C281.042 264.954 281.523 263.787 281.523 262.571V250.341H293.714C294.926 250.341 296.089 249.858 296.946 248.998C297.804 248.138 298.285 246.971 298.285 245.755C298.285 244.539 297.804 243.372 296.946 242.512C296.089 241.652 294.926 241.169 293.714 241.169Z"
          fill="white"
        />
        <path
          d="M238.584 146.748H162.662C162.242 146.748 161.826 146.666 161.437 146.505C161.049 146.344 160.696 146.107 160.399 145.809C160.102 145.512 159.866 145.158 159.705 144.768C159.544 144.379 159.461 143.962 159.461 143.54C159.461 143.119 159.544 142.701 159.705 142.312C159.866 141.922 160.102 141.569 160.399 141.271C160.696 140.973 161.049 140.737 161.437 140.576C161.826 140.415 162.242 140.332 162.662 140.333H238.584C239.431 140.334 240.243 140.672 240.842 141.273C241.441 141.875 241.777 142.69 241.777 143.54C241.777 144.39 241.441 145.206 240.842 145.807C240.243 146.408 239.431 146.747 238.584 146.748Z"
          fill="#E6E6E6"
        />
        <path
          d="M251.772 157.573H162.662C162.242 157.574 161.826 157.491 161.437 157.33C161.049 157.169 160.696 156.933 160.399 156.635C160.102 156.337 159.866 155.983 159.705 155.594C159.544 155.204 159.461 154.787 159.461 154.366C159.461 153.944 159.544 153.527 159.705 153.137C159.866 152.748 160.102 152.394 160.399 152.096C160.696 151.798 161.049 151.562 161.437 151.401C161.826 151.24 162.242 151.158 162.662 151.158H251.772C252.62 151.158 253.434 151.496 254.033 152.098C254.633 152.699 254.97 153.515 254.97 154.366C254.97 155.216 254.633 156.032 254.033 156.634C253.434 157.235 252.62 157.573 251.772 157.573Z"
          fill="#E6E6E6"
        />
        <path
          d="M238.584 195.261H162.662C162.242 195.262 161.826 195.179 161.437 195.018C161.049 194.857 160.696 194.621 160.399 194.323C160.102 194.025 159.866 193.672 159.705 193.282C159.544 192.893 159.461 192.475 159.461 192.054C159.461 191.632 159.544 191.215 159.705 190.826C159.866 190.436 160.102 190.082 160.399 189.785C160.696 189.487 161.049 189.25 161.437 189.089C161.826 188.928 162.242 188.846 162.662 188.846H238.584C239.432 188.846 240.245 189.184 240.845 189.786C241.444 190.387 241.781 191.203 241.781 192.054C241.781 192.905 241.444 193.72 240.845 194.322C240.245 194.923 239.432 195.261 238.584 195.261Z"
          fill="#E6E6E6"
        />
        <path
          d="M251.772 206.087H162.662C162.242 206.087 161.826 206.005 161.437 205.844C161.049 205.683 160.696 205.446 160.399 205.149C160.102 204.851 159.866 204.497 159.705 204.108C159.544 203.718 159.461 203.301 159.461 202.879C159.461 202.458 159.544 202.04 159.705 201.651C159.866 201.262 160.102 200.908 160.399 200.61C160.696 200.312 161.049 200.076 161.437 199.915C161.826 199.754 162.242 199.671 162.662 199.672H251.772C252.193 199.671 252.609 199.754 252.997 199.915C253.385 200.076 253.738 200.312 254.036 200.61C254.333 200.908 254.569 201.262 254.73 201.651C254.89 202.04 254.973 202.458 254.973 202.879C254.973 203.301 254.89 203.718 254.73 204.108C254.569 204.497 254.333 204.851 254.036 205.149C253.738 205.446 253.385 205.683 252.997 205.844C252.609 206.005 252.193 206.087 251.772 206.087Z"
          fill="#E6E6E6"
        />
        <path
          d="M145.655 162.487H118.787C118.381 162.487 117.991 162.325 117.704 162.036C117.417 161.748 117.255 161.357 117.254 160.949V136.956C117.255 136.549 117.417 136.158 117.704 135.869C117.991 135.581 118.381 135.419 118.787 135.418H145.655C146.062 135.419 146.452 135.581 146.739 135.869C147.026 136.158 147.188 136.549 147.189 136.956V160.949C147.188 161.357 147.026 161.748 146.739 162.036C146.452 162.325 146.062 162.487 145.655 162.487Z"
          fill="#E6E6E6"
        />
        <path
          d="M145.655 211.001H118.787C118.381 211 117.991 210.838 117.704 210.55C117.417 210.262 117.255 209.871 117.254 209.463V185.47C117.255 185.062 117.417 184.671 117.704 184.383C117.991 184.095 118.381 183.932 118.787 183.932H145.655C146.062 183.932 146.452 184.095 146.739 184.383C147.026 184.671 147.188 185.062 147.189 185.47V209.463C147.188 209.871 147.026 210.262 146.739 210.55C146.452 210.838 146.062 211 145.655 211.001Z"
          fill="#E6E6E6"
        />
        <path
          d="M238.668 92.7424H182.694C181.846 92.7424 181.033 92.4045 180.433 91.803C179.834 91.2014 179.497 90.3856 179.497 89.5349C179.497 88.6842 179.834 87.8684 180.433 87.2668C181.033 86.6653 181.846 86.3274 182.694 86.3274H238.668C239.516 86.3274 240.329 86.6653 240.929 87.2668C241.528 87.8684 241.865 88.6842 241.865 89.5349C241.865 90.3856 241.528 91.2014 240.929 91.803C240.329 92.4045 239.516 92.7424 238.668 92.7424Z"
          fill="#CCCCCC"
        />
        <path
          d="M251.857 103.568H182.694C182.274 103.568 181.858 103.485 181.471 103.324C181.083 103.163 180.73 102.926 180.433 102.628C180.136 102.331 179.901 101.977 179.74 101.588C179.58 101.199 179.497 100.782 179.497 100.36C179.497 99.9391 179.58 99.522 179.74 99.1329C179.901 98.7437 180.136 98.3901 180.433 98.0923C180.73 97.7944 181.083 97.5582 181.471 97.397C181.858 97.2358 182.274 97.1528 182.694 97.1528H251.857C252.705 97.1528 253.518 97.4908 254.118 98.0923C254.717 98.6938 255.054 99.5097 255.054 100.36C255.054 101.211 254.717 102.027 254.118 102.628C253.518 103.23 252.705 103.568 251.857 103.568Z"
          fill="#CCCCCC"
        />
        <path
          d="M170.306 116.687H118.703C118.297 116.686 117.907 116.524 117.619 116.236C117.332 115.948 117.17 115.557 117.17 115.149V74.7464C117.17 74.3386 117.332 73.9477 117.619 73.6593C117.907 73.371 118.297 73.2088 118.703 73.2084H170.306C170.713 73.2088 171.102 73.371 171.39 73.6593C171.677 73.9477 171.839 74.3386 171.839 74.7464V115.149C171.839 115.557 171.677 115.948 171.39 116.236C171.102 116.524 170.713 116.686 170.306 116.687Z"
          fill="#F6E3F0"
        />
      </g>
      <defs>
        <clipPath id="clip0_1208_29125">
          <rect width="312" height="280.916" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
