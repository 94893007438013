export { default as capture } from './Capture.png';
export { default as baby } from './babe.png';
export { default as img } from './img.png';
export { default as img1 } from './img1.png';
export { default as img2 } from './img2.png';
export { default as img4 } from './img4.png';
export { default as profile } from './profile.png';
export { default as FilterOption } from './filter.svg';
export { default as Base } from './Base.png';
export { default as SidebarLogo } from './sidebar_logo.png';
export { default as teacher } from './teacher.png';
export { default as AccordionUp } from './buttonIcon/accordian.svg';
export { default as lineIndicationOne } from './line_indication_one.png';
export { default as lineIndicationTwo } from './line_indication_two.png';
export { default as lineIndicationThree } from './line_indication_three.png';
export { default as lineIndicationFour } from './line_indication_four.png';
export { default as teacher2 } from './Teacher.svg';
export { default as student } from './student.svg';
export { default as studentQR } from './studentQR.png';
export { default as classrooms } from './classrooms.svg';
export { default as gems } from './gems.svg';
export { default as skillsAssigned } from './skillsAssigned.svg';
export { default as skillsCompleted } from './skillsCompleted.svg';
export { default as videos } from './videos.svg';
export { default as skills } from './skills.svg';
export { default as parents } from './parents.svg';
export { default as unitquiz } from './unitquiz.svg';
export { default as bellwork2 } from './bellwork.svg';
export { default as schoolLeader } from './schoolLeader.svg';
export { default as districts } from './districts.svg';
export { default as exitticket } from './exitticket.svg';
export { default as dailypracticearrow } from './dailypracticearrow.svg';
export { default as diagnostic } from './diagnostic.svg';
export { default as school } from './school.svg';
export { default as bellwork } from './classroom.png';
export { default as classroom } from './classroom.svg';
export { default as quizTotal } from './quizTotal.svg';
export { default as masteredSkill } from './masteredSkill.svg';
export { default as ticket } from './ticket.png';
export { default as quiz } from './quiz.png';
export { default as practice } from './practice.png';
