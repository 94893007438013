import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  notificationFail,
  notificationSuccess,
} from 'store/Slice/notificationSlice';
import { setLoading, setParentsList } from 'store/Slice/parentSlice';
import API from 'store/api';

export const getAllParents = createAsyncThunk(
  'getAllParents',
  async (_request: any, { dispatch }) => {
    try {
      if (_request.allowPagination) {
        const page = _request?.pageIndex || 0;
        const size = _request?.pageSize || 10;
        const search = _request?.search || '';
        let url = `/api/v1/parents/?page=${page + 1}&limit=${size}`;
        if (search) {
          url += `&query=${search}`;
        }
        const response: any = await API.get(url);
        setLoading(true);
        dispatch(setParentsList(response?.data?.data));
        setLoading(false);
        return response?.data?.data;
      } else {
        let url = '/api/v1/parents/';
        dispatch(setLoading(true));
        const response: any = await API.get(url);
        dispatch(setParentsList(response?.data?.data));
        dispatch(setLoading(false));
        return response?.data?.data;
      }
    } catch (err: any) {
      return err;
    }
  },
);

export const updateParent = createAsyncThunk(
  'updateParent',
  async (_request: any, { dispatch }) => {
    try {
      const { name, image, school, children, salutation, uuid } = _request;
      const formData = new FormData();

      if (_request.file) {
        formData.append('profileImage', image);
      }

      formData.append('name', name);
      formData.append('schoolId', school);
      formData.append('file', image);
      formData.append('children', children);
      formData.append('salutation', salutation);
      await API.patch(`/api/v1/parents/${uuid}`, formData);
    } catch (err: any) {
      dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);
export const updateUserStatus = createAsyncThunk(
  'updateUser',
  async ({ uuid, status }: { uuid: string; status: string }, { dispatch }) => {
    try {
      console.log('UUID', uuid);
      API.patch(`/api/v1/user/${uuid}`, { status });
      dispatch(notificationSuccess('User Status Updated Successfully'));
    } catch (err: any) {
      dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const removeParentChildren = createAsyncThunk(
  'updateUser',
  async (
    { parentId, userIds }: { parentId: string; userIds: string[] },
    { dispatch },
  ) => {
    try {
      API.post(`/api/v1/user/parents/${parentId}/remove/children`, {
        userIds,
      });
    } catch (err: any) {
      dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const getAllParentIds = createAsyncThunk(
  'getAllParents',
  async (_, { dispatch }) => {
    try {
      let url = '/api/v1/parents/all';
      const response: any = await API.get(url);
      return response?.data?.data;
    } catch (err: any) {
      return err;
    }
  },
);

export const deleteParent = createAsyncThunk(
  'deleteStudent',
  async (parentId: string, { dispatch }) => {
    try {
      const response: any = await API.delete(`api/v1/parents/${parentId}`);
      dispatch(notificationSuccess('Parent Deleted Successfully'));
      return response?.data?.data || [];
    } catch (err: any) {
      dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);
