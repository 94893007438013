import { createAsyncThunk } from '@reduxjs/toolkit';
import { getLinkPartFromRole } from 'helper/functions';
import API from 'store/api';
import { startLoading, stopLoading } from 'store/Slice/loadingSlice';
import { notificationFail } from 'store/Slice/notificationSlice';
import { setSchoolList } from 'store/Slice/schoolSlice';
import {
  setTutorList,
  setUserList,
  setParentList,
  setDomainList,
  setTeacherList,
  setStudentList,
  setSchoolLeaderList,
} from 'store/Slice/userSlice';

export const getUserList = createAsyncThunk(
  'getUserList',
  async (_, { dispatch }) => {
    let url = `/api/v1/users?limit=10000`;
    dispatch(startLoading());
    try {
      const response: any = await API.get(url);
      dispatch(setUserList(response.data.data));
      dispatch(stopLoading());
      return response;
    } catch (err: any) {
      dispatch(stopLoading());
      dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

type GetUserListRequest = {
  pageIndex?: number;
  pageSize?: number;
  search?: string;
  userType?: string;
  schoolId?: string;
};

export const getUserListAccordingToType = createAsyncThunk(
  'getUserList',
  async (request: GetUserListRequest, { dispatch }) => {
    dispatch(setUserList([]));

    const {
      pageIndex = 0,
      pageSize = 10,
      search = '',
      userType = '',
      schoolId = '',
    } = request;

    let url = `/api/v1/users?page=${
      pageIndex + 1
    }&limit=${pageSize}&type=${userType}`;

    dispatch(startLoading());
    try {
      if (search) {
        url += `&query=${search}`;
      }
      if (schoolId) {
        // get users list based on the user school id -> for school leader.
        url += `&school=${schoolId}`;
      }
      const response: any = await API.get(url);
      const userListSetter = (userType: string, data: any) => {
        switch (userType) {
          case 'teacher':
            dispatch(setTeacherList(data));
            break;
          case 'student':
            dispatch(setStudentList(data));
            break;
          case 'school_leader':
            dispatch(setSchoolLeaderList(data));
            break;
          case 'parent':
            dispatch(setParentList(data));
            break;
          default:
            return [];
        }
      };

      userListSetter(userType, response?.data?.data);
      dispatch(stopLoading());
      return response?.data?.data;
    } catch (err: any) {
      dispatch(stopLoading());
      dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const getDomainList = createAsyncThunk(
  'getUserList',
  async (_request: any, { dispatch }) => {
    let url = _request.topicId
      ? `/api/v1/domains/all?topicId=${_request.topicId}`
      : `/api/v1/domains/all`;
    dispatch(startLoading());
    try {
      const response: any = await API.get(url);
      dispatch(setDomainList(response.data.data));
      dispatch(stopLoading());
      return response;
    } catch (err: any) {
      dispatch(stopLoading());
      dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);
export const getTutorList = createAsyncThunk(
  'getUserList',
  async (_, { dispatch }) => {
    let url = `/api/v1/tutors`;
    dispatch(startLoading());
    try {
      const response: any = await API.get(url);
      dispatch(setTutorList(response.data.data));
      dispatch(stopLoading());
      return response;
    } catch (err: any) {
      dispatch(stopLoading());
      dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

interface UnassignedCall {
  schoolId: string;
  classId: string;
  type: string;
}

export const getUnAssignedUsersList = createAsyncThunk(
  'getUserList',
  async (request: UnassignedCall, { dispatch }) => {
    let url = `/api/v1/schools/${request.schoolId}/students/${request.classId}/?type=${request.type}`;
    try {
      if (request?.schoolId && request?.classId) {
        const response: any = await API.get(url);
        return response;
      }
    } catch (err: any) {
      dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);
