import {
  Box,
  Breadcrumbs,
  Card,
  Checkbox,
  Stack,
  Typography,
} from '@mui/material';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Instructional from '../indtractional';
import ReadMore from 'components/ReadMore/ReadMore';
import { getVideoList } from 'store/Thunk/videoThunk';
import DefaultCell from 'components/DefaultCell';
import { startLoading } from 'store/Slice/loadingSlice';
import { useAppDispatch, useAppSelector } from 'store/store';
import TableWrapper from 'components/tableWrapper';
import CustomTable from 'components/customTable';
import { displayMultipleValue, getLocalDateTime } from 'helper/service';
import SharedButton from 'components/button';
import AssignDeselect from '../AssignDeselect';
import { Container, ContentWrapper } from '../../styles';
import AssignConfirmationTable from './AssignConfirmationTable';
interface DialogProps {
  data?: any;
  selectedAssignments?: any;
  setAssignments?: any;
  assignments?: any;
  onClose?: () => void;
  onOk?: () => void;
}
export default function AssignConfirmationImported({
  onClose,
  data,
  setAssignments,
  assignments,
  selectedAssignments,
}: DialogProps) {
  const [selectedId, setSelectedId] = useState('');
  const handleDeselect = () => setDeSelectedContent(true);
  const handleDeselectCancel = () => setDeSelectedContent(false);

  const [deSelectedContent, setDeSelectedContent] = useState<any>(false);
  const [confirmDeselect, setConfirmDeselect] = useState(false);
  const handleCloseDeselect = (itemID: string) => {
    console.log('selectedId', selectedId);
    setDeSelectedContent(false);
    setConfirmDeselect(true);
    setSelectedItems((value) => value.filter((item) => item !== itemID));
    setQuizzes((prevQuizzes) =>
      prevQuizzes.filter((item) => item.id !== itemID),
    );
    // handleAssignContent();
  };
  const dispatch = useAppDispatch();

  const navigate: any = useNavigate();
  const [pageCount, setPageCount] = useState(0);
  const [quizzes, setQuizzes] = useState<any[]>([]);

  const [videos, setVideos] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([
    '05d27c4e-2b07-4170-89bd-1cd247718e4a',
    '047ca921-0009-4ff9-a686-504b97e7e355',
    '0402cece-cdc7-4c17-b089-f0185fa519a5',
  ]);
  const [foundedItems, setFoundedItems] = useState<any>({});
  const [pageNo, setPageNo] = useState(0);
  const [count, setCount] = useState<number>(0);

  console.log('selectedItems', selectedItems);
  function onConfirmDeselect(
    itemID: string,
    e: React.ChangeEvent<HTMLInputElement>,
  ) {
    handleDeselect();
  }
  function onItemSelect(
    itemID: string,
    e: React.ChangeEvent<HTMLInputElement>,
  ) {
    console.log('selectedId', itemID);
    setSelectedId(() => itemID);
    handleDeselect();
    console.log('selectedId', selectedId);
  }

  const breadcrumbs = [
    <Link to={''} key="1" color="inherit">
      School Management
    </Link>,
    <Link to={''} key="1" color="inherit">
      Classroom
    </Link>,
    <Link to={''} key="1" color="inherit">
      Grade 6
    </Link>,
    <Link to={''} key="1" color="inherit">
      Classroom Name - Assignments
    </Link>,
    <Typography key="2" color="#C73E9D" onClick={() => navigate(-1)}>
      Confirmation of Assignments
    </Typography>,
  ];
  const header = [{ label: '01 Quarter', width: 1, value: 'name' }];

  const color = '#E9F6F3';

  return (
    <div>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        style={{ marginBottom: 20 }}
      >
        {breadcrumbs}
      </Breadcrumbs>
      <Container>
        <Card>
          <ContentWrapper className="!w-full !flex-col !gap-0">
            <Stack gap={8} mb={8} className=" w-full flex-1">
              <Typography
                sx={{
                  color: '#20A58A',
                  fontSize: '23px',
                  fontWeight: '700',
                  lineHeight: '23px',
                }}
              >
                Summary
              </Typography>
              <Stack className="!flex-row justify-between w-full flex-1">
                <Stack className="gap-4">
                  <Typography className="!font-normal !text-sm">
                    Assign To
                  </Typography>
                  <Typography className="!font-medium !text-lg !text-black">
                    Whole class
                  </Typography>
                </Stack>
                <Stack className="gap-4">
                  <Typography className="!font-normal !text-sm">
                    Assignment Type
                  </Typography>
                  <Typography className="!font-medium !text-lg !text-black">
                    Bellwork
                  </Typography>
                </Stack>
                <Stack className="gap-4">
                  <Typography className="!font-normal !text-sm">
                    Start Date
                  </Typography>
                  <Typography className="!font-medium !text-lg !text-black">
                    06/24/2021
                  </Typography>
                </Stack>
                <Stack className="gap-4">
                  <Typography className="!font-normal !text-sm">
                    End Date
                  </Typography>
                  <Typography className="!font-medium !text-lg !text-black">
                    06/24/2021
                  </Typography>
                </Stack>
                <Stack className="gap-4">
                  <Typography className="!font-normal !text-sm">
                    Time Duration
                  </Typography>
                  <Typography className="!font-medium !text-lg !text-black">
                    5 minutes
                  </Typography>
                </Stack>
                <Stack className="gap-4">
                  <Typography className="!font-normal !text-sm">
                    Videos
                  </Typography>
                  <Typography className="!font-medium !text-lg !text-black">
                    05
                  </Typography>
                </Stack>
                <Stack className="gap-4">
                  <Typography className="!font-normal !text-sm">
                    Quiz
                  </Typography>
                  <Typography className="!font-medium !text-lg !text-black">
                    05
                  </Typography>
                </Stack>
                <Stack className="gap-4">
                  <Typography className="!font-normal !text-sm">
                    Pin Activated
                  </Typography>
                  <Typography className="!font-medium !text-lg !text-black">
                    YES
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Box className="m-2">
              <AssignConfirmationTable
                color={color}
                openDefault={true}
                classes={selectedAssignments}
                header={header}
                assignments={assignments}
                setAssignments={setAssignments}
              />
            </Box>

            <Box className="flex justify-end gap-4 w-full mt-8">
              <SharedButton
                variant="outlined"
                name="Back"
                className="primary_bg_btn_outlined"
              />
              <SharedButton
                onClick={onClose}
                variant="contained"
                name="Assign"
                className="primary_bg_btn"
              />
            </Box>
          </ContentWrapper>
        </Card>
      </Container>
      <AssignDeselect
        open={deSelectedContent}
        itemID={selectedId}
        onClose={() => handleCloseDeselect(selectedId)}
        onCancel={handleDeselectCancel}
      />
    </div>
  );
}
