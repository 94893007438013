import React, { useState } from 'react';
import Card from '@mui/material/Card';

import Typography from '@mui/material/Typography';
import { EditIcon, DeleteIcon, ViewIcon } from 'icons';
import EyeBorderIcon from 'icons/eyeBorderIcon';
import DeleteBorderIcon from 'icons/deleteBorderIcon';
import EditBorderIcon from 'icons/editBorderIcon';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';

// import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';

import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Box, Pagination, TextField } from '@mui/material';
import { Bell } from 'mdi-material-ui';
import { VisibilityOutlined } from '@mui/icons-material';

export interface Header {
  label: string;
  width: number;
  value: string;
}
export interface IStudent {
  id?: string;
  name?: string;
  skill?: string;
  topic?: string;
  subTopic?: string;
  assignmentType?: string;
  totalGems?: string;
  pending?: string;
  status?: string;
  startDate?: string;
  endDate?: string;
}

export interface IClasses {
  id?: string;
  name?: string;
  totalAssignments?: string;
  completedAssignments?: string;
  pendingAssignments?: string;
  totalGems?: string;
  interVentionRecommended?: string;
  averageScore?: string;
  studentCount?: string;
  target?: string;
  Assignments?: IStudent[];
}

const StudentsList = ({ students }: { students: IStudent[] | undefined }) => {
  let stud: IStudent[] = [];
  if (students && students.length > 0) {
    stud = students;
  }
  return (
    <List component="div" disablePadding>
      <Box sx={{ m: 2, mb: 4 }}>
        <ListItem sx={{ pl: 4 }}>
          {/* grid for listing student properties */}
          <Grid
            container
            spacing={2}
            sx={{
              '& .MuiGrid-item': {
                display: 'flex',
                alignItems: 'center',
              },
            }}
          >
            <Grid item md={1.4} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography fontSize={'14px'}>Assignment Type</Typography>
            </Grid>
            <Grid item md={2.5}>
              <Typography fontSize={'14px'}>Skill</Typography>
            </Grid>
            {/* <Grid item md={1}>
              <Typography fontSize={'14px'}>Topic</Typography>
            </Grid> */}
            <Grid item md={2}>
              <Typography fontSize={'14px'}>Sup-Topics</Typography>
            </Grid>
            <Grid item md={1.1} justifyContent={'center'}>
              <Typography fontSize={'14px'}>Total $G3MS</Typography>
            </Grid>
            <Grid item md={1.5}>
              <Typography fontSize={'14px'}>Start Date</Typography>
            </Grid>
            <Grid item md={1.5}>
              <Typography fontSize={'14px'}>Due Date</Typography>
            </Grid>
            <Grid item md={2}>
              <Typography fontSize={'14px'}>Grade or Progress</Typography>
            </Grid>
          </Grid>
        </ListItem>
      </Box>
      {stud.map((student) => (
        <Box sx={{ m: 2, mb: 4 }}>
          <ListItem sx={{ pl: 4 }}>
            {/* grid for listing student properties */}
            <Grid
              container
              spacing={2}
              sx={{
                '& .MuiGrid-item': {
                  display: 'flex',
                  alignItems: 'center',
                },
              }}
            >
              <Grid item md={1.4} sx={{ display: 'flex' }}>
                <span>
                  <Bell style={{ color: '#20A58A' }} />
                </span>
                <Typography
                  ml={1}
                  variant="subtitle1"
                  color={'#131515'}
                  fontWeight={'500'}
                  fontSize={'14px'}
                  lineHeight={'14px'}
                >
                  {student?.assignmentType}
                </Typography>
              </Grid>
              <Grid item md={2.5}>
                <Typography
                  variant="subtitle1"
                  color={'#131515'}
                  fontWeight={'500'}
                  fontSize={'14px'}
                  lineHeight={'14px'}
                >
                  {student?.skill || 'N/A'}
                </Typography>
              </Grid>
              {/* <Grid item md={1}>
                <Typography
                  variant="subtitle1"
                  color={'#131515'}
                  fontWeight={'500'}
                  fontSize={'14px'}
                  lineHeight={'14px'}
                >
                  {student?.topic || 'N/A'}
                </Typography>
              </Grid> */}
              <Grid item md={2} justifyContent={'center'}>
                <Typography
                  variant="subtitle1"
                  color={'#131515'}
                  fontWeight={'500'}
                  fontSize={'14px'}
                  lineHeight={'14px'}
                >
                  {student?.subTopic || 'N/A'}
                </Typography>
              </Grid>
              <Grid item md={1.1} justifyContent={'center'}>
                <Typography
                  variant="subtitle1"
                  color={'#131515'}
                  fontWeight={'500'}
                  fontSize={'14px'}
                  textAlign="center"
                  lineHeight={'14px'}
                >
                  {student?.totalGems}
                </Typography>
              </Grid>
              <Grid item md={1.5}>
                <Typography
                  variant="subtitle1"
                  color={'#131515'}
                  fontWeight={'500'}
                  fontSize={'14px'}
                  lineHeight={'14px'}
                >
                  {student?.startDate}
                </Typography>
              </Grid>
              <Grid item md={1.5}>
                <Typography
                  variant="subtitle1"
                  color={'#131515'}
                  fontWeight={'500'}
                  fontSize={'14px'}
                  lineHeight={'14px'}
                >
                  {student?.endDate}
                </Typography>
              </Grid>
              <Grid item md={0.7}>
                <Typography
                  variant="subtitle1"
                  color={'#131515'}
                  fontWeight={600}
                  fontSize={'12px'}
                  lineHeight={'14px'}
                  sx={{
                    color:
                      student?.status === 'todo'
                        ? '#B42318'
                        : student?.status === 'done'
                        ? '#027A48'
                        : '#B54708',
                    backgroundColor:
                      student?.status === 'todo'
                        ? '#FEF3F2'
                        : student?.status === 'done'
                        ? '#ECFDF3'
                        : '#FFFAEB',
                    padding: '5px 8px',
                    borderRadius: '16px',
                  }}
                >
                  {student?.status === 'todo'
                    ? 'todo'
                    : student?.status === 'done'
                    ? 'completed'
                    : 'pending'}
                </Typography>
              </Grid>
              <Grid item md={1.3}>
                <IconButton
                  style={{
                    color: '#20A58A',
                    marginRight: '-11px',
                    padding: '8px',
                  }}
                  edge="end"
                  aria-label="comments"
                >
                  <EditBorderIcon />
                </IconButton>
                <IconButton
                  style={{
                    color: '#20A58A',
                    marginRight: '-11px',
                    padding: '8px',
                  }}
                  edge="end"
                  aria-label="comments"
                >
                  <DeleteBorderIcon />
                </IconButton>
                <IconButton
                  style={{
                    color: '#20A58A',
                    marginRight: '-11px',
                    padding: '8px',
                  }}
                  edge="end"
                  aria-label="comments"
                >
                  <EyeBorderIcon />
                </IconButton>
              </Grid>
            </Grid>
          </ListItem>
        </Box>
      ))}
    </List>
  );
};

export default function StudentProgressTable({
  classes,
  color,
  pageIndex,
  pageSize,
  header,
  pageCount,
  showPagination,
  totalCount,
  entriesPerPage,
}: {
  classes?: any;
  pageCount?: any;
  pageIndex?: any;
  pageSize?: any;
  showPagination?: any;
  totalCount?: any;
  color?: string;
  entriesPerPage?: any;
  header?: Header[];
}) {
  const [open, setOpen] = React.useState(false);
  console.log('classes', classes);
  console.log('classes', header);
  const [search, setSearch] = useState('');

  const renderPagination = () => (
    <Pagination
      count={pageCount || 10}
      page={pageIndex || 4 + 1}
      shape="rounded"
      // onChange={handleChangePage}
    />
  );
  const setEntriesPerPage = (value: any) => {
    // fetchData({
    //   pageIndex: pageIndex,
    //   pageSize: value,
    //   search: search,
    // });
    // setPageSize(value);
  };

  const onSearchChange = (value: any) => {
    setSearch(value); // Update the search state with the current input value.
  };
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      {classes?.map((classes: any, i: number) => (
        <div
          style={{
            border: open ? '1px solid #DCDCDC' : 'none',
            borderRadius: '10px 10px 0px 0px',
            marginBottom: '16px',
          }}
        >
          <Card
            style={{
              backgroundColor: color,
              borderRadius: '10px 10px 0px 0px',
              boxShadow:
                '0px 2px 1px -1px rgba(76, 78, 100, 0.2),0px 1px 1px 0px rgba(76, 78, 100, 0.14),0px 1px 3px 0px rgba(76, 78, 100, 0.12)',
            }}
          >
            <ListItemButton
              style={{ padding: '3px 12px' }}
              onClick={handleClick}
            >
              {/* <ListItemIcon>
                        class
                    </ListItemIcon> */}

              <Grid
                container
                spacing={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  color: '#131515',
                  '& .css-6uzn28-MuiTypography-root': {
                    color: '#131515',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '22px',
                  },
                }}
              >
                {header?.map((head) => (
                  <Grid item>
                    {head && classes && (
                      <>
                        <Typography
                          color="#84818A"
                          fontSize={'14px'}
                          fontWeight={400}
                          gutterBottom
                        >
                          {head?.label}
                        </Typography>
                        <Typography fontWeight={600} color={'#131515'}>
                          {classes[head?.value] || 'N/A'}
                        </Typography>
                      </>
                    )}
                  </Grid>
                ))}
                <Grid
                  item
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {open ? <ExpandLess /> : <ExpandMore />}
                </Grid>
              </Grid>
            </ListItemButton>
          </Card>

          <Collapse in={open} timeout="auto" unmountOnExit>
            <StudentsList students={classes?.Assignments} />
          </Collapse>
        </div>
      ))}
      {/* {showPagination && ( */}
      {true && (
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          my={4}
          sx={{ border: '1px solid #E9EBED', borderRadius: '6px', p: 2 }}
        >
          {/* {entriesPerPage && ( */}
          {true && (
            <Box flex="1" display="flex" alignItems="center">
              <Typography variant="body2" className="entries-page-txt">
                Show&nbsp;&nbsp;
              </Typography>
              <Autocomplete
                disableClearable
                value={pageSize?.toString()}
                options={['10', '25', '50', '100']}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10));
                }}
                size="small"
                sx={{ width: '5rem' }}
                renderInput={(params) => <TextField {...params} />}
              />
              <Typography variant="body2" className="entries-page-txt">
                &nbsp;&nbsp;entries of {totalCount}
              </Typography>
            </Box>
          )}
          {renderPagination()}
        </Box>
      )}
    </>
  );
}
