import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

export interface ResponseData {
  status: String;
  messagecode: String;
  message: String;
  data: any;
}

interface IntialState {
  teacherList: any;
  topicList: any;
  subtopicList: any;
  skillList: any;
  gradeList: any;
  videoList: any;
  quarterList: any;
  assetList: any;
  videoObj: any;
  uploadUrl: any;
  loading: boolean;
}

const intialState: IntialState = {
  teacherList: [],
  topicList: [],
  subtopicList: [],
  skillList: [],
  gradeList: [],
  assetList: [],
  videoList: [],
  quarterList: [],
  videoObj: {},
  uploadUrl: '',
  loading: false,
};

const VideoSlice = createSlice({
  name: 'video_slice',
  initialState: intialState,
  reducers: {
    setTeacherList: (state: Draft<IntialState>, action: PayloadAction<[]>) => {
      state.teacherList = action.payload;
    },
    setTopicList: (state: Draft<IntialState>, action: PayloadAction<[]>) => {
      state.topicList = action.payload;
    },
    setSubTopicList: (state: Draft<IntialState>, action: PayloadAction<[]>) => {
      state.subtopicList = action.payload;
    },
    setSkillList: (state: Draft<IntialState>, action: PayloadAction<[]>) => {
      state.skillList = action.payload;
    },
    setGradeList: (state: Draft<IntialState>, action: PayloadAction<[]>) => {
      state.gradeList = action.payload;
    },
    setAssetList: (state: Draft<IntialState>, action: PayloadAction<[]>) => {
      state.assetList = action.payload;
    },
    setVideoList: (state: Draft<IntialState>, action: PayloadAction<[]>) => {
      state.videoList = action.payload;
    },
    setQuarterList: (state: Draft<IntialState>, action: PayloadAction<any>) => {
      state.quarterList = action.payload;
    },
    setVideoById: (state: Draft<IntialState>, action: PayloadAction<any>) => {
      state.videoObj = action.payload;
    },
    setMuxUploadUrl: (state: Draft<IntialState>, action: PayloadAction<[]>) => {
      state.uploadUrl = action.payload;
    },
    setLoading: (state: Draft<IntialState>, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const {
  setTeacherList,
  setTopicList,
  setSubTopicList,
  setSkillList,
  setGradeList,
  setVideoList,
  setQuarterList,
  setAssetList,
  setVideoById,
  setMuxUploadUrl,
  setLoading,
} = VideoSlice.actions;

export default VideoSlice.reducer;
