import React from 'react';

export default function editBlackSVG() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="18" height="17" transform="translate(0 0.5)" fill="white" />
      <path
        d="M14.241 1.92383L16.491 4.04883L14.7757 5.6695L12.5257 3.54449L14.241 1.92383ZM6 11.832H8.25L13.7152 6.67037L11.4652 4.54537L6 9.707V11.832Z"
        fill="#131515"
      />
      <path
        d="M14.25 13.9583H6.1185C6.099 13.9583 6.07875 13.9654 6.05925 13.9654C6.0345 13.9654 6.00975 13.959 5.98425 13.9583H3.75V4.04167H8.88525L10.3853 2.625H3.75C2.92275 2.625 2.25 3.25967 2.25 4.04167V13.9583C2.25 14.7403 2.92275 15.375 3.75 15.375H14.25C14.6478 15.375 15.0294 15.2257 15.3107 14.9601C15.592 14.6944 15.75 14.3341 15.75 13.9583V7.8185L14.25 9.23517V13.9583Z"
        fill="#131515"
      />
    </svg>
  );
}
