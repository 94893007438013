import { Chip, Checkbox, CircularProgress } from '@mui/material';
import { Stack, minWidth } from '@mui/system';
import DefaultCell from 'components/DefaultCell';
import ReadMore from 'components/ReadMore/ReadMore';
import CustomTable from 'components/customTable';
import StudentProgressTable from 'components/student/progress/table';
import { set } from 'date-fns';
import { typesColors } from 'helper/constants';
import {
  itemExistsWithType,
  removeItemByID,
  updateOrAddType,
} from 'helper/functions';
import { displayMultipleValue, getLocalDateTime } from 'helper/service';
import { _assignmentTypes } from 'helper/types';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { startLoading } from 'store/Slice/loadingSlice';
import { checkExistence } from 'store/Thunk/assignContentThunk';
import { getVideoList } from 'store/Thunk/videoThunk';
import { useAppDispatch, useAppSelector } from 'store/store';
import TableHeader from 'components/tableHeader';
import Instructional from './instractional';

interface propsType {
  setSelectedContent: (value: any) => void;
  selectedContent: any;
  data: any;
  setData: any;
  selectiveInfo: any;
  setInfoModal: any;
}

const InstructionalCalanderImported = ({
  setSelectedContent,
  selectedContent,
  selectiveInfo,
  data,
  setData,
  setInfoModal,
}: propsType) => {
  const urlParams = new URLSearchParams(window.location.search);
  const assignmentType = urlParams.get('assignmentType');
  const assignmentFrom = urlParams.get('type');
  const header = [{ label: '01 Quarter', width: 1, value: 'name' }];
  const classes = [
    {
      id: '1',
      name: 'Shana Cooper',
      totalAssignments: 12,
      completedAssignments: 5,
      pendingAssignments: 7,
      totalGems: 100,
      totalWatchHours: '100',
      interVentionRecommended: 'YES',
      averageScore: 121,
      Assignments: [
        {
          id: 'string',
          assignmentType: 'Bellwork',
          skill: '90A - Convert fractions or mixed numbers to decimals',
          topic: 'Master Reading',
          totalGems: 2,
          subTopic: '23 - Synonyms & antonyms',
          startDate: 'October 23, 2023',
          endDate: 'October 23, 2023',
        },
      ],
    },
    {
      id: '1',
      name: 'James',
      totalAssignments: 15,
      completedAssignments: 8,
      pendingAssignments: 7,
      totalGems: 200,
      totalWatchHours: '100',
      interVentionRecommended: 'YES',
      averageScore: 121,
      Assignments: [
        {
          id: 'string',
          assignmentType: 'Bellwork',
          skill: '90A - Convert fractions or mixed numbers to decimals',
          topic: 'Master Reading',
          subTopic: '23 - Synonyms & antonyms',
          totalGems: 2,
          startDate: 'October 23, 2023',
          endDate: 'October 23, 2023',
        },
      ],
    },
  ];
  const color = '#E9F6F3';

  return (
    <Stack>
      <TableHeader
        item={{
          action: true,
          onCloseFilter: console.log(true),
        }}
      />
      <Instructional color={color} classes={classes} header={header} />
    </Stack>
  );
};

export default InstructionalCalanderImported;
