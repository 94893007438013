import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import {
  getDashboardInfo,
  getSchoolLeaderDashboardTeachers,
  getSchoolLeaderDashboardProgress,
  getSchoolLeaderDashboardSkillsMastered,
} from '../../../store/Thunk/dashboardThunk';
import { CardInfo } from '../../../components/Dashboard/cardInfo';

import Icon from '../../super_admin/dashboard/Frame_1261155075.png';
import Icon1 from '../../super_admin/dashboard/assets/icon-1.svg';
import Icon2 from '../../super_admin/dashboard/assets/icon-2.svg';
import Icon3 from '../../super_admin/dashboard/assets/icon-3.svg';
import Icon4 from '../../super_admin/dashboard/assets/icon-4.svg';
import Icon5 from '../../super_admin/dashboard/assets/icon-5.svg';
import Icon6 from '../../super_admin/dashboard/assets/icon-6.svg';
import Icon7 from '../../super_admin/dashboard/assets/icon-7.svg';
import Icon8 from '../../super_admin/dashboard/assets/icon-8.svg';
import Icon9 from '../../super_admin/dashboard/assets/icon-9.svg';
import Icon10 from '../../super_admin/dashboard/assets/icon-10.svg';
import Icon11 from '../../super_admin/dashboard/assets/icon-11.svg';
import Icon12 from '../../super_admin/dashboard/assets/icon-12.svg';
import SkillsMasteredChart from '../../../components/v2_components/Admin_Panel_Dashboards/skills_chart';
import {
  scrambledData,
  tabs,
} from '../../../components/v2_components/Admin_Panel_Dashboards/skills_chart/helpers/helpers';
import DetailedProgressTable from '../../../components/v2_components/Admin_Panel_Dashboards/detailed_progress_table';
import { Grid } from '@mui/material';

function Index() {
  const dispatch: any = useAppDispatch();
  const { teachers, totalCounts, teacherProgress, skillsMastered } =
    useAppSelector((state: any) => state.dashboardReducer);

  const header: any = localStorage.getItem('userData');
  const id: string = JSON.parse(header)?.id || '';
  const currentDate = new Date();
  const previousDay = new Date(currentDate);
  previousDay.setDate(currentDate.getDate() - 1);
  useEffect(() => {
    dispatch(getDashboardInfo({ id: id }));
    dispatch(getSchoolLeaderDashboardTeachers({ id: id }));
    dispatch(getSchoolLeaderDashboardProgress({ id: id }));
    dispatch(
      getSchoolLeaderDashboardSkillsMastered({ id: id, date: previousDay }),
    );
  }, []);
  console.log('totalCounts', totalCounts);

  const data = [
    {
      title: 'Total Students',
      icon: Icon1,
      count: totalCounts?.totalStudents || 0,
      md: 3,
    },
    {
      title: 'Total Teachers',
      icon: Icon2,
      count: totalCounts?.totalTeachers || 0,
      md: 3,
    },
    {
      title: 'Total Parents',
      count: totalCounts?.totalParents || 0,
      icon: Icon3,
      md: 3,
    },
    {
      title: 'Total Classrooms',
      icon: Icon4,
      count: totalCounts?.totalClassrooms || 0,
      md: 3,
    },
    {
      title: 'Bellwork',
      count: totalCounts?.schoolAssignmentBellwork
        ? totalCounts?.schoolAssignmentBellwork + '%'
        : '' || '0%',
      icon: Icon5,
      md: 3,
    },
    {
      title: 'Exit Ticket',
      count: totalCounts?.schoolAssignmentExitTicket
        ? totalCounts?.schoolAssignmentExitTicket + '%'
        : '' || '0%',
      icon: Icon6,
      md: 3,
    },
    {
      title: 'Unit Quiz',
      count: totalCounts?.schoolAssignmentUnitQuiz
        ? totalCounts?.schoolAssignmentUnitQuiz + '%'
        : '' || '0%',
      icon: Icon8,
      md: 3,
    },
    {
      title: 'Unit Test',
      count: totalCounts?.schoolAssignmentUnitTest
        ? totalCounts?.schoolAssignmentUnitTest + '%'
        : '' || '0%',
      icon: Icon7,
      md: 3,
    },
    {
      title: 'Daily Practice',
      count: totalCounts?.dailyPractices
        ? totalCounts?.dailyPractices + '%'
        : '' || '0%',
      icon: Icon9,
      md: 3,
    },
    {
      title: 'Diagnostics',
      count: totalCounts?.schoolAssignmentDiagnostic
        ? totalCounts?.schoolAssignmentDiagnostic + '%'
        : '' || '0%',
      icon: Icon10,
      md: 3,
    },
    {
      title: 'Total Videos Watched',
      count: totalCounts?.watchedVideos || 0,
      icon: Icon11,
      md: 3,
    },
    {
      title: 'Total G3MS Earned',
      count: totalCounts?.totalGemsEarned || 0,
      icon: Icon12,
      md: 3,
    },
  ];
  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: '22px',
        marginTop: '1.5rem',
        width: '100%',
        overflowX: 'auto',
      }}
    >
      <Grid container spacing={4} sx={{ mt: 3, mb: 4 }}>
        {data?.map((info: any) => (
          <CardInfo
            item={{
              title: ` ${info?.title}`,
              content: `${info?.count || 0}`,
              icon: `${info?.icon}`,
              color: `${info?.color}`,
              sm: info?.sm || 4,
              md: info?.md || 4,
            }}
          />
        ))}
      </Grid>
      <SkillsMasteredChart
        data={skillsMastered.length ? skillsMastered : scrambledData}
        tabs={tabs}
      />
      <DetailedProgressTable
        sectionTitle="Progress by Teachers and Classrooms "
        leftVerticalBar={true}
        progressData={teacherProgress}
      />
    </div>
  );
}

export default Index;
