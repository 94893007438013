import { Box, IconButton, Typography } from '@mui/material';
import DefaultCell from 'components/DefaultCell';
import CustomTable from 'components/customTable';
import '../../user_management/index.scss';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { useOnClickOutside } from 'hooks/useOnClickOutside';

import { setTeacherList } from 'store/Slice/videoSlice';
import { useAppDispatch, useAppSelector } from 'store/store';
import { getUserListAccordingToType } from 'store/Thunk/userThunk';
import CrudPopUp from 'components/popUp/crudPopup';
import AvatartWithText from 'components/AvatarWithText';
import { formatDate, getLocalDateTime } from 'helper/service';
import ReadMore from 'components/ReadMore/ReadMore';
import { useNavigate } from 'react-router-dom';
import { getLinkPartFromRole } from 'helper/functions';
import { deleteStudent } from 'store/Thunk/studentThunk';
import DeleteUserDialog from 'components/dialog/users/Delete/deleteUser';

const ClassItem = ({
  title,
  count,
  itemKey,
}: {
  title: string;
  count: number;
  itemKey: number;
}) => {
  return (
    <Box>
      {itemKey < 2 && (
        <Box
          sx={{
            background: '#F8F8F8',
            borderRadius: '4px',

            fontWeight: 400,
            p: 1,
          }}
        >
          <span>{title}</span>
        </Box>
      )}
    </Box>
  );
};

function Student({ userList }: any) {
  const navigate = useNavigate();
  const [activeButtonRow, setActiveButtonRow] = useState<any>(null);
  const dropDownRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(dropDownRef, () => setActiveButtonRow(null));
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPageSize] = useState(10);
  const [search, setSearch] = useState<any>(null);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const [selectedUserToDelete, setSelectedUserToDelete] = useState({
    dependentOfUser: {
      name: '',
      profileImage: '',
    },
    id: '',
  });
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const initial = getLinkPartFromRole();

  const { studentList } = useAppSelector((state) => state.userReducer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    let schoolId = '';
    const intial = getLinkPartFromRole();
    if (intial === 'school-leader') {
      schoolId =
        localStorage.getItem('teacherSchool') ||
        localStorage.getItem('current_schoolId') ||
        '';
    }
    dispatch(getUserListAccordingToType({ userType: 'student', schoolId }));
  }, []);

  useEffect(() => {
    if (studentList?.items && studentList?.meta) {
      setPageCount(studentList?.meta?.totalPages);
      setTotalCount(studentList?.meta?.totalCount);
    }
  }, [studentList]);

  const handleEventClick = (rowIndex: number) => {
    setActiveButtonRow(rowIndex);
  };

  useEffect(() => {
    if (studentList?.items?.length > 0) {
      setPageCount(studentList?.meta?.totalPages);
      setTotalCount(studentList?.meta?.totalCount);
    }
  }, [studentList]);

  const defaultCellStyle = {};

  const handleSubmit = () => {
    setDeleteLoading(true);
    dispatch(deleteStudent(selectedUserToDelete.id))
      .unwrap()
      .then(() => {
        setOpenDeleteModal(false);
        dispatch(
          getUserListAccordingToType({
            pageSize: perPage,
            pageIndex: pageNo,
            search,
            userType: 'student',
          }),
        );
      })
      .catch(() => {})
      .finally(() => {
        setDeleteLoading(false);
        setSelectedUserToDelete({
          dependentOfUser: {
            name: '',
            profileImage: '',
          },
          id: '',
        });
      });
  };

  const columns = [
    {
      Header: 'StudentId',
      accessor: 'studentId',
      Cell: ({ value }: any) => (
        <DefaultCell sx={defaultCellStyle} value={value} />
      ),
    },
    {
      Header: 'Grade',
      accessor: 'grade',
      Cell: ({ value }: any) => (
        <DefaultCell sx={defaultCellStyle} minWidth="50px" value={value} />
      ),
    },
    {
      Header: 'Name',
      accessor: 'studentInfo',
      Cell: ({ value }: any) => <AvatartWithText value={value} />,
    },
    {
      Header: 'Parent',
      accessor: 'parentInfo',
      Cell: ({ value }: any) => <AvatartWithText value={value} />,
    },
    {
      Header: 'Email',
      accessor: 'email',
      Cell: ({ value }: any) => (
        <ReadMore
          maxLength={20}
          sx={{ ...defaultCellStyle, minWidth: '150px' }}
          text={value}
        />
      ),
    },

    {
      Header: 'Classrooms',
      accessor: 'classroom',
      Cell: ({ value }: any) => (
        <>
          {value.length > 0 ? (
            <Box className="class_list">
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}
              >
                {value.map((item: any, index: number) => (
                  <ClassItem
                    itemKey={index}
                    title={item}
                    count={value.length}
                  />
                ))}
              </Box>
              <Box className="badge_head">
                {value.length > 2 && (
                  <span className="count_badge">
                    {'+' + (value.length - 2)}
                  </span>
                )}
              </Box>
            </Box>
          ) : (
            <Typography sx={{ minWidth: '145px' }} variant="body1">
              -
            </Typography>
          )}
        </>
      ),
    },
    {
      Header: 'Created Date',
      accessor: 'createdDate',
      Cell: ({ value }: any) => (
        <DefaultCell sx={defaultCellStyle} minWidth="145px" value={value} />
      ),
    },
    {
      Header: 'Managed By',
      accessor: 'managedBy',
      Cell: ({ value }: any) => (
        <DefaultCell sx={defaultCellStyle} minWidth="180px" value={value} />
      ),
    },
    {
      Header: 'Action',
      accessor: '',
      Cell: ({ row }: any) => (
        <>
          <IconButton onClick={() => handleEventClick(row.index)}>
            <MoreVertIcon />
          </IconButton>
          {activeButtonRow === row.index && (
            <CrudPopUp
              showEdit
              showDelete
              showView
              onDeleteClick={() => {
                setOpenDeleteModal(true);
                setSelectedUserToDelete({
                  id: row.original.studentId || '',
                  dependentOfUser: row?.original?.parentInfo,
                });
              }}
              onEditClick={() => {
                navigate(
                  `/${initial}/user-management/edit?mode=student&id=${row?.original?.studentId}`,
                );
              }}
              onViewClick={() =>
                navigate(
                  `/${initial}/user-management/view?mode=student&id=${row?.original?.studentId}`,
                )
              }
              setClose={() => setActiveButtonRow(null)}
            />
          )}
        </>
      ),
    },
  ];

  const fetchData = useCallback(({ pageSize, pageIndex, search }: any) => {
    setPageNo(pageIndex);
    setPageSize(pageSize);
    dispatch(
      getUserListAccordingToType({
        pageSize,
        pageIndex,
        search,
        userType: 'student',
      }),
    );
  }, []);

  const rows = useMemo(() => {
    if (studentList?.items?.length > 0) {
      const data = studentList?.items?.map((item: any) => ({
        studentId: item.id,
        grade: item?.grade?.length > 0 && item?.grade[0]?.grade?.name,
        studentInfo: {
          name: item?.name,
          image: item?.profileImage,
        },

        parentInfo: {
          name: item?.parent?.name || item?.parent?.email,
          image: item?.parent?.profileImage,
        },
        email: item.email,
        managedBy: item?.school?.length > 0 && item?.school[0]?.school?.name,
        classroom: item?.classroom?.map(
          (item: any) =>
            item?.clasroom?.name.split(':')[1]?.trim() || item?.clasroom?.name,
        ),
        createdDate: formatDate(item?.createdAt),
      }));
      return data;
    } else return [];
  }, [studentList]);

  return (
    <>
      <div style={{ position: 'relative' }}>
        <CustomTable
          fetchData={fetchData}
          pageCount={pageCount}
          canSearch
          pageNo={pageNo}
          totalCount={totalCount}
          table={{
            columns: columns,
            rows: rows,
          }}
        />
      </div>
      <DeleteUserDialog
        loading={deleteLoading}
        open={openDeleteModal}
        title={'Are you sure you want to delete the user from G3MS?'}
        description={
          selectedUserToDelete?.dependentOfUser?.name
            ? 'Are you sure want to remove parent children relation too?'
            : ''
        }
        onOk={handleSubmit}
        onClose={() => {
          setOpenDeleteModal(false);
          setSelectedUserToDelete({
            dependentOfUser: {
              name: '',
              profileImage: '',
            },
            id: '',
          });
        }}
      />
    </>
  );
}

export default Student;
