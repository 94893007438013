import SimpleLoader from '@core/components/MDLoader/simpleLoader';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import WarningIcon from 'icons/warning';
import { useState } from 'react';
import {
  deleteClassroom,
  getClassroomBySchoolID,
} from 'store/Thunk/classroomThunk';
import { DeleteQuestion } from 'store/Thunk/questionThunk';
import { useAppDispatch } from 'store/store';

interface DialogProps {
  open: boolean;
  onClose: (id: string) => void;
}

function OpenAiFail(props: DialogProps) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose('');
  };

  return (
    <Dialog fullWidth maxWidth="sm" onClose={handleClose} open={open}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        py={6}
      >
        <WarningIcon />
        <Typography variant="h5" mt={3} className="w-4/5 text-center">
          Error while generating the questions, please try again!
        </Typography>

        <Stack direction={'row'} spacing={4} mt={4}>
          <Button
            fullWidth
            size="small"
            variant="contained"
            className="dialog_red_button"
            onClick={handleClose}
          >
            Ok
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
}

export default OpenAiFail;
