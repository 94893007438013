import { useState } from 'react';
import MDDialog from '@core/components/MDDialog';

import { useAppSelector } from 'store/store';
import {
  Box,
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

const StatusPopup = ({ open, status, onClose, onSave }: any) => {
  const { btnLoading, screenLoading } = useAppSelector(
    (state: any) => state.loadingReducer,
  );

  const [updatedstatus, setUpdatedStatus] = useState<any>(status);

  const dialogProps = {
    open,
    onClose,
    onSave: () => onSave(updatedstatus),
    title: 'Change Status',
    size: '',
    saveTbtText: 'Save',
    closeBtnText: 'Cancel',
    loading: btnLoading,
  };

  return (
    <MDDialog {...dialogProps}>
      <Box sx={{ p: 3 }}>
        <Grid container>
          <Grid item md={12} sm={12} xs={12}>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Status Type
              </FormLabel>
              <RadioGroup
                row
                defaultValue={status.toLowerCase()}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="type"
                onChange={(e) => {
                  setUpdatedStatus(e.target.value);
                }}
              >
                <FormControlLabel
                  value="active"
                  control={<Radio />}
                  label="Active"
                />
                <FormControlLabel
                  value="deactive"
                  control={<Radio />}
                  label="Deactivate"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </MDDialog>
  );
};

export default StatusPopup;
