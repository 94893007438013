import React from 'react';
import Avatar from '@mui/material/Avatar';

import { Box, Button, Paper, Typography, useMediaQuery } from '@mui/material';
import { baby } from 'assets';
import { Theme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';

export interface StudInfo {
  name: string;
  percent: string;
  img: string;
}
const StudentCard = (props: any) => {
  const mobileSize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  return (
    <Box sx={{ ...(!mobileSize && { maxWidth: 170 }) }}>
      <Box sx={{ my: { sm: 4, xs: 2 } }}>
        <Stack alignItems="center">
          <Avatar
            alt={'Student'}
            src={props?.stud?.profileImage}
            sx={{
              width: { sm: 120, xs: 54 },
              height: { sm: 120, xs: 54 },
              p: 1,
              border: { sm: '1px solid #E9EBEC', xs: 'none' },
            }}
          />
          <Stack alignItems="baseline" direction="row">
            <Typography variant="body1" mx={2} mt={2}>
              {props?.stud?.name}
            </Typography>
            <Typography
              style={{
                fontWeight: 'bold',
                color:
                  parseInt(props?.stud?.progress) > 74 ? '#202020' : '#ff0000',
              }}
              variant="body1"
              mt={2}
            >
              {Math.round(props?.stud?.progress)}&#37;
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default StudentCard;
