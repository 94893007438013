import React from "react";

export default function HomeIcon(){


    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 15V12.5" stroke="#666666" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.39099 2.35008L2.61599 6.97507C1.96599 7.49174 1.54933 8.58341 1.69099 9.40007L2.79933 16.0334C2.99933 17.2167 4.13266 18.1751 5.33266 18.1751H14.666C15.8577 18.1751 16.9993 17.2084 17.1993 16.0334L18.3077 9.40007C18.441 8.58341 18.0243 7.49174 17.3827 6.97507L11.6077 2.35841C10.716 1.64174 9.27433 1.64174 8.39099 2.35008Z" stroke="#666666" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );

}