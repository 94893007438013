import { SilverwareSpoon } from 'mdi-material-ui';
import { StudInfo } from './student';

export const studentsList: StudInfo[] = [
  {
    name: 'Earl Silas',
    percent: '50',
    img: '/images/avatar/av9.png',
  },
  {
    name: 'kory Silas',
    percent: '80',
    img: '/images/avatar/av4.png',
  },
  {
    name: 'Jimmy Silas',
    percent: '74',
    img: '/images/avatar/1.png',
  },
];

export const classStudents: StudInfo[] = [
  {
    name: 'Taylor Silas',
    percent: '80',
    img: '/images/avatar/av1.png',
  },
  {
    name: 'taylor Silas',
    percent: '50',
    img: '/images/avatar/av8.png',
  },
  {
    name: 'kory Silas',
    percent: '80',
    img: '/images/avatar/av2.png',
  },

  {
    name: 'kory Silas',
    percent: '80',
    img: '/images/avatar/av3.png',
  },
  {
    name: 'kory Silas',
    percent: '80',
    img: '/images/avatar/av4.png',
  },
  {
    name: 'kory Silas',
    percent: '80',
    img: '/images/avatar/av5.png',
  },
  {
    name: 'kory Silas',
    percent: '80',
    img: '/images/avatar/av6.png',
  },
  {
    name: 'kory Silas',
    percent: '80',
    img: '/images/avatar/av7.png',
  },
  {
    name: 'kory Silas',
    percent: '80',
    img: '/images/avatar/av8.png',
  },
  {
    name: 'kory Silas',
    percent: '80',
    img: '/images/avatar/av9.png',
  },

  {
    name: 'jimmy Silas',
    percent: '74',
    img: '/images/avatar/1.png',
  },
  {
    name: 'kory Silas',
    percent: '74',
    img: '/images/avatar/24.png',
  },
];

export const AvatarsList = [
  {
    img: '/avatar/av1.png',
  },
  {
    img: '/avatar/av8.png',
  },
  {
    img: '/avatar/av2.png',
  },

  {
    img: '/avatar/av3.png',
  },
  {
    img: '/avatar/av4.png',
  },
  {
    img: '/avatar/av5.png',
  },
  {
    img: '/avatar/av6.png',
  },
  {
    img: '/avatar/av7.png',
  },
  {
    img: '/avatar/av8.png',
  },
  {
    img: '/avatar/av9.png',
  },

  {
    img: '/avatar/1.png',
  },
  {
    img: '/avatar/24.png',
  },
];
