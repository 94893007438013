import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

interface IntialState {
  btnLoading: boolean;
}

const intialState: IntialState = {
  btnLoading: false,
};

const AssignContentSlice = createSlice({
  name: 'assign_content_slice',
  initialState: intialState,
  reducers: {
    setButtonLoading: (
      state: Draft<IntialState>,
      action: PayloadAction<boolean>,
    ) => {
      state.btnLoading = action.payload;
    },
  },
});

export const { setButtonLoading } = AssignContentSlice.actions;

export default AssignContentSlice.reducer;
