import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Typography,
  Card,
  Button,
  Stack,
  Dialog,
  Divider,
} from '@mui/material';

function AddModal({
  children,
  setOpen,
  open,
  onClose,
  onSave,
  info,
  title,
}: any) {
  return (
    <Dialog
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '732px',
            borderRadius: '10px 10px 0px 0px',
            padding: '0px 20px',
          },
        },
      }}
      fullWidth={true}
      maxWidth="md"
      onClose={onClose}
      open={open}
    >
      <Box>
        <Stack>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '18px',
              fontWeight: 500,
              color: '#131515',
            }}
          >
            <p>{title}</p>
            <CloseIcon
              onClick={onClose}
              sx={{
                fontSize: '24px',
                marginTop: '20px',
                color: '#656565',
                cursor: 'pointer',
              }}
            />
          </Box>
          <Divider sx={{ mt: 0 }} />
        </Stack>
        {children}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ color: '#131515', fontSize: '18px', fontWeight: 500 }}>
            {info}
          </p>
          <Button
            onClick={onSave}
            sx={{ minWidth: '163px', maxHeight: '44px' }}
            variant="contained"
            className="primary_bg_btn"
          >
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default AddModal;
