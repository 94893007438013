import React from 'react';

export default function threeDotVertical() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame 2">
        <g id="Group 427326411">
          <circle
            id="Ellipse 2"
            cx="10.5"
            cy="17"
            r="2.5"
            transform="rotate(-90 10.5 17)"
            fill="#00A8A8"
          />
          <circle
            id="Ellipse 3"
            cx="10.5"
            cy="10"
            r="2.5"
            transform="rotate(-90 10.5 10)"
            fill="#00A8A8"
          />
          <circle
            id="Ellipse 4"
            cx="10.5"
            cy="2.5"
            r="2.5"
            transform="rotate(-90 10.5 2.5)"
            fill="#00A8A8"
          />
        </g>
      </g>
    </svg>
  );
}
