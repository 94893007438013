import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getLocalDateTime } from 'helper/service';
import { useAppSelector } from 'store/store';
export interface SimpleDialogProps {
  open: boolean;
  selectedAssignments?: any;
  imported?: boolean;
  selectedAssessments?: any;
  selectedAssignement?: any;
  onClose: (value: boolean) => void;
}
export default function AssignmentView(props: SimpleDialogProps) {
  const { onClose, open, selectedAssignement } = props;
  const ClassroomData = useAppSelector(
    (state) => state.classroomReducer.classroomID,
  );
  console.log('selectedAssignement', selectedAssignement);

  const totalPercentage = selectedAssignement?.school_assignment_quiz_results
    ?.length
    ? selectedAssignement?.school_assignment_quiz_results?.reduce(
        (total: any, obj: any) => total + parseFloat(obj.percentage),
        0,
      )
    : 0;

  let correctScore = 0;
  let incorrectScore = 0;
  if (selectedAssignement?.school_assignment_quiz_results?.length)
    selectedAssignement?.school_assignment_quiz_results?.forEach((obj: any) => {
      obj?.answerCorrect?.forEach((answer: any) => {
        if (answer.correct_answer === answer.student_answer) {
          correctScore++;
        } else {
          incorrectScore++;
        }
      });
    });
  const AllQuestions =
    selectedAssignement?.school_assignment_quiz_results?.reduce(
      (mergedArray: any, obj: any) => {
        return mergedArray.concat(obj.answerCorrect);
      },
      [],
    );
  console.log('mergedAnswerCorrect', AllQuestions);
  const handleListItemClick = (value: boolean) => {
    onClose(false);
  };
  return (
    <Dialog
      onClose={onClose}
      fullWidth
      open={open}
      className="assign-dialog"
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '1026px',
        },
      }}
    >
      <Stack className="flex justify-between" m={4}>
        <DialogTitle color={'#131515'}>Quiz</DialogTitle>
        <IconButton
          onClick={() => handleListItemClick(false)}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 16,
            color: (theme) => theme.palette.grey[600],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Grid container spacing={4} mt={4} px={2} mb={4}>
          <Grid item md={3}>
            <Typography
              className="!font-semibold !text-base !text-black"
              onClick={() => console.log(correctScore)}
            >
              Assignment
            </Typography>
            <Typography className="!font-normal !text-base !text-gray-500">
              {selectedAssignement?.type || ''}
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography className="!font-semibold !text-base !text-black">
              Standard
            </Typography>
            <Typography className="!font-normal !text-base !text-gray-500">
              {selectedAssignement?.template?.subTopic[0]?.subTopic?.name ||
                'N/A'}
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography className="!font-semibold !text-base !text-black">
              Skill
            </Typography>
            <Typography className="!font-normal !text-base !text-gray-500">
              {selectedAssignement?.template?.skill[0]?.skill?.name || 'N/A'}
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography className="!font-semibold !text-base !text-black">
              Due Date
            </Typography>
            <Typography className="!font-normal !text-base !text-gray-500">
              {getLocalDateTime(selectedAssignement?.dueDate, 'MMMMM D, YYYY')}
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography className="!font-semibold !text-base !text-black">
              Classroom Name
            </Typography>
            <Typography className="!font-normal !text-base !text-gray-500">
              {ClassroomData?.classroom?.name || ''}
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography className="!font-semibold !text-base !text-black">
              Department
            </Typography>
            <Typography className="!font-normal !text-base !text-gray-500">
              {ClassroomData?.classroom?.topic?.name || ''}
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography className="!font-semibold !text-base !text-black">
              Student
            </Typography>
            <Box className="flex gap-2 justify-center items-center">
              <img
                className="w-8 rounded-full h-8"
                src={selectedAssignement?.profileImage || ''}
                alt=""
              />
              <Typography className="!font-normal !text-base !text-gray-500">
                {selectedAssignement?.name || ''} (ID:{' '}
                {selectedAssignement?.userId || ''})
              </Typography>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Typography className="!font-semibold !text-base !text-black">
              Teacher
            </Typography>
            <Box className="flex gap-2 justify-center items-center">
              <img
                className="w-8 rounded-full h-8"
                src={selectedAssignement?.assignBy?.profileImage || ''}
                alt=""
              />
              <Typography className="!font-normal !text-base !text-gray-500">
                {selectedAssignement?.assignBy?.name || ''} (ID:{' '}
                {selectedAssignement?.assignBy?.id || ''})
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid
          className="flex"
          spacing={2}
          p={2}
          mt={4}
          bgcolor={'#F9F9F9'}
          container
        >
          <Grid item md={6}>
            <Typography className="!font-semibold !text-base !text-black">
              Questions
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography className="!font-semibold !text-base !text-black">
              Score:{' '}
              <span
                style={{
                  color: Math.round(totalPercentage / 2) > 79 ? 'green' : 'red',
                }}
              >
                {Math.round(totalPercentage / 2)}%
              </span>
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography className="!font-semibold !text-base !text-black">
              Correct: <span style={{ color: 'green' }}>{correctScore}</span>
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography className="!font-semibold !text-base !text-black">
              Incorrect: <span style={{ color: 'red' }}>{incorrectScore}</span>
            </Typography>
          </Grid>
        </Grid>
        {selectedAssignement?.status === 'Completed' ? (
          <>
            {AllQuestions?.map((question: any, key: any) => (
              <Grid container spacing={4} mt={4}>
                <Grid item md={2}>
                  {key + 1}. Question
                </Grid>
                <Grid item md={7}>
                  <Tooltip title={question?.questionId || ''}>
                    {question?.questionTitle}
                  </Tooltip>
                </Grid>
                <Grid item md={3} textAlign={'left'}>
                  Answer:{' '}
                  <span style={{ textTransform: 'uppercase' }}>
                    {question?.correct_answer}
                  </span>{' '}
                  <span
                    style={{
                      fontWeight: 600,
                      color:
                        question?.correct_answer === question?.student_answer
                          ? 'green'
                          : 'red',
                    }}
                  >
                    {question?.correct_answer === question?.student_answer
                      ? 'Correct'
                      : 'Incorrect'}
                  </span>
                </Grid>
              </Grid>
            ))}
          </>
        ) : (
          <></>
        )}
      </Stack>
    </Dialog>
  );
}
