// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selected {
  border: 3px solid #c73e9d;
  border-radius: 100px;
}

.arrow {
  position: absolute;
  bottom: 0px;
  right: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/createUser/index.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,oBAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,WAAA;AACF","sourcesContent":[".selected {\n  border: 3px solid #c73e9d;\n  border-radius: 100px;\n}\n\n.arrow {\n  position: absolute;\n  bottom: 0px;\n  right: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
