import React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

interface AvatartWithTextProps {
  value: {
    name?: string;
    image?: string;
    // Add any other properties you expect in the 'value' object
  };
}

const AvatartWithText: React.FC<AvatartWithTextProps> = ({ value }) => {
  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return value?.name ? (
    <Box
      style={{
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        gap: '8px',
      }}
    >
      <Avatar
        alt={'Student'}
        src={value?.image}
        sx={{
          borderRadius: '100px',
          maxWidth: '24px',
          maxHeight: '24px',
        }}
      />
      <Typography sx={{ fontSize: '13px', color: '#84818a', fontWeight: 500 }}>
        {capitalizeFirstLetter(value?.name)}
      </Typography>
    </Box>
  ) : (
    <Typography style={{ width: '150px' }}>-</Typography>
  );
};

export default AvatartWithText;
