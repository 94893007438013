export default function Hazard() {
  return (
    <svg
      width="70"
      height="62"
      viewBox="0 0 70 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M69.0981 51.5617L40.3853 3.94365C39.2541 2.06773 37.1906 0.902344 34.9999 0.902344C32.8093 0.902344 30.7458 2.06773 29.6144 3.94379L0.90172 51.5617C-0.267362 53.5006 -0.301951 55.9299 0.811349 57.9013C1.92492 59.8728 4.02301 61.0976 6.28707 61.0976H63.7128C65.9768 61.0976 68.0751 59.8728 69.1886 57.9012C70.3019 55.9296 70.2673 53.5004 69.0981 51.5617ZM65.2202 55.6603C65.0692 55.9271 64.8501 56.1491 64.5853 56.3037C64.3205 56.4583 64.0194 56.5399 63.7128 56.5402H6.28707C5.98047 56.5399 5.67942 56.4584 5.41462 56.3038C5.14982 56.1493 4.93075 55.9272 4.77975 55.6604C4.62921 55.3933 4.55218 55.091 4.55653 54.7844C4.56088 54.4779 4.64644 54.1779 4.80449 53.9152L33.5175 6.29713C33.6722 6.04229 33.8898 5.83146 34.1494 5.68485C34.409 5.53825 34.7019 5.46078 35.0001 5.45986C35.603 5.45986 36.1711 5.78074 36.4825 6.29713L65.1952 53.9152C65.3534 54.1778 65.439 54.4778 65.4434 54.7843C65.4478 55.0909 65.3708 55.3932 65.2202 55.6603Z"
        fill="#F41F52"
      />
      <path
        d="M35.0209 19.6533C33.2873 19.6533 31.9344 20.5836 31.9344 22.2327C31.9344 27.2642 32.5262 34.4944 32.5262 39.5261C32.5263 40.8368 33.6681 41.3864 35.0211 41.3864C36.0358 41.3864 37.4734 40.8368 37.4734 39.5261C37.4734 34.4945 38.0652 27.2643 38.0652 22.2327C38.0652 20.5837 36.67 19.6533 35.0209 19.6533ZM35.0634 44.3038C33.2031 44.3038 31.8076 45.7837 31.8076 47.5597C31.8076 49.2933 33.203 50.8155 35.0634 50.8155C36.797 50.8155 38.277 49.2933 38.277 47.5597C38.277 45.7837 36.7969 44.3038 35.0634 44.3038Z"
        fill="#F41F52"
      />
    </svg>
  );
}
