import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

export interface ResponseData {
  status: String;
  messageCode: String;
  message: String;
  data: any;
}

export interface SchoolAssignment {
  id: string;
  type: string;
  pinCode: string;
  startDate?: string;
  dueDate?: string;
  created_at: string;
  updated_at: string;
  timeDuration: number;
  schoolId: string;
  assignById: string;
  classroomId: string;
  isDoubleTime: boolean;
}

export interface SchoolAssignmentSliceInitialState {
  schoolAssignments: SchoolAssignment[];
  currentSchoolAssignmentInitiated: null | SchoolAssignment;
  screenLoading: boolean;
  loading: boolean;
}

const initialState: SchoolAssignmentSliceInitialState = {
  schoolAssignments: [],
  currentSchoolAssignmentInitiated: null,
  screenLoading: false,
  loading: false,
};

const SchoolAssignmentSlice = createSlice({
  name: 'user_slice',
  initialState: initialState,
  reducers: {
    setSchoolAssignmentList: (
      state: Draft<SchoolAssignmentSliceInitialState>,
      action: PayloadAction<[]>,
    ) => {
      state.schoolAssignments = action.payload;
    },
    setCurrentSchoolAssignment: (
      state: Draft<SchoolAssignmentSliceInitialState>,
      action: PayloadAction<SchoolAssignment>,
    ) => {
      state.currentSchoolAssignmentInitiated = action.payload;
    },
    setScreenLoading: (
      state: Draft<SchoolAssignmentSliceInitialState>,
      action: PayloadAction<boolean>,
    ) => {
      state.screenLoading = action.payload;
    },
    setLoading: (
      state: Draft<SchoolAssignmentSliceInitialState>,
      action: PayloadAction<boolean>,
    ) => {
      state.loading = action.payload;
    },
  },
});

export const {
  setSchoolAssignmentList,
  setCurrentSchoolAssignment,
  setScreenLoading,
  setLoading,
} = SchoolAssignmentSlice.actions;

export default SchoolAssignmentSlice.reducer;
