// *** React Imports
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Stack,
  Breadcrumbs,
  Link,
  Modal,
  Card,
  Dialog,
  Backdrop,
  CircularProgress,
} from '@mui/material';

// *** Third Party Imports
import { useLocation, useNavigate } from 'react-router-dom';

// *** Custom Components
import { route_info } from 'navigation/route';
import DefaultCell from 'components/DefaultCell';
import AddVideo from 'components/dialog/video/addVideo';

// *** Redux Components
import { getVideoList } from 'store/Thunk/videoThunk';
import { useAppDispatch, useAppSelector } from 'store/store';

// *** Icon
import { EditIcon, DeleteIcon, ViewIcon } from 'icons';
import Profile from 'assets/actionIcons/profile.png';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { displayMultipleValue, getLocalDateTime } from 'helper/service';
import { setScreenLoading } from 'store/Slice/userSlice';
import DeleteVideo from 'components/dialog/video/deleteVideo';
import DataTable from 'components/customTable';
import { startLoading } from 'store/Slice/loadingSlice';
import Avatar from '@core/components/mui/avatar';
import { sortArray } from '@core/utils/sort';
import { useSelector } from 'react-redux';
import AppLoader from 'components/appLoader';
import { setVideoList } from 'store/Slice/videoSlice';

function handleBreadCrumbClick(
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
) {
  event.preventDefault();
}

function Index() {
  const navigate: any = useNavigate();
  const dispatch = useAppDispatch();
  const { videoList, loading } = useAppSelector(
    (state: any) => state.videoReducer,
  );

  const [open, setOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState<string>('');
  const [videos, setVideos] = useState<any[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPageSize] = useState(10);
  const [search, setSearch] = useState<any>(null);
  const [totalCount, setTotalCount] = useState(0);
  let location = useLocation();
  const isAcademic = !location?.pathname.includes('fun');
  console.log('location', location);
  console.log('location', isAcademic);
  const fetchIdRef = useRef(0);
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href={route_info.content_management.video}
      onClick={() => handleBreadCrumbClick}
    >
      Resources
    </Link>,
    <Typography key="2" color="#C73E9D">
      {isAcademic ? 'Academic Video' : 'Social Fun Videos'}
    </Typography>,
  ];
  useEffect(() => {
    setPageCount(0);
    setPageNo(0);
    setPageSize(10);
    setVideos([]);
  }, [isAcademic]);
  useEffect(() => {
    setVideos([]);
    dispatch(
      getVideoList({
        perPage,
        pageNo,
        search,
        isAcademic: `${isAcademic}`,
      }),
    );
  }, [isAcademic]);

  const handleDeleteDialog = (id: string) => {
    setDeleteId(id);
    setOpen(!open);
  };

  useEffect(() => {
    if (videoList && videoList?.items) {
      setVideos(videoList?.items);
      setPageCount(videoList?.meta?.lastPage);
      setTotalCount(videoList?.meta?.total);
    }
  }, [videoList]);
  const sortedVideos = sortArray(videos, search);

  let { screenLoading } = useAppSelector((state: any) => state.userReducer);

  const [isUploadVideo, setIsUploadVideo] = useState(false);

  const getColumns = () => {
    const columns = isAcademic
      ? [
          {
            Header: 'Grade',
            accessor: 'grade',
            width: '20px',
            Cell: ({ value }: any) => {
              if (value.length) {
                return (
                  <DefaultCell
                    value={displayMultipleValue(value, 'grade') || '-'}
                  />
                );
              } else {
                return '-';
              }
            },
          },
          {
            Header: 'Title',
            accessor: 'title',
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: 'Topic',
            accessor: 'topic',
            Cell: ({ value }: any) => {
              if (value.length > 0) {
                return <DefaultCell value={value[0]?.topic?.name || '-'} />;
              } else {
                return '-';
              }
            },
          },
          {
            Header: 'Sub Topic',
            accessor: 'sub_topic',

            Cell: ({ value }: any) => {
              if (value.length) {
                return (
                  <DefaultCell
                    value={`${value[0]?.subTopic?.sequenceNumber} - ${value[0]?.subTopic?.name} || '-'`}
                  />
                );
              } else {
                return '-';
              }
            },
          },
          {
            Header: 'Skill',
            accessor: 'skill',
            Cell: ({ value }: any) => {
              if (value.length) {
                return (
                  <DefaultCell
                    value={
                      `${value[0]?.skill?.sequenceNumber} - ${value[0]?.skill?.name}` ||
                      '-'
                    }
                  />
                );
              } else {
                return '-';
              }
            },
          },
          {
            Header: 'Action',
            accessor: 'action',
            Cell: ({ value }: any) => (
              <Box>
                <IconButton onClick={() => handleEditClick(value.id)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDeleteDialog(value.id)}>
                  <DeleteIcon />
                </IconButton>
                <IconButton onClick={() => handleViewClick(value.id)}>
                  <ViewIcon />
                </IconButton>
              </Box>
            ),
          },
        ]
      : [
          {
            Header: 'Grade',
            accessor: 'grade',
            width: '20px',
            Cell: ({ value }: any) => {
              if (value.length) {
                return (
                  <DefaultCell
                    value={displayMultipleValue(value, 'grade') || '-'}
                  />
                );
              } else {
                return '-';
              }
            },
          },
          {
            Header: 'Title',
            accessor: 'title',
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
          {
            Header: 'Topic',
            accessor: 'topic',
            Cell: ({ value }: any) => {
              if (value.length > 0) {
                return <DefaultCell value={value[0]?.topic?.name || '-'} />;
              } else {
                return '-';
              }
            },
          },

          {
            Header: 'Action',
            accessor: 'action',
            Cell: ({ value }: any) => (
              <Box>
                <IconButton onClick={() => handleEditClick(value.id)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDeleteDialog(value.id)}>
                  <DeleteIcon />
                </IconButton>
                <IconButton onClick={() => handleViewClick(value.id)}>
                  <ViewIcon />
                </IconButton>
              </Box>
            ),
          },
        ];
    return columns;
  };
  console.log('sorted', sortedVideos);
  const getRows = () => {
    const data: any = isAcademic
      ? sortedVideos?.map((obj: any) => ({
          title: obj?.title || '-',
          user_created: obj?.user,
          date: obj.createdAt ? getLocalDateTime(obj.createdAt) : '-',
          topic: obj?.topic,
          sub_topic:
            obj?.sequence != null
              ? `${obj?.sequence}${obj?.subTopic}`
              : obj?.subTopic || {},
          grade: obj?.grade || {},
          skill:
            obj?.sequence != null
              ? `${obj?.sequence}${obj?.skill}`
              : obj?.skill || {},
          action: obj,
        }))
      : sortedVideos?.map((obj: any) => ({
          title: obj?.title || '-',
          user_created: obj?.user,
          date: obj.createdAt ? getLocalDateTime(obj.createdAt) : '-',
          topic: obj?.topic,
          grade: obj?.grade || {},
          action: obj,
        }));
    return data;
  };

  const tableData = {
    columns: getColumns(),
    rows: getRows(),
  };
  useEffect(() => {
    //set video list to empty array to reset to academic or social fun
    setVideoList([]);
    setVideos([]);
  }, []);
  const handleEditClick = (id: any) => {
    isAcademic
      ? navigate(
          `${route_info.content_management.video_edit}/${id}?academic=${isAcademic}`,
        )
      : navigate(
          `${route_info.content_management.video_edit_fun}/${id}?academic=${isAcademic}`,
        );
  };
  const handleViewClick = (id: any) => {
    isAcademic
      ? navigate(
          `${route_info.content_management.video_detail}/${id}?academic=${isAcademic}`,
        )
      : navigate(
          `${route_info.content_management.video_detail_fun}/${id}?academic=${isAcademic}`,
        );
  };

  const handleCancel = (e: any) => {
    if (!loading) {
      setIsUploadVideo(!isUploadVideo);
    }
  };

  const fetchData = useCallback(
    ({ pageSize, pageIndex, search }: any) => {
      const fetchId = ++fetchIdRef.current;
      // We'll even set a delay to simulate a server here
      if (fetchId === fetchIdRef.current) {
        setPageNo(pageIndex);
        setPageSize(pageSize);
        setSearch(search);
        dispatch(startLoading());
        dispatch(
          getVideoList({
            pageSize,
            pageIndex,
            search,
            isAcademic: `${isAcademic}`,
          }),
        );
      }
      // setTimeout(() => {}, 1000);
    },
    [isAcademic],
  );

  return (
    <Box>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        style={{ marginBottom: 20 }}
      >
        {breadcrumbs}
      </Breadcrumbs>
      <Card className="datatable_card" sx={{ p: '24px' }}>
        <DataTable
          table={tableData}
          fetchData={fetchData}
          canSearch
          title={isAcademic ? 'Academic Video' : 'Social Fun Videos'}
          buttonTitle="Create Video"
          searchText="Search..."
          pageCount={pageCount}
          pageNo={pageNo}
          totalCount={totalCount}
          handleOnChange={handleCancel}
        />
      </Card>

      {isUploadVideo && (
        <AddVideo open={isUploadVideo} onClose={handleCancel} />
      )}
      {open && (
        <DeleteVideo
          search={search}
          open={open}
          deleteId={deleteId}
          isAcademic={isAcademic}
          onClose={handleDeleteDialog}
        />
      )}
      {loading && <AppLoader />}
    </Box>
  );
}

export default Index;
