import React from 'react';

export default function SchoolManagementNew() {
  return (
    <svg
      width="18"
      height="24"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00001 11.43C9.6668 11.43 10.3186 11.2323 10.8731 10.8619C11.4275 10.4915 11.8597 9.96507 12.115 9.34909C12.3703 8.73311 12.4373 8.05527 12.3074 7.40125C12.1776 6.74723 11.8567 6.14639 11.3854 5.67469C10.9141 5.20299 10.3136 4.8816 9.65969 4.75115C9.00578 4.6207 8.32788 4.68705 7.71167 4.94181C7.09547 5.19657 6.56863 5.6283 6.19775 6.18243C5.82687 6.73655 5.6286 7.3882 5.62801 8.05499C5.62801 8.94958 5.98317 9.80757 6.61546 10.4404C7.24775 11.0733 8.10542 11.4292 9.00001 11.43ZM9.00001 6.18299C9.37025 6.18299 9.73218 6.29278 10.04 6.49848C10.3479 6.70418 10.5878 6.99654 10.7295 7.33861C10.8712 7.68067 10.9083 8.05707 10.836 8.4202C10.7638 8.78333 10.5855 9.11689 10.3237 9.37869C10.0619 9.6405 9.72835 9.81879 9.36522 9.89102C9.00208 9.96325 8.62569 9.92618 8.28362 9.78449C7.94156 9.64281 7.64919 9.40287 7.4435 9.09502C7.2378 8.78717 7.12801 8.42524 7.12801 8.05499C7.1288 7.55875 7.32628 7.08306 7.67718 6.73216C8.02807 6.38127 8.50376 6.18378 9.00001 6.18299Z"
        fill="#84818A"
      />
      <path
        d="M0.838501 19.4115C0.838501 21.9045 5.043 23.25 9 23.25C12.957 23.25 17.1615 21.906 17.1615 19.4115C17.1615 17.8245 15.405 16.5345 12.5085 15.921L14.8125 12.441C15.9578 10.9428 16.4752 9.05769 16.2551 7.18474C16.0351 5.31179 15.0949 3.59796 13.6335 2.40599C12.6707 1.61459 11.5214 1.08279 10.295 0.861208C9.06852 0.639627 7.80577 0.735644 6.62694 1.14012C5.4481 1.54459 4.3924 2.24406 3.56035 3.17194C2.7283 4.09981 2.14759 5.22521 1.8735 6.44099C1.63746 7.46864 1.6304 8.53564 1.85282 9.56632C2.07523 10.597 2.52168 11.5661 3.1605 12.405L5.4915 15.921C2.595 16.5345 0.838501 17.826 0.838501 19.4115ZM3.339 6.76649C3.58479 5.68387 4.13555 4.69431 4.92612 3.9149C5.7167 3.13549 6.71399 2.59886 7.8 2.36849C8.18932 2.29175 8.58519 2.25306 8.982 2.25299C10.0674 2.24393 11.1334 2.54095 12.0577 3.10997C12.982 3.67899 13.7272 4.49699 14.208 5.47016C14.6887 6.44333 14.8854 7.53228 14.7756 8.61213C14.6657 9.69199 14.2538 10.719 13.587 11.5755L10.6125 16.065L10.6005 16.0845L9.468 17.7915C9.41681 17.8685 9.34736 17.9317 9.26584 17.9754C9.18432 18.0191 9.09325 18.042 9.00075 18.042C8.90825 18.042 8.81719 18.0191 8.73566 17.9754C8.65414 17.9317 8.58469 17.8685 8.5335 17.7915L7.398 16.0815L7.3875 16.0665L4.383 11.5365C3.86863 10.867 3.50836 10.0922 3.3279 9.26739C3.14743 8.4426 3.15122 7.58965 3.339 6.76649ZM6.393 17.2815L7.2825 18.6225C7.47074 18.9056 7.72604 19.1378 8.02568 19.2984C8.32533 19.459 8.66003 19.543 9 19.543C9.33997 19.543 9.67467 19.459 9.97432 19.2984C10.274 19.1378 10.5293 18.9056 10.7175 18.6225L11.607 17.2815C14.1465 17.7135 15.6615 18.675 15.6615 19.4115C15.6615 20.3655 13.0665 21.75 8.9985 21.75C4.9305 21.75 2.3385 20.3655 2.3385 19.4115C2.3385 18.675 3.8535 17.7135 6.393 17.2815Z"
        fill="#84818A"
      />
    </svg>
  );
}
