/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { ReactNode } from 'react';

// @mui material components
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React TS contexts
// import { useMaterialUIController } from "context";
import { Box, SxProps } from '@mui/material';

// Declaring props types for DataTableHeadCell
interface Props {
  width?: string | number;
  children: ReactNode;
  sorted?: false | 'none' | 'asc' | 'desc';
  align?: 'left' | 'right' | 'center';
  backgroundColor?: string | null;
  color?: string | null;
  sx?: SxProps;
}

function DataTableHeadCell({
  width,
  children,
  sorted,
  align,
  backgroundColor,
  color,
  sx,

  ...rest
}: Props): JSX.Element {
  // const [controller] = useMaterialUIController();
  // const { darkMode } = controller;

  return (
    <Box
      component="th"
      width={width}
      py={1.5}
      px={3}
      sx={{ backgroundColor: backgroundColor, ...sx }}
      // sx={({ palette: { light }, borders: { borderWidth } }: Theme) => ({
      //   borderBottom: `${borderWidth[1]} solid ${light.main}`,
      // })}
    >
      <Box
      // {...rest}
      // position="relative"
      // textAlign={align}
      // color={darkMode ? "white" : "secondary"}
      // opacity={0.7}
      // sx={({ typography: { size, fontWeightBold } }: Theme) => ({
      //   fontSize: size.xxs,
      //   fontWeight: fontWeightBold,
      //   textTransform: "uppercase",
      //   cursor: sorted && "pointer",
      //   userSelect: sorted && "none",
      //   display: "flex",
      // })}
      >
        <Box
          sx={{
            color: color ? color : '#52525B',
            fontSize: '13px',
            fontWeight: '500',
            textTransform: 'capitalize',
          }}
        >
          {children}
        </Box>
        {sorted && (
          <Box
            top={0}
            right={align !== 'right' ? '5px' : 0}
            left={align === 'right' ? '-5px' : 'unset'}
            sx={({ typography: { size } }: any) => ({
              // fontSize: size.lg,
            })}
          >
            {sorted === 'asc' && (
              <Box
              // position="absolute"
              // top={-6}
              // color={sorted === "asc" ? "info" : "secondary"}
              // opacity={sorted === "asc" ? 1 : 0.5}
              >
                <Icon sx={{ lineHeight: 1.25 }}>keyboard_arrow_up</Icon>
              </Box>
            )}
            {sorted === 'desc' && (
              <Box
              // position="absolute"
              // top={0}
              // color={sorted === "desc" ? "info" : "secondary"}
              // opacity={sorted === "desc" ? 1 : 0.5}
              >
                <Icon sx={{ lineHeight: 0.75 }}>keyboard_arrow_down</Icon>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}

// Declaring default props for DataTableHeadCell
DataTableHeadCell.defaultProps = {
  width: 'auto',
  sorted: 'none',
  align: 'left',
};

export default DataTableHeadCell;
