import { Grid } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import MenuIcon from '@mui/icons-material/Menu';
import MediaUpload from './MediaUpload';
import React, { useState, ChangeEvent, DragEvent, useEffect } from 'react';
import {
  InputLabel,
  TextField,
  Typography,
  Button,
  Box,
  Paper,
} from '@mui/material';
import VideocamIcon from '@mui/icons-material/Videocam';
const MediaOptions = (props: any) => {
  const [initialItems, setInitialItems] = React.useState([
    { id: 'A', content: '' },
    { id: 'B', content: '' },
    { id: 'C', content: '' },
    { id: 'D', content: '' },
  ]);
  const [items, setItems] = React.useState(initialItems);
  const [answerIndex, setAnswerIndex] = React.useState<any>(null);

  React.useEffect(() => {
    let TempAnswerIndex =
      props.options?.answer === 'a'
        ? 0
        : props.options?.answer === 'b'
        ? 1
        : props.options?.answer === 'c'
        ? 2
        : props.options?.answer === 'd'
        ? 3
        : null;

    setAnswerIndex(TempAnswerIndex);
  }, [props.options?.answer]);

  const getCharacterOption = (index: number) => {
    return index === 0
      ? 'A'
      : index === 1
      ? 'B'
      : index === 2
      ? 'C'
      : index === 3
      ? 'D'
      : null;
  };
  React.useEffect(() => {
    setItems([
      { id: 'A', content: props.options ? props.options?.optionA : '' },
      { id: 'B', content: props.options ? props.options?.optionB : '' },
      { id: 'C', content: props.options ? props.options?.optionC : '' },
      { id: 'D', content: props.options ? props.options?.optionD : '' },
    ]);
  }, [
    props?.options.optionA,
    props.options?.optionB,
    props.options?.optionC,
    props.options?.optionD,
  ]);
  return (
    <div>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {items?.map((item, index) => (
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <TextField
              id="input-with-icon-textfield"
              variant="outlined"
              multiline
              rows={2}
              placeholder="Write an option here"
              value={item.content}
              name={`option${getCharacterOption(index)}`}
              onChange={props.handleChange}
              sx={{
                width: { md: '100%', xs: '70%', sm: '100%' },
                marginY: '10px',
                paddingTop:'0px'
              }}
              size={'medium'}
            />
            <Box display={'flex'} sx={{ alignItems: 'center' }}>
              {/* <MenuIcon sx={{ pr: 1, fontSize: '24px' }} /> */}
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-end',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: '8px',
                      border: '1px solid #c8c8cf',
                      paddingX: '6px',
                      paddingY: '0px',
                    }}
                  >
                    <Box>
                      <Box
                        onClick={() => {
                          props.handleChangeAnswer(getCharacterOption(index));
                        }}
                        sx={{
                          display: 'inline',
                        }}
                      >
                        {answerIndex === index ? (
                          <Box
                            sx={{
                              display: 'inline',
                              cursor: 'pointer',
                            }}
                          >
                            <DoneIcon
                              sx={{
                                backgroundColor: '#13BD38',
                                color: '#fff',
                                borderRadius: '4px',
                              }}
                            />
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              p: '4px 8px',
                              backgroundColor: '#979C9E',
                              borderRadius: '4px',
                              display: 'inline',
                              color: '#fff',
                              cursor: 'pointer',
                            }}
                          >
                            {getCharacterOption(index)}
                          </Box>
                        )}
                      </Box>
                    </Box>
                    <Box>
                      <MediaUpload
                        // id={props?.id}
                        // questionType={props?.questionType}
                        key={`option${getCharacterOption(index)}Media`}
                        formData={props?.options}
                        handleFileChange={props?.handleFileChange}
                        name={`option${getCharacterOption(index)}Media`}
                      />
                      {/* <div>
                        <Box>
                          <Box
                            sx={{
                              borderRadius: '8px',
                              border: '1px solid #c8c8cf',
                              padding: '10px',
                              textAlign: 'center',
                              cursor: 'pointer',
                              marginTop: 4,
                            }}
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                          >
                                                <Typography variant="body1" paragraph>
                                Click here or drag and drop to add asset
                              </Typography>
                            <input
                              type="file"
                              accept="image/*, video/*"
                              style={{ display: 'none' }}
                              onChange={(event) =>
                                props.handleFileChange(event, props?.name)
                              }
                              name={props?.name}
                              id={`asset-input-${props?.name}`}
                            />
                            <label htmlFor={`asset-input-${props?.name}`}>
                              {props?.options?.[`${props?.name}`] ? (
                                <div>
                                  <img
                                    src={URL.createObjectURL(
                                      props?.options?.[
                                        `option${getCharacterOption(
                                          index,
                                        )}Media`
                                      ],
                                    )}
                                    alt="Selected File"
                                    style={{
                                      maxWidth: '100%',
                                      maxHeight: '200px',
                                      marginTop: '10px',
                                    }}
                                  />
                                </div>
                              ) : (
                                <>
                                  <Box
                                    component="div"
                                    sx={{
                                      border: '2px dashed #c8c8cf',
                                      borderRadius: '8px',
                                      padding: '10px',
                                      marginBottom: '10px',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <VideocamIcon
                                      sx={{ fontSize: 30, color: '#C73E9D' }}
                                    />
                                    <Typography variant="body1">
                                      <pre>
                                        Click here to add asset or drag{' '}
                                        <br></br> &amp; drop to add asset
                                        <br></br> PNG , JPEG, Max size :2MB
                                      </pre>
                                    </Typography>
                                  </Box>
                                </>
                              )}
                            </label>
                          </Box>
                        </Box>
                      </div> */}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default MediaOptions;
