import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

export interface ResponseData {
  status: String;
  messagecode: String;
  message: String;
  data: any;
}

interface IntialState {
  classroomList: any;
  classroomID: any;
  classroomSchoolID:any;
  classRoomParents:any
}

const intialState: IntialState = {
  classroomList: [],
  classroomID: [],
  classroomSchoolID: [],
  classRoomParents: []
};

const ClassroomSlice = createSlice({
  name: 'Classroom_slice',
  initialState: intialState,
  reducers: {
    setClassroomList: (state: Draft<IntialState>, action: PayloadAction<[]>) => {
      state.classroomList = action.payload;
    },
    setClassroomByID: (state: Draft<IntialState>, action: PayloadAction<{}>) => {
      state.classroomID = action.payload;
    },
    setClassroomBySchoolID: (state: Draft<IntialState>, action: PayloadAction<[]>) => {
      state.classroomSchoolID = action.payload;
    },
    setClassRoomParents: (state: Draft<IntialState>, action: PayloadAction<[]>) => {
      state.classRoomParents = action.payload;
    },
  },
});

export const { setClassroomList, setClassroomByID, setClassroomBySchoolID,setClassRoomParents } = ClassroomSlice.actions;

export default ClassroomSlice.reducer;
