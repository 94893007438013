import React, { useCallback, useEffect, useRef, useState } from 'react';
import Card from '@mui/material/Card';

import Typography from '@mui/material/Typography';
import { DartIcon, AssignmentIcon } from 'icons';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';

// import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';

import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Pagination,
  TextField,
  Tooltip,
} from '@mui/material';
import checkIfExists from 'helper/exist';
import { startLoading } from 'store/Slice/loadingSlice';
import { useAppDispatch } from 'store/store';
import { getImportedAssignments } from 'store/Thunk/assignContentThunk';
import useSchoolClassroomInfo from 'hooks/useSchoolClassroomInfo';
interface Assignment {
  assignmentId: string;
  type: string;
  subTopics: string;
  skills: string;
  date: string;
  score: number;
  checked?: boolean; // Make the 'checked' property optional
}

interface AssignmentWrapper {
  id: string;
  picture: string;
  name: string;
  email: string;
  skillsMastered: number;
  completedAssignments: number;
  skillsNotMastered: number;
  assignemetsTypes: {
    type: string;
    totalVideo: string;
    totalQuiz: string;
    totalChallenge: string;
    assignments: Assignment[];
  }[];
}

interface SelectedAssignment {
  id: string;
  name: string;
  picture: string;
  email: string;
  assignmentId: string;
  subTopic: string;
  skill: string;
  count: number;
}

export interface Header {
  label: string;
  width: number;
  value: string;
  color: string;
}
export interface SubHeader {
  label: string;
  width: number;
  icon?: any;
  value: string;
}
export interface AssignmentTypeHeader {
  label: string;
  icon: any;
  color: string;
  width: number;
  value: string;
}
export interface AssignmentMasteredHeader {
  label: string;
  icon: any;
  color: string;
  width: number;
  value: string;
}
export interface IStudent {
  id?: string;
  name?: string;
  skill?: string;
  topic?: string;
  subTopic?: string;
  assignmentType?: string;
  totalGems?: string;
  pending?: string;
  status?: string;
  startDate?: string;
  endDate?: string;
}

export interface IClasses {
  id?: string;
  name?: string;
  totalAssignments?: string;
  completedAssignments?: string;
  pendingAssignments?: string;
  totalGems?: string;
  interVentionRecommended?: string;
  averageScore?: string;
  studentCount?: string;
  target?: string;
  Assignments?: IStudent[];
}

const StudentsList = ({
  assignments,
  subHeader,
  assignmentTypeHeader,
  assignmentMasteredHeader,
  selectedAssessments,
  handleAssignmentChange,
  selectAll,
  wrapper,
  selected,
}: {
  subHeader: any;
  selectedAssessments: any;
  wrapper: any;
  assignmentTypeHeader: any;
  selectAll: any;
  assignmentMasteredHeader: any;
  handleAssignmentChange: any;
  selected: any;
  assignments: any[] | undefined;
}) => {
  let stud: any[] = [];
  if (assignments && assignments.length > 0) {
    stud = assignments;
  }
  let MasteredSkills: any[] = [];
  let NotMasteredSkills: any[] = [];
  stud.forEach((item: any) => {
    if (item.score > 69) {
      MasteredSkills.push(item);
    } else {
      NotMasteredSkills.push(item);
    }
  });
  return (
    <List component="div" disablePadding>
      <Box sx={{ m: 2, mb: 4 }}>
        <Box
          className="flex-1 rounded-2xl py-4 my-4 w-full font-bold flex justify-between px-4 text-sm"
          sx={{ bgcolor: '#F9F9F9', color: '#C73E9D' }}
        >
          {assignmentTypeHeader?.map((header: any) => (
            <Box className="flex justify-between w-full pr-6 items-center">
              <Box className="flex items-center gap-2">
                <DartIcon />
                <Box className="font-semibold text-sm">{header?.label}</Box>
              </Box>
              <Checkbox
                sx={{ p: 0 }}
                onChange={(e) =>
                  selectAll(NotMasteredSkills, wrapper, e.target.checked)
                }
              />
            </Box>
          ))}
        </Box>
        <ListItem sx={{ pl: 4 }}>
          {/* grid for listing student properties */}
          <Grid
            container
            spacing={2}
            className="w-full"
            sx={{
              '& .MuiGrid-item': {
                display: 'flex',
                alignItems: 'center',
              },
            }}
          >
            {subHeader?.map((sub: any) => (
              <Grid
                item
                md={sub.width}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent:
                    sub.value === 'score' || sub.value === 'action'
                      ? 'center'
                      : 'flex-start',
                }}
              >
                <Typography
                  fontSize={'14px'}
                  color={'#979C9E'}
                  className="!font-bold "
                >
                  {sub.label}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </ListItem>
      </Box>
      <Box sx={{ m: 2, mb: 4 }}>
        {NotMasteredSkills?.map((item: any) => (
          <ListItem sx={{ pl: 4, mt: 4 }}>
            {/* grid for listing student properties */}
            <Grid
              container
              className="w-full"
              spacing={2}
              sx={{
                '& .MuiGrid-item': {
                  display: 'flex',
                  alignItems: 'center',
                },
              }}
            >
              <Grid
                onClick={() => console.log(item)}
                item
                sx={{ display: 'flex' }}
                md={4}
              >
                <Typography
                  ml={1}
                  variant="subtitle1"
                  color={false ? '#C73E9D' : '#131515'}
                  fontWeight={'500'}
                  justifyContent={'flex-start'}
                  fontSize={'14px'}
                  lineHeight={'14px'}
                >
                  {item.skill.name || ''}
                </Typography>
              </Grid>
              <Grid
                onClick={() => console.log(item)}
                item
                sx={{ display: 'flex' }}
                md={5}
              >
                <Typography
                  ml={1}
                  variant="subtitle1"
                  color={false ? '#C73E9D' : '#131515'}
                  fontWeight={'500'}
                  justifyContent={'flex-start'}
                  fontSize={'14px'}
                  lineHeight={'14px'}
                >
                  {item.sub_topic.name || ''}
                </Typography>
              </Grid>
              <Grid
                onClick={() => console.log(item)}
                item
                sx={{
                  display: 'flex',
                  textAlign: 'center',
                  justifyContent: 'center',
                }}
                md={2}
              >
                <Typography
                  ml={1}
                  variant="subtitle1"
                  color={
                    item.score < 40
                      ? '#C73E9D'
                      : item.score < 70
                      ? '#B54708'
                      : '#027A48'
                  }
                  bgcolor={
                    item.score < 40
                      ? '#FEF3F2'
                      : item.score < 70
                      ? '#FFFAEB'
                      : '#e6fff4'
                  }
                  padding="4px 6px"
                  borderRadius="20px"
                  fontWeight={'500'}
                  justifyContent={'center'}
                  fontSize={'14px'}
                  lineHeight={'14px'}
                >
                  {item.score + '% score' || 0}
                </Typography>
              </Grid>
              <Grid item md={1} className="flex justify-end">
                <FormGroup className="flex !flex-row gap-0">
                  <FormControlLabel
                    sx={{ span: { fontWeight: '800' } }}
                    className="font-bold"
                    label=""
                    color="#C73E9D"
                    control={
                      <Checkbox
                        checked={checkIfExists(
                          wrapper.id,
                          item.templateId,
                          selectedAssessments,
                        )}
                        onChange={(e) =>
                          handleAssignmentChange(
                            wrapper.id,
                            item.templateId,
                            wrapper.name,
                            wrapper.lastname,
                            wrapper.email,
                            wrapper.profileImage,
                          )
                        }
                      />
                    }
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </Box>
      <Box sx={{ m: 2, mb: 4 }}>
        <Box
          className="flex-1 rounded-2xl py-4 my-4 w-full font-bold flex justify-between px-4 text-sm"
          sx={{ bgcolor: '#F9F9F9', color: '#C73E9D' }}
        >
          {assignmentMasteredHeader?.map((header: any) => (
            <Box className="flex justify-between w-full pr-6 items-center">
              <Box className="flex items-center gap-2">
                <DartIcon />
                <Box className="font-semibold text-sm">{header?.label}</Box>
              </Box>
              <Checkbox
                sx={{ p: 0 }}
                onChange={(e) =>
                  selectAll(MasteredSkills, wrapper, e.target.checked)
                }
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box sx={{ m: 2, mb: 4 }}>
        {MasteredSkills?.map((item: any) => (
          <ListItem sx={{ pl: 4, mt: 4 }}>
            {/* grid for listing student properties */}
            <Grid
              container
              className="w-full"
              spacing={2}
              sx={{
                '& .MuiGrid-item': {
                  display: 'flex',
                  alignItems: 'center',
                },
              }}
            >
              <Grid
                onClick={() => console.log(item)}
                item
                sx={{ display: 'flex' }}
                md={4}
              >
                <Typography
                  ml={1}
                  variant="subtitle1"
                  color={false ? '#C73E9D' : '#131515'}
                  fontWeight={'500'}
                  justifyContent={'flex-start'}
                  fontSize={'14px'}
                  lineHeight={'14px'}
                >
                  {item.skill.name || ''}
                </Typography>
              </Grid>
              <Grid
                onClick={() => console.log(item)}
                item
                sx={{ display: 'flex' }}
                md={5}
              >
                <Typography
                  ml={1}
                  variant="subtitle1"
                  color={false ? '#C73E9D' : '#131515'}
                  fontWeight={'500'}
                  justifyContent={'flex-start'}
                  fontSize={'14px'}
                  lineHeight={'14px'}
                >
                  {item.sub_topic.name || ''}
                </Typography>
              </Grid>
              <Grid
                onClick={() => console.log(item)}
                item
                sx={{
                  display: 'flex',
                  textAlign: 'center',
                  justifyContent: 'center',
                }}
                md={2}
              >
                <Typography
                  ml={1}
                  variant="subtitle1"
                  color={
                    item.score < 40
                      ? '#C73E9D'
                      : item.score < 70
                      ? '#B54708'
                      : '#027A48'
                  }
                  bgcolor={
                    item.score < 40
                      ? '#FEF3F2'
                      : item.score < 70
                      ? '#FFFAEB'
                      : '#e6fff4'
                  }
                  padding="4px 6px"
                  borderRadius="20px"
                  fontWeight={'500'}
                  justifyContent={'center'}
                  fontSize={'14px'}
                  lineHeight={'14px'}
                >
                  {item.score + '% score' || 0}
                </Typography>
              </Grid>
              <Grid item md={1} className="flex justify-end">
                <FormGroup className="flex !flex-row gap-0">
                  <FormControlLabel
                    sx={{ span: { fontWeight: '800' } }}
                    className="font-bold"
                    label=""
                    color="#C73E9D"
                    control={
                      <Checkbox
                        checked={checkIfExists(
                          wrapper.id,
                          item.templateId,
                          selectedAssessments,
                        )}
                        onChange={(e) =>
                          handleAssignmentChange(
                            wrapper.id,
                            item.templateId,
                            wrapper.name,
                            wrapper.lastname,
                            wrapper.email,
                            wrapper.profileImage,
                          )
                        }
                      />
                    }
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </Box>
    </List>
  );
};

export default function StudentProgressTable({
  classes,
  color,
  pageNo,
  header,
  search,
  showPagination,
  entriesPerPage,
  setSelected,
  selected,
  setPageNo,
  subHeader,
  assignmentTypeHeader,
  assignmentMasteredHeader,
  selectedAssignments,
  selectedAssessments,
  setSelectedAssignments,
  setImportedAssessments,
  setImportedAssessmentsMeta,
  importedAssssmentsMeta,
  setSelectedAssessments,
}: {
  classes?: any;
  pageNo?: any;
  setPageNo?: any;
  search?: any;
  setImportedAssessments?: any;
  showPagination?: any;
  color?: string;
  entriesPerPage?: any;
  header?: Header[];
  subHeader?: SubHeader[];
  selectedAssignments?: any;
  selectedAssessments?: any;
  setSelectedAssignments?: any;
  setSelectedAssessments?: any;
  setImportedAssessmentsMeta?: any;
  importedAssssmentsMeta?: any;
  setSelected?: any;
  selected?: any;
  assignmentTypeHeader?: AssignmentTypeHeader[];
  assignmentMasteredHeader?: AssignmentMasteredHeader[];
}) {
  const [open, setOpen] = React.useState(true);
  console.log('selectedAssignments', selectedAssessments);
  const selectAll = (assessments: any, wrapper: any, checkedStatus: any) => {
    setSelectedAssessments((prevAssessments: any) => {
      const studentAssessments = prevAssessments.find(
        (assessment: any) => assessment.userIds[0] === wrapper.id,
      );

      if (studentAssessments) {
        // Assessments already exist for the student
        const updatedAssessments = studentAssessments.assignmentIds;
        assessments.forEach((assessment: any) => {
          const index = updatedAssessments.indexOf(assessment.templateId);
          if (checkedStatus && index === -1) {
            updatedAssessments.push(assessment.templateId); // Add assessment if not already present
          } else if (!checkedStatus && index !== -1) {
            updatedAssessments.splice(index, 1); // Remove assessment if present
          }
        });

        if (updatedAssessments.length === 0) {
          // If no assessments remaining, remove the student
          return prevAssessments.filter(
            (assessment: any) => assessment.userIds[0] !== wrapper.id,
          );
        } else {
          // Update the assessments for the student
          return prevAssessments.map((assessment: any) =>
            assessment.userIds[0] === wrapper.id
              ? { ...assessment, assignmentIds: updatedAssessments }
              : assessment,
          );
        }
      } else {
        // Assessments don't exist for the student
        if (checkedStatus) {
          // If checkedStatus is true, add the assessments
          return [
            ...prevAssessments,
            {
              userIds: [wrapper.id],
              assignmentIds: assessments.map(
                (assessment: any) => assessment.templateId,
              ),
              name: wrapper.name,
              lastname: wrapper.lastname,
              email: wrapper.email,
              profileImage: wrapper.profileImage,
            },
          ];
        } else {
          // If checkedStatus is false, no assessments to add
          return prevAssessments;
        }
      }
    });
  };
  const handleAssignmentChange = (
    userId: string,
    skillId: string,
    name: string,
    lastname: string,
    email: string,
    profileImage: string,
  ) => {
    setSelectedAssessments((prevAssessments: any) => {
      const assessmentIndex = prevAssessments.findIndex((assessment: any) =>
        assessment.userIds.includes(userId),
      );

      if (assessmentIndex !== -1) {
        const updatedAssessments = [...prevAssessments];
        const assignmentIds = updatedAssessments[assessmentIndex].assignmentIds;
        const skillIndex = assignmentIds.indexOf(skillId);

        if (skillIndex === -1) {
          assignmentIds.push(skillId);
        } else {
          assignmentIds.splice(skillIndex, 1);
        }

        return updatedAssessments;
      }

      // User doesn't exist, add a new assessment
      return [
        ...prevAssessments,
        {
          userIds: [userId],
          assignmentIds: [skillId],
          name: name,
          lastname: lastname,
          email: email,
          profileImage: profileImage,
        },
      ];
    });
  };
  const { classroomId } = useSchoolClassroomInfo();

  const dispatch = useAppDispatch();
  const fetchIdRef = useRef(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  useEffect(() => {
    if (classes && classes.length) {
      setPageCount(importedAssssmentsMeta?.meta?.totalPages);
      setTotalCount(importedAssssmentsMeta?.meta?.totalCount);
    }
  }, [importedAssssmentsMeta]);
  const fetchData = useCallback(({ pageSize, pageIndex, search }: any) => {
    const fetchId = ++fetchIdRef.current;
    // We'll even set a delay to simulate a server here
    if (fetchId === fetchIdRef.current) {
      setPageNo(pageIndex);
      setPageSize(pageSize);
      dispatch(startLoading());
      dispatch(
        getImportedAssignments({
          id: classroomId,
          pageSize,
          pageIndex,
          search,
        }),
      )
        .unwrap()
        .then((res) => {
          setImportedAssessments(res.items);
          setImportedAssessmentsMeta(res);
          console.log('assignmentsList', res);
        });
    }
    // setTimeout(() => {}, 1000);
  }, []);
  const setEntriesPerPage = (value: any) => {
    setPageNo(0);
    fetchData({
      pageIndex: 0,
      pageSize: value,
      search: search,
    });
    setPageSize(value);
  };
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    if (fetchData) {
      fetchData({
        pageIndex: value - 1,
        pageSize,
        search: search,
      });
    }
  };
  const renderPagination = () => (
    <Pagination
      count={pageCount || 10}
      page={pageNo + 1 || 1}
      shape="rounded"
      onChange={handleChangePage}
    />
  );

  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  const handleClick = (index: number) => {
    if (selectedIndex === index) {
      setSelectedIndex(-1); // Collapse the selected div if it's already expanded
    } else {
      setSelectedIndex(index); // Expand the selected div
    }
  };
  return (
    <>
      {classes?.map((classes: any, i: number) => (
        <div
          style={{
            border: open ? '1px solid #DCDCDC' : 'none',
            borderRadius: '10px 10px 0px 0px',
            marginBottom: '16px',
          }}
        >
          <Card
            style={{
              backgroundColor: color,
              borderRadius: '10px 10px 0px 0px',
              boxShadow:
                '0px 2px 1px -1px rgba(76, 78, 100, 0.2),0px 1px 1px 0px rgba(76, 78, 100, 0.14),0px 1px 3px 0px rgba(76, 78, 100, 0.12)',
            }}
          >
            <ListItemButton
              style={{ padding: '3px 12px' }}
              onClick={() => handleClick(i)}
            >
              {/* <ListItemIcon>
                        class
                    </ListItemIcon> */}

              <Grid
                container
                spacing={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  color: '#131515',
                  '& .css-6uzn28-MuiTypography-root': {
                    color: '#131515',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '22px',
                  },
                }}
              >
                {header?.map((head) => (
                  <Grid item>
                    {head && classes && (
                      <>
                        <Typography
                          color={head.color}
                          fontSize={'14px'}
                          fontWeight={600}
                          gutterBottom
                        >
                          {head?.label}
                        </Typography>
                        <Tooltip
                          title={head?.value === 'name' ? classes['id'] : ''}
                        >
                          <Typography
                            fontWeight={600}
                            color={'#131515'}
                            className="w-48"
                          >
                            {head?.value === 'name'
                              ? classes['name'] + ' ' + classes['lastname'] ||
                                ''
                              : classes[head?.value] || '-'}
                          </Typography>
                        </Tooltip>
                      </>
                    )}
                  </Grid>
                ))}
                <Grid
                  item
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {selectedIndex === i ? <ExpandLess /> : <ExpandMore />}
                </Grid>
              </Grid>
            </ListItemButton>
          </Card>

          <Collapse in={selectedIndex === i} timeout="auto" unmountOnExit>
            <StudentsList
              selected={selected}
              subHeader={subHeader}
              assignments={classes?.assessments}
              assignmentTypeHeader={assignmentTypeHeader}
              assignmentMasteredHeader={assignmentMasteredHeader}
              handleAssignmentChange={handleAssignmentChange}
              selectedAssessments={selectedAssessments}
              wrapper={classes}
              selectAll={selectAll}
            />
          </Collapse>
        </div>
      ))}
      {/* {showPagination && ( */}
      {true && (
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          my={4}
          sx={{ border: '1px solid #E9EBED', borderRadius: '6px', p: 2 }}
        >
          {/* {entriesPerPage && ( */}
          {true && (
            <Box flex="1" display="flex" alignItems="center">
              <Typography variant="body2" className="entries-page-txt">
                Show&nbsp;&nbsp;
              </Typography>
              <Autocomplete
                disableClearable
                value={pageSize?.toString()}
                options={['10', '25', '50', '100']}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10));
                }}
                size="small"
                sx={{ width: '5rem' }}
                renderInput={(params) => <TextField {...params} />}
              />
              <Typography variant="body2" className="entries-page-txt">
                &nbsp;&nbsp;entries of {totalCount}
              </Typography>
            </Box>
          )}
          {renderPagination()}
        </Box>
      )}
    </>
  );
}

// const selectedAssessments = [
//   { userIds: [123], assignmentIds: [1234, 1236] },
//   { userIds: [122], assignmentIds: [123, 121, 120] },
// ];

// const [selectedAssessments, setSelectedAssessments] = useState([]);
// const handleAssignmentChange = (
//   userId: string,
//   skillId: string,
//   name: string,
//   lastname: string,
//   email: string,
//   profileImage: string,
// ) => {
//   setSelectedAssessments((prevAssessments: any) => {
//     const assessmentIndex = prevAssessments.findIndex((assessment: any) =>
//       assessment.userIds.includes(userId),
//     );

//     if (assessmentIndex !== -1) {
//       const updatedAssessments = [...prevAssessments];
//       const assignmentIds = updatedAssessments[assessmentIndex].assignmentIds;
//       const skillIndex = assignmentIds.indexOf(skillId);

//       if (skillIndex === -1) {
//         assignmentIds.push(skillId);
//       } else {
//         assignmentIds.splice(skillIndex, 1);
//       }

//       return updatedAssessments;
//     }

//     // User doesn't exist, add a new assessment
//     return [
//       ...prevAssessments,
//       {
//         userIds: [userId],
//         assignmentIds: [skillId],
//         name: name,
//         lastname: lastname,
//         email: email,
//         profileImage: profileImage,
//       },
//     ];
//   });
// };
// I have this function which used for adding skillId of selected student's assessments.
//  the things is like a table there are list of students and for each student there are
//  list of assessments this function is for each assessments there is a checkbox
//  performs based on checked and unchecked to store selected assessments(skillId attached to it)
//  .what i want now is im having a functionality to select all assessments(skillId) for each student and
//   when selected once add those assessments with the studnet detail and when selecting again delte all the added assessments.

// Create a function like this
// const selectAll = (assessments: any, wrapper: any) => {};

// datas for these userId,name,lastname,email,profileImage can be found in the wrapper which is going to be same for all but
// skillId if found from assessments array which is different for each.

// this is the sample data when clicking one assessments and add for that specific student only so what we do now is try to add
// all the assessments of that student. try to understand the adding function for single Assessment.
// const selectedAssessmentsSample = [
//   {
//     userIds: ['4804097274'],
//     assignmentIds: ['tmpma8f111', 'tmpma8f121', 'tmpma8gr121'],
//     name: ' JaQuarius',
//     lastname: 'Robinson',
//     email: '4804097274@students.ocps.net',
//     profileImage:
//       'https://storage.googleapis.com/new-backend-dev-bucket/user_profiles/8cfd55d2-6864-47a0-ac4d-f32ecab0d5c3.jpg',
//   },
//   {
//     userIds: ['4804213800'],
//     assignmentIds: ['tmpma8ar411', 'tmpma8ar431', 'tmpma8ar421'],
//     name: ' Jeffrey',
//     lastname: 'Michel',
//     email: '4804213800@students.ocps.net',
//     profileImage:
//       'https://storage.googleapis.com/new-backend-dev-bucket/user_profiles/8cfd55d2-6864-47a0-ac4d-f32ecab0d5c3.jpg',
//   },
// ];
