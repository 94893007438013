import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Stack,
  Link,
  Breadcrumbs,
  Card,
  Tabs,
  Tab,
  Grid,
  Button,
  TextField,
  InputAdornment,
} from "@mui/material";
import CustomTable from "components/customTable";
import DefaultCell from "components/DefaultCell";
import { EditIcon, DeleteIcon, ViewIcon, LinkIcon } from "icons";
import Profile from "assets/actionIcons/profile.png";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SearchBar from "components/searchBar";
import { route_info } from "navigation/route";
import { useNavigate } from "react-router-dom";
import SearchIcon from "icons/search";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function handleBreadCrumbClick(
  event: React.MouseEvent<HTMLDivElement, MouseEvent>
) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

function Index() {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [isOpen, setIsOpen] = useState(true);
  const [tabbarList, setTabbarList] = useState([
    "All",
    "Text",
    "Math Text",
    "Text to Sound",
    "Image",
    "Video",
  ]);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/"
      onClick={() => handleBreadCrumbClick}
    >
      Resources
    </Link>,
    <Typography key="2">Quiz</Typography>,
    <Typography key="3" color="#C73E9D">
      Manage Assessment
    </Typography>,
  ];

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleCreateModal = () => {
    setIsOpen(!isOpen);
  };

  const templateFormat = (title: string) => {
    return (
      <Box
        className=""
        sx={{
          p: 5,
          borderRadius: 1,
          bgcolor: "#E9F6F3",
          minHeight: "176px",
        }}
      >
        <Typography mb={4} fontSize={18} fontWeight={500} color={"#131515"}>
          {title}
        </Typography>
        <Typography mb={4} fontSize={14} fontWeight={400} color={"#484848"}>
          Conduct quizzes online and grade them automatically with our free Math
          Quiz template. Great for remo...
        </Typography>
        <Button
          onClick={() => navigate(route_info.content_management.question)}
          sx={{
            bgcolor: "#DCDCDC",
            width: "100%",
            color: "#484848",
            fontSize: 16,
            fontWeight: 500,
          }}
        >
          Use Template
        </Button>
      </Box>
    );
  };

  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        style={{ marginBottom: 20 }}
      >
        {breadcrumbs}
      </Breadcrumbs>
      <Card sx={{ p: 6 }}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          mb="24px"
        >
          <Typography className="table_title">Manage Quiz</Typography>
          <TextField
            placeholder="Search..."
            sx={{ mb: 0}}
            size="small"
            value={value}
            onChange={(e: any) => {}}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {/* <SearchBar onChangeSearch={() => {}} value="" style={{ mb: 0 }} /> */}
        </Box>

        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 3 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {tabbarList &&
              tabbarList?.length &&
              tabbarList?.map((data: any, index: number) => (
                <Tab label={data} {...a11yProps(index)} />
              ))}
          </Tabs>
        </Box>
        {tabbarList && tabbarList?.length
          ? tabbarList?.map((data: any, index: number) => (
              <TabPanel value={value} index={index}>
                {index === 0 ? (
                  <Grid container spacing={10} mb={10}>
                    <Grid item xs={3}>
                      {templateFormat("Text")}
                    </Grid>
                    <Grid item xs={3}>
                      {templateFormat("Text to Sound")}
                    </Grid>
                    <Grid item xs={3}>
                      {templateFormat("Image")}
                    </Grid>
                    <Grid item xs={3}>
                      {templateFormat("Video")}
                    </Grid>
                    <Grid item xs={3}>
                      {templateFormat("Mixed")}
                    </Grid>
                    {/* <Grid item xs={3}>
                      {templateFormat(index + 4)}
                    </Grid> */}
                  </Grid>
                ) : (
                  <Typography>{data}</Typography>
                )}
              </TabPanel>
            ))
          : null}
        {/* <CreateModal isOpen={isOpen} handleCloseClick={handleCreateModal} /> */}
      </Card>
    </>
  );
}

export default Index;
