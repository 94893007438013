import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

export interface ResponseData {
  status: String;
  messagecode: String;
  message: String;
  data: any;
}

interface IntialState {
  dailyChallengesList: any;
  dailyChallengesObj: any;
}

const intialState: IntialState = {
  dailyChallengesList: [],
  dailyChallengesObj: {},
};

const DailyChallengesSlice = createSlice({
  name: 'daily_challenges_slice',
  initialState: intialState,
  reducers: {
    setDailyChallengesList: (
      state: Draft<IntialState>,
      action: PayloadAction<[]>,
    ) => {
      state.dailyChallengesList = action.payload;
    },
    setDailyChallengesById: (
      state: Draft<IntialState>,
      action: PayloadAction<any>,
    ) => {
      state.dailyChallengesObj = action.payload;
    },
  },
});

export const { setDailyChallengesList, setDailyChallengesById } =
  DailyChallengesSlice.actions;

export default DailyChallengesSlice.reducer;
