import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

export interface ResponseData {
  status: String;
  messagecode: String;
  message: String;
  data: any;
}

interface IntialState {
  user: any;
  userQR: any;
  userList: any;
  domainList: any;
  tutorList: any;
  studentList: any;
  teacherList: any;
  parentList: any;
  schoolLeaderList: any;
  screenLoading: boolean;
  loading: boolean;
}

const intialState: IntialState = {
  user: [],
  userQR: '',
  userList: [],
  domainList: [],
  tutorList: [],
  studentList: [],
  teacherList: [],
  parentList: [],
  schoolLeaderList: [],
  screenLoading: false,
  loading: false,
};

const UserSlice = createSlice({
  name: 'user_slice',
  initialState: intialState,
  reducers: {
    setUserList: (state: Draft<IntialState>, action: PayloadAction<[]>) => {
      state.userList = action.payload;
    },
    setDomainList: (state: Draft<IntialState>, action: PayloadAction<[]>) => {
      state.domainList = action.payload;
    },
    setTutorList: (state: Draft<IntialState>, action: PayloadAction<[]>) => {
      state.tutorList = action.payload;
    },
    setUserQR: (state: Draft<IntialState>, action: PayloadAction<[]>) => {
      state.userQR = action.payload;
    },
    setScreenLoading: (
      state: Draft<IntialState>,
      action: PayloadAction<boolean>,
    ) => {
      state.screenLoading = action.payload;
    },
    setParentList: (
      state: Draft<IntialState>,
      action: PayloadAction<boolean>,
    ) => {
      state.parentList = action.payload;
    },
    setSchoolLeaderList: (
      state: Draft<IntialState>,
      action: PayloadAction<boolean>,
    ) => {
      state.schoolLeaderList = action.payload;
    },
    setTeacherList: (
      state: Draft<IntialState>,
      action: PayloadAction<boolean>,
    ) => {
      state.teacherList = action.payload;
    },
    setStudentList: (
      state: Draft<IntialState>,
      action: PayloadAction<boolean>,
    ) => {
      state.studentList = action.payload;
    },
    setUser: (state: Draft<IntialState>, action: PayloadAction<[]>) => {
      state.user = action.payload;
    },
    setLoading: (state: Draft<IntialState>, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const {
  setUserList,
  setScreenLoading,
  setTutorList,
  setSchoolLeaderList,
  setTeacherList,
  setUserQR,
  setParentList,
  setStudentList,
  setUser,
  setDomainList,
  setLoading,
} = UserSlice.actions;

export default UserSlice.reducer;
