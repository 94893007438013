import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { parents, school, student, teacher2 } from 'assets';

import { TableList } from '../../../components/Dashboard/table';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { getDashboardInfo } from '../../../store/Thunk/dashboardThunk';
import { CardInfo } from '../../../components/Dashboard/cardInfo';

import Students from '../../../components/Dashboard/students';
// import {studentsList} from '../../../components/Dashboard/students.mock'
import { StudInfo } from '../../../components/Dashboard/student';
import { ITeacher } from '../../../components/Dashboard/table';

function Index() {
  const dispatch: any = useAppDispatch();
  // const { teachers  } = useAppSelector((state: any) => state.dashboardReducer);
  // const studentsList: StudInfo[] =[]
  // const teachers:ITeacher =[]
  const teacherSchool: any = localStorage.getItem('teacherSchool');

  useEffect(() => {
    dispatch(getDashboardInfo({ id: teacherSchool }));
  }, []);
  const {
    totalStudentsCount,
    totalTeachersCount,
    totalParentsCount,
    totalClassroomsCount,
  } = useAppSelector((state: any) => state.dashboardReducer);
  return (
    <>
      <Grid container spacing={4} sx={{ mt: 3, mb: 4 }}>
        <CardInfo
          item={{
            title: 'Total Students',
            content: `${totalStudentsCount}`,
            icon: `${student}`,
          }}
        />
        <CardInfo
          item={{
            title: 'Total Assigned Video Lessons',
            content: `${totalTeachersCount}`,
            icon: `${teacher2}`,
          }}
        />
        <CardInfo
          item={{
            title: 'Total Assigned Quizzes',
            content: `${totalParentsCount}`,
            icon: `${parents}`,
          }}
        />
        <CardInfo
          item={{
            title: 'Total Skills Mastered',
            content: `${totalClassroomsCount}`,
            icon: `${school}`,
          }}
        />
      </Grid>
      <Students
        students={[]}
        title={'Total Skills Mastered by Students'}
        select={['Daily', 'Last 7 Days', 'Last 14 Days']}
      />
      <TableList teachers={[]} title={'Progress by  Classrooms '} />
      <Grid container spacing={4} sx={{ mt: 3, mb: 4 }}></Grid>
    </>
  );
}

export default Index;
