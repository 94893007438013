import React from 'react';
import Card from '@mui/material/Card';

import Typography from '@mui/material/Typography';

import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';

// import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';

import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/material';
import { VisibilityOutlined } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';

export interface IStudent {
  id?: string;
  name?: string;
  totalAssignments?: string;
  totalDone?: string;
  pending?: string;
  progress?: string;
  dueDate?: string;
}

export interface IClasses {
  id?: string;
  name?: string;
  totalSkills?: string;
  onTarget?: string;
  totalVideoWatchHours?: string;
  totalScore?: string;
  students?: IStudent[];
}

const StudentsList = ({
  students,
  classroom,
}: {
  students: IStudent[] | undefined;
  classroom: any;
}) => {
  console.log('dasboard teacher info new: ', students);
  console.log('dasboard teacher info new: ', classroom);
  let stud: any[] = [];
  if (students && students.length > 0) {
    stud = students;
  }
  const history = useNavigate();
  const teacherSchool: any = localStorage.getItem('teacherSchool');

  const navigate = useNavigate();

  const handleStudent = (studentId: string) => {
    history(
      `/school/${teacherSchool}/classroom/${
        classroom?.id
      }?studentId=${studentId}&topic=${classroom?.topic?.name}&topicId=${
        classroom?.topic?.id
      }&grade=${classroom?.grade?.name}&gradeId=${
        classroom?.grade?.id
      }&studentDetail=${true}`,
    );
  };
  return (
    <List component="div" disablePadding>
      {stud?.map((student) => (
        <Box
          sx={{
            m: 2,
            backgroundColor: '#E9EBED',
            borderRadius: '10px',
            marginBottom: '12px',
          }}
        >
          <ListItem sx={{ pl: 4, pt: '3px', pb: '4px' }}>
            {/* grid for listing student properties */}
            <Grid
              container
              spacing={2}
              sx={{
                color: '#131515',
                display: 'flex',
                justifyContent: 'space-between',
                '& .css-6uzn28-MuiTypography-root': {
                  color: '#131515',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '22px',
                  display: 'flex',
                },
              }}
            >
              <Grid item>
                <Typography color="#84818A" fontSize={'14px'} gutterBottom>
                  Student Name
                </Typography>
                <Tooltip title={student?.user?.id}>
                  <Typography
                    fontWeight={'600'}
                    color={'#131515'}
                    sx={{
                      width: '150px',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    {student?.name}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item>
                <Typography color="#84818A" fontSize={'14px'} gutterBottom>
                  Total assignments
                </Typography>
                <Typography fontWeight={'600'} color={'#131515'}>
                  {student?.totalAssignments}
                </Typography>
              </Grid>
              <Grid item>
                <Typography color="#84818A" fontSize={'14px'} gutterBottom>
                  Completed
                </Typography>
                <Typography fontWeight={'600'} color={'#131515'}>
                  {student?.totalDone || 0}
                </Typography>
              </Grid>
              <Grid item>
                <Typography color="#84818A" fontSize={'14px'} gutterBottom>
                  Not Started
                </Typography>
                <Typography fontWeight={'600'} color={'#131515'}>
                  {student?.totalTodo || 0}
                </Typography>
              </Grid>
              <Grid item>
                <Typography color="#84818A" fontSize={'14px'} gutterBottom>
                  Total $G3MS
                </Typography>
                <Typography fontWeight={'600'} color={'#131515'}>
                  {student?.totalGems || 0}
                </Typography>
              </Grid>
              <Grid item>
                <Typography color="#84818A" fontSize={'14px'} gutterBottom>
                  Intervention Recommended
                </Typography>
                <Typography fontWeight={'600'} fontSize={'14px'} color={'red'}>
                  {student?.interventionRecommended}
                </Typography>
              </Grid>
              <Grid item>
                <Typography color="#84818A" fontSize={'14px'} gutterBottom>
                  Overall Grade
                </Typography>
                <Typography
                  fontWeight={'600'}
                  color={
                    parseInt(student?.overAllGrade) > 74 ? '#202020' : '#ff0000'
                  }
                  fontSize={'14px'}
                >
                  {Math.round(parseInt(student?.overAllGrade)) || 0}%
                </Typography>
              </Grid>
              <Grid
                item
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <IconButton
                  style={{ color: '#20A58A' }}
                  edge="end"
                  aria-label="comments"
                  onClick={() => handleStudent(student?.id)}
                >
                  <VisibilityOutlined />
                </IconButton>
              </Grid>
            </Grid>
          </ListItem>
        </Box>
      ))}
    </List>
  );
};

export default function TableTeacherProgress({
  classes,
  color,
}: {
  classes: IClasses | undefined;
  color: string;
}) {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <div
      style={{
        border: open ? '1px solid #DCDCDC' : 'none',
        borderRadius: '10px 10px 0px 0px',
        width: '100%',
        minWidth: '873px',
        alignSelf: 'center',
      }}
    >
      <Card
        style={{
          backgroundColor: color,
          borderRadius: '10px 10px 0px 0px',
          boxShadow:
            '0px 2px 1px -1px rgba(76, 78, 100, 0.2),0px 1px 1px 0px rgba(76, 78, 100, 0.14),0px 1px 3px 0px rgba(76, 78, 100, 0.12)',
        }}
      >
        <ListItemButton style={{ padding: '16px 12px' }} onClick={handleClick}>
          {/* <ListItemIcon>
                        class
                    </ListItemIcon> */}

          <Grid
            container
            spacing={2}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              color: '#131515',

              '& .css-6uzn28-MuiTypography-root, .css-v8f53y-MuiTypography-root':
                {
                  color: '#131515',
                  fontSize: '14px',
                  lineHeight: '22px',
                },
            }}
          >
            <Grid item>
              <Tooltip title={classes?.id}>
                <Typography fontWeight={'700'} variant="subtitle1">
                  {classes?.name}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </ListItemButton>
      </Card>

      <Collapse sx={{ padding: '5px' }} in={open} timeout="auto" unmountOnExit>
        <StudentsList classroom={classes} students={classes?.students} />
      </Collapse>
    </div>
  );
}
