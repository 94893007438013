import { Box, Button, Divider, Stack } from '@mui/material';
import TableHeader from 'components/tableHeader';
import React, { useCallback, useState } from 'react';
import { Circles } from 'react-loader-spinner';

import { useAppDispatch } from 'store/store';
import StudentProgressHeader from './StudentProgressHeader';
import AssignmentList from 'pages/super_admin/school_management/assignment/AssignmentList';
import AssignmentView from 'pages/super_admin/school_management/assignment/AssignmentView';
export const AdminAssignmentStatus = {
  All: 'All',
  Not_Started: 'Not Started',
  Started: 'Started',
  Completed: 'Completed',
  InProgress: 'InProgress',
};
export default function ProgressTable() {
  const dispatch = useAppDispatch();

  const [search, setSearch] = useState('');
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tabValue, setTabValue] = useState(AdminAssignmentStatus.All);
  const color = '#E9F6F3';
  const [isOpenAssignmentView, setIsOpenAssignmentView] = React.useState(false);

  const handleCloseView = () => {
    setIsOpenAssignmentView(false);
  };
  const [selectedAssignment, setSelectedAssignment] = React.useState<any>();
  const header = [
    { label: 'Classroom Name', width: 3, value: 'name' },
    { label: 'Total Assignments', width: 1.5, value: 'totalAssignments' },
    { label: 'Completed', width: 1.5, value: 'totalCompletedAssignments' },
    { label: 'Not Started', width: 1.5, value: 'totalNotStartedAssignments' },
    { label: 'Overall Grade', width: 1.5, value: 'totalGems' },
    {
      label: 'Intervention Needed?',
      width: 1.5,
      value: 'interVentionRecommended',
    },
  ];

  const CHECK_POINT = 20;
  const studentProgressByClassroom = { items: [], meta: {} };
  const tabValues = [
    { title: 'All', value: AdminAssignmentStatus.All },
    { title: 'Completed', value: AdminAssignmentStatus.Completed },
    { title: 'Not Started', value: AdminAssignmentStatus.Not_Started },
    { title: 'In Progress', value: AdminAssignmentStatus.InProgress },
  ];

  const fetchData = useCallback(
    ({ pageSize, pageIndex }: any) => {
      setPageNo(pageIndex);
      setPageSize(pageSize);
    },
    [dispatch, setPageNo, tabValue, setPageSize, search],
  );
  return (
    <Stack mt={12}>
      <TableHeader
        item={{
          title: 'Progress By Classrooms',
          action: true,
          setSearch: setSearch,
          fetchData,
        }}
      />
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          {tabValues?.map((item) => {
            return (
              <>
                <Box
                  sx={
                    tabValue === item?.value
                      ? {
                          borderBottom: '4px #C73E9D solid',
                          color: '#000',
                        }
                      : null
                  }
                >
                  <Button
                    onClick={() => {
                      setTabValue(item?.value);
                    }}
                    sx={{
                      p: '8px 30px',
                      borderRadius: '0px',
                      color: tabValue === item?.value ? '#484848' : '#84818A',
                      fontSize: '12px',
                      fontWeight: tabValue === item?.value ? '600' : '500',
                      lineHeight: '22px',
                      '&:hover': {
                        backgroundColor: 'none',
                      },
                      textTransform: 'none',
                    }}
                  >
                    {item?.title}
                  </Button>
                </Box>
              </>
            );
          })}
        </Box>
        <Divider sx={{ mt: 0 }} />
      </Box>
      <StudentProgressHeader />
      {false ? (
        <Stack alignItems={'center'}>
          <Circles width="30" color="#c73e9d" visible={false} />
        </Stack>
      ) : (
        true && (
          <>
            <AssignmentList
              color={color}
              search={search}
              pageNo={pageNo}
              setPageNo={setPageNo}
              pageSize={pageSize}
              assignmentsList={studentProgressByClassroom.items}
              assignmentsListMeta={studentProgressByClassroom.meta}
              classes={studentProgressByClassroom.items}
              setSelectedAssignment={setSelectedAssignment}
              setIsOpenAssignmentView={setIsOpenAssignmentView}
              header={header}
              hidePagination={true}
            />
            <AssignmentView
              open={isOpenAssignmentView}
              onClose={handleCloseView}
              selectedAssignments={selectedAssignment}
            />
          </>
        )
      )}
    </Stack>
  );
}
