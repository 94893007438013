import MESSAGES from './messages';
import { isValidEmail } from '@core/utils/global-mock';
const Validations = {
  validateVideoForm: (form: any, isAcademic: any) => {
    const errors: Partial<any> = {};
    const {
      title,
      districtIds,
      schoolIds,
      topicId,
      subTopicId,
      skillId,
      userId,
      gradeIds,
      assetId,
      domainId,
      videoFile,
    } = form;

    if (!title) {
      errors.title = MESSAGES.VIDEO.ERROR.TITLE.EMPTY;
    }
    if (districtIds?.length === 0) {
      errors.district = MESSAGES.VIDEO.ERROR.DISTRICT.EMPTY;
    }
    if (schoolIds?.length === 0) {
      errors.school = MESSAGES.VIDEO.ERROR.SCHOOL.EMPTY;
    }
    if (!topicId) {
      errors.topic = MESSAGES.VIDEO.ERROR.TOPIC.EMPTY;
    }
    if (!subTopicId && isAcademic) {
      errors.sub_topic = MESSAGES.VIDEO.ERROR.SUB_TOPIC.EMPTY;
    }
    if (!skillId && isAcademic) {
      errors.skill = MESSAGES.VIDEO.ERROR.SKILL.EMPTY;
    }
    if (!domainId && isAcademic) {
      errors.skill = MESSAGES.VIDEO.ERROR.SKILL.EMPTY;
    }
    /* if (!userId) {
      errors.user = MESSAGES.VIDEO.ERROR.USER.EMPTY
    } */
    if (!gradeIds?.length) {
      errors.grade = MESSAGES.VIDEO.ERROR.GRADE.EMPTY;
    }
    if (!assetId && !videoFile?.length) {
      errors.videoFile = MESSAGES.VIDEO.ERROR.FILE.EMPTY;
    }
    if (!assetId && !videoFile?.length) {
      errors.assetId = MESSAGES.VIDEO.ERROR.FILE.EMPTY;
    }
    return errors;
  },
  validateUpdateVideoForm: (form: any, isAcademic: any) => {
    const errors: Partial<any> = {};
    const {
      title,
      districtIds,
      schoolIds,
      topicId,
      subTopicId,
      skillId,
      userId,
      gradeIds,
      videoFile,
      assetId,
      playbackId,
    } = form;

    if (!title) {
      errors.title = MESSAGES.VIDEO.ERROR.TITLE.EMPTY;
    }
    if (districtIds?.length === 0) {
      errors.district = MESSAGES.VIDEO.ERROR.DISTRICT.EMPTY;
    }
    if (schoolIds?.length === 0) {
      errors.school = MESSAGES.VIDEO.ERROR.SCHOOL.EMPTY;
    }
    if (!topicId) {
      errors.topic = MESSAGES.VIDEO.ERROR.TOPIC.EMPTY;
    }
    if (!subTopicId && isAcademic) {
      errors.sub_topic = MESSAGES.VIDEO.ERROR.SUB_TOPIC.EMPTY;
    }
    if (!skillId && isAcademic) {
      errors.skill = MESSAGES.VIDEO.ERROR.SKILL.EMPTY;
    }
    if (!userId) {
      errors.user = MESSAGES.VIDEO.ERROR.USER.EMPTY;
    }
    if (!gradeIds?.length) {
      errors.grade = MESSAGES.VIDEO.ERROR.GRADE.EMPTY;
    }

    return errors;
  },

  validateTeacherForm: (form: any, current_user: string) => {
    const errors: Partial<any> = {};
    const {
      salutation,
      name,
      role,
      email,
      school,
      classrooms,
      state,
      gradeIds,
      topicIds,
      zipCode,
      teachMethod,
      phone,
    } = form;

    if (!salutation) {
      errors.salutation = 'Salutation is required';
    }

    if (!phone) {
      errors.phone = 'Phone number is required';
    }

    if (!name) {
      errors.name = 'Name is required';
    }

    if (!role) {
      errors.role = 'Role is required';
    }

    if (!email) {
      errors.email = 'Email is required';
    } else if (!isValidEmail(email)) {
      errors.email = 'Invalid email format';
    }

    if (!school) {
      errors.school = 'School is required';
    }

    if (!classrooms || classrooms.length === 0) {
      errors.classroom = 'At least one classroom is required';
    }

    if (!state && current_user === 'super_admin') {
      errors.state = 'State is required';
    }

    if ((!gradeIds || gradeIds.length === 0) && teachMethod === 'teacher') {
      errors.grades = 'At least one grade is required';
    }

    if ((!topicIds || topicIds.length === 0) && teachMethod === 'tutor') {
      errors.topicIds = 'At least one topic is required';
    }

    if (!zipCode) {
      errors.zipCode = 'Zip Code is required';
    }

    if (!teachMethod) {
      errors.teachMethod = 'Teaching method is required';
    }
    return errors;
  },
  validateSchoolLeaderForm: (form: any, current_user: string) => {
    const errors: Partial<any> = {};
    const {
      salutation,
      name,
      role,
      email,
      school,
      state,
      gradeIds,
      topicIds,
      zipCode,
      teachMethod,
      phone,
    } = form;

    // if (!phone) {
    //   errors.phone = 'Phone number is required';
    // }

    if (!name) {
      errors.name = 'Name is required';
    }

    if (!role) {
      errors.role = 'Role is required';
    }

    if (!email) {
      errors.email = 'Email is required';
    } else if (!isValidEmail(email)) {
      errors.email = 'Invalid email format';
    }

    if (!school) {
      errors.school = 'School is required';
    }

    if (!state && current_user === 'super_admin') {
      errors.state = 'State is required';
    }

    return errors;
  },

  validateQuizTemplateForm: (form: any) => {
    const errors: Partial<any> = {};
    const {
      // type,
      title,
      colorCode,
      districtIds,
      schoolIds,
      topicId,
      subTopicId,
      skillId,
      createdById,
      gradeIds,
      // videoIds,
    } = form;

    if (!title) {
      errors.title = MESSAGES.QUIZ_TEMPLATE.ERROR.TITLE.EMPTY;
    }
    // if (!type) {
    //   errors.type = MESSAGES.QUIZ_TEMPLATE.ERROR.TYPE.EMPTY;
    // }

    if (!colorCode) {
      errors.colorCode = MESSAGES.QUIZ_TEMPLATE.ERROR.COLORCODE.EMPTY;
    }
    // if (!teacherId) {
    //   errors.teacher = MESSAGES.QUIZ_TEMPLATE.ERROR.TEACHER.EMPTY;
    // }
    if (districtIds.length === 0) {
      errors.district = MESSAGES.QUIZ_TEMPLATE.ERROR.DISTRICT.EMPTY;
    }
    if (schoolIds.length === 0) {
      errors.school = MESSAGES.QUIZ_TEMPLATE.ERROR.SCHOOL.EMPTY;
    }
    if (!topicId) {
      errors.topic = MESSAGES.QUIZ_TEMPLATE.ERROR.TOPIC.EMPTY;
    }
    if (!subTopicId) {
      errors.sub_topic = MESSAGES.QUIZ_TEMPLATE.ERROR.SUB_TOPIC.EMPTY;
    }
    if (!skillId) {
      errors.skill = MESSAGES.QUIZ_TEMPLATE.ERROR.SKILL.EMPTY;
    }
    if (!createdById) {
      errors.teacher = MESSAGES.QUIZ_TEMPLATE.ERROR.TEACHER.EMPTY;
    }
    if (!gradeIds?.length) {
      errors.grade = MESSAGES.QUIZ_TEMPLATE.ERROR.GRADE.EMPTY;
    }
    // if (!videoIds) {
    //   errors.video = MESSAGES.QUIZ_TEMPLATE.ERROR.VIDEO.EMPTY;
    // }
    return errors;
  },
  validateSkillForm: (form: any) => {
    const errors: Partial<any> = {};
    const { name, sequenceNumber } = form;
    if (!name) {
      errors.name = MESSAGES.SKILL.ERROR.NAME.EMPTY;
    }
    if (!sequenceNumber) {
      errors.sequenceNumber = MESSAGES.SKILL.ERROR.SEQUENCE_NUMBER.EMPTY;
    }
    return errors;
  },
  validateDomainForm: (form: any) => {
    const errors: Partial<any> = {};
    const { name, topicId } = form;
    if (!name) {
      errors.name = MESSAGES.DOMAIN.ERROR.NAME.EMPTY;
    }
    if (!topicId) {
      errors.topicId = MESSAGES.DOMAIN.ERROR.TOPIC_ID.EMPTY;
    }
    return errors;
  },
  validateTopicForm: (form: any) => {
    const errors: Partial<any> = {};
    const { name } = form;
    if (!name) {
      errors.name = MESSAGES.TOPIC.ERROR.NAME.EMPTY;
    }

    return errors;
  },
  validateGenerateQuestionForm: (form: any) => {
    const errors: Partial<any> = {};
    const { questionsCount, questionType, questionLevel } = form;

    if (!questionsCount) {
      errors.questionsCount = MESSAGES.GENERATE.ERROR.QUESTION_COUNT.EMPTY;
    }
    if (!questionType) {
      errors.questionType = MESSAGES.GENERATE.ERROR.QUSTION_TYPE.EMPTY;
    }
    if (!questionLevel) {
      errors.questionLevel = MESSAGES.GENERATE.ERROR.QUESTION_LEVEL.EMPTY;
    }
    return errors;
  },

  ValidateQuestionForm: (form: any) => {
    const errors: Partial<any> = {};
    const {
      optionA,
      optionB,
      optionC,
      optionD,
      answer,
      question,
      message,
      explanation,
    } = form;

    if (!optionA) {
      errors.optionA = MESSAGES.QUESTION.ERROR.OPTIONA.EMPTY;
    }
    if (!optionB) {
      errors.optionB = MESSAGES.QUESTION.ERROR.OPTIONB.EMPTY;
    }
    if (!optionC) {
      errors.optionC = MESSAGES.QUESTION.ERROR.OPTIONC.EMPTY;
    }
    if (!optionD) {
      errors.optionD = MESSAGES.QUESTION.ERROR.OPTIOND.EMPTY;
    }
    if (!answer) {
      errors.answer = MESSAGES.QUESTION.ERROR.ANSWER.EMPTY;
    }
    if (!question) {
      errors.question = MESSAGES.QUESTION.ERROR.QUESTION.EMPTY;
    }
    if (!message) {
      errors.message = MESSAGES.QUESTION.ERROR.MESSAGE.EMPTY;
    }
    if (!explanation) {
      errors.explanation = MESSAGES.QUESTION.ERROR.MESSAGE.EMPTY;
    }

    return errors;
  },
  ValidateMediaQuestionForm: (form: any) => {
    const errors: Partial<any> = {};
    const {
      optionA,
      optionB,
      optionC,
      optionD,
      optionAMedia,
      optionBMedia,
      optionCMedia,
      optionDMedia,
      answer,
      question,
      questionMedia,
      message,
      explanation,
    } = form;

    if (!optionA && !optionAMedia) {
      errors.optionA = MESSAGES.QUESTION.ERROR.OPTIONA.EMPTY;
    }
    if (!optionB && !optionBMedia) {
      errors.optionB = MESSAGES.QUESTION.ERROR.OPTIONB.EMPTY;
    }
    if (!optionC && !optionCMedia) {
      errors.optionC = MESSAGES.QUESTION.ERROR.OPTIONC.EMPTY;
    }
    if (!optionD && !optionDMedia) {
      errors.optionD = MESSAGES.QUESTION.ERROR.OPTIOND.EMPTY;
    }
    if (!answer) {
      errors.answer = MESSAGES.QUESTION.ERROR.ANSWER.EMPTY;
    }
    if (!question && !questionMedia) {
      errors.question = MESSAGES.QUESTION.ERROR.QUESTION.EMPTY;
    }
    if (!message) {
      errors.message = MESSAGES.QUESTION.ERROR.MESSAGE.EMPTY;
    }
    if (!explanation) {
      errors.explanation = MESSAGES.QUESTION.ERROR.MESSAGE.EMPTY;
    }

    return errors;
  },

  ValidateDailyChallengeFrom: (form: any) => {
    const errors: Partial<any> = {};
    const {
      id,
      title,
      question,
      datePosted,
      districtIds,
      schoolIds,
      topicId,
      subTopicId,
      skillId,
      videoId,
      userId,
      assetId,
      gradeIds,
      videoFile,
      uploadId,
    } = form;

    if (!title) {
      errors.title = MESSAGES.VIDEO.ERROR.TITLE.EMPTY;
    }
    if (!question) {
      errors.question = MESSAGES.VIDEO.ERROR.QUESTION.EMPTY;
    }
    if (!datePosted) {
      errors.date_posted = MESSAGES.VIDEO.ERROR.DATE_POSTED.EMPTY;
    }
    if (districtIds?.length === 0) {
      errors.district = MESSAGES.VIDEO.ERROR.DISTRICT.EMPTY;
    }
    if (schoolIds?.length === 0) {
      errors.school = MESSAGES.VIDEO.ERROR.SCHOOL.EMPTY;
    }
    if (!topicId) {
      errors.topic = MESSAGES.VIDEO.ERROR.TOPIC.EMPTY;
    }
    if (!subTopicId) {
      errors.sub_topic = MESSAGES.VIDEO.ERROR.SUB_TOPIC.EMPTY;
    }
    if (!skillId) {
      errors.skill = MESSAGES.VIDEO.ERROR.SKILL.EMPTY;
    }
    if (!videoId) {
      errors.video = MESSAGES.VIDEO.ERROR.VIDEO.EMPTY;
    }
    /* if (!userId) {
      errors.user = MESSAGES.VIDEO.ERROR.USER.EMPTY
    } */
    if (!gradeIds?.length) {
      errors.grade = MESSAGES.VIDEO.ERROR.GRADE.EMPTY;
    }
    if (!id && !videoFile.length) {
      errors.videoFile = MESSAGES.VIDEO.ERROR.FILE.EMPTY;
    }
    return errors;
  },
  ValidateDistrictDataFrom: (form: any) => {
    const errors: Partial<any> = {};
    const {
      id,
      name,
      ncesDistrictId,
      streetAddress,
      city,
      state,
      zipCode,
      county,
      districtType,
      startGrade,
      endGrade,
      startGradeId,
      endGradeId,
      federalFunding,
      stateFunding,
      localFunding,
      totalNoOfSchool,
      totalNoOfElementarySchool,
      totalNoOfMiddleSchool,
      totalNoOfHighSchool,
    } = form;

    if (!name) {
      errors.name = MESSAGES.DISTRICT.ERROR.NAME.EMPTY;
    }
    if (!ncesDistrictId) {
      errors.ncesDistrictId = MESSAGES.DISTRICT.ERROR.NCESDISTRICTID.EMPTY;
    }
    if (!streetAddress) {
      errors.streetAddress = MESSAGES.DISTRICT.ERROR.STREETADDRESS.EMPTY;
    }
    if (!city) {
      errors.city = MESSAGES.DISTRICT.ERROR.CITY.EMPTY;
    }
    if (!state) {
      errors.state = MESSAGES.DISTRICT.ERROR.STATE.EMPTY;
    }
    if (!zipCode) {
      errors.zipCode = MESSAGES.DISTRICT.ERROR.ZIPCODE.EMPTY;
    }
    if (!county) {
      errors.county = MESSAGES.DISTRICT.ERROR.COUNTY.EMPTY;
    }
    if (!districtType) {
      errors.districtType = MESSAGES.DISTRICT.ERROR.DISTRICTTYPE.EMPTY;
    }

    if (!startGradeId) {
      errors.startGradeId = MESSAGES.DISTRICT.ERROR.STARTGRADEID.EMPTY;
    }

    if (!endGradeId) {
      errors.endGradeId = MESSAGES.DISTRICT.ERROR.ENDGRADEID.EMPTY;
    }
    if (!federalFunding) {
      errors.federalFunding = MESSAGES.DISTRICT.ERROR.FEDERALFUNDING.EMPTY;
    }
    if (!stateFunding) {
      errors.stateFunding = MESSAGES.DISTRICT.ERROR.STATEFUNDING.EMPTY;
    }
    if (!localFunding) {
      errors.localFunding = MESSAGES.DISTRICT.ERROR.LOCALFUNDING.EMPTY;
    }
    if (!totalNoOfSchool) {
      errors.totalNoOfSchool = MESSAGES.DISTRICT.ERROR.TOTALNOOFSCHOOL.EMPTY;
    }
    if (!totalNoOfElementarySchool) {
      errors.totalNoOfElementarySchool =
        MESSAGES.DISTRICT.ERROR.TOTALNOOFELEMENTARYSCHOOL.EMPTY;
    }
    if (!totalNoOfMiddleSchool) {
      errors.totalNoOfMiddleSchool =
        MESSAGES.DISTRICT.ERROR.TOTALNOOFMIDDLESCHOOL.EMPTY;
    }
    if (!totalNoOfHighSchool) {
      errors.totalNoOfHighSchool =
        MESSAGES.DISTRICT.ERROR.TOTALNOOFHIGHSCHOOL.EMPTY;
    }
    return errors;
  },

  ValidateSchoolDataFrom: (form: any) => {
    const errors: Partial<any> = {};
    const {
      name,
      schoolType,
      specificSchoolType,
      schoolGrades,
      streetAddress,
      city,
      state,
      zipCode,
      county,
      phone,
      districtId,
      ncesSchoolId,
      schoolLeaderIds,
    } = form;

    if (!name) {
      errors.name = MESSAGES.SCHOOLLEADER.ERROR.NAME.EMPTY;
    }
    if (!schoolType) {
      errors.schoolType = MESSAGES.SCHOOLLEADER.ERROR.SCHOOLTYPE.EMPTY;
    }
    if (!streetAddress) {
      errors.streetAddress = MESSAGES.SCHOOLLEADER.ERROR.STREETADDRESS.EMPTY;
    }
    if (!city) {
      errors.city = MESSAGES.SCHOOLLEADER.ERROR.CITY.EMPTY;
    }
    if (!state) {
      errors.state = MESSAGES.SCHOOLLEADER.ERROR.STATE.EMPTY;
    }
    if (!zipCode) {
      errors.zipCode = MESSAGES.SCHOOLLEADER.ERROR.ZIPCODE.EMPTY;
    }
    if (!county) {
      errors.county = MESSAGES.SCHOOLLEADER.ERROR.COUNTY.EMPTY;
    }
    if (!specificSchoolType) {
      errors.specificSchoolType =
        MESSAGES.SCHOOLLEADER.ERROR.SPECIFICSCHOOLTYPE.EMPTY;
    }

    if (!schoolGrades) {
      errors.schoolGrades = MESSAGES.SCHOOLLEADER.ERROR.SCHOOLGRADES.EMPTY;
    }

    if (!phone) {
      errors.phone = MESSAGES.SCHOOLLEADER.ERROR.PHONE.EMPTY;
    }
    if (!districtId) {
      errors.districtId = MESSAGES.SCHOOLLEADER.ERROR.DISTRICTID.EMPTY;
    }
    if (!ncesSchoolId) {
      errors.ncesSchoolId = MESSAGES.SCHOOLLEADER.ERROR.NCESSCHOOLID.EMPTY;
    }
    if (!schoolLeaderIds) {
      errors.schoolLeaderIds =
        MESSAGES.SCHOOLLEADER.ERROR.SCHOOLLEADERIDS.EMPTY;
    }
    return errors;
  },

  ValidateClassroomDataFrom: (form: any) => {
    const errors: Partial<any> = {};
    const { name, topic, gradeId, period, classroomTeacher1Ids } = form;

    if (!name) {
      errors.name = MESSAGES.CLASSROOM.ERROR.NAME.EMPTY;
    }
    // if (!type) {
    //   errors.classroomType = MESSAGES.CLASSROOM.ERROR.CLASSROOMTYPE.EMPTY;
    // }
    if (!gradeId) {
      errors.grade = MESSAGES.CLASSROOM.ERROR.GRADE.EMPTY;
    }
    if (!topic) {
      errors.topic = MESSAGES.CLASSROOM.ERROR.TOPIC.EMPTY;
    }
    if (!period) {
      errors.classroomPeriod = MESSAGES.CLASSROOM.ERROR.CLASSROOMPERIOD.EMPTY;
    }
    // if (!classroomTeacher1Ids) {
    //   errors.classroomTeacher1Ids = MESSAGES.CLASSROOM.ERROR.CLASSROOMTEACHER1IDS.EMPTY;
    // }
    return errors;
  },

  ValidationsForSchoolAssignment: (info: any) => {
    const errors: Partial<any> = {};

    if (
      !(info.userIds?.length > 0 || Object.keys(info.userIds)?.length > 0) &&
      !(info.grade && info.topic)
    ) {
      errors.userIds = 'Select user or grade and department';
    }
    if (!(info.type === 'Daily_Practice') && !info.timeDuration) {
      errors.timeDuration = 'Select user or grade and department';
    }
    return errors;
  },
  ValidationsForAssignContent: (info: any) => {
    const errors: Partial<any> = {};
    if (!info.type) {
      errors.type = 'Select The type first';
    }

    if (!info.assignTo) {
      errors.assignTo = 'Select Type to which you want to assign?';
    }

    if (
      info.assignTo?.title &&
      info.assignTo?.title !== 'Whole class' &&
      !info?.assignToType
    ) {
      errors.assignToType = 'Please Select Whom you want to assign.';
    }

    if (!info.deadline) {
      errors.deadline = 'Deadline is required';
    }
    return errors;
  },

  validateAddStudentForm: (form: any) => {
    const errors: Partial<any> = {};
    const {
      name,

      email,
      studentId,
      gradeId,
      role,
      parentId,
      school,
      classrooms,
    } = form;

    if (!name) {
      errors.name = MESSAGES.ADD_STUDENT.ERROR.FUll_NAME.EMPTY;
    }
    if (!email) {
      errors.email = MESSAGES.ADD_STUDENT.ERROR.EMAIL.EMPTY;
    }

    if (!isValidEmail(email)) {
      errors.email = MESSAGES.ADD_PARENT.ERROR.EMAIL.INVALID;
    }
    // if (!studentId) {
    //   errors.studentId = MESSAGES.ADD_STUDENT.ERROR.STUDENT_ID.EMPTY;
    // }
    if (!gradeId?.length) {
      errors.grade = MESSAGES.ADD_STUDENT.ERROR.GRADE.EMPTY;
    }
    // if (role) {
    //   errors.role = 'Please Select Role';
    // }
    if (!parentId) {
      errors.parent = MESSAGES.ADD_STUDENT.ERROR.PARENT.EMPTY;
    }
    if (!school) {
      errors.school = MESSAGES.ADD_STUDENT.ERROR.SCHOOL.EMPTY;
    }
    if (!classrooms.length) {
      errors.classroom = MESSAGES.ADD_STUDENT.ERROR.CLASSROOM.EMPTY;
    }
    return errors;
  },

  validateAddParentForm: (form: any, current_user: string) => {
    const errors: Partial<any> = {};
    const { name, email, salutation, role, school, children, state } = form;

    if (!state && current_user === 'super_admin') {
      errors.state = MESSAGES.ADD_PARENT.ERROR.STATE.EMPTY;
    }

    // need to enable again

    if (!salutation) {
      errors.salutation = MESSAGES.ADD_PARENT.ERROR.SALUTATION.EMPTY;
    }

    if (!name) {
      errors.name = MESSAGES.ADD_PARENT.ERROR.FIRST_NAME.EMPTY;
    }

    if (!isValidEmail(email)) {
      errors.email = MESSAGES.ADD_PARENT.ERROR.EMAIL.INVALID;
    }

    if (!name) {
      errors.lastName = MESSAGES.ADD_PARENT.ERROR.LAST_NAME.EMPTY;
    }
    if (!email) {
      errors.email = MESSAGES.ADD_PARENT.ERROR.EMAIL.EMPTY;
    }

    if (!role) {
      errors.role = 'Please Select Role';
    }

    if (!school) {
      errors.school = MESSAGES.ADD_PARENT.ERROR.SCHOOL.EMPTY;
    }
    if (children.length === 0) {
      errors.children = MESSAGES.ADD_PARENT.ERROR.CHILDREN.EMPTY;
    }
    return errors;
  },

  ValidateGroupDataFrom: (form: any) => {
    const errors: Partial<any> = {};
    const {
      groupName,
      // type,
      studentIds,
    } = form;
    console.log('ERRRR@@@@', studentIds);
    if (!groupName) {
      errors.groupName = MESSAGES.GROUP.ERROR.GROUPNAME.EMPTY;
    }
    if (studentIds?.length < 1) {
      errors.studentIds = MESSAGES.GROUP.ERROR.STUDENTIDS.EMPTY;
    }
    return errors;
  },
  // validateADD_STUDNETForm: (form: any) => {
  //   const errors: Partial<any> = {};
  //   const {
  //     first_name,
  //     last_name,
  //     email,
  //     password,
  //     privacy
  //   } = form;

  //   if (!first_name) {
  //     errors.firstName = MESSAGES.ADD_STUDNET.ERROR.FIRST_NAME.EMPTY;
  //   }
  //   if (!last_name) {
  //     errors.lastName = MESSAGES.REGISTER.ERROR.LAST_NAME.EMPTY;
  //   }

  //   if (!email) {
  //     errors.email = MESSAGES.REGISTER.ERROR.EMAIL.EMPTY
  //   }
  //   if (email && !emailValidation.test(String(email).toLowerCase())) {
  //     errors.email = MESSAGES.REGISTER.ERROR.EMAIL.EMAIL_VALIDATION
  //   }

  //   if (!reWhiteSp.test(String(password).toLowerCase())) {
  //     errors.password = MESSAGES.VALID_PASSWORD.ERROR.PASSWORD.WHITE_SPACE;
  //   }

  //   if (password.length < 8) {
  //     errors.password = MESSAGES.VALID_PASSWORD.ERROR.PASSWORD.INVALID_LENGTH;
  //   }

  //   if (!smallLetter.test(password) || !capitalLetter.test(password) || password.search(/[0-9]/) < 0 || password.search(/[!#_$@%&]/) < 0) {
  //     errors.password = MESSAGES.VALID_PASSWORD.ERROR.PASSWORD.STRONG_PASSWORD;
  //   }

  //   if (!password) {
  //     errors.password = MESSAGES.REGISTER.ERROR.PASSWORD.EMPTY;
  //   }

  //   if(!privacy) {
  //     errors.privacy = MESSAGES.REGISTER.ERROR.PRIVACY.EMPTY;
  //   }

  //   return errors;
  // },

  // validateBusinessForm: (form: any) => {
  //   const errors: Partial<any> = {};
  //   const {
  //     business,
  //     company_name
  //   } = form;

  //   if (!business) {
  //     errors.business = MESSAGES.REGISTER.ERROR.BUSINESS.EMPTY;
  //   }

  //   if (!company_name) {
  //     errors.company_name = MESSAGES.REGISTER.ERROR.COMPANY_NAME.EMPTY;
  //   }

  //   return errors;
  // },
  // validateForgetPasswordForm: (form: any) => {
  //   const errors: Partial<any> = {};
  //   const { email } = form;

  //   if (!email) {
  //     errors.email = MESSAGES.LOGIN.ERROR.EMAIL.EMPTY
  //   } else if (email && !emailValidation.test(String(email).toLowerCase())) {
  //     errors.email = MESSAGES.LOGIN.ERROR.EMAIL.EMAIL_VALIDATION
  //   }

  //   return errors;
  // },
  // validateResetPasswordForm: (form: any) => {
  //   const errors: Partial<any> = {};
  //   const { password1, password2 } = form;

  //   if (!reWhiteSp.test(String(password1).toLowerCase())) {
  //     errors.password1 = MESSAGES.VALID_PASSWORD.ERROR.PASSWORD.WHITE_SPACE;
  //   }

  //   if (password1.length < 8) {
  //     errors.password1 = MESSAGES.VALID_PASSWORD.ERROR.PASSWORD.INVALID_LENGTH;
  //   }

  //   if (!smallLetter.test(password1) || !capitalLetter.test(password1) || password1.search(/[0-9]/) < 0 || password1.search(/[!#_$@%&]/) < 0) {
  //     errors.password1 = MESSAGES.VALID_PASSWORD.ERROR.PASSWORD.STRONG_PASSWORD;
  //   }

  //   if (!password1) {
  //     errors.password1 = MESSAGES.REGISTER.ERROR.PASSWORD.EMPTY;
  //   }
  //   // return errors;

  //   if (!reWhiteSp.test(String(password2).toLowerCase())) {
  //     errors.password2 = MESSAGES.VALID_PASSWORD.ERROR.PASSWORD.WHITE_SPACE;
  //   }

  //   if (password2.length < 8) {
  //     errors.password2 = MESSAGES.VALID_PASSWORD.ERROR.PASSWORD.INVALID_LENGTH;
  //   }

  //   if (!smallLetter.test(password2) || !capitalLetter.test(password2) || password2.search(/[0-9]/) < 0 || password2.search(/[!#_$@%&]/) < 0) {
  //     errors.password2 = MESSAGES.VALID_PASSWORD.ERROR.PASSWORD.STRONG_PASSWORD;
  //   }else if(password1 != password2) {
  //     errors.password2 = MESSAGES.REGISTER.ERROR.NOT_MATCH.INVALID;
  //   }

  //   if (!password2) {
  //     errors.password2 = MESSAGES.REGISTER.ERROR.PASSWORD.EMPTY;
  //   }

  //   return errors;
  // },
};

export default Validations;
