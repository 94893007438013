import React, { useState, useEffect } from 'react';
import AddModal from '.';
import { Autocomplete, InputLabel, List, TextField } from '@mui/material';
import { useAppDispatch } from 'store/store';
import { addStudentInClassRoom } from 'store/Thunk/classroomThunk';
import { getUnAssignedUsersList } from 'store/Thunk/userThunk';
import useSchoolClassroomInfo from 'hooks/useSchoolClassroomInfo';

function AddStudentToClassRoom({
  isAddStudentDialogOpen,
  setIsAddStudentDialogOpen,
  classRoomId,
  schoolId,
  fetchData,
  onClose,
}) {
  const [selectedStudents, setSelectedStudnents] = useState([]);
  const [isError, setIsError] = useState(false);
  const [selected, setSelected] = useState([]);
  const [openState, setOpenState] = React.useState(false);
  const urlParams = new URLSearchParams(window.location.search);

  const classroomId = urlParams.get('classroomId');
  const schoolID = urlParams.get('schoolId');

  const handleChangeAutocomplete = (_, values) => {
    const selectedValues = values?.map((student) => student.id);
    setSelectedStudnents(selectedValues);
    setSelected(values);
  };

  const dispatch = useAppDispatch();
  const [studentList, setStudentList] = useState([]);

  console.log('studentList', {
    schoolID,
    classroomId,
  });
  console.log('studentList', 'kkkkkkkkkk');
  console.log('studentList', studentList);
  console.log('studentList', openState);
  useEffect(() => {
    console.log('studentList', 'kkkkkkkkkk');
    console.log('studentList', openState);
    console.log('studentList', schoolId);
    console.log('studentList', classroomId);
    dispatch(
      getUnAssignedUsersList({
        schoolId: schoolID || schoolId,
        classId: classroomId || classRoomId,
        type: 'student',
      }),
    )
      .unwrap()
      .then((res) => {
        setStudentList(res?.data?.data);
      });
  }, [openState]);

  const handleSubmit = () => {
    if (selectedStudents?.length > 0) {
      setIsError(false);
      dispatch(
        addStudentInClassRoom({
          classId: classroomId || classRoomId,
          studentIds: selectedStudents,
        }),
      )
        .unwrap()
        .then((res) => {
          fetchData();
          onClose();
        })
        .catch((err) => {});
    } else {
      setIsError(true);
    }
  };

  return (
    <AddModal
      onClose={onClose}
      open={isAddStudentDialogOpen}
      setOpen={setIsAddStudentDialogOpen}
      onSave={handleSubmit}
      title="Add a Student to Classroom"
      info="*Note if a student is not listed then please contact your
            administrator to add the student"
    >
      <InputLabel error={isError} sx={{ color: '#484848', pb: 2 }}>
        Search And Select student
      </InputLabel>
      <Autocomplete
        multiple
        options={studentList || []}
        id="tags-standard"
        open={openState}
        onOpen={() => setOpenState(true)}
        onClose={() => setOpenState(false)}
        getOptionLabel={(option) => option?.name || ''}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        filterSelectedOptions
        onChange={handleChangeAutocomplete}
        ListboxComponent={(props) => {
          return (
            <List {...props}>
              {props.children.map((child, index) =>
                React.cloneElement(child, { key: index }),
              )}
            </List>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Select Student"
            error={Boolean('')}
            InputProps={{
              ...params.InputProps,
              endAdornment: <>{params.InputProps.endAdornment}</>,
            }}
          />
        )}
      />
    </AddModal>
  );
}

export default AddStudentToClassRoom;
