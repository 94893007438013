import React from "react";

function Index() {

  return (
    <p>Coming Soon</p>
  );
}

export default Index;
