import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  AddParent,
  AddSchoolLeader,
  AddStudent,
  AddTeacher,
} from 'components/createUser';
import { Card } from '@mui/material';

function CreateUser() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [createType, setCreateType] = useState(queryParams.get('mode'));

  useEffect(() => {
    setCreateType(queryParams.get('mode'));
  }, [location.search]);

  let componentToRender;

  switch (createType) {
    case 'teacher':
      componentToRender = <AddTeacher />;
      break;
    case 'student':
      componentToRender = <AddStudent />;
      break;
    case 'parent':
      componentToRender = <AddParent />;
      break;
    case 'leader':
      componentToRender = <AddSchoolLeader />;
      break;
    default:
      componentToRender = <div>Invalid user type</div>;
  }

  return <Card sx={{ p: 2, height: '100%' }}>{componentToRender}</Card>;
}

export default CreateUser;
