import React from 'react';
import { ReactComponent as NoData } from '../assets/nodata.svg';
import { ReactComponent as NoData2 } from '../assets/nodata2.svg';

export default function Nodata({
  type,
  message,
  description,
}: {
  type: number;
  message?: string;
  description?: string;
}) {
  return (
    <div className="w-full justify-center flex-col items-center flex gap-4">
      {type === 1 ? <NoData /> : <NoData2 />}
      <div className="font-semibold text-2xl">
        {message || 'No Data Available!'}
      </div>
      <div className="max-w-[40%] text-center text-gray-500 text-base font-normal">
        {description ||
          "Oops! It looks like there's nothing here yet. Start exploring and adding content to see results populate."}
      </div>
    </div>
  );
}
