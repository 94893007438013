import { createAsyncThunk } from '@reduxjs/toolkit';
import API from 'store/api';
import { startLoading, stopLoading } from '../Slice/loadingSlice';
import {
  setDashboardInfo,
  setDashboardTeachers,
  setDashboardLeaderTeacherProgress,
  setDashboardLeaderSkillsMastered,
  setSuperAdminCounts,
  setSuperAdminVideos,
  setSuperAdminAssignemtns,
} from '../Slice/dashboardSlice';
import { setTeacherDashboardInfo } from 'store/Slice/teacherDashboardSlice';
import { setSchoolLeaderDashboardInfo } from 'store/Slice/schoolLeaderDashboardSlice';
import { IClasses, ITeacher } from '../../components/Dashboard/table';
import classroomSlice from '../Slice/classroomSlice';

interface teacherType {
  id: string;
  name: string;
  email: string;
  profileImage: string;
}
interface teacherObj {
  user: teacherType;
}
interface classRoomType {
  id: string;
  name: string;
  ClassroomTeacher: teacherObj[];
  UserClassroom: teacherObj[];
}

interface clsObj {
  clasroom: classRoomType;
}

const getTeachersArray = (classArray: clsObj[]) => {
  console.log('given Api class Obj==============', classArray);

  try {
    const teachersMap: Record<string, ITeacher> = {};
    classArray.forEach((classRoom) => {
      const classroomInfo = classRoom.clasroom;
      classroomInfo.ClassroomTeacher.forEach((teacher) => {
        const teacherId: string = teacher.user.id;

        //if the teacher dont exists it will add a new teacehr
        if (!teachersMap[teacherId]) {
          teachersMap[teacherId] = {
            id: teacher.user.id,
            name: teacher.user.name,
            avatar: teacher.user.profileImage,
            classes: [],
          };
        }

        const classInfo: IClasses = {
          name: classroomInfo.name,
          id: classroomInfo.id,
          students: [],
        };
        classroomInfo.UserClassroom.forEach((student) => {
          if (!classInfo.students) classInfo.students = [];
          classInfo?.students.push({
            id: student.user.id,
            name: student.user.name,
          });
        });

        // Add the class to the teacher's classes
        teachersMap[teacherId].classes.push(classInfo);
      });
    });

    return Object.values(teachersMap);
  } catch (e: any) {
    console.log('parsing error happned==>>>>>>', e.message);
    return [];
  }
};

function transformData(inputArray: any) {
  return inputArray.map((item: any) => {
    // Rename profileImage to avatar
    item.avatar = item.profileImage;
    delete item.profileImage;

    // Rename teacherClassrooms to classes
    item.classes = item.teacherClassrooms;
    delete item.teacherClassrooms;

    // Rename userClassroom to students
    if (item.classes) {
      item.classes.forEach((classroom: any) => {
        if (classroom.UserClassroom) {
          classroom.students = classroom.UserClassroom.map((user: any) => {
            user.target = user.onTarget;
            delete user.onTarget;
            user.email = user?.user?.email;
            user.name = user?.user?.name;
            user.assesment = user?.user?.Assignments;
            user.completed = user?.user?.totalDone;
            delete user.user?.totalDone;
            delete user.user?.name;
            delete user.user?.Assignments;
            delete user?.user?.email;
            user.pending = user.user?.totalPending;
            delete user.user?.totalPending;
            user.progress = user.user?.progress;
            delete user.user?.progress;
            return user;
          });

          delete classroom.UserClassroom;
        }
      });
    }

    return item;
  });
}

export const getSchoolLeaderDashboardTeachers = createAsyncThunk(
  'getDashboardInfo',
  async ({ id }: any, { dispatch }) => {
    let url = `/api/v1/dashboard/school_leader/progress/${id}`;
    dispatch(startLoading());
    try {
      const response: any = await API.get(url);
      console.log('teacher info : ', response.data.data);
      const teachersArr = transformData(response.data.data);
      console.log('parsed teachers ===', teachersArr);
      dispatch(setDashboardTeachers(response?.data?.data));
      dispatch(stopLoading());
    } catch (err: any) {
      console.log('the error apeared!');
      dispatch(setDashboardInfo({}));
      dispatch(stopLoading());
      return err;
    }
  },
);
export const getSchoolLeaderDashboardProgress = createAsyncThunk(
  'getDashboardInfo',
  async ({ id }: any, { dispatch }) => {
    let url = `/api/v1/dashboard/school_leader/progress/${id}`;
    dispatch(startLoading());
    try {
      const response: any = await API.get(url);
      dispatch(setDashboardLeaderTeacherProgress(response?.data?.data));
      dispatch(stopLoading());
    } catch (err: any) {
      console.log('the error apeared!');
      dispatch(setDashboardInfo({}));
      dispatch(stopLoading());
      return err;
    }
  },
);

export const getTeacherDashboardSkillsMastered = createAsyncThunk(
  'getDashboardInfo',
  async ({ id }: any, { dispatch }) => {
    let url = `/api/v1/dashboard/teacher/graph/skills_mastered/${id}`;
    dispatch(startLoading());
    try {
      const response: any = await API.get(url);
      console.log('getSchoolLeaderDashboardSkillsMastered : ', response.data);
      dispatch(setDashboardLeaderSkillsMastered(response?.data?.data));
      dispatch(stopLoading());
    } catch (err: any) {
      console.log('the error apeared!');
      dispatch(setDashboardInfo({}));
      dispatch(stopLoading());
      return err;
    }
  },
);
export const getSuperadminDashboardSkillsMastered = createAsyncThunk(
  'getDashboardInfo',
  async (_request: any, { dispatch }) => {
    dispatch(startLoading());
    const date = _request.date || new Date();
    let url = `/api/v1/dashboard/super_admin/skills_mastered?fromDate=${date}`;
    try {
      const response: any = await API.get(url);
      dispatch(setDashboardLeaderSkillsMastered(response?.data?.data));
      dispatch(stopLoading());
    } catch (err: any) {
      console.log('the error apeared!');
      dispatch(setDashboardInfo({}));
      dispatch(stopLoading());
      return err;
    }
  },
);
export const getSchoolLeaderDashboardSkillsMastered = createAsyncThunk(
  'getDashboardInfo',
  async ({ id, date }: any, { dispatch }) => {
    let url = `/api/v1/dashboard/school_leader/skills_mastered/${id}/${date}`;
    dispatch(startLoading());
    try {
      const response: any = await API.get(url);
      console.log('getSchoolLeaderDashboardSkillsMastered : ', response.data);
      dispatch(setDashboardLeaderSkillsMastered(response?.data?.data));
      dispatch(stopLoading());
    } catch (err: any) {
      console.log('the error apeared!');
      dispatch(setDashboardInfo({}));
      dispatch(stopLoading());
      return err;
    }
  },
);
export const getDashboardInfo = createAsyncThunk(
  'getDashboardInfo',
  async ({ id }: any, { dispatch }) => {
    let url = `/api/v1/dashboard/school_leader/totals/${id}`;
    dispatch(startLoading());
    try {
      const response: any = await API.get(url);
      console.log('dasboard info : ', response.data.data);

      dispatch(setDashboardInfo(response.data.data));
      const teachersArr = getTeachersArray(
        response?.data?.data?.schoolTeacherClassroomStudentInfo?.classroom,
      );
      console.log('parsed teachers ===', teachersArr);
      dispatch(setDashboardTeachers(teachersArr as any));
      dispatch(stopLoading());
    } catch (err: any) {
      console.log('the error apeared!');
      dispatch(setDashboardInfo({}));
      dispatch(stopLoading());
      return err;
    }
  },
);
export const getTeacherDashboardInfo = createAsyncThunk(
  'getDashboardInfo',
  async ({ id }: any, { dispatch }) => {
    let url = `/api/v1/dashboard/teacher/totals/${id}`;
    dispatch(startLoading());
    try {
      const response: any = await API.get(url);
      console.log('dasboard teacher info : ', response.data.data);

      dispatch(setTeacherDashboardInfo({ totalCounts: response.data.data }));
      dispatch(stopLoading());
    } catch (err: any) {
      dispatch(setTeacherDashboardInfo({}));
      dispatch(stopLoading());
      return err;
    }
  },
);
export const getTeacherSkillMastered = createAsyncThunk(
  'getDashboardInfo',
  async ({ id }: any, { dispatch }) => {
    let url = `/api/v1/dashboard/teacher/graph/skills_mastered/${id}`;
    dispatch(startLoading());
    try {
      const response: any = await API.get(url);
      console.log('dasboard teacher info new: ', response.data.data);

      dispatch(
        setTeacherDashboardInfo({ teacherStudents: response.data.data }),
      );
      dispatch(stopLoading());
    } catch (err: any) {
      dispatch(setTeacherDashboardInfo({}));
      dispatch(stopLoading());
      return err;
    }
  },
);
export const getSuperAdminCounts = createAsyncThunk(
  'getSuperAdminCounts',
  async ({ id }: any, { dispatch }) => {
    let url = `/api/v1/dashboard/super_admin/totals`;
    dispatch(startLoading());
    try {
      const response: any = await API.get(url);
      console.log('dasboard teacher info new: ', response.data.data);

      dispatch(setSuperAdminCounts(response.data.data));
      dispatch(stopLoading());
    } catch (err: any) {
      dispatch(setSuperAdminCounts({}));
      dispatch(stopLoading());
      return err;
    }
  },
);
function transformAssignement(arrayFromApi: any[]): any[] {
  return arrayFromApi.map((item) => {
    const expectedItem: any = {
      date: item.createdAt,
      student: {
        name: item.student.name,
        id: item.student.id,
        imageUrl: item.student.profileImage,
      },
      topic: item.template,
      action: ['View'],
      score: item.score,
      skill: item.template,
      answerCorrect: item.answerCorrect
        ? item.answerCorrect
        : item.answerCorrect1st,
      school_assignments: item.school_assignments
        ? item.school_assignments
        : // : item.template.questions,
          {
            school_assignment_session: [
              {
                currentTemplateQuestions: item.template.questions.map(
                  (item: any) => {
                    const { question, ...rest } = item;
                    return { ...question, ...rest };
                  },
                ),
              },
            ],
            classroom: item.assignment.classroom,
            assignedBy: item.assignment.assignedBy,
          },
    };
    return expectedItem;
  });
}
function transformArray(fromApi: any[]): any[] {
  return fromApi.map((item) => {
    return {
      id: item.id,
      assetId: item.assetId,
      playbackId: item.playbackId,
      userId: item.userId,
      status: item.status,
      approval: item.approval,
      video: {
        title: item.title,
        length: item.length,
        playbackId: item.playbackId,
      },
      user: {
        name: item.user.name,
        lastname: item.user.lastname,
        id: item.user.id,
        imageUrl: item.user.profileImage,
      },
      school: item.school,
      videoLength: { playbackId: item.playbackId, assetId: item.assetId },
      action: ['View', 'Approve', 'Reject'],
      date: item.createdAt,
    };
  });
}
export const getSuperAdminAssignments = createAsyncThunk(
  'getSuperAdminAssignments',
  async (_request: any, { dispatch }) => {
    dispatch(startLoading());
    const page = _request.page || 1;
    const size = _request.limit || 10;
    const type = _request.type || 'Bellwork';
    let url = `/api/v1/assignment/super-admin/list?type=${type}&limit=${size}&page=${page}`;
    try {
      const response: any = await API.get(url);
      let transformData = transformAssignement(response.data.data.items);

      dispatch(
        setSuperAdminAssignemtns({
          items: transformAssignement,
          meta: response.data.data.meta,
        }),
      );
      dispatch(stopLoading());
      return { items: transformData, meta: response.data.data.meta };
    } catch (err: any) {
      dispatch(setSuperAdminCounts({}));
      dispatch(stopLoading());
      return err;
    }
  },
);
export const getSuperAdminVideos = createAsyncThunk(
  'getSuperAdminVideos',
  async (_request: any, { dispatch }) => {
    dispatch(startLoading());
    const page = _request.page || 1;
    const size = _request.limit || 10;
    let url = `/api/v1/videos/super-admin/list?limit=${size}&page=${page}`;
    try {
      const response: any = await API.get(url);
      console.log('dasboard teacher info new: ', response.data.data);
      let transformData = transformArray(response.data.data.items);
      dispatch(
        setSuperAdminVideos({
          items: transformData,
          meta: response.data.data.meta,
        }),
      );
      dispatch(stopLoading());
      return transformData;
    } catch (err: any) {
      dispatch(setSuperAdminCounts({}));
      dispatch(stopLoading());
      return err;
    }
  },
);
export const getTeacherClassroomProgress = createAsyncThunk(
  'getDashboardInfo',
  async ({ id }: any, { dispatch }) => {
    let url = `/api/v1/dashboard/teacher/progress/${id}`;
    dispatch(startLoading());
    try {
      const response: any = await API.get(url);
      console.log('dasboard teacher info new: ', response.data.data);

      dispatch(
        setTeacherDashboardInfo({
          teacherClassrooms: response.data.data,
        }),
      );
      dispatch(stopLoading());
    } catch (err: any) {
      dispatch(setTeacherDashboardInfo({}));
      dispatch(stopLoading());
      return err;
    }
  },
);

export const getTotalWatchHour = createAsyncThunk(
  'getDashboardInfo',
  async ({ userId, startDate }: any, { dispatch }) => {
    console.log(userId, 'the haha');
    let url = `api/v1/dashboard/school_leader/total_watch_hours/${userId}/${startDate}`;
    dispatch(startLoading());
    try {
      const response: any = await API.get(url);
      dispatch(
        setSchoolLeaderDashboardInfo({ totalWatchHours: response.data.data }),
      );
      dispatch(stopLoading());
    } catch (err: any) {
      dispatch(setSchoolLeaderDashboardInfo({}));
      dispatch(stopLoading());
      return err;
    }
  },
);

export const getSkillsMastered = createAsyncThunk(
  'getDashboardInfo',
  async ({ userId, startDate }: any, { dispatch }) => {
    let url = `api/v1/dashboard/school_leader/skills_mastered/${userId}/${startDate}`;
    dispatch(startLoading());
    try {
      const response: any = await API.get(url);

      dispatch(
        setSchoolLeaderDashboardInfo({ skillMastered: response.data.data }),
      );
      dispatch(stopLoading());
    } catch (err: any) {
      dispatch(setSchoolLeaderDashboardInfo({}));
      dispatch(stopLoading());
      return err;
    }
  },
);
