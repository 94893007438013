import React, { useEffect } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { colors, colors2 } from '../helpers/helpers';
import '../styles/style.css';

const groupByIndex = (inputArray: any) => {
  const numRows = inputArray.length;
  const numCols = Math.max(...inputArray.map((row: any) => row.length));

  const resultArray = Array.from({ length: numCols }, () => []);

  for (let col = 0; col < numCols; col++) {
    for (let row = 0; row < numRows; row++) {
      if (inputArray[row][col] !== undefined) {
        resultArray[col].push(inputArray[row][col] as never);
      }
    }
  }

  return resultArray;
};

const GroupedBarChart = ({
  data,
  selectTab,
}: {
  data: any[];
  selectTab: string;
}) => {
  useEffect(() => {
    // We need a delay to make sure the svg elements are rendered before we can access them
    setTimeout(() => {
      const svgElement = document.querySelectorAll('.MuiBarElement-root');

      // We need to set rx and ry to 3% to make the bars rounded
      if (svgElement) {
        svgElement.forEach((item) => {
          item.setAttribute('rx', '3%');
          item.setAttribute('ry', '3%');
        });
      }
    }, 10);
  }, [data, selectTab]);

  const xAxisTabs = data
    .map((item) => {
      if (item.tableTitle === selectTab) {
        return item.tableData.map((entry: any) => entry.date);
      }
      return;
    })
    .filter((item) => item);

  const yAxisTabs = data
    .map((item) => {
      if (item.tableTitle === selectTab) {
        return item.tableData.map((entry: any) =>
          entry.data.map((item: any) => item.value),
        );
      }
      return;
    })
    .filter((item) => item)[0];

  const seriesData = groupByIndex(yAxisTabs).map((item: any) => {
    return { data: item };
  });

  return (
    <BarChart
      series={[...seriesData]}
      height={320}
      xAxis={[
        {
          data: xAxisTabs[0],
          scaleType: 'band',
          tickSize: 0,
          disableTicks: true,
        },
      ]}
      yAxis={[
        {
          data: yAxisTabs[0],
          scaleType: 'linear',
          tickSize: 0,
          disableTicks: true,
          min: 0,
          // max: 100,
        },
      ]}
      margin={{ top: 15, bottom: 30, left: 40, right: 10 }}
      colors={selectTab === 'Skills mastered' ? colors : colors2}
      slots={{
        axisTick: () => null,
        axisLine: () => null,
      }}
    />
  );
};

export default GroupedBarChart;
