import React from "react";
import { img, img1, img2, img4 } from "assets";
import { Box, Button, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { Search } from "@mui/icons-material";
import { LuSettings2 } from "react-icons/lu";

const tempImg = [img, img1, img2, img4, img, img1, img2, img4]

const index = () => {
    return (
      <Box my={4} mx={6}>
        <Stack direction={"row"} spacing={2} mt={2}>
          <TextField
            placeholder="Search"
            size="small"
            sx={{ width: {xs: "100%", sm: "40%"}}}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          
          <Button variant="contained" sx={{display: {xs: "none", sm: "flex"}}} className="filter_button" endIcon={<LuSettings2 />}>
            Filter
          </Button>
          <IconButton sx={{display: {xs: "flex", sm: "none"}, backgroundColor: "#E0FAF4", borderRadius: "6px"}}>
            <LuSettings2 style={{color: "#20A58A"}}/>
          </IconButton>

        </Stack>
        
        <Grid container my={4} spacing={4}>
          {
            [...new Array(8)].map((_, index) => (    
              <Grid item md={3} sm={4} xs={12}>
                <Box>
                  <img src={tempImg[index]} alt="" />
                  <Typography variant="subtitle1">Lesson {index + 1}</Typography>
                </Box>
              </Grid>
            ))
          }
        </Grid>
      </Box>
    );
  };
  
  export default index;

// import React from "react";
// import { img, img1, img2, img4 } from "../../../../../assets";
// import { AiOutlineSearch } from "react-icons/ai";
// import { BsUiRadios } from "react-icons/bs";
// import { InputAdornment, TextField } from "@mui/material";
// import { Search } from "@mui/icons-material";
// const index = () => {
//   return (
//     <div className="student-page">
//       <div className="search_input_student">
//       <TextField
//             placeholder="Search"
//             size="small"
//             InputProps={{
//               startAdornment: (
//                 <InputAdornment position="start">
//                   <Search />
//                 </InputAdornment>
//               ),
//             }}
//           />
//         <button className="filter">
//           Filter <BsUiRadios style={{ marginLeft: "10px", fontSize: "10px" }} />
//         </button>
//       </div>

//       <div className="main_student">
//         <div className="main_video">
//           <div className="img_student_video">
//             <img src={img4} alt="" />
//           </div>

//           <p>Lesson 02.mp4</p>
//         </div>
//         <div className="main_video">
//           <div className="img_student_video">
//             <img src={img} alt="" />
//           </div>

//           <p>Lesson 02.mp4</p>
//         </div>
//         <div className="main_video">
//           <div className="img_student_video">
//             <img src={img1} alt="" />
//           </div>

//           <p>Lesson 02.mp4</p>
//         </div>
//         <div className="main_video">
//           <div className="img_student_video">
//             <img src={img2} alt="" />
//           </div>

//           <p>Lesson 02.mp4</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default index;
