import { Typography } from '@mui/material';

// Declaring props types for DefaultCell
interface Props {
  value: string;
  minWidth?: string;
  sx?: React.CSSProperties;
}

function DefaultCell({ value, minWidth, sx }: Props): JSX.Element {
  return (
    <Typography
      variant="inherit"
      fontWeight={500}
      sx={{
        ...sx,
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-words',
        width: '100%',
        color: '#84818a',
        minWidth: minWidth ? minWidth : '150px',
      }}
    >
      {value || '-'}
    </Typography>
  );
}

export default DefaultCell;
