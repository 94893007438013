import React from "react";

export default function EditWithBlackIcon() {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="28" height="28" rx="14" fill="#F6E3F0" />
      <path
        d="M18.3675 7.75757L20.2425 9.63257L18.8131 11.0626L16.9381 9.18757L18.3675 7.75757ZM11.5 16.5001H13.375L17.9294 11.9457L16.0544 10.0707L11.5 14.6251V16.5001Z"
        fill="black"
      />
      <path
        d="M18.375 18.375H11.5987C11.5825 18.375 11.5656 18.3812 11.5494 18.3812C11.5287 18.3812 11.5081 18.3756 11.4869 18.375H9.625V9.625H13.9044L15.1544 8.375H9.625C8.93562 8.375 8.375 8.935 8.375 9.625V18.375C8.375 19.065 8.93562 19.625 9.625 19.625H18.375C18.7065 19.625 19.0245 19.4933 19.2589 19.2589C19.4933 19.0245 19.625 18.7065 19.625 18.375V12.9575L18.375 14.2075V18.375Z"
        fill="black"
      />
    </svg>
  );
}
