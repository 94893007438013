import React, { useEffect, useState } from 'react';
import { Roles, USA_STATES } from '@core/utils/global-mock';
import {
  Grid,
  TextField,
  InputLabel,
  Typography,
  Button,
  Stack,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
} from '@mui/material';
import DynamicAutocomplete from 'components/AutoComplete/DynamicAutocomplete';
import { useAppDispatch, useAppSelector } from 'store/store';
import ClearIcon from '@mui/icons-material/Clear';
import Validations from 'helper/validations';
import { getClassroomBySchoolID } from 'store/Thunk/classroomThunk';
import { LoadingButton } from '@mui/lab';
import { getGradeList, getTopicList } from 'store/Thunk/videoThunk';
import { getSchoolsInState, getStates } from 'store/Thunk/districtThunk';
import { createUserAtFirebaseAndSetAtBackend } from 'store/Thunk/authThunk';
import { getUserData } from 'store/Thunk/user';
import { setUser } from 'store/Slice/userSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateTeacher } from 'store/Thunk/teacherThunk';
import { notificationSuccess } from 'store/Slice/notificationSlice';
import ViewLoaderBox from 'components/viewUser/loader';
import { setBtnLoading } from 'store/Slice/loadingSlice';
import { getLinkPartFromRole, getLoggedInUser } from 'helper/functions';
import { getAllSchoolListIds } from 'store/Thunk/schoolThunk';
import ImageViewPopup from 'components/popUp/imageViewPopup';

function Teacher() {
  const [formError, setFormError] = useState<any>({});
  const [schoolOpen, setSchoolOpen] = useState<boolean>(false);

  const { classroomList } = useAppSelector((state) => state.classroomReducer);
  const [classroomOpen, setClassRoomOpen] = useState<boolean>(false);
  const { btnLoading } = useAppSelector((state: any) => state.loadingReducer);
  const { gradeList } = useAppSelector((state: any) => state.videoReducer);
  const [statesList, setStatesList] = useState([]);
  const [loadingSchool, setLoadingSchool] = useState<boolean>(false);
  const [schoolList, setSchoolList] = useState<any>([]);
  const [imageFile, setImageFile] = useState<any>(null);
  const [openTopic, setOpenTopic] = useState(false);
  const [loadingTopic, setLoadingTopic] = useState(false);
  const { user, loading } = useAppSelector((state) => state.userReducer);
  const [imagePreview, setImagePreview] = useState<any>(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [teacherId] = useState(queryParams.get('id'));
  const navigate = useNavigate();
  const initial = getLinkPartFromRole();
  const [imageView, setImageView] = useState(false);

  const [topic, setTopic] = useState([]);
  const { topicList } = useAppSelector((state: any) => state.videoReducer);
  const { schoolList: allSchoolList, loading: schoolLoading } = useAppSelector(
    (state) => state.schoolReducer,
  );
  const { stateList } = useAppSelector((state: any) => state.districtReducer);

  const currentLoggedInUserSchool =
    localStorage.getItem('current_schoolId') || '';
  const loggedInUser = getLoggedInUser();

  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<any>({
    school: '',
    teachMethod: 'teacher',
  });

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (teacherId) {
      dispatch(getUserData(teacherId));
    }
    return () => {
      dispatch(setUser([]));
      dispatch(setBtnLoading(false));
    };
  }, []);

  useEffect(() => {
    dispatch(getGradeList());
  }, []);

  useEffect(() => {
    if (openTopic) {
      dispatch(getTopicList());
      setLoadingTopic(openTopic);
    } else {
      setTopic([]);
    }
  }, [openTopic]);

  useEffect(() => {
    setLoadingTopic(false);
    setTopic(topicList);
  }, [topicList]);

  useEffect(() => {
    dispatch(
      getClassroomBySchoolID({
        id: formData?.school?.id,
      }),
    );
  }, [formData?.school]);
  console.log('stateList', user);
  console.log('stateList', formData);
  console.log('stateList', stateList);
  console.log('stateList', statesList);
  useEffect(() => {
    if (user) {
      const school = user?.school?.length > 0 ? user?.school[0]?.school : {};
      setFormData({
        salutation: user?.salutation || '',
        name: user?.name,
        role: { label: Roles.teacher },
        zipCode: user?.zipCode,
        email: user?.email || '',
        school: user?.school,
        teachMethod: user?.type,
        phone: user?.phone || '',
        type: user?.type,
        grades:
          user?.grade?.map((item: any) => {
            return {
              id: item?.grade?.id,
              name: item?.grade?.name,
            };
          }) || [],
        topics:
          user?.topic?.map((item: any) => {
            return {
              id: item?.topic?.id,
              name: item?.topic?.name,
            };
          }) || [],
        classroom:
          user?.classrooms?.map((item: any) => {
            return {
              classroom: {
                id: item?.classroom?.id,
                name: item?.classroom?.name,
              },
            };
          }) || [],
        state: { id: user?.school?.state, name: user?.school?.state },
      });
      if (user?.profileImage) {
        setImagePreview(user?.profileImage);
      }
    }
  }, [user, statesList]);

  useEffect(() => {
    if (schoolOpen && formData?.state && loggedInUser?.type === 'super_admin') {
      setLoadingSchool(true);
      dispatch(getSchoolsInState(formData?.state?.name))
        .unwrap()
        .then((res) => {
          if (res?.length > 0) {
            setSchoolList(
              res?.map((item: any) => {
                return {
                  id: item.id,
                  name: item?.name,
                };
              }),
            );
          }
        })
        .finally(() => {
          setLoadingSchool(false);
        });
    } else if (schoolOpen && loggedInUser?.type === 'school_leader') {
      dispatch(getAllSchoolListIds({}));
    }
  }, [schoolOpen]);

  const handleImageChange = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      setImageFile(file);
      reader.onload = () => {
        setImagePreview(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleChangeAutocomplete =
    (name: string) => (value: string | null | any) => {
      let param = {
        [name]: value,
        ...(name === 'school' && {
          classroom: [],
        }),
      };

      setFormData({ ...formData, ...param });
    };

  const onSubmit = (event: any) => {
    event.preventDefault();

    let _request = {
      salutation: formData?.salutation || '',
      name: formData?.name || '',
      role: Roles.teacher,
      email: formData?.email || '',
      school: formData?.school?.id || '',
      classrooms:
        formData?.classroom?.map((item: any) => item?.classroom?.id) || [],
      state: formData?.state?.value || formData?.state?.id || '',
      gradeIds: formData?.grades?.map((item: any) => item.id),
      topicIds: formData?.topics?.map((item: any) => item.id),
      zipCode: formData?.zipCode || '',
      teachMethod: formData?.teachMethod || 'teacher',
      file: imageFile,
      phone: formData?.phone,
    };

    let allError = Validations.validateTeacherForm(
      _request,
      loggedInUser?.type,
    );
    setFormError(allError);

    if (
      Object.entries(allError).length === 0 &&
      allError.constructor === Object
    ) {
      if (teacherId) {
        dispatch(setBtnLoading(true));
        dispatch(updateTeacher({ ..._request, uuid: teacherId }))
          .unwrap()
          .then(() => {
            dispatch(notificationSuccess('Teacher Updated Successfully'));
            setTimeout(() => {
              navigate(`/${initial}/user-management?tab=0`);
            }, 100);
          })
          .catch(() => {
            dispatch(setBtnLoading(false));
          });
      } else {
        dispatch(
          createUserAtFirebaseAndSetAtBackend({
            userInfo: { ..._request },
          }),
        );
      }
    } else {
      setFormError(allError);
    }
  };

  useEffect(() => {
    dispatch(getStates({}))
      .unwrap()
      .then((res) => {
        if (res.length > 0) {
          setStatesList(
            res?.map((item: string) => {
              return {
                label: item,
                value: item,
              };
            }),
          );
        }
      });
  }, []);

  if (loading) {
    return <ViewLoaderBox />;
  }

  const handleCancel = () => {
    navigate(`/${initial}/user-management?tab=0`);
  };

  return (
    <>
      <Grid sx={{ p: 4 }} container spacing={2}>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between">
            <Typography color="#000" fontWeight={700} variant="body1">
              User Information
            </Typography>
            <input
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
              hidden
              onChange={handleImageChange}
            />
            {imagePreview ? (
              <span
                style={{
                  background: '#a1f2cb',
                  padding: '10px 15px',
                  borderRadius: '6px',
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'center',
                  width: '168px',
                  justifyContent: 'space-between',
                  color: '#000',
                }}
              >
                <span onClick={() => setImageView(true)}>View Image</span>
                <span
                  onClick={() => {
                    setImagePreview(null);
                    setImageFile(null);
                  }}
                  style={{ marginBottom: '-5px', cursor: 'pointer' }}
                >
                  <ClearIcon />
                </span>
              </span>
            ) : (
              <label htmlFor="contained-button-file">
                <Button
                  sx={{ borderStyle: 'dashed' }}
                  variant="outlined"
                  component="span"
                >
                  Upload Image
                </Button>
              </label>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InputLabel
            error={Boolean(formError?.salutation)}
            sx={{ color: '#484848', pb: 2 }}
          >
            Salutation
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultChecked={formData?.salutation}
            value={formData?.salutation}
            error={formError?.salutation}
            label="Age"
            onChange={(e) =>
              setFormData({
                ...formData,
                salutation: e.target.value,
              })
            }
            sx={{ width: '100%' }}
          >
            <MenuItem value={'Mr'}>Mr</MenuItem>
            <MenuItem value={"Mr's"}>Mr's</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InputLabel
            error={Boolean(formError?.name)}
            sx={{ color: '#484848', pb: 2 }}
          >
            Full Name
          </InputLabel>
          <TextField
            required
            fullWidth
            id="Full name"
            value={formData?.name || ''}
            onChange={handleChangeInput}
            name="name"
            error={formError && formError?.name}
            helperText={formError && formError?.name ? formError.name : ''}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InputLabel
            error={Boolean(formError?.email)}
            sx={{ color: '#484848', pb: 2 }}
          >
            Email
          </InputLabel>
          <TextField
            required
            fullWidth
            id="email"
            type="email"
            value={formData?.email || ''}
            disabled={Boolean(teacherId)}
            onChange={handleChangeInput}
            name="email"
            error={formError && formError?.email}
            helperText={formError && formError?.email ? formError.email : ''}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InputLabel
            error={Boolean(formError?.phone)}
            sx={{ color: '#484848', pb: 2 }}
          >
            Phone
          </InputLabel>
          <TextField
            required
            fullWidth
            id="phone"
            type="phone"
            value={formData?.phone || ''}
            onChange={handleChangeInput}
            name="phone"
            error={formError && formError?.phone}
            helperText={formError && formError?.phone ? formError.phone : ''}
          />
        </Grid>

        {loggedInUser?.type === 'school_leader' && (
          <>
            <Grid item xs={12} sm={6} md={4}>
              <DynamicAutocomplete
                id="parent"
                label="School"
                multiple={false}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option?.name || option?.id || ''}
                options={
                  allSchoolList?.length > 0
                    ? allSchoolList.filter(
                        (item: any) => item.id === currentLoggedInUserSchool,
                      )
                    : []
                }
                onOpen={() => setSchoolOpen(true)}
                onClose={() => setSchoolOpen(false)}
                loading={schoolLoading}
                open={schoolOpen}
                filterSelectedOptions
                value={formData?.school ? formData?.school : { name: '' }}
                onChange={(event, value) =>
                  handleChangeAutocomplete('school')(value)
                }
                placeholder="Select School"
                error={formError && formError?.school}
                helperText={
                  formError && formError?.school ? formError.school : ''
                }
              />
            </Grid>
          </>
        )}

        {loggedInUser?.type === 'super_admin' && (
          <>
            <Grid item xs={12} sm={6} md={4}>
              <DynamicAutocomplete
                id="state"
                label="State"
                options={stateList || []}
                getOptionLabel={(option) => option.name || ''}
                multiple={false}
                value={formData?.state || ''}
                onChange={(event, value) =>
                  handleChangeAutocomplete('state')(value)
                }
                placeholder="Select State"
                error={formError && formError?.state}
                helperText={
                  formError && formError?.state ? formError.state : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <DynamicAutocomplete
                id="parent"
                label="School"
                multiple={false}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option?.name || option?.id || ''}
                options={schoolList?.length > 0 ? schoolList : []}
                onOpen={() => setSchoolOpen(true)}
                onClose={() => setSchoolOpen(false)}
                loading={loadingSchool}
                open={schoolOpen}
                filterSelectedOptions
                value={formData?.school ? formData?.school : { name: '' }}
                onChange={(event, value) =>
                  handleChangeAutocomplete('school')(value)
                }
                placeholder="Select School"
                error={formError && formError?.school}
                helperText={
                  formError && formError?.school ? formError.school : ''
                }
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} sm={6} md={4}>
          <InputLabel
            error={Boolean(formError?.phone)}
            sx={{ color: '#484848', pb: 2 }}
          >
            Zip Code
          </InputLabel>
          <TextField
            required
            fullWidth
            id="zipCode"
            type="zipCode"
            value={formData?.zipCode || ''}
            onChange={handleChangeInput}
            name="zipCode"
            error={formError && formError?.zipCode}
            helperText={
              formError && formError?.zipCode ? formError.zipCode : ''
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <DynamicAutocomplete
            id="role"
            disable
            label="Role"
            getOptionLabel={(option) => option?.label || ''}
            options={[{ label: Roles.teacher }]}
            filterSelectedOptions
            multiple={false}
            value={
              formData?.role?.length > 0
                ? formData?.role[0]
                : formData?.role || { label: Roles.teacher }
            }
            onChange={(event, value) => handleChangeAutocomplete('role')(value)}
            placeholder="Select Role"
            error={formError && formError?.role}
            helperText={formError && formError?.role ? formError.role : ''}
          />
        </Grid>

        {/* <Grid item xs={12}>
          <div>
            <FormLabel component="legend">Teaching Preference</FormLabel>
            <RadioGroup
              sx={{ display: 'flex', flexDirection: 'row' }}
              aria-label="options"
              name="options"
              value={formData?.teachMethod || 'teacher'}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  teachMethod: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="teacher"
                control={<Radio />}
                label="I teacher"
              />
              <FormControlLabel
                value="tutor"
                control={<Radio />}
                label="I tutor"
              />
            </RadioGroup>
          </div>
        </Grid> */}

        {formData?.teachMethod === 'teacher' || !formData?.teachMethod ? (
          <Grid item xs={12} sm={6} md={4}>
            <DynamicAutocomplete
              id="grade"
              label="Grade"
              multiple={true}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              getOptionLabel={(option) => option?.name || ''}
              options={gradeList}
              filterSelectedOptions
              value={formData?.grades || []}
              onChange={(event, value) =>
                handleChangeAutocomplete('grades')(value)
              }
              placeholder="Select Grade"
              error={formError && formError?.grades}
              helperText={
                formError && formError?.grades ? formError.grades : ''
              }
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} sm={6} md={4}>
              <DynamicAutocomplete
                id="topic"
                label="Topic"
                multiple={true}
                open={openTopic}
                onOpen={() => setOpenTopic(true)}
                onClose={() => setOpenTopic(false)}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option?.name || ''}
                options={topic}
                filterSelectedOptions
                value={formData?.topics || []}
                onChange={(event, value) =>
                  handleChangeAutocomplete('topics')(value)
                }
                placeholder="Select Topic"
                error={formError && formError?.topics}
                helperText={
                  formError && formError?.topics ? formError.topics : ''
                }
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <DynamicAutocomplete
            id="classroom"
            label="Classroom"
            multiple
            getOptionLabel={(option) => option?.classroom?.name || ''}
            options={
              classroomList?.items?.length > 0 ? classroomList?.items : []
            }
            onOpen={() => setClassRoomOpen(true)}
            onClose={() => setClassRoomOpen(false)}
            open={classroomOpen}
            filterSelectedOptions
            value={formData?.classroom ? formData?.classroom : []}
            onChange={(event, value) =>
              handleChangeAutocomplete('classroom')(value)
            }
            placeholder="Select Classroom"
            error={formError && formError?.classroom}
            helperText={
              formError && formError?.classroom ? formError.classroom : ''
            }
          />
        </Grid>

        <Grid
          item
          xs={12}
          justifyContent="flex-end"
          sx={{ mt: 5, display: 'flex', gap: '20px' }}
        >
          <Button
            className="gray_bg_btn"
            sx={{ width: '163px', height: '44px' }}
            variant="contained"
            disabled={btnLoading}
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={btnLoading}
            onClick={onSubmit}
            sx={{
              width: '163px',
              height: '44px',
              '& .MuiLoadingButton-loadingIndicator': {
                color: 'white',
              },
            }}
            className="primary_bg_btn"
          >
            {teacherId ? 'Update' : 'Apply'}
          </LoadingButton>
        </Grid>
      </Grid>
      <ImageViewPopup
        imageUrl={imagePreview}
        open={imageView}
        onClose={() => setImageView(false)}
      />
    </>
  );
}

export default Teacher;
