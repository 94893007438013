import { Box, Typography, Button, Stack, Dialog } from '@mui/material';
import { InfoIcon } from 'icons';
import { useSelector } from 'react-redux';
import SimpleLoader from '@core/components/MDLoader/simpleLoader';

import { useEffect, useState } from 'react';
import { deleteStudent } from 'store/Thunk/studentThunk';
import { useAppDispatch } from 'store/store';
import DynamicAutocomplete from 'components/AutoComplete/DynamicAutocomplete';
import { getAllParentIds } from 'store/Thunk/parentThunk';
import select from '@core/theme/overrides/select';

const WarningSvg = () => (
  <svg
    width="71"
    height="70"
    viewBox="0 0 71 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5447_34960)">
      <path
        d="M69.5981 55.5617L40.8853 7.94365C39.7541 6.06773 37.6906 4.90234 35.4999 4.90234C33.3093 4.90234 31.2458 6.06773 30.1144 7.94379L1.40172 55.5617C0.232638 57.5006 0.198049 59.9299 1.31135 61.9013C2.42492 63.8728 4.52301 65.0976 6.78707 65.0976H64.2128C66.4768 65.0976 68.5751 63.8728 69.6886 61.9012C70.8019 59.9296 70.7673 57.5004 69.5981 55.5617ZM65.7202 59.6603C65.5692 59.9271 65.3501 60.1491 65.0853 60.3037C64.8205 60.4583 64.5194 60.5399 64.2128 60.5402H6.78707C6.48047 60.5399 6.17942 60.4584 5.91462 60.3038C5.64982 60.1493 5.43075 59.9272 5.27975 59.6604C5.12921 59.3933 5.05218 59.091 5.05653 58.7844C5.06088 58.4779 5.14644 58.1779 5.30449 57.9152L34.0175 10.2971C34.1722 10.0423 34.3898 9.83146 34.6494 9.68485C34.909 9.53825 35.2019 9.46078 35.5001 9.45986C36.103 9.45986 36.6711 9.78074 36.9825 10.2971L65.6952 57.9152C65.8534 58.1778 65.939 58.4778 65.9434 58.7843C65.9478 59.0909 65.8708 59.3932 65.7202 59.6603Z"
        fill="#F41F52"
      />
      <path
        d="M35.5212 23.6531C33.7876 23.6531 32.4346 24.5833 32.4346 26.2324C32.4346 31.2639 33.0265 38.4942 33.0265 43.5258C33.0266 44.8365 34.1683 45.3862 35.5213 45.3862C36.536 45.3862 37.9736 44.8365 37.9736 43.5258C37.9736 38.4943 38.5655 31.2641 38.5655 26.2324C38.5655 24.5834 37.1703 23.6531 35.5212 23.6531ZM35.5637 48.3036C33.7033 48.3036 32.3079 49.7834 32.3079 51.5594C32.3079 53.293 33.7032 54.8152 35.5637 54.8152C37.2973 54.8152 38.7773 53.293 38.7773 51.5594C38.7773 49.7834 37.2971 48.3036 35.5637 48.3036Z"
        fill="#F41F52"
      />
    </g>
    <defs>
      <clipPath id="clip0_5447_34960">
        <rect width="70" height="70" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

interface DialogProps {
  open: boolean;
  onClose: () => void;
  onOk: () => void;
  description?: string;
  title: string;
  loading: boolean;
  haveChildren: boolean;
  newSelectedParent: any;
  setNewSelectedParent: any;
}

function DeleteParentDialog({
  onClose,
  open,
  onOk,
  loading,
  title,
  description,
  haveChildren,
  newSelectedParent,
  setNewSelectedParent,
}: DialogProps) {
  const dispatch = useAppDispatch();
  const [parentList, setParentList] = useState([]);
  const [openParentList, setOpenParentList] = useState(false);
  const [parentLoading, setParentLoading] = useState(false);

  useEffect(() => {
    if (openParentList) {
      setParentLoading(true);
      dispatch(getAllParentIds())
        .unwrap()
        .then((res) => {
          setParentList(res);
        })
        .catch(() => {})
        .finally(() => {
          setParentLoading(false);
        });
    }
  }, [openParentList]);

  return (
    <Dialog
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '499px',
          },
        },
      }}
      fullWidth={true}
      maxWidth="sm"
      onClose={onClose}
      open={open}
    >
      <Stack sx={{ p: 5, gap: 5, textAlign: 'center', alignItems: 'center' }}>
        <WarningSvg />

        <Box>
          <Typography fontSize={'20px'} fontWeight={'700'}>
            {title}
          </Typography>
          <Typography
            sx={{ fontSize: '14px', fontWeight: 500, lineHeight: '24px' }}
          >
            {description}
          </Typography>
        </Box>

        {haveChildren && (
          <DynamicAutocomplete
            id="parent"
            label="Select Parent"
            multiple={false}
            isOptionEqualToValue={(option, value) =>
              option?.name?.includes(value?.name)
            }
            getOptionLabel={(option) => option?.name || option?.id || ''}
            options={parentList || []}
            onOpen={() => setOpenParentList(true)}
            onClose={() => setOpenParentList(false)}
            loading={parentLoading}
            open={openParentList}
            filterSelectedOptions
            value={newSelectedParent}
            onChange={(event, value) => setNewSelectedParent(value)}
            placeholder="Select Parent You want to replace with!"
            error={false}
            helperText={''}
          />
        )}

        <div
          style={{
            width: '100%',
            display: 'flex',
            gap: '20px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            variant="contained"
            sx={{ width: '160px' }}
            className="gray_bg_btn"
            onClick={onClose}
          >
            No
          </Button>
          <Button
            onClick={onOk}
            sx={{
              width: '160px',
              background: '#F41F52',
              '&:hover': {
                background: '#F41F52',
                opacity: '0.9',
              },
            }}
            variant="contained"
            disabled={loading}
          >
            {loading ? <SimpleLoader size={24} color={'secondary'} /> : 'YES'}
          </Button>
        </div>
      </Stack>
    </Dialog>
  );
}

export default DeleteParentDialog;
