import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

const ContentWrapper = styled('main')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '30px',
  width: '100%',
  [theme.breakpoints.up('xl')]: {
    width: '90%',
    gap: '14px',
  },
  [theme.breakpoints.down('md')]: {
    width: '90%',
    flexDirection: 'column',
    gap: '14px',
  },
}));

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
}));

const AssignmentInfoContainer = styled('main')(() => ({
  display: 'flex',
  gap: '10px',
}));

export { ContentWrapper, Container, AssignmentInfoContainer };
