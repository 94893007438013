interface Item {
  title: string;
}
export const sortArray = (arr: Item[], keyword: string): Item[] => {
  const keywordLowerCase = keyword?.toLowerCase();
  const singleKeyword = keyword?.toLowerCase()?.split(' ')?.length < 2;
  const sortedArray = [...arr];
  return sortedArray?.sort((a, b) => {
    const aContainsKeyword = singleKeyword
      ? a.title.toLowerCase()?.split(' ').includes(keywordLowerCase)
      : a.title.toLowerCase()?.includes(keywordLowerCase);
    const bContainsKeyword = singleKeyword
      ? b.title.toLowerCase()?.split(' ').includes(keywordLowerCase)
      : b.title.toLowerCase()?.includes(keywordLowerCase);

    if (aContainsKeyword && !bContainsKeyword) {
      return -1;
    } else if (!aContainsKeyword && bContainsKeyword) {
      return 1;
    } else {
      return 0;
    }
  });
};
