// ** React Imports
import { ReactNode } from "react";

// ** MUI Imports
import { styled, useTheme } from "@mui/material/styles";
// import useScrollTrigger from '@mui/material/useScrollTrigger'
import MuiAppBar, { AppBarProps } from "@mui/material/AppBar";
import MuiToolbar, { ToolbarProps } from "@mui/material/Toolbar";

// ** Type Import
import { Settings } from "../../../../../@core/context/settingsContext";

// ** Util Import
import { hexToRGBA } from "../../../../../@core/utils/hex-to-rgba";

interface Props {
  admin: boolean;
  hidden: boolean;
  settings: Settings;
  toggleNavVisibility: () => void;
  saveSettings: (values: Settings) => void;
  verticalAppBarContent?: (props?: any) => ReactNode;
}

const AppBar = styled(MuiAppBar)<AppBarProps>(({ theme }) => ({
  transition: "none",
  alignItems: "center",
  justifyContent: "center",
  // padding: theme.spacing(0, 6),
  backgroundColor: "transparent",
  color: theme.palette.text.primary,
  // top: "16px",
  minHeight: theme.mixins.toolbar.minHeight,
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

const Toolbar = styled(MuiToolbar)<ToolbarProps>(({ theme }) => ({
  width: "100%",
  padding: "0 !important",
  // borderRadius: "16px",
  minHeight: `${theme.mixins.toolbar.minHeight}px !important`,
  transition:
    "padding .25s ease-in-out, box-shadow .25s ease-in-out, backdrop-filter .25s ease-in-out",
}));

const LayoutAppBar = (props: Props) => {
  // ** Props
  const {
    admin,
    settings,
    verticalAppBarContent: userVerticalAppBarContent,
  } = props;

  // ** Hooks
  const theme = useTheme();
  // const scrollTrigger = useScrollTrigger({ threshold: 0, disableHysteresis: true })

  // ** Vars
  const { skin, appBar, appBarBlur } = settings;

  const appBarFixedStyles = () => {
    return {
      px: `${theme.spacing(6)} !important`,
      ...(appBarBlur && { backdropFilter: "blur(8px)" }),
      boxShadow: "11px 9px 14px rgba(32, 165, 138, 0.08)",
      backgroundColor: hexToRGBA(
        theme.palette.background.paper,
        appBarBlur ? 0.9 : 1
      ),
      ...(skin === "bordered" && {
        border: `1px solid ${theme.palette.divider}`,
        borderTopWidth: 0,
      }),
    };
  };

  if (appBar === "hidden") {
    return null;
  }

  return (
    <AppBar
      elevation={0}
      color="default"
      className="layout-navbar"
      position={appBar === "fixed" ? "sticky" : "static"}
      sx={{ ...(!admin && { top: "16px", padding: "0rem 1.5rem" }) }}
    >
      <Toolbar
        className="navbar-content-container"
        sx={{
          ...appBarFixedStyles(),
          // ...(contentWidth === "boxed" && {
          //   "@media (min-width:1440px)": {
          //     maxWidth: `calc(1440px - ${theme.spacing(6)} * 2)`,
          //   },
          // }),
          ...(!admin && {
            borderRadius: "16px",
          }),
        }}
      >
        {(userVerticalAppBarContent && userVerticalAppBarContent(props)) ||
          null}
      </Toolbar>
    </AppBar>
  );
};

export default LayoutAppBar;
