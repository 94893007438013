import { Card } from '@mui/material';
import { ViewIcon } from 'icons';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import React, { useRef } from 'react';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import './styles.scss';

const CrudPopUp = ({
  setClose,
  onEditClick,
  onDeleteClick,
  onViewClick,
  showEdit,
  showDelete,
  showView,
  deleteText,
  sx,
}: any) => {
  const dropDownRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(dropDownRef, () => setClose());
  return (
    <Card
      sx={{ display: 'flex', flexDirection: 'column', ...sx }}
      ref={dropDownRef}
      className="action_popup"
    >
      {showEdit && (
        <span
          className="action_item"
          onClick={() => {
            onEditClick();
            setClose();
          }}
        >
          <EditIcon style={{ color: '#84818A' }} />
          <span>Edit</span>
        </span>
      )}

      {showDelete && (
        <span
          className="action_item"
          onClick={() => {
            onDeleteClick();
            setClose();
          }}
        >
          <DeleteOutlineIcon style={{ color: '#F41F52' }} />
          <span>{deleteText ? deleteText : 'Delete'}</span>
        </span>
      )}

      {showView && (
        <span
          className="action_item"
          onClick={() => {
            onViewClick();
            setClose();
          }}
        >
          <ViewIcon />
          <span>View</span>
        </span>
      )}
    </Card>
  );
};

export default CrudPopUp;
