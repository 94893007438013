import { Chip, Checkbox, CircularProgress, Box } from '@mui/material';
import { Stack, minWidth } from '@mui/system';
import DefaultCell from 'components/DefaultCell';
import ReadMore from 'components/ReadMore/ReadMore';
import CustomTable from 'components/customTable';
import StudentProgressTable from 'components/student/progress/table';
import { set } from 'date-fns';
import { typesColors } from 'helper/constants';
import {
  itemExistsWithType,
  removeItemByID,
  updateOrAddType,
} from 'helper/functions';
import { displayMultipleValue, getLocalDateTime } from 'helper/service';
import { _assignmentTypes } from 'helper/types';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { startLoading } from 'store/Slice/loadingSlice';
import { checkExistence } from 'store/Thunk/assignContentThunk';
import { getQuarterList, getVideoList } from 'store/Thunk/videoThunk';
import { useAppDispatch, useAppSelector } from 'store/store';
import Instructional from './indtractional';
import TableHeader from 'components/tableHeader';
import Nodata from 'components/v2_components/Admin_Panel_Dashboards/skills_chart/components/noData';

interface propsType {
  setSelectedContent: (value: any) => void;
  selectedContent: any;
  data: any;
  setData: any;
  selectiveInfo: any;
  setInfoModal: any;
  setIsOpenQuestionLimit?: any;
  handleCloseLimit?: any;
  search?: any;
  setSearch?: any;
  fetchData?: any;
}

const InstructionalCalander = ({
  setSelectedContent,
  selectedContent,
  selectiveInfo,
  search,
  setSearch,
  fetchData,
  setData,
  setIsOpenQuestionLimit,
  handleCloseLimit,
  data,
  setInfoModal,
}: propsType) => {
  const urlParams = new URLSearchParams(window.location.search);
  const assignmentType = urlParams.get('assignmentType');
  const assignmentFrom = urlParams.get('type');
  const header = [{ label: '01 Quarter', width: 1, value: 'name' }];
  const dispatch = useAppDispatch();

  const color = '#E9F6F3';
  return (
    <Stack>
      {data.length ? (
        <TableHeader
          item={{
            action: true,
            search: search,
            setSearch: setSearch,
            fetchData: fetchData,
            onCloseFilter: console.log(true),
          }}
        />
      ) : (
        <></>
      )}
      {data.length ? (
        <Instructional
          color={color}
          setData={setData}
          data={data}
          classes={data}
          header={header}
          setIsOpenQuestionLimit={setIsOpenQuestionLimit}
          handleCloseLimit={handleCloseLimit}
        />
      ) : (
        <Box className="mt-8">
          <Nodata
            type={2}
            message={'Instructional Calendar Not Configured'}
            description="Oops! It looks like there's nothing here yet. Please email help@getg3ms.com to import your instructional calendar for the year."
          />
        </Box>
      )}
    </Stack>
  );
};

export default InstructionalCalander;
