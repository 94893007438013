import React from "react";

export default function ShareIcon() {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.96792 6.7864L7.03213 3.72219M2.8185 4.87107L2.05259 5.63698C1.85139 5.83818 1.69179 6.07704 1.5829 6.33992C1.47401 6.6028 1.41797 6.88455 1.41797 7.16909C1.41797 7.45363 1.47401 7.73538 1.5829 7.99826C1.69179 8.26114 1.85139 8.49999 2.05259 8.70119C2.25379 8.90239 2.49264 9.06199 2.75552 9.17088C3.0184 9.27977 3.30015 9.33581 3.58469 9.33581C3.86923 9.33581 4.15098 9.27977 4.41386 9.17088C4.67674 9.06199 4.9156 8.90239 5.1168 8.70119L5.88163 7.93528M5.11625 2.57278L5.88217 1.80686C6.08337 1.60566 6.32223 1.44606 6.58511 1.33717C6.84798 1.22829 7.12974 1.17224 7.41427 1.17224C7.69881 1.17224 7.98056 1.22829 8.24344 1.33717C8.50632 1.44606 8.74518 1.60566 8.94638 1.80686C9.14758 2.00806 9.30718 2.24692 9.41606 2.50979C9.52495 2.77267 9.581 3.05443 9.581 3.33896C9.581 3.6235 9.52495 3.90525 9.41606 4.16813C9.30718 4.43101 9.14758 4.66987 8.94638 4.87107L8.18046 5.63698"
        stroke="#84818A"
        stroke-width="1.08333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
