import React, { useCallback, useEffect, useRef, useState } from 'react';
import Card from '@mui/material/Card';

import Typography from '@mui/material/Typography';
import { EditIcon, DeleteIcon, ViewIcon } from 'icons';
import EyeBorderIcon from 'icons/eyeBorderIcon';
import DeleteBorderIcon from 'icons/deleteBorderIcon';
import EditBorderIcon from 'icons/editBorderIcon';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';

// import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';

import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Box, Pagination, TextField } from '@mui/material';
import { Bell } from 'mdi-material-ui';
import { VisibilityOutlined } from '@mui/icons-material';
import {
  bellwork2,
  dailypracticearrow,
  diagnostic,
  exitticket,
  unitquiz,
} from 'assets';
import moment from 'moment';
import { status, statusBgColors, statusColors } from 'constants/consts';
import { useAppDispatch } from 'store/store';
import { getAllAssignments } from 'store/Thunk/assignContentThunk';
import useSchoolClassroomInfo from 'hooks/useSchoolClassroomInfo';
import { startLoading } from 'store/Slice/loadingSlice';
const icons: { [key: string]: string } = {
  Bellwork: bellwork2,
  Exit_Tickets: exitticket,
  Unit_Test: unitquiz,
  Unit_Quiz: unitquiz,
  Diagnostic: diagnostic,
  Daily_Practice: unitquiz,
};
export interface Header {
  label: string;
  width: number;
  value: string;
}
export interface IStudent {
  id?: string;
  name?: string;
  skill?: string;
  topic?: string;
  subTopic?: string;
  assignmentType?: string;
  dailyPracticeAssignments?: any[];
  totalGems?: string;
  pending?: string;
  status?: string;
  startDate?: string;
  endDate?: string;
}
export interface Template {
  type: string;
  startDate: string;
  dueDate: string;
  status: string;
  template: {
    schoolAssignmentQuizResult: { percentage: string; colorCode: string }[];
    subTopic: {
      subTopic: {
        name: string;
      };
    }[];
    skill: {
      skill: {
        name: string;
      };
    }[];
  };
}
export interface IClasses {
  id?: string;
  name?: string;
  totalAssignments?: string;
  completedAssignments?: string;
  pendingAssignments?: string;
  totalGems?: string;
  interVentionRecommended?: string;
  averageScore?: string;
  studentCount?: string;
  target?: string;
  Assignments?: IStudent[];
}

const StudentsList = ({
  students,
  setSelectedAssignment,
  setIsOpenAssignmentView,
  setIsOpenDelete,
}: {
  students: any;
  setSelectedAssignment: any;
  setIsOpenAssignmentView: any;
  setIsOpenDelete: any;
}) => {
  console.log('students', students);
  let AllAssignment: IStudent[] = [];
  if (students && students.length > 0) {
    AllAssignment = students;
  }
  // let dailyPracticeAssignments = students?.length
  //   ? students
  //       ?.flatMap((assignment: any) =>
  //         assignment.dailyPracticeAssignments.map(
  //           (dailyAssignment: any) => dailyAssignment.templates,
  //         ),
  //       )
  //       .flat()
  //   : [];
  console.log('students', students);

  const dailyPracticeAssignments = students.dailyPracticeAssignments.flatMap(
    (assignment: any) => {
      const id = assignment.id;
      const type = assignment.type;
      const status = assignment.status;
      const endDate = assignment.endDate;
      const startDate = assignment.startDate;
      let allAssignments = assignment?.templates?.concat(assignment.videos);
      if (allAssignments?.length > 0) {
        return allAssignments?.map((template: any) => ({
          id,
          type,
          status,
          endDate,
          startDate,
          ...template,
        }));
      }
    },
  );
  console.log(
    'students',
    students.dailyPracticeAssignments[5]?.dailyPracticeAssignments,
  );
  console.log(
    'students',
    students.dailyPracticeAssignments[5]?.schoolAssignments,
  );
  console.log('students', dailyPracticeAssignments);

  const schoolAssignments = students.schoolAssignments.flatMap(
    (assignment: any) => {
      const id = assignment.id;
      const userId = students.id;
      const name = students.name;
      const profileImage = students.profileImage;
      const type = assignment.type;
      const status = assignment.status;
      const dueDate = assignment.dueDate;
      const startDate = assignment.startDate;
      const assignBy = assignment.assignBy;
      const school_assignment_template = assignment.school_assignment_template;
      const school_assignment_quiz_results =
        assignment.school_assignment_quiz_results;

      return assignment.school_assignment_template.map((template: any) => ({
        id,
        userId,
        name,
        profileImage,
        type,
        status,
        dueDate,
        startDate,
        assignBy,
        school_assignment_template,
        school_assignment_quiz_results,
        ...template,
      }));
    },
  );
  console.log('students', schoolAssignments);

  const handleView = (item: any) => {
    setSelectedAssignment(item);
    setIsOpenAssignmentView(true);
  };
  const handleDelete = (item: any) => {
    console.log('students', item);
    setSelectedAssignment(item);
    setIsOpenDelete(true);
  };
  return (
    <List component="div" disablePadding>
      <Box sx={{ m: 2, mb: 4 }}>
        <ListItem sx={{ pl: 4 }}>
          {/* grid for listing student properties */}
          <Grid
            container
            spacing={2}
            sx={{
              '& .MuiGrid-item': {
                display: 'flex',
                alignItems: 'center',
              },
            }}
          >
            <Grid item md={1.5} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography fontSize={'14px'} color={'#000'} fontWeight={600}>
                Assignment Type
              </Typography>
            </Grid>
            <Grid item md={2.9}>
              <Typography fontSize={'14px'} color={'#000'} fontWeight={600}>
                Standard
              </Typography>
            </Grid>
            <Grid item md={2.5}>
              <Typography fontSize={'14px'} color={'#000'} fontWeight={600}>
                Skill
              </Typography>
            </Grid>

            <Grid item md={1.5}>
              <Typography fontSize={'14px'} color={'#000'} fontWeight={600}>
                Start Date
              </Typography>
            </Grid>
            <Grid item md={1.5}>
              <Typography fontSize={'14px'} color={'#000'} fontWeight={600}>
                End Date
              </Typography>
            </Grid>

            <Grid item md={1}>
              <Typography fontSize={'14px'} color={'#000'} fontWeight={600}>
                Status
              </Typography>
            </Grid>
            <Grid item md={1}>
              <Typography fontSize={'14px'} color={'#000'} fontWeight={600}>
                Actions
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
      </Box>
      {schoolAssignments?.map((template: Template) => (
        <Box sx={{ m: 2, mb: 4 }}>
          <ListItem sx={{ pl: 4 }}>
            {/* grid for listing template properties */}
            <Grid
              container
              spacing={2}
              sx={{
                '& .MuiGrid-item': {
                  display: 'flex',
                  alignItems: 'center',
                },
              }}
            >
              <Grid item md={1.5} sx={{ display: 'flex' }}>
                <img src={icons[template?.type || '']} alt="" />
                <Typography
                  ml={1}
                  variant="subtitle1"
                  color={'#84818a'}
                  fontWeight={'500'}
                  fontSize={'14px'}
                  lineHeight={'14px'}
                >
                  {template?.type}
                </Typography>
              </Grid>
              <Grid item md={2.9} justifyContent={'center'}>
                <Typography
                  variant="subtitle1"
                  color={'#84818a'}
                  fontWeight={'500'}
                  fontSize={'14px'}
                  lineHeight={'14px'}
                >
                  {template?.template?.subTopic[0]?.subTopic?.name || 'N/A'}
                </Typography>
              </Grid>
              <Grid item md={2.5}>
                <Typography
                  variant="subtitle1"
                  color={'#84818a'}
                  fontWeight={'500'}
                  fontSize={'14px'}
                  lineHeight={'14px'}
                >
                  {template?.template?.skill[0]?.skill?.name || 'N/A'}
                </Typography>
              </Grid>

              <Grid item md={1.5}>
                <Typography
                  variant="subtitle1"
                  color={'#84818a'}
                  fontWeight={'500'}
                  fontSize={'14px'}
                  lineHeight={'14px'}
                >
                  {moment(template?.startDate).format('MMMM DD, YYYY')}
                </Typography>
              </Grid>
              <Grid item md={1.5}>
                <Typography
                  variant="subtitle1"
                  color={'#84818a'}
                  fontWeight={'500'}
                  fontSize={'14px'}
                  lineHeight={'14px'}
                >
                  {moment(template?.dueDate).format('MMMM DD, YYYY')}
                </Typography>
              </Grid>
              <Grid item md={1}>
                <Typography
                  variant="subtitle1"
                  color={'#84818a'}
                  fontWeight={600}
                  fontSize={'12px'}
                  lineHeight={'14px'}
                  sx={{
                    minWidth: '80px',
                    textAlign: 'center',
                    color:
                      template?.template?.schoolAssignmentQuizResult[0]
                        ?.colorCode ||
                      statusColors[template?.status] ||
                      '',
                    backgroundColor:
                      `${template?.template?.schoolAssignmentQuizResult[0]?.colorCode}11` ||
                      statusBgColors[template?.status] ||
                      '',
                    padding: '5px 8px',
                    borderRadius: '16px',
                  }}
                >
                  {template?.template?.schoolAssignmentQuizResult[0]?.percentage
                    ? `${template?.template?.schoolAssignmentQuizResult[0]?.percentage}%`
                    : status[template?.status]}
                </Typography>
              </Grid>
              <Grid item md={1}>
                <IconButton
                  onClick={() => handleDelete(template)}
                  style={{
                    color: '#20A58A',
                    marginRight: '-11px',
                    padding: '8px',
                  }}
                  edge="end"
                  aria-label="comments"
                >
                  <DeleteBorderIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleView(template)}
                  style={{
                    color: '#20A58A',
                    marginRight: '-11px',
                    padding: '8px',
                  }}
                  edge="end"
                  aria-label="comments"
                >
                  <EyeBorderIcon />
                </IconButton>
              </Grid>
            </Grid>
          </ListItem>
        </Box>
      ))}
      <Box sx={{ m: 2, mb: 4, p: 2 }}>
        {/* grid for listing template properties */}
        <Grid
          container
          spacing={2}
          bgcolor={'#F9F9F9'}
          borderRadius={'8px'}
          pb={2}
          sx={{
            '& .MuiGrid-item': {
              display: 'flex',
              alignItems: 'center',
            },
          }}
        >
          <Grid
            item
            md={3}
            sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}
          >
            <img src={dailypracticearrow} alt="" />
            <Typography fontSize={'14px'} fontWeight={600} color={'#131515'}>
              Daily Practice
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography fontSize={'14px'} color={'#000'} fontWeight={600}>
              Total Videos:{' '}
              <span className="font-bold" style={{ color: '#84818a' }}>
                -
              </span>
            </Typography>
          </Grid>
          {/* <Grid item md={1}>
              <Typography fontSize={'14px'}>Topic</Typography>
            </Grid> */}
          <Grid item md={3}>
            <Typography fontSize={'14px'} color={'#000'} fontWeight={600}>
              Total Quiz:{' '}
              <span className="font-bold" style={{ color: '#84818a' }}>
                {dailyPracticeAssignments?.length || 0}
              </span>
            </Typography>
          </Grid>
          <Grid item md={3} justifyContent={'center'}>
            <Typography fontSize={'14px'} color={'#000'} fontWeight={600}>
              Total Challenges:{' '}
              <span className="font-bold" style={{ color: '#84818a' }}>
                -
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {dailyPracticeAssignments?.map((template: any) => (
        <Box sx={{ m: 2, mb: 4 }}>
          <ListItem sx={{ pl: 4 }}>
            {/* grid for listing template properties */}
            <Grid
              container
              spacing={2}
              sx={{
                '& .MuiGrid-item': {
                  display: 'flex',
                  alignItems: 'center',
                },
              }}
            >
              <Grid item md={1.5} sx={{ display: 'flex' }}>
                <span>
                  <img src={unitquiz} alt="" />
                </span>
                <Typography
                  ml={1}
                  variant="subtitle1"
                  color={'#84818a'}
                  fontWeight={'500'}
                  fontSize={'14px'}
                  lineHeight={'14px'}
                >
                  {'Daily practice'}
                </Typography>
              </Grid>
              <Grid item md={2.9} justifyContent={'center'}>
                <Typography
                  variant="subtitle1"
                  color={'#84818a'}
                  fontWeight={'500'}
                  fontSize={'14px'}
                  lineHeight={'14px'}
                >
                  {template?.template
                    ? template?.template?.subTopic[0]?.subTopic?.name
                    : template?.video?.subTopic[0]?.subTopic?.name || 'N/A'}
                </Typography>
              </Grid>
              <Grid item md={2.5}>
                <Typography
                  variant="subtitle1"
                  color={'#84818a'}
                  fontWeight={'500'}
                  fontSize={'14px'}
                  lineHeight={'14px'}
                >
                  {template?.template
                    ? template?.template?.skill[0]?.skill?.name
                    : template?.video?.skill[0]?.skill?.name || 'N/A'}
                </Typography>
              </Grid>

              <Grid item md={1.5}>
                <Typography
                  variant="subtitle1"
                  color={'#84818a'}
                  fontWeight={'500'}
                  fontSize={'14px'}
                  lineHeight={'14px'}
                >
                  {moment(template?.startDate).format('MMMM DD, YYYY')}
                </Typography>
              </Grid>
              <Grid item md={1.5}>
                <Typography
                  variant="subtitle1"
                  color={'#84818a'}
                  fontWeight={'500'}
                  fontSize={'14px'}
                  lineHeight={'14px'}
                >
                  {moment(template?.endDate).format('MMMM DD, YYYY')}
                </Typography>
              </Grid>
              <Grid item md={1}>
                <Typography
                  variant="subtitle1"
                  color={'#84818a'}
                  fontWeight={600}
                  fontSize={'12px'}
                  lineHeight={'14px'}
                  sx={{
                    minWidth: '80px',
                    textAlign: 'center',
                    color:
                      template?.template?.QuizResult[0]?.colorCode ||
                      statusColors[template?.status] ||
                      '',
                    backgroundColor: template?.template?.QuizResult[0]
                      ?.colorCode
                      ? `${template?.template?.QuizResult[0]?.colorCode}11`
                      : `${statusColors[template?.status]}11` || '',
                    padding: '5px 8px',
                    borderRadius: '16px',
                  }}
                >
                  {template?.template?.QuizResult[0]?.percentage1st
                    ? `${template?.template?.QuizResult[0]?.percentage1st}%`
                    : '' || status[template?.status]}
                </Typography>
              </Grid>
              <Grid item md={1}>
                <IconButton
                  onClick={() => handleDelete(template)}
                  style={{
                    color: '#20A58A',
                    marginRight: '-11px',
                    padding: '8px',
                  }}
                  edge="end"
                  aria-label="comments"
                >
                  <DeleteBorderIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleView(template)}
                  style={{
                    color: '#20A58A',
                    marginRight: '-11px',
                    padding: '8px',
                  }}
                  edge="end"
                  aria-label="comments"
                >
                  <EyeBorderIcon />
                </IconButton>
              </Grid>
            </Grid>
          </ListItem>
        </Box>
      ))}
    </List>
  );
};

export default function AssignmentList({
  classes,
  color,
  search,
  pageNo,
  setPageNo,
  pageIndex,
  hidePagination,
  header,
  showPagination,
  entriesPerPage,
  setSelectedAssignment,
  setIsOpenDelete,
  setAssignmentsList,
  assignmentsList,
  setIsOpenAssignmentView,
  assignmentsListMeta,
  setAssignmentsListMeta,
}: {
  classes?: any;
  assignmentsListMeta?: any;
  setAssignmentsListMeta?: any;
  pageIndex?: any;
  setAssignmentsList?: any;
  assignmentsList?: any;
  pageSize?: any;
  showPagination?: any;
  hidePagination?: any;
  search?: any;
  pageNo?: any;
  setPageNo?: any;
  setSelectedAssignment?: any;
  setIsOpenDelete?: any;
  setIsOpenAssignmentView?: any;
  color?: string;
  entriesPerPage?: any;
  header?: Header[];
}) {
  const urlParams = new URLSearchParams(window.location.search);
  const studentDetail: any = urlParams.get('studentDetail');
  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(
    studentDetail ? 0 : -1,
  );

  const handleClick = (index: number) => {
    if (selectedIndex === index) {
      setSelectedIndex(-1); // Collapse the selected div if it's already expanded
    } else {
      setSelectedIndex(index); // Expand the selected div
    }
  };
  const dispatch = useAppDispatch();
  const fetchIdRef = useRef(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const classroomId = urlParams.get('classroomId');
  useEffect(() => {
    if (classes && classes.length) {
      setPageCount(assignmentsListMeta?.meta?.totalPages);
      setTotalCount(assignmentsListMeta?.meta?.totalCount);
    }
  }, [assignmentsListMeta]);
  const fetchData = useCallback(({ pageSize, pageIndex, search }: any) => {
    const fetchId = ++fetchIdRef.current;
    // We'll even set a delay to simulate a server here
    if (fetchId === fetchIdRef.current) {
      setPageNo(pageIndex);
      setPageSize(pageSize);
      dispatch(startLoading());
      dispatch(
        getAllAssignments({
          id: classroomId,
          pageSize,
          pageIndex,
          search,
        }),
      )
        .unwrap()
        .then((res) => {
          setAssignmentsList(res.items);
          setAssignmentsListMeta(res);
        });
    }
    // setTimeout(() => {}, 1000);
  }, []);
  const setEntriesPerPage = (value: any) => {
    setPageNo(0);
    fetchData({
      pageIndex: 0,
      pageSize: value,
      search: search,
    });
    setPageSize(value);
  };
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    if (fetchData) {
      fetchData({
        pageIndex: value - 1,
        pageSize,
        search: search,
      });
    }
  };
  const renderPagination = () => (
    <Pagination
      count={pageCount || 10}
      page={pageNo + 1 || 1}
      shape="rounded"
      onChange={handleChangePage}
    />
  );

  return (
    <>
      {classes?.map((classes: any, i: number) => (
        <div
          style={{
            border: open ? '1px solid #DCDCDC' : 'none',
            borderRadius: '10px 10px 0px 0px',
            marginBottom: '16px',
          }}
        >
          <Card
            style={{
              backgroundColor: color,
              borderRadius: '10px 10px 0px 0px',
              boxShadow:
                '0px 2px 1px -1px rgba(76, 78, 100, 0.2),0px 1px 1px 0px rgba(76, 78, 100, 0.14),0px 1px 3px 0px rgba(76, 78, 100, 0.12)',
            }}
          >
            <ListItemButton
              style={{ padding: '3px 12px' }}
              onClick={() => handleClick(i)}
            >
              {/* <ListItemIcon>
                        class
                    </ListItemIcon> */}

              <Grid
                container
                spacing={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  color: '#131515',
                  '& .css-6uzn28-MuiTypography-root': {
                    color: '#131515',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '22px',
                  },
                }}
              >
                {header?.map((head) => (
                  <Grid item md={head.width}>
                    {head && classes && (
                      <>
                        <Typography
                          color="#84818A"
                          fontSize={'14px'}
                          fontWeight={400}
                          gutterBottom
                        >
                          {head?.label}
                        </Typography>
                        <Tooltip
                          title={head?.value === 'name' ? classes['id'] : ''}
                        >
                          <Typography fontWeight={600} color={'#131515'}>
                            {head?.value === 'totalAssignments'
                              ? classes.dailyPracticeAssignments.length +
                                classes.schoolAssignments.length
                              : head?.value === 'name'
                              ? classes['name'] ||
                                '' + ' ' + classes['lastname'] ||
                                ''
                              : classes[head?.value] || '-'}
                          </Typography>
                        </Tooltip>
                      </>
                    )}
                  </Grid>
                ))}
                <Grid
                  item
                  md={0.5}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {selectedIndex === i ? <ExpandLess /> : <ExpandMore />}
                </Grid>
              </Grid>
            </ListItemButton>
          </Card>

          <Collapse in={selectedIndex === i} timeout="auto" unmountOnExit>
            <StudentsList
              setSelectedAssignment={setSelectedAssignment}
              setIsOpenAssignmentView={setIsOpenAssignmentView}
              setIsOpenDelete={setIsOpenDelete}
              students={classes}
            />
          </Collapse>
        </div>
      ))}
      {/* {showPagination && ( */}
      {true && !hidePagination && (
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          my={4}
          sx={{ border: '1px solid #E9EBED', borderRadius: '6px', p: 2 }}
        >
          {/* {entriesPerPage && ( */}
          {true && (
            <Box flex="1" display="flex" alignItems="center">
              <Typography variant="body2" className="entries-page-txt">
                Show&nbsp;&nbsp;
              </Typography>
              <Autocomplete
                disableClearable
                value={pageSize?.toString()}
                options={['10', '25', '50', '100']}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10));
                }}
                size="small"
                sx={{ width: '5rem' }}
                renderInput={(params) => <TextField {...params} />}
              />
              <Typography variant="body2" className="entries-page-txt">
                &nbsp;&nbsp;entries of {totalCount}
              </Typography>
            </Box>
          )}
          {renderPagination()}
        </Box>
      )}
    </>
  );
}
