import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import {
  Box,
  Checkbox,
  DialogActions,
  Divider,
  FormGroup,
  IconButton,
  Stack,
} from '@mui/material';
import SharedButton from 'components/button';

const emails = ['username@gmail.com', 'user02@gmail.com'];

export interface SimpleDialogProps {
  open: boolean;
  onClose: (value: boolean) => void;
}

export default function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, open } = props;
  const [viewBy, setViewBy] = React.useState('classroom');
  const handleListItemClick = (value: boolean) => {
    onClose(false);
  };
  console.log('open', open);

  return (
    <Dialog onClose={onClose} fullWidth open={open} className="filter-dialog">
      <Box className="flex justify-between">
        <DialogTitle color={'#131515'}>Filter</DialogTitle>
        <DialogActions onClick={() => handleListItemClick(false)}>
          <IconButton
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 8,
              top: 16,
              color: (theme) => theme.palette.grey[600],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Box>
      <Divider className="!m-0" />
      <List className="!p-4">
        <ListItem
          disableGutters
          className="flex-1 flex-col justify-start !items-start"
        >
          <Typography className="!font-bold !text-sm ">View By</Typography>
          <FormControl>
            <RadioGroup
              row
              value={viewBy}
              onChange={(e) => setViewBy(e.target.value)}
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="classroom"
                control={<Radio />}
                label="Classroom"
              />
              <FormControlLabel
                value="group"
                control={<Radio />}
                label="Group"
              />
              <FormControlLabel
                value="student"
                control={<Radio />}
                label="Student"
              />
            </RadioGroup>
          </FormControl>
        </ListItem>
        <ListItem
          disableGutters
          className="flex-1 flex-col justify-start !items-start mt-2"
        >
          <Typography className="!font-bold !text-sm  ">
            School Assessment
          </Typography>
          <FormGroup className="flex !flex-row gap-0">
            <FormControlLabel
              control={<Checkbox />}
              label="Bellwork"
            />
            <FormControlLabel control={<Checkbox />} label="Exit Ticket" />
            <FormControlLabel control={<Checkbox />} label="Unit Quiz" />
            <FormControlLabel control={<Checkbox />} label="Diagnostic Test" />
            <FormControlLabel control={<Checkbox />} label="Unit Test" />
          </FormGroup>
        </ListItem>
        <ListItem
          disableGutters
          className="flex-1 flex-col justify-start !items-start mt-2"
        >
          <Typography className="!font-bold !text-sm">
            Daily Practice
          </Typography>
          <FormGroup className="flex !flex-row gap-0">
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Video"
            />
            <FormControlLabel control={<Checkbox />} label="Quiz" />
            <FormControlLabel control={<Checkbox />} label="Daily Challenges" />
          </FormGroup>
        </ListItem>
        <ListItem
          disableGutters
          className="flex-1 flex-col justify-start !items-start mt-2"
        >
          <Typography className="!font-bold !text-sm  ">Score (%)</Typography>
          <FormGroup className="flex !flex-row gap-0">
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="0 - 49 (Emerging)"
            />
            <FormControlLabel
              control={<Checkbox />}
              label="50 - 69 (Approaching)"
            />
            <FormControlLabel
              control={<Checkbox />}
              label="70 - 100 (Proficient)"
            />
          </FormGroup>
        </ListItem>
        <ListItem
          disableGutters
          className="flex-1 flex-col justify-start !items-start mt-2"
        >
          <Typography className="!font-bold !text-sm">Topics</Typography>
          <FormGroup className="flex !flex-row gap-0">
            <FormControlLabel
              control={<Checkbox defaultChecked/>}
              label="Master Math"
            />
            <FormControlLabel control={<Checkbox />} label="Master Reading" />
            <FormControlLabel control={<Checkbox />} label="Science" />
            <FormControlLabel control={<Checkbox />} label="Social Studies" />
            <FormControlLabel control={<Checkbox />} label="ELL/ESOL" />
            <FormControlLabel control={<Checkbox />} label="SEL" />
          </FormGroup>
        </ListItem>
        <ListItem
          disableGutters
          className="flex-1 flex-col justify-start !items-start mt-2"
        >
          <Typography className="!font-bold !text-sm">Date</Typography>
          <Box className="flex justify-between gap-4 w-4/5 mt-3">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker label="From" className="text-3xl" />
              </DemoContainer>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker label="To" />
              </DemoContainer>
            </LocalizationProvider>
          </Box>
        </ListItem>
        <ListItem
          disableGutters
          className="flex-1 flex-col justify-start !items-start mt-4"
        >
          <Box className="flex justify-between gap-4 w-full mt-3">
            <SharedButton
              variant="outlined"
              name="Reset Filter"
              fullWidth
              className="primary_bg_btn_outlined"
            />
            <SharedButton
              variant="contained"
              fullWidth
              name="Apply Filter"
              className="primary_bg_btn"
            />
          </Box>
        </ListItem>
      </List>
    </Dialog>
  );
}
