// ** Third Party Import
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  admin?: boolean;
  active?: boolean;
  text: string;
}

const Translations = ({ text, admin, active }: Props) => {
  // ** Hook
  const { t } = useTranslation();

  return (
    <Typography
      component={'p'}
      className={`${
        admin && !active
          ? 'admin_menu_txt'
          : active
          ? 'admin_menu_txt_selected'
          : 'menu_txt'
      }`}
    >{`${t(text)}`}</Typography>
  );
};

export default Translations;
