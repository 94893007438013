import React, { useCallback, useEffect, useRef, useState } from 'react';
import './styles/style.css';
import { ReactComponent as EyeIcon } from './assets/eye-icon.svg';
import { sampleColumns, sampleRows } from './helpers/helpers';

///
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ViewVideo from './components/viewVideo';
import { useAppDispatch, useAppSelector } from 'store/store';
import { getLocalDateTime } from 'helper/service';
import { changeVideoStatus } from 'store/Thunk/videoThunk';
import { startLoading } from 'store/Slice/loadingSlice';
import { getSuperAdminVideos } from 'store/Thunk/dashboardThunk';
import MuxPlayer from '@mux/mux-player-react/lazy';
import { secondsToMinutes } from 'helper/secondsToMinutes';

const ProgressDetailSection = ({ sectionTitle }: { sectionTitle: string }) => {
  return (
    <div style={Styles.mainContainer}>
      <div
        className="detailedProgressTable-section-body"
        style={Styles.sectionBody}
      >
        <StickyHeadTable />
      </div>
    </div>
  );
};

export default ProgressDetailSection;

const Styles = {
  mainContainer: {
    marginTop: '2rem', //TODO: remove this
    width: '100%',
    height: 'auto',
    backgroundColor: 'white',
    color: '#484848',
  },
  sectionHeader: {
    height: '60px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 2rem',
  },
  rightAligned: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',

    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '28px',
    marginTop: '1rem',
  },
  sectionBody: {
    width: '100%',
  },
};

function StickyHeadTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const fetchIdRef = useRef(0);
  const [videoList, setVideoList] = useState([]);

  const fetchData = useCallback(
    ({ pageSize, pageIndex, search }: any) => {
      const fetchId = ++fetchIdRef.current;
      // We'll even set a delay to simulate a server here
      if (fetchId === fetchIdRef.current) {
        dispatch(startLoading());
        dispatch(
          getSuperAdminVideos({
            limit: pageSize,
            page: pageIndex + 1,
          }),
        )
          .unwrap()
          .then((res: any) => {
            setVideoList(res);
          });
      }
      // setTimeout(() => {}, 1000);
    },
    [page],
  );
  console.log('videoList', videoList);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    fetchData({
      pageIndex: newPage,
      pageSize: rowsPerPage,
    });
  };
  const [openDuplicate, setOpenDuplicate] = useState<any>(false);
  const [selectedVideo, setSelectedVideo] = useState('');
  const dispatch: any = useAppDispatch();

  const handleChangeStatus = (videoId: any, status: any) => {
    dispatch(startLoading());
    dispatch(changeVideoStatus({ videoId: videoId, status: status })).then(
      () => {
        fetchData({
          pageIndex: page,
          pageSize: rowsPerPage,
        });
        handleOpenDuplicate();
      },
    );
  };
  useEffect(() => {
    fetchData({
      pageIndex: page,
      pageSize: rowsPerPage,
    });
  }, []);
  const handleOpenDuplicate = () => setOpenDuplicate(false);
  const handleCloseDeselect = (video: any) => {
    setSelectedVideo(video);
    setOpenDuplicate(!openDuplicate);

    // handleAssignContent();
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    fetchData({
      pageIndex: 1,
      pageSize: +event.target.value,
    });
  };
  const { superAdminVideos } = useAppSelector(
    (state: any) => state.dashboardReducer,
  );
  return (
    <Paper
      sx={{ width: '100%', overflow: 'hidden', borderRadius: '6px !important' }}
    >
      {videoList.length ? (
        <TableContainer
          sx={{
            maxHeight: 440,
            minWidth: 1000,
            overflowX: 'scroll',
            display: 'flex',
          }}
        >
          <Table
            stickyHeader
            className="!flex w-full flex-col"
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow
                className="!flex w-full"
                sx={{
                  overflow: 'visible !important',
                }}
              >
                {sampleColumns.map((column) => (
                  <TableCell
                    className={`!flex`}
                    sx={{
                      backgroundColor: '#E9F6F3 !important',
                      borderRadius: '0px !important',
                      fontWeight: '500 !important',
                      lineHeight: '21px !important',
                      fontSize: '14px !important',
                      color: '#52525B !important',
                      textTransform: 'none !important',
                      flex: column.width,
                      justifyContent:
                        column.id === 'action' ? 'center' : 'left',
                    }}
                    key={column.id}
                    align={column.align}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody className="!flex flex-col w-full">
              {videoList?.map((row: any) => {
                return (
                  <TableRow
                    className="!flex flex-1 w-full"
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.video.title}
                    sx={{
                      height: '80px !important',
                    }}
                  >
                    {sampleColumns.map((column) => {
                      const value = row[column.id];

                      return (
                        <TableCell
                          className={`!flex`}
                          key={column.id}
                          align={column.align}
                          sx={{ flex: column.id === 'action' ? 1.5 : 1 }}
                        >
                          <DisplayCorrectColumn
                            column={column}
                            row={row}
                            value={value}
                            handleChangeStatus={handleChangeStatus}
                            handleView={handleCloseDeselect}
                          />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <></>
      )}
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={superAdminVideos?.meta?.total || ''}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ViewVideo
        handleChangeStatus={handleChangeStatus}
        open={openDuplicate}
        itemID={selectedVideo}
        selectedVideo={selectedVideo}
        onClose={() => handleCloseDeselect(selectedVideo)}
        onCancel={handleOpenDuplicate}
      />
    </Paper>
  );
}

const DisplayCorrectColumn = ({
  column,
  handleChangeStatus,
  row,
  value,
  handleView,
}: {
  column: any;
  row: any;
  value: any;
  handleView: any;
  handleChangeStatus: any;
}) => {
  const [data, setData] = useState(false);
  const playerRef = useRef(null);
  const [totalVideoDuration, setTotalVideoDuration] = useState(0);
  console.log('totalVideoDuration', totalVideoDuration);

  if (column?.id === 'action') {
    return (
      <div
        className="action-buttons"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {value?.map((action: string, index: number) => {
          return (
            <div
              className="action-button"
              key={`${action}-${index}`}
              onClick={() =>
                action === 'View'
                  ? handleView(row)
                  : action === 'Approve'
                  ? handleChangeStatus(row.id, 'approved')
                  : handleChangeStatus(row.id, 'rejected')
              }
              style={{
                backgroundColor:
                  action === 'Approve' && !(row?.approval === 'approved')
                    ? '#66CB63'
                    : action === 'Approve' && row?.approval === 'approved'
                    ? 'lightgray'
                    : action === 'Reject' && !(row.approval === 'rejected')
                    ? '#F41F52'
                    : action === 'Reject' && row.approval === 'rejected'
                    ? 'lightgray'
                    : '',
                pointerEvents:
                  (action === 'Approve' && row?.approval === 'approved') ||
                  (action === 'Reject' && row.approval === 'rejected')
                    ? 'none'
                    : 'auto',
                height: '45px',
                cursor: 'pointer',
                width: '74px',
                borderRadius: '8px',
                padding: '17px, 16px, 17px, 16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                color: '#FFFFFF',
                marginRight: '8px',
                fontSize: '14px',
              }}
            >
              {action === 'View' ? <EyeIcon /> : action}
            </div>
          );
        })}
      </div>
    );
  }

  if (column?.id === 'video') {
    return (
      <div
        style={{
          display: 'flex',
        }}
      >
        <img
          src={`https://image.mux.com/${value?.playbackId}/thumbnail.png`}
          alt=""
          style={{
            width: 60,
            height: 47,
            borderRadius: 4,
            marginRight: 5,
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            className="video-title"
            style={{
              color: '#131515',
              fontWeight: 600,
              fontSize: '12px',
            }}
            onClick={() => console.log('value', value)}
          >
            {value?.title}
          </div>
          <div
            className="video-length"
            style={{
              color: '#979C9E',
            }}
          >
            {value?.length}
          </div>
        </div>
      </div>
    );
  }

  if (column.id === 'user') {
    return (
      <div
        className="student-container flex-row gap-1"
        style={{
          display: 'flex',
          minWidth: column?.minWidth,
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <img
          src={value?.imageUrl}
          alt=""
          style={{
            width: '32px',
            height: '32px',
            borderRadius: '50%',
            marginRight: '5px',
          }}
        />
        <div
          style={{
            display: 'flex',
          }}
        >
          <div
            className="student-name"
            style={{
              color: '#484848',
              fontWeight: 500,
              fontSize: '12px',
            }}
          >
            {value?.name} (ID:{value?.id})
          </div>
        </div>
      </div>
    );
  }
  if (column.id === 'date') {
    return (
      <div
        className="flex items-center"
        style={{
          maxWidth: column.id === 'skill' ? 150 : 200,
          fontSize: '12px',
        }}
      >
        {getLocalDateTime(value, 'MMMM D, YYYY')}
      </div>
    );
  }
  if (column.id === 'videoLength') {
    return (
      <div
        className="flex items-center"
        style={{
          maxWidth: column.id === 'skill' ? 150 : 200,
          fontSize: '12px',
        }}
      >
        <div
          style={{ position: 'absolute', opacity: 0, zIndex: -9999, top: 0 }}
        >
          <MuxPlayer
            ref={playerRef}
            startTime={Number(0)}
            streamType="on-demand"
            metadataVideoId={value?.playbackId}
            playbackId={value?.playbackId}
            onLoadedData={(e: any) => {
              setTotalVideoDuration(e.target.duration);
            }}
            style={{ aspectRatio: 16 / 9, height: '100%' }}
          />
        </div>

        <div>
          {totalVideoDuration ? secondsToMinutes(totalVideoDuration) : '0 min'}
        </div>
      </div>
    );
  }

  if (!column.id) {
    return <div></div>;
  }

  return (
    <div
      className="flex items-center"
      style={{
        maxWidth: column.id === 'skill' ? 150 : 200,
        fontSize: '12px',
      }}
    >
      {value}
    </div>
  );
};
