import SimpleLoader from '@core/components/MDLoader/simpleLoader';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import WarningIcon from 'icons/warning';
import { useState } from 'react';
import {
  deleteClassroom,
  getClassroomBySchoolID,
} from 'store/Thunk/classroomThunk';
import { DeleteQuestion } from 'store/Thunk/questionThunk';
import { useAppDispatch } from 'store/store';

interface DialogProps {
  open: boolean;
  deleteId: string;
  onSave: any;
  onCancel: any;
  setFormData: any;
  blankFormData: any;
  setDeleteId: any;
  onClose: (id: string) => void;
  fetch: (setPageOne: boolean) => void;
}

function ConfirmClose(props: DialogProps) {
  const dispatch = useAppDispatch();
  const {
    onClose,
    onCancel,
    blankFormData,
    deleteId,
    setFormData,
    open,
    setDeleteId,
    onSave,
    fetch,
  } = props;

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    onClose('');
  };
  console.log(deleteId);

  return (
    <Dialog fullWidth maxWidth="sm" onClose={handleClose} open={open}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        py={6}
      >
        <WarningIcon />
        <Typography variant="h5" mt={3}>
          Are you sure you want to close?
        </Typography>
        <Typography variant="h6" mt={3}>
          Unsaved questions will be lost
        </Typography>
        <Stack direction={'row'} spacing={4} mt={4}>
          <Button
            fullWidth
            size="small"
            variant="contained"
            className="dialog_grey_button"
            onClick={onCancel}
          >
            Close
          </Button>
          <Button
            fullWidth
            size="small"
            variant="contained"
            className="dialog_red_button"
            onClick={onSave}
            disabled={loading}
          >
            {loading ? <SimpleLoader size={24} color={'secondary'} /> : 'Save'}
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
}

export default ConfirmClose;
