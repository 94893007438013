// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action_popup {
  z-index: 99999;
  position: absolute;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  width: 140px;
  padding: 14px;
  right: 50px;
}

.action_item {
  display: grid;
  grid-template-columns: 0.4fr 1fr;
  padding: 10px 0px;
  text-align: left;
  align-items: center;
  gap: 12px;
  font-size: 14px;
}

.action_item:hover {
  color: #20a58a;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/popUp/styles.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;EACA,aAAA;EACA,WAAA;AACF;;AACA;EACE,aAAA;EACA,gCAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,SAAA;EACA,eAAA;AAEF;;AAAA;EACE,cAAA;EACA,eAAA;AAGF","sourcesContent":[".action_popup {\n  z-index: 99999;\n  position: absolute;\n  font-size: 16px;\n  display: flex;\n  flex-direction: column;\n  width: 140px;\n  padding: 14px;\n  right: 50px;\n}\n.action_item {\n  display: grid;\n  grid-template-columns: 0.4fr 1fr;\n  padding: 10px 0px;\n  text-align: left;\n  align-items: center;\n  gap: 12px;\n  font-size: 14px;\n}\n.action_item:hover {\n  color: #20a58a;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
