import { Chip, Checkbox, CircularProgress } from '@mui/material';
import { Stack, minWidth } from '@mui/system';
import DefaultCell from 'components/DefaultCell';
import ReadMore from 'components/ReadMore/ReadMore';
import CustomTable from 'components/customTable';
import TableHeader from 'components/tableHeader';
import TableWrapper from 'components/tableWrapper';
import { set } from 'date-fns';
import { typesColors } from 'helper/constants';
import {
  itemExistsWithType,
  removeItemByID,
  updateOrAddType,
} from 'helper/functions';
import { displayMultipleValue, getLocalDateTime } from 'helper/service';
import { _assignmentTypes } from 'helper/types';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { startLoading } from 'store/Slice/loadingSlice';
import { setVideoList } from 'store/Slice/videoSlice';
import { checkExistence } from 'store/Thunk/assignContentThunk';
import { getVideoList } from 'store/Thunk/videoThunk';
import { useAppDispatch, useAppSelector } from 'store/store';

interface propsType {
  setSelectedContent: (value: any) => void;
  selectedContent: any;
  selectiveInfo: any;
  setInfoModal: any;
  setSelectedQuizzes: any;
  selectedVideos: any;
  setSelectedVideos: any;
}

const AssignVideosTable = ({
  setSelectedContent,
  setSelectedQuizzes,
  selectedContent,
  selectiveInfo,
  selectedVideos,
  setSelectedVideos,
  setInfoModal,
}: propsType) => {
  const dispatch = useAppDispatch();
  const { videoList } = useAppSelector((state: any) => state.videoReducer);

  const [videos, setVideos] = useState<any[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [search, setSearch] = useState('');

  const { id: assigmentId } = useParams();
  const [searchParams] = useSearchParams();
  const contentId = searchParams.get('content');
  const contentType = searchParams.get('contentType');
  const [loading, setLoading] = useState(false);
  const [foundedItems, setFoundedItems] = useState<any>({});
  const alreadyAssignedValues: string[] = JSON.parse(
    localStorage.getItem('alreadyAssigned') || '[]',
  );
  const ClassroomData = useAppSelector(
    (state) => state.classroomReducer.classroomID,
  );
  const urlParams = new URLSearchParams(window.location.search);
  let topicId = ClassroomData?.classroom?.topic?.id;
  useEffect(() => {
    setVideos([]);
    dispatch(setVideoList([]));
  }, []);
  useEffect(() => {
    dispatch(
      getVideoList({ pageCount: 1, perPage: 10, topicId, isAcademic: true }),
    );
  }, []);

  useEffect(() => {
    if (videoList && videoList?.items) {
      setVideos(videoList?.items);
      setPageCount(videoList?.meta?.lastPage);
      setTotalCount(videoList?.meta?.total);
    }
  }, [videoList]);
  console.log('videoList', videoList);
  console.log('videoList', videos);
  useEffect(() => {
    if (contentId && contentType === 'video') {
      setSelectedContent([
        {
          type: 'Videos',
          ids: [contentId],
        },
      ]);
    }
  }, [contentId]);

  const onItemSelect = useCallback(
    (itemID: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setSelectedVideos((prevState: any) => {
        return prevState.map((obj: any) => {
          if (obj.id === itemID) {
            return { ...obj, selected: e.target.checked, type: 'video' };
          }
          return obj;
        });
      });
    },
    [],
  );
  function handleCountChange(id: number, text: string) {
    const increase = text === 'increment';

    // Find the object in the array with the matching id
    setSelectedVideos((prevState: any) => {
      return prevState.map((obj: any) => {
        if (obj.id === id) {
          return { ...obj, count: increase ? obj.count + 1 : obj.count - 1 };
        }
        return obj;
      });
    });

    // Output the updated array for demonstration
    console.log(rows);
    console.log(text);
  }
  useEffect(() => {}, [selectedItems, selectedContent]);

  const columns = useMemo(
    () => [
      {
        Header: 'Grade',
        accessor: 'grade',
        Cell: ({ value }: any) => {
          if (value.length) {
            return <ReadMore text={displayMultipleValue(value, 'grade')} />;
          }
        },
      },
      {
        Header: 'Title',
        accessor: 'title', // accessor is the "key" in your data
        Cell: ({ value }: any) => {
          if (value.length) {
            return <ReadMore text={value || '-'} />;
          } else {
            return '-';
          }
        },
      },

      {
        Header: 'Content Area',
        accessor: 'topic',
        Cell: ({ value }: any) => {
          if (value.length) {
            return (
              <DefaultCell
                value={displayMultipleValue(value, 'topic') || '-'}
              />
            );
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Domain',
        accessor: 'subTopic',
        Cell: ({ value }: any) => {
          if (value?.length) {
            return (
              <ReadMore
                text={
                  `${value[0]?.subTopic?.sequenceNumber} - ${value[0]?.subTopic?.name}` ||
                  '-'
                }
              />
            );
          } else {
            return '-';
          }
        },
      },
      {
        Header: 'Skill',
        accessor: 'skill',
        Cell: ({ row, value }: any) => {
          if (value && value?.length > 0 && value[0]?.skill) {
            const skillInfo = value[0].skill;
            const displayText = `${skillInfo.sequenceNumber} - ${
              skillInfo.name || '-'
            }`;

            return <DefaultCell value={displayText} />;
          } else {
            return '-';
          }
        },
      },
      // {
      //   Header: 'No of Questions',
      //   accessor: 'assigned',
      //   Cell: ({ row }) => {
      //     return (
      //       <div className="flex items-center justify-center px-4 py-1 border-gray-400 border rounded-lg">
      //         <button
      //           className="text-2xl text-gray-500"
      //           onClick={() => handleCountChange(row.original.id, 'decrement')}
      //         >
      //           -
      //         </button>
      //         <span className="mx-3 text-xl">{row.original.count}</span>
      //         <button
      //           className="text-2xl text-gray-500"
      //           onClick={() => handleCountChange(row.original.id, 'increment')}
      //         >
      //           +
      //         </button>
      //       </div>
      //     );
      //   },
      // },
      // {
      //   Header: 'Assigned',
      //   accessor: 'assigned',
      //   Cell: ({ row }) => {
      //     if (row.original?.alreadyAssigned) {
      //       return (
      //         <Chip
      //           sx={{
      //             minWidth: '80px',
      //           }}
      //           onClick={() => {
      //             setInfoModal(true);
      //           }}
      //           label={'Already Assigned !'}
      //           style={{
      //             background: '#d0dfff',
      //             color: '#1e48a1',
      //           }}
      //         />
      //       );
      //     }
      //     if (itemExistsWithType(selectedContent, 'Videos', row.original.id)) {
      //       return (
      //         <Chip
      //           sx={{
      //             minWidth: '80px',
      //           }}
      //           label={_assignmentTypes[selectiveInfo?.assignmentType]}
      //           style={{
      //             background:
      //               typesColors[selectiveInfo?.assignmentType]?.bgColor,
      //             color: typesColors[selectiveInfo?.assignmentType]?.textColor,
      //           }}
      //         />
      //       );
      //     }
      //     return null;
      //   },
      // },
      {
        Header: '',
        accessor: 'selected',
        Cell: ({ value, row }) => {
          if (
            selectiveInfo?.toName !== 'Invididual' &&
            !row.original.alreadyAssigned
          ) {
            return (
              <div className="flex flex-1 justify-center">
                <Checkbox
                  sx={{
                    '&.Mui-checked': {
                      color: '#C73E9D',
                    },
                    margin: '10px',
                  }}
                  onChange={onItemSelect(row.original.id)}
                  checked={
                    itemExistsWithType(
                      selectedContent,
                      'Videos',
                      row.original.id,
                    ) || row.original.selected
                  }
                />
              </div>
            );
          }
        },
      },
    ],
    [selectedContent, alreadyAssignedValues],
  );

  useEffect(() => {
    const checkIds = videos.map((item) => item.id);
    if (checkIds.length > 0) {
      dispatch(
        checkExistence({
          type: 'Videos',
          ids: [...checkIds],
        }),
      )
        .unwrap()
        .then((response) => {
          setFoundedItems(response);
        })
        .catch(() => {});
    }
  }, [videos, dispatch]);

  const rows = useMemo(() => {
    const data = videos.map((item) => ({
      id: item.id,
      title: item.title,
      topic: item.topic,
      grade: item.grade,
      skill: item.skill,
      subTopic: item.subTopic,
      count: item?.count || 1,
      date: item.createdAt ? getLocalDateTime(item.createdAt) : '-',
      selected: selectedItems.includes(item.id),
      alreadyAssigned:
        foundedItems?.existingVideoIds?.includes(item.id) || false,
    }));
    return data;
  }, [videos, selectedItems, foundedItems]);
  useEffect(() => {
    setSelectedVideos(rows);
  }, [rows]);
  useEffect(() => {
    setSelectedQuizzes([]);
  }, []);
  const fetchData = useCallback(({ pageSize, pageIndex, search }: any) => {
    setPageNo(pageIndex);
    dispatch(startLoading());
    dispatch(
      getVideoList({ pageSize, pageIndex, search, topicId, isAcademic: true }),
    );
  }, []);

  return (
    <Stack>
      <TableHeader
        item={{
          action: false,
          fetchData,
          search,
          setSearch,
        }}
      />
      <TableWrapper title="Video">
        <CustomTable
          table={{
            columns,
            rows: selectedVideos,
          }}
          fetchData={fetchData}
          variant="borderLess"
          canSearch={false}
          searchText="Search..."
          pageCount={pageCount}
          pageNo={pageNo}
          totalCount={totalCount}
          color="#929292"
          searchAssignment={search}
        />
      </TableWrapper>
    </Stack>
  );
};

export default AssignVideosTable;
