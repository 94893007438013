import React, { useState } from 'react';
import {
  Card,
  Checkbox,
  Chip,
  Collapse,
  Grid,
  ListItemButton,
  Typography,
} from '@mui/material';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

interface CustomComponentProps {
  title: string;
  children: React.ReactNode;
}

const TableWrapper: React.FC<CustomComponentProps> = ({ title, children }) => {
  const [open, setOpen] = useState(true);
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <div
      style={{
        border: open ? '1px solid #DCDCDC' : 'none',
        borderRadius: '10px 10px 0px 0px',
        marginTop: '16px',
      }}
    >
      <Card
        style={{
          backgroundColor: '#E9F6F3',
          borderRadius: '10px 10px 0px 0px',
          boxShadow:
            '0px 2px 1px -1px rgba(76, 78, 100, 0.2),0px 1px 1px 0px rgba(76, 78, 100, 0.14),0px 1px 3px 0px rgba(76, 78, 100, 0.12)',
        }}
      >
        <ListItemButton style={{ padding: '3px 12px' }} onClick={handleClick}>
          <Grid
            container
            spacing={2}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              color: '#131515',
              '& .css-6uzn28-MuiTypography-root': {
                color: '#131515',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '22px',
              },
            }}
          >
            <Grid item className="min-h-14 flex items-center">
              <Typography fontWeight={600} color={'#131515'}>
                {title}
              </Typography>
            </Grid>
            <Grid
              item
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {open ? <ExpandLess /> : <ExpandMore />}
            </Grid>
          </Grid>
        </ListItemButton>
      </Card>
      <Collapse sx={{ padding: '5px' }} in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </div>
  );
};

export default TableWrapper;
