import React, { useState } from 'react';
import arrowUp from './arrowUp.svg';
interface CustomDropdownProps {
  options: string[];
  onSelect: (index: number) => void;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  options,
  onSelect,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<number | null>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (index: number) => {
    setSelectedOption(index);
    onSelect(index);
    toggleDropdown();
  };

  return (
    <div
      className="custom-dropdown"
      style={{ position: 'relative', width: '468px' }}
    >
      <button
        className="dropdown-button"
        style={{
          width: '100%',
          height: '52px',
          borderRadius: '8px',
          border: '1px solid #DCDCDC',
          padding: '0 12px',
          background: 'none',
          textAlign: 'left',
          position: 'relative',
        }}
        onClick={toggleDropdown}
      >
        {selectedOption !== null ? options[selectedOption] : 'Select an option'}
        <span
          style={{
            position: 'absolute',
            top: '50%',
            right: '12px',
            transform: 'translateY(-50%)',
          }}
        >
          {isOpen ? '▲' : <img src={arrowUp} alt="" />}
        </span>
      </button>
      {isOpen && (
        <div
          className="dropdown-options"
          style={{
            position: 'absolute',
            top: '100%',
            left: '0',
            width: '100%',
            background: '#FFF',
            borderRadius: '8px',
            border: '1px solid #DCDCDC',
            maxHeight: '160px',
            overflowY: 'auto',
            zIndex: '1',
          }}
        >
          {options.map((option, index) => (
            <div
              key={index}
              className="option"
              style={{
                padding: '8px 12px',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '19.36px',
                cursor: 'pointer',
              }}
              onClick={() => handleSelect(index)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default CustomDropdown;
