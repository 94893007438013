import { AnyAction, ThunkDispatch, createAsyncThunk } from '@reduxjs/toolkit';
import API from 'store/api';
import { setLoginUser } from 'store/Slice/authSlice';
import {
  stopLoading,
  startLoading,
  setBtnLoading,
} from 'store/Slice/loadingSlice';
import {
  notificationFail,
  notificationSuccess,
} from 'store/Slice/notificationSlice';
import { auth } from 'store/firebase';
import { updateParent } from './parentThunk';
import { Roles, generatePassword } from '@core/utils/global-mock';
import { updateStudent } from './studentThunk';
import { updateTeacher } from './teacherThunk';
import { addParentToClassRoom } from './classroomThunk';
import { getLinkPartFromRole } from 'helper/functions';
import { updateSchoolLeader } from './schoolLeaderThunk';

export const loginwithEmailPassword = createAsyncThunk(
  'login_email',
  async (req: any, { dispatch }) => {
    let url = `/api/v1/admin/login`;
    dispatch(startLoading());
    try {
      const response: any = await API.post(url, req);
      dispatch(setLoginUser(response?.data));
      localStorage.setItem('userData', JSON.stringify(response?.data));
      dispatch(notificationSuccess('Login success .!!'));
      dispatch(stopLoading());
      return response;
    } catch (err: any) {
      dispatch(notificationFail(err.response.data.message));
      dispatch(stopLoading());
      return err;
    }
  },
);

export const loginwithToken = createAsyncThunk(
  'verify',
  async (req: any, { dispatch }) => {
    let url = `/api/v1/verifyToken/${req?.token}`;
    dispatch(startLoading());
    try {
      const response: any = await API.get(url);
      dispatch(setLoginUser(response?.data?.data));
      localStorage.setItem('userData', JSON.stringify(response?.data?.data));
      localStorage.setItem('teacherSchool', req.schoolId[1] || '');
      dispatch(notificationSuccess('verify success .!!'));
      dispatch(stopLoading());
      return response;
    } catch (err: any) {
      dispatch(notificationFail(err.response.data.message));
      dispatch(stopLoading());
      dispatch(setLoginUser({}));
      localStorage.removeItem('userData');
      localStorage.removeItem('header');
      req?.navigate('/');
      return err;
    }
  },
);

async function createAndUpdateParent(
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  userInfo: any,
  firebaseId: string,
) {
  try {
    const response = await API.post('/api/v1/user', {
      email: userInfo.email,
      firebaseId,
      type: userInfo.role.toLowerCase(),
    });

    if (response.status === 201 || response.status === 200) {
      await dispatch(
        updateParent({ ...userInfo, uuid: response?.data?.data?.id }),
      );
      dispatch(notificationSuccess('User Created Successfully'));
    }
  } catch (error: any) {
    if (error.response && error.response.status === 409) {
      // Handle the case where the user already exists
      dispatch(notificationFail('User already exists'));
    } else {
      // Handle other errors
      dispatch(notificationFail(error.message));
    }
  }
}

async function createAndUpdateStudent(
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  userInfo: any,
  firebaseId: string,
) {
  try {
    const response = await API.post('/api/v1/user', {
      email: userInfo.email,
      firebaseId,
      type: userInfo.role.toLowerCase(),
    });

    if (response.status === 201 || response.status === 200) {
      await dispatch(
        updateStudent({ ...userInfo, uuid: response?.data?.data?.id }),
      );

      if (userInfo?.parentId) {
        await dispatch(
          addParentToClassRoom({
            userIds: [response?.data?.data?.id],
            parentId: userInfo?.parentId,
          }),
        );
      }

      dispatch(notificationSuccess('User Created Successfully'));
    }
  } catch (error: any) {
    if (error.response && error.response.status === 409) {
      dispatch(notificationFail('User already exists'));
    } else {
      dispatch(notificationFail(error.message));
    }
  }
}

async function createAndUpdateTeacher(
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  userInfo: any,
  firebaseId: string,
) {
  try {
    const response = await API.post('/api/v1/user', {
      email: userInfo.email,
      firebaseId,
      type: userInfo.teachMethod.toLowerCase(),
    });

    if (response.status === 201 || response.status === 200) {
      await dispatch(
        updateTeacher({ ...userInfo, uuid: response?.data?.data?.id }),
      );
      dispatch(notificationSuccess('User Created Successfully'));
    }
  } catch (error: any) {
    if (error.response && error.response.status === 409) {
      dispatch(notificationFail('User already exists'));
    } else {
      dispatch(notificationFail(error.message));
    }
  }
}
async function createAndUpdateSchoolLeader(
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  userInfo: any,
  firebaseId: string,
) {
  try {
    const response = await API.post('/api/v1/user', {
      email: userInfo.email,
      firebaseId,
      type: userInfo.role.toLowerCase(),
    });

    if (response.status === 201 || response.status === 200) {
      await dispatch(
        updateSchoolLeader({ ...userInfo, uuid: response?.data?.data?.id }),
      );
      dispatch(notificationSuccess('User Created Successfully'));
    }
  } catch (error: any) {
    if (error.response && error.response.status === 409) {
      dispatch(notificationFail('User already exists'));
    } else {
      dispatch(notificationFail(error.message));
    }
  }
}

export const createUserAtFirebaseAndSetAtBackend = createAsyncThunk(
  'verify',
  async ({ userInfo }: { userInfo: any }, { dispatch }) => {
    try {
      dispatch(setBtnLoading(true));

      const userCredential = await auth.createUserWithEmailAndPassword(
        userInfo.email,
        generatePassword(6),
      );

      const user = userCredential.user;
      if (user) {
        if (userInfo?.role === Roles.parent) {
          await createAndUpdateParent(dispatch, userInfo, user.uid);
          dispatch(setBtnLoading(false));
          navigateUser(1);
        }
        if (userInfo?.role === Roles.student) {
          await createAndUpdateStudent(dispatch, userInfo, user.uid);
          dispatch(setBtnLoading(false));
          navigateUser(2);
        }
        if (userInfo?.role === Roles.teacher) {
          await createAndUpdateTeacher(dispatch, userInfo, user.uid);
          dispatch(setBtnLoading(false));
          navigateUser(0);
        }
        if (userInfo?.role === Roles.leader) {
          await createAndUpdateSchoolLeader(dispatch, userInfo, user.uid);
          dispatch(setBtnLoading(false));
          navigateUser(3);
        }
      }
    } catch (error: any) {
      dispatch(setBtnLoading(false));
      if (error.code === 'auth/email-already-in-use') {
        dispatch(notificationFail('Email is already in use'));
      } else {
        dispatch(notificationFail(error.message));
      }
    }
  },
);

const navigateUser = (key: number) => {
  const initial = getLinkPartFromRole();

  setTimeout(() => {
    window.location.href = `/${initial}/user-management?tab=${key}`;
  }, 100);
};
