import React from 'react';

const Info = () => (
  <svg
    width="101"
    height="100"
    viewBox="0 0 101 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M101 50C101 77.6142 78.6142 100 51 100C23.3858 100 1 77.6142 1 50C1 22.3858 23.3858 0 51 0C78.6142 0 101 22.3858 101 50Z"
      fill="#C73E9D"
      fill-opacity="0.2"
    />
    <path
      d="M52.3772 46.9481C52.3149 46.6038 52.1259 46.2954 51.8475 46.0835C51.5692 45.8716 51.2215 45.7716 50.8731 45.8032C50.5247 45.8349 50.2007 45.9958 49.965 46.2543C49.7293 46.5128 49.599 46.8503 49.5996 47.2001V59.8057L49.622 60.0577C49.6843 60.402 49.8733 60.7104 50.1517 60.9223C50.4301 61.1342 50.7777 61.2342 51.1261 61.2026C51.4745 61.1709 51.7985 61.01 52.0342 60.7515C52.2699 60.493 52.4003 60.1555 52.3996 59.8057V47.2001L52.3772 46.9481ZM53.2368 40.9001C53.2368 40.3431 53.0156 39.809 52.6217 39.4152C52.2279 39.0213 51.6938 38.8001 51.1368 38.8001C50.5799 38.8001 50.0457 39.0213 49.6519 39.4152C49.2581 39.809 49.0368 40.3431 49.0368 40.9001C49.0368 41.4571 49.2581 41.9912 49.6519 42.385C50.0457 42.7788 50.5799 43.0001 51.1368 43.0001C51.6938 43.0001 52.2279 42.7788 52.6217 42.385C53.0156 41.9912 53.2368 41.4571 53.2368 40.9001ZM73.3996 50.0001C73.3996 44.0592 71.0396 38.3617 66.8388 34.1609C62.638 29.9601 56.9405 27.6001 50.9996 27.6001C45.0588 27.6001 39.3612 29.9601 35.1604 34.1609C30.9596 38.3617 28.5996 44.0592 28.5996 50.0001C28.5996 55.9409 30.9596 61.6385 35.1604 65.8393C39.3612 70.0401 45.0588 72.4001 50.9996 72.4001C56.9405 72.4001 62.638 70.0401 66.8388 65.8393C71.0396 61.6385 73.3996 55.9409 73.3996 50.0001ZM31.3996 50.0001C31.3996 47.4262 31.9066 44.8775 32.8916 42.4995C33.8766 40.1215 35.3203 37.9608 37.1403 36.1408C38.9603 34.3208 41.121 32.8771 43.499 31.8921C45.877 30.9071 48.4257 30.4001 50.9996 30.4001C53.5735 30.4001 56.1222 30.9071 58.5002 31.8921C60.8782 32.8771 63.0389 34.3208 64.8589 36.1408C66.6789 37.9608 68.1227 40.1215 69.1077 42.4995C70.0926 44.8775 70.5996 47.4262 70.5996 50.0001C70.5996 55.1983 68.5346 60.1837 64.8589 63.8594C61.1832 67.5351 56.1979 69.6001 50.9996 69.6001C45.8014 69.6001 40.816 67.5351 37.1403 63.8594C33.4646 60.1837 31.3996 55.1983 31.3996 50.0001Z"
      fill="#C73E9D"
    />
  </svg>
);

export default Info;
