// ** MUI Imports
import { IconButton, Box } from '@mui/material'
import MuxPlayer from '@mux/mux-player-react/lazy'

import CancelIcon from '@mui/icons-material/Cancel'

const MuxVideoPlayer = ({ assestId, playbackId, title, user, id, edit, handleRemoveVideo }: any) => {
  console.log(assestId, "assestId");
  console.log("edit", edit);
  return (
    <Box
      sx={{
        position: 'relative'
      }}
    >
      <MuxPlayer
        playbackId={playbackId}
        metadata={{
          video_id: id,
          video_title: title,
          viewer_user_id: user?.id || ""
        }}
        title={title}
        streamType="on-demand"
        style={{ aspectRatio: 16/9, width:"100%", height: "300px" }}
      />
      {
        edit && (
          <IconButton size="large" sx={{ position: 'absolute', top: 0, right: 0, color: 'white' }} onClick={handleRemoveVideo}>
            <CancelIcon fontSize="large" />
          </IconButton>
        )
      }
    </Box>
  )
}

export default MuxVideoPlayer
