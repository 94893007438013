import {
  Box,
  Typography,
  Button,
  Stack,
  Dialog,
  Grid,
  IconButton,
  Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { HazardIcon, SuccessIcon } from 'icons';
import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { getLocalDateTime } from 'helper/service';
import MuxVideoPlayer from 'components/fileUpload/MuxVideoPlayer';
import { Cancel, CancelOutlined } from '@mui/icons-material';
import { secondsToMinutes } from 'helper/secondsToMinutes';
import MuxPlayer from '@mux/mux-player-react/lazy';

interface DialogProps {
  open?: any;
  itemID: string;
  selectedVideo: any;
  onCancel: () => void;
  handleChangeStatus: (itemID: string, status: string) => void;
  onClose: (itemID: string) => void;
  onOk?: () => void;
}

function ViewVideo({
  onClose,
  onCancel,
  handleChangeStatus,
  itemID,
  open,
  onOk,
  selectedVideo,
}: DialogProps) {
  const { btnLoading } = useSelector((state: any) => state.assignContentSlice);
  const selectedInfo = localStorage.getItem('selectiveInfo');
  const [selectiveInfo, setSelectiveInfo] = useState<Record<string, any>>({});
  const [formData, setFormData] = useState<any>({
    grade: [],

    skill: [],
  });
  const handleRemoveVideo = (event: any) => {
    setFormData({ ...formData, assetId: '', playbackId: '' });
  };
  useEffect(() => {
    if (selectedInfo) {
      setSelectiveInfo(JSON.parse(selectedInfo));
    }
  }, [selectedInfo]);

  const playerRef = useRef(null);
  const [totalVideoDuration, setTotalVideoDuration] = useState(0);
  console.log('itemID', itemID);
  console.log('itemID', selectedVideo);
  return (
    <Dialog
      sx={{
        position: 'relative',
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '60vw',
            zIndex: '999999',
          },
        },
      }}
      fullWidth={true}
      maxWidth="sm"
      onClose={onClose}
      open={open}
      className="p-8"
    >
      <IconButton
        onClick={() => onClose('3')}
        sx={{ position: 'absolute', right: 8, top: 8 }}
        aria-label="delete"
      >
        <CloseIcon />
      </IconButton>
      <Stack p={8} sx={{ p: 5, gap: 5 }}>
        <Box mt={4} className="flex flex-col">
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 600,
              color: '#131515',
              lineHeight: '34px',
            }}
            mb={2}
          >
            {selectedVideo?.video?.title}
          </Typography>

          <Stack mt={8} gap={2}>
            <Grid container>
              <Grid item sm={12}>
                <Box
                  sx={{
                    position: 'relative',
                    height: '50vh',
                  }}
                >
                  <MuxPlayer
                    ref={playerRef}
                    startTime={Number(0)}
                    streamType="on-demand"
                    metadataVideoId={selectedVideo?.playbackId}
                    playbackId={selectedVideo?.playbackId}
                    onLoadedData={(e: any) => {
                      setTotalVideoDuration(e.target.duration);
                    }}
                    style={{ aspectRatio: 16 / 9, height: '100%' }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Stack>
          <Grid container mt={6}>
            <Grid item sm={3}>
              <Stack className="flex-col">
                <Typography className="!font-semibold !text-base !text-black">
                  School Name
                </Typography>
                <Typography className="!font-normal !text-base !text-gray-500">
                  {selectedVideo.school?.length
                    ? selectedVideo.school[0]?.school?.name || '-'
                    : '-'}
                </Typography>
              </Stack>
            </Grid>
            <Grid item sm={3}>
              <Stack className="flex-col">
                <Typography className="!font-semibold !text-base !text-black">
                  Date Posted
                </Typography>
                <Typography className="!font-normal !text-base !text-gray-500">
                  {getLocalDateTime(selectedVideo?.date, 'MMMM D, YYYY')}
                </Typography>
              </Stack>
            </Grid>
            <Grid item sm={3.5}>
              <Stack className="flex-col">
                <Typography className="!font-semibold !text-base !text-black">
                  Student
                </Typography>
                <Tooltip title={selectedVideo?.user?.id}>
                  <Stack className="!flex-row gap-2 justify-start items-center">
                    <img
                      src={selectedVideo?.user?.imageUrl}
                      className="w-8 h-8 rounded-full"
                      alt=""
                    />

                    <Typography className="!font-normal !text-base !text-gray-500">
                      {selectedVideo?.user?.name || ''}{' '}
                      {selectedVideo?.user?.lastname || ''}
                    </Typography>
                  </Stack>
                </Tooltip>
              </Stack>
            </Grid>
            <Grid item sm={2.5}>
              <Stack className="flex-col">
                <Typography className="!font-semibold !text-base !text-black">
                  Video Length
                </Typography>
                <Typography className="!font-normal !text-base !text-gray-500">
                  {totalVideoDuration
                    ? secondsToMinutes(totalVideoDuration)
                    : '0 min'}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>

        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '20px',
          }}
          className="mt-4"
        >
          <Button
            onClick={() => handleChangeStatus(selectedVideo.id, 'approved')}
            variant="contained"
            className="light_green_btn w-max"
            disabled={selectedVideo.approval === 'approved'}
          >
            Approve
          </Button>
          <Button
            onClick={() => handleChangeStatus(selectedVideo.id, 'rejected')}
            disabled={selectedVideo.approval === 'rejected'}
            variant="contained"
            className="dialog_red_button w-max"
          >
            Reject
          </Button>
        </div>
      </Stack>
    </Dialog>
  );
}

export default ViewVideo;
