import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, Card, Button } from '@mui/material';

import { CustomTabPanel, MuiTabs } from 'components/Tabs';
import AddIcon from '@mui/icons-material/Add';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Teacher from './user_tables/Teacher';
import Parent from './user_tables/Parent';
import Student from './user_tables/Student';
import { useLocation, useNavigate } from 'react-router-dom';
import { getLinkPartFromRole } from 'helper/functions';
import SchoolLeader from './user_tables/SchoolLeader';

function Index() {
  const [value, setValue] = useState(2);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [tab] = useState(queryParams.get('tab'));

  const Tabs = [
    {
      label: 'Teacher',
      value: 0,
      query: 'teacher',
    },
    {
      label: 'Parents',
      value: 1,
      query: 'parent',
    },
    {
      label: 'Students',
      query: 'student',
      value: 2,
    },
    {
      label: 'School Leader',
      query: 'leader',
      value: 3,
    },
  ];

  useEffect(() => {
    if (tab) {
      setValue(parseInt(tab));
    }
  }, [tab]);

  function setTabValue(tab: number) {
    setValue(tab);
    // update url
    var newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      `?tab=${tab}`;
    window.history.pushState({ path: newurl }, '', newurl);
  }

  const handleAddClick = () => {
    const selectedTab = Tabs.find((tab) => tab.value === value);
    if (selectedTab) {
      const selectedLabel = selectedTab.query;
      const intial = getLinkPartFromRole();
      const url = `/${intial}/user-management/create?mode=${selectedLabel.toLocaleLowerCase()}`;
      navigate(url);
    }
  };

  return (
    <Card sx={{ display: 'flex', gap: '20px', flexDirection: 'column', p: 5 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography fontWeight="500" fontSize="18px" color="#000">
          User Management
        </Typography>
        <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          {/* <Typography fontWeight="500" color="secondary">
            Download Sample
          </Typography> */}
          <Box sx={{ display: 'flex', gap: '10px' }}>
            {/* <Button
              endIcon={<FileUploadIcon style={{ color: '#000' }} />}
              variant="contained"
              className="gray_bg_btn"
            >
              Upload CSV
            </Button> */}
            <Button
              onClick={() => handleAddClick()}
              endIcon={<AddIcon style={{ color: '#ffff' }} />}
              variant="contained"
              className="primary_bg_btn"
            >
              Add User
            </Button>
          </Box>
        </Box>
      </Box>
      <Box>
        <MuiTabs value={value} setValue={setTabValue} tabsList={Tabs} />
        <CustomTabPanel index={0} value={value}>
          <Teacher />
        </CustomTabPanel>
        <CustomTabPanel index={1} value={value}>
          <Parent />
        </CustomTabPanel>
        <CustomTabPanel index={2} value={value}>
          <Student />
        </CustomTabPanel>
        <CustomTabPanel index={3} value={value}>
          <SchoolLeader />
        </CustomTabPanel>
      </Box>
    </Card>
  );
}

export default Index;
