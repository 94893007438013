import { AnyCnameRecord } from 'dns';
import { _LessonTypes } from './types';
import { listItemIconClasses } from '@mui/material';

function updateOrAddType(
  type: string,
  array: any,
  itemID: string,
  edit?: string,
) {
  if (edit) {
    return [{ type, ids: [itemID] }];
  }
  let updated = false;
  const updatedArray = array.map((item: any) => {
    if (item.type === type) {
      updated = true;
      return {
        ...item,
        ids: [...item.ids, itemID],
      };
    }
    return item;
  });

  if (!updated) {
    updatedArray.push({
      type,
      ids: [itemID],
    });
  }

  return updatedArray;
}

function removeItemByID(type: string, itemID: string, value: any) {
  return value.map((item: any) => {
    if (item.type === type && Array.isArray(item.ids)) {
      item.ids = item.ids.filter((id: string) => id !== itemID);
    }
    return item;
  });
}

function areAllIdsEmpty(data: any) {
  return data.every(
    (item: any) => Array.isArray(item.ids) && item.ids.length === 0,
  );
}

function itemExistsWithType(data: any, targetType: string, itemId: string) {
  const matched = data.some(
    (item: any) => item.type === targetType && item.ids.includes(itemId),
  );

  return matched;
}

const getFirstAvailableContent = (content: any, key: string) => {
  const availableContent: any =
    ['video', 'template', 'dailyChallenges'].find((type) => content[type]) ||
    {};

  const newKey = availableContent === 'dailyChallenges' ? 'topic' : key;
  if (
    content[availableContent] &&
    content[availableContent][newKey] &&
    content[availableContent][newKey][0]
  ) {
    return {
      text: content[availableContent]?.[newKey][0][newKey]?.name,
      title: _LessonTypes[availableContent],
      type: availableContent,
    };
  } else {
    return {
      text: '',
      title: _LessonTypes[availableContent],
      type: availableContent,
    };
  }
};

export const getLinkPartFromRole = () => {
  const userData: any = localStorage.getItem('userData');
  const userInfo = JSON.parse(userData);
  if (userInfo) {
    const role = userInfo?.type || '';
    if (role === 'school_leader') {
      return 'school-leader';
    } else if (role === 'super_admin') {
      return 'super-admin';
    }
  }
};

export const getLoggedInUser = () => {
  const userData: any = localStorage.getItem('userData');
  const userInfo = JSON.parse(userData);
  if (userInfo) {
    return userInfo;
  }
};

function searchBySkillOrStudent({ query, data, sortOrder = 'sd' }: any) {
  query = query.toLowerCase();

  const filteredData: Record<string, any> = {};

  const sortedData = Object.fromEntries(
    Object.entries(data).map(([studentId, studentData]: any) => {
      if (studentData.assignments) {
        studentData.assignments.sort((a: any, b: any) => {
          if (a.startDate && b.startDate) {
            if (sortOrder === 'sd') {
              return (
                new Date(a?.startDate).getTime() -
                new Date(b?.startDate).getTime()
              );
            }
            if (sortOrder === 'ed') {
              return (
                new Date(b?.startDate).getTime() -
                new Date(a?.startDate).getTime()
              );
            }
          }
          return 0;
        });
      }
      return [studentId, studentData];
    }),
  );

  Object.entries(sortedData).forEach(([key, item]: any) => {
    let studentMatched = false;

    const studentName =
      item.student?.user?.name.toLowerCase() ||
      item?.user?.name.toLowerCase() ||
      '';

    if (studentName.includes(query)) {
      studentMatched = true;
    }

    if (item.assignments) {
      const filteredAssignments: any[] = [];

      item.assignments.forEach((assignment: any) => {
        if (assignment.content) {
          const filteredContent: any[] = [];

          assignment.content.forEach((content: any) => {
            const contentSkills: string[] = [];

            if (content.video && content.video.skill) {
              content.video.skill.forEach((skill: any) => {
                if (
                  skill?.skill?.name &&
                  skill.skill.name.toLowerCase().includes(query)
                ) {
                  contentSkills.push(skill.skill.name.toLowerCase());
                }
              });
            }
            if (content.template && content.template.skill) {
              content.template.skill.forEach((skill: any) => {
                if (skill?.skill?.name) {
                  if (skill.skill.name.toLowerCase().includes(query)) {
                    contentSkills.push(skill.skill.name.toLowerCase());
                  }
                }
              });
            }
            if (content.dailyChallenges && content.dailyChallenges.topic) {
              content.dailyChallenges.topic.forEach((topic: any) => {
                if (
                  topic?.topic?.name &&
                  topic.topic.name.toLowerCase().includes(query)
                ) {
                  filteredContent.push(content);
                }
              });
            }

            if (contentSkills.length > 0) {
              filteredContent.push(content);
            }
          });

          if (filteredContent.length > 0) {
            // Include the matching content and assignment details
            const filteredAssignment: any = {
              ...assignment,
              content: filteredContent,
            };
            filteredAssignments.push(filteredAssignment);
          }
        }
      });

      if (studentMatched || filteredAssignments.length > 0) {
        const filteredItem: any = { ...item };
        if (filteredAssignments.length > 0) {
          filteredItem.assignments = filteredAssignments;
        }
        filteredData[key] = { ...filteredItem };
      }
    }
  });

  return filteredData;
}

function getUser() {
  const userData: any = localStorage.getItem('userData');
  const userInfo = JSON.parse(userData);
  return userInfo;
}

const assignmentStatusType = {
  todo: 'Not Started',
  progress: 'In Progress',
  completed: 'Completed',
};

export {
  assignmentStatusType,
  updateOrAddType,
  removeItemByID,
  areAllIdsEmpty,
  itemExistsWithType,
  getFirstAvailableContent,
  searchBySkillOrStudent,
  getUser,
};
