import React, { useState, ChangeEvent, DragEvent, useEffect } from 'react';
import {
  InputLabel,
  TextField,
  Typography,
  Button,
  Box,
  Paper,
} from '@mui/material';
import VideocamIcon from '@mui/icons-material/Videocam';
import ImageIcon from '@mui/icons-material/Image';
import DragableOption from 'pages/super_admin/content_management/quiz_template/question/addQuestion/DragableOptions';

const MediaUpload = (props: any) => {
  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files?.[0];
    props?.handleFileChange(event, props?.name, true);
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <div>
      <div>
        <Box>
          <Box
            sx={{
              borderRadius: props?.name === 'questionMedia' ? '8px' : '0px',
              border:
                props?.name === 'questionMedia' ? '1px solid #c8c8cf' : 'none',
              padding: '10px',
              textAlign: 'center',
              marginTop: 4,
            }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <input
              type="file"
              // accept="image/*, video/*"
              accept={props?.formData?.type === 'image' ? 'image/*' : 'video/*'}
              style={{ display: 'none' }}
              onChange={(event) => props?.handleFileChange(event, props?.name)}
              name={props?.name}
              id={`asset-input-${props?.name}`}
            />
            <label htmlFor={`asset-input-${props?.name}`} style={{}}>
              {/* {JSON.stringify(props?.name)} */}
              {props?.formData?.[`${props?.name}`] ? (
                <div>
                  {props?.formData?.type === 'image' ? (
                    <div>
                      <img
                        src={
                          props?.formData?.id === undefined
                            ? props?.formData?.[`${props?.name}`]?.contents
                            : props?.formData?.[`${props?.name}`]?.contents
                            ? props?.formData?.[`${props?.name}`]?.contents
                            : props?.formData?.[`${props?.name}`]
                        }
                        alt="Selected File"
                        style={{
                          width: '200px',
                          height: '200px',
                          marginTop: '10px',
                        }}
                      />
                    </div>
                  ) : (
                    <div style={{ padding: '15px', cursor: 'pointer' }}>
                      <video
                        key={props?.formData?.[`${props?.name}`]?.contents}
                        controls
                        width="150px"
                        height="200px"
                        // style={{ aspectRatio: 16 / 9, height: '200px' }}
                      >
                        <source
                          src={
                            props?.formData?.[`${props?.name}`]?.contents
                              ? props?.formData?.[`${props?.name}`]?.contents
                              : props?.formData?.[`${props?.name}`]
                          }
                          // type={`video/${props?.formData?.[`${props?.name}`]
                          //   ?.split('.')
                          //   ?.pop()
                          //   ?.toLowerCase()}`}
                          type={`video/mp4`}
                        />
                        <source
                          src={
                            props?.formData?.[`${props?.name}`]?.contents
                              ? props?.formData?.[`${props?.name}`]?.contents
                              : props?.formData?.[`${props?.name}`]
                          }
                          type={`video/ogg`}
                        />
                        <source
                          src={
                            props?.formData?.id === undefined
                              ? props?.formData?.[`${props?.name}`]?.contents
                              : props?.formData?.[`${props?.name}`]?.contents
                              ? props?.formData?.[`${props?.name}`]?.contents
                              : props?.formData?.[`${props?.name}`]
                          }
                          type={`video/webm`}
                        />
                        <source
                          src={
                            props?.formData?.id === undefined
                              ? props?.formData?.[`${props?.name}`]?.contents
                              : props?.formData?.[`${props?.name}`]?.contents
                              ? props?.formData?.[`${props?.name}`]?.contents
                              : props?.formData?.[`${props?.name}`]
                          }
                          type={`audio/mp3`}
                        />
                        <source
                          src={
                            props?.formData?.id === undefined
                              ? props?.formData?.[`${props?.name}`]?.contents
                              : props?.formData?.[`${props?.name}`]?.contents
                              ? props?.formData?.[`${props?.name}`]?.contents
                              : props?.formData?.[`${props?.name}`]
                          }
                          type={`audio/wav`}
                        />
                        Your browser does not support the video tag.
                      </video>
                      {/* <Box
                        sx={{
                          display: 'flex',
                          border: (theme) =>
                            `solid 1px ${theme.palette.divider}`,
                          borderRadius: '12px',
                          p: 2,
                        }}
                      >
                      
                        <img
                          src={previewImage}
                          width={'120px'}
                          height={'90px'}
                          style={{ borderRadius: '12px' }}
                        />
                        <Box sx={{ flex: 1, ml: 4 }}>
                          <Typography variant="subtitle1">
                            {file.name}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                          >
                            {`${Math.abs(file.size / 1048576).toFixed(2)}`} mb
                          </Typography>
                          
                        </Box>
                        <Box>
                          <IconButton
                            sx={{
                              // padding: 1.5,
                              fontSize: 16,
                            }}
                            onClick={() => onDeleteUploadFile(file)}
                          >
                            <CloseIcon sx={{ fontSize: 18 }} />
                          </IconButton>
                        </Box>
                      </Box> */}
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <Box
                    component="div"
                    sx={{
                      border: '2px dashed #c8c8cf',
                      borderRadius: '8px',
                      padding: '10px',
                      marginBottom: '10px',
                      cursor: 'pointer',
                    }}
                  >
                    {props?.formData?.type === 'image' ? (
                      <ImageIcon sx={{ fontSize: 30, color: '#C73E9D' }} />
                    ) : (
                      <VideocamIcon sx={{ fontSize: 30, color: '#C73E9D' }} />
                    )}
                    <Typography variant="body1">
                      {props?.formData?.type === 'image' ? (
                        <pre>
                          Click here to add asset or drag <br></br> &amp; drop
                          to add asset
                          <br></br> PNG , JPEG, Max size :2MB
                        </pre>
                      ) : props?.formData?.type === 'video' ? (
                        <pre>
                          Click here to add asset or drag <br></br> &amp; drop
                          to add asset
                          <br></br> MP4, AVI, Max size :2MB
                        </pre>
                      ) : (
                        <pre>
                          Click here to add asset or drag <br></br> &amp; drop
                          to add asset
                          <br></br> MP3, WAV, Max size :2MB
                        </pre>
                      )}
                    </Typography>
                  </Box>
                </>
              )}
            </label>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default MediaUpload;
