import React from "react";

export default function FileUploadIcon() {
  return (
    <svg
      width="48"
      height="44"
      viewBox="0 0 48 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.6263 3.9567C16.5124 1.46798 20.1934 0.0934933 24.0044 0.081543C31.772 0.081543 38.22 5.85674 38.9217 13.3039C43.5188 13.9536 47.1052 17.8027 47.1052 22.5269C47.1052 27.713 42.7796 31.8452 37.5386 31.8452H29.7796C29.3967 31.8452 29.0294 31.693 28.7587 31.4223C28.4879 31.1515 28.3358 30.7843 28.3358 30.4014C28.3358 30.0184 28.4879 29.6512 28.7587 29.3804C29.0294 29.1097 29.3967 28.9576 29.7796 28.9576H37.5414C41.2693 28.9576 44.2176 26.0353 44.2176 22.5269C44.2176 19.0155 41.2722 16.0933 37.5386 16.0933H36.0948V14.6495C36.0976 8.23902 30.7267 2.96914 24.0044 2.96914C20.8854 2.9816 17.8735 4.10811 15.5119 6.1455C13.326 8.02822 12.1825 10.2979 12.1825 12.0795V13.3732L10.8976 13.5147C6.86357 13.9565 3.79117 17.2685 3.79117 21.213C3.79117 25.4491 7.34291 28.9576 11.8216 28.9576H18.2292C18.6121 28.9576 18.9793 29.1097 19.2501 29.3804C19.5209 29.6512 19.673 30.0184 19.673 30.4014C19.673 30.7843 19.5209 31.1515 19.2501 31.4223C18.9793 31.693 18.6121 31.8452 18.2292 31.8452H11.8216C5.83559 31.8452 0.903564 27.1268 0.903564 21.213C0.903564 16.1222 4.55927 11.9063 9.39889 10.8379C9.81181 8.34586 11.4144 5.86252 13.6263 3.9567Z"
        fill="#8C979A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.9842 12.0536C23.1183 11.9191 23.2776 11.8124 23.453 11.7396C23.6284 11.6669 23.8165 11.6294 24.0064 11.6294C24.1963 11.6294 24.3843 11.6669 24.5597 11.7396C24.7351 11.8124 24.8945 11.9191 25.0286 12.0536L33.6914 20.7164C33.9625 20.9875 34.1148 21.3552 34.1148 21.7386C34.1148 22.122 33.9625 22.4897 33.6914 22.7608C33.4203 23.0319 33.0526 23.1842 32.6692 23.1842C32.2858 23.1842 31.9181 23.0319 31.647 22.7608L25.4502 16.5611V41.9518C25.4502 42.3347 25.2981 42.7019 25.0273 42.9727C24.7565 43.2435 24.3893 43.3956 24.0064 43.3956C23.6235 43.3956 23.2562 43.2435 22.9855 42.9727C22.7147 42.7019 22.5626 42.3347 22.5626 41.9518V16.5611L16.3658 22.7608C16.0947 23.0319 15.727 23.1842 15.3436 23.1842C14.9602 23.1842 14.5925 23.0319 14.3214 22.7608C14.0503 22.4897 13.8979 22.122 13.8979 21.7386C13.8979 21.3552 14.0503 20.9875 14.3214 20.7164L22.9842 12.0536Z"
        fill="#8C979A"
      />
    </svg>
  );
}
