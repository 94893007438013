import { createAsyncThunk } from '@reduxjs/toolkit';
import { Roles } from 'constants/consts';
import { getUser } from 'helper/functions';
import {
  setClassroomList,
  setClassroomByID,
  setClassroomBySchoolID,
  setClassRoomParents,
} from 'store/Slice/classroomSlice';
import {
  setBtnLoading,
  startLoading,
  stopLoading,
} from 'store/Slice/loadingSlice';
import {
  notificationFail,
  notificationSuccess,
} from 'store/Slice/notificationSlice';
import { setSchoolList } from 'store/Slice/schoolSlice';
import API from 'store/api';

export const addUpdateClassroom = createAsyncThunk(
  'addUpdateClassroom',
  async (formData: any, { dispatch }) => {
    console.log('@@@@@', formData);
    // /school/{schoolId}/classroom ,  /school/{schoolId}/classroom/{classroomId}
    let url = formData?.id
      ? `/api/v1/school/${formData?.schoolId}/classroom/${formData?.id}`
      : `/api/v1/school/${formData?.schoolId}/classroom`;
    try {
      if (formData?.id) {
        console.log('@@@@@', formData);
        let _request = {
          name: formData?.name || '',
          period: formData?.period || '',
          gradeId: formData?.gradeId || '',
          topicId: formData?.topic || '',
          userIds: formData?.classroomTeacher1Ids || [],
        };
        console.log('@@@@@', formData);
        await API.patch(url, _request);
        console.log('@@@@@', formData);
        dispatch(notificationSuccess('Classroom updated successfully'));
      } else {
        let _request = {
          name: formData?.name || '',
          period: formData?.period || '',
          topicId: formData?.topic || '',
          gradeId: formData?.gradeId || '',
          userIds: formData?.classroomTeacher1Ids || [],
        };
        console.log('@@@@@', formData);
        await API.post(url, _request);
        dispatch(notificationSuccess('Classroom created successfully'));
      }
      dispatch(setBtnLoading(false));
      //dispatch(getDistrictList({ pageSize: 10, pageIndex: 0 }));
    } catch (err: any) {
      dispatch(setBtnLoading(false));
      dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const getClassroomList = createAsyncThunk(
  'getClassroomList',
  async (_request: any, { dispatch }) => {
    const { id, type } = getUser();

    const gradeId = _request?.grade || '';
    const topicId = _request?.topic || '';
    const page = _request?.pageIndex || 0;
    const size = _request?.pageSize || 10;
    const search = _request?.search || '';
    const userId = type === Roles.TEACHER || type === Roles.TUTOR ? id : '';
    let url = `/api/v1/school/${
      _request?.id
    }/classroom?gradeId=${gradeId}&topicId=${topicId}&page=${
      page + 1
    }&limit=${size}`;
    dispatch(startLoading());
    try {
      if (search) {
        url += `&query=${search}`;
      }

      if (userId.length > 0) {
        url += `&userId=${userId}`;
      }

      const response: any = await API.get(url);

      dispatch(setClassroomList(response.data.data));
      dispatch(stopLoading());
      // dispatch(setDistrictList([]));
      return true;
    } catch (err: any) {
      dispatch(stopLoading());
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const getClassroomByID = createAsyncThunk(
  'getClassroomByID',
  async (_request: any, { dispatch }: any) => {
    let url = `/api/v1/school/${_request?.schoolId}/classroom/${_request?.id}`;
    try {
      const response: any = await API.get(url);

      dispatch(setClassroomByID(response.data.data));
      // dispatch(setDistrictList([]));
      return true;
    } catch (err: any) {
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const getClassroomBySchoolID = createAsyncThunk(
  'getClassroomBySchoolID',
  async (_request: any, { dispatch }: any) => {
    let url = `/api/v1/school/${_request?.id}/classroom?page=1&limit=10`;
    try {
      const response: any = await API.get(url);

      dispatch(setClassroomBySchoolID(response.data.data));
      dispatch(setClassroomList(response.data.data));

      // dispatch(setDistrictList([]));
      return true;
    } catch (err: any) {
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const deleteClassroom = createAsyncThunk(
  'deleteClassroom',
  async (_request: any, { dispatch }) => {
    // /school/{schoolId}/classroom/{classroomId} , /api/v1/school/${formData?.schoolId}/classroom/${formData?.id}
    // let url = `/api/v1/school/${id}`;
    let url = `/api/v1/school/${_request.schoolId}/classroom/${_request.id}`;
    try {
      await API.delete(url);
      dispatch(notificationSuccess('Classroom deleted successfully'));
      return true;
    } catch (err: any) {
      dispatch(notificationFail(err.response.data.message));
      return false;
    }
  },
);

interface addStudentToClassRoom {
  classId: string;
  studentIds: string[];
}

// ** ADD STUDENT TO THE CLASS ROOM
export const addStudentInClassRoom = createAsyncThunk(
  'addStudentInClassRoom',
  async (_request: addStudentToClassRoom, { dispatch }) => {
    let url = `/api/v1/classRoom/${_request.classId}/student/`;
    try {
      await API.post(url, { studentIds: _request.studentIds });
      dispatch(notificationSuccess('Student Added successfully'));
      return true;
    } catch (err: any) {
      if (err.response.data.statusCode == 409) {
        dispatch(notificationFail('One of the student is already Exists'));
      } else {
        dispatch(notificationFail(err.response.data.message));
      }
      return err;
    }
  },
);

interface addTeacherToClassRoom {
  classId: string;
  teacherIds: string;
}

export const addTeacherToClassRoom = createAsyncThunk(
  'addTeacherToClassRoom',
  async (_request: addTeacherToClassRoom, { dispatch }) => {
    let url = `/api/v1/classRoom/${_request.classId}/teacher/`;
    try {
      await API.post(url, { teacherIds: _request.teacherIds });
      dispatch(notificationSuccess('Teacher added successfully'));
      return true;
    } catch (err: any) {
      if (err.response.data.statusCode == 409) {
        dispatch(notificationFail('One of the Teacher is already Exists'));
      } else {
        dispatch(notificationFail(err.response.data.message));
      }
      return err;
    }
  },
);

interface addParentToClassRoom {
  parentId: string;
  userIds: string[];
}

export const addParentToClassRoom = createAsyncThunk(
  'addParentToClassRoom',
  async (_request: addParentToClassRoom, { dispatch }) => {
    console.log('request', _request);

    let url = `/api/v1/parents/${_request.parentId}/children`;
    try {
      await API.post(url, _request);
      dispatch(notificationSuccess('Parent added successfully'));
      return true;
    } catch (err: any) {
      if (err.response.data.statusCode == 409) {
        dispatch(notificationFail('One of the Teacher is already Exists'));
      } else {
        dispatch(notificationFail(err.response.data.message));
      }
      return err;
    }
  },
);

export const getClassroomsOfStudent = createAsyncThunk(
  'getUserClassRooms',
  async (id: string, { dispatch }) => {
    let url = `/api/v1/classroom/student/${id}`;
    try {
      const response = await API.get(url);
      return response?.data?.data;
    } catch (err) {
      return err;
    }
  },
);

export const getParentsListOfClass = createAsyncThunk(
  'getClassroomList',
  async (_request: any, { dispatch }) => {
    const page = _request?.pageIndex || 0;
    const size = _request?.pageSize || 10;
    const search = _request?.search || '';
    let url = `/api/v1/parents/classroom/${_request?.id}?page=${
      page + 1
    }&limit=${size}`;
    dispatch(startLoading());
    try {
      if (search) {
        url += `&query=${search}`;
      }
      const response: any = await API.get(url);
      dispatch(setClassRoomParents(response.data.data));
      dispatch(stopLoading());
      return true;
    } catch (err: any) {
      dispatch(stopLoading());
      return err;
    }
  },
);

interface RemoveUserFromClassRoomRequest {
  classRoomId: string;
  typeToRemove: string;
  payload: any;
}

export const removeUserFromClassRoom = createAsyncThunk(
  'removeUserFromClassRoom',
  async (
    { classRoomId, typeToRemove, payload }: RemoveUserFromClassRoomRequest,
    { dispatch },
  ) => {
    console.log('payload', payload);
    try {
      const url = `/api/v1/classroom/${classRoomId}/${typeToRemove}`;
      const response: any = await API.delete(url, { data: payload });
      console.log('RESPONSE', response);
      return response;
    } catch (err: any) {
      dispatch(stopLoading());
      return err;
    }
  },
);
