import React from 'react';

export default function ViewIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_885_20330)">
        <path
          d="M18.8003 9.50637L18.8003 9.50636L18.7996 9.5044C18.0894 7.66737 16.8565 6.07871 15.2532 4.93476C13.65 3.79081 11.7467 3.14172 9.77856 3.0677L9.77857 3.06743L9.77136 3.0677C7.80323 3.14172 5.89992 3.79081 4.29667 4.93476C2.69342 6.07871 1.46052 7.66737 0.75033 9.5044L0.750308 9.50439L0.749593 9.50637C0.694485 9.6588 0.694484 9.82571 0.749593 9.97814L0.749571 9.97814L0.75033 9.98011C1.46052 11.8171 2.69342 13.4058 4.29667 14.5497C5.89992 15.6937 7.80323 16.3428 9.77136 16.4168L9.77135 16.4171L9.77856 16.4168C11.7467 16.3428 13.65 15.6937 15.2532 14.5497C16.8565 13.4058 18.0894 11.8171 18.7996 9.98012L18.8003 9.97814C18.8554 9.82571 18.8554 9.6588 18.8003 9.50637ZM9.77496 15.0293C6.65879 15.0293 3.35721 12.7231 2.14493 9.74225C3.35721 6.76142 6.65879 4.45519 9.77496 4.45519C12.8911 4.45519 16.1927 6.76142 17.405 9.74225C16.1927 12.7231 12.8911 15.0293 9.77496 15.0293Z"
          fill="#20A58A"
          stroke="#20A58A"
          stroke-width="0.191387"
        />
        <path
          d="M9.77429 6.05763C9.04572 6.05763 8.33352 6.27367 7.72773 6.67844C7.12195 7.08321 6.6498 7.65853 6.37099 8.33164C6.09218 9.00475 6.01923 9.74542 6.16137 10.46C6.30351 11.1746 6.65434 11.8309 7.16952 12.3461C7.68469 12.8613 8.34107 13.2121 9.05564 13.3543C9.7702 13.4964 10.5109 13.4234 11.184 13.1446C11.8571 12.8658 12.4324 12.3937 12.8372 11.7879C13.2419 11.1821 13.458 10.4699 13.458 9.74133C13.458 8.76435 13.0699 7.82739 12.3791 7.13656C11.6882 6.44573 10.7513 6.05763 9.77429 6.05763ZM9.77429 12.0376C9.32012 12.0376 8.87615 11.903 8.49853 11.6506C8.1209 11.3983 7.82658 11.0397 7.65277 10.6201C7.47897 10.2005 7.4335 9.73878 7.5221 9.29334C7.6107 8.8479 7.82941 8.43874 8.15055 8.11759C8.4717 7.79645 8.88086 7.57774 9.3263 7.48914C9.77174 7.40054 10.2335 7.44601 10.6531 7.61981C11.0726 7.79362 11.4313 8.08794 11.6836 8.46557C11.9359 8.84319 12.0706 9.28716 12.0706 9.74133C12.0706 10.3504 11.8287 10.9344 11.398 11.3651C10.9674 11.7957 10.3833 12.0376 9.77429 12.0376Z"
          fill="#20A58A"
          stroke="#20A58A"
          stroke-width="0.191387"
        />
      </g>
      <defs>
        <clipPath id="clip0_885_20330">
          <rect
            width="19.1387"
            height="19.1387"
            fill="white"
            transform="translate(0.205566 0.172852)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
