// *** React Imports
import React, { useEffect, useState } from 'react';

// *** Material UI Imports
import {
  Box,
  Grid,
  TextField,
  Autocomplete,
  InputLabel,
  CircularProgress,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  createFilterOptions,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
  Stack,
  List,
  Chip,
  Checkbox,
  Paper,
  Divider,
} from '@mui/material';

import MDDialog from '@core/components/MDDialog';

/* mui-color-input' */
import { MuiColorInput } from 'mui-color-input';
import {
  getGradeList,
  getSkillList,
  getSubTopicList,
  getTeacherList,
  getTopicList,
  getVideoList,
} from 'store/Thunk/videoThunk';
import { useAppDispatch, useAppSelector } from 'store/store';
import Validations from 'helper/validations';
import { setBtnLoading } from 'store/Slice/loadingSlice';
import {
  addUpdateTemplate,
  getQuizTemplateById,
} from 'store/Thunk/templateThunk';
import { getAuthUser, getOptionLabel } from 'helper/service';
import { setQuizTemplateById } from 'store/Slice/templateSlice';

import AddSkill from 'components/dialog/skill/addSkill';
import AddSubTopic from 'components/dialog/subtopic/addSubTopic';
import AddDomain from 'components/dialog/domain/addDomain';
import AddTopic from 'components/dialog/topic/addTopic';
import {
  getDistrictList,
  getSchoolsInDistricts,
} from 'store/Thunk/districtThunk';
import { getSchoolList } from 'store/Thunk/schoolThunk';
import { notificationFail } from 'store/Slice/notificationSlice';
import SelectDistrictAndSchool from 'components/selectDistrictAndSchool';
import { getDomainList } from 'store/Thunk/userThunk';

const filter = createFilterOptions();

function Index({ editId, open, onClose }: any) {
  const dispatch: any = useAppDispatch();
  const {
    teacherList,
    topicList,
    subtopicList,
    skillList,
    gradeList,
    videoList,
  } = useAppSelector((state: any) => state.videoReducer);

  const authUser = getAuthUser();

  const { districtList } = useAppSelector(
    (state: any) => state.districtReducer,
  );
  const { domainList } = useAppSelector((state: any) => state.userReducer);
  const { schoolList } = useAppSelector((state: any) => state.schoolReducer);
  const { btnLoading, screenLoading } = useAppSelector(
    (state: any) => state.loadingReducer,
  );
  const { quizTemplate } = useAppSelector(
    (state: any) => state.templateReducer,
  );

  const [formData, setFormData] = useState<any>({
    grade: [],
    school: [],
  });
  useEffect(() => {
    setFormData({ ...formData, school: schoolList });
  }, [schoolList]);
  useEffect(() => {
    setFormData({});
  }, []);
  const [formError, setFormError] = useState<any>({});
  const [loadingDistrict, setLoadingDistrict] = React.useState(false);
  const [loadingSchool, setLoadingSchool] = React.useState(false);
  const [screenLoader, setScreenLoader] = React.useState(false);
  const [loadingTeacher, setLoadingTeacher] = React.useState(false);
  const [loadingTopic, setLoadingTopic] = React.useState(false);
  const [loadingDomain, setLoadingDomain] = React.useState(false);
  const [loadingSubTopic, setLoadingSubTopic] = React.useState(false);
  const [loadingGrade, setLoadingGrade] = React.useState(false);
  const [loadingSkill, setLoadingSkill] = React.useState(false);
  const [loadingVideo, setLoadingVideo] = React.useState(false);
  const [openDistrict, setOpenDistrict] = React.useState(false);
  const [openDomain, setOpenDomain] = React.useState(false);
  const [openSchool, setOpenSchool] = React.useState(false);
  const [openTeacher, setOpenTeacher] = React.useState(false);
  const [openTopic, setOpenTopic] = React.useState(false);
  const [openSubTopic, setOpenSubTopic] = React.useState(false);
  const [openGrade, setOpenGrade] = React.useState(false);
  const [openSkill, setOpenSkill] = React.useState(false);
  const [openVideo, setOpenVideo] = React.useState(false);
  const [district, setDistrict] = React.useState<any[]>([]);
  const [school, setSchool] = React.useState<any[]>([]);
  const [teacher, setTeacher] = React.useState<any[]>([]);
  const [topic, setTopic] = React.useState<any[]>([]);
  const [subTopic, setSubTopic] = React.useState<any[]>([]);
  const [grade, setGrade] = React.useState<any[]>([]);
  const [skill, setSkill] = React.useState<any[]>([]);
  const [video, setVideo] = React.useState<any[]>([]);

  const [openTopicDialog, setOpenTopicDialog] = React.useState(false);
  const [openDomainDialog, setOpenDomainDialog] = React.useState(false);
  const [openSubTopicDialog, setOpenSubTopicDialog] = React.useState(false);
  const [openSkillDialog, setOpenSkillDialog] = React.useState(false);
  const [newSubTopicValue, setNewSubTopicValue] = React.useState({});
  const [newTopicValue, setNewTopicValue] = React.useState({});
  const [newDomainValue, setNewDomainValue] = React.useState({});
  const [newSkillValue, setNewSkillValue] = React.useState({});

  useEffect(() => {
    dispatch(setQuizTemplateById({}));
    if (editId) {
      dispatch(getQuizTemplateById({ id: editId }));
    }
  }, [editId]);

  useEffect(() => {
    setScreenLoader(screenLoading);
  }, [screenLoading]);
  console.log('quizTemplate', quizTemplate);
  console.log('quizTemplate', formData);
  useEffect(() => {
    const Allvideos: Array<any> = quizTemplate?.video?.map(
      (item: any) => item.video,
    );
    setTimeout(() => {
      if (
        quizTemplate &&
        Object.entries(quizTemplate).length &&
        quizTemplate.constructor === Object &&
        editId
      ) {
        setFormData({
          ...formData,
          // type: quizTemplate?.type || '',
          title: quizTemplate?.title || '',
          colorCode: quizTemplate?.colorCode || '',
          district: quizTemplate?.district.map((item: any) => item?.district),
          school: quizTemplate?.school.map((item: any) => item?.school),
          topic: quizTemplate?.topic[0]?.topic || '',
          domain: quizTemplate?.domain || '',
          sub_topic: quizTemplate?.subTopic[0]?.subTopic || '',
          grade: quizTemplate?.grade?.map((obj: any) => obj.grade),
          skill: quizTemplate?.skill[0]?.skill || '',
          video: Allvideos,
          teacher: quizTemplate?.teacher[0]?.teacher || '',
        });
      }
    }, 1200);
  }, [quizTemplate]);

  useEffect(() => {
    if (openDistrict) {
      dispatch(getDistrictList({ pageIndex: 0, pageSize: 1000 }));
      setLoadingDistrict(openDistrict);
    } else {
      setDistrict([]);
    }
  }, [openDistrict]);
  useEffect(() => {
    openDomain && dispatch(getDomainList({ topicId: formData?.topic?.id }));
  }, [openDomain]);
  useEffect(() => {
    if (openTeacher) {
      dispatch(getTeacherList());
      setLoadingTeacher(openTeacher);
    } else {
      setTeacher([]);
    }
  }, [openTeacher]);

  useEffect(() => {
    if (openTopic) {
      dispatch(getTopicList());
      setLoadingTopic(openTopic);
    } else {
      setTopic([]);
    }
  }, [openTopic]);

  useEffect(() => {
    dispatch(
      getVideoList({
        pageIndex: 0,
        pageSize: 2000,
        search: '',
        isAcademic: true,
      }),
    );
    setLoadingVideo(openVideo);
  }, []);

  useEffect(() => {
    if (formData?.topic?.id && openSubTopic) {
      dispatch(getSubTopicList({ topic: formData?.topic }));
      setLoadingSubTopic(openSubTopic);
    } else {
      setSubTopic([]);
    }
  }, [openSubTopic, formData?.topic]);

  useEffect(() => {
    if (openGrade) {
      dispatch(getGradeList());
      setLoadingGrade(openGrade);
    } else {
      setGrade([]);
    }
  }, [openGrade]);

  useEffect(() => {
    if (formData?.sub_topic?.id && openSkill) {
      dispatch(getSkillList({ subTopic: formData?.sub_topic }));
      setLoadingSkill(openSkill);
    } else {
      setSkill([]);
    }
  }, [openSkill, formData?.sub_topic]);

  useEffect(() => {
    setLoadingDistrict(false);
    if (districtList && districtList?.items) {
      setDistrict(districtList.items);
    } else {
      setDistrict([]);
    }
  }, [districtList]);

  useEffect(() => {
    setLoadingSchool(false);
    if (schoolList && schoolList?.items) {
      setSchool(schoolList.items);
    } else {
      setSchool(schoolList);
    }
  }, [schoolList]);

  useEffect(() => {
    setLoadingTeacher(false);
    setTeacher(teacherList);
  }, [teacherList]);

  useEffect(() => {
    setLoadingTopic(false);
    setTopic(topicList);
  }, [topicList]);

  useEffect(() => {
    setLoadingSubTopic(false);
    setSubTopic(subtopicList);
  }, [subtopicList]);

  useEffect(() => {
    setLoadingGrade(false);
    setGrade(gradeList);
  }, [gradeList]);

  useEffect(() => {
    setLoadingSkill(false);
    setSkill(skillList);
  }, [skillList]);

  useEffect(() => {
    setLoadingVideo(false);
    setVideo(videoList?.items || []);
  }, [videoList]);

  const handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleChangeAutocomplete =
    (name: string) =>
    (
      event: React.SyntheticEvent<Element, Event>,
      newValue: string | null | any,
    ) => {
      let param = {
        [name]: newValue,
        ...(name === 'topic' && {
          sub_topic: '',
          skill: '',
        }),
        ...(name === 'sub_topic' && {
          skill: '',
        }),
        ...(name === 'district' && {
          school: [],
        }),
      };
      setFormData({ ...formData, ...param });
    };

  const handleChangeColor = (event: SelectChangeEvent<any>) => {
    const newValue = event.target.value as string;
    setFormData({ ...formData, colorCode: newValue });
  };

  // Autocomplete onChange method for select and create a options
  const handleChangeCreateOptions =
    (name: string) =>
    (
      event: React.SyntheticEvent<Element, Event>,
      newValue: string | null | any,
    ) => {
      if (typeof newValue === 'string') {
        // timeout to avoid instant validation of the dialog's form.
        setTimeout(() => {
          handleSkillDialog(newValue);
        });
      } else if (newValue && newValue.inputValue) {
        handleSkillDialog(newValue.inputValue);
      } else {
        setFormData({
          ...formData,
          skill: newValue,
        });
      }
    };

  const handleFilterOptions = (options: any, params: any) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some(
      (option: any) => inputValue === option.name,
    );
    if (inputValue !== '' && !isExisting && formData?.sub_topic) {
      filtered.push({
        inputValue,
        name: `Add "${inputValue}"`,
      });
    }

    return filtered;
  };

  // Autocomplete onChange method for select and create a options
  const handleChangeCreateSubTopic = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string | null | any,
  ) => {
    if (typeof newValue === 'string') {
      // timeout to avoid instant validation of the dialog's form.
      setTimeout(() => {
        handleSubTopicDialog(newValue);
      });
    } else if (newValue && newValue.inputValue) {
      handleSubTopicDialog(newValue.inputValue);
    } else {
      setFormData({
        ...formData,
        sub_topic: newValue,
      });
    }
  };

  const handleFilterSubTopic = (options: any, params: any) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some(
      (option: any) => inputValue === option.name,
    );
    if (inputValue !== '' && !isExisting && formData?.topic) {
      filtered.push({
        inputValue,
        name: `Add "${inputValue}"`,
      });
    }

    return filtered;
  };

  const handleSkillDialog = (name: string = '') => {
    if (!formData.topic && !formData.sub_topic) {
      dispatch(notificationFail('Please Select Topic and Sub Topic First'));
      return;
    }
    if (!formData.topic || !formData.sub_topic) {
      const message = !formData.topic
        ? 'Please Select Topic First'
        : 'Please Select SubTopic First';

      dispatch(notificationFail(message));
      return;
    }
    setNewSkillValue({
      ...(!openSkillDialog
        ? {
            topic: formData.topic,
            name: name,
            subTopic: formData.sub_topic,
          }
        : {}),
    });
    setOpenSkillDialog(!openSkillDialog);
  };

  const handleSubTopicDialog = (name: string = '') => {
    if (!formData.topic) {
      dispatch(notificationFail('Please Select Topic First'));
      return;
    }
    setNewSubTopicValue({
      ...(!openSubTopicDialog
        ? {
            topic: formData.topic,
            name: name,
          }
        : {}),
    });
    setOpenSubTopicDialog(!openSubTopicDialog);
  };
  const handleTopicDialog = (name: string = '') => {
    setNewTopicValue({
      ...(!openTopicDialog
        ? {
            topic: formData.topic,
            name: name,
          }
        : {}),
    });
    setOpenTopicDialog(!openTopicDialog);
  };
  const handleDomainDialog = (name: string = '') => {
    setNewDomainValue({
      ...(!openDomainDialog
        ? {
            topic: formData.topic,
            name: name,
          }
        : {}),
    });
    setOpenDomainDialog(!openDomainDialog);
  };

  const idArray: string[] =
    formData?.video?.length && formData?.video?.map((video: any) => video.id);

  const onSave = (event: any) => {
    event.preventDefault();
    let _request: any = {
      id: editId || '',
      // type: formData?.type || '',
      title: formData?.title || '',
      colorCode: formData?.colorCode || '',
      // teacherId: formData?.teacher?.id || '',
      districtIds:
        (Array.isArray(formData?.district) &&
          formData?.district?.map((item: any) => item.id)) ||
        [],
      schoolIds:
        Array.isArray(formData?.school) && formData?.school?.length > 0
          ? formData?.school.map((item: any) => item.id)
          : [],
      topicId: formData?.topic?.id || '',
      subTopicId: formData?.sub_topic?.id || '',
      skillId: formData?.skill?.id || '',
      createdById: authUser?.id,
      gradeIds: formData?.grade.map((grade: any) => grade?.id),
      videoIds: idArray || [],
      domainId: formData?.domain?.id || '',
    };
    let allError = Validations.validateQuizTemplateForm(_request);
    if (
      Object.entries(allError).length === 0 &&
      allError.constructor === Object
    ) {
      dispatch(setBtnLoading(true));
      dispatch(addUpdateTemplate(_request));
      onClose();
    } else {
      setFormError(allError);
    }
  };

  const dialogProps = {
    open,
    onClose,
    onSave,
    title: editId ? 'Edit an Assessment' : 'Create a new Assessment',
    size: 'md',
    saveTbtText: editId ? 'Edit an Assessment' : 'Create a new Assessment',
    loading: btnLoading,
    screenLoader,
  };

  return (
    <MDDialog {...dialogProps}>
      <Box sx={{ p: 3 }}>
        <Grid
          container
          spacing={6}
          sx={{
            mb: 7,
            '& .MuiTypography-root:hover': { textDecoration: 'underline' },
          }}
        >
          {/* <Grid item xs={12}>
            <FormControl error={formError && formError?.type}>
              <FormLabel>Quiz Template Type</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="type"
                value={formData?.type?.toString() || ''}
                onChange={handleChangeInput}
              >
                <FormControlLabel
                  value="text"
                  control={<Radio />}
                  label="Text"
                />
                <FormControlLabel
                  value="video"
                  control={<Radio />}
                  label="Video"
                />
                <FormControlLabel
                  value="image"
                  control={<Radio />}
                  label="Image"
                />
                <FormControlLabel
                  value="audio"
                  control={<Radio />}
                  label="Audio"
                />
              </RadioGroup>
              {formError && formError?.type && (
                <FormHelperText error id="password-error">
                  {formError?.type}
                </FormHelperText>
              )}
            </FormControl>
          </Grid> */}
          <Grid item xs={12}>
            <InputLabel
              error={formError && formError?.title}
              sx={{ color: '#484848', pb: 2 }}
            >
              Assessment Title
            </InputLabel>
            <TextField
              required
              fullWidth
              id="title"
              value={formData?.title || ''}
              onChange={handleChangeInput}
              placeholder="Write your header title here"
              name="title"
              error={formError && formError?.title}
              helperText={formError && formError?.title ? formError.title : ''}
            />
          </Grid>
          <Grid item xs={12}>
            {/* <MDEditor
                            value={formData?.editorValue || ""}
                            onChange={handleChangeEditor}
                            modules={constants.editorModules}
                            formats={constants.editorFormats}
                        /> */}
          </Grid>
          <Grid item xs={6}>
            <InputLabel
              error={formError && formError?.colorCode}
              sx={{ color: '#484848', pb: 2 }}
            >
              Background Color
            </InputLabel>

            <Select
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={''}
              label="color"
              onChange={handleChangeColor}
              style={{ backgroundColor: formData?.colorCode || '#ffffff' }}
            >
              <MenuItem value={'#ffffff'}>white</MenuItem>
              <MenuItem value={'#5EB1FF'}>#5EB1FF</MenuItem>
              <MenuItem value={'#FFC240'}>#FFC240</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={6}>
            <InputLabel
              error={formError && formError?.video}
              sx={{ color: '#484848', pb: 2 }}
            >
              Video
            </InputLabel>
            <Autocomplete
              multiple
              id="asynchronous-demo"
              open={openVideo}
              onOpen={() => setOpenVideo(true)}
              onClose={() => setOpenVideo(false)}
              isOptionEqualToValue={(option, value) =>
                option.title === value.title
              }
              getOptionLabel={(option) => option?.title || ''}
              options={video}
              loading={loadingVideo}
              value={formData?.video?.length ? formData?.video : [] || [] || {}}
              onChange={handleChangeAutocomplete('video')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Video"
                  error={formError && formError?.video}
                  helperText={
                    formError && formError?.video ? formError.video : ''
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingVideo ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <InputLabel
              error={formError && formError?.teacher}
              sx={{ color: '#484848', pb: 2 }}
            >
              Teacher
            </InputLabel>
            <Autocomplete
              id="asynchronous-demo"
              open={openTeacher}
              onOpen={() => setOpenTeacher(true)}
              onClose={() => setOpenTeacher(false)}
              isOptionEqualToValue={(option, value) =>
                option.email === value.email
              }
              getOptionLabel={(option) => option?.email || ''}
              options={teacher}
              loading={loadingTeacher}
              filterSelectedOptions
              value={formData?.teacher || {}}
              onChange={handleChangeAutocomplete('teacher')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Teacher"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingTeacher ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  error={formError && formError?.teacher}
                  helperText={
                    formError && formError?.teacher ? formError.teacher : ''
                  }
                />
              )}
            />
          </Grid> */}
          <Grid item xs={12}>
            <InputLabel
              error={formError && formError?.grade}
              sx={{ color: '#484848', pb: 2 }}
            >
              Grade
            </InputLabel>
            <Autocomplete
              multiple
              id="asynchronous-demo"
              open={openGrade}
              onOpen={() => setOpenGrade(true)}
              onClose={() => setOpenGrade(false)}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              getOptionLabel={(option) => option?.name || ''}
              options={grade}
              loading={loadingGrade}
              value={formData?.grade || []}
              onChange={handleChangeAutocomplete('grade')}
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Grade"
                  error={formError && formError?.grade}
                  helperText={
                    formError && formError?.grade ? formError.grade : ''
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingGrade ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <SelectDistrictAndSchool
            state={formData}
            formError={formError}
            setState={setFormData}
          />
          <Grid item xs={4}>
            <Stack direction="row" gap="10px" textAlign="center">
              <InputLabel sx={{ color: '#484848', pb: 2 }}>Topic -</InputLabel>
              <Typography
                fontSize="14px"
                color="secondary"
                sx={{ cursor: 'pointer' }}
                onClick={() => handleTopicDialog()}
              >
                Add new
              </Typography>
            </Stack>
            <Autocomplete
              id="asynchronous-demo"
              open={openTopic}
              onOpen={() => setOpenTopic(true)}
              onClose={() => setOpenTopic(false)}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              getOptionLabel={(option) => option?.name || ''}
              options={topic}
              loading={loadingTopic}
              filterSelectedOptions
              value={formData?.topic || {}}
              onChange={handleChangeAutocomplete('topic')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Topic"
                  error={formError && formError?.topic}
                  helperText={
                    formError && formError?.topic ? formError.topic : ''
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingTopic ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Stack direction="row" gap="10px" textAlign="center">
              <InputLabel sx={{ color: '#484848', pb: 2 }}>
                Domain -{' '}
              </InputLabel>
              <Typography
                fontSize="14px"
                color="secondary"
                sx={{ cursor: 'pointer' }}
                onClick={() => handleDomainDialog()}
              >
                Add new
              </Typography>
            </Stack>
            <Autocomplete
              id="asynchronous-demo"
              open={openDomain}
              disabled={!formData?.topic}
              onOpen={() => setOpenDomain(true)}
              onClose={() => setOpenDomain(false)}
              isOptionEqualToValue={(option, value) =>
                option?.name === value?.name
              }
              getOptionLabel={(option) => option?.name || ''}
              options={domainList?.length > 0 ? domainList : []}
              loading={loadingDomain}
              filterSelectedOptions
              value={formData?.domain || {}}
              onChange={handleChangeAutocomplete('domain')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Domain"
                  error={formError && formError?.domain}
                  helperText={
                    formError && formError?.domain ? formError.domain : ''
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingDomain ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" gap="10px" textAlign="center">
              <InputLabel sx={{ color: '#484848', pb: 2 }}>
                Sub-Topic -
              </InputLabel>
              <Typography
                fontSize="14px"
                color="secondary"
                sx={{ cursor: 'pointer' }}
                onClick={() => handleSubTopicDialog()}
              >
                Add new
              </Typography>
            </Stack>
            <Autocomplete
              id="asynchronous-demo"
              open={openSubTopic}
              onOpen={() => setOpenSubTopic(true)}
              onClose={() => setOpenSubTopic(false)}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              getOptionLabel={getOptionLabel}
              options={subTopic}
              loading={loadingSubTopic}
              value={formData?.sub_topic || {}}
              onChange={handleChangeCreateSubTopic}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Sub topic"
                  error={formError && formError?.sub_topic}
                  helperText={
                    formError && formError?.sub_topic ? formError.sub_topic : ''
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingSubTopic ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
              filterOptions={handleFilterSubTopic}
            />
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row" gap="10px" textAlign="center">
              <InputLabel sx={{ color: '#484848', pb: 2 }}>Skill -</InputLabel>
              <Typography
                fontSize="14px"
                color="secondary"
                sx={{ cursor: 'pointer' }}
                onClick={() => handleSkillDialog()}
              >
                Add new
              </Typography>
            </Stack>
            <Autocomplete
              id="asynchronous-demo"
              open={openSkill}
              onOpen={() => setOpenSkill(true)}
              onClose={() => setOpenSkill(false)}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              getOptionLabel={getOptionLabel}
              options={skill}
              loading={loadingSkill}
              value={formData?.skill || {}}
              onChange={handleChangeCreateOptions('skill')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Skill"
                  error={formError && formError?.skill}
                  helperText={
                    formError && formError?.skill ? formError.skill : ''
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingSkill ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
              filterOptions={handleFilterOptions}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel disabled sx={{ color: '#484848', pb: 2 }}>
              User
            </InputLabel>
            <TextField
              sx={{ width: '100%' }}
              placeholder="User"
              name="user"
              disabled
              value={formData?.user || authUser?.email || ''}
              // error={formError && formError?.user}
              // helperText={formError && formError?.user ? formError.user : ""}
            />
          </Grid>
        </Grid>
        {openSkillDialog && (
          <AddSkill
            open={openSkillDialog}
            onClose={handleSkillDialog}
            skill={newSkillValue}
            setParentFormData={setFormData}
          />
        )}
        {openSubTopicDialog && (
          <AddSubTopic
            open={openSubTopicDialog}
            onClose={handleSubTopicDialog}
            subTopic={newSubTopicValue}
            setParentFormData={setFormData}
          />
        )}
        {openTopicDialog && (
          <AddTopic
            open={openTopicDialog}
            onClose={handleTopicDialog}
            topic={newTopicValue}
            setParentFormData={setFormData}
          />
        )}
        {openDomainDialog && (
          <AddDomain
            open={openDomainDialog}
            onClose={handleDomainDialog}
            subTopic={newDomainValue}
            setParentFormData={setFormData}
          />
        )}
      </Box>
    </MDDialog>
  );
}

export default Index;
