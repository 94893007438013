import {
  Grid,
  Box,
  Typography,
  Divider,
  Card,
  Avatar,
  IconButton,
  SxProps,
} from '@mui/material';

export const ClassItem = ({ title, sx }: { title: string; sx?: SxProps }) => {
  return (
    <Box sx={{ background: '#F8F8F8', borderRadius: '4px', p: 2, ...sx }}>
      <span>{title}</span>
    </Box>
  );
};

export const TypoItem = ({ title, detail }: any) => {
  return (
    <Box>
      <Typography sx={{ color: '#929292' }}>{title}</Typography>
      <Typography sx={{ fontWeight: 400, color: '#131515' }}>
        {detail}
      </Typography>
    </Box>
  );
};
