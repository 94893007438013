const muiLink =  {
  MuiLink: {
    styleOverrides: {
      root: {
        textDecorationColor: 'transparent',
        cursor: 'pointer'
      }
    },
  }
}
export default muiLink;