import React, { useState, useEffect } from 'react';
import AddModal from '.';
import {
  Autocomplete,
  CircularProgress,
  InputLabel,
  Box,
  TextField,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/store';
import { getStudentListByClassroom } from 'store/Thunk/studentThunk';
import { addParentToClassRoom } from 'store/Thunk/classroomThunk';

import useSchoolClassroomInfo from 'hooks/useSchoolClassroomInfo';
import { getAllParentInSchool } from 'store/Thunk/schoolThunk';

function AddParentToClassRoom({
  open,
  setOpen,
  classRoomId,
  fetchData,
  onClose,
}) {
  const [selectedStudents, setSelectedStudnents] = useState([]);
  const [isError, setIsError] = useState(false);
  const [selectedParent, setSelectedParent] = useState();
  const [parentsList, setParentsList] = useState([]);
  const urlParams = new URLSearchParams(window.location.search);
  const classroomId = urlParams.get('classroomId');
  const schoolId = urlParams.get('schoolId');

  const handleChangeAutocomplete = (_, values) => {
    const selectedValues = values?.map((student) => student.user.id);
    setSelectedStudnents(selectedValues);
  };

  const { studentListByClassroom } = useAppSelector(
    (state) => state.studentReducer,
  );

  const dispatch = useAppDispatch();
  const [studentList, setStudentList] = useState([]);

  useEffect(() => {
    dispatch(getStudentListByClassroom({ id: classroomId }));
    dispatch(getAllParentInSchool(schoolId))
      .unwrap()
      .then((res) => {
        setParentsList(res.data);
      });
  }, []);

  const handleSubmit = () => {
    if (selectedStudents?.length > 0) {
      setIsError(false);
      dispatch(
        addParentToClassRoom({
          parentId: selectedParent,
          userIds: selectedStudents,
        }),
      )
        .unwrap()
        .then((res) => {
          fetchData();
          onClose();
        })
        .catch((err) => {});
    } else {
      setIsError(true);
    }
  };

  return (
    <AddModal
      onClose={onClose}
      open={open}
      setOpen={setOpen}
      onSave={handleSubmit}
      title="Add a Parent to Classroom"
      info="*Note if a parent is not listed then please contact your administrator to add the parent"
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
        <Box>
          <InputLabel error={isError} sx={{ color: '#484848', pb: 2 }}>
            Search And Select student
          </InputLabel>
          <Autocomplete
            multiple
            options={studentListByClassroom?.items}
            id="grade"
            getOptionLabel={(option) => option?.user.name || ''}
            clearOnBlur
            clearOnEscape
            selectOnFocus={false}
            isOptionEqualToValue={(option, value) =>
              option.user.name === value.user.name
            }
            filterSelectedOptions
            onChange={handleChangeAutocomplete}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Student ID - Student First Name Student Last Name - Student Email Address "
                error={Boolean('')}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
              />
            )}
          />
        </Box>
        <Box>
          <InputLabel error={isError} sx={{ color: '#484848', pb: 2 }}>
            Search and Select Parent
          </InputLabel>
          <Autocomplete
            options={parentsList}
            id="grade"
            getOptionLabel={(option) => option?.name || ''}
            clearOnBlur
            clearOnEscape
            selectOnFocus={false}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            filterSelectedOptions
            onChange={(_, value) => {
              setSelectedParent(value?.id);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Parent ID - Parent First Name Parent Last Name - Parent Email Address"
                error={Boolean('')}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
              />
            )}
          />
        </Box>
      </Box>
    </AddModal>
  );
}

export default AddParentToClassRoom;
