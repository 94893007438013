// ** MUI Imports
import { Theme } from '@mui/material/styles'

const Avatar = (theme: Theme) => {
  return {
    MuiAvatar: {
      styleOverrides: {
        root: {
          position: "inherit"
        },
        colorDefault: {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.customColors.lightGray
        },
        rounded: {
          borderRadius: 8
        }
      }
    },
    MuiAvatarGroup: {
      styleOverrides: {
        root: {
          justifyContent: 'flex-end',
          // '.MuiCard-root & .MuiAvatar-root': {
          //   borderColor: theme.palette.background.paper
          // }
        }
      }
    }
  }
}

export default Avatar
