import React, { useEffect, useState } from 'react';
import SkillsMasteredChart from '../../../components/v2_components/Admin_Panel_Dashboards/skills_chart';
import DetailedProgressTable from '../../../components/v2_components/Admin_Panel_Dashboards/detailed_progress_table';
import ApprovalTable from '../../../components/v2_components/Admin_Panel_Dashboards/approval_table';
import VideosTable from '../../../components/v2_components/Admin_Panel_Dashboards/videos_table';
import { CardInfo } from '../../../components/Dashboard/cardInfo';
import Icon from './Frame_1261155075.png';

import {
  scrambledData,
  tabs,
} from '../../../components/v2_components/Admin_Panel_Dashboards/skills_chart/helpers/helpers';
import {
  classrooms,
  districts,
  gems,
  parents,
  school,
  schoolLeader,
  skillsAssigned,
  skillsCompleted,
  student,
  teacher2,
  videos,
} from 'assets';
import { Grid } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/store';
import {
  getSuperAdminCounts,
  getSuperAdminVideos,
  getSuperadminDashboardSkillsMastered,
} from 'store/Thunk/dashboardThunk';
export default function Soon() {
  const {
    totalStudentsCount,
    totalTeachersCount,
    totalParentsCount,
    totalClassroomsCount,
  } = useAppSelector((state: any) => state.dashboardReducer);
  const dispatch: any = useAppDispatch();
  const { superAdminCounts, skillsMastered, superAdminVideos } = useAppSelector(
    (state: any) => state.dashboardReducer,
  );
  console.log('superAdminCounts', superAdminCounts);
  console.log('superAdminCounts', skillsMastered);
  console.log('superAdminCounts', superAdminVideos);
  const currentDate = new Date();
  const previousDay = new Date(currentDate);
  previousDay.setDate(currentDate.getDate() - 1);

  useEffect(() => {
    dispatch(getSuperAdminCounts(''));
    dispatch(getSuperadminDashboardSkillsMastered({ date: previousDay }));
  }, []);
  const data = [
    {
      title: 'Total Students',
      icon: student,
      count: superAdminCounts?.totalStudents || 0,
      md: 3,
    },
    {
      title: 'Total Teachers',
      icon: teacher2,
      count: superAdminCounts?.totalTeachers || 0,
      md: 3,
    },
    {
      title: 'Total Parents',
      count: superAdminCounts?.totalParents || 0,
      icon: parents,
      md: 3,
    },
    {
      title: 'Total School & District Leaders',
      icon: schoolLeader,
      count: superAdminCounts?.totalDistrictLeader || 0,
      md: 3,
    },
    {
      title: 'Total Districts',
      icon: districts,
      count: superAdminCounts?.totalDistricts || '0',
      md: 3,
    },
    {
      title: 'Total Number Of Schools',
      icon: school,
      count: superAdminCounts?.totalSchools || '0',
      md: 3,
    },
    {
      title: 'Total Classrooms',
      icon: classrooms,
      count: superAdminCounts?.totalClassrooms || '',
      md: 3,
    },
    {
      title: 'Total G3MS Earned',
      icon: gems,
      count: superAdminCounts?.totalGemsEarned || '0',
      md: 3,
    },
    {
      title: 'Total Number of Skills Assigned',
      icon: skillsAssigned,
      count: superAdminCounts?.totalSkillsAssigned || '0',
      md: 3,
    },
    {
      title: 'Total Number of Skills Completed',
      icon: skillsCompleted,
      count: superAdminCounts?.totalSkillsCompleted || '0',
      md: 3,
    },
    {
      title: 'Total Videos Assigned',
      icon: videos,
      count: superAdminCounts?.totalVideosAssigned || '0',
      md: 3,
    },
    {
      title: 'Total Videos Watched',
      icon: videos,
      count: superAdminCounts?.watchedVideos || '0',
      md: 3,
    },
  ];
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          gap: '1rem',
          marginBottom: '1.5rem',
        }}
      >
        <Grid container spacing={4} sx={{ mt: 3, mb: 4 }}>
          {data?.map((info: any) => (
            <CardInfo
              item={{
                title: ` ${info?.title}`,
                content: `${info?.count || 0}`,
                icon: `${info?.icon}`,
                color: `${info?.color}`,
                sm: info?.sm || 4,
                md: info?.md || 4,
              }}
            />
          ))}
        </Grid>
      </div>

      <SkillsMasteredChart data={skillsMastered || scrambledData} tabs={tabs} />
      <ApprovalTable sectionTitle="Approve Videos" data={scrambledData} />
      <VideosTable sectionTitle="Daily Challenges" />
    </>
  );
}
