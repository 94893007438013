// ** MUI Imports
import { Theme } from '@mui/material/styles';
import { hexToRGBA } from '../../utils/hex-to-rgba';

// ** Util Import
const Pagination = (theme: Theme) => {
  return {
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          minWidth: '28px',
          backgroundColor: '#F9F9F9',
          '&.Mui-selected:not(.Mui-disabled):not(.MuiPaginationItem-textPrimary):not(.MuiPaginationItem-textSecondary):hover':
            {
              backgroundColor: `rgba(${theme.palette.customColors.main}, 0.12)`,
            },
          '&.Mui-selected': {
            color: '#FFFFFF',
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: `${hexToRGBA(
                theme.palette.primary.main,
                8.24,
              )} !important`,
            },
          },
        },
        outlined: {
          borderColor: `rgba(${theme.palette.customColors.main}, 0.22)`,
        },
        outlinedPrimary: {
          '&. Mui-selected': {
            backgroundColor: theme.palette.primary.main,
          },
        },
        outlinedSecondary: {
          '&. Mui-selected': {
            backgroundColor: theme.palette.primary.main,
          },
        },
        rounded: {
          borderRadius: 6,
        },
      },
    },
  };
};

export default Pagination;
