import React, { useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import { Tune } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import SharedButton from 'components/button';

const Wrapper = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '5px',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '24px',
  marginBottom: '24px',
});

const SectionBox = styled(Paper)({
  display: 'flex',
  flex: 1,
  boxShadow: 'none',
  flexWrap: 'wrap',
  gap: '24px',
  justifyContent: 'space-between',
  alignItems: 'stretch',
});
export default function TableHeader({ item }: { item: Header }) {
  const { fetchData, search, setSearch } = item;
  console.log('search', search);
  const textFieldRef = useRef<HTMLInputElement | null>(null); // Specify the type as HTMLInputElement
  const onSearchChange = (e: any) => {
    setSearch(textFieldRef.current?.value || ''); // Update the search state with the current input value.
  };
  function handleKeyDown(e: any) {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (fetchData) {
        fetchData({
          pageIndex: 0,
          pageSize: 0,
          search,
        });
      }
    }
  }

  const focusOnTextField = () => {
    // Function to set focus on the TextField
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  };
  React.useEffect(() => {
    focusOnTextField(); // Set focus on TextField when the component mounts
  }, []);
  return (
    <Wrapper>
      {item.title && (
        <Typography
          style={{ fontWeight: '600', fontSize: '20px', color: '#202020' }}
          display="block"
          className="flex-1"
        >
          {' '}
          {item?.title || ''}
        </Typography>
      )}
      <SectionBox>
        {!item.canNotSearch && (
          <Box
            className="flex-1"
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              pl: 2,
              pr: 8,
              py: 1,
              flexGrow: 1,
              justifyContent: 'center',
              bgcolor: '#F9F9F9',
              borderRadius: '5px',
              border: 1,
              borderColor: '#DCDCDC',
            }}
          >
            <IconButton size="small">
              <SearchIcon />
            </IconButton>
            <TextField
              defaultValue={search || ''}
              value={textFieldRef.current?.value || ''}
              onChange={(e) => onSearchChange(e.target.value)}
              sx={{
                '& .css-7hgqn8-MuiInputBase-root-MuiInput-root:before': {
                  borderBottom: '0px',
                },
              }}
              onKeyDown={handleKeyDown}
              inputRef={textFieldRef} // Assign the ref to the TextField component
              placeholder="Search…"
              variant="standard"
              className="flex-1"
            />
            {/* <TextField value={search || ''} onChange={onSearchChange} /> */}
          </Box>
        )}
        {/* {item.action && ( */}
        {false && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '5px',
              p: 1,
              bgcolor: '#F9F9F9',
              border: 1,
              borderColor: '#DCDCDC',
            }}
          >
            <IconButton size="small" onClick={() => item?.onCloseFilter(true)}>
              <Tune />
            </IconButton>
          </Box>
        )}
        {item.button && (
          <SharedButton
            disabled={item.disabledButton}
            onClick={() => item?.onCloseAssign(true)}
            variant="contained"
            name="Assign Content"
            className="primary_bg_btn"
          />
        )}
      </SectionBox>
    </Wrapper>
  );
}

interface Header {
  title?: string;
  action?: boolean;
  open?: boolean;
  disabledButton?: boolean;
  openAssign?: boolean;
  openFilter?: boolean;
  onCloseAssign?: any;
  onClose?: any;
  onCloseFilter?: any;
  button?: boolean;
  fetchData?: any;
  search?: any;
  canNotSearch?: any;
  canSearch?: any;
  setSearch?: any;
}
