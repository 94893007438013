import { Box, Button, IconButton, Typography } from '@mui/material';
import DefaultCell from 'components/DefaultCell';
import CustomTable from 'components/customTable';

import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import AddFirstStudent from '../super_admin/school_management/student/AddFirstStudent';

import { useAppDispatch, useAppSelector } from 'store/store';
import { getUserListAccordingToType } from 'store/Thunk/userThunk';
import AvatartWithText from 'components/AvatarWithText';
import { formatDate } from 'helper/service';
import ReadMore from 'components/ReadMore/ReadMore';
import { useNavigate } from 'react-router-dom';
import { getLinkPartFromRole } from 'helper/functions';
import NodataToShow from 'icons/NoDataToShow';
import AddIcon from '@mui/icons-material/Add';
import CrudPopUp from 'components/popUp/crudPopup';
import { MoreVertOutlined } from '@mui/icons-material';
import DeleteUserDialog from 'components/dialog/users/Delete/deleteUser';
import { deleteStudent } from 'store/Thunk/studentThunk';

const ClassItem = ({
  title,
  itemKey,
}: {
  title: string;
  count: number;
  itemKey: number;
}) => {
  return (
    <Box>
      {itemKey < 2 && (
        <Box
          sx={{
            background: '#F8F8F8',
            borderRadius: '4px',

            fontWeight: 400,
            p: 1,
          }}
        >
          <span>{title}</span>
        </Box>
      )}
    </Box>
  );
};

function StudentListBySchool({ schoolId }: any) {
  const navigate = useNavigate();
  const [activeButtonRow, setActiveButtonRow] = useState<any>(null);
  const dropDownRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(dropDownRef, () => setActiveButtonRow(null));
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPageSize] = useState(10);
  const [search, setSearch] = useState<any>(null);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedUserToDelete, setSelectedUserToDelete] = useState({
    dependentOfUser: {
      name: '',
      profileImage: ''
    },
    id: '',
  });

  const initial = getLinkPartFromRole();

  const { studentList } = useAppSelector((state) => state.userReducer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUserListAccordingToType({ userType: 'student', schoolId }));
  }, []);

  useEffect(() => {
    if (studentList?.items?.length > 0) {
      setPageCount(studentList?.meta?.totalPages);
      setTotalCount(studentList?.meta?.totalCount);
    }
  }, [studentList]);

  const handleEventClick = (rowIndex: number) => {
    setActiveButtonRow(rowIndex);
  };

  useEffect(() => {
    if (studentList?.items?.length > 0) {
      setPageCount(studentList?.meta?.totalPages);
      setTotalCount(studentList?.meta?.totalCount);
    }
  }, [studentList]);

  const defaultCellStyle = {};

  const handleAddStudent = () => {
    navigate(`/${initial}/user-management/create?mode=student`);
  };

  const handleSubmit = () => {
    setDeleteLoading(true);
    dispatch(deleteStudent(selectedUserToDelete.id))
      .unwrap()
      .then(() => {
        setOpenDeleteModal(false);
        dispatch(
          getUserListAccordingToType({
            pageSize: perPage,
            pageIndex: pageNo,
            search,
            userType: 'student',
          }),
        );
      })
      .catch(() => { })
      .finally(() => {
        setDeleteLoading(false);
        setSelectedUserToDelete({
          dependentOfUser: {
            name: '',
            profileImage: ''
          },
          id: '',
        });
      });
  };

  const columns = [
    {
      Header: 'StudentId',
      accessor: 'studentId',
      Cell: ({ value }: any) => (
        <DefaultCell sx={defaultCellStyle} value={value} />
      ),
    },
    {
      Header: 'Grade',
      accessor: 'grade',
      Cell: ({ value }: any) => (
        <DefaultCell sx={defaultCellStyle} minWidth="50px" value={value} />
      ),
    },
    {
      Header: 'Name',
      accessor: 'studentInfo',
      Cell: ({ value }: any) => <AvatartWithText value={value} />,
    },
    {
      Header: 'Parent',
      accessor: 'parentInfo',
      Cell: ({ value }: any) => <AvatartWithText value={value} />,
    },
    {
      Header: 'Email',
      accessor: 'email',
      Cell: ({ value }: any) => (
        <ReadMore
          maxLength={20}
          sx={{ ...defaultCellStyle, minWidth: '150px' }}
          text={value}
        />
      ),
    },

    {
      Header: 'Classrooms',
      accessor: 'classroom',
      Cell: ({ value }: any) => (
        <>
          {value.length > 0 ? (
            <Box className="class_list">
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}
              >
                {value.map((item: any, index: number) => (
                  <ClassItem
                    itemKey={index}
                    title={item}
                    count={value.length}
                  />
                ))}
              </Box>
              <Box className="badge_head">
                {value.length > 2 && (
                  <span className="count_badge">
                    {'+' + (value.length - 2)}
                  </span>
                )}
              </Box>
            </Box>
          ) : (
            <Typography sx={{ minWidth: '145px' }} variant="body1">
              -
            </Typography>
          )}
        </>
      ),
    },
    {
      Header: 'Created Date',
      accessor: 'createdDate',
      Cell: ({ value }: any) => (
        <DefaultCell sx={defaultCellStyle} minWidth="145px" value={value} />
      ),
    },
    {
      Header: 'Managed By',
      accessor: 'managedBy',
      Cell: ({ value }: any) => (
        <DefaultCell sx={defaultCellStyle} minWidth="180px" value={value} />
      ),
    },
    {
      Header: 'Action',
      accessor: '',
      Cell: ({ row }: any) => (
        <>
          <IconButton onClick={() => handleEventClick(row.index)}>
            <MoreVertOutlined />
          </IconButton>
          {activeButtonRow === row.index && (
            <CrudPopUp
              showEdit
              showDelete
              showView
              onDeleteClick={() => {
                setOpenDeleteModal(true);
                setSelectedUserToDelete({
                  id: row.original.studentId || '',
                  dependentOfUser: row?.original?.parentInfo,
                });
              }}
              onEditClick={() => {
                navigate(
                  `/${initial}/user-management/edit?mode=student&id=${row?.original?.studentId}`,
                );
              }}
              onViewClick={() =>
                navigate(
                  `/${initial}/user-management/view?mode=student&id=${row?.original?.studentId}`,
                )
              }
              setClose={() => setActiveButtonRow(null)}
            />
          )}
        </>
      ),
    },
  ];

  const fetchData = useCallback(({ pageSize, pageIndex, search }: any) => {
    setPageNo(pageIndex);
    dispatch(
      getUserListAccordingToType({
        pageSize,
        pageIndex,
        search,
        userType: 'student',
      }),
    );
  }, []);

  const rows = useMemo(() => {
    if (studentList?.items?.length > 0) {
      const data = studentList?.items?.map((item: any) => ({
        studentId: item.id,
        grade: item?.grade?.length > 0 && item?.grade[0]?.grade?.name,
        studentInfo: {
          name: item?.name,
          image: item?.profileImage,
        },
        parentInfo: {
          name: item?.parent?.name || item?.parent?.email,
          image: item?.parent?.profileImage,
        },
        email: item.email,
        managedBy: item?.school?.length > 0 && item?.school[0]?.school?.name,
        classroom: item?.classroom?.map(
          (item: any) =>
            item?.clasroom?.name.split(':')[1]?.trim() || item?.clasroom?.name,
        ),
        createdDate: formatDate(item?.createdAt),
      }));
      return data;
    } else return [];
  }, [studentList]);

  return (
    <>
      {studentList?.items?.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              m: '15px',
              mt: '5px',
            }}
          >
            <NodataToShow />
            <Typography
              variant="body1"
              sx={{ fontSize: '18px', fontWeight: '600' }}
            >
              No Student Found
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{
                textAlign: 'justify',
                fontSize: '13px',
                fontWeight: '400',
                p: '0 30px',
              }}
            >
              Please Add students in your school
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              m: '15px 0 0 0',
            }}
          >
            <Button
              variant="contained"
              className="primary_bg_btn"
              onClick={() => handleAddStudent()}
              endIcon={<AddIcon />}
            >
              Add Student
            </Button>
          </Box>
        </Box>
      ) : (
        <div style={{ position: 'relative' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'right',
              alignItems: 'right',
              m: '15px 0 0 0',
            }}
          >
            <Button
              variant="contained"
              className="primary_bg_btn"
              onClick={() => handleAddStudent()}
              endIcon={<AddIcon />}
            >
              Add Student
            </Button>
          </Box>
          <CustomTable
            fetchData={fetchData}
            pageCount={pageCount}
            canSearch
            pageNo={pageNo}
            totalCount={totalCount}
            table={{
              columns: columns,
              rows: rows,
            }}
          />
          <DeleteUserDialog
            loading={deleteLoading}
            open={openDeleteModal}
            title={'Are you sure you want to delete the user from G3MS?'}
            description={
              selectedUserToDelete?.dependentOfUser?.name
                ? 'Are you sure want to remove parent children relation too?'
                : ''
            }
            onOk={handleSubmit}
            onClose={() => {
              setOpenDeleteModal(false);
              setSelectedUserToDelete({
                dependentOfUser: {
                  name: '',
                  profileImage: ''
                }, id: ''
              });
            }}
          />
        </div>
      )}
    </>
  );
}

export default StudentListBySchool;
