import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

export interface ResponseData {
  status: String;
  messagecode: String;
  message: String;
  data: any;
}

interface IntialState {
  schoolLeaderList: any;
}

const intialState: IntialState = {
  schoolLeaderList: [],
};

const SchoolLeaderSlice = createSlice({
  name: 'user_slice',
  initialState: intialState,
  reducers: {
    setSchoolLeaderList: (
      state: Draft<IntialState>,
      action: PayloadAction<[]>,
    ) => {
      state.schoolLeaderList = action.payload;
    },
  },
});

export const { setSchoolLeaderList } = SchoolLeaderSlice.actions;

export default SchoolLeaderSlice.reducer;
