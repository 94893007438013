import React from 'react';

export default function saveIcon() {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.25 2V4.26667C5.25 4.64004 5.25 4.82672 5.33175 4.96933C5.40365 5.09477 5.51839 5.19676 5.65951 5.26067C5.81994 5.33333 6.02996 5.33333 6.45 5.33333H11.55C11.97 5.33333 12.1801 5.33333 12.3405 5.26067C12.4816 5.19676 12.5964 5.09477 12.6683 4.96933C12.75 4.82672 12.75 4.64004 12.75 4.26667V2.66667M12.75 14V9.73333C12.75 9.35997 12.75 9.17328 12.6683 9.03067C12.5964 8.90523 12.4816 8.80324 12.3405 8.73933C12.1801 8.66667 11.97 8.66667 11.55 8.66667H6.45C6.02996 8.66667 5.81994 8.66667 5.65951 8.73933C5.51839 8.80324 5.40365 8.90523 5.33175 9.03067C5.25 9.17328 5.25 9.35997 5.25 9.73333V14M15.75 6.21699V10.8C15.75 11.9201 15.75 12.4802 15.5048 12.908C15.289 13.2843 14.9448 13.5903 14.5215 13.782C14.0402 14 13.4101 14 12.15 14H5.85C4.58988 14 3.95982 14 3.47852 13.782C3.05516 13.5903 2.71095 13.2843 2.49524 12.908C2.25 12.4802 2.25 11.9201 2.25 10.8V5.2C2.25 4.0799 2.25 3.51984 2.49524 3.09202C2.71095 2.71569 3.05516 2.40973 3.47852 2.21799C3.95982 2 4.58988 2 5.85 2H11.0059C11.3728 2 11.5562 2 11.7288 2.03684C11.8819 2.0695 12.0282 2.12337 12.1624 2.19648C12.3138 2.27894 12.4435 2.39424 12.7029 2.62484L15.0471 4.7085C15.3065 4.9391 15.4362 5.0544 15.529 5.18895C15.6112 5.30825 15.6718 5.43831 15.7086 5.57436C15.75 5.72781 15.75 5.89087 15.75 6.21699Z"
        stroke="#C73E9D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
