import React from "react";

export default function EditWithColorIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4011 1.82788L16.5553 3.98214L14.9131 5.62511L12.7588 3.47086L14.4011 1.82788ZM6.51074 11.8725H8.665L13.8977 6.63977L11.7434 4.48551L6.51074 9.7182V11.8725Z"
        fill="#C73E9D"
      />
      <path
        d="M14.4098 14.0267H6.62429C6.60562 14.0267 6.58624 14.0339 6.56756 14.0339C6.54387 14.0339 6.52017 14.0274 6.49576 14.0267H4.35658V3.97352H9.27331L10.7095 2.53735H4.35658C3.56453 2.53735 2.92041 3.18076 2.92041 3.97352V14.0267C2.92041 14.8195 3.56453 15.4629 4.35658 15.4629H14.4098C14.7907 15.4629 15.156 15.3116 15.4253 15.0422C15.6946 14.7729 15.8459 14.4076 15.8459 14.0267V7.80235L14.4098 9.23852V14.0267Z"
        fill="#C73E9D"
      />
    </svg>
  );
}
