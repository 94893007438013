import * as React from 'react';
import Card from '@mui/material/Card';

import Typography from '@mui/material/Typography';

import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';

// import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';

import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';

export interface IStudent {
  id?: string;
  name?: string;
  assesment?: string;
  completed?: string;
  pending?: string;
  progress?: string;
  dueDate?: string;
}

export interface IClasses {
  id?: string;
  name?: string;
  studentCount?: string;
  target?: string;
  students?: IStudent[];
}
export interface ITeacher {
  id?: string;
  avatar?: string;
  name?: string;
  color?: string;
  classes: IClasses[];
}

const colorArr = ['#BBF3FB', '#FFBFEB7D', '#A0F2CB'];
const styles = {
  bg1: '#BBF3FB',
  bg2: '#FFBFEB7D',
  bg3: '#A0F2CB',
  fadedText: {
    opacity: 0.6,
    fontSize: '50px',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: 10,
  border: '1px solid #DCDCDC',
  backgroundColor: '#F9F9F9',
  '&:hover': {
    backgroundColor: '#DCDCDC',
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),

    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '37ch',
      height: '3ch',
    },
  },
}));

export const TableList = ({
  title,
  teachers,
}: {
  title: string;
  teachers: ITeacher[];
}) => {
  const hasData = teachers !== undefined && teachers.length > 0;
  const nodes = hasData ? (
    teachers.map((teacher: ITeacher, i: number) => (
      <TeacherComponent key={i} color={colorArr[i % 3]} teacher={teacher} />
    ))
  ) : (
    <div style={{ height: '30em' }}></div>
  );

  return (
    <Card sx={{ p: 2 }}>
      {/* main stack to lay out the titles & list of teachers */}
      <Stack sx={{ p: 2 }} spacing={2}>
        {/* seach and title stack */}
        <Stack
          sx={{ mx: 2, pt: 1 }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography
            style={{ fontWeight: 'bold' }}
            variant="body1"
            display="block"
            gutterBottom
          >
            {title}
          </Typography>

          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
        </Stack>

        {/* teachers list grid */}
        {nodes}
      </Stack>
    </Card>
  );
};

const StudentsList = ({ students }: { students: IStudent[] | undefined }) => {
  let stud: IStudent[] = [];
  if (students && students.length > 0) {
    stud = students;
  }
  return (
    <List component="div" disablePadding>
      {stud.map((student) => (
        <Card sx={{ m: 2 }}>
          <ListItem
            sx={{ pl: 4 }}
            secondaryAction={
              <Tooltip title="Coming Soon">
                <IconButton
                  style={{ color: '#20A58A' }}
                  edge="end"
                  aria-label="comments"
                >
                  {/* <VisibilityOutlinedIcon /> */}
                </IconButton>
              </Tooltip>
            }
          >
            {/* grid for listing student properties */}
            <Grid container spacing={2}>
              {/* Student name*/}
              <Grid item xs={4} md={4}>
                <Typography color="#84818A" variant="caption" gutterBottom>
                  Student Name:
                </Typography>
                <Tooltip title={student?.id}>
                  <Typography color="#F41F52" variant="subtitle1">
                    {student?.name}
                  </Typography>
                </Tooltip>
              </Grid>
              {/* student total assignment */}
              <Grid item xs={4} sm={2}>
                <Typography color="#84818A" variant="caption">
                  Total Assignments:
                </Typography>
                <Typography color="#F41F52" variant="subtitle1">
                  {student?.assesment?.length}
                </Typography>
              </Grid>
              {/* completed */}
              <Grid item xs={4} sm={2}>
                <Typography color="#84818A" variant="caption">
                  Completed:
                </Typography>
                <Typography color="#F41F52" variant="subtitle1">
                  {student.completed}
                </Typography>
              </Grid>
              {/* Pending */}
              <Grid item xs={4} sm={2}>
                <Typography color="#84818A" variant="caption">
                  Pending:
                </Typography>
                <Typography color="#F41F52" variant="subtitle1">
                  {student.pending}
                </Typography>
              </Grid>
              {/* Progress */}
              <Grid item xs={4} sm={2}>
                <Typography color="#84818A" variant="caption">
                  Progress:
                </Typography>
                <Typography color="#F41F52" variant="subtitle1">
                  {student.progress}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        </Card>
      ))}
    </List>
  );
};

const ClassesComponent = ({
  classes,
  color,
}: {
  classes: IClasses | undefined;
  color: string;
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <div>
      <Card sx={{ mb: 2 }} style={{ backgroundColor: color }}>
        <ListItemButton onClick={handleClick}>
          {/* <ListItemIcon>
                        class
                    </ListItemIcon> */}

          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Tooltip title={classes?.id}>
                <ListItemText primary={`${classes?.name}`} />
              </Tooltip>
            </Grid>
            <Grid container alignItems="baseline" gap={1} item xs={6} md={3}>
              <Typography color="#84818A" variant="caption">
                Students
              </Typography>
              <Typography variant="body1">
                {!!classes?.students ? classes?.students.length : 0}
              </Typography>
            </Grid>
            <Grid container alignItems="baseline" gap={1} item xs={3}>
              <Typography color="#84818A" variant="body2">
                On target{' '}
              </Typography>
              <Typography
                color={classes?.target  ? '#131515' : '#F41F52'}
                variant="body1"
              >
                {classes?.target ? 'YES' : 'NO'}
              </Typography>
            </Grid>
          </Grid>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </Card>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <StudentsList students={classes?.students} />
      </Collapse>
    </div>
  );
};

const TeacherComponent = ({
  teacher,
  color,
}: {
  teacher: ITeacher;
  color: string;
}) => {
  return (
    <Grid container spacing={2}>
      {/* grid that conains the avatar */}
      <Grid
        container
        alignItems="center"
        style={{
          backgroundColor: color,
          borderTopLeftRadius: '10px',
          borderBottomLeftRadius: '10px',
        }}
        item
        xs={2}
        sm={2}
        justifyContent="center"
      >
        {/* stack that contains the avatar */}

        <Tooltip title={teacher?.id}>
          <Stack
            mt={2}
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={0}
          >
            <Avatar alt="User Avatar" src={teacher.avatar} />

            <Typography variant="overline" display="block" gutterBottom>
              {teacher.name}
            </Typography>
          </Stack>
        </Tooltip>
      </Grid>

      {/* Grid that contains the classes list */}
      <Grid item xs={10} md={10}>
        {/* classes list object */}
        <List
          sx={{ width: '100%', bgcolor: 'background.paper' }}
          component="nav"
        >
          {teacher.classes.map((classes: IClasses, i: number) => (
            <ClassesComponent key={i} color={color} classes={classes} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};
