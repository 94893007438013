import SimpleLoader from '@core/components/MDLoader/simpleLoader';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import WarningIcon from 'icons/warning';
import { useState } from 'react';
import { deleteQuiz } from 'store/Thunk/templateThunk';
import { deleteUserSession } from 'store/Thunk/userSessionThunk';
import { useAppDispatch } from 'store/store';

interface DialogProps {
  open: boolean;
  deleteId: string;
  onClose: (id: string) => void;
}

function Index(props: DialogProps) {
  const dispatch = useAppDispatch();
  const { onClose, deleteId, open } = props;
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    onClose('');
  };

  const onDelete = (id: string) => {
    setLoading(true);
    dispatch(deleteUserSession({ id })).then((res: any) => {
      setLoading(false);
      if (res.payload) {
        handleClose();
      }
    });
  };

  return (
    <Dialog fullWidth maxWidth="sm" onClose={handleClose} open={open}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        py={6}
      >
        <WarningIcon />
        <Typography variant="h6" mt={3}>
          Are you sure you want to Delete this user data?
        </Typography>
        <Stack direction={'row'} spacing={4} mt={4}>
          <Button
            fullWidth
            size="small"
            variant="contained"
            className="dialog_grey_button"
            onClick={() => onClose('')}
          >
            No
          </Button>
          <Button
            fullWidth
            size="small"
            variant="contained"
            className="dialog_red_button"
            onClick={() => onDelete(deleteId)}
            disabled={loading}
          >
            {loading ? <SimpleLoader size={24} color={'secondary'} /> : 'Yes'}
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
}

export default Index;
