import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Button, Paper, Typography, useMediaQuery } from '@mui/material';
import { teacher } from 'assets';
import { Theme } from '@mui/material/styles';
import CrudPopUp from 'components/popUp/crudPopup';
import { useNavigate } from 'react-router-dom';

const TeacherCard = ({
  user,
  id,
  deleteUserFromClassRoom,
  setUserIdToRemove,
  setRemoveUserModal,
}: any) => {
  const navigate = useNavigate();
  const mobileSize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const [showPopUp, setShowPopUp] = useState(false);
  return (
    <Box sx={{ ...(!mobileSize && { maxWidth: 260 }) }}>
      <Paper
        elevation={0}
        sx={{
          position: 'relative',
          border: '1px solid #DCDCDC',
          borderRadius: '8px',
        }}
      >
        <Box sx={{ my: { sm: 4, xs: 2 } }}>
          <IconButton
            aria-label="settings"
            sx={{
              ...(!mobileSize
                ? { position: 'absolute', right: '0px', top: '0px', m: 1 }
                : { display: 'none' }),
            }}
          >
            <MoreVertIcon onClick={() => setShowPopUp(!showPopUp)} />
            {showPopUp && (
              <CrudPopUp
                sx={{ right: '20px !important', width: '200px' }}
                showDelete={true}
                setClose={() => setShowPopUp(false)}
                deleteText="Remove from Classroom"
                onDeleteClick={() => {
                  setRemoveUserModal(true);
                  setShowPopUp(false);
                  if (id) {
                    setUserIdToRemove(id);
                  }
                }}
              />
            )}
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { sm: 'column', xs: 'row' },
              alignItems: 'center',
              my: { sm: 6, xs: 0 },
              mx: { sm: 0, xs: 2 },
            }}
          >
            <Avatar
              alt={'Student'}
              src={user?.profileImage}
              sx={{
                width: { sm: 86, xs: 54 },
                height: { sm: 86, xs: 54 },
                p: 1,
                border: { sm: '1px solid #E9EBEC', xs: 'none' },
              }}
            />
            <Typography variant="subtitle1" className="title_text" mt={2}>
              {user?.name}
            </Typography>
            {/* <Typography
              variant="body1"
              className="subtitle_text"
              mt={2}
              sx={{ ...(mobileSize && { display: 'none' }) }}
            >
              School Name they selected
            </Typography> */}
            <Button
              variant="contained"
              className="gray_bg_btn"
              sx={{ ...(mobileSize ? { display: 'none' } : { mt: 3 }) }}
              onClick={() => {
                navigate(`/school-leader/user-management/view?mode=teacher&id=${id}`)
              }}
            >
              View Profile
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default TeacherCard;
