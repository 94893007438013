import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { ITeacher } from '../../components/Dashboard/table';

interface IntialState {
  teacherClassrooms: any[];
  teacherStudents: any[];
  totalAssignedDailyChallengesCount: number;
  totalAssignedTemplatesCount: number;
  totalAssignedVideosCount: number;
  totalSkillsMasteredCount: number;
  totalClassroomsCount: number;
  totalStudentsCount: number;
  totalParentsCount: number;
  totalCounts: any;
}

const intialState: IntialState = {
  teacherClassrooms: [],
  teacherStudents: [],
  totalCounts: {},
  totalAssignedDailyChallengesCount: 0,
  totalAssignedTemplatesCount: 0,
  totalAssignedVideosCount: 0,
  totalClassroomsCount: 0,
  totalSkillsMasteredCount: 0,
  totalStudentsCount: 0,
  totalParentsCount: 0,
};

const TeacherDashboardsSlice = createSlice({
  name: 'teacher_dashboard_slice',
  initialState: intialState,
  reducers: {
    setTeacherDashboardInfo: (
      state: Draft<IntialState>,
      action: PayloadAction<any>,
    ) => {
      if (action.payload.teacherClassrooms) {
        state.teacherClassrooms = action.payload.teacherClassrooms;
      }

      if (action.payload.teacherStudents) {
        state.teacherStudents = action.payload.teacherStudents;
      }

      if (action.payload.totalAssignedDailyChallengesCount) {
        state.totalAssignedDailyChallengesCount =
          action.payload.totalAssignedDailyChallengesCount;
      }

      if (action.payload.totalAssignedTemplatesCount) {
        state.totalAssignedTemplatesCount =
          action.payload.totalAssignedTemplatesCount;
      }

      if (action.payload.totalAssignedVideosCount) {
        state.totalAssignedVideosCount =
          action.payload.totalAssignedVideosCount;
      }

      if (action.payload.totalClassroomsCount) {
        state.totalClassroomsCount = action.payload.totalClassroomsCount;
      }
      if (action.payload.totalSkillsMasteredCount) {
        state.totalSkillsMasteredCount =
          action.payload.totalSkillsMasteredCount;
      }

      if (action.payload.totalStudentsCount) {
        state.totalStudentsCount = action.payload.totalStudentsCount;
      }
      if (action.payload.totalParentsCount) {
        state.totalParentsCount = action.payload.totalParentsCount;
      }
      if (action.payload.totalCounts) {
        state.totalCounts = action.payload.totalCounts;
      }
    },
  },
});

export const { setTeacherDashboardInfo } = TeacherDashboardsSlice.actions;

export default TeacherDashboardsSlice.reducer;
