// *** React Imports
import React, { useEffect, useState } from 'react';

// *** Material UI Imports
import {
  Box,
  Grid,
  TextField,
  Autocomplete,
  InputLabel,
  CircularProgress,
  InputAdornment,
  Checkbox,
} from '@mui/material';

import MDDialog from '@core/components/MDDialog';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

/* mui-color-input' */
import { useAppDispatch, useAppSelector } from 'store/store';
import Validations from 'helper/validations';
import { setBtnLoading } from 'store/Slice/loadingSlice';

import AddSkill from 'components/dialog/skill/addSkill';
import AddSubTopic from 'components/dialog/subtopic/addSubTopic';
import {
  getDistrictByID,
  addUpdateDistrict,
  getDistrictList,
} from 'store/Thunk/districtThunk';
import { getGradeList, getTopicList } from 'store/Thunk/videoThunk';
import { addUpdateSchool } from 'store/Thunk/schoolThunk';
import { getSchoolLeaderList } from 'store/Thunk/schoolLeaderThunk';
import { getDomainList, getTutorList } from 'store/Thunk/userThunk';
import {
  addUpdateClassroom,
  getClassroomByID,
  getClassroomBySchoolID,
  getClassroomList,
} from 'store/Thunk/classroomThunk';
import { useLocation, useParams } from 'react-router-dom';
import useSchoolClassroomInfo from 'hooks/useSchoolClassroomInfo';
import { getNavItems } from 'store/Thunk/navThunk';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function Index({
  open,
  onClose,
  editId,
  id,
  userId,
  handleFetchClassRoom,
}: any) {
  const params = useParams();

  const { schoolId } = useSchoolClassroomInfo();

  const dispatch: any = useAppDispatch();
  const { btnLoading, screenLoading } = useAppSelector(
    (state: any) => state.loadingReducer,
  );
  const { gradeList, topicList } = useAppSelector(
    (state: any) => state.videoReducer,
  );

  const { tutorList } = useAppSelector((state: any) => state.userReducer);
  const { navItems } = useAppSelector((state: any) => state.navItemReducer);

  const [formData, setFormData] = useState<any>({
    grade: [],
  });
  const { classroomID } = useAppSelector(
    (state: any) => state.classroomReducer,
  );
  console.log('classroomID', topicList);
  console.log('classroomID', formData);
  console.log('param', id);
  console.log('param', gradeList);
  const user: any = localStorage.getItem('userData') || {};
  const [formError, setFormError] = useState<any>({});

  const [screenLoader, setScreenLoader] = React.useState(false);
  const [loadingDistrict, setLoadingDistrict] = React.useState(false);
  const [loadingSchoolLeader, setLoadingSchoolLeader] = React.useState(false);
  const [openDistrict, setOpenDistrict] = React.useState<boolean>(false);
  const [openClassroomType, setOpenClassroomType] = React.useState(false);
  const [openSpecificSchoolType, setOpenSpecificSchoolType] =
    React.useState(false);
  const [openSchoolGrade, setOpenSchoolGrade] = React.useState(false);
  const [openSchoolLeader, setOpenSchoolLeader] = React.useState(false);
  const [openTeacher, setOpenTeacher] = React.useState(false);
  const [grade, setGrade] = React.useState<any[]>([]);
  const [openSubTopicDialog, setOpenSubTopicDialog] = React.useState(false);
  const [newSubTopicValue, setNewSubTopicValue] = React.useState({});
  const [openSkillDialog, setOpenSkillDialog] = React.useState(false);
  const [newSkillValue, setNewSkillValue] = React.useState({});
  const [loadingGrade, setLoadingGrade] = React.useState(false);
  const [openGrade, setOpenGrade] = React.useState(false);
  const [loadingDomain, setLoadingDomain] = React.useState(false);
  const [openDomain, setOpenDomain] = React.useState(false);

  const location: any = useLocation();
  const locationSplited: any = location.pathname.split('/');

  const classroomType = [
    { name: 'Inside of school hours' },
    { name: 'Outside of school hours' },
  ];

  const SpecificSchoolType = [
    { name: 'All' },
    { name: 'Charter' },
    { name: 'Magnet' },
  ];

  const SchoolGrade = [
    { name: 'Elementary' },
    { name: 'Middle' },
    { name: 'High School' },
  ];

  useEffect(() => {
    setFormData({});
  }, []);

  useEffect(() => {
    if (editId) {
      dispatch(getClassroomByID({ schoolId: id, id: editId }));
    }
  }, [editId]);
  useEffect(() => {
    dispatch(getNavItems(`/api/v1/school/${id}/grades-topics`));
  }, [openGrade]);

  useEffect(() => {
    if (
      classroomID &&
      Object.entries(classroomID).length &&
      classroomID.constructor === Object &&
      editId
    ) {
      setFormData({
        name:
          classroomID?.classroom?.name.split(':')[1]?.trim() ||
          classroomID?.classroom?.name,
        grade: navItems.find(
          (item: any) => item.grade.id === classroomID?.classroom?.grade?.id,
        ),

        classroomPeriod: classroomID?.classroom?.period,
        classroomTeacher1: classroomID?.classroom?.ClassroomTeacher?.length
          ? classroomID?.classroom?.ClassroomTeacher?.map(
              (item: any) => item?.user,
            )
          : [],
        topic: classroomID?.classroom?.topic,
      });
    }
  }, [classroomID]);

  useEffect(() => {
    dispatch(getDistrictList({ pageSize: 50, pageIndex: 0 })).then(() => {
      setLoadingDistrict(false);
    });
    setLoadingDistrict(true);
  }, [openDistrict]);

  useEffect(() => {
    dispatch(getSchoolLeaderList()).then(() => {
      setLoadingSchoolLeader(false);
    });
    setLoadingSchoolLeader(true);
  }, [openSchoolLeader]);

  useEffect(() => {
    dispatch(getTutorList());
  }, [openTeacher]);
  useEffect(() => {
    if (openDomain) {
      dispatch(getTopicList());
      setLoadingDomain(openDomain);
    } else {
      setLoadingDomain(openDomain);
    }
  }, []);

  useEffect(() => {
    if (openGrade) {
      dispatch(getGradeList()).then(() => {
        setLoadingGrade(false);
      });
      setLoadingGrade(true);
    } else {
      setGrade([]);
    }
  }, [openGrade]);

  useEffect(() => {
    dispatch(getTopicList());
  }, []);

  useEffect(() => {
    dispatch(getTutorList());
  }, []);
  const handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleChangeAutocomplete =
    (name: string) =>
    (
      event: React.SyntheticEvent<Element, Event>,
      newValue: string | null | any,
    ) => {
      let param = {
        [name]: newValue,
      };
      if (name === 'grade') {
        setFormData({
          ...formData,
          ...{
            topic: {},
          },
          ...param,
        });
      } else {
        setFormData({ ...formData, ...param });
      }
    };

  useEffect(() => {
    // Default-check the current user by finding their data in the tutorList
    const currentUser = tutorList?.find(
      (teacher: any) => teacher.id === JSON?.parse(user)?.id,
    );
    if (currentUser) {
      setFormData({ ...formData, classroomTeacher1: [currentUser] });
    }
  }, []);

  const handleSkillDialog = (name: string = '') => {
    setNewSkillValue({
      ...(!openSkillDialog
        ? {
            topic: formData.topic,
            name: name,
            subTopic: formData.sub_topic,
          }
        : {}),
    });
    setOpenSkillDialog(!openSkillDialog);
  };

  const handleSubTopicDialog = (name: string = '') => {
    setNewSubTopicValue({
      ...(!openSubTopicDialog
        ? {
            topic: formData.topic,
            name: name,
          }
        : {}),
    });
    setOpenSubTopicDialog(!openSubTopicDialog);
  };

  const onSave = (event: any) => {
    console.log('editId', editId);
    event.preventDefault();
    let _request: any = {
      id: editId || '',
      name: formData?.name || '',
      type: formData?.schoolType?.name || '',
      period: formData?.classroomPeriod || '',
      schoolId: id || '',
      gradeId: formData?.grade?.grade?.id || '',
      topic: formData?.topic?.id || '',
      classroomTeacher1Ids:
        formData?.classroomTeacher1?.map((item: any) => {
          return item?.id;
        }) || '',
    };
    let allError = Validations.ValidateClassroomDataFrom(_request);
    console.log('formData2', allError);
    if (
      Object.entries(allError).length === 0 &&
      allError.constructor === Object
    ) {
      dispatch(setBtnLoading(true));
      dispatch(addUpdateClassroom(_request)).then(() => {
        dispatch(
          getClassroomList({
            pageCount: 1,
            perPage: 10,
            id,
            grade: params.gradeId,
            topic: params.topicId,
            userId: userId,
          }),
        );
        onClose();
        dispatch(setBtnLoading(false));
      });
    } else {
      setFormError(allError);
    }
  };

  const dialogProps = {
    open,
    onClose,
    onSave,
    title: editId ? 'Edit Classroom' : 'Add Classroom',
    size: 'md',
    saveTbtText: editId ? 'Update' : 'Add',
    closeBtnText: 'Cancel',
    loading: btnLoading,
    screenLoader,
  };

  useEffect(() => {
    setFormData({ ...formData, classroomTeacher1: [JSON.parse(user)] });
  }, []);

  return (
    <MDDialog {...dialogProps}>
      <Box sx={{ p: 3 }}>
        <Grid container spacing={6}>
          <Grid item md={4} sm={6} xs={12}>
            <InputLabel
              error={formError && formError?.name}
              sx={{ color: '#484848', pb: 2 }}
            >
              Classroom Name
            </InputLabel>
            <TextField
              required
              fullWidth
              id="name"
              value={formData?.name || ''}
              onChange={handleChangeInput}
              placeholder="Enter school name"
              name="name"
              error={formError && formError?.name}
              helperText={formError && formError?.name ? formError.name : ''}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <InputLabel
              error={formError && formError?.grade}
              sx={{ color: '#484848', pb: 2 }}
            >
              Grade
            </InputLabel>
            <Autocomplete
              id="grade"
              open={openGrade}
              onOpen={() => setOpenGrade(true)}
              onClose={() => setOpenGrade(false)}
              isOptionEqualToValue={(option, value) =>
                option.grade?.name === value?.grade?.name
              }
              getOptionLabel={(option) =>
                option?.grade?.name || option?.name || ''
              }
              options={navItems}
              loading={loadingGrade}
              filterSelectedOptions
              value={
                formData?.grade?.length > 0
                  ? formData?.grade[0]
                  : formData?.grade || {}
              }
              onChange={handleChangeAutocomplete('grade')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Start Grade"
                  error={formError && formError?.grade}
                  helperText={
                    formError && formError?.grade ? formError.grade : ''
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingGrade ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          {/* <Grid item md={4} sm={12} xs={12}>
            <InputLabel
              error={formError && formError?.classroomType}
              sx={{ color: '#484848', pb: 2 }}
            >
              Classroom type
            </InputLabel>
            <Autocomplete
              id="classroomType"
              open={openClassroomType}
              onOpen={() => setOpenClassroomType(true)}
              onClose={() => setOpenClassroomType(false)}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              getOptionLabel={(option) => option?.name || ''}
              options={classroomType}
              filterSelectedOptions
              value={formData?.classroomType || {}}
              onChange={handleChangeAutocomplete('classroomType')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select District type"
                  error={formError && formError?.classroomType}
                  helperText={
                    formError && formError?.classroomType
                      ? formError.classroomType
                      : ''
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid> */}
          <Grid item md={4} sm={6} xs={12}>
            <InputLabel
              error={formError && formError?.classroomPeriod}
              sx={{ color: '#484848', pb: 2 }}
            >
              Classroom Period
            </InputLabel>
            <TextField
              required
              fullWidth
              id="classroomPeriod"
              value={formData?.classroomPeriod || ''}
              onChange={handleChangeInput}
              placeholder="Enter classroom period"
              name="classroomPeriod"
              error={formError && formError?.classroomPeriod}
              helperText={
                formError && formError?.classroomPeriod
                  ? formError.classroomPeriod
                  : ''
              }
            />
          </Grid>

          {tutorList ? (
            <Grid item md={4} sm={12} xs={12}>
              <InputLabel
                error={formError && formError?.classroomTeacher1Ids}
                sx={{ color: '#484848', pb: 2 }}
              >
                Classroom teacher
              </InputLabel>
              <Autocomplete
                multiple
                disableCloseOnSelect
                id="classroomTeacher1Ids"
                open={openTeacher}
                onOpen={() => setOpenTeacher(true)}
                onClose={() => setOpenTeacher(false)}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option?.name || option?.email || ''}
                renderOption={(props, option, { selected }) => (
                  <li
                    {...props}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    {option?.name || option?.email || ''}
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                  </li>
                )}
                options={tutorList}
                loading={loadingSchoolLeader}
                filterSelectedOptions
                defaultValue={
                  !editId
                    ? [JSON.parse(user)]
                    : formData?.classroomTeacher1?.length > 0
                    ? formData?.classroomTeacher1
                    : []
                }
                value={
                  formData?.classroomTeacher1?.length > 0
                    ? formData?.classroomTeacher1
                    : []
                }
                onChange={handleChangeAutocomplete('classroomTeacher1')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Teacher"
                    error={formError && formError?.classroomTeacher1Ids}
                    helperText={
                      formError && formError?.classroomTeacher1Ids
                        ? formError.classroomTeacher1Ids
                        : ''
                    }
                    style={{ minWidth: 250 }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingDistrict ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          ) : null}

          <Grid item md={4} sm={12} xs={12}>
            <InputLabel
              error={formError && formError?.topic}
              sx={{ color: '#484848', pb: 2 }}
            >
              Department
            </InputLabel>
            <Autocomplete
              disableCloseOnSelect
              id="classroomTeacher1Ids"
              open={openDomain}
              onOpen={() => setOpenDomain(true)}
              onClose={() => setOpenDomain(false)}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              getOptionLabel={(option) => option?.name || option?.id || ''}
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  {option?.name || option?.id || ''}
                </li>
              )}
              options={formData?.grade?.topic || []}
              // loading={loadingSchoolLeader}
              filterSelectedOptions
              value={formData?.topic || []}
              onChange={handleChangeAutocomplete('topic')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Department"
                  error={formError && formError?.topic}
                  helperText={
                    formError && formError?.topic ? formError.topic : ''
                  }
                  style={{ minWidth: 250 }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingDomain ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        {openSkillDialog && (
          <AddSkill
            open={openSkillDialog}
            onClose={handleSkillDialog}
            skill={newSkillValue}
          />
        )}
        {openSubTopicDialog && (
          <AddSubTopic
            open={openSubTopicDialog}
            onClose={handleSubTopicDialog}
            subTopic={newSubTopicValue}
          />
        )}
      </Box>
    </MDDialog>
  );
}

export default Index;
