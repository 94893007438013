import { createAsyncThunk } from '@reduxjs/toolkit';
import API from 'store/api';
import { startLoading, stopLoading } from 'store/Slice/loadingSlice';
import {
  notificationFail,
  notificationSuccess,
} from 'store/Slice/notificationSlice';
import { setTeacherListByClassRoom } from 'store/Slice/teacherSlice';

export const getTeacherListByClassroom = createAsyncThunk(
  'getTeacherListByClassroom',
  async (_request: any, { dispatch }) => {
    dispatch(startLoading());
    const page = _request.pageIndex || 0;
    const limit = _request.pageSize || 10;

    const search = _request?.search || '';
    let url = `api/v1/classroom/${_request.id}/teacher?page=${page + 1
      }&limit=${limit}`;
    try {
      if (search) {
        url += `&query=${search}`;
      }
      const response: any = await API.get(url);
      dispatch(setTeacherListByClassRoom(response.data.data));
      dispatch(stopLoading());
      return response;
    } catch (err: any) {
      dispatch(stopLoading());
      return err;
    }
  },
);

export const updateTeacher = createAsyncThunk(
  'updateStudent',
  async (_request: any, { dispatch }) => {
    const {
      name,
      email,
      classrooms,
      school,
      uuid,
      gradeIds,
      phone,
      teachMethod,
      salutation,
      topicIds,
      zipCode,
    } = _request;
    let url = `api/v1/tutors/${uuid}`;
    try {
      const formData = new FormData();

      if (_request?.file) {
        formData.append('file', _request?.file);
      }

      if (teachMethod == 'teacher') {
        gradeIds.forEach((gradeId: string) =>
          formData.append('gradeIds[]', gradeId),
        );
      }

      if (teachMethod == 'tutor') {
        topicIds.forEach((topicId: string) =>
          formData.append('topicIds[]', topicId),
        );

      }

      formData.append('name', name);
      formData.append('salutation', salutation);
      formData.append('phone', phone);
      formData.append('zipCode', zipCode);
      formData.append('schoolId', school);
      formData.append('classRoomIds', classrooms);
      API.put(url, formData);
    } catch (err: any) {
      dispatch(notificationFail(err.response.data.message));
    }
  },
);

export const deleteTeacher = createAsyncThunk(
  'deleteTeacher',
  async (teacherId: string, { dispatch }) => {
    try {
      const response: any = await API.delete(`api/v1/tutors/${teacherId}`);
      dispatch(notificationSuccess('Teacher Deleted Successfully'));
      return response?.data?.data || [];
    } catch (err: any) {
      dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);
