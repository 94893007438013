import React from 'react';

export default function bellworkIcon() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 15.6121C2 15.9804 2.14695 16.3337 2.40853 16.5942C2.67011 16.8546 3.02489 17.001 3.39481 17.001H13.4431H17.6052C17.9751 17.001 18.3299 16.8546 18.5915 16.5942C18.853 16.3337 19 15.9804 19 15.6121V13.0565C18.4368 12.9038 17.9397 12.5709 17.5853 12.1091C17.231 11.6473 17.039 11.0822 17.039 10.501C17.039 9.91977 17.231 9.3547 17.5853 8.89288C17.9397 8.43106 18.4368 8.09813 19 7.94542V5.38987C19 5.02151 18.853 4.66824 18.5915 4.40777C18.3299 4.14731 17.9751 4.00098 17.6052 4.00098H3.39481C3.02489 4.00098 2.67011 4.14731 2.40853 4.40777C2.14695 4.66824 2 5.02151 2 5.38987V7.93987C2.56797 8.08896 3.07041 8.42106 3.42893 8.88436C3.78744 9.34766 3.98186 9.91609 3.98186 10.501C3.98186 11.0859 3.78744 11.6543 3.42893 12.1176C3.07041 12.5809 2.56797 12.913 2 13.0621V15.6121Z"
        fill="#C73E9D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.5 8.66764C13.7761 8.66764 14 8.9322 14 9.25855V11.7434C14 12.0697 13.7761 12.3343 13.5 12.3343C13.2239 12.3343 13 12.0697 13 11.7434V9.25855C13 8.9322 13.2239 8.66764 13.5 8.66764Z"
        fill="white"
      />
      <path
        d="M13 15.107V17.001H14V15.107C14 14.7807 13.7761 14.5161 13.5 14.5161C13.2239 14.5161 13 14.7807 13 15.107Z"
        fill="white"
      />
      <path
        d="M14 5.90704V4.00098H13V5.90704C13 6.23339 13.2239 6.49795 13.5 6.49795C13.7761 6.49795 14 6.23339 14 5.90704Z"
        fill="white"
      />
    </svg>
  );
}
