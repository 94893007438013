import { Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';

interface types {
  text: string;
  maxLength?: number;
  sx?: React.CSSProperties;
}

const ReadMore = ({ text, maxLength = 35, sx }: types) => {
  const [isReadMore, setIsReadMore] = useState(false);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const displayText = text;

  return (
    <Tooltip
      sx={{ background: '#92929A' }}
      arrow={text?.length < maxLength && true}
      placement="top"
      title={text?.length < maxLength && displayText}
    >
      <Typography
        variant="inherit"
        fontWeight={500}
        color="#84818a"
        onMouseEnter={toggleReadMore}
        onMouseLeave={toggleReadMore}
        className="ellipse-text"
        sx={{
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
          minWidth: maxLength < 35 ? '50px' : '200px',
          cursor: text?.length > maxLength ? 'pointer' : 'default',
          ...sx,
        }}
      >
        {text || ''}
      </Typography>
    </Tooltip>
  );
};

export default ReadMore;
