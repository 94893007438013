const MESSAGES = {
  //login
  LOGIN: {
    ERROR: {
      EMAIL: {
        EMPTY: 'Email is required.',
        EMAIL_VALIDATION: 'Please enter valid email address',
      },
      PASSWORD: {
        EMPTY: 'Password is required.',
        STRONG_PASSWORD: 'Please enter valid password.',
      },
    },
  },
  //register
  REGISTER: {
    ERROR: {
      FIRST_NAME: {
        EMPTY: 'Please enter first name.',
        INVALID: 'Please enter valid first name',
        WHITE_SPACE: 'Name should not contain space',
      },
      LAST_NAME: {
        EMPTY: 'Please enter last name.',
        INVALID: 'Please enter valid last name',
        WHITE_SPACE: 'Name should not contain space',
      },
      EMAIL: {
        EMPTY: 'Please enter email.',
        EMAIL_VALIDATION: 'Please enter valid email address',
      },
      CONFIRM_EMAIL: {
        EMPTY: 'Please enter confirm email.',
        INVALID: "Emails don't match",
      },
      PASSWORD: {
        EMPTY: 'Please enter password.',
        STRONG_PASSWORD: 'Please enter valid password.',
      },
      BUSINESS: {
        EMPTY: 'Please Select One Of Above.',
      },
      PRIVACY: {
        EMPTY: 'Please Select Privacy Policy & terms',
      },
      NOT_MATCH: {
        INVALID: 'Password Did Not Match',
      },
      COMPANY_NAME: {
        EMPTY: 'Please enter company name.',
      },
    },
  },
  //add child
  VIDEO: {
    ERROR: {
      TITLE: {
        EMPTY: 'Please enter title.',
      },
      QUESTION: {
        EMPTY: 'Please enter question.',
      },
      DATE_POSTED: {
        EMPTY: 'Please enter date posted.',
      },
      DISTRICT: {
        EMPTY: 'Please select district.',
      },
      SCHOOL: {
        EMPTY: 'Please select school.',
      },
      TOPIC: {
        EMPTY: 'Please select topic.',
      },
      SUB_TOPIC: {
        EMPTY: 'Please select sub_topic.',
      },
      GRADE: {
        EMPTY: 'Please select grade.',
      },
      SKILL: {
        EMPTY: 'Please select skill.',
      },
      VIDEO: {
        EMPTY: 'Please select video.',
      },
      USER: {
        EMPTY: 'Please select user.',
      },
      FILE: {
        EMPTY: 'Please select video.',
      },
    },
  },
  QUIZ_TEMPLATE: {
    ERROR: {
      TITLE: {
        EMPTY: 'Please enter title.',
      },
      TYPE: {
        EMPTY: 'Please enter type.',
      },
      MESSAGE: {
        EMPTY: 'Please enter message.',
      },
      COLORCODE: {
        EMPTY: 'Please enter color-code.',
      },
      DISTRICT: {
        EMPTY: 'Please select district.',
      },
      SCHOOL: {
        EMPTY: 'Please select school.',
      },
      TOPIC: {
        EMPTY: 'Please select topic.',
      },
      SUB_TOPIC: {
        EMPTY: 'Please select sub_topic.',
      },
      GRADE: {
        EMPTY: 'Please select grade.',
      },
      SKILL: {
        EMPTY: 'Please select skill.',
      },
      TEACHER: {
        EMPTY: 'Please select teacher.',
      },
      VIDEO: {
        EMPTY: 'Please select video.',
      },
    },
  },
  SKILL: {
    ERROR: {
      NAME: {
        EMPTY: 'Please enter name.',
      },
      SEQUENCE_NUMBER: {
        EMPTY: 'Please enter sequence number.',
      },
    },
  },
  DOMAIN: {
    ERROR: {
      NAME: {
        EMPTY: 'Please enter name.',
      },
      TOPIC_ID: {
        EMPTY: 'Please select topic first number.',
      },
    },
  },
  TOPIC: {
    ERROR: {
      NAME: {
        EMPTY: 'Please enter name.',
      },
    },
  },
  GENERATE: {
    ERROR: {
      QUSTION_TYPE: {
        EMPTY: 'Please choose question type.',
      },
      QUESTION_COUNT: {
        EMPTY: 'Please enter question count.',
      },
      QUESTION_LEVEL: {
        EMPTY: 'Please choose question level.',
      },
    },
  },
  QUESTION: {
    ERROR: {
      OPTIONA: {
        EMPTY: 'Please enter option a.',
      },
      OPTIONB: {
        EMPTY: 'Please enter option b.',
      },
      OPTIONC: {
        EMPTY: 'Please enter option c.',
      },
      OPTIOND: {
        EMPTY: 'Please enter option d.',
      },
      ANSWER: {
        EMPTY: 'Please enter answer.',
      },
      QUESTION: {
        EMPTY: 'Please enter question.',
      },
      MESSAGE: {
        EMPTY: 'Please enter message.',
      },
    },
  },
  VALID_PASSWORD: {
    ERROR: {
      EMAIL: {
        EMPTY: 'Email is required.',
        EMAIL_VALIDATION: 'Please enter valid email address',
      },
      PASSWORD: {
        EMPTY: 'Password is required.',
        STRONG_PASSWORD:
          'Password must contain Number, Upper, Lower And One Special Character',
        INVALID_LENGTH: 'Password must be at least 8 characters.',
        SMALL_LETTER: 'Your password must contain at least one small letter.',
        CAPITAL_LETTER:
          'Your password must contain at least one capital letter.',
        DIGIT: 'Your password must contain at least one digit.',
        SPECIAL_CHARACTERS:
          'Your password must contain at least one special characters.',
        WHITE_SPACE: 'Password should not contain space',
      },
      CPASSWORD: {
        EMPTY: 'Please enter confirm password.',
        CONFIRM_PASS_VALIDATION: 'Confirm password shoud be same as password.',
      },
      PARENT_PASSWORD: {
        INVALID:
          'Sorry but that password doesn’t seem to be correct. Please check and try again',
      },
      CURRENT_PASSWORD: {
        EMPTY: 'Required to change any password',
      },
    },
  },
  ADMIN_EDIT: {
    ERROR: {
      NAME: {
        EMPTY: 'Please enter name.',
      },
      EMAIL: {
        EMPTY: 'Please enter email.',
        EMAIL_VALIDATION: 'Please enter valid email address',
      },
      OLD_PASSWORD: {
        EMPTY: 'Please enter old password.',
      },
    },
  },
  VACATION: {
    ERROR: {
      TITLE: {
        EMPTY: 'Please enter Title.',
      },
      COUNTRY: {
        EMPTY: 'Please select country.',
      },
      DATE_PICKER: {
        EMPTY: 'Please select start date and end date.',
      },
    },
  },
  WORKSHEET_MODEL: {
    ERROR: {
      TITLE: {
        EMPTY: 'Title must be required.',
      },
      DESCRIPTION: {
        EMPTY: 'Description must be required.',
      },
      FILE: {
        EMPTY: 'File must be required.',
      },
    },
  },
  MAPPING_LESSON: {
    ERROR: {
      LESSON: {
        EMPTY: 'Lesson must be required.',
      },
      WORKSHEET: {
        EMPTY: 'Worksheet must be required.',
      },
      STAGE: {
        EMPTY: 'Stage must be required.',
      },
      LESSON_NO: {
        EMPTY: 'lesson no must be required.',
        INVALID: 'This lesson no is already assign.',
      },
    },
  },
  VIDEO_FORM: {
    ERROR: {
      TITLE: {
        EMPTY: 'Title must be required.',
      },
      DESCRIPTION: {
        EMPTY: 'Description must be required.',
      },
    },
  },
  DISCOUNT_CODE: {
    ERROR: {
      NAME: {
        EMPTY: 'Name must be required.',
      },
      CODE: {
        EMPTY: 'Discount code must be required.',
      },
      CURRENCY: {
        EMPTY: 'Currency must be required.',
      },
      AMOUNT: {
        EMPTY: 'Amount must be required.',
      },
      EXPIRY_DATE: {
        EMPTY: 'Expiry date must be required.',
      },
      DURATION_IN_MONTH: {
        EMPTY: 'Duration months field must be required.',
      },
      SUBSCRIPTION_PLAN: {
        EMPTY: 'Subscription plan must be required.',
      },
    },
  },
  ADD_PLAN: {
    ERROR: {
      TITLE: {
        EMPTY: 'Title must be required.',
      },
      SUBTITLE: {
        EMPTY: 'Subtitle must be required.',
      },
      NO_OF_STUDENT: {
        EMPTY: 'No of student must be required.',
        ONLYT_NUMBER: 'No of student can contain only numbers.',
      },
      AMOUNT: {
        EMPTY: 'Amount must be required.',
        INVALID_AMOUNT: 'Please enter valid amount.',
      },
      DESCRIPTION: {
        EMPTY: 'Description must be required.',
      },
    },
  },
  DISTRICT: {
    ERROR: {
      NAME: {
        EMPTY: 'Please enter district name',
      },
      NCESDISTRICTID: {
        EMPTY: 'Please enter NCES District ID',
      },
      STREETADDRESS: {
        EMPTY: 'Please enter Street Address',
      },
      CITY: {
        EMPTY: 'Please enter City',
      },
      STATE: {
        EMPTY: 'Please enter State',
      },
      ZIPCODE: {
        EMPTY: 'Please enter Zip Code',
      },
      COUNTY: {
        EMPTY: 'Please enter County',
      },
      DISTRICTTYPE: {
        EMPTY: 'Please enter District Type',
      },
      STARTGRADEID: {
        EMPTY: 'Please select Start Grade',
      },
      ENDGRADEID: {
        EMPTY: 'Please select End Grade',
      },
      FEDERALFUNDING: {
        EMPTY: 'Please enter Federal Funding',
      },
      STATEFUNDING: {
        EMPTY: 'Please enter state Funding',
      },
      LOCALFUNDING: {
        EMPTY: 'Please enter local funding',
      },
      TOTALNOOFSCHOOL: {
        EMPTY: 'Please enter total number of Schools',
      },
      TOTALNOOFELEMENTARYSCHOOL: {
        EMPTY: 'Please enter total number of elementary School',
      },
      TOTALNOOFMIDDLESCHOOL: {
        EMPTY: 'Please enter total number of Middle School',
      },
      TOTALNOOFHIGHSCHOOL: {
        EMPTY: 'Please enter total number of High School',
      },
    },
  },
  SCHOOLLEADER: {
    ERROR: {
      NAME: {
        EMPTY: 'Please enter school name',
      },
      SCHOOLTYPE: {
        EMPTY: 'Please select school type',
      },
      STREETADDRESS: {
        EMPTY: 'Please enter Street Address',
      },
      CITY: {
        EMPTY: 'Please enter City',
      },
      STATE: {
        EMPTY: 'Please enter State',
      },
      ZIPCODE: {
        EMPTY: 'Please enter Zip Code',
      },
      COUNTY: {
        EMPTY: 'Please enter County',
      },
      SPECIFICSCHOOLTYPE: {
        EMPTY: 'Please select Type',
      },
      SCHOOLGRADES: {
        EMPTY: 'Please select Grade',
      },
      PHONE: {
        EMPTY: 'Please enter phone number',
      },
      DISTRICTID: {
        EMPTY: 'Please select district',
      },
      NCESSCHOOLID: {
        EMPTY: 'Please enter school id',
      },
      SCHOOLLEADERIDS: {
        EMPTY: 'Please select school leaders',
      },
    },
  },
  CLASSROOM: {
    ERROR: {
      NAME: {
        EMPTY: 'Please enter classroom name',
      },
      CLASSROOMTYPE: {
        EMPTY: 'Please select classroom type',
      },
      CLASSROOMPERIOD: {
        EMPTY: 'Please enter classroom period',
      },
      GRADE: {
        EMPTY: 'Please select classroom grade',
      },
      TOPIC: {
        EMPTY: 'Please select classroom department',
      },
      CLASSROOMTEACHER1IDS: {
        EMPTY: 'Please select teachers',
      },
    },
  },
  PARENT: {
    ERROR: {
      EMPTY: 'Please select classroom grade',
    },
  },
  GROUP: {
    ERROR: {
      GROUPNAME: {
        EMPTY: 'Please enter group name',
      },
      STUDENTIDS: {
        EMPTY: 'Please select members of group',
      },
    },
  },
  ADD_STUDENT: {
    ERROR: {
      FUll_NAME: {
        EMPTY: 'Please enter full name',
      },
      FIRST_NAME: {
        EMPTY: 'Please enter first name',
      },
      LAST_NAME: {
        EMPTY: 'Please enter last name',
      },
      EMAIL: {
        EMPTY: 'Please enter email address',
        INVALID: 'Please enter a valid email address',
      },
      STUDENT_ID: {
        EMPTY: 'Please enter student ID',
        INVALID: 'Please enter a valid student ID',
      },
      GRADE: {
        EMPTY: 'Please select grade',
      },
      ROLE: {
        EMPTY: 'Please select role',
      },
      PARENT: {
        EMPTY: 'Please select parent',
      },
      SCHOOL: {
        EMPTY: 'Please select school',
      },
      CLASSROOM: {
        EMPTY: 'Please select classroom',
      },
    },
  },
  ADD_PARENT: {
    ERROR: {
      NAME: {
        EMPTY: 'Please enter full name',
      },
      STATE: {
        EMPTY: 'Please select state',
      },
      SALUTATION: {
        EMPTY: 'Please enter salutation',
      },
      FIRST_NAME: {
        EMPTY: 'Please enter first name',
      },
      LAST_NAME: {
        EMPTY: 'Please enter last name',
      },
      EMAIL: {
        EMPTY: 'Please enter email address',
        INVALID: 'Please enter a valid email address',
      },
      ROLE: {
        EMPTY: 'Please select role',
      },
      SCHOOL: {
        EMPTY: 'Please select school',
      },
      CHILDREN: {
        EMPTY: "Please select children's",
      },
    },
  },
};

export default MESSAGES;
