import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  TextField,
} from '@mui/material';
import Validations from 'helper/validations';
import { useEffect, useState } from 'react';
import { addUpdateSkill } from 'store/Thunk/skillThunk';
import { useAppDispatch } from 'store/store';

interface DialogProps {
  open: boolean;
  skill: any;
  onClose: (name: string) => void;
  setParentFormData?: any;
}

function Index(props: DialogProps) {
  const dispatch = useAppDispatch();
  const { onClose, skill, open, setParentFormData } = props;
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<any>({
    name: '',
    sequenceNumber: '',
  });
  const [formError, setFormError] = useState<any>({});

  useEffect(() => {
    setFormData({
      ...formData,
      name: skill?.name || '',
    });
  }, [skill]);

  const handleClose = () => {
    onClose('');
  };

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event: any) => {
    let _request = {
      name: formData?.name?.trim() || '',
      sequenceNumber: formData?.sequenceNumber || '',
      topicId: skill?.topic?.id || '',
      subTopicId: skill?.subTopic?.id || '',
    };

    const allError = Validations.validateSkillForm(_request);
    if (
      Object.entries(allError).length === 0 &&
      allError.constructor === Object
    ) {
      setLoading(true);
      dispatch(addUpdateSkill(_request))
        .unwrap()
        .then((res) => {
          setLoading(false);
          if (res) {
            setParentFormData((prev: any) => {
              handleClose();
              return {
                ...prev,
                skill: res,
              };
            });
          }
        });
    } else {
      setFormError(allError);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add a new skill</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          Did you miss any skill in our list? Please, add it!
        </DialogContentText>
        <Box display={'flex'} flexDirection={'column'}>
          <InputLabel sx={{ color: '#484848', mb: 1 }}>Name</InputLabel>
          <TextField
            fullWidth
            placeholder="Name"
            name="name"
            onChange={handleChange}
            value={formData?.name || ''}
            error={formError && formError?.name}
            helperText={formError && formError?.name ? formError.name : ''}
          />
          <InputLabel sx={{ color: '#484848', mt: 4, mb: 1 }}>
            Sequence Number
          </InputLabel>
          <TextField
            fullWidth
            placeholder="Sequence Number"
            name="sequenceNumber"
            onChange={handleChange}
            value={formData?.sequenceNumber}
            error={formError && formError?.sequenceNumber}
            helperText={
              formError && formError?.sequenceNumber
                ? formError.sequenceNumber
                : ''
            }
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="inherit">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          className="primary_bg_btn"
          disabled={loading}
          sx={{ minWidth: '140px' }}
        >
          {loading ? (
            <CircularProgress size={24} color={'secondary'} />
          ) : (
            'Create'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Index;
