import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

interface QuestionDialogProps {
  open: boolean;
  onClose: () => void;
  questionType: string;
  handleQuestionTypeChange: (event: SelectChangeEvent<string>) => void;
  questionMessage: string;
  handleQuestionMessageChange: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
}

const QuestionDialog: React.FC<QuestionDialogProps> = ({
  open,
  onClose,
  questionType,
  handleQuestionTypeChange,
  questionMessage,
  handleQuestionMessageChange,
}) => {
  //   const [questionType, setQuestionType] = useState<string>('text');
  //   const [questionMessage, setQuestionMessage] = useState<string>('');

  //   const handleQuestionTypeChange = (event: SelectChangeEvent<string>) => {
  //     setQuestionType(event.target.value);
  //   };

  //   const handleQuestionMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setQuestionMessage(event.target.value);
  //   };

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    // if(questionMessage){
    // }
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add Question</DialogTitle>
      <DialogContent>
        <FormControl fullWidth sx={{ marginTop: '10px' }}>
          <InputLabel id="question-type-label">Question Type</InputLabel>
          <Select
            labelId="question-type-label"
            id="question-type"
            fullWidth
            value={questionType}
            onChange={handleQuestionTypeChange}
            label="Question Type"

          sx={{minWidth:'250px'}}
          >
            <MenuItem value="text">Text</MenuItem>
            <MenuItem value="image">Image</MenuItem>
            <MenuItem value="video">Video</MenuItem>
          </Select>
        </FormControl>
        {/* <TextField
          autoFocus
          margin="dense"
          id="question-message"
          label="Question Message"
          type="text"
          fullWidth
          value={questionMessage}
          onChange={handleQuestionMessageChange}
          error={questionMessage.trim() === ''} 
          helperText={
            questionMessage.trim() === '' ? 'Please enter question message' : ''
          }
          required
          sx={{ marginTop: '16px' }}
        /> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuestionDialog;
