import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Stack,
  Breadcrumbs,
  Link,
  AvatarGroup,
  Card,
} from '@mui/material';
import CustomTable from 'components/customTable';
import DefaultCell from 'components/DefaultCell';
import { EditIcon, DeleteIcon, ViewIcon } from 'icons';
import Profile from 'assets/actionIcons/profile.png';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AddUpdate from 'components/dialog/dailyChallenges/addUpdate';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useNavigate } from 'react-router-dom';
import { displayMultipleValue, getLocalDateTime } from 'helper/service';
import { route_info } from 'navigation/route';
import { Base } from '../../../../assets';
import DeleteDailyChallenge from 'components/dialog/dailyChallenges/deleteDailyChallenges';
import { getDailyChallengesList } from 'store/Thunk/dailyChallengesThunk';
import Avatar from '@core/components/mui/avatar';
import ReadMore from 'components/ReadMore/ReadMore';

function handleBreadCrumbClick(
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const breadcrumbs = [
  <Link
    underline="hover"
    key="1"
    color="inherit"
    href="/"
    onClick={() => handleBreadCrumbClick}
  >
    Resources
  </Link>,
  <Typography key="2" color="#C73E9D">
    Daily Challenge
  </Typography>,
];

function Index() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { dailyChallengesList } = useAppSelector(
    (state: any) => state.dailyChallengesReducer,
  );

  const [isOpen, setIsOpen] = useState(false);
  const [dailyChallenges, setDailyChallenges] = useState<any[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPageSize] = useState(10);
  const [search, setSearch] = useState<any>(null);
  const [totalCount, setTotalCount] = useState(0);
  const [editId, setEditId] = useState('');
  const [openDelete, setOpenDelete] = React.useState(false);
  const [deleteId, setDeleteId] = useState('');

  const fetchIdRef = useRef(0);

  useEffect(() => {
    if (dailyChallengesList && dailyChallengesList?.items) {
      setDailyChallenges(dailyChallengesList?.items);
      setPageCount(dailyChallengesList?.meta?.lastPage);
      setTotalCount(dailyChallengesList?.meta?.total);
    }
  }, [dailyChallengesList]);

  useEffect(() => {
    dispatch(getDailyChallengesList({ perPage, pageNo, search }));
  }, []);

  const handleAddUpdateChallenges = () => {
    setEditId('');
    setIsOpen(!isOpen);
  };

  const handleEditClick = (id: string) => {
    setEditId(id);
    setIsOpen(!isOpen);
  };

  const handleDeleteDialog = (id: string) => {
    setDeleteId(id);
    setOpenDelete(!openDelete);
  };

  const columns = [
    {
      Header: 'Grade',
      accessor: 'grade',
      Cell: ({ value }: any) => {
        if (value.length) {
          return (
            <DefaultCell value={displayMultipleValue(value, 'grade') || '-'} />
          );
        } else {
          return '-';
        }
      },
    },
    {
      Header: 'Title',
      accessor: 'title',
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: 'User Created',
      accessor: 'user_created',
      Cell: ({ value }: any) => (
        <Stack direction="row" spacing={2} alignItems="center">
          <Avatar
            alt={value?.name}
            src={value?.profileImage || Profile}
            sx={{ width: 28, height: 28 }}
          />
          {/* <Box component="img" src={value?.profileImage || Profile} /> */}
          <Box>{value?.email}</Box>
        </Stack>
      ),
    },
    {
      Header: 'Topic',
      accessor: 'topic',
      Cell: ({ value }: any) => {
        if (value.length > 0) {
          return <DefaultCell value={value[0]?.topic?.name || '-'} />;
        } else {
          return '-';
        }
      },
    },
    {
      Header: 'Sub Topic',
      accessor: 'sub_topic',
      Cell: ({ value }: any) => {
        if (value.length) {
          return <ReadMore text={value[0]?.subTopic?.name || '-'} />;
        } else {
          return '-';
        }
      },
    },
    {
      Header: 'Skill',
      accessor: 'skill',
      Cell: ({ value }: any) => {
        if (value.length) {
          return (
            <DefaultCell
              value={
                `${value[0]?.skill?.sequenceNumber} - ${value[0]?.skill?.name}` ||
                '-'
              }
            />
          );
        } else {
          return '-';
        }
      },
    },
    {
      Header: 'Date Posted',
      accessor: 'date',
      Cell: ({ value }: any) => <DefaultCell value={value} />,
    },
    {
      Header: 'Student Replies',
      accessor: 'student_replies',
      Cell: ({ value }: any) => {
        if (value?.StudentReplies?.length > 0) {
          return (
            <AvatarGroup
              total={6}
              spacing={'small'}
              onClick={() => {
                navigate(
                  route_info?.content_management?.student_replies.replace(
                    ':id',
                    value?.id,
                  ),
                );
              }}
            >
              <Avatar alt="Eureka" src={Base} />
              <Avatar alt="Eureka" src={Base} />
              <Avatar alt="Eureka" src={Base} />
              <Avatar alt="Eureka" src={Base} />
              <Avatar alt="Eureka" src={Base} />
              <Avatar alt="Eureka" src={Base} />
            </AvatarGroup>
          );
        } else {
          return '-';
        }
      },
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: ({ value }: any) => (
        <Box>
          <IconButton onClick={() => handleEditClick(value.id)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDeleteDialog(value.id)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];
  const getRows = () => {
    const data: any = dailyChallenges.map((obj: any) => ({
      id: obj?.id || '',
      title: obj?.title || '-',
      date: obj?.createdAt ? getLocalDateTime(obj?.createdAt) : '-',
      user_created: obj?.createdBy,
      topic: obj?.topic || {},
      sub_topic:
        obj?.sequence != null
          ? `${obj?.sequence}${obj?.subTopic}`
          : obj?.subTopic || {},
      grade: obj?.grade || {},
      skill:
        obj?.sequence != null
          ? `${obj?.sequence}${obj?.skill}`
          : obj?.skill || {},
      student_replies: obj?.StudentReplies,
      action: obj,
    }));
    return data;
  };

  const tableData = {
    columns: columns,
    rows: getRows(),
  };

  const fetchData = useCallback(({ pageSize, pageIndex, search }: any) => {
    const fetchId = ++fetchIdRef.current;

    // We'll even set a delay to simulate a server here
    setTimeout(() => {
      if (fetchId === fetchIdRef.current) {
        setPageNo(pageIndex);
        setPageSize(pageSize);
        setSearch(search);
        dispatch(getDailyChallengesList({ pageSize, pageIndex, search }));
      }
    }, 1000);
  }, []);

  return (
    <Box>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        style={{ marginBottom: 20 }}
      >
        {breadcrumbs}
      </Breadcrumbs>
      <Card sx={{ p: '24px' }}>
        <CustomTable
          table={tableData}
          fetchData={fetchData}
          canSearch
          title="Daily Challenge"
          buttonTitle="Create Daily Challange"
          searchText="Search..."
          pageCount={pageCount}
          pageNo={pageNo}
          totalCount={totalCount}
          handleOnChange={handleAddUpdateChallenges}
        />
      </Card>
      {isOpen && (
        <AddUpdate
          editId={editId}
          open={isOpen}
          onClose={handleAddUpdateChallenges}
        />
      )}
      {openDelete && (
        <DeleteDailyChallenge
          open={openDelete}
          deleteId={deleteId}
          onClose={handleDeleteDialog}
        />
      )}
    </Box>
  );
}

export default Index;
