import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

interface IntialState {
  teacherListByClassRoom: any;
}

const intialState: IntialState = {
  teacherListByClassRoom: [],
};

const TeacherSlice = createSlice({
  name: 'Teacher_slice',
  initialState: intialState,
  reducers: {
    setTeacherListByClassRoom: (
      state: Draft<IntialState>,
      action: PayloadAction<[]>,
    ) => {
      state.teacherListByClassRoom = action.payload;
    },
  },
});

export const { setTeacherListByClassRoom } = TeacherSlice.actions;

export default TeacherSlice.reducer;
