interface BarData {
  title: string;
  color: string;
  value: number;
}

interface TabData {
  date: string; // grouping ie 6th, 7th, 8th, 9th, 10th
  data: BarData[];
}

export interface SkillsMasteredBarChartData {
  tableTitle: string;
  tableData: TabData[];
}

export const tabs = ['Skills mastered', 'Watched hours'];
export const tabsAssignment = [
  { name: 'Bellwork', value: 'Bellwork' },
  { name: 'Exit Tickets', value: 'Exit_Tickets' },
  { name: 'Unit Quiz', value: 'Unit_Quiz' },

  { name: 'Diagnostic', value: 'Diagnostic' },
  { name: 'Daily Practice', value: 'Daily_Practice' },
];

const items = [
  {
    title: 'Bell work',
    color: '#DEF2ED',
    value: 10,
  },
  {
    title: 'Exit tickets',
    color: '#BAE3DB',
    value: 40,
  },
  {
    title: 'Unit quiz',
    color: '#20A58A',
    value: 70,
  },
  {
    title: 'Daily practice',
    color: '#0B3A30',
    value: 30,
  },
  {
    title: 'Actual cont',
    color: '#1A846E',
    value: 10,
  },
  {
    title: 'Actual cont',
    color: '#136353',
    value: 10,
  },
];

const data = [
  {
    tableTitle: 'Skills mastered',
    tableData: [
      {
        date: '6th',
        data: items,
      },
      {
        date: '7th',
        data: items,
      },
      {
        date: '8th',
        data: items,
      },
      {
        date: '9th',
        data: items,
      },
      {
        date: '10th',
        data: items,
      },
    ],
  },
  {
    tableTitle: 'Watched hours',
    tableData: [
      {
        date: '6th',
        data: items,
      },
      {
        date: '7th',
        data: items,
      },
      {
        date: '8th',
        data: items,
      },
      {
        date: '9th',
        data: items,
      },
      {
        date: '10th',
        data: items,
      },
    ],
  },
];

function getRandomInt(min: any, max: any) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// Function to generate more random graph data
export const scrambledData = data.map(
  (category: SkillsMasteredBarChartData) => ({
    tableTitle: category.tableTitle,
    tableData: category.tableData.map((entry: TabData) => ({
      date: entry.date,
      data: entry.data.map((item: any) => ({
        title: item.title,
        color: item.color,
        value: getRandomInt(0, 100),
      })),
    })),
  }),
);

export const getUniqueSkills = (
  data: SkillsMasteredBarChartData[],
  selectTab: string,
) => {
  const indexOfSelectedTab = data.findIndex(
    (item) => item.tableTitle === selectTab,
  );

  if (indexOfSelectedTab === -1) return [];

  const selectedTable = data[indexOfSelectedTab];
  const uniqueSkills = new Map();

  selectedTable.tableData.forEach((entry: any) => {
    entry.data.forEach((skill: any) => {
      const key = skill.title;
      if (!uniqueSkills.has(key)) {
        uniqueSkills.set(key, skill);
      }
    });
  });

  return Array.from(uniqueSkills.values()).map((item: any, index: number) => ({
    ...item,
    color: colors[index] || colors[0],
  }));
};

export const groupByIndex = (inputArray: any) => {
  const numRows = inputArray.length;
  const numCols = Math.max(...inputArray.map((row: any) => row.length));

  const resultArray = Array.from({ length: numCols }, () => []);

  for (let col = 0; col < numCols; col++) {
    for (let row = 0; row < numRows; row++) {
      if (inputArray[row][col] !== undefined) {
        resultArray[col].push(inputArray[row][col] as never);
      }
    }
  }

  return resultArray;
};

export const colors = [
  '#DEF2ED',
  '#BAE3DB',
  '#20A58A',
  '#0B3A30',
  '#1A846E',
  '#136353',
  '#0B3A30',
  '#ADD8E6',
  '#A9A9A9',
];
export const colors2 = [
  '#F9ECF5',
  '#EEC3E1',
  '#C73E9D',
  '#952F76',
  '#5A1C47',
  '#20A58A',
  // '#0B3A30',
  // '#ADD8E6',
  // '#A9A9A9',
];

export default data;
