// *** React Imports
import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  Autocomplete,
  CircularProgress,
  InputLabel,
  createFilterOptions,
  Stack,
  Typography,
  Chip,
  Checkbox,
  Paper,
  Divider,
  FormControlLabel,
  List,
} from '@mui/material';

// *** Redux Imports
import { useAppDispatch, useAppSelector } from 'store/store';
import {
  getTopicList,
  getSubTopicList,
  getSkillList,
  getGradeList,
  getMuxUploadUrl,
  getVideoList,
} from 'store/Thunk/videoThunk';

// *** Custom Components
import FileUpload from 'components/fileUpload';

import MDDialog from '@core/components/MDDialog';

// *** Assets
import MuxVideoPlayer from 'components/fileUpload/MuxVideoPlayer';
import Validations from 'helper/validations';
import {
  setBtnLoading,
  startLoading,
  stopLoading,
} from 'store/Slice/loadingSlice';
import { getAuthUser, getLocalDateTime, getOptionLabel } from 'helper/service';

import AddSkill from 'components/dialog/skill/addSkill';
import {
  addUpdateChallenge,
  getDailyChallengesById,
} from 'store/Thunk/dailyChallengesThunk';
import { setDailyChallengesById } from 'store/Slice/dailyChallengesSlice';
import AddSubTopic from 'components/dialog/subtopic/addSubTopic';
import {
  getDistrictList,
  getSchoolsInDistricts,
} from 'store/Thunk/districtThunk';
import { getSchoolList } from 'store/Thunk/schoolThunk';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { notificationFail } from 'store/Slice/notificationSlice';
import SelectDistrictAndSchool from 'components/selectDistrictAndSchool';
import { getDomainList } from 'store/Thunk/userThunk';
interface Props {
  handleCancel?: any;
}

const filter = createFilterOptions();

function Index({ editId, open, onClose }: any) {
  const dispatch: any = useAppDispatch();
  const {
    topicList,
    subtopicList,
    skillList,
    gradeList,
    uploadUrl,
    videoList,
  } = useAppSelector((state: any) => state.videoReducer);
  const { userList } = useAppSelector((state: any) => state.userReducer);
  const { domainList } = useAppSelector((state: any) => state.userReducer);

  const { btnLoading, screenLoading } = useAppSelector(
    (state: any) => state.loadingReducer,
  );
  const { dailyChallengesObj } = useAppSelector(
    (state: any) => state.dailyChallengesReducer,
  );
  const { districtList } = useAppSelector(
    (state: any) => state.districtReducer,
  );
  const { schoolList } = useAppSelector((state: any) => state.schoolReducer);

  const [uploadedFile, setUploadedFile]: any = useState<any[]>([]);

  const [formData, setFormData] = useState<any>({
    grade: [],
    skill: [],
    school: [],
  });

  const [formError, setFormError] = useState<any>({});
  const [screenLoader, setScreenLoader] = React.useState(false);
  const [loadingDistrict, setLoadingDistrict] = React.useState(false);
  const [loadingSchool, setLoadingSchool] = React.useState(false);
  const [loadingTopic, setLoadingTopic] = React.useState(false);
  const [loadingSubTopic, setLoadingSubTopic] = React.useState(false);
  const [loadingGrade, setLoadingGrade] = React.useState(false);
  const [loadingDomain, setLoadingDomain] = React.useState(false);

  const [loadingSkill, setLoadingSkill] = React.useState(false);
  const [loadingUser, setLoadingUser] = React.useState(false);
  const [loadingVideo, setLoadingVideo] = React.useState(false);
  const [openDistrict, setOpenDistrict] = React.useState(false);
  const [openSchool, setOpenSchool] = React.useState(false);
  const [openTopic, setOpenTopic] = React.useState(false);
  const [openSubTopic, setOpenSubTopic] = React.useState(false);
  const [openGrade, setOpenGrade] = React.useState(false);
  const [openSkill, setOpenSkill] = React.useState(false);
  const [openUser, setOpenUser] = React.useState(false);
  const [openVideo, setOpenVideo] = React.useState(false);
  const [district, setDistrict] = React.useState<any[]>([]);
  const [school, setSchool] = React.useState<any[]>([]);
  const [topic, setTopic] = React.useState<any[]>([]);
  const [subTopic, setSubTopic] = React.useState<any[]>([]);
  const [grade, setGrade] = React.useState<any[]>([]);
  const [skill, setSkill] = React.useState<any[]>([]);
  const [user, setUser] = React.useState<any[]>([]);
  const [video, setVideo] = React.useState<any[]>([]);
  const [selectAllSchool, setSelectAllSchool] = useState(false);
  const [selectAllDistricts, setSelectAllDistricts] = useState(false);
  const [openDomain, setOpenDomain] = React.useState(false);

  const [openSubTopicDialog, setOpenSubTopicDialog] = React.useState(false);
  const [newSubTopicValue, setNewSubTopicValue] = React.useState({});
  const [openSkillDialog, setOpenSkillDialog] = React.useState(false);
  const [newSkillValue, setNewSkillValue] = React.useState({});

  const [videoProgress, setVideoProgress] = React.useState(0);

  const authUser = getAuthUser();

  const handleToggleSelectAll = (name: string) => {
    const updateFormData = (field: string, value: any) => {
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        [field]: value,
      }));
    };

    if (name === 'school') {
      setSelectAllSchool((prev) => {
        const updatedList = !prev ? schoolList : [];
        updateFormData(name, updatedList);
        return !prev;
      });
    }
    if (name === 'district') {
      setSelectAllDistricts((prev) => {
        const updatedList = !prev ? districtList?.items : [];
        updateFormData(name, updatedList);
        return !prev;
      });
    }
  };

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        if (Array.isArray(formData?.district) && openSchool) {
          const districtIds = formData?.district?.map(
            (item: any) => item?.ncesDistrictId,
          );
          setLoadingSchool(true);
          dispatch(getSchoolsInDistricts(districtIds));
        } else {
          setSchool([]);
        }
      } catch (error) {
        console.error('Error fetching school data:', error);
      } finally {
        setLoadingSchool(false);
      }
    };

    fetchSchools();
  }, [openSchool, formData?.district, dispatch]);

  useEffect(() => {
    if (openDomain) {
      dispatch(getDomainList(''));
      setLoadingDomain(openDomain);
    } else {
      setTopic([]);
    }
  }, [openDomain]);
  useEffect(() => {
    if (authUser) {
      setUser([{ ...authUser }]);
    }
    dispatch(setDailyChallengesById({}));
  }, []);

  useEffect(() => {
    setScreenLoader(screenLoading);
  }, [screenLoading]);

  useEffect(() => {
    if (editId) {
      dispatch(getDailyChallengesById({ id: editId }));
    }
  }, [editId]);

  useEffect(() => {
    if (
      Object.entries(dailyChallengesObj).length &&
      dailyChallengesObj.constructor === Object &&
      editId
    ) {
      setFormData({
        ...formData,
        id: dailyChallengesObj?.id || '',
        title: dailyChallengesObj?.title || '',
        question: dailyChallengesObj?.question || '',
        date_posted: moment(dailyChallengesObj?.datePosted) || null,
        district: dailyChallengesObj?.district.map(
          (item: any) => item?.district,
        ),
        topic: dailyChallengesObj?.topic[0]?.topic || '',
        school: dailyChallengesObj?.school.map((item: any) => item?.school),
        sub_topic: dailyChallengesObj?.subTopic[0]?.subTopic || '',
        grade: dailyChallengesObj?.grade?.map((obj: any) => obj.grade),
        video: dailyChallengesObj?.video[0]?.video || '',
        skill: dailyChallengesObj?.skill[0]?.skill || '',
        assetId: dailyChallengesObj?.assetId || '',
        playbackId: dailyChallengesObj?.playbackId || '',
      });
    }
  }, [dailyChallengesObj]);

  useEffect(() => {
    if (openDistrict) {
      dispatch(getDistrictList({ pageIndex: 0, pageSize: 1000 }));
      setLoadingDistrict(openDistrict);
    } else {
      setDistrict([]);
    }
  }, [openDistrict]);

  useEffect(() => {
    if (formData?.district?.id && openSchool) {
      dispatch(getSchoolList({ district: formData?.district }));
      setLoadingSchool(openSchool);
    } else {
      setSchool([]);
    }
  }, [openSchool, formData?.district]);

  useEffect(() => {
    if (openTopic) {
      dispatch(getTopicList());
      setLoadingTopic(openTopic);
    } else {
      setTopic([]);
    }
  }, [openTopic]);

  useEffect(() => {
    if (formData?.topic?.id && openSubTopic) {
      dispatch(getSubTopicList({ topic: formData?.topic }));
      setLoadingSubTopic(openSubTopic);
    } else {
      setSubTopic([]);
    }
  }, [openSubTopic, formData?.topic]);

  useEffect(() => {
    if (openGrade) {
      dispatch(getGradeList());
      setLoadingGrade(openGrade);
    } else {
      setGrade([]);
    }
  }, [openGrade]);

  useEffect(() => {
    if (openVideo) {
      dispatch(getVideoList({ pageIndex: 0, pageSize: 100, search: '' }));
      setLoadingVideo(openVideo);
    } else {
      setVideo([]);
    }
  }, [openVideo]);

  useEffect(() => {
    if (formData?.sub_topic?.id && openSkill) {
      dispatch(getSkillList({ subTopic: formData?.sub_topic }));
      setLoadingSkill(openSkill);
    } else {
      setSkill([]);
    }
  }, [openSkill, formData?.sub_topic]);

  useEffect(() => {
    setLoadingDistrict(false);
    setDistrict(districtList?.items || []);
  }, [districtList]);

  useEffect(() => {
    setLoadingSchool(false);
    if (schoolList && schoolList?.items) {
      setSchool(schoolList.items);
    } else {
      setSchool(schoolList);
    }
  }, [schoolList]);

  useEffect(() => {
    setLoadingTopic(false);
    setTopic(topicList);
  }, [topicList]);

  useEffect(() => {
    setLoadingSubTopic(false);
    setSubTopic(subtopicList);
  }, [subtopicList]);

  useEffect(() => {
    setLoadingGrade(false);
    setGrade(gradeList || []);
  }, [gradeList]);

  useEffect(() => {
    setLoadingVideo(false);
    setVideo(videoList?.items || []);
  }, [videoList]);

  useEffect(() => {
    setLoadingSkill(false);
    setSkill(skillList);
  }, [skillList]);

  useEffect(() => {
    setLoadingUser(false);
    setUser(userList);
  }, [userList]);

  useEffect(() => {
    setFormError({ ...formError, videoFile: '' });
  }, [uploadedFile]);

  let UpChunk: any = null;
  if (typeof window !== 'undefined') {
    UpChunk = require('@mux/upchunk');
  }

  useEffect(() => {
    // dispatch(getUserList())
    dispatch(getMuxUploadUrl());
  }, []);

  const handleChangeAutocomplete =
    (name: string) =>
    (
      event: React.SyntheticEvent<Element, Event>,
      newValue: string | null | any,
    ) => {
      let param = {
        [name]: newValue,
        ...(name === 'topic' && {
          sub_topic: '',
          skill: '',
        }),
        ...(name === 'sub_topic' && {
          skill: '',
        }),
        ...(name === 'district' && {
          school: [],
        }),
      };
      setFormData({ ...formData, ...param });
    };

  const handleSubTopicDialog = (name: string = '') => {
    if (!formData.topic && !formData.sub_topic) {
      dispatch(notificationFail('Please Select Topic and Sub Topic First'));
      return;
    }
    setNewSubTopicValue({
      ...(!openSubTopicDialog
        ? {
            topic: formData.topic,
            name: name,
          }
        : {}),
    });
    setOpenSubTopicDialog(!openSubTopicDialog);
  };

  // Autocomplete onChange method for select and create a options
  const handleChangeCreateSubTopic = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string | null | any,
  ) => {
    if (typeof newValue === 'string') {
      // timeout to avoid instant validation of the dialog's form.
      setTimeout(() => {
        handleSubTopicDialog(newValue);
      });
    } else if (newValue && newValue.inputValue) {
      handleSubTopicDialog(newValue.inputValue);
    } else {
      setFormData({
        ...formData,
        sub_topic: newValue,
      });
    }
  };

  const handleFilterSubTopic = (options: any, params: any) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    const isExisting = options.some(
      (option: any) => inputValue === option.name,
    );
    if (inputValue !== '' && !isExisting && formData?.topic) {
      filtered.push({
        inputValue,
        name: `Add New "${inputValue}"`,
      });
    }

    return filtered;
  };

  // Autocomplete onChange method for select and create a options
  const handleChangeCreateOptions =
    (name: string) =>
    (
      event: React.SyntheticEvent<Element, Event>,
      newValue: string | null | any,
    ) => {
      if (typeof newValue === 'string') {
        // timeout to avoid instant validation of the dialog's form.
        setTimeout(() => {
          handleSkillDialog(newValue);
        });
      } else if (newValue && newValue.inputValue) {
        handleSkillDialog(newValue.inputValue);
      } else {
        setFormData({
          ...formData,
          skill: newValue,
        });
      }
    };

  const handleFilterOptions = (options: any, params: any) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some(
      (option: any) => inputValue === option.name,
    );
    if (inputValue !== '' && !isExisting && formData?.sub_topic) {
      filtered.push({
        inputValue,
        name: `Add "${inputValue}"`,
      });
    }

    return filtered;
  };

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSkillDialog = (name: string = '') => {
    if (!formData.topic && !formData.sub_topic) {
      dispatch(notificationFail('Please Select Topic and Sub Topic First'));
      return;
    }
    if (!formData.topic || !formData.sub_topic) {
      const message = !formData.topic
        ? 'Please Select Topic First'
        : 'Please Select SubTopic First';

      dispatch(notificationFail(message));
      return;
    }
    setNewSkillValue({
      ...(!openSkillDialog
        ? {
            topic: formData.topic,
            name: name,
            subTopic: formData.sub_topic,
          }
        : {}),
    });
    setOpenSkillDialog(!openSkillDialog);
  };

  const handleRemoveVideo = (event: any) => {
    setFormData({ ...formData, assetId: '', playbackId: '' });
  };

  const handleDateChange = (name: string) => (date: any) => {
    setFormData({ ...formData, [name]: date });
  };

  const onSave = (event: any) => {
    event.preventDefault();
    let _request: any = {
      id: formData?.id || '',
      title: formData.title?.trim(),
      question: formData.question?.trim(),
      datePosted: getLocalDateTime(formData.date_posted, 'MM/DD/YYYY') || '',
      districtIds:
        (Array.isArray(formData?.district) &&
          formData?.district?.map((item: any) => item.id)) ||
        [],
      schoolIds:
        Array.isArray(formData?.school) && formData?.school?.length > 0
          ? formData?.school.map((item: any) => item.id)
          : [],
      topicId: formData.topic?.id || '',
      subTopicId: formData.sub_topic?.id || '',
      skillId: formData?.skill?.id || '',
      userId: authUser?.id || '',
      gradeIds: formData?.grade.map((grade: any) => grade?.id),
      videoId: formData?.video?.id || '',
      videoFile: uploadedFile,
      uploadId: uploadedFile.length ? uploadUrl?.id : '',
      domainId: formData?.domain?.id,
    };

    let allError = Validations.ValidateDailyChallengeFrom(_request);

    if (
      Object.entries(allError).length === 0 &&
      allError.constructor === Object
    ) {
      dispatch(setBtnLoading(true));
      if (uploadUrl?.url && uploadedFile.length) {
        const muxUpload = UpChunk.createUpload({
          endpoint: uploadUrl?.url,
          file: uploadedFile[0],
          chunkSize: 5120, // Uploads the file in ~5mb chunks
        });

        // subscribe to events
        muxUpload.on('error', (error: any) => {
          setVideoProgress(0);
          console.error('💥 🙀', error.detail);
          dispatch(stopLoading());
        });

        muxUpload.on('progress', (progress: any) => {
          setVideoProgress(progress?.detail || 0);
        });

        muxUpload.on('success', () => {
          dispatch(addUpdateChallenge(_request));
          onClose();
        });
      } else {
        dispatch(addUpdateChallenge(_request));
        onClose();
      }
    } else {
      setFormError(allError);
    }
  };

  const dialogProps = {
    open,
    onClose,
    onSave,
    title: `Daily Challange`,
    size: 'md',
    saveTbtText: 'Upload',
    loading: btnLoading,
    screenLoader,
  };

  return (
    <MDDialog {...dialogProps}>
      <Box p={4}>
        <Box>
          <Grid container spacing={7} mb={5}>
            <Grid item xs={6}>
              <InputLabel sx={{ color: '#484848', pb: 2 }}>Title</InputLabel>
              <TextField
                fullWidth
                placeholder="Title"
                name="title"
                onChange={handleChange}
                value={formData?.title || ''}
                error={formError && formError?.title}
                helperText={
                  formError && formError?.title ? formError.title : ''
                }
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel disabled sx={{ color: '#484848', pb: 2 }}>
                User Created
              </InputLabel>
              <Autocomplete
                id="asynchronous-demo"
                open={openUser}
                onOpen={() => setOpenUser(true)}
                onClose={() => setOpenUser(false)}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option?.email || ''}
                options={user}
                loading={loadingUser}
                disabled
                value={authUser}
                onChange={handleChangeAutocomplete('user')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select User"
                    error={formError && formError?.user}
                    helperText={
                      formError && formError?.user ? formError.user : ''
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingUser ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ color: '#484848', pb: 2 }}>Question</InputLabel>
              <TextField
                fullWidth
                placeholder="Question"
                name="question"
                onChange={handleChange}
                value={formData?.question || ''}
                error={formError && formError?.question}
                helperText={
                  formError && formError?.question ? formError.question : ''
                }
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel sx={{ color: '#484848', pb: 2 }}>
                Date Posted
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  sx={{ width: '100%' }}
                  value={formData?.date_posted || null}
                  onChange={handleDateChange('date_posted')}
                  slotProps={{
                    textField: {
                      error: formError && formError?.date_posted,
                      helperText:
                        formError && formError?.date_posted
                          ? formError.date_posted
                          : '',
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <InputLabel sx={{ color: '#484848', pb: 2 }}>Grade</InputLabel>
              <Autocomplete
                multiple
                id="asynchronous-demo"
                open={openGrade}
                onOpen={() => setOpenGrade(true)}
                onClose={() => setOpenGrade(false)}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option?.name || ''}
                options={grade}
                loading={loadingGrade}
                value={formData?.grade || []}
                disableCloseOnSelect
                onChange={handleChangeAutocomplete('grade')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Grade"
                    error={formError && formError?.grade}
                    helperText={
                      formError && formError?.grade ? formError.grade : ''
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingGrade ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                error={formError && formError?.video}
                sx={{ color: '#484848', pb: 2 }}
              >
                Video
              </InputLabel>
              <Autocomplete
                id="asynchronous-demo"
                open={openVideo}
                onOpen={() => setOpenVideo(true)}
                onClose={() => setOpenVideo(false)}
                isOptionEqualToValue={(option, value) =>
                  option.title === value.title
                }
                getOptionLabel={(option) => option?.title || ''}
                options={video}
                loading={loadingVideo}
                value={formData?.video || {}}
                onChange={handleChangeAutocomplete('video')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Video"
                    error={formError && formError?.video}
                    helperText={
                      formError && formError?.video ? formError.video : ''
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingVideo ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <SelectDistrictAndSchool
              state={formData}
              formError={formError}
              setState={setFormData}
            />
            <Grid item xs={4}>
              <InputLabel sx={{ color: '#484848', pb: 2 }}>Topic</InputLabel>
              <Autocomplete
                id="asynchronous-demo"
                open={openTopic}
                onOpen={() => setOpenTopic(true)}
                onClose={() => setOpenTopic(false)}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option?.name || ''}
                options={topic}
                loading={loadingTopic}
                filterSelectedOptions
                value={formData?.topic || {}}
                onChange={handleChangeAutocomplete('topic')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Topic"
                    error={formError && formError?.topic}
                    helperText={
                      formError && formError?.topic ? formError.topic : ''
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingTopic ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel sx={{ color: '#484848', pb: 2 }}>Domain</InputLabel>
              <Autocomplete
                id="asynchronous-demo"
                open={openDomain}
                onOpen={() => setOpenDomain(true)}
                onClose={() => setOpenDomain(false)}
                isOptionEqualToValue={(option, value) =>
                  option?.name === value?.name
                }
                getOptionLabel={(option) => option?.name || ''}
                options={domainList?.length > 0 ? domainList : []}
                loading={loadingDomain}
                filterSelectedOptions
                value={formData?.domain || {}}
                onChange={handleChangeAutocomplete('domain')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Domain"
                    error={formError && formError?.domain}
                    helperText={
                      formError && formError?.domain ? formError.domain : ''
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingDomain ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" gap="10px" textAlign="center">
                <InputLabel sx={{ color: '#484848', pb: 2 }}>
                  Sub-Topic -
                </InputLabel>
                <Typography
                  fontSize="14px"
                  color="secondary"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => handleSubTopicDialog()}
                >
                  Add new
                </Typography>
              </Stack>
              <Autocomplete
                id="asynchronous-demo"
                open={openSubTopic}
                onOpen={() => setOpenSubTopic(true)}
                onClose={() => setOpenSubTopic(false)}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={getOptionLabel}
                options={subTopic}
                loading={loadingSubTopic}
                value={formData?.sub_topic || {}}
                onChange={handleChangeCreateSubTopic}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Sub topic"
                    error={formError && formError?.sub_topic}
                    helperText={
                      formError && formError?.sub_topic
                        ? formError.sub_topic
                        : ''
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingSubTopic ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
                filterOptions={handleFilterSubTopic}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" gap="10px" textAlign="center">
                <InputLabel sx={{ color: '#484848', pb: 2 }}>Skill</InputLabel>
                <Typography
                  fontSize="14px"
                  color="secondary"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => handleSkillDialog()}
                >
                  Add new
                </Typography>
              </Stack>
              <Autocomplete
                id="asynchronous-demo"
                open={openSkill}
                onOpen={() => setOpenSkill(true)}
                onClose={() => setOpenSkill(false)}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={getOptionLabel}
                options={skill}
                loading={loadingSkill}
                value={formData?.skill || {}}
                onChange={handleChangeCreateOptions('skill')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Skill"
                    error={formError && formError?.skill}
                    helperText={
                      formError && formError?.skill ? formError.skill : ''
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingSkill ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
                filterOptions={handleFilterOptions}
              />
            </Grid>
          </Grid>
          <Box mb={10}>
            {formData.assetId && formData.playbackId && (
              <Grid container>
                <Grid item sm={12}>
                  <MuxVideoPlayer
                    {...formData}
                    edit
                    handleRemoveVideo={handleRemoveVideo}
                  />
                </Grid>
              </Grid>
            )}
            {!formData.assetId && !formData.playbackId && (
              <Box mb={10}>
                <FileUpload
                  videoProgress={videoProgress}
                  uploadedFile={uploadedFile}
                  setUploadedFile={setUploadedFile}
                  errorText={formError?.videoFile || ''}
                />
              </Box>
            )}
          </Box>
          {openSubTopicDialog && (
            <AddSubTopic
              open={openSubTopicDialog}
              onClose={handleSubTopicDialog}
              subTopic={newSubTopicValue}
              setParentFormData={setFormData}
            />
          )}
          {openSkillDialog && (
            <AddSkill
              open={openSkillDialog}
              onClose={handleSkillDialog}
              skill={newSkillValue}
              setParentFormData={setFormData}
            />
          )}
        </Box>
      </Box>
    </MDDialog>
  );
}

export default Index;
