import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import NodataToShow from 'icons/NoDataToShow';

//*** Icons
import AddIcon from '@mui/icons-material/Add';
import { useParams } from 'react-router-dom';
import AddParentToClassRoom from '../modals/AddParentToClassRoom';
import useSchoolClassroomInfo from 'hooks/useSchoolClassroomInfo';
import { getParentsListOfClass } from 'store/Thunk/classroomThunk';
import { useAppDispatch } from 'store/store';

function Index() {
  const [addParentDialog, setAddParentDialog] = useState(false);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const handleParentDialog = () => {
    setAddParentDialog(true);
  };

  return (
    <>
      {true ? (
        <>
          <Box sx={{ p: '24px' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  m: '15px',
                  mt: '5px',
                }}
              >
                <NodataToShow />
                <Typography
                  variant="body1"
                  sx={{ fontSize: '18px', fontWeight: '600' }}
                >
                  No Parent Found
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: 'justify',
                    fontSize: '13px',
                    fontWeight: '400',
                    p: '0 30px',
                  }}
                >
                  Please Add Parents to the classroom
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  m: '15px 0 0 0',
                }}
              >
                <Button
                  variant="contained"
                  className="primary_bg_btn"
                  onClick={() => handleParentDialog()}
                  endIcon={<AddIcon />}
                >
                  Add Parent
                </Button>
              </Box>
            </Box>
          </Box>
          {/* {isOpen && (
            <AddUpdate
              open={isOpen}
              onClose={handleAddUpdateDistrict}
              id={id}s
            />
          )} */}
        </>
      ) : null}
      <AddParentToClassRoom
        open={addParentDialog}
        setOpen={setAddParentDialog}
        classRoomId={id}
        fetchData={() => {
          dispatch(getParentsListOfClass({ id: id }));
        }}
        onClose={() => setAddParentDialog(false)}
      />
    </>
  );
}

export default Index;
