import { Box, Typography, Button, Stack, Dialog } from '@mui/material';
import { HazardIcon, SuccessIcon } from 'icons';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

interface DialogProps {
  open: boolean;
  onClose: () => void;
  onOk: () => void;
}

function AssignContentDialogSuccess({ onClose, open, onOk }: DialogProps) {
  const { btnLoading } = useSelector((state: any) => state.assignContentSlice);
  const selectedInfo = localStorage.getItem('selectiveInfo');
  const [selectiveInfo, setSelectiveInfo] = useState<Record<string, any>>({});
  useEffect(() => {
    if (selectedInfo) {
      setSelectiveInfo(JSON.parse(selectedInfo));
    }
  }, [selectedInfo]);

  return (
    <Dialog
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '435px',
            zIndex: '999999',
          },
        },
      }}
      fullWidth={true}
      maxWidth="sm"
      onClose={onClose}
      open={open}
      className="p-8"
    >
      <Stack
        p={8}
        sx={{ p: 5, gap: 5, textAlign: 'center', alignItems: 'center' }}
      >
        <SuccessIcon />
        <Box mt={4}>
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 600,
              color: '#131515',
              lineHeight: '34px',
            }}
            mb={2}
          >
            Successfully Assigned!
          </Typography>
          <Typography
            color="#979C9E"
            sx={{ fontSize: '16px', fontWeight: 400, lineHeight: '24px' }}
          >
            The assignment has been successfully assigned.{' '}
          </Typography>
          {/* <Typography color="#484848">
            a these assignments to {selectiveInfo?.toName} ?
          </Typography> */}
        </Box>

        <div
          style={{
            width: '100%',
            display: 'flex',
            gap: '20px',
          }}
          className="mt-4"
        >
          <Button
            onClick={onOk}
            sx={{ width: '100%' }}
            variant="contained"
            className="primary_bg_btn"
          >
            Ok
          </Button>
        </div>
      </Stack>
    </Dialog>
  );
}

export default AssignContentDialogSuccess;
