import React from 'react';
import { Typography, Breadcrumbs, Link, Card, Box } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import EditDetailVideo from 'components/video/editDetail';
import { route_info } from 'navigation/route';
// *** Third Party Imports
import { useLocation, useNavigate } from 'react-router-dom';

function handleBreadCrumbClick(
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const breadcrumbs = [
  <Link
    underline="hover"
    key="1"
    color="inherit"
    href={route_info.content_management.video}
    onClick={() => handleBreadCrumbClick}
  >
    Resources
  </Link>,
  <Typography key="2" color="inherit">
    Video
  </Typography>,
  <Typography key="2" color="#C73E9D">
    Edit
  </Typography>,
];

function Index() {
  const navigate: any = useNavigate();
  let location = useLocation();
  const isAcademic = !location?.pathname.includes('fun');

  const handleCancel = ({ add, edit, detail }: any) => {
    console.log('isAcademic', isAcademic);
    console.log('isAcademic', 'ddddddddddd');
    if (edit || detail) {
      isAcademic
        ? navigate(route_info.content_management.video)
        : navigate(route_info.content_management.video_fun);
    }
  };
  return (
    <Box>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        style={{ marginBottom: 20 }}
      >
        {breadcrumbs}
      </Breadcrumbs>
      <Card
        sx={{
          p: '25px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <EditDetailVideo saveTitle="Update" edit handleCancel={handleCancel} />
      </Card>
    </Box>
  );
}

export default Index;
