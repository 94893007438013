/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { ReactNode } from 'react';

// Material Dashboard 2 PRO React TS components
import { TableCell, Tooltip } from '@mui/material';

import { Theme } from '@mui/material/styles';

// Declaring prop types for DataTableBodyCell
interface Props {
  children: ReactNode;
  tooltip?: any;
  tooltipId?: string;
  variant: string;
  width?: string;
  align?: 'left' | 'right' | 'center';
}

function DataTableBodyCell({
  width,
  variant,
  tooltip,
  align,
  children,
}: Props): JSX.Element {
  function convertToCamelCaseWithId(input: string): string {
    const camelCaseString = input
      .replace(/\s+/g, '')
      .replace(/(\w)(\w*)/g, (_, first, rest) => first.toLowerCase() + rest);

    const resultString =
      camelCaseString.charAt(0).toLowerCase() + camelCaseString.slice(1) + 'Id';

    return resultString;
  }

  let resultString;
  if (tooltip?.key !== undefined) {
    resultString = convertToCamelCaseWithId(tooltip?.key);
  }
  return (
    <TableCell
      sx={{
        // fontSize: size.sm,
        // maxWidth: width,
        textAlign: align || 'center',
        py: 1.5,
        px: 2,
        // borderBottom: noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`,
      }}
    >
      {' '}
      <Tooltip title={tooltip?.value?.[`${resultString}`]}>
        <div
          style={{
            display: 'flex',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '100%',
            color: '#000',
          }}
        >
          {children}
        </div>
      </Tooltip>
    </TableCell>
  );
}

// Declaring default props for DataTableBodyCell
DataTableBodyCell.defaultProps = {
  noBorder: false,
  align: 'left',
  width: '',
};

export default DataTableBodyCell;
