import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

export interface ResponseData {
  status: String;
  messagecode: String;
  message: String;
  data: any;
}

interface IntialState {
  questionList: any;
  generatedQuestions: any;
  loadingAI: any;
}

const intialState: IntialState = {
  questionList: [],
  generatedQuestions: [],
  loadingAI: false,
};

const QuestionSlice = createSlice({
  name: 'video_slice',
  initialState: intialState,
  reducers: {
    setQuestionList: (state: Draft<IntialState>, action: PayloadAction<[]>) => {
      state.questionList = action.payload;
    },
    setGeneratedQuestions: (
      state: Draft<IntialState>,
      action: PayloadAction<[]>,
    ) => {
      state.generatedQuestions = action.payload;
    },
    startLoadingAI: (state: Draft<IntialState>) => {
      state.loadingAI = true;
    },
    stopLoadingAI: (state: Draft<IntialState>) => {
      state.loadingAI = false;
    },
  },
});

export const {
  setQuestionList,
  startLoadingAI,
  stopLoadingAI,
  setGeneratedQuestions,
} = QuestionSlice.actions;

export default QuestionSlice.reducer;
