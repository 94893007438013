const assignmentTypes: { [key: string]: string } = {
  homeWork: 'Homework',
  classWork: 'Classwork',
  both: 'Both',
};

const _assignmentTypes: { [key: string]: string } = {
  homeWork: 'Homework',
  classWork: 'Classwork',
  both: 'Both',
  Homework: 'Homework',
  Classwork: 'Classwork',
  Both: 'Both',
};
const _assignmentTypesLable: { [key: string]: string } = {
  Exit_Ticket: 'Exit Ticket',
  Diagnostic: 'Diagnostic',
  Daily_Practive: 'Daily Practice',
  Bellwork: 'Bellwork',
  Unit_Quiz: 'Unit Quiz',
};

const _LessonTypes: { [key: string]: string } = {
  video: 'Video',
  template: 'Quiz',
  dailyChallenges: 'Daily Challenge',
};

const _LessonTypes_2: { [key: string]: string } = {
  Videos: 'Video',
  Templates: 'Template',
  DailyChallenges: 'DailyChallenge',
};

export {
  assignmentTypes,
  _assignmentTypesLable,
  _assignmentTypes,
  _LessonTypes,
  _LessonTypes_2,
};
