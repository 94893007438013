// *** React Imports
import React, { useEffect, useState } from 'react';

// *** Material UI Imports
import {
  Box,
  Grid,
  TextField,
  Autocomplete,
  InputLabel,
  CircularProgress,
  Checkbox,
  Avatar,
  Typography,
  Button,
} from '@mui/material';

import MDDialog from '@core/components/MDDialog';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

/* mui-color-input' */
import { useAppDispatch, useAppSelector } from 'store/store';
import Validations from 'helper/validations';
import { setBtnLoading } from 'store/Slice/loadingSlice';

import AddSkill from 'components/dialog/skill/addSkill';
import AddSubTopic from 'components/dialog/subtopic/addSubTopic';
import { getDistrictByID, addUpdateDistrict } from 'store/Thunk/districtThunk';
import { getGradeList } from 'store/Thunk/videoThunk';
import { getUserList } from 'store/Thunk/userThunk';
import { profile } from 'assets';

function Index({ open, onClose }: any) {
  const dispatch: any = useAppDispatch();
  const { gradeList } = useAppSelector((state: any) => state.videoReducer);
  const { userList } = useAppSelector((state: any) => state.userReducer);
  const { btnLoading, screenLoading } = useAppSelector(
    (state: any) => state.loadingReducer,
  );
  const { districtID } = useAppSelector((state: any) => state.districtReducer);

  const [formData, setFormData] = useState<any>({
    grade: [],
  });
  const [formError, setFormError] = useState<any>({});
  // console.log('yyyyyyyyyyyyyyyy', districtID[0], formData);
  const [screenLoader, setScreenLoader] = React.useState<boolean>(false);
  const [loadingStartGrade, setLoadingStartGrade] = React.useState<boolean>(false);
  const [loadingEndGrade, setLoadingEndGrade] = React.useState<boolean>(false);
  const [openDistrictType, setOpenDistrictType] = React.useState<boolean>(false);
  const [openGrade, setOpenGrade] = React.useState<boolean>(false);
  const [openEndGrade, setOpenEndGrade] = React.useState<boolean>(false);
  const [grade, setGrade] = React.useState<any[]>([]);
  const [openSubTopicDialog, setOpenSubTopicDialog] = React.useState<boolean>(false);
  const [newSubTopicValue, setNewSubTopicValue] = React.useState({});
  const [openSkillDialog, setOpenSkillDialog] = React.useState<boolean>(false);
  const [newSkillValue, setNewSkillValue] = React.useState({});
  const [openTeacher, setOpenTeacher] = React.useState<boolean>(false);
  const [loadingSchoolLeader, setLoadingSchoolLeader] = React.useState<boolean>(false);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const DistrictTypesList = [
    { name: 'Regular' },
    { name: 'Component' },
    { name: 'Supervisory Union ID' },
    { name: 'Regional' },
    { name: 'State' },
    { name: 'Federal' },
    { name: 'Charter' },
    { name: 'Other' },
  ];

  useEffect(() => {
    setFormData({});
  }, []);

  useEffect(() => {
    setScreenLoader(screenLoading);
  }, [screenLoading]);

  useEffect(() => {
    if (openGrade) {
      dispatch(getGradeList());
      setLoadingStartGrade(true);
    } else {
      setGrade([]);
    }
  }, [openGrade]);

  useEffect(() => {
    if (openEndGrade) {
      dispatch(getGradeList());
      setLoadingEndGrade(true);
    } else {
      setGrade([]);
    }
  }, [openEndGrade]);

  useEffect(() => {
    setLoadingStartGrade(false);
    setLoadingEndGrade(false);
    setGrade(gradeList);
  }, [gradeList]);

  useEffect(() => {
    dispatch(getUserList());
  }, [openTeacher]);

  const handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleChangeAutocomplete =
    (name: string) =>
    (
      event: React.SyntheticEvent<Element, Event>,
      newValue: string | null | any,
    ) => {
      let param = {
        [name]: newValue,
        ...(name === 'startGrade' && {
          endGrade: ""
        })
      };
      setFormData({ ...formData, ...param });
    };

  const handleSkillDialog = (name: string = '') => {
    setNewSkillValue({
      ...(!openSkillDialog
        ? {
            topic: formData.topic,
            name: name,
            subTopic: formData.sub_topic,
          }
        : {}),
    });
    setOpenSkillDialog(!openSkillDialog);
  };

  const handleSubTopicDialog = (name: string = '') => {
    setNewSubTopicValue({
      ...(!openSubTopicDialog
        ? {
            topic: formData.topic,
            name: name,
          }
        : {}),
    });
    setOpenSubTopicDialog(!openSubTopicDialog);
  };

  const handleDisableOption = (option: any) => {
    const optionGradeIndex = grade.findIndex((obj) => obj.id === option.id);
    const startGradeIndex = grade.findIndex(
      (obj) => obj.id === formData?.startGrade?.id,
    );
    return optionGradeIndex <= startGradeIndex
  };

  const onSave = (event: any) => {
    event.preventDefault();
    let _request: any = {
      name: formData?.name || '',
      ncesDistrictId: formData?.ncesDistrictId || '',
      streetAddress: formData?.streetAddress || '',
      city: formData?.city || '',
      state: formData?.state || '',
      zipCode: formData?.zipCode || '',
      county: formData?.county || '',
      districtType: formData?.districtType?.name || '',
      startGradeId: formData?.startGrade?.id || '',
      startGrade: formData?.startGrade?.name || '',
      endGradeId: formData?.endGrade?.id || '',
      endGrade: formData?.endGrade?.name || '',
      federalFunding: formData?.federalFunding || '',
      stateFunding: formData?.stateFunding || '',
      localFunding: formData?.localFunding || '',
      totalNoOfSchool: formData?.totalNoOfSchool || '',
      totalNoOfElementarySchool: formData?.totalNoOfElementarySchool || '',
      totalNoOfMiddleSchool: formData?.totalNoOfMiddleSchool || '',
      totalNoOfHighSchool: formData?.totalNoOfHighSchool || '',
    };

    let allError = Validations.ValidateDistrictDataFrom(_request);
    console.log('_request', _request);
    if (
      Object.entries(allError).length === 0 &&
      allError.constructor === Object
    ) {
      dispatch(setBtnLoading(true));
      dispatch(addUpdateDistrict(_request));
      onClose();
    } else {
      setFormError(allError);
    }
  };

  const dialogProps = {
    open,
    onClose,
    onSave,
    title: 'Add District',
    size: 'lg',
    saveTbtText: 'Add',
    closeBtnText: 'Cancel',
    loading: btnLoading,
    screenLoader,
  };

  return (
    <MDDialog {...dialogProps}>
      <Box sx={{ p: 3 }}>
        <Grid container>
          <Grid container spacing={6}>
            <Grid item md={4} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.name}
                sx={{ color: '#484848', pb: 2 }}
              >
                District Name
              </InputLabel>
              <TextField
                required
                fullWidth
                id="name"
                value={formData?.name || ''}
                onChange={handleChangeInput}
                placeholder="Enter district name"
                name="name"
                error={formError && formError?.name}
                helperText={formError && formError?.name ? formError.name : ''}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.ncesDistrictId}
                sx={{ color: '#484848', pb: 2 }}
              >
                NCES District ID
              </InputLabel>
              <TextField
                required
                fullWidth
                id="ncesDistrictId"
                value={formData?.ncesDistrictId || ''}
                onChange={handleChangeInput}
                placeholder="Enter NECS District ID"
                name="ncesDistrictId"
                error={formError && formError?.ncesDistrictId}
                helperText={
                  formError && formError?.ncesDistrictId
                    ? formError.ncesDistrictId
                    : ''
                }
              />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <InputLabel
                error={formError && formError?.districtType}
                sx={{ color: '#484848', pb: 2 }}
              >
                District type
              </InputLabel>
              <Autocomplete
                id="districtType"
                open={openDistrictType}
                onOpen={() => setOpenDistrictType(true)}
                onClose={() => setOpenDistrictType(false)}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option?.name || ''}
                options={DistrictTypesList}
                filterSelectedOptions
                value={formData?.districtType || {}}
                onChange={handleChangeAutocomplete('districtType')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select District type"
                    error={formError && formError?.districtType}
                    helperText={
                      formError && formError?.districtType
                        ? formError.districtType
                        : ''
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6} sx={{ mt: 'unset' }}>
            <Grid item md={3} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.county}
                sx={{ color: '#484848', pb: 2 }}
              >
                Country
              </InputLabel>
              <TextField
                required
                fullWidth
                id="county"
                value={formData?.county || ''}
                onChange={handleChangeInput}
                placeholder="United States"
                name="county"
                error={formError && formError?.county}
                helperText={
                  formError && formError?.county ? formError.county : ''
                }
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.city}
                sx={{ color: '#484848', pb: 2 }}
              >
                City
              </InputLabel>
              <TextField
                required
                fullWidth
                id="city"
                value={formData?.city || ''}
                onChange={handleChangeInput}
                placeholder="Enter City"
                name="city"
                error={formError && formError?.city}
                helperText={formError && formError?.city ? formError.city : ''}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.state}
                sx={{ color: '#484848', pb: 2 }}
              >
                State
              </InputLabel>
              <TextField
                required
                fullWidth
                id="state"
                value={formData?.state || ''}
                onChange={handleChangeInput}
                placeholder="Enter State"
                name="state"
                error={formError && formError?.state}
                helperText={
                  formError && formError?.state ? formError.state : ''
                }
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.zipCode}
                sx={{ color: '#484848', pb: 2 }}
              >
                Zip Code
              </InputLabel>
              <TextField
                required
                fullWidth
                id="zipCode"
                value={formData?.zipCode || ''}
                onChange={handleChangeInput}
                placeholder="Enter Zip Code"
                name="zipCode"
                error={formError && formError?.zipCode}
                helperText={
                  formError && formError?.zipCode ? formError.zipCode : ''
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={6} sx={{ mt: 'unset' }}>
            <Grid item md={4} sm={12} xs={12}>
              <InputLabel
                error={formError && formError?.streetAddress}
                sx={{ color: '#484848', pb: 2 }}
              >
                Street Address
              </InputLabel>
              <TextField
                required
                fullWidth
                id="streetAddress"
                value={formData?.streetAddress || ''}
                onChange={handleChangeInput}
                placeholder="Enter Address"
                name="streetAddress"
                error={formError && formError?.streetAddress}
                helperText={
                  formError && formError?.streetAddress
                    ? formError.streetAddress
                    : ''
                }
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.startGradeId}
                sx={{ color: '#484848', pb: 2 }}
              >
                Start Grade
              </InputLabel>
              <Autocomplete
                id="startGradeId"
                open={openGrade}
                onOpen={() => setOpenGrade(true)}
                onClose={() => setOpenGrade(false)}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option?.name || ''}
                options={grade}
                loading={loadingStartGrade}
                filterSelectedOptions
                value={
                  formData?.startGrade?.length > 0
                    ? formData?.startGrade[0]
                    : formData?.startGrade || {}
                }
                onChange={handleChangeAutocomplete('startGrade')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Start Grade"
                    error={formError && formError?.startGradeId}
                    helperText={
                      formError && formError?.startGradeId
                        ? formError.startGradeId
                        : ''
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingStartGrade ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.endGradeId}
                sx={{ color: '#484848', pb: 2 }}
              >
                End Grade
              </InputLabel>
              <Autocomplete
                id="endGrade"
                open={openEndGrade}
                onOpen={() => setOpenEndGrade(true)}
                onClose={() => setOpenEndGrade(false)}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option?.name || ''}
                options={grade}
                loading={loadingEndGrade}
                filterSelectedOptions
                getOptionDisabled={handleDisableOption}
                value={
                  formData?.startGrade?.length > 0
                    ? formData?.endGrade[0]
                    : formData?.endGrade || {}
                }
                onChange={handleChangeAutocomplete('endGrade')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select End Grade"
                    error={formError && formError?.startGradeId}
                    helperText={
                      formError && formError?.endGradeId
                        ? formError.endGradeId
                        : ''
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingEndGrade ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6} sx={{ mt: 'unset' }}>
            <Grid item md={4} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.federalFunding}
                sx={{ color: '#484848', pb: 2 }}
              >
                Federal Funding
              </InputLabel>
              <TextField
                required
                fullWidth
                id="federalFunding"
                value={formData?.federalFunding || ''}
                onChange={handleChangeInput}
                placeholder="Enter Federal Funding"
                name="federalFunding"
                error={formError && formError?.federalFunding}
                helperText={
                  formError && formError?.federalFunding
                    ? formError.federalFunding
                    : ''
                }
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.stateFunding}
                sx={{ color: '#484848', pb: 2 }}
              >
                State Funding
              </InputLabel>
              <TextField
                required
                fullWidth
                id="stateFunding"
                value={formData?.stateFunding || ''}
                onChange={handleChangeInput}
                placeholder="Enter State Funding"
                name="stateFunding"
                error={formError && formError?.stateFunding}
                helperText={
                  formError && formError?.stateFunding
                    ? formError.stateFunding
                    : ''
                }
              />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <InputLabel
                error={formError && formError?.localFunding}
                sx={{ color: '#484848', pb: 2 }}
              >
                Local Funding
              </InputLabel>
              <TextField
                required
                fullWidth
                id="localFunding"
                value={formData?.localFunding || ''}
                onChange={handleChangeInput}
                placeholder="Enter Local Funding"
                name="localFunding"
                error={formError && formError?.localFunding}
                helperText={
                  formError && formError?.localFunding
                    ? formError.localFunding
                    : ''
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={6} sx={{ mt: 'unset' }}>
            <Grid item md={3} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.totalNoOfSchool}
                sx={{ color: '#484848', pb: 2 }}
              >
                Total Number of Schools
              </InputLabel>
              <TextField
                required
                fullWidth
                type="number"
                id="totalNoOfSchool"
                value={formData?.totalNoOfSchool || ''}
                onChange={handleChangeInput}
                placeholder="Enter Number of Schools"
                name="totalNoOfSchool"
                error={formError && formError?.totalNoOfSchool}
                helperText={
                  formError && formError?.totalNoOfSchool
                    ? formError.totalNoOfSchool
                    : ''
                }
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.totalNoOfElementarySchool}
                sx={{ color: '#484848', pb: 2 }}
              >
                Total Number of Elementary Schools
              </InputLabel>
              <TextField
                required
                fullWidth
                type="number"
                id="totalNoOfElementarySchool"
                value={formData?.totalNoOfElementarySchool || ''}
                onChange={handleChangeInput}
                placeholder="Enter Number of Elementary Schools"
                name="totalNoOfElementarySchool"
                error={formError && formError?.totalNoOfElementarySchool}
                helperText={
                  formError && formError?.totalNoOfElementarySchool
                    ? formError.totalNoOfElementarySchool
                    : ''
                }
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.totalNoOfMiddleSchool}
                sx={{ color: '#484848', pb: 2 }}
              >
                Total Number of Middle Schools
              </InputLabel>
              <TextField
                required
                fullWidth
                type="number"
                id="totalNoOfMiddleSchool"
                value={formData?.totalNoOfMiddleSchool || ''}
                onChange={handleChangeInput}
                placeholder="Enter Number of Middle Schools"
                name="totalNoOfMiddleSchool"
                error={formError && formError?.totalNoOfMiddleSchool}
                helperText={
                  formError && formError?.totalNoOfMiddleSchool
                    ? formError.totalNoOfMiddleSchool
                    : ''
                }
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <InputLabel
                error={formError && formError?.totalNoOfHighSchool}
                sx={{ color: '#484848', pb: 2 }}
              >
                Total Number of High Schools
              </InputLabel>
              <TextField
                required
                fullWidth
                type="number"
                id="totalNoOfHighSchool"
                value={formData?.totalNoOfHighSchool || ''}
                onChange={handleChangeInput}
                placeholder="Enter Number of High Schools"
                name="totalNoOfHighSchool"
                error={formError && formError?.totalNoOfHighSchool}
                helperText={
                  formError && formError?.totalNoOfHighSchool
                    ? formError.totalNoOfHighSchool
                    : ''
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={6} sx={{ mt: 'unset' }}>
            {userList ? (
              <Grid item md={3} sm={12} xs={12}>
                <InputLabel
                  error={formError && formError?.classroomTeacher1Ids}
                  sx={{ color: '#484848', pb: 2 }}
                >
                  District Leader
                </InputLabel>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  id="classroomTeacher1Ids"
                  open={openTeacher}
                  onOpen={() => setOpenTeacher(true)}
                  onClose={() => setOpenTeacher(false)}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.name === value.name
                  // }
                  getOptionLabel={(option) => option?.name || ''}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Grid
                        container
                        spacing={1}
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Grid item xs={2}>
                          <Avatar
                            alt={'avatar_profile'}
                            sx={{ height: '20px', width: '20px', mr: 2 }}
                            src={profile}
                          />
                        </Grid>
                        <Grid item xs={9} sx={{ overflow: 'hidden' }}>
                          <Typography variant="body1">
                            {option?.email || ''}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                        </Grid>
                      </Grid>
                    </li>
                  )}
                  options={userList}
                  loading={loadingSchoolLeader}
                  filterSelectedOptions
                  value={
                    formData?.classroomTeacher1?.length > 0
                      ? formData?.classroomTeacher1
                      : []
                  }
                  onChange={handleChangeAutocomplete('classroomTeacher1')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Start Grade"
                      error={formError && formError?.classroomTeacher1Ids}
                      helperText={
                        formError && formError?.classroomTeacher1Ids
                          ? formError.classroomTeacher1Ids
                          : ''
                      }
                      style={{ minWidth: 250 }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingSchoolLeader ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        {openSkillDialog && (
          <AddSkill
            open={openSkillDialog}
            onClose={handleSkillDialog}
            skill={newSkillValue}
          />
        )}
        {openSubTopicDialog && (
          <AddSubTopic
            open={openSubTopicDialog}
            onClose={handleSubTopicDialog}
            subTopic={newSubTopicValue}
          />
        )}
      </Box>
    </MDDialog>
  );
}

export default Index;
