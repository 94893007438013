import { useMemo } from 'react';

const useSchoolClassroomInfo = () => {
  const currentLocation = window.location.href;

  const { schoolId, classroomId } = useMemo(() => {
    const pattern = /school\/(\d+)\/classroom\/([\w-]+)/;
    const pattern_2 = /district\/([\w-]+)\/school\/(\d+)/;
    const school = /school\/(\d+)\/?$/;

    const matches = pattern.exec(currentLocation);
    const matches_2 = pattern_2.exec(currentLocation);
    const match_3 = school.exec(currentLocation);

    if (matches) {
      const [_, matchedSchoolId, matchedClassId] = matches;
      return {
        schoolId: matchedSchoolId,
        classroomId: matchedClassId,
      };
    }

    if (matches_2) {
      const [_, matchedDistrictId, matchedSchoolId] = matches_2;
      return {
        schoolId: matchedSchoolId,
      };
    }

    if (match_3) {
      const [, capturedNumber] = match_3;
      return {
        schoolId: capturedNumber,
      };
    }

    return {
      schoolId: '',
      classroomId: '',
    };
  }, [currentLocation]);

  return { schoolId, classroomId };
};

export default useSchoolClassroomInfo;
