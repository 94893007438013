import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getLocalDateTime } from 'helper/service';
import { useAppSelector } from 'store/store';
export interface SimpleDialogProps {
  open: boolean;
  selectedAssignments?: any;
  assignmentType?: any;
  imported?: boolean;
  selectedAssessments?: any;
  selectedAssignement?: any;
  onClose: (value: boolean) => void;
}
export default function QuizView(props: SimpleDialogProps) {
  const { onClose, open, selectedAssignement, assignmentType } = props;
  const ClassroomData = useAppSelector(
    (state) => state.classroomReducer.classroomID,
  );
  console.log('selectedAssignement', selectedAssignement);

  const totalPercentage = selectedAssignement?.school_assignment_quiz_results
    ?.length
    ? selectedAssignement?.school_assignment_quiz_results?.reduce(
        (total: any, obj: any) => total + parseFloat(obj.percentage),
        0,
      )
    : 0;

  let correctScore = 0;
  let incorrectScore = 0;
  if (selectedAssignement?.school_assignment_quiz_results?.length)
    selectedAssignement?.school_assignment_quiz_results?.forEach((obj: any) => {
      obj?.answerCorrect?.forEach((answer: any) => {
        if (answer.correct_answer === answer.student_answer) {
          correctScore++;
        } else {
          incorrectScore++;
        }
      });
    });
  const AllQuestions = [
    {
      questionTitle:
        'Emily scored 85% on her math test. If there were 40 questions on the test, how many questions did Emily answer correctly?',
      correct_answer: 'Option A',
      student_answer: 'abcd',
      correct: true,
    },
    {
      questionTitle:
        'Emily scored 85% on her math test. If there were 40 questions on the test, how many questions did Emily answer correctly?',
      correct_answer: 'Option B',
      student_answer: 'abc',
      correct: false,
    },
    {
      questionTitle:
        'Emily scored 85% on her math test. If there were 40 questions on the test, how many questions did Emily answer correctly?',
      correct_answer: 'Option c',
      student_answer: 'abcd',
      correct: false,
    },
    {
      questionTitle:
        'Emily scored 85% on her math test. If there were 40 questions on the test, how many questions did Emily answer correctly?',
      correct_answer: 'Option d',
      student_answer: 'abcd',
      correct: true,
    },
    {
      questionTitle:
        'Emily scored 85% on her math test. If there were 40 questions on the test, how many questions did Emily answer correctly?',
      correct_answer: 'Option e',
      student_answer: 'abcd',
      correct: true,
    },
  ];
  //   const AllQuestions =
  //     selectedAssignement?.school_assignment_quiz_results?.reduce(
  //       (mergedArray: any, obj: any) => {
  //         return mergedArray.concat(obj.answerCorrect);
  //       },
  //       [],
  //     );
  let AllQuestions2: any[] = [];
  selectedAssignement?.school_assignments?.school_assignment_session?.forEach(
    (session: any) => {
      session?.currentTemplateQuestions?.forEach((question: any) => {
        const newQuestion = {
          questionTitle: question.question,
          correct_answer: '',
          student_answer: '',
          correct: false,
        };

        // Find the matching question in answerCorrect
        const matchingAnswer = selectedAssignement?.answerCorrect?.find(
          (answer: any) => answer.questionId === question.id,
        );

        if (matchingAnswer) {
          newQuestion.correct_answer = matchingAnswer.correct_answer;
          newQuestion.student_answer = matchingAnswer.student_answer;
          newQuestion.correct =
            matchingAnswer.correct_answer === matchingAnswer.student_answer;
        }

        AllQuestions2.push(newQuestion);
      });
    },
  );
  let updatedQuiz = AllQuestions2.filter((item) => item.student_answer);

  console.log('mergedAnswerCorrect', AllQuestions2);
  console.log('mergedAnswerCorrect', updatedQuiz);
  console.log('mergedAnswerCorrect', selectedAssignement);
  const handleListItemClick = (value: boolean) => {
    onClose(false);
  };
  return (
    <Dialog
      onClose={onClose}
      fullWidth
      open={open}
      className="assign-dialog"
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '1026px',
        },
      }}
    >
      <Stack className="flex justify-between" m={2}>
        <DialogTitle
          color={'#131515'}
          fontWeight={600}
          fontSize={'24px'}
          onClick={() => console.log('mergedAnswerCorrect', AllQuestions2)}
        >
          Quiz
        </DialogTitle>
        <IconButton
          onClick={() => handleListItemClick(false)}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 16,
            color: (theme) => theme.palette.grey[600],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Grid container spacing={4} mt={4} px={2} mb={4}>
          <Grid item md={2}>
            <Typography
              className="!font-semibold !text-base !text-black"
              onClick={() => console.log(correctScore)}
            >
              Assignment
            </Typography>
            <Typography className="!font-normal !text-base !text-gray-500 mt-2 !capitalize">
              {assignmentType?.replace(/_/g, ' ') || ''}
            </Typography>
          </Grid>
          <Grid item md={4}>
            <Typography className="!font-semibold !text-base !text-black">
              Standard
            </Typography>
            <Typography className="!font-normal !text-base !text-gray-500 mt-2">
              {selectedAssignement?.skill?.subTopic[0]?.subTopic?.name || ''}
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography className="!font-semibold !text-base !text-black">
              Skill
            </Typography>
            <Typography className="!font-normal !text-base !text-gray-500 mt-2">
              {selectedAssignement?.skill?.skill[0]?.skill?.name ||
                '23 - Synonyms & antonyms'}
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography className="!font-semibold !text-base !text-black">
              Completed on Date
            </Typography>
            <Typography className="!font-normal !text-base !text-gray-500 mt-2">
              {getLocalDateTime(
                selectedAssignement?.dueDate || new Date(),
                'MMMM D, YYYY',
              )}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography className="!font-semibold !text-base !text-black">
              Classroom Name
            </Typography>
            <Typography className="!font-normal !text-base !text-gray-500 mt-2">
              {selectedAssignement?.school_assignments?.classroom?.name || '-'}
            </Typography>
          </Grid>
          <Grid item md={4}>
            <Typography className="!font-semibold !text-base !text-black">
              School Name
            </Typography>
            <Typography className="!font-normal !text-base !text-gray-500 mt-2">
              {selectedAssignement?.school_assignments?.classroom
                ?.SchoolClassroom[0]?.school?.name || '-'}
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography className="!font-semibold !text-base !text-black">
              Student
            </Typography>
            <Tooltip title={selectedAssignement?.student?.id}>
              <Box className="flex gap-2 justify-start items-center mt-2">
                <img
                  className="w-8 rounded-full h-8"
                  src={selectedAssignement?.student?.imageUrl || ''}
                  alt=""
                />
                <Typography className="!font-normal !text-base !text-gray-500">
                  {selectedAssignement?.student?.name || ''}
                </Typography>
              </Box>
            </Tooltip>
          </Grid>
          <Grid item md={3}>
            <Typography className="!font-semibold !text-base !text-black">
              Teacher Name
            </Typography>
            <Typography className="!font-normal !text-base !text-gray-500 mt-2">
              {selectedAssignement?.school_assignments?.assignedBy?.name ||
                selectedAssignement?.school_assignments?.assignBy?.name ||
                '-'}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          className="flex"
          spacing={4}
          p={2}
          mt={4}
          bgcolor={'#F9F9F9'}
          container
          sx={{
            '& .MuiGrid-item': {
              pt: 0,
            },
          }}
        >
          <Grid item md={2}>
            <Typography className="!font-semibold !text-base !text-black">
              Students Answer
            </Typography>
          </Grid>
          <Grid item md={7.5} justifyContent={'center'} display={'flex'}>
            <Typography className="!font-semibold !text-base !text-black">
              Question
            </Typography>
          </Grid>
          <Grid item md={2.5} justifyContent={'center'} display={'flex'}>
            <Typography className="!font-semibold !text-base !text-black">
              Correct/Incorrect ?
            </Typography>
          </Grid>
        </Grid>
        <>
          {updatedQuiz?.map((question: any, key: any) => (
            <Grid container spacing={4} mt={4}>
              <Grid item md={2}>
                <Typography
                  fontWeight={700}
                  fontSize={'13px'}
                  color={'#212121'}
                >
                  {key + 1} Option{' '}
                  {
                    <span style={{ textTransform: 'capitalize' }}>
                      {question.correct_answer || '-'}
                    </span>
                  }
                </Typography>
              </Grid>
              <Grid item md={7.5} justifyContent={'left'}>
                <Typography
                  fontWeight={500}
                  fontSize={'13px'}
                  color={'#212121'}
                >
                  {question?.questionTitle}
                </Typography>
              </Grid>
              <Grid
                item
                md={2.5}
                textAlign={'left'}
                justifyContent={'center'}
                alignItems={'center'}
                display={'flex'}
              >
                <span
                  style={{
                    fontWeight: 500,
                    fontSize: '12px',
                    borderRadius: '16px',
                    padding: '2px 8px',
                    color: question?.correct ? '#027A48' : '#B42318',
                    backgroundColor: question?.correct ? '#ECFDF3' : '#FEF3F2',
                  }}
                >
                  {question?.correct ? 'Correct' : 'Incorrect'}
                </span>
              </Grid>
            </Grid>
          ))}
        </>
      </Stack>
    </Dialog>
  );
}
