import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

interface componentProps {
  columns: string[];
  rows: string[];
  title: string;
}

const SimpleTable = ({ title, columns, rows }: componentProps) => {
  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Typography variant="h6" sx={{ my: 6 }} color={'#000'} fontWeight={600}>
        {title}
      </Typography>
      <div>
        <Grid container>
          {columns.map((head, index) => (
            <Grid key={index} item md={12 / columns.length}>
              {head}
            </Grid>
          ))}
        </Grid>

        <Grid
          container
          sx={{
            mt: 2,
          }}
        >
          {rows?.map((data, index) => (
            <Grid container item key={index}>
              {/* Table Row */}
              {Object.values(data).map((value, valueIndex) => (
                <Grid
                  key={valueIndex}
                  item
                  md={12 / columns.length}
                  width={'30%'}
                  sx={{
                    color: '#000',
                    p: 3,
                    borderRadius: '8px',
                    backgroundColor: '#F9F9F9',
                    mt: 2,
                  }}
                >
                  {value}
                </Grid>
              ))}
            </Grid>
          ))}
        </Grid>
      </div>
    </Box>
  );
};

export default SimpleTable;
